import { AfterViewInit, Component, Injector, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";
import { isStepComponent, isValidatingComponent } from "@e-commerce/checkout/base/checkout";
import { EComCheckoutBaseComponent } from "@e-commerce/checkout/base/checkout.base.component";
import { EComInvoiceBillingPaymentComponent } from "@e-commerce/checkout/invoice/billingPayment/invoice.billing.payment.component";
import { EComInvoiceOrderReviewComponent } from "@e-commerce/checkout/invoice/orderReview/invoice.order.review.component";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { EComCompleteInvoiceModel } from "@e-commerce/modelConfigs/complete.invoice.model.config";
import { EComRootService } from "@e-commerce/root/authn/root.authn.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";

const TYPE_INFO: MfTypeInfo = { className: "EComCheckoutInvoiceComponent" };

@Component({
  selector: "ecom-checkout-invoice",
  templateUrl: "./checkout.invoice.component.html",
  styleUrls: ["./checkout.invoice.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EComCheckoutInvoiceComponent extends EComCheckoutBaseComponent implements AfterViewInit {
  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected override _rootService: EComRootService,
  ) {
    super(TYPE_INFO, _injector, _rootService);
  }

  public ngAfterViewInit(): void {
    this._routerActivate();
  }

  protected _summaryContinueClick(): void {
    if (!mfTypeIsUndefined(this._routerOutletComponent)) {
      if (this._isEComBillingPaymentComponent(this._routerOutletComponent)) {
        this._router.navigate([EcomRouteNames.partNameOrderReview], { relativeTo: this._activatedRoute });
        return;
      }
      if (this._isEComOrderReviewComponent(this._routerOutletComponent)) {
        this._router.navigate([EcomRouteNames.routeOrderConfirmation]);
      }
    }
  }

  protected _setComponentChangeStates(routerOutlet: RouterOutlet): void {
    setTimeout(() => {
      const component = routerOutlet.component;
      if (isStepComponent<EComCompleteInvoiceModel>(component)) {
        this._routerOutletComponent = component;

        if (isValidatingComponent<EComCompleteInvoiceModel>(this._routerOutletComponent)) {
          this._formGroup = this._routerOutletComponent.formGroup;
        } else {
          this._formGroup = new FormGroup([]);
        }

        if (this._isEComOrderReviewComponent(this._routerOutletComponent)) {
          this._summaryButtonLabel = "Make Payment";
          this._summaryShowTC = true;
        } else {
          this._summaryButtonLabel = "Continue";
          this._summaryShowTC = false;
        }
      }
    }, 1);
  }

  protected _isEComBillingPaymentComponent(component: object): component is EComInvoiceBillingPaymentComponent {
    if (component instanceof EComInvoiceBillingPaymentComponent) {
      return true;
    }
    return false;
  }

  protected _isEComOrderReviewComponent(component: object): component is EComInvoiceOrderReviewComponent {
    if (component instanceof EComInvoiceOrderReviewComponent) {
      return true;
    }
    return false;
  }
}