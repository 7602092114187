import { Injector } from "@angular/core";
import { MfError } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfStringIsEmptyOrWhitespace, mfStringTrimEnd } from "@material-framework/common/utils/string.utils";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFilterGroup } from "@material-framework/filter/filter";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfPortalsFilterConverterService } from "@material-framework/portals/filter/portals.filter.converter.service";
import { MfPortalsCollectionResponseData, MfPortalsPaging, MfPortalsRequestCollectionData, mfPortalsSortTypesToMfPortalsSortingTypes } from "@material-framework/portals/portals";
import { MfPortalsClientService } from "@material-framework/portals/portals.client.service";
import { MfTableDataSource } from "@material-framework/table/dataSource/table.data.source";
import { MfTableFieldColumn, MfTablePagination } from "@material-framework/table/table";
import { MfTableConfig } from "@material-framework/table/table.config";
import { BehaviorSubject, concatMap, Observable, of, Subscription } from "rxjs";


const TYPE_INFO: MfTypeInfo = { className: "MfPortalsTableDataSource" };

export type MfPortalsDataSourceUrl = {
  url: string;
}

export class MfPortalsTableDataSource<TModel extends MfModelBase> extends MfTableDataSource<TModel> {
  public isPortalsDataSource = true;
  public data: TModel[] = [];
  public url?: string;
  public namedResultsKey?: keyof MfPortalsCollectionResponseData<TModel>;

  public buildPostData?: <TPostModel extends MfPortalsRequestCollectionData>(postData: TPostModel) => Observable<MfPortalsRequestCollectionData>;
  public buildUrl?: (urlData: MfPortalsDataSourceUrl) => Observable<MfPortalsDataSourceUrl>;

  protected _dateSubject = new BehaviorSubject<TModel[]>([]);
  protected _portalsFilterConverterService: MfPortalsFilterConverterService;
  protected _portalsClientService: MfPortalsClientService;
  protected _getCollectionPostSubscription?: Subscription;

  public constructor(
    protected override _injector: Injector,
    protected override _config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector, _config);
    this._portalsFilterConverterService = this._injector.get(MfPortalsFilterConverterService);
    this._portalsClientService = this._injector.get(MfPortalsClientService);
  }


  public connect(): Observable<TModel[]> {
    return this._dateSubject.asObservable();
  }

  public disconnect(): void {
    this._dateSubject.complete();
    this.isLoading.complete();
    if (!mfTypeIsUndefined(this._getCollectionPostSubscription) && this._getCollectionPostSubscription.closed === false) {
      this._getCollectionPostSubscription.unsubscribe();
    }
  }

  public load(
    pagination: MfTablePagination,
    group: MfFilterGroup,
    columns: MfTableFieldColumn[],
    responseModelConfig: MfModelConfigMapped,
    postModelConfig?: MfModelConfigMapped
  ): void {
    if (mfTypeIsUndefined(this._getCollectionPostSubscription)) {
      if (!mfTypeIsUndefined(this.url)) {
        this.isLoading.next(true);

        const paging = this._getPaging(pagination);
        const urlData: MfPortalsDataSourceUrl = { url: mfStringTrimEnd(this.url, "/") };

        const postData: MfPortalsRequestCollectionData = {
          filter: this._portalsFilterConverterService.fromGroup(group, true),
          page: paging!.page,
          pageSize: paging?.pageSize,
          sort: this._getSorting(columns),
        };

        if (mfTypeIsUndefined(this.buildPostData)) {
          this.buildPostData = () => of(postData);
        }

        if (mfTypeIsUndefined(this.buildUrl)) {
          this.buildUrl = () => of(urlData);
        }

        const obs$ = this.buildPostData(postData).pipe(
          concatMap((postData) => {
            return this.buildUrl!(urlData).pipe(
              concatMap((urlDataResult) => {
                return this._portalsClientService.getCollectionPost<TModel, MfPortalsRequestCollectionData>(
                  responseModelConfig,
                  urlDataResult.url,
                  postData,
                  postModelConfig,
                );
              }),
            );
          })
        );

        this._getCollectionPostSubscription = obs$.subscribe({
          next: (response) => {
            let results: TModel[] | undefined;
            if (!mfTypeIsUndefined(this.namedResultsKey)) {
              results = response[this.namedResultsKey] as TModel[];
            } else {
              results = response.results;
            }

            if (mfTypeIsUndefined(results)) {
              throw new MfError(this.typeInfo, "load", "results data was undefined");
            }

            if (results.length < pagination.pageSize) {
              pagination.length = pagination.pageIndex * pagination.pageSize;
            } else {
              pagination.length = Number.MAX_SAFE_INTEGER;
            }

            this.data = results;
            this.onDataLoaded.next(this.data);
            this._dateSubject.next(this.data);
            this.isLoading.next(false);
            this.hasData.next(this.data.length > 0);
            delete this._getCollectionPostSubscription;
          },
          error: (error) => {
            this.data = [];
            this.onDataLoaded.next(this.data);
            this._dateSubject.next(this.data);
            this.isLoading.next(false);
            this.hasData.next(this.data.length > 0);
            delete this._getCollectionPostSubscription;

            throw error;
          }
        });
      } else {
        throw new MfError(this.typeInfo, "load", "url is undefined");
      }
    }
  }

  protected _getPaging(pagination: MfTablePagination): MfPortalsPaging | undefined {
    return {
      page: pagination.pageIndex,
      pageSize: pagination.pageSize,
    };
  }

  protected _getSorting(columns: MfTableFieldColumn[]): string | undefined {
    let sorting: string = "";

    const filtered = columns.filter((column) => !mfTypeIsUndefined(column.sort) && !mfTypeIsUndefined(column.sort.index));
    const sorted = filtered.sort((a, b) => a.sort!.index! - b.sort!.index!);

    sorted.forEach((column) => {
      if (!mfTypeIsUndefined(column.sort) && !mfTypeIsUndefined(column.sort.direction)) {
        sorting += `${column.fieldKey} ${mfPortalsSortTypesToMfPortalsSortingTypes(column.sort.direction).toString()} `;
      }
    });

    const sortString = sorting.trimEnd();

    if (mfStringIsEmptyOrWhitespace(sortString)) {
      return;
    }

    return sortString;
  }
}
