import { ContentChild, Directive, Inject, Input } from "@angular/core";
import { MfTableColumnDef } from "@material-framework/table/row/column/table.column.def.directive";
import { MfTableHeaderCellActionsModelConfig, MfTableColumnModelConfigHeaderActionsDef } from "@material-framework/table/row/header/table.row.header.cell.actions.model.config.def.directive";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";


@Directive({
  selector: "[mfTableColumnModelConfigDef]",
})
export class MfTableColumnModelConfigDef extends MfTableColumnDef {
  @ContentChild(MfTableHeaderCellActionsModelConfig)
  public headerCellActions!: MfTableHeaderCellActionsModelConfig;

  @ContentChild(MfTableColumnModelConfigHeaderActionsDef)
  public headerCellActionsDef!: MfTableColumnModelConfigHeaderActionsDef;

  @Input("mfTableColumnModelConfigDef")
  public fieldPath?: string;

  public constructor(
    @Inject(MF_TABLE_CONFIG_TOKEN)
    public override config: MfTableConfig
  ) {
    super(config);
  }
}