import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { booleanAttribute, Component, EventEmitter, Inject, Injector, Input, Output, ViewEncapsulation } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfTableFooterLeftDef } from "@material-framework/table/footer/table.footer.left.def.directive";
import { MfTableFooterRightDef } from "@material-framework/table/footer/table.footer.right.def.directive";
import { MfTablePagination } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableFooterComponent" };

@Component({

  selector: "mf-table-footer",
  templateUrl: "table.footer.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableFooterComponent extends MfBaseComponent {
  @Output()
  public onPageChange: EventEmitter<PageEvent> = new EventEmitter();

  @Input()
  public pagination?: MfTablePagination;

  @Input()
  public footerLeftDef?: MfTableFooterLeftDef;

  @Input()
  public footerRightDef?: MfTableFooterRightDef;

  @Input({ transform: booleanAttribute })
  public get paginationEnabled(): boolean {
    return this._paginationEnabled;
  }
  public set paginationEnabled(value: BooleanInput) {
    this._paginationEnabled = coerceBooleanProperty(value);
  }

  protected _paginationEnabled: boolean = true;

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN) public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }
}