import { Component, EventEmitter, Inject, Injector, Input, Output, ViewEncapsulation } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MF_CLEAR_VALUE_BUTTON_CONFIG_TOKEN, MfClearValueButtonConfig } from "@material-framework/clearValueButton/clear.value.button.config";
import { MfTypeInfo } from "@material-framework/common/type.info";

const TYPE_INFO: MfTypeInfo = { className: "MfClearValueButtonComponent" };

@Component({
  selector: "mf-clear-value-button",
  templateUrl: "clear.value.button.component.html",
  styleUrls: ["clear.value.button.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfClearValueButtonComponent extends MfBaseComponent {
  @Input()
  public fontIcon?: string;

  @Input()
  public fontSet?: string;

  @Input()
  public label?: string;

  @Output()
  public onClearClick: EventEmitter<MouseEvent> = new EventEmitter();

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_CLEAR_VALUE_BUTTON_CONFIG_TOKEN) protected _config: MfClearValueButtonConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected _onClicked(event: MouseEvent): void {
    this.onClearClick.emit(event);
  }
}