import { Component, Inject, Injector, ViewEncapsulation } from "@angular/core";
import { MfAuthService } from "@material-framework/auth/auth.service";
import { MF_AUTH_LANDING_CONFIG_TOKEN, MfAuthLandingConfig } from "@material-framework/auth/landing/auth.landing.config";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";

const TYPE_INFO: MfTypeInfo = { className: "MfAuthLoggedOutComponent" };

@Component({
  selector: "mf-logged-out",
  templateUrl: "./auth.logged.out.component.html",
  styleUrls: ["./auth.logged.out.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfAuthLoggedOutComponent extends MfBaseComponent {
  public constructor(
    protected override _injector: Injector,
    protected _authService: MfAuthService,
    @Inject(MF_AUTH_LANDING_CONFIG_TOKEN)
    protected _config: MfAuthLandingConfig,
  ) {
    super(TYPE_INFO, _injector);
  }
}