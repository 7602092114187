import { Component, ElementRef, Inject, Injector, Input, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from "@angular/core";
import { merge } from "rxjs";
import { Overlay } from "@angular/cdk/overlay";
import { MF_ANIMATION_FADE_IN, MF_ANIMATION_FADE_OUT } from "@material-framework/animations/animations";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfSnackBarMessage, MfSnackBarMfErrorMessage, MfSnackBarMessageTypes } from "@material-framework/snackbar/snackbar";
import { MfSnackBarMessageTypeConfig, MF_SNACKBAR_CONFIG_TOKEN, MfSnackBarConfig } from "@material-framework/snackbar/snackbar.config";
import { MfSnackBarService } from "@material-framework/snackbar/snackbar.service";

const TYPE_INFO: MfTypeInfo = { className: "MfMSnackBarComponent" };

@Component({
  selector: "mf-snackbar",
  templateUrl: "snackbar.component.html",
  styleUrls: ["snackbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    MF_ANIMATION_FADE_IN,
    MF_ANIMATION_FADE_OUT,
  ]
})
export class MfSnackBarComponent extends MfBaseComponent {
  @Input()
  public get selectedMessage(): MfSnackBarMessage | MfSnackBarMfErrorMessage | undefined {
    return this._selectedMessage;
  }
  public set selectedMessage(value: MfSnackBarMessage | MfSnackBarMfErrorMessage | undefined) {
    this._setSelectedMessage(value);
    this._resetIndex();
    if (mfTypeIsUndefined(this._selectedMessage)) {
      delete this.selectedIndex;
    }
  }

  @Input()
  public selectedIndex?: number = 0;

  @ViewChild("extendedInfoOverlayTemplate", { static: true })
  protected _extendedInfoOverlayTemplate!: TemplateRef<unknown>;

  @ViewChild("snackbarContainer", { static: true, read: ElementRef })
  protected _snackbarContainer!: ElementRef;

  protected _snackBarMessageTypes = MfSnackBarMessageTypes;
  protected _selectedMessageTypeConfig?: MfSnackBarMessageTypeConfig;
  protected _canFirst = false;
  protected _canPrevious = false;
  protected _canNext = false;
  protected _canLast = false;
  protected _selectedMessage?: MfSnackBarMessage;
  protected _extendedInfoIsOpen = false;

  public constructor(
    protected override _injector: Injector,
    protected _snackBarService: MfSnackBarService,
    protected _viewContainerRef: ViewContainerRef,
    protected _overlay: Overlay,
    @Inject(MF_SNACKBAR_CONFIG_TOKEN)
    protected _config: MfSnackBarConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._sub(merge(this._snackBarService.onMessageAdded, this._snackBarService.onMessageRemoved), {
      next: () => this._resetIndex()
    });
  }

  protected get _selectedMfErrorMessage(): MfSnackBarMfErrorMessage | undefined {
    return this._selectedMessage as MfSnackBarMfErrorMessage;
  }

  protected _openExtendedInfo(): void {
    this._extendedInfoIsOpen = !this._extendedInfoIsOpen;
  }

  protected _firstMessage(): void {
    this._setSelectedMessage(this._snackBarService.message[0]);
    this.selectedIndex = 0;
    this._setCanIndex();
  }

  protected _previousMessage(): void {
    if (!mfTypeIsUndefined(this.selectedIndex) && this.selectedIndex > 0) {
      this.selectedIndex = this.selectedIndex - 1;
      this._setSelectedMessage(this._snackBarService.message[this.selectedIndex]);
      this._setCanIndex();
    }
  }

  protected _nextMessage(): void {
    if (!mfTypeIsUndefined(this.selectedIndex) && this.selectedIndex <= (this._snackBarService.messageCount - 2)) {
      this.selectedIndex = this.selectedIndex + 1;
      this._setSelectedMessage(this._snackBarService.message[this.selectedIndex]);
      this._setCanIndex();
    }
  }

  protected _lastMessage(): void {
    this.selectedIndex = this._snackBarService.messageCount - 1;
    this._setSelectedMessage(this._snackBarService.message[this.selectedIndex]);
    this._setCanIndex();
  }

  protected _deleteMessage(): void {
    if (!mfTypeIsUndefined(this._selectedMessage)) {
      this._snackBarService.deleteMessage(this._selectedMessage);
    }
  }

  protected _setSelectedMessage(message?: MfSnackBarMessage): void {
    this._selectedMessage = message;
    this._setSelectedMessageTypeConfig();
  }

  protected _resetIndex(): void {
    if (!mfTypeIsUndefined(this._selectedMessage)) {
      const index = this._snackBarService.message.findIndex((i) => i === this._selectedMessage);
      if (index != -1) {
        this.selectedIndex = index;
      } else {
        this.selectedIndex = 0;
        this._selectedMessage = this._snackBarService.message[this.selectedIndex];
      }
      this._setCanIndex();
    }
  }

  protected _setCanIndex(): void {
    if (!mfTypeIsUndefined(this.selectedIndex)) {
      this._canFirst = this._canPrevious = this.selectedIndex > 0;
      this._canLast = this._canNext = this.selectedIndex < (this._snackBarService.messageCount - 1);
    }
  }

  protected _setSelectedMessageTypeConfig(): void {
    if (!mfTypeIsUndefined(this._selectedMessage)) {
      this._selectedMessageTypeConfig = this._config.types[this._selectedMessage.type];
    }
  }
}