import { Component, Injector } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";

const TYPE_INFO: MfTypeInfo = { className: "MfFillRouterOutletComponent" };

@Component({
  selector: "mf-fill-router-outlet",
  templateUrl: "./fill.router.outlet.component.html",
})
export class MfFillRouterOutletComponent extends MfBaseComponent {
  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }
}