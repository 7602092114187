import { Component, Injector, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { faHouse, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfIcon } from "@material-framework/icon/icon";
import { MfIconTypes } from "@material-framework/icon/icon.types";

const TYPE_INFO: MfTypeInfo = { className: "MfIconComponent" };

@Component({
  selector: "mf-icon",
  templateUrl: "icon.component.html",
  styleUrls: ["icon.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfIconComponent extends MfBaseComponent implements OnInit {
  @Input()
  public get icon(): MfIcon | undefined {
    return this._icon;
  }
  public set icon(value: MfIcon | undefined) {
    this._icon = value;
    if (mfTypeIsUndefined(this._icon)) {
      delete this._faIcon;
      delete this._matIcon;
    } else {
      this._setIcons();
    }
  }

  @Input()
  public size?: number;

  @Input()
  public color?: string;

  protected _faIcon?: IconDefinition;
  protected _matIcon?: string;
  protected _iconTypes = MfIconTypes;
  protected _faHouse = faHouse;
  protected _icon?: MfIcon | undefined;

  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    this._setIcons();
  }

  protected _setIcons(): void {
    if (!mfTypeIsNullOrUndefined(this._icon)) {
      switch (this._icon.type) {
        case MfIconTypes.fontAwesome:
          this._faIcon = this._icon.icon as IconDefinition;
          break;
        case MfIconTypes.materialIcons:
          this._matIcon = this._icon.icon as string;
          break;
      }
    }
  }
}