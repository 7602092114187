import { A11yModule } from "@angular/cdk/a11y";
import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BrowserModule } from "@angular/platform-browser";
import { MfLoadingComponent } from "@material-framework/loading/loading.component";
import { MF_LOADING_CONFIG_TOKEN, MF_LOADING_DEFAULT_CONFIG } from "@material-framework/loading/loading.config";
import { MfLoadingService } from "@material-framework/loading/loading.service";

@NgModule({
  declarations: [
    MfLoadingComponent,
  ],
  imports: [
    BrowserModule,
    MatProgressSpinnerModule,
    A11yModule,
  ],
  providers: [
    { provide: MF_LOADING_CONFIG_TOKEN, useValue: MF_LOADING_DEFAULT_CONFIG },
    MfLoadingService,
  ]
})
export class MfLoadingModule {
}