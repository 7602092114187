import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Component, ComponentRef, ElementRef, EventEmitter, Inject, Injector, Input, OnInit, Output, ViewChild, ViewContainerRef, ViewEncapsulation, booleanAttribute } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPathValue } from "@material-framework/common/utils/object.utils";
import { mfStringIsEmptyOrWhitespace } from "@material-framework/common/utils/string.utils";
import { mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfig, MfModelConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfUserPersona, MfUserPersonaCardAction } from "@material-framework/userPersona/user.persona";
import { MfUserPersonaCardComponent } from "@material-framework/userPersona/user.persona.card.component";
import { MF_USER_PERSONA_CONFIG_TOKEN, MfUserPersonaConfig } from "@material-framework/userPersona/user.persona.config";


const TYPE_INFO: MfTypeInfo = { className: "MfUserPersonaComponent" };

@Component({
  selector: "mf-user-persona",
  templateUrl: "user.persona.component.html",
  styleUrls: ["user.persona.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfUserPersonaComponent extends MfBaseComponent implements OnInit {
  @ViewChild("container")
  public container?: ElementRef;

  @Input()
  public actions?: MfUserPersonaCardAction[];

  @Input()
  public persona?: MfUserPersona;

  @Input()
  public modelConfig?: MfModelConfigMapped | MfModelConfig;

  @Input()
  public get model(): MfModelBase | undefined {
    return this._model;
  }
  public set model(value: MfModelBase | undefined) {
    this._model = value;
    this._getUserPersona();
  }

  @Input({ transform: booleanAttribute })
  public get infoCardEnabled(): boolean {
    return this._infoCardEnabled;
  }
  public set infoCardEnabled(value: BooleanInput) {
    this._infoCardEnabled = coerceBooleanProperty(value);
  }

  @Output()
  public onActionClicked: EventEmitter<MfUserPersonaCardAction> = new EventEmitter();

  protected _infoCardIsOpen = false;
  protected _model?: MfModelBase | undefined;
  protected _infoCardEnabled = false;
  protected _infoCardOverlayRef?: OverlayRef;
  protected _infoCardPortal?: ComponentPortal<MfUserPersonaCardComponent>;
  protected _infoCardRef?: ComponentRef<MfUserPersonaCardComponent>;
  protected _infoCard?: MfUserPersonaCardComponent;

  public constructor(
    protected override _injector: Injector,
    protected _overlay: Overlay,
    protected _viewContainerRef: ViewContainerRef,
    @Inject(MF_USER_PERSONA_CONFIG_TOKEN) public config: MfUserPersonaConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    this._getUserPersona();
  }

  protected _toggleInfoCard(): void {
    if (this.infoCardEnabled === true) {
      if (this._infoCardIsOpen) {
        this._closeInfoCard();
      } else {
        this._openInfoCard();
      }
    }
  }

  protected _getUserPersona(): void {
    if (!mfTypeIsNullOrUndefined(this.model) && !mfTypeIsUndefined(this.modelConfig) && !mfTypeIsUndefined(this.modelConfig.userPersona)) {
      const mappings = this.modelConfig.userPersona.mappings;
      this.persona = { userId: "", displayName: "" };

      if (!mfTypeIsUndefined(mappings.idMap)) {
        const userIdRawValue = mfObjectGetPropertyPathValue<string>(mappings.idMap, this.model);
        if (!mfTypeIsNullOrUndefined(userIdRawValue)) {
          this.persona.userId = userIdRawValue;
        }
      }
      if (!mfTypeIsUndefined(mappings.displayNameMap)) {
        const displayNameRawValue = mfObjectGetPropertyPathValue<string>(mappings.displayNameMap, this.model);
        if (!mfTypeIsNullOrUndefined(displayNameRawValue)) {
          this.persona.displayName = displayNameRawValue;
        }
      }
      if (!mfTypeIsUndefined(mappings.emailMap)) {
        const emailRawValue = mfObjectGetPropertyPathValue<string>(mappings.emailMap, this.model);
        if (!mfTypeIsNullOrUndefined(emailRawValue)) {
          this.persona.eMail = emailRawValue;
        }
      }
      if (!mfTypeIsUndefined(mappings.photoMap)) {
        const photoRawValue = mfObjectGetPropertyPathValue<string>(mappings.photoMap, this.model);
        if (!mfTypeIsNullOrUndefined(photoRawValue) && !mfStringIsEmptyOrWhitespace(photoRawValue)) {
          this.persona.photo = photoRawValue;
        }
      }
      if (!mfTypeIsUndefined(mappings.jobTitleMap)) {
        const jobTitleRawValue = mfObjectGetPropertyPathValue<string>(mappings.jobTitleMap, this.model);
        if (!mfTypeIsNullOrUndefined(jobTitleRawValue)) {
          this.persona.jobTitle = jobTitleRawValue;
        }
      }
      if (!mfTypeIsUndefined(mappings.initialsMap)) {
        const initialsRawValue = mfObjectGetPropertyPathValue<string>(mappings.initialsMap, this.model);
        if (!mfTypeIsNullOrUndefined(initialsRawValue)) {
          this.persona.initials = initialsRawValue;
        }
      }

    }
  }

  protected _getInfoCardConfigOverlay(container: ElementRef): OverlayConfig {
    const positionStrategy = this._overlay
      .position()
      .flexibleConnectedTo(container)
      .withLockedPosition()
      .withViewportMargin(0)
      .withGrowAfterOpen(false)
      .withPush(false)
      .withPositions([{
        originX: "start",
        originY: "top",
        overlayX: "start",
        overlayY: "top",
      }]);

    return new OverlayConfig({
      scrollStrategy: this._overlay.scrollStrategies.block(),
      panelClass: ["mf-user-persona-info-card-panel"],
      positionStrategy: positionStrategy,
      width: container.nativeElement.offsetWidth,
    });
  }

  protected _openInfoCard(): void {
    if (this.infoCardEnabled === true && mfTypeIsUndefined(this._infoCardOverlayRef) && !mfTypeIsUndefined(this.container)) {

      const overlayConfig = this._getInfoCardConfigOverlay(this.container);

      this._infoCardOverlayRef = this._overlay.create(overlayConfig);
      this._infoCardPortal = new ComponentPortal(MfUserPersonaCardComponent, this._viewContainerRef, this._injector);
      this._infoCardRef = this._infoCardOverlayRef.attach(this._infoCardPortal);
      this._infoCard = this._infoCardRef.instance;

      this._infoCardRef.setInput("actions", this.actions);

      this._sub(this._infoCardRef.instance.onMouseLeave, { next: () => this._closeInfoCard() });
      this._sub(this._infoCardRef.instance.onActionClicked, { next: (action) => this._actionClicked(action) });
      this._sub(this._infoCardRef.instance.onPersonaClicked, { next: () => this._closeInfoCard() });

      this._infoCardIsOpen = true;
    }
  }

  protected _closeInfoCard(): void {
    if (this.infoCardEnabled === true && !mfTypeIsUndefined(this._infoCardOverlayRef)) {
      this._infoCardOverlayRef.dispose();
      delete this._infoCardOverlayRef;
      this._infoCardIsOpen = false;
    }
  }

  protected _actionClicked(action: MfUserPersonaCardAction): void {
    if (mfTypeIsUndefined(action.disableCloseOnClick) || action.disableCloseOnClick === false) {
      this._closeInfoCard();
    }
    this.onActionClicked.emit(action);
  }
}