import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable, Injector } from "@angular/core";
import { MF_PORTALS_CONFIG_TOKEN, MfPortalsConfig } from "@material-framework/portals/portals.config";
import { default as mime } from "mime";
import { catchError, concatMap, map, Observable, of, share } from "rxjs";
import { MfAuthService } from "../auth/auth.service";
import { MfBaseService } from "../base/base.service";
import { MfError, MfErrorHttpResponseError } from "../common/error/error";
import { MfTypeInfo } from "../common/type.info";
import { mfStringIsEmptyOrWhitespace } from "../common/utils/string.utils";
import { mfTypeGetKeys, mfTypeHasOwnProperty, mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "../common/utils/type.utils";
import { mfFunctionRefGetRegisteredFunctionRef } from "../functionRef/function.ref";
import { mfRetryHTTPRequest, MfRetryHTTPRequestOptions } from "../httpClient/http.retry.utils";
import { MfModelBase } from "../model/model.base";
import { MfModelConfigMapped } from "../modelConfig/model.config";
import { MfModelConfigValueFormatterService } from "../modelConfig/model.config.value.formatter.service";
import { MfPortalsCollectionResponseData, MFPortalsFileBlob, MfPortalsRequestCollectionData } from "../portals/portals";

const TYPE_INFO: MfTypeInfo = { className: "MfPortalsClientService" };


@Injectable()
export class MfPortalsClientService extends MfBaseService {
  protected _forceLogin$?: Observable<unknown>;

  public constructor(
    protected override _injector: Injector,
    protected _httpClientService: HttpClient,
    protected _authService: MfAuthService,
    protected _modelConfigValueFormatterService: MfModelConfigValueFormatterService,
    @Inject(MF_PORTALS_CONFIG_TOKEN)
    protected _config: MfPortalsConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public deleteItemDelete<TResponseModel extends MfModelBase>(
    modelConfig: MfModelConfigMapped,
    url: string | undefined):
    Observable<TResponseModel> {
    if (mfTypeIsNullOrUndefined(url) || mfStringIsEmptyOrWhitespace(url)) {
      throw new MfError(this.typeInfo, "deleteItemDelete", "Portals data source url is not set");
    }

    const requestOptions: { withCredentials?: boolean } = {};

    const retryOptions = { retriesCount: 0 };

    const isUrlAuthenticated = this._authService.isUrlAuthenticated(url);
    if (!isUrlAuthenticated) {
      requestOptions.withCredentials = true;
    }

    return this._httpClientService.delete<TResponseModel>(url, requestOptions).pipe(
      map((response) => {
        this._modelConfigValueFormatterService.formateModelsValues(response, modelConfig);
        return response;
      }),
      catchError((error, caught) => this._errorHandler(error, "deleteItemDelete", caught, isUrlAuthenticated, retryOptions)),
    );
  }

  public updateItemPut<TResponseModel extends MfModelBase, TPostModel extends MfModelBase>(
    responseModelConfig: MfModelConfigMapped,
    postModelConfig: MfModelConfigMapped,
    postModel: TPostModel | undefined,
    url: string | undefined):
    Observable<TResponseModel> {
    if (mfTypeIsNullOrUndefined(url) || mfStringIsEmptyOrWhitespace(url)) {
      throw new MfError(this.typeInfo, "updateItemPut", "Portals data source url is not set");
    }

    const requestOptions: { withCredentials?: boolean } = {};

    const retryOptions = { retriesCount: 0 };

    const isUrlAuthenticated = this._authService.isUrlAuthenticated(url);
    if (!isUrlAuthenticated) {
      requestOptions.withCredentials = true;
    }

    if (!mfTypeIsUndefined(postModel)) {
      this._modelConfigValueFormatterService.formateModelsValues<TPostModel>(postModel, postModelConfig);
    }

    return this._httpClientService.put<TResponseModel>(url, postModel, requestOptions).pipe(
      map((response) => {
        this._modelConfigValueFormatterService.formateModelsValues(response, responseModelConfig);
        return response;
      }),
      catchError((error, caught) => this._errorHandler(error, "updateItemPut", caught, isUrlAuthenticated, retryOptions)),
    );
  }

  public updateItemPost<TResponseModel extends MfModelBase, TPostModel extends MfModelBase>(
    responseModelConfig: MfModelConfigMapped,
    postModelConfig: MfModelConfigMapped,
    postModel: TPostModel | undefined,
    url: string | undefined):
    Observable<TResponseModel> {
    if (mfTypeIsNullOrUndefined(url) || mfStringIsEmptyOrWhitespace(url)) {
      throw new MfError(this.typeInfo, "updateItemPost", "Portals data source url is not set");
    }

    const requestOptions: { withCredentials?: boolean } = {};

    const retryOptions = { retriesCount: 0 };

    const isUrlAuthenticated = this._authService.isUrlAuthenticated(url);
    if (!isUrlAuthenticated) {
      requestOptions.withCredentials = true;
    }

    if (!mfTypeIsUndefined(postModel)) {
      this._modelConfigValueFormatterService.formateModelsValues<TPostModel>(postModel, postModelConfig);
    }

    return this._httpClientService.post<TResponseModel>(url, postModel, requestOptions).pipe(
      map((response) => {
        this._modelConfigValueFormatterService.formateModelsValues(response, responseModelConfig);
        return response;
      }),
      catchError((error, caught) => this._errorHandler(error, "updateItemPost", caught, isUrlAuthenticated, retryOptions)),
    );
  }

  public getFileBlobPost<TPostModel extends MfModelBase>(
    postModelConfig: MfModelConfigMapped,
    postModel: TPostModel | undefined,
    url: string | undefined):
    Observable<MFPortalsFileBlob> {
    if (mfTypeIsNullOrUndefined(url) || mfStringIsEmptyOrWhitespace(url)) {
      throw new MfError(this.typeInfo, "getFileBlobPost", "Portals data source url is not set");
    }

    const requestOptions: { withCredentials?: boolean, body?: unknown, responseType: "blob", observe: "response" } = {
      body: postModel,
      observe: "response",
      responseType: "blob",
    };

    const retryOptions = { retriesCount: 0 };

    const isUrlAuthenticated = this._authService.isUrlAuthenticated(url);
    if (!isUrlAuthenticated) {
      requestOptions.withCredentials = true;
    }

    if (!mfTypeIsUndefined(postModel)) {
      this._modelConfigValueFormatterService.formateModelsValues<TPostModel>(postModel, postModelConfig);
    }

    return this._httpClientService.request("post", url, requestOptions).pipe(
      map((response) => {
        const contentType = response.headers.get("content-type");
        if (!mfTypeIsNullOrUndefined(contentType)) {
          const extension = mime.getExtension(contentType);
          if (!mfTypeIsNullOrUndefined(extension)) {
            return {
              blob: response.body,
              extension,
            } as MFPortalsFileBlob;
          }
          throw new MfError(this._typeInfo, "getFileBlobPost", `No mime file extension mapping for contentType: ${contentType}`);
        }
        throw new MfError(this._typeInfo, "getFileBlobPost", `no content-type header returned. contentType: ${contentType}`);
      }),
      catchError((error, caught) => this._errorHandler(error, "getFileBlobPost", caught, isUrlAuthenticated, retryOptions)),
    );
  }

  public getItemPost<TResponseModel extends MfModelBase, TPostModel extends MfModelBase>(
    responseModelConfig: MfModelConfigMapped,
    postModelConfig: MfModelConfigMapped,
    postModel: TPostModel | undefined,
    url: string | undefined):
    Observable<TResponseModel> {
    if (mfTypeIsNullOrUndefined(url) || mfStringIsEmptyOrWhitespace(url)) {
      throw new MfError(this.typeInfo, "getItemPost", "Portals data source url is not set");
    }

    const requestOptions: { withCredentials?: boolean } = {};

    const retryOptions = { retriesCount: 0 };

    const isUrlAuthenticated = this._authService.isUrlAuthenticated(url);
    if (!isUrlAuthenticated) {
      requestOptions.withCredentials = true;
    }

    if (!mfTypeIsUndefined(postModel)) {
      this._modelConfigValueFormatterService.formateModelsValues<TPostModel>(postModel, postModelConfig);
    }

    return this._httpClientService.post(url, postModel, requestOptions).pipe(
      map((response) => {
        this._modelConfigValueFormatterService.formateModelsValues(response, responseModelConfig);
        return response as TResponseModel;
      }),
      catchError((error, caught) => this._errorHandler(error, "getItemPost", caught, isUrlAuthenticated, retryOptions)),
    );
  }

  public post<TPostModel extends MfModelBase>(
    postModelConfig: MfModelConfigMapped,
    postModel: TPostModel | undefined,
    url: string | undefined):
    Observable<boolean> {
    if (mfTypeIsNullOrUndefined(url) || mfStringIsEmptyOrWhitespace(url)) {
      throw new MfError(this.typeInfo, "getItemPost", "Portals data source url is not set");
    }

    const requestOptions: { withCredentials?: boolean } = {};

    const retryOptions = { retriesCount: 0 };

    const isUrlAuthenticated = this._authService.isUrlAuthenticated(url);
    if (!isUrlAuthenticated) {
      requestOptions.withCredentials = true;
    }

    if (!mfTypeIsUndefined(postModel)) {
      this._modelConfigValueFormatterService.formateModelsValues<TPostModel>(postModel, postModelConfig);
    }

    return this._httpClientService.post(url, postModel, requestOptions).pipe(
      map(() => true),
      catchError((error, caught) => this._errorHandler(error, "getItemPost", caught, isUrlAuthenticated, retryOptions)),
    );
  }

  public getItemGet<TResponseModel extends MfModelBase>(
    responseModelConfig: MfModelConfigMapped,
    url: string | undefined):
    Observable<TResponseModel> {
    if (mfTypeIsNullOrUndefined(url) || mfStringIsEmptyOrWhitespace(url)) {
      throw new MfError(this.typeInfo, "getItem", "Portals data source url is not set");
    }

    const requestOptions: { withCredentials?: boolean } = {};

    const retryOptions = { retriesCount: 0 };

    const isUrlAuthenticated = this._authService.isUrlAuthenticated(url);
    if (!isUrlAuthenticated) {
      requestOptions.withCredentials = true;
    }

    return this._httpClientService.get<TResponseModel>(url, requestOptions).pipe(
      map((response) => {
        this._modelConfigValueFormatterService.formateModelsValues(response, responseModelConfig);
        return response;
      }),
      catchError((error, caught) => this._errorHandler(error, "getItem", caught, isUrlAuthenticated, retryOptions)),
    );
  }

  public getCollectionGet<TResponseModel extends MfModelBase, TResponseModel1 extends MfModelBase | undefined = undefined, TResponseModel2 extends MfModelBase | undefined = undefined, TResponseModel3 extends MfModelBase | undefined = undefined>(
    responseModelConfig: MfModelConfigMapped,
    url: string | undefined
  ):
    Observable<MfPortalsCollectionResponseData<TResponseModel, TResponseModel1, TResponseModel2, TResponseModel3>> {

    const mockResponse = this._getCollectionPostMockData<TResponseModel, TResponseModel1, TResponseModel2, TResponseModel3>(responseModelConfig);
    if (!mfTypeIsUndefined(mockResponse)) {
      return mockResponse;
    }

    if (mfTypeIsNullOrUndefined(url) || mfStringIsEmptyOrWhitespace(url)) {
      throw new MfError(this.typeInfo, "getCollection", "Portals data source url is not set");
    }

    const requestOptions: { withCredentials?: boolean } = {};

    const retryOptions = { retriesCount: 0 };

    const isUrlAuthenticated = this._authService.isUrlAuthenticated(url);
    if (!isUrlAuthenticated) {
      requestOptions.withCredentials = true;
    }

    return this._httpClientService.get<MfPortalsCollectionResponseData<TResponseModel, TResponseModel1, TResponseModel2, TResponseModel3>>(url, requestOptions).pipe(
      map((response) => {
        this._formatCollectionResponse(response, responseModelConfig);
        return response;
      }),
      catchError((error, caught) => this._errorHandler(error, "getCollection", caught, isUrlAuthenticated, retryOptions)),
    );
  }

  public getCollectionPost<TResponseModel extends MfModelBase, TPostModel extends MfPortalsRequestCollectionData, TResponseModel1 extends MfModelBase | undefined = undefined, TResponseModel2 extends MfModelBase | undefined = undefined, TResponseModel3 extends MfModelBase | undefined = undefined>(
    responseModelConfig: MfModelConfigMapped,
    url: string | undefined,
    postModel: TPostModel,
    postModelConfig?: MfModelConfigMapped):
    Observable<MfPortalsCollectionResponseData<TResponseModel, TResponseModel1, TResponseModel2, TResponseModel3>> {

    const mockResponse = this._getCollectionPostMockData<TResponseModel, TResponseModel1, TResponseModel2, TResponseModel3>(responseModelConfig);
    if (!mfTypeIsUndefined(mockResponse)) {
      return mockResponse;
    }

    if (mfTypeIsNullOrUndefined(url) || mfStringIsEmptyOrWhitespace(url)) {
      throw new MfError(this.typeInfo, "postCollection", "Portals data source url is not set");
    }

    const postOptions: { withCredentials?: boolean } = {};

    const retryOptions = { retriesCount: 0 };

    const isUrlAuthenticated = this._authService.isUrlAuthenticated(url);
    if (!isUrlAuthenticated) {
      postOptions.withCredentials = true;
    }

    this._prepRequestCollectionData(postModel);

    if (!mfTypeIsUndefined(postModelConfig) && !mfTypeIsUndefined(postModel)) {
      this._modelConfigValueFormatterService.formateModelsValues<TPostModel>(postModel, postModelConfig);
    }

    return this._httpClientService.post<MfPortalsCollectionResponseData<TResponseModel, TResponseModel1, TResponseModel2, TResponseModel3>>(url, postModel, postOptions).pipe(
      map((response) => {
        this._formatCollectionResponse(response, responseModelConfig);
        return response;
      }),
      catchError((error, caught) => this._errorHandler(error, "postCollection", caught, isUrlAuthenticated, retryOptions)),
    );
  }

  protected _formatCollectionResponse<
    TResponseModel extends MfModelBase,
    TResponseModel1 extends MfModelBase | undefined = undefined,
    TResponseModel2 extends MfModelBase | undefined = undefined,
    TResponseModel3 extends MfModelBase | undefined = undefined>(
      response: MfPortalsCollectionResponseData<TResponseModel, TResponseModel1, TResponseModel2, TResponseModel3>,
      modelConfig: MfModelConfigMapped
    ): void {
    const resultsKeys = mfTypeGetKeys(response).filter(i => i !== "filter" && i !== "page" && i !== "pageSize" && i !== "sort" && i !== "select");
    resultsKeys.forEach(resultsKey => {
      if (mfTypeHasOwnProperty(response, resultsKey) && !mfTypeIsNullOrUndefined(response[resultsKey])) {
        this._modelConfigValueFormatterService.formateModelsValues(response[resultsKey], modelConfig);
      }
    });
  }

  protected _getCollectionPostMockData<TResponseModel extends MfModelBase, TResponseModel1 extends MfModelBase | undefined = undefined, TResponseModel2 extends MfModelBase | undefined = undefined, TResponseModel3 extends MfModelBase | undefined = undefined>(
    modelConfig: MfModelConfigMapped):
    Observable<MfPortalsCollectionResponseData<TResponseModel, TResponseModel1, TResponseModel2, TResponseModel3>> | undefined {
    if (!mfTypeIsUndefined(modelConfig.portals) && !mfTypeIsUndefined(modelConfig.portals.mock)) {
      if (!mfTypeIsUndefined(modelConfig.portals.mock.response)) {
        return of(modelConfig.portals.mock.response as MfPortalsCollectionResponseData<TResponseModel, TResponseModel1, TResponseModel2, TResponseModel3>);
      } else if (!mfTypeIsUndefined(modelConfig.portals.mock.generator) && !mfTypeIsUndefined(modelConfig.portals.mock.generator.getValueFunctionPointer)) {
        const funcRef = mfFunctionRefGetRegisteredFunctionRef(modelConfig.portals.mock.generator.getValueFunctionPointer);
        if (funcRef.isObservable === true) {
          return funcRef.function(this._injector) as Observable<MfPortalsCollectionResponseData<TResponseModel, TResponseModel1, TResponseModel2, TResponseModel3>>;
        } else {
          return of(funcRef.function(this._injector) as MfPortalsCollectionResponseData<TResponseModel, TResponseModel1, TResponseModel2, TResponseModel3>);
        }
      }
    }
    return undefined;
  }

  protected _prepRequestCollectionData(data: MfPortalsRequestCollectionData): void {
    if (mfTypeIsUndefined(data.filter)) {
      data.filter = "";
    }

    if (mfTypeIsUndefined(data.sort)) {
      data.sort = "";
    }

    if (mfTypeIsUndefined(data.select)) {
      data.select = "";
    }
  }


  protected _errorHandler<T>(error: Error, methodName: string, caught: Observable<T>, isUrlAuthenticated: boolean, retryOptions: MfRetryHTTPRequestOptions): Observable<T> {
    if (error instanceof HttpErrorResponse) {
      if (isUrlAuthenticated === true) {
        switch (error.status) {
          case 401: //Unauthorized
            if (mfTypeIsUndefined(this._forceLogin$)) {
              this._authService.clearCredentials();
              this._forceLogin$ = of(true).pipe(
                concatMap(() => {
                  return this._authService.runInitialLoginSequence().pipe(
                    concatMap(() => {
                      delete this._forceLogin$;
                      if (this._authService.hasValidAccessToken()) {
                        return caught;
                      } else {
                        const mfError = new MfErrorHttpResponseError(this._typeInfo, methodName, error.status, "HTTP 401 Forbidden from portals API - force login", error);
                        mfError.hideFromSnackBar = true;

                        throw mfError;
                      }
                    })
                  );
                }),
                share(),
              );
              return this._forceLogin$ as Observable<T>;
            }
            const mfError = new MfErrorHttpResponseError(this._typeInfo, methodName, error.status, "HTTP 401 Forbidden from portals API", error);
            mfError.hideFromSnackBar = true;

            throw mfError;
          case 403: //Forbidden
            throw new MfErrorHttpResponseError(this._typeInfo, methodName, error.status, "HTTP 403 Forbidden from portals API - you don't have access", error);
          default:
            return mfRetryHTTPRequest(this._typeInfo, error, methodName, caught, this._config.httpRetry, retryOptions);
        }
      }
    }

    throw new MfError(this._typeInfo, methodName, error.message, error);
  }
}