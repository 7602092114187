import { Inject, Injectable, Injector } from "@angular/core";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPathValue, mfObjectGetPropertyPathValueAsDate, mfObjectPropertyPathIsUndefined, mfObjectSetPropertyPathValue } from "@material-framework/common/utils/object.utils";
import { mfTypeIsArray, mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFunctionRefService } from "@material-framework/functionRef/function.ref.service";
import { MfModelBase } from "@material-framework/model/model.base";
import { MF_MODEL_DEFAULTS_CONFIG_TOKEN, MfModelConfigMapped, MfModelDefaultsConfig, MfModelFieldConfigMapped, mfModelFieldDataTypeIsNumber, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import * as moment from "moment";

const TYPE_INFO: MfTypeInfo = { className: "MfModelConfigValueFormatterService" };

/**
 * @class
 * @extends MfBaseService
 * @description
 * The `MfModelConfigValueFormatterService` class is responsible for formatting and updating the values of model fields based on their configuration.
 * It handles operations such as zeroing out null or undefined number fields and updating calculated values within models.
 */
@Injectable()
export class MfModelConfigValueFormatterService extends MfBaseService {

  /**
   * @constructor
   * @param {Injector} _injector - The Angular injector used to inject dependencies.
   * @param {MfModelConfigService} _modelConfigService - The service used to retrieve and manipulate model configurations.
   * @param {MfFunctionRefService} _functionRefService - The service used to execute function references for calculated fields.
   * @param {MfModelDefaultsConfig} config - The default configuration settings for models.
   */
  public constructor(
    protected override _injector: Injector,
    protected _modelConfigService: MfModelConfigService,
    protected _functionRefService: MfFunctionRefService,
    @Inject(MF_MODEL_DEFAULTS_CONFIG_TOKEN) public config: MfModelDefaultsConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  /**
   * @method zeroNullOrUndefinedNumberFields
   * @description
   * Sets the value of null or undefined number fields (integer and decimal types) to zero within a model based on its configuration.
   * If the field is an array of objects, it recursively processes each object.
   * @template TModel
   * @param {MfModelConfigMapped} modelConfig - The mapped model configuration.
   * @param {TModel} model - The model instance to process.
   */
  public zeroNullOrUndefinedNumberFields<TModel extends MfModelBase>(modelConfig: MfModelConfigMapped, model: TModel): void {
    this._modelConfigService.forEachModelFieldRecursive(modelConfig.fields, (modelFieldConfig) => {
      if (mfModelFieldDataTypeIsNumber(modelFieldConfig.dataType.type)) {
        const numberValue = mfObjectGetPropertyPathValue<number>(modelFieldConfig.fieldPath);
        if (mfTypeIsNullOrUndefined(numberValue)) {
          mfObjectSetPropertyPathValue(modelFieldConfig.fieldPath, model, 0);
        }
      } else if (modelFieldConfig.dataType.type === MfModelFieldDataTypes.array) {
        if (modelFieldConfig.dataType.arrayItemType === MfModelFieldDataTypes.object) {
          const items = mfObjectGetPropertyPathValue<MfModelBase[]>(modelFieldConfig.fieldPath);
          if (!mfTypeIsUndefined(items)) {
            const itemsLength = items.length;
            for (let itemIndex = 0; itemIndex < itemsLength; itemIndex++) {
              if (!mfTypeIsUndefined(modelFieldConfig.model)) {
                this.zeroNullOrUndefinedNumberFields(modelFieldConfig.model, items[itemIndex]);
              }
            }
          }
        }
      }
    });
  }

  /**
   * @method formateModelsValues
   * @description
   * Formats the values of a model or an array of models based on the model configuration. It updates calculated fields by evaluating their associated functions.
   * @template TModel
   * @param {TModel | TModel[]} model - The model or array of models to process.
   * @param {MfModelConfigMapped} modelConfig - The mapped model configuration.
   */
  public formateModelsValues<TModel extends MfModelBase>(model: TModel | TModel[], modelConfig: MfModelConfigMapped) {
    if (mfTypeIsArray(model)) {
      const length = model.length;
      for (let index = 0; index < length; index++) {
        this._updateCalculatedModel(model[index], modelConfig);
      }
    } else {
      this._updateCalculatedModel(model, modelConfig);
    }
  }

  /**
   * @method _updateCalculatedModel
   * @protected
   * @description
   * Updates the calculated values within a model based on its configuration by evaluating the associated function pointers.
   * @template TModel
   * @param {TModel} model - The model instance to update.
   * @param {MfModelConfigMapped} modelConfig - The mapped model configuration.
   */
  protected _updateCalculatedModel<TModel extends MfModelBase>(model: TModel, modelConfig: MfModelConfigMapped): void {
    this._modelConfigService.forEachModelFieldRecursive(
      modelConfig.fields,
      (modelFieldConfig) => {
        this._updateCalculatedModelValues(model, modelFieldConfig);
        this._updateModelValues(model, modelFieldConfig);
      },
      {
        selector: (model, modelFieldConfig) => {
          return !mfObjectPropertyPathIsUndefined(modelFieldConfig.fieldPath, model);
        },
        model: model,
      }
    );
  }

  /**
   * @method _updateCalculatedModelValues
   * @protected
   * @description
   * Updates the value of a calculated field within a model by executing its associated function and setting the result.
   * @template TModel
   * @param {TModel} model - The model instance containing the field to update.
   * @param {MfModelFieldConfigMapped} modelFieldConfig - The configuration of the model field to update.
   */
  protected _updateCalculatedModelValues<TModel extends MfModelBase>(model: TModel, modelFieldConfig: MfModelFieldConfigMapped): void {
    if (!mfTypeIsUndefined(modelFieldConfig.calculated)) {
      const subFieldPath = this._modelConfigService.removeLastFieldPathSegment(modelFieldConfig.fieldPath);
      const funcPassModel = mfTypeIsUndefined(subFieldPath) ? model : mfObjectGetPropertyPathValue<MfModelBase>(subFieldPath, model);

      if (!mfTypeIsUndefined(funcPassModel)) {
        this._functionRefService.getValueForModel(funcPassModel, modelFieldConfig.calculated.getValueFunctionPointer).subscribe({
          next: (value) => mfObjectSetPropertyPathValue(modelFieldConfig.fieldPath, model, value)
        });
      }
    }
  }
  /**
   * Updates the model values based on the specified field configuration.
   * 
   * This method checks the data type of the field as defined in the `modelFieldConfig`.
   * If the field type is a date, it ensures that the date is formatted as "YYYY-MM-DD"
   * before setting it on the model. The method handles both `moment` objects and 
   * raw date values.
   * 
   * @template TModel - The type of the model, which must extend `MfModelBase`.
   * 
   * @param {TModel} model - The model instance whose values are to be updated.
   * @param {MfModelFieldConfigMapped} modelFieldConfig - The configuration of the model field, 
   * which defines how the field should be updated, including its data type and field path.
   * 
   * @protected
   * @memberof MfTableComponent
   */
  protected _updateModelValues<TModel extends MfModelBase>(model: TModel, modelFieldConfig: MfModelFieldConfigMapped): void {
    if (!mfTypeIsUndefined(modelFieldConfig.dataType.valueFormatter) && !mfTypeIsUndefined(modelFieldConfig.dataType.valueFormatter.converter)) {
      mfObjectSetPropertyPathValue(modelFieldConfig.fieldPath, model, modelFieldConfig.dataType.valueFormatter.converter(mfObjectGetPropertyPathValue(modelFieldConfig.fieldPath, model)));
      return;
    }

    switch (modelFieldConfig.dataType.type) {
      case MfModelFieldDataTypes.date:
        const rawDateValue = mfObjectGetPropertyPathValueAsDate(modelFieldConfig.fieldPath, model);

        const format = modelFieldConfig.dataType.valueFormatter?.dataTypes?.date?.format || this.config.dataTypes.date.format;

        if (!mfTypeIsNullOrUndefined(rawDateValue)) {
          if (moment.isMoment(rawDateValue)) {
            mfObjectSetPropertyPathValue(modelFieldConfig.fieldPath, model, rawDateValue.format(format));
          } else {
            mfObjectSetPropertyPathValue(modelFieldConfig.fieldPath, model, moment(rawDateValue).format(format));
          }
        }

        break;
    }
  }
}
