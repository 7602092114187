import { EComPriceBreakModel } from "@e-commerce/modelConfigs/price.break.model.config";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";

export type EComItemDetailModel = MfModelBase & {
  itemKey: string,
  itemCode: string,
  itemShortCode: string,
  itemDescription: string,
  unitOfMeasure: string;
  itemSpecKey: string,
  descriptions: string[];
  priceBreaks: EComPriceBreakModel[];
}

export type EComItemDetailFieldsConfig = {
  [key in keyof Required<EComItemDetailModel>]: MfModelFieldConfig
};

export const ECOM_ITEM_DETAIL_MODEL_FIELDS_CONFIG: EComItemDetailFieldsConfig = {
  itemKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemShortCode: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  itemDescription: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  unitOfMeasure: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemSpecKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  descriptions: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.string,
    },
  },
  priceBreaks: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.object,
    },
  }
};

export const ECOM_ITEM_DETAIL_MODEL_CONFIG_ID = "itemDetail";

export const ECOM_ITEM_DETAIL_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ITEM_DETAIL_MODEL_CONFIG_ID,
  fields: ECOM_ITEM_DETAIL_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ITEM_DETAIL_MODEL_CONFIG",
  },
  portals: {}
};