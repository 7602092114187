import { InjectionToken } from "@angular/core";

export const MF_DATE_PICKER_CONFIG_TOKEN = new InjectionToken<MfDatePickerConfig>("MfDatePickerConfig");
export const MF_DATE_PICKER_DEFAULT_CONFIG: MfDatePickerConfig = {

};


export type MfDatePickerConfig = {

}