import { PercentPipe } from "@angular/common";
import { Inject, InjectionToken, Pipe, PipeTransform } from "@angular/core";

export const MF_PERCENT_PIPE_CONFIG_TOKEN = new InjectionToken<MfPercentPipeConfig>("MfPercentPipeConfig");
export const MF_PERCENT_PIPE_DEFAULT_CONFIG: MfPercentPipeConfig = {
  digitsInfo: "1.0-0",
};

export type MfPercentPipeConfig = {
  digitsInfo: string;
  locale?: string,
}

@Pipe({ name: "mfPercent" })
export class MfPercentPipe implements PipeTransform {
  public constructor(
    protected _percentPipe: PercentPipe,
    @Inject(MF_PERCENT_PIPE_CONFIG_TOKEN)
    protected _config: MfPercentPipeConfig,
  ) { }

  public transform(value: string | number | null | undefined) {
    return this._percentPipe.transform(value, this._config.digitsInfo, this._config.locale);
  }
}