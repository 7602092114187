import { Component, EventEmitter, Inject, Injector, Input, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfFilterBaseComponent } from "@material-framework/filter/base/filter.base.component";
import { MfFilterExpression } from "@material-framework/filter/filter";
import { MF_FILTER_CONFIG_TOKEN, MfFilterConfig } from "@material-framework/filter/filter.config";
import { MfFilterOperatorSelectComponent } from "@material-framework/filter/operatorSelect/filter.operator.select.component";
import { MfFilterService } from "@material-framework/filter/services/filter.service";
import { MfFilterValueInputComponent } from "@material-framework/filter/valueInput/filter.value.input.component";
import { MfModelFieldConfig, MfModelFieldsConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";

const TYPE_INFO: MfTypeInfo = { className: "MfFilterExpressionComponent" };

@Component({
  selector: "mf-filter-expression",
  templateUrl: "filter.expression.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfFilterExpressionComponent extends MfFilterBaseComponent {
  @ViewChild("operatorSelect")
  public operatorSelect?: MfFilterOperatorSelectComponent;

  @ViewChild("valueInput")
  public valueInput?: MfFilterValueInputComponent;

  @Input()
  public expression?: MfFilterExpression;

  @Input()
  public modelFieldConfig?: MfModelFieldConfig;

  @Input()
  public modelFieldsConfig?: MfModelFieldsConfigMapped;

  @Input()
  public singleFieldFilter = false;

  @Input()
  public singleFieldPath?: string;

  @Output()
  public onRemoveExpression: EventEmitter<MfFilterExpression> = new EventEmitter();

  @Output()
  public onExpressionChange: EventEmitter<MfFilterExpression> = new EventEmitter();

  @Output()
  public onEnterKey: EventEmitter<boolean> = new EventEmitter();

  public constructor(
    protected override _injector: Injector,
    protected _modelConfigService: MfModelConfigService,
    protected _filterService: MfFilterService,
    @Inject(MF_FILTER_CONFIG_TOKEN)
    protected _config: MfFilterConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected _onFieldChanged(expression: MfFilterExpression): void {
    this.valueInput?.reset();
    this.operatorSelect?.reset();
    this.onExpressionChange.emit(expression);
  }

  protected _onOperatorChanged(expression: MfFilterExpression): void {
    this.valueInput?.reset(false);
    this.onExpressionChange.emit(expression);
  }

  protected _onValueChange(expression: MfFilterExpression): void {
    this.onExpressionChange.emit(expression);
  }

  protected _onRemoveExpressionClicked(): void {
    this.onRemoveExpression.emit(this.expression);
  }
}