import { animate, AnimationTriggerMetadata, style, transition, trigger } from "@angular/animations";

export const MF_ANIMATION_FADE_IN: AnimationTriggerMetadata = trigger("fadeIn", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("200ms ease-in", style({
      opacity: 1
    })),
  ]),
]);

export const MF_ANIMATION_FADE_OUT: AnimationTriggerMetadata = trigger("fadeOut", [
  transition(":leave", [
    style({ opacity: 1 }),
    animate("200ms ease-out", style({
      opacity: 0
    })),
  ]),
]);