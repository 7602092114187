import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MfIconComponent } from "@material-framework/icon/icon.component";
import { MF_ICON_CONFIG, MF_ICON_CONFIG_TOKEN } from "@material-framework/icon/icon.config";

@NgModule({
  declarations: [
    MfIconComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MatIconModule,
    FontAwesomeModule,
  ],
  exports: [
    MfIconComponent,
  ],
  providers: [
    { provide: MF_ICON_CONFIG_TOKEN, useValue: MF_ICON_CONFIG },
  ]
})
export class MfIconModule {
}