<div>
  <mf-filter (onAddExpression)="onAddExpression($event)"
             (onAddGroup)="onAddGroup($event)"
             (onExpressionChange)="onExpressionChange($event)"
             (onFilterChange)="onFilterChangeHandler($event)"
             (onGroupChange)="onGroupChange($event)"
             (onRemoveExpression)="onRemoveExpression($event)"
             (onRemoveGroup)="onRemoveGroup($event)"
             (onEnterKey)="onFilterEnterKey.emit($event)"
             [group]="column.filter!.group"
             [singleFieldPath]="column.fieldPath"
             [singleFieldFilter]="true"
             [modelFieldConfig]="column.modelFieldConfig"></mf-filter>
</div>