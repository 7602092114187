import { NgModule } from "@angular/core";
import { MfLocalStorageService } from "@material-framework/storage/local.storage.service";
import { MfSessionStorageService } from "@material-framework/storage/session.storage.service";

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  exports: [
  ],
  providers: [
    MfLocalStorageService,
    MfSessionStorageService,
  ]
})
export class MfStorageModule {
}