import { NgModule } from "@angular/core";
import { MfModelConfigModule } from "@material-framework/modelConfig/model.config.module";
import { MfPortalsFilterConverterService } from "@material-framework/portals/filter/portals.filter.converter.service";
import { MfPortalsClientService } from "@material-framework/portals/portals.client.service";
import { MF_PORTALS_CONFIG_TOKEN, MF_PORTALS_DEFAULT_CONFIG } from "@material-framework/portals/portals.config";

@NgModule({
  imports: [
    MfModelConfigModule,
  ],
  providers: [
    { provide: MF_PORTALS_CONFIG_TOKEN, useValue: MF_PORTALS_DEFAULT_CONFIG },
    MfPortalsFilterConverterService,
    MfPortalsClientService,
  ],
})
export class MfPortalsModule {
}