<div [formGroup]="_formGroup">
  <mat-form-field>
    <input #input
           matInput
           [readonly]="true"
           (dateChange)="_changed($event)"
           [ngxMatDatetimePicker]="picker"
           [formControl]="_inputFormControl"
           [min]="_min">
    <ngx-mat-datepicker-toggle matSuffix
                               [for]="picker"></ngx-mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker
                             [hideTime]="true">
    </ngx-mat-datetime-picker>
    <mat-error *ngIf="_inputFormControl.invalid">{{_errorMessages[_inputName]}}</mat-error>
  </mat-form-field>
</div>