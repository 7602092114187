import { PercentPipe } from "@angular/common";
import { Component, Inject, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPathValue } from "@material-framework/common/utils/object.utils";
import { mfTypeIsNull } from "@material-framework/common/utils/type.utils";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfTableRowCellBaseFormatterComponent } from "@material-framework/table/row/cell/value/table.row.cell.base.formatter.component";
import { MfTableRowCellFormatterBaseOptions } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowCellPercentageFormatterComponent" };

export type MfTableRowCellPercentageFormatterOptions = MfTableRowCellFormatterBaseOptions & {

}

@Component({
  selector: "mf-table-row-cell-percentage-formatter-component",
  templateUrl: "table.row.cell.base.formatter.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowCellPercentageFormatterComponent<TModel extends MfModelBase> extends MfTableRowCellBaseFormatterComponent<MfTableRowCellPercentageFormatterOptions, TModel> implements OnInit {
  public constructor(
    protected override _injector: Injector,
    protected _percentPipe: PercentPipe,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    const value = this._percentPipe.transform(mfObjectGetPropertyPathValue<number>(this.fieldColumn?.fieldPath, this.rowItem));
    this._displayValue = mfTypeIsNull(value) ? "" : value;
  }
}