<div class="ecom-order-documentation-container">
  <div *ngIf="_isLoading"
       class="mf-default-margin-top">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="!_isLoading">
    <table>
      <tr *ngFor="let document of _documents">
        <td class="mf-large-padding-right">
          {{ document.name}}
        </td>
        <td>
          <button mat-icon-button
                  (click)="_onDownloadClicked(document)"
                  color="primary">
            <mf-icon [icon]="_iconsConfig.downloadIcon"></mf-icon>
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>