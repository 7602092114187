import { Injectable, Injector } from "@angular/core";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComCheckoutSummeryModel } from "@e-commerce/modelConfigs/checkout.summery.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComCartService } from "@e-commerce/services/cart.service";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfModelConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfPortalsClientService } from "@material-framework/portals/portals.client.service";
import { concatMap, Observable } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComCheckoutService" };

@Injectable({ providedIn: "root" })
export class EComCheckoutService extends MfBaseService {
  protected _checkoutSummeryModelConfig: MfModelConfigMapped;

  public constructor(
    protected override _injector: Injector,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    protected _customerService: EComCustomerService,
    protected _cartService: EComCartService,
  ) {
    super(TYPE_INFO, _injector);
    this._checkoutSummeryModelConfig = this._modelConfigService.get<EComModelsConfig>("checkoutSummery");
  }

  public get summery(): Observable<EComCheckoutSummeryModel> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this._cartService.id.pipe(
          concatMap((cartId) => {
            return this._portalsClientService.getItemGet<EComCheckoutSummeryModel>(
              this._checkoutSummeryModelConfig,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${selectedCustomer.key}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/${cartId}/summary`
            );
          }),
        );
      }),
    );
  }
}