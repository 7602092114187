import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { booleanAttribute, Component, Injector, Input, OnInit } from "@angular/core";
import { EComCustomerModel } from "@e-commerce/modelConfigs/customer.model.config";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfSelectOption } from "@material-framework/select/select.option";

const TYPE_INFO: MfTypeInfo = { className: "EComCartComponent" };

@Component({
  selector: "ecom-account-select",
  templateUrl: "./account.select.component.html",
  styleUrls: ["./account.select.component.scss"]
})
export class EComAccountSelectComponent extends MfBaseComponent implements OnInit {
  @Input({ transform: booleanAttribute })
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }

  protected _options?: MfSelectOption<string>[];
  protected _accounts?: EComCustomerModel[];
  protected _selectedId?: string;
  protected _selectedAccount?: EComCustomerModel;
  protected _disabled: boolean = false;

  public constructor(
    protected override _injector: Injector,
    protected _customerService: EComCustomerService,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    this._getAccounts();
    this._setSelectedAccount();
  }

  protected _optionChanged(options: MfSelectOption<string> | undefined): void {
    if (!mfTypeIsUndefined(options)) {
      this._sub(this._customerService.setSelectedById(options.value));
    }
  }

  protected _getAccounts(): void {
    this._sub(this._customerService.customers, {
      next: (accounts) => {
        this._options = accounts.map(account => ({ value: account.key, label: account.displayName }));
      }
    });
  }

  protected _onSelectedChange(selectedAccount: EComCustomerModel): void {
    this._selectedId = selectedAccount.key;
    this._selectedAccount = selectedAccount;
  }

  protected _setSelectedAccount(): void {
    this._sub(this._customerService.selected, {
      next: (selectedAccount) => {
        this._selectedId = selectedAccount?.key;
        this._selectedAccount = selectedAccount;
      }
    });
  }
}