<div class="mf-table-simple-search-container">
  <mf-filter-value-input #filterValueInput
                         [disabled]="isLoading"
                         [placeholder]="_modelFieldConfig?.display?.displayName"
                         (onEnterKey)="_onSearch()"
                         [expression]="_expression"></mf-filter-value-input>
  <button mat-icon-button
          [matTooltip]="'Search by ' + _modelFieldConfig?.display?.displayName"
          [disabled]="isLoading"
          (click)="_onSearch()">
    <mf-icon [icon]="_config.searchIcon"></mf-icon>
  </button>
</div>