import { Directive, EventEmitter, Injector, Input, Output } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfTableRowCellBaseFormatter } from "@material-framework/table/row/cell/value/Table.row.cell.base.formatter";
import { MfTableFieldColumn, MfTableRowCellValueBaseOptions } from "@material-framework/table/table";

@Directive()
export abstract class MfTableRowCellBaseFormatterComponent<
  TOptions extends MfTableRowCellValueBaseOptions,
  TModel extends MfModelBase = MfModelBase> extends MfBaseComponent implements MfTableRowCellBaseFormatter<TOptions, TModel> {
  @Input()
  public get options(): TOptions | undefined {
    return this._options;
  }
  public set options(value: TOptions | undefined) {
    this._options = value;
  }

  @Input()
  public get fieldColumn(): MfTableFieldColumn | undefined {
    return this._fieldColumn;
  }
  public set fieldColumn(value: MfTableFieldColumn | undefined) {
    this._fieldColumn = value;
  }

  @Input()
  public get rowItem(): TModel | undefined {
    return this._rowItem;
  }
  public set rowItem(value: TModel | undefined) {
    this._rowItem = value;
  }

  @Output()
  public requireTableReload: EventEmitter<boolean> = new EventEmitter();

  public isEditor = false;
  public isFormatter = true;

  protected _displayValue = "";
  protected _options?: TOptions | undefined;
  protected _fieldColumn?: MfTableFieldColumn | undefined;
  protected _rowItem?: TModel | undefined;

  public constructor(
    protected override _typeInfo: MfTypeInfo,
    protected override _injector: Injector,
  ) {
    super(_typeInfo, _injector);
  }
}