import { ComponentRef, EventEmitter, Inject, Injectable, Injector, Type } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MF_ROOT_CONFIG_TOKEN, MfRootConfig } from "@material-framework/root/root.config";


const TYPE_INFO: MfTypeInfo = { className: "MfRootSideDrawerService" };

export class MfSideDrawerRef<TComponent extends MfBaseComponent> {
  public component: Type<TComponent>;
  public componentRef?: ComponentRef<TComponent>;

  public onAfterOpened: EventEmitter<MfSideDrawerRef<TComponent>> = new EventEmitter();
  public onAfterClosed: EventEmitter<MfSideDrawerRef<TComponent>> = new EventEmitter();
  public onBeforeClosed: EventEmitter<MfSideDrawerRef<TComponent>> = new EventEmitter();

  public constructor(protected _component: Type<TComponent>) {
    this.component = this._component;
  }

  public close(): void { }
}

@Injectable()
export class MfRootSideDrawerService extends MfBaseService {
  public onOpenRightSideDrawer: EventEmitter<MfSideDrawerRef<any>> = new EventEmitter(true);

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_ROOT_CONFIG_TOKEN) public config: MfRootConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public open<TComponent extends MfBaseComponent>(component: Type<TComponent>): MfSideDrawerRef<TComponent> {
    const sideDrawerRef = new MfSideDrawerRef<TComponent>(component);
    this.onOpenRightSideDrawer.emit(sideDrawerRef);
    return sideDrawerRef;
  }
}