import { MfAuthRouteNames } from "@material-framework/auth/auth.route.names";
import { MfAuthLoggedOutComponent } from "@material-framework/auth/landing/auth.logged.out.component";
import { MfAuthLogInComponent } from "@material-framework/auth/landing/auth.login.component";
import { MfAuthRedirectComponent } from "@material-framework/auth/landing/auth.redirect.component";
import { MfRoutes } from "@material-framework/router/route";


export const MF_UNAUTHN_ROUTES: MfRoutes = [
  {
    path: MfAuthRouteNames.partNameRedirect,
    component: MfAuthRedirectComponent,
  },
  {
    path: MfAuthRouteNames.partNameLoggedOut,
    component: MfAuthLoggedOutComponent,
  },
  {
    path: MfAuthRouteNames.partNameLogIn,
    component: MfAuthLogInComponent,
  }
];
