import { Injectable, Injector } from "@angular/core";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComItemDetailModel } from "@e-commerce/modelConfigs/item.detail.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComRequestQuoteModel } from "@e-commerce/modelConfigs/quote.model.config";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfStringIsEmptyOrWhitespace } from "@material-framework/common/utils/string.utils";
import { mfTypeIsNullOrUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfPortalsClientService } from "@material-framework/portals/portals.client.service";
import { Observable, concatMap } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComProductService" };

@Injectable({ providedIn: "root" })
export class EComProductService extends MfBaseService {

  protected _itemDetailModelConfig: MfModelConfigMapped;
  protected _requestQuoteModelConfig: MfModelConfigMapped;

  public constructor(
    protected override _injector: Injector,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    protected _customerService: EComCustomerService,
  ) {
    super(TYPE_INFO, _injector);
    this._itemDetailModelConfig = this._modelConfigService.get<EComModelsConfig>("itemDetail");
    this._requestQuoteModelConfig = this._modelConfigService.get<EComModelsConfig>("requestQuote");
  }

  public getProductImageUrl(itemDetail: EComItemDetailModel | undefined): string {
    if (!mfTypeIsNullOrUndefined(itemDetail) && !mfTypeIsNullOrUndefined(itemDetail.itemCode) && !mfStringIsEmptyOrWhitespace(itemDetail.itemCode)) {
      return `${ECOM_ENVIRONMENT.productImageUrl}${itemDetail.itemCode}`;
    } else {
      return "/assets/product-placeholder-wp.jpg";
    }
  }

  public getProductDetail(itemKeyOrSpecKey: string): Observable<EComItemDetailModel> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this._portalsClientService.getItemGet<EComItemDetailModel>(
          this._itemDetailModelConfig,
          `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${selectedCustomer.key}/${ECOM_ENVIRONMENT.portalsStocksUrl}/${itemKeyOrSpecKey}`
        );
      }),
    );
  }

  public requestQuote(itemSpecKey: string, itemKey: string, quantity: number): Observable<boolean> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        const request: EComRequestQuoteModel = {
          key: selectedCustomer.key,
          itemSpecKey,
          itemKey,
          quantity,
        };

        return this._portalsClientService.post<EComRequestQuoteModel>(
          this._requestQuoteModelConfig,
          request,
          `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsRequestForQuoteUrl}`
        );
      }),
    );
  }
}