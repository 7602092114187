import { Directive, Input } from "@angular/core";

@Directive({
  selector: "mf-select-option",
})
export class MfSelectOptionDirective<TOptionValue>  {
  @Input()
  public value?: TOptionValue;

  @Input()
  public label?: string;
}