<ng-template #actionTemplate
             let-action>
  <container-element [ngSwitch]="true">
    <mat-list-item (click)="_actionClicked(action)"
                   matRipple
                   *ngSwitchCase="action.type === _actionTypes.action">
      <div class="mf-action">
        <div>{{action.title}}</div>
        <div class="mf-flex-fill"></div>
        <mf-icon class="mf-action-icon"
                 *ngIf="!_isUndefined(action.icon)"
                 [icon]="action.icon"></mf-icon>
      </div>
    </mat-list-item>
    <mat-divider *ngSwitchCase="action.type === _actionTypes.divider"></mat-divider>
  </container-element>
</ng-template>

<div class="mf-user-persona-card-container"
     @fadeIn
     @fadeOut
     (mouseover)="_mouseOver($event)"
     (mouseleave)="_mouseLeave($event)">
  <div class="mf-persona-fill"
       (click)="_onPersonaClick()">

  </div>
  <div class="mf-actions">
    <mat-list role="list">
      <ng-template ngFor
                   let-action
                   [ngForOf]="actions">
        <ng-template *ngTemplateOutlet="actionTemplate; context: {$implicit: action}"></ng-template>
      </ng-template>
    </mat-list>
  </div>
</div>