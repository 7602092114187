<div class="mf-select-container"
     [attr.aria-labelledby]="!_isNullOrUndefined(formField) ? formField.getLabelId() : ''"
     (focusin)="_onFocusIn()"
     (focusout)="_onFocusOut($event)">
  <div *ngIf="!_isNullOrUndefined(label)"
       class="mf-select-label">
    {{ label }}:
  </div>
  <mat-select #select
              [disabled]="disabled"
              [required]="required"
              [value]="value"
              [multiple]="multiple"
              [placeholder]="placeholder"
              (keyup.enter)="onEnterKey.emit(true)"
              (selectionChange)="_onSelectionChange($event)">
    <form [formGroup]="searchForm">
      <div class="mf-select-filter">
        <mat-form-field style="width: 100%;"
                        class="mf-slim-mdc-form-field">
          <input #input
                 class="mf-select-filter-input mdc-text-field__input"
                 matInput
                 [placeholder]="searchPlaceholder"
                 formControlName="value"
                 (keydown)="_onSearchKeydown($event)">
        </mat-form-field>
        <mat-spinner *ngIf="localSpinner"
                     class="mf-select-spinner"
                     diameter="16"></mat-spinner>
        <mf-clear-value-button style="margin-left: 8px;"
                               (onClearClick)="_onClearSearchClicked()"></mf-clear-value-button>
      </div>
      <div *ngIf="noResults"
           class="mf-select-noResultsMessage">
        {{noResultsMessage}}
      </div>
    </form>
    <mat-option style="white-space: nowrap;"
                *ngFor="let option of filteredOptions"
                [value]="option.value">{{ option.label }}</mat-option>
  </mat-select>
  <mf-clear-value-button class="mf-select-clear-button"
                         matSuffix
                         *ngIf="clearEnabled === true && !_isUndefined(select.selected)"
                         (onClearClick)="_onClearValueClicked($event)">
  </mf-clear-value-button>
</div>