import { Component, Inject, Injector, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { EComUserService } from "@e-commerce/services/user.service";
import { MF_AUTH_LANDING_CONFIG_TOKEN, MfAuthLandingConfig } from "@material-framework/auth/landing/auth.landing.config";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";


const TYPE_INFO: MfTypeInfo = { className: "EComNotApprovedComponent" };

@Component({
  selector: "ecom-not-approved",
  templateUrl: "./not.approved.component.html",
  styleUrls: ["./not.approved.component.scss"]
})
export class EComNotApprovedComponent extends MfBaseComponent implements OnInit {
  public constructor(
    protected override _injector: Injector,
    protected _userService: EComUserService,
    protected _router: Router,
    @Inject(MF_AUTH_LANDING_CONFIG_TOKEN)
    protected _config: MfAuthLandingConfig,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    this._userService.isApproved.subscribe({
      next: (isApproved) => {
        if (isApproved === true) {
          this._router.navigate([EcomRouteNames.routeHome]);
        }
      }
    });
  }
}