import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { MfError } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFilterBaseComponent } from "@material-framework/filter/base/filter.base.component";
import { MfFilterExpression, MfFilterGroup } from "@material-framework/filter/filter";
import { MF_FILTER_CONFIG_TOKEN, MfFilterConfig } from "@material-framework/filter/filter.config";
import { MfFilterService } from "@material-framework/filter/services/filter.service";
import { MfModelFieldConfigMapped, MfModelFieldsConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfTableFilterExpressionEvent, MfTableFilterGroupEvent } from "@material-framework/table/table";

const TYPE_INFO: MfTypeInfo = { className: "MfFilterComponent" };

export const MF_QL_FILTER_ID = "mFQlFilterId";

@Component({
  selector: "mf-filter",
  templateUrl: "filter.component.html",
  styleUrls: ["filter.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfFilterComponent extends MfFilterBaseComponent implements OnInit {
  @Input()
  public modelFieldsConfig?: MfModelFieldsConfigMapped;

  @Input()
  public get modelFieldConfig(): MfModelFieldConfigMapped | undefined {
    return this._modelFieldConfig;
  }
  public set modelFieldConfig(value: MfModelFieldConfigMapped | undefined) {
    this._modelFieldConfig = value;
    if (!mfTypeIsUndefined(value) && !mfTypeIsUndefined(this.singleFieldPath) && this.singleFieldFilter === true) {
      this.modelFieldsConfig = { [this.singleFieldPath]: value };
    }
  }

  @Input()
  public singleFieldFilter = false;

  @Input()
  public singleFieldPath?: string;

  @Input()
  public group?: MfFilterGroup;

  @Output()
  public onFilterChange: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onGroupChange: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onRemoveGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onAddGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onExpressionChange: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onAddExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onRemoveExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onEnterKey: EventEmitter<boolean> = new EventEmitter();

  protected _modelFieldConfig?: MfModelFieldConfigMapped | undefined;

  public constructor(
    protected override _injector: Injector,
    protected _modelConfigService: MfModelConfigService,
    protected _filterService: MfFilterService,
    @Inject(MF_FILTER_CONFIG_TOKEN) public config: MfFilterConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    if (this.singleFieldFilter === true && this._isUndefined(this.singleFieldPath)) {
      throw new MfError(this._typeInfo, "ngOnInit", "When set to singleFieldFilter the singleFieldKey must be set with the field to be filtered");
    }
  }

  protected _addExpression(event: MfFilterExpression): void {
    this.onAddExpression.emit({ expression: event });
    if (!mfTypeIsUndefined(this.group)) {
      this.onFilterChange.emit({ group: this.group });
    }
  }

  protected _addGroup(event: MfFilterGroup): void {
    if (!mfTypeIsUndefined(this.group)) {
      this.onAddGroup.emit({ group: event });
      this.onFilterChange.emit({ group: this.group });
    }
  }

  protected _expressionChange(event: MfFilterExpression): void {
    if (!mfTypeIsUndefined(this.group)) {
      this.onExpressionChange.emit({ expression: event });
      this.onFilterChange.emit({ group: this.group });
    }
  }

  protected _groupChange(event: MfFilterGroup): void {
    if (!mfTypeIsUndefined(this.group)) {
      this.onGroupChange.emit({ group: event });
      this.onFilterChange.emit({ group: this.group });
    }
  }

  protected _removeExpression(event: MfFilterExpression): void {
    if (!mfTypeIsUndefined(this.group)) {
      this.onRemoveExpression.emit({ expression: event });
      this.onFilterChange.emit({ group: this.group });
    }
  }

  protected _removeGroup(event: MfFilterGroup): void {
    if (!mfTypeIsUndefined(this.group)) {
      this.onRemoveGroup.emit({ group: event });
      this.onFilterChange.emit({ group: this.group });
    }
  }
}