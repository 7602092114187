<div class="mf-column ecom-checkout-part-container">
  <div class="ecom-page-header">
    <div class="ecom-title">
      Order Review
    </div>
  </div>
  <div class="mf-row">
    <div class="mf-column ecom-split">
      <div class="ecom-shiping-address">
        <mat-card class="ecom-card-left">
          <mat-card-header>
            <mat-card-title>Shipping Address</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mf-column mf-large-margin-top">
              <div>
                UNIT 7 Stoney Gate Road
              </div>
              <div>
                Spondon
              </div>
              <div>
                Derby
              </div>
              <div>
                United Kingdom
              </div>
              <div>
                DE22 5GH
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="ecom-shipping-method">
        <mat-card class="ecom-card-left">
          <mat-card-header>
            <mat-card-title>Shipping Method</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mf-column mf-large-margin-top">
              <div>
                FedEX
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="mf-column ecom-split">
      <div class="ecom-billing-address">
        <mat-card class="ecom-card-right">
          <mat-card-header>
            <mat-card-title>Billing Address</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mf-column mf-large-margin-top">
              <div>
                UNIT 7 Stoney Gate Road
              </div>
              <div>
                Spondon
              </div>
              <div>
                Derby
              </div>
              <div>
                United Kingdom
              </div>
              <div>
                DE22 5GH
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="ecom-purchase-order-reference">
        <mat-card class="ecom-card-right">
          <mat-card-header>
            <mat-card-title>Purchase Order Reference</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mf-column mf-large-margin-top">
              <div>
                PO#12345
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="ecom-Payment-Method">
        <mat-card class="ecom-card-right">
          <mat-card-header>
            <mat-card-title>Payment Method</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mf-column mf-large-margin-top">
              <div>
                Credit Card
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>