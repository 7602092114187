import { Inject, NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { BrowserModule } from "@angular/platform-browser";
import { MfAuthAccessTokenInjectorInterceptor } from "@material-framework/auth/auth.access.token.injector.interceptor";
import { MF_AUTH_CONFIG_DEFAULT, MF_AUTH_CONFIG_TOKEN } from "@material-framework/auth/auth.config";
import { MfAuthGuardService } from "@material-framework/auth/auth.guard.service";
import { MfAuthService } from "@material-framework/auth/auth.service";
import { MfAuthXsrfTokenService } from "@material-framework/auth/auth.xsrf.token.service";
import { MF_AUTH_LANDING_CONFIG_DEFAULT, MF_AUTH_LANDING_CONFIG_TOKEN } from "@material-framework/auth/landing/auth.landing.config";
import { MfAuthLoggedOutComponent } from "@material-framework/auth/landing/auth.logged.out.component";
import { MfAuthLogInComponent } from "@material-framework/auth/landing/auth.login.component";
import { MfAuthRedirectComponent } from "@material-framework/auth/landing/auth.redirect.component";
import { MfErrorModule } from "@material-framework/error/error.module";
import { MfLoadingModule } from "@material-framework/loading/loading.module";
import { MfStorageModule } from "@material-framework/storage/storage.module";
import { AuthConfig } from "angular-oauth2-oidc";

@NgModule({
  declarations: [
    MfAuthRedirectComponent,
    MfAuthLoggedOutComponent,
    MfAuthLogInComponent,
  ],
  exports: [
    MfAuthRedirectComponent,
    MfAuthLoggedOutComponent,
    MfAuthLogInComponent,
  ],
  imports: [
    BrowserModule,
    MfErrorModule,
    MfLoadingModule,
    MfStorageModule,
    MatButtonModule,
  ],
  providers: [
    { provide: MF_AUTH_CONFIG_TOKEN, useValue: MF_AUTH_CONFIG_DEFAULT },
    { provide: MF_AUTH_LANDING_CONFIG_TOKEN, useValue: MF_AUTH_LANDING_CONFIG_DEFAULT },
    MfAuthService,
    MfAuthGuardService,
    MfAuthAccessTokenInjectorInterceptor,
    MfAuthXsrfTokenService,
  ]
})
export class MfAuthModule {
  public constructor(
    protected _authService: MfAuthService,
    @Inject(MF_AUTH_CONFIG_TOKEN)
    public authConfig: AuthConfig,
  ) {
    authConfig.redirectUri = this._authService.getRedirectUri();
  }
}