import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { EComCheckoutSummaryComponent } from "@e-commerce/checkout/summary/checkout.summary.component";
import { MfPipesModule } from "@material-framework/pipes/pipes.module";


@NgModule({
  declarations: [
    EComCheckoutSummaryComponent
  ],
  exports: [
    EComCheckoutSummaryComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    MatButtonModule,
    MfPipesModule,
    MatCheckboxModule,
  ],
  providers: [

  ]
})
export class EComCheckoutSummaryModule { }