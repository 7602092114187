import { EventEmitter, Injectable, Injector } from "@angular/core";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComCustomerDetailModel } from "@e-commerce/modelConfigs/customer.detail.model.config";
import { EComCustomerModel } from "@e-commerce/modelConfigs/customer.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfError } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfPortalsClientService } from "@material-framework/portals/portals.client.service";
import { MfLocalStorageService } from "@material-framework/storage/local.storage.service";
import { Observable, catchError, concatMap, map, of, share } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComCustomerService" };

const ECOM_SELECTED_KEY = "selected";


@Injectable({ providedIn: "root" })
export class EComCustomerService extends MfBaseService {

  public onSelectedChange: EventEmitter<EComCustomerModel> = new EventEmitter();

  protected _customer?: EComCustomerModel;
  protected _customer$?: Observable<EComCustomerModel>;
  protected _customerDetailModelConfig: MfModelConfigMapped;
  protected _customerModelConfig: MfModelConfigMapped;
  protected _customers?: EComCustomerModel[];
  protected _detail?: EComCustomerDetailModel;
  protected _detail$?: Observable<EComCustomerDetailModel>;
  protected _getCustomers$?: Observable<EComCustomerModel[]>;

  public constructor(
    protected override _injector: Injector,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    protected _storageService: MfLocalStorageService,
  ) {
    super(TYPE_INFO, _injector);
    this._customerModelConfig = this._modelConfigService.get<EComModelsConfig>("customer");
    this._customerDetailModelConfig = this._modelConfigService.get<EComModelsConfig>("customerDetail");
  }

  public get hasSelection(): Observable<boolean> {
    return this.selected.pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public get details(): Observable<EComCustomerDetailModel> {
    if (!mfTypeIsUndefined(this._detail)) {
      return of(this._detail);
    } else {
      if (mfTypeIsUndefined(this._detail$)) {
        this._detail$ = this.selected.pipe(
          concatMap((selectedCustomer) => {
            return this._portalsClientService.getItemGet<EComCustomerDetailModel>(this._customerDetailModelConfig, `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCustomerUrl}/${selectedCustomer.key}/details`)
              .pipe(
                map((customerDetail) => {
                  this._detail = customerDetail;
                  return this._detail;
                }),
              );
          }),
          share(),
        );
      }
      return this._detail$;
    }
  }

  public get selected(): Observable<EComCustomerModel> {
    if (!mfTypeIsUndefined(this._customer)) {
      return of(this._customer);
    } else {
      if (mfTypeIsUndefined(this._customer$)) {
        this._customer$ = this.customers.pipe(
          concatMap((customers) => {
            if (customers.length > 0) {
              const selectedStorage = this._storageService.get<EComCustomerModel>(`${this.typeInfo.className}_${ECOM_SELECTED_KEY}`);
              const selectedKey = !mfTypeIsUndefined(selectedStorage) ? selectedStorage.key : customers[0].key;

              return this.setSelectedById(selectedKey).pipe(
                concatMap((result) => {
                  if (result === true) {
                    return of(this._customer as EComCustomerModel);
                  } else {
                    return this.setSelectedById(customers[0].key).pipe(
                      map((result) => {
                        if (result === true) {
                          return this._customer as EComCustomerModel;
                        }
                        throw new MfError(this._typeInfo, "selected", "Unable to set selected customer no default found");
                      })
                    );
                  }
                })
              );
            }

            const error = new MfError(this._typeInfo, "selected", "Unable to get selected customer customers list is empty");
            error.hideFromSnackBar = true;

            throw error;
          }),
          share(),
        );
      }
      return this._customer$;
    }
  }

  public get customers(): Observable<EComCustomerModel[]> {
    if (mfTypeIsUndefined(this._customers)) {
      if (mfTypeIsUndefined(this._getCustomers$)) {
        this._getCustomers$ = this._portalsClientService.getCollectionGet<EComCustomerModel>(this._customerModelConfig, `${ECOM_ENVIRONMENT.portalsUserRootUrl}/${ECOM_ENVIRONMENT.portalsCustomersUrl}`).pipe(
          map((response: any) => {

            if (!mfTypeIsUndefined(response) && !mfTypeIsUndefined(response.Results)) {
              //TODO: hack until case is fixed
              this._customers = response.Results.map((i: any) => ({ key: i.Key, displayName: i.DisplayName, sourceSystem: i.SourceSystem }));

              if (!mfTypeIsUndefined(this._customers)) {
                return this._customers;
              }
            }

            throw new MfError(this._typeInfo, "customers", `Unable to read customers from response ${response}`);
          }),
          share(),
        );
      }

      return this._getCustomers$;
    } else {
      return of(this._customers);
    }
  }

  public setSelected(selectedAccount: EComCustomerModel): Observable<boolean> {
    return this.setSelectedById(selectedAccount.key);
  }

  public setSelectedById(key: string): Observable<boolean> {
    if (mfTypeIsUndefined(this._customer) || (!mfTypeIsUndefined(this._customer) && this._customer.key !== key)) {
      return this.customers.pipe(
        map((accounts) => {
          const selectedAccount = accounts.find(account => account.key === key);
          if (!mfTypeIsUndefined(selectedAccount)) {
            this._storageService.addUpdate(`${this.typeInfo.className}_${ECOM_SELECTED_KEY}`, selectedAccount);
            this._customer = selectedAccount;
            this.onSelectedChange.next(this._customer);
            return true;
          }
          return false;
        }),
      );

    }
    return of(true);
  }
}