// https://developer.paypal.com/api/nvp-soap/payflow/integration-guide/transaction-responses/

// eslint-disable-next-line @typescript-eslint/naming-convention
export const EComPayPalResultTypes = {
  approved: 0,
  userAuthenticationFailed: 1,
  invalidTenderType: 2,
  invalidTransactionType: 3,
  invalidAmount: 4,
  invalidMerchantInformation: 5,
  invalidOrUnsupportedCurrencyCode: 6,
  fieldFormatError: 7,
  notATransactionServer: 8,
  tooManyParametersOrInvalidStream: 9,
  tooManyLineItems: 10,
  clientTimeOutWaitingForResponse: 11,
  declined: 12,
  referral: 13,
  originalTransactionIdNotFound: 19,
  cannotFindTheCustomerReferenceNumber: 20,
  invalidAbaNumber: 22,
  invalidAccountNumber: 23,
  invalidExpirationDate: 24,
  invalidHostMapping: 25,
  invalidVendorAccount: 26,
  insufficientPartnerPermissions: 27,
  insufficientUserPermissions: 28,
  invalidXmlDocument: 29,
  duplicateTransaction: 30,
  errorInAddingTheRecurringProfile: 31,
  errorInModifyingTheRecurringProfile: 32,
  errorInCancelingTheRecurringProfile: 33,
  errorInForcingTheRecurringProfile: 34,
  errorInReactivatingTheRecurringProfile: 35,
  oltpTransactionFailed: 36,
  invalidRecurringProfileId: 37,
  recurringProfileDeactivated: 38,
  insufficientFundsAvailableInAccount: 50,
  exceedsPerTransactionLimit: 51,
  permissionIssue: 52,
  generalError: 99,
  transactionTypeNotSupportedByHost: 100,
  timeOutValueTooSmall: 101,
  processorNotAvailable: 102,
  errorReadingResponseFromHost: 103,
  timeoutWaitingForProcessorResponse: 104,
  creditError: 105,
  hostNotAvailable: 106,
  duplicateSuppressionTimeOut: 107,
  voidTransactionError: 108,
  timeOutWaitingForHostResponse: 109,
  referencedAuthAgainstOrderError: 110,
  captureError: 111,
  failedAvsCheck: 112,
  merchantSaleTotalWillExceedTheDailySalesCapWithCurrentTransaction: 113,
  cardSecurityCodeCscMismatch: 114,
  systemBusyTryAgainLater: 115,
  failedToLockTerminalNumberPleaseTryAgainLater: 116,
  failedMerchantRuleCheck: 117,
  invalidKeywordsFoundInStringFields: 118,
  attemptToReferenceAFailedTransaction: 120,
  notEnabledForFeature: 121,
  merchantSaleTotalWillExceedTheCreditCapWithCurrentTransaction: 122,
  fraudProtectionServicesFilterDeclinedByFilters: 125,
  fraudProtectionServicesFilterFlaggedForReviewByFilters: 126,
  fraudProtectionServicesFilterNotProcessedByFilters: 127,
  fraudProtectionServicesFilterDeclinedByMerchantAfterBeingFlaggedForReviewByFilters: 128,
  cardHasNotBeenSubmittedForUpdate: 132,
  dataMismatchInHttpRetryRequest: 133,
  issuingBankTimedOut: 150,
  issuingBankUnavailable: 151,
  secureTokenAlreadyBeenUsed: 160,
  transactionUsingSecureTokenIsAlreadyInProgress: 161,
  secureTokenExpired: 162,
  fraudulentActivityDetected: 170,
  reauthError: 200,
  orderError: 201,
  genericHostError: 1000,
  buyerAuthenticationServiceUnavailable: 1001,
  buyerAuthenticationServiceTransactionTimeout: 1002,
  buyerAuthenticationServiceInvalidClientVersion: 1003,
  buyerAuthenticationServiceInvalidTimeoutValue: 1004,
  buyerAuthenticationServiceUnavailable1: 1011,
  buyerAuthenticationServiceUnavailable2: 1012,
  buyerAuthenticationServiceUnavailable3: 1013,
  buyerAuthenticationServiceMerchantIsNotEnrolledForBuyerAuthenticationService3DSecure: 1014,
  buyerAuthenticationService3DSecureErrorResponseReceived: 1016,
  buyerAuthenticationService3DSecureErrorResponseIsInvalid: 1017,
  buyerAuthenticationServiceInvalidCardType: 1021,
  buyerAuthenticationServiceInvalidOrMissingCurrencyCode: 1022,
  buyerAuthenticationServiceMerchantStatusFor3DSecureIsInvalid: 1023,
  buyerAuthenticationServiceValidateAuthenticationFailedMissingOrInvalidPares: 1041,
  buyerAuthenticationServiceValidateAuthenticationFailedParesFormatIsInvalid: 1042,
  buyerAuthenticationServiceValidateAuthenticationFailedCannotFindSuccessfulVerifyEnrollment: 1043,
  buyerAuthenticationServiceValidateAuthenticationFailedSignatureValidationFailedForPares: 1044,
  buyerAuthenticationServiceValidateAuthenticationFailedMismatchedOrInvalidAmountInPares: 1045,
  buyerAuthenticationServiceValidateAuthenticationFailedMismatchedOrInvalidAcquirerInPares: 1046,
  buyerAuthenticationServiceValidateAuthenticationFailedMismatchedOrInvalidMerchantIdInPares: 1047,
  buyerAuthenticationServiceValidateAuthenticationFailedMismatchedOrInvalidCardNumberInPares: 1048,
  buyerAuthenticationServiceValidateAuthenticationFailedMismatchedOrInvalidCurrencyCodeInPares: 1049,
  buyerAuthenticationServiceValidateAuthenticationFailedMismatchedOrInvalidXidInPares: 1050,
  buyerAuthenticationServiceValidateAuthenticationFailedMismatchedOrInvalidOrderDateInPares: 1051,
  buyerAuthenticationServiceValidateAuthenticationFailedThisParesWasAlreadyValidatedForAPreviousValidateAuthenticationTransaction: 1052,
} as const;

export type EComPayPalResultTypes = typeof EComPayPalResultTypes[keyof typeof EComPayPalResultTypes];