import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { EComCartPreviewComponent } from "@e-commerce/cartPreview/cart.preview.component";
import { MfIconModule } from "@material-framework/icon/icon.module";


@NgModule({
  declarations: [
    EComCartPreviewComponent
  ],
  exports: [
    EComCartPreviewComponent
  ],
  imports: [
    BrowserModule,
    MfIconModule,
  ],
  providers: [
  ]
})
export class EComCartPreviewModule { }