import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MfInputModule } from "@material-framework/input/input.module";
import { MfTableSimpleSearchComponent } from "@material-framework/table/simpleSearch/table.simple.search.component";
import { MF_TABLE_SIMPLE_SEARCH_CONFIG_TOKEN, MF_TABLE_SIMPLE_SEARCH_DEFAULT_CONFIG } from "@material-framework/table/simpleSearch/table.simple.search.config";
import { MfFilterModule } from "../../filter/filter.module";

@NgModule({
  declarations: [
    MfTableSimpleSearchComponent
  ],
  imports: [
    BrowserModule,
    MatButtonModule,
    MatFormFieldModule,
    MfIconModule,
    MfInputModule,
    MfFilterModule,
    MatTooltipModule,
  ],
  exports: [
    MfTableSimpleSearchComponent
  ],
  providers: [
    { provide: MF_TABLE_SIMPLE_SEARCH_CONFIG_TOKEN, useValue: MF_TABLE_SIMPLE_SEARCH_DEFAULT_CONFIG },
  ]
})
export class MfTableSimpleSearchModule {
}