// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfFilterOperatorSetTypes = {
  boolean: "boolean",
  enum: "enum",
  string: "string",
  comparable: "comparable",
  array: "array",
  none: "none",
  date: "date",
} as const;
export type MfFilterOperatorSetTypes = typeof MfFilterOperatorSetTypes[keyof typeof MfFilterOperatorSetTypes];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfFilterOperatorTypes = {
  eq: "eq",
  neq: "neq",
  in: "in",
  nin: "nin",
  contains: "contains",
  endsWith: "endsWith",
  ncontains: "ncontains",
  nendsWith: "nendsWith",
  nstartsWith: "nstartsWith",
  startsWith: "startsWith",
  gt: "gt",
  gte: "gte",
  lt: "lt",
  lte: "lte",
  ngt: "ngt",
  ngte: "ngte",
  nlt: "nlt",
  nlte: "nlte",
  all: "all",
  none: "none",
  some: "some",
  any: "any",
} as const;
export type MfFilterOperatorTypes = typeof MfFilterOperatorTypes[keyof typeof MfFilterOperatorTypes];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfFilterValueInputTypes = {
  boolean: "boolean",
  date: "date",
  number: "number",
  select: "select",
  string: "string",
  none: "none",
} as const;
export type MfFilterValueInputTypes = typeof MfFilterValueInputTypes[keyof typeof MfFilterValueInputTypes];