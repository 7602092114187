import { Component, Inject, Injector } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { EComCheckoutStepValidationBaseComponent } from "@e-commerce/checkout/base/checkout.step.validation.base.component";
import { EComOrderCheckoutData } from "@e-commerce/checkout/order/checkout.order";
import { EComCartPaymentTypes } from "@e-commerce/common/cart.modes";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { ERROR_MESSAGE_REQUIRED } from "@material-framework/common/validation/validation.const";

const TYPE_INFO: MfTypeInfo = { className: "EComOrderBillingPaymentComponent" };



@Component({
  selector: "ecom-order-billing-payment",
  templateUrl: "./order.billing.payment.component.html",
  styleUrls: ["./order.billing.payment.component.scss"]
})
export class EComOrderBillingPaymentComponent extends EComCheckoutStepValidationBaseComponent<EComOrderCheckoutData> {
  public formGroup: FormGroup = new FormGroup([]);

  protected _purchaseOrderReferenceFormControl = new FormControl();
  protected readonly _purchaseOrderReferenceName = "purchaseOrder" as const;
  protected _paymentTypeFormControl = new FormControl();
  protected readonly _paymentTypeName = "paymentType" as const;

  protected _cartPaymentTypes = EComCartPaymentTypes;

  public constructor(
    protected override _injector: Injector,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._buildFormGroup();
  }

  protected _buildFormGroup(): void {
    this._addFormControlToFormGroup(this.formGroup, this._purchaseOrderReferenceName, this._purchaseOrderReferenceFormControl);
    this._addValidation(this._purchaseOrderReferenceName, this._purchaseOrderReferenceFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._purchaseOrderReferenceName, this._purchaseOrderReferenceFormControl);

    this._addFormControlToFormGroup(this.formGroup, this._paymentTypeName, this._paymentTypeFormControl);
    this._addValidation(this._paymentTypeName, this._paymentTypeFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._paymentTypeName, this._paymentTypeFormControl);
    this._setFormControlValue(this._paymentTypeFormControl, EComCartPaymentTypes.account);


    this._sub(this._purchaseOrderReferenceFormControl.valueChanges, { next: () => this.checkoutData!.purchaseOrderRef = this._purchaseOrderReferenceFormControl.value });
    this._sub(this._paymentTypeFormControl.valueChanges, {
      next: () => {
        this.checkoutData!.paymentMethod = this._paymentTypeFormControl.value;
        if (this.checkoutData!.paymentMethod === EComCartPaymentTypes.creditCard) {
          this.checkoutData!.paymentMethodName = "Credit Card";
        } else {
          this.checkoutData!.paymentMethodName = "Account (Invoice)";
        }
      }
    });
  }

  protected override _onCheckoutModelSet(model: EComOrderCheckoutData): void {
    this._setFormControlValue(this._purchaseOrderReferenceFormControl, model.purchaseOrderRef);
    this._setFormControlValue(this._paymentTypeFormControl, model.paymentMethod);
  }
}