<div class="mf-column ecom-checkout-part-container">
  <div class="ecom-page-header">
    <div class="ecom-title">
      Shipping Method
    </div>
  </div>
  <div [formGroup]="formGroup"
       class="mf-column ecom-form">
    <div class="ecom-field">
      <mat-form-field>
        <mat-label>Shipping Method</mat-label>
        <mf-select id="shipping-method"
                   [options]="_carrierOptions"
                   [value]="_checkoutData?.carrierKey"
                   [formControlName]="_shippingMethodName">
        </mf-select>
        <mat-error *ngIf="_shippingMethodFormControl.invalid">{{_errorMessages[_shippingMethodName]}}</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>