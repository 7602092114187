import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeGetKeys, mfTypeHasOwnProperty, mfTypeIsArray, mfTypeIsObject, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFilterBaseComponent } from "@material-framework/filter/base/filter.base.component";
import { MfFilterExpression } from "@material-framework/filter/filter";
import { MF_FILTER_CONFIG_TOKEN, MfFilterConfig } from "@material-framework/filter/filter.config";
import { MfFilterOperatorSetTypes, MfFilterOperatorTypes } from "@material-framework/filter/filter.types";
import { MfFilterService } from "@material-framework/filter/services/filter.service";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfSelectOption } from "@material-framework/select/select.option";

const TYPE_INFO: MfTypeInfo = { className: "MfFilterOperatorSelectComponent" };

@Component({
  selector: "mf-filter-operator-select",
  templateUrl: "filter.operator.select.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfFilterOperatorSelectComponent extends MfFilterBaseComponent implements OnInit {
  @Input()
  public expression?: MfFilterExpression;

  @Output()
  public onOperatorChanged: EventEmitter<MfFilterExpression> = new EventEmitter();

  public options: MfSelectOption<MfFilterOperatorTypes>[] = [];
  public operatorTypes = MfFilterOperatorSetTypes;

  public constructor(
    protected override _injector: Injector,
    protected _modelConfigService: MfModelConfigService,
    protected _filterService: MfFilterService,
    @Inject(MF_FILTER_CONFIG_TOKEN)
    protected _config: MfFilterConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    this._setOptions();
    this._setDefaultOption();
  }

  public reset(): void {
    this._setOptions();
    this._setDefaultOption();
  }

  protected _onSelectionChange(option: MfSelectOption<MfFilterOperatorTypes> | MfSelectOption<MfFilterOperatorTypes>[] | undefined): void {
    if (!mfTypeIsUndefined(this.expression) && !mfTypeIsUndefined(option) && !mfTypeIsArray(option)) {
      this.expression.operatorKey = option.value;
      this._setSelectedOperator();
      this.onOperatorChanged.emit(this.expression);
    }
  }

  protected _setSelectedOperator(): void {
    if (!mfTypeIsUndefined(this.expression)) {
      if (this.expression.operatorKey === MfFilterOperatorTypes.all ||
        this.expression.operatorKey === MfFilterOperatorTypes.none ||
        this.expression.operatorKey === MfFilterOperatorTypes.some) {
        if (mfTypeIsUndefined(this.expression.subGroup)) {
          this.expression.subGroup = this._filterService.getNewFilterGroup();
        }
      } else {
        delete this.expression.subGroup;
      }
    }
  }

  protected _setOptions(): void {
    if (!mfTypeIsUndefined(this.expression) && !mfTypeIsUndefined(this.expression.modelFieldConfig.filter)) {
      this.options = [];

      const operatorsConfigKeys = mfTypeGetKeys(this._config.operatorsTypes[this.expression.modelFieldConfig.filter.operator.type]);
      const operatorsConfigKeysLength = operatorsConfigKeys.length;
      for (let i = 0; i < operatorsConfigKeysLength; i++) {
        const operatorKey = operatorsConfigKeys[i];

        if (
          !mfTypeIsUndefined(this.expression.modelFieldConfig.filter.exclude) &&
          !mfTypeIsUndefined(this.expression.modelFieldConfig.filter.exclude.operatorsTypes) &&
          mfTypeHasOwnProperty(this.expression.modelFieldConfig.filter.exclude.operatorsTypes, this.expression.modelFieldConfig.dataType.type)
        ) {
          const operatorsType = this.expression.modelFieldConfig.filter.exclude.operatorsTypes[this.expression.modelFieldConfig.dataType.type];
          if (!mfTypeIsUndefined(operatorsType) && mfTypeIsObject(operatorsType) && mfTypeHasOwnProperty(operatorsType, operatorKey) && operatorsType[operatorKey] === true) {
            continue;
          }
        }

        const operatorConfig = this._config.operatorsTypes[this.expression.modelFieldConfig.filter.operator.type][operatorKey] as { displayName: string };
        this.options.push({ value: operatorKey, label: operatorConfig.displayName });
      }

    }
  }

  protected _setDefaultOption(): void {
    if (!mfTypeIsUndefined(this.expression) && !mfTypeIsUndefined(this.expression.modelFieldConfig)) {
      const optionsLength = this.options.length;
      for (let i = 0; i < optionsLength; i++) {
        if (this.options[i].value === this.expression.operatorKey) {
          return;
        }
      }

      const defaultOption = this.options[0];
      if (!mfTypeIsUndefined(defaultOption)) {
        this.expression.operatorKey = defaultOption.value;
        this._setSelectedOperator();
      }
    }
  }
}