import { Component, Inject, Injector, OnInit } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPathValueAsNumber } from "@material-framework/common/utils/object.utils";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfTableRowCellBaseFormatterComponent } from "@material-framework/table/row/cell/value/table.row.cell.base.formatter.component";
import { MfTableRowCellValueBaseOptions } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "EComShelfLifeDaysFormatterComponent" };

export type EComShelfLifeDaysFormatterOptions = MfTableRowCellValueBaseOptions & {
};

@Component({
  selector: "econ-shelf.life.days-cell-formatter-component",
  templateUrl: "shelf.life.days.cell.formatter.component.html",
  styleUrls: ["shelf.life.days.cell.formatter.component.scss"],
})
export class EComShelfLifeDaysFormatterComponent extends MfTableRowCellBaseFormatterComponent<EComShelfLifeDaysFormatterOptions, { shelfLifeDays: number }> implements OnInit {

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    if (!mfTypeIsUndefined(this.fieldColumn)) {
      const numberValue = mfObjectGetPropertyPathValueAsNumber(this.fieldColumn.fieldPath, this.rowItem);
      if (!mfTypeIsUndefined(numberValue)) {
        this._displayValue = numberValue === 0 ? "N/A" : numberValue.toString();
      } else {
        this._displayValue = "N/A";
      }
    }
  }
}
