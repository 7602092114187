import { Component, EventEmitter, Inject, Injector, Input, Output, ViewEncapsulation } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfTableFieldColumn } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowHeaderMenuWidthComponent" };

@Component({
  selector: "mf-table-row-header-menu-width",
  templateUrl: "table.row.header.menu.width.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowHeaderMenuWidthComponent extends MfBaseComponent {
  @Input()
  public column!: MfTableFieldColumn;

  @Output()
  public onWidthChanged: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  public constructor(
    protected _modelConfigService: MfModelConfigService,
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN) public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public onWidthValueChange(event: Event): void {
    if (!mfTypeIsUndefined(this.column) && !mfTypeIsUndefined(this.column.width)) {
      this.column.width.width = parseFloat((event.target as HTMLInputElement).value);
    }
  }
}