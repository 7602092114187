<div class="ecom-order-details-container">
  <table>
    <tr>
      <td>Status</td>
      <td>
        <mf-status-chip [modelFieldConfig]="_orderStatusModelFieldConfig"
                        [model]="order"></mf-status-chip>
      </td>
    </tr>
    <tr>
      <td>Incora Order</td>
      <td>{{ order?.orderNumber }}</td>
    </tr>
    <tr>
      <td>Incora Order Line</td>
      <td>{{ order?.orderLineNumber }}</td>
    </tr>
    <tr>
      <td>Customer Order</td>
      <td>{{ order?.customerOrder }}</td>
    </tr>
    <tr>
      <td>Customer Order Line</td>
      <td>{{ order?.customerOrderLine }}</td>
    </tr>
    <tr>
      <td>Part</td>
      <td>{{ order?.itemCode }}</td>
    </tr>
    <tr>
      <td>Revision</td>
      <td>{{ order?.revision }}</td>
    </tr>
    <tr>
      <td>Action Qty</td>
      <td>{{ order?.actionQuantity }}</td>
    </tr>
    <tr>
      <td>Order Qty</td>
      <td>{{ order?.orderQuantity }}</td>
    </tr>
    <tr>
      <td>Shipped Qty</td>
      <td>{{ order?.shippedQuantity }}</td>
    </tr>
    <tr>
      <td>Required Date</td>
      <td>{{ order?.requiredDate | mfDate }}</td>
    </tr>
    <tr>
      <td>Promised Date</td>
      <td>{{ order?.promiseDate| mfDate }}</td>
    </tr>
    <tr>
      <td>Shipped Date</td>
      <td>{{ order?.shipDate| mfDate }}</td>
    </tr>
    <!-- TODO: need Shipment Method added to model
    <tr>
      <td>Shipment Method</td>
      <td>{{ order?. }}</td>
    </tr> -->
    <tr>
      <td>Tracking No.</td>
      <td>{{ order?.trackingNumber }}</td>
    </tr>
  </table>
</div>