import { Component, Inject, Injector, Input, ViewEncapsulation } from "@angular/core";
import { FormControl, FormControlStatus, FormGroup, Validators } from "@angular/forms";
import { MfBaseValidationComponent } from "@material-framework/base/base.validation.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsNullOrUndefined } from "@material-framework/common/utils/type.utils";
import { ERROR_MESSAGE_MAX_LENGTH, ERROR_MESSAGE_REQUIRED } from "@material-framework/common/validation/validation.const";
import { MF_VIEW_MANAGER_CONFIG_TOKEN, MfViewManagerConfig } from "@material-framework/viewManager/view.manager.config";

const TYPE_INFO: MfTypeInfo = { className: "MfViewManagerEditViewComponent" };

@Component({
  selector: "mf-view-manager-edit-view",
  templateUrl: "view.manager.edit.view.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfViewManagerEditViewComponent extends MfBaseValidationComponent {
  @Input()
  public name = "";

  public isValid = false;

  protected _formGroup: FormGroup = new FormGroup([]);
  protected _nameFormControl = new FormControl();

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_VIEW_MANAGER_CONFIG_TOKEN)
    protected _config: MfViewManagerConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._buildFormGroup();
  }

  protected _buildFormGroup(): void {
    this._addFormControlToFormGroup(this._formGroup, "name", this._nameFormControl);
    this._addFormControlErrorMessageHandling("name", this._nameFormControl);
    this._addValidation("name", this._nameFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addValidation("name", this._nameFormControl, Validators.maxLength(30), { priority: 1, validatorName: "maxLength", message: ERROR_MESSAGE_MAX_LENGTH, messageParams: [30] });

    this._sub(this._formGroup.statusChanges, {
      next: (status: FormControlStatus) => {
        this.isValid = (status === "VALID");
      }
    });

    this._sub(this._formGroup.valueChanges, {
      next: () => {
        const nameValue = this._formGroup.controls["name"].value;
        if (!mfTypeIsNullOrUndefined(nameValue)) {
          this.name = nameValue;
        }
      }
    });
  }
}