import { EComCheckoutStepBaseComponent } from "@e-commerce/checkout/base/checkout.step.base.component";
import { EComCheckoutStepValidationBaseComponent } from "@e-commerce/checkout/base/checkout.step.validation.base.component";

export type EComCheckoutBasModel = {};

export function isValidatingComponent<TCheckoutData extends EComCheckoutBasModel>(component: object): component is EComCheckoutStepValidationBaseComponent<TCheckoutData> {
  if (component instanceof EComCheckoutStepValidationBaseComponent) {
    return true;
  }
  return false;
}

export function isStepComponent<TCheckoutData extends EComCheckoutBasModel>(component: object): component is EComCheckoutStepBaseComponent<TCheckoutData> | EComCheckoutStepValidationBaseComponent<TCheckoutData> {
  if (component instanceof EComCheckoutStepBaseComponent || component instanceof EComCheckoutStepValidationBaseComponent) {
    return true;
  }
  return false;
}