<mf-root [menuItems]="_menuItems">
  <ng-container *mfHeaderMainLeftDef>
    <div class="ecom-logo">
      <img height="26px"
           (click)="_goHome()"
           src="/assets/incora-logo.svg" />
    </div>
  </ng-container>
  <ng-container *mfHeaderMainRightDef>
    <mf-user-persona class="ecom-user-persona-current"
                     (onActionClicked)="_onPersonaActionClicked($event)"
                     [modelConfig]="_userModelConfig"
                     [model]="_user"
                     [actions]="_userPersonaActions"
                     infoCardEnabled="true"></mf-user-persona>
  </ng-container>
  <ng-container *mfHeaderSubRightDef>
    <ecom-cart-preview></ecom-cart-preview>
  </ng-container>
  <ng-container *mfRootEndOfContentsDef>
    <mf-footer *ngIf="_showFooter">
      <ecom-footer></ecom-footer>
    </mf-footer>
  </ng-container>
</mf-root>