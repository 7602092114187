<ng-template #layoutControls
             let-layoutConfigSide>
  <ng-template ngFor
               let-configSide
               [ngForOf]="layoutConfigSide"
               let-i="index">
    <container-element [ngSwitch]="true">
      <button *ngSwitchCase="_config.dataAutoReload.length !== _tableDataReloadTypesLength && configSide === _tableHeaderItem.reloadDateButton"
              (click)="_onReloadDataClicked()"
              [@reloadDateButton]="_animationState"
              [disabled]="!dataNeedsReload"
              matTooltip="{{_config.header.reloadDateTooltip}}"
              mat-icon-button>
        <mf-icon [icon]="_config.header.reloadDateIcon"></mf-icon>
      </button>
      <mf-table-header-filter *ngSwitchCase="configSide === _tableHeaderItem.filter && _config.filter.mode === _tableFilterModeTypes.tableHeader"
                              (onFilterAddExpression)="onFilterAddExpression.emit($event)"
                              (onFilterAddGroup)="onFilterAddGroup.emit($event)"
                              (onFilterChange)="onFilterChange.emit($event)"
                              (onFilterExpressionChange)="onFilterExpressionChange.emit($event)"
                              (onFilterGroupChange)="onFilterGroupChange.emit($event)"
                              (onFilterRemoveExpression)="onFilterRemoveExpression.emit($event)"
                              (onFilterRemoveGroup)="onFilterRemoveGroup.emit($event)"
                              (onMenuClose)="_onFilterMenuClose()"
                              [modelFieldsConfig]="modelFieldsConfig"
                              [filterGroup]="filterGroup"></mf-table-header-filter>

      <button mat-icon-button
              *ngSwitchCase="configSide === _tableHeaderItem.orderShowHideButton"
              (click)="_toggleShowHideOrder()"
              matTooltip="{{_config.header.orderShowHide.tooltip}}">
        <mf-icon [icon]="_config.header.orderShowHide.icon"></mf-icon>
      </button>
      <mf-view-manager *ngSwitchCase="configSide === _tableHeaderItem.viewManager"
                       (onSelectedViewStateChanged)="onSelectedViewStateChanged.emit($event)"
                       (onViewStateBeforeUpdate)="onViewStateBeforeUpdate.emit($event)"
                       [viewState]="viewState"
                       [locationKey]="locationKey"></mf-view-manager>
    </container-element>
  </ng-template>
</ng-template>

<div class="mf-table-header-container"
     #headerContainer>
  <div class="mf-table-header-controls mf-table-header-controls-left"
       [style.width]="shrinkSticky ? '49px' : 'unset'">
    <ng-template *ngTemplateOutlet="layoutControls; context: {$implicit: _config.header.layout.left}"></ng-template>
  </div>
  <div *ngIf="!_isUndefined(headerLeftDef)">
    <ng-template [ngTemplateOutlet]="headerLeftDef.template"> </ng-template>
  </div>
  <div class="mf-flex-fill"></div>
  <div *ngIf="!_isUndefined(headerRightDef)">
    <ng-template [ngTemplateOutlet]="headerRightDef.template"></ng-template>
  </div>
  <div class="mf-table-header-controls mf-table-header-controls-right"
       [style.width]="shrinkSticky ? '240px' : 'unset'">
    <ng-template *ngTemplateOutlet="layoutControls; context: {$implicit: _config.header.layout.right}"></ng-template>
  </div>
</div>