import { InjectionToken } from "@angular/core";

export const MF_AUTH_LANDING_CONFIG_TOKEN = new InjectionToken<MfAuthLandingConfig>("MfAuthLandingConfig");
export const MF_AUTH_LANDING_CONFIG_DEFAULT: MfAuthLandingConfig = {
  loggedOut: {
    showSlogan: false,
    color: "black",
  },
  login: {
    showSlogan: false,
    color: "black",
    showLoginButton: false,
  }
};

export type MfAuthLandingConfig = {
  loggedOut: MfAuthLandingPageConfig;
  login: MfAuthLandingLoginPageConfig & MfAuthLandingPageConfig;
};

export type MfAuthLandingPageConfig = {
  showSlogan: boolean;
  sloganHeaderText?: string;
  sloganText?: string;
  color: string;
  backgroundImageUrl?: string;
};

export type MfAuthLandingLoginPageConfig = {
  showLoginButton: boolean;
  loginRedirectUrl?: string;
}