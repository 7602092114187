import { MfModelFieldDataTypes, MfModelFieldNumberTypes } from "@material-framework/modelConfig/model.config";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfInputTypes = {
  [MfModelFieldDataTypes.string]: MfModelFieldDataTypes.string,
  ...MfModelFieldNumberTypes,
} as const;

export type MfInputTypes = typeof MfInputTypes[keyof typeof MfInputTypes]

