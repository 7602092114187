import { GlobalPositionStrategy, Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { ComponentRef, Inject, Injectable, Injector, ViewContainerRef } from "@angular/core";
import { MfBaseObject } from "@material-framework/base/base.object";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfFavicon } from "@material-framework/common/utils/document.utils";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfLoadingComponent } from "@material-framework/loading/loading.component";
import { MF_LOADING_CONFIG_TOKEN, MfLoadingConfig } from "@material-framework/loading/loading.config";
import * as moment from "moment";

const TYPE_INFO: MfTypeInfo = { className: "MfLoadingService" };
let eventId = 0;

@Injectable()
export class MfLoadingService extends MfBaseObject {

  protected _overlayRef?: OverlayRef;
  protected _viewContainerRef?: ViewContainerRef;
  protected _loadingPortal?: ComponentPortal<MfLoadingComponent>;
  protected _componentRef?: ComponentRef<MfLoadingComponent>;
  protected _activeEvents: Set<number> = new Set();
  protected _isLoading = false;

  public constructor(
    protected _injector: Injector,
    protected _overlay: Overlay,
    protected _overlayPositionBuilder: OverlayPositionBuilder,
    @Inject(MF_LOADING_CONFIG_TOKEN)
    public config: MfLoadingConfig,
  ) {
    super(TYPE_INFO);
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public get nextEventId(): number {
    eventId += 1;
    return eventId;
  }

  public clear(): void {
    if (!mfTypeIsUndefined(this._componentRef)) {
      this.clearStatus();
      this.clearEta();
    }
  }

  public updateStatus(status: string): void {
    if (!mfTypeIsUndefined(this._componentRef)) {
      this._componentRef.instance.status = status;
    }
  }

  public clearStatus(): void {
    if (!mfTypeIsUndefined(this._componentRef)) {
      this._componentRef.instance.status = "";
    }
  }

  public updateEta(milliseconds: number): void {
    if (!mfTypeIsUndefined(this._componentRef)) {
      if (milliseconds < 0) {
        milliseconds = 0;
      }
      const duration = moment.duration(milliseconds);
      const seconds = duration.seconds();
      const minutes = duration.minutes();
      const hours = Math.trunc(duration.asHours());

      this._componentRef.instance.eta = `${hours}:${minutes}:${seconds}`;
    }
  }

  public clearEta(): void {
    if (!mfTypeIsUndefined(this._componentRef)) {
      this._componentRef.instance.eta = "";
    }
  }

  public setViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this._viewContainerRef = viewContainerRef;
    this._overlayRef = this._overlay.create(this._getOverlayConfig());
    this._loadingPortal = new ComponentPortal(MfLoadingComponent, this._viewContainerRef, this._injector);
  }

  public loadingStarted(eventId: number): void {
    this.clear();
    this._activeEvents.add(eventId);
    this._isLoading = true;
    if (!mfTypeIsUndefined(this._overlayRef) && !this._overlayRef.hasAttached()) {
      if (!mfTypeIsUndefined(this.config.icons)) {
        mfFavicon.animate(this.config.icons, this.config.delay);
      }
      this._componentRef = this._overlayRef.attach(this._loadingPortal);
      this.clear();
    }
  }

  public loadingComplete(eventId: number): void {
    if (this._activeEvents.has(eventId)) {
      this._activeEvents.delete(eventId);
      this._isLoading = false;

      if (this._activeEvents.size === 0) {
        if (!mfTypeIsUndefined(this._overlayRef)) {
          if (!mfTypeIsUndefined(this.config.icons)) {
            mfFavicon.stopAnimate();
            if (!mfTypeIsUndefined(this.config.defaultIcon)) {
              mfFavicon.change(this.config.defaultIcon);
            }
          }
          this._overlayRef.detach();
        }
      }
    }
  }

  protected _getOverlayConfig(): OverlayConfig {
    return {
      positionStrategy: this._getOverlayPositionStrategy(),
      panelClass: "mf-loading-service",
      backdropClass: "mf-loading-service-backdrop",

    } as OverlayConfig;
  }

  protected _getOverlayPositionStrategy(): GlobalPositionStrategy {
    return this._overlayPositionBuilder
      .global();
  }
}