import { InjectionToken } from "@angular/core";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { MfIcon } from "@material-framework/icon/icon";
import { MfIconTypes } from "@material-framework/icon/icon.types";

export const MF_CLEAR_VALUE_BUTTON_CONFIG_TOKEN = new InjectionToken<MfClearValueButtonConfig>("MfClearValueButtonConfig");
export const MF_CLEAR_VALUE_BUTTON_DEFAULT_CONFIG: MfClearValueButtonConfig = {
  icon: {
    icon: faDeleteLeft,
    type: MfIconTypes.fontAwesome,
  },
  tooltip: "",
};

export type MfClearValueButtonConfig = {
  icon: MfIcon;
  tooltip: string;
}