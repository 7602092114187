import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { BrowserModule } from "@angular/platform-browser";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MfClearValueButtonModule } from "@material-framework/clearValueButton/clear.value.button.module";
import { MfSelectComponent } from "@material-framework/select/select.component";
import { MF_SELECT_CONFIG_TOKEN, MF_SELECT_DEFAULT_CONFIG } from "@material-framework/select/select.config";
import { MfSelectOptionDirective } from "@material-framework/select/select.option.directive";

@NgModule({
  declarations: [
    MfSelectComponent,
    MfSelectOptionDirective,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MfClearValueButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [
    MfSelectComponent,
    MfSelectOptionDirective,
  ],
  providers: [
    { provide: MF_SELECT_CONFIG_TOKEN, useValue: MF_SELECT_DEFAULT_CONFIG },
  ]
})
export class MfSelectModule {
}