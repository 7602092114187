import { NgModule } from "@angular/core";
import { MfFunctionRefService } from "@material-framework/functionRef/function.ref.service";

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  exports: [
  ],
  providers: [
    MfFunctionRefService
  ]
})
export class MfFunctionRefModule {
}