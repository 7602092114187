import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { MfAuthService } from "@material-framework/auth/auth.service";
import { MfAuthXsrfTokenService } from "@material-framework/auth/auth.xsrf.token.service";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfCookieGetCookie } from "@material-framework/common/utils/cookie.utils";
import { Observable } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "MfAuthXsrfTokenInjectorInterceptor" };

@Injectable()
export class MfAuthXsrfTokenInjectorInterceptor extends MfBaseService implements HttpInterceptor {
  protected _getCookie = mfCookieGetCookie;

  public constructor(
    protected _authService: MfAuthService,
    protected _authXsrfTokenService: MfAuthXsrfTokenService,
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this._authService.isUrlAuthenticated(request.url)) {
      return next.handle(request);
    }

    const requestWithToken = request.clone({
      headers: request.headers.set(
        "X-XSRF-TOKEN",
        this._authXsrfTokenService.get(),
      ),
    });

    return next.handle(requestWithToken);
  }
}