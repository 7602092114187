import { Injectable, Injector } from "@angular/core";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComManufacturerModel } from "@e-commerce/modelConfigs/manufacturer.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfPortalsClientService } from "@material-framework/portals/portals.client.service";
import { concatMap, map, Observable, of, share } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComManufacturerService" };

type EComManufacturerExtendedModel = EComManufacturerModel & {
  customerKey: string;
}

@Injectable({ providedIn: "root" })
export class EComManufacturerService extends MfBaseService {

  protected _manufacturerModelConfig: MfModelConfigMapped;
  protected _manufacturers?: EComManufacturerExtendedModel[] = [];
  protected _getManufacturers$?: Observable<EComManufacturerModel>;

  public constructor(
    protected override _injector: Injector,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    protected _customerService: EComCustomerService,
  ) {
    super(TYPE_INFO, _injector);
    this._manufacturerModelConfig = this._modelConfigService.get<EComModelsConfig>("manufacturer");
  }

  public getManufacturerDetail(manufacturerKey: string): Observable<EComManufacturerModel> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        const manufacturerCached = this._manufacturers?.find(i => i.customerKey === selectedCustomer.key && i.manufacturerAccountKey === manufacturerKey);

        if (!mfTypeIsUndefined(manufacturerCached)) {
          return of(manufacturerCached);
        }

        if (mfTypeIsUndefined(this._getManufacturers$)) {
          this._getManufacturers$ = this._portalsClientService.getItemGet<EComManufacturerModel>(
            this._manufacturerModelConfig,
            `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${selectedCustomer.key}/${ECOM_ENVIRONMENT.portalsManufacturersUrl}/${manufacturerKey}`
          ).pipe(
            map((manufacturer) => {
              this._manufacturers?.push({ ...manufacturer, customerKey: selectedCustomer.key });
              delete this._getManufacturers$;
              return manufacturer;
            }),
            share(),
          );
        }

        return this._getManufacturers$;
      }),
    );
  }
}