import { Component, Inject, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { eComPayPalGetPaymentInfoFromQueryPrams } from "@e-commerce/common/paypal";
import { EComPayPalResultTypes } from "@e-commerce/common/paypal.result.types";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { EComCartService } from "@e-commerce/services/cart.service";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfStringIsEmptyOrWhitespace } from "@material-framework/common/utils/string.utils";
import { mfTypeIsNullOrUndefined } from "@material-framework/common/utils/type.utils";
import { map } from "rxjs";


const TYPE_INFO: MfTypeInfo = { className: "EComPaypalErrorComponent" };

const ECOM_DECLINED_MESSAGE = "Your transaction has been declined please try again with another card" as const;
const ECOM_GENERAL_MESSAGE = "Your transaction failed please try again" as const;

@Component({
  selector: "ecom-paypal-error",
  templateUrl: "./paypal.error.component.html",
  styleUrls: ["./paypal.error.component.scss"]
})
export class EComPaypalErrorComponent extends MfBaseComponent {
  protected _result: string = "";

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected _cartService: EComCartService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._errorPayment();
  }

  protected _errorPayment(): void {
    const loading = this._subLoading(
      this._activatedRoute.queryParams.pipe(
        map((queryParams) => {
          const paymentInfo = eComPayPalGetPaymentInfoFromQueryPrams(queryParams);
          if (!mfTypeIsNullOrUndefined(paymentInfo.respText) && !mfStringIsEmptyOrWhitespace(paymentInfo.respText)) {
            if (paymentInfo.result === EComPayPalResultTypes.declined) {
              this._result = ECOM_DECLINED_MESSAGE;
            } else {
              this._result = ECOM_GENERAL_MESSAGE;
            }
          }
        })
      ),
      {
        next: () => { loading.complete(); },
        error: () => { loading.complete(); },
      }
    );
  }

  protected _goCart(): void {
    this._router.navigate([EcomRouteNames.routeShopCartList]);
  }
}