import { APP_BASE_HREF, PlatformLocation } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { MfErrorModule } from "@material-framework/error/error.module";
import { MfFooterModule } from "@material-framework/footer/footer.module";
import { MfHeaderMainLeftDef, MfHeaderMainRightDef, MfHeaderSubLeftDef, MfHeaderSubRightDef } from "@material-framework/header/header.def.directive";
import { MfHeaderModule } from "@material-framework/header/header.module";
import { MfLoadingModule } from "@material-framework/loading/loading.module";
import { MfMenuModule } from "@material-framework/menu/menu.module";
import { MfRootComponent } from "@material-framework/root/root.component";
import { MF_ROOT_CONFIG_TOKEN, MF_ROOT_DEFAULT_CONFIG } from "@material-framework/root/root.config";
import { MfRootEndOfContentsDef } from "@material-framework/root/root.def.directive";
import { MfRootSideDrawerService } from "@material-framework/root/root.side.drawer.service";
import { MfSnackBarModule } from "@material-framework/snackbar/snackbar.module";

@NgModule({
  declarations: [
    MfRootComponent,
    MfRootEndOfContentsDef
  ],
  imports: [
    BrowserAnimationsModule,
    MatSidenavModule,
    MfErrorModule,
    MfHeaderModule,
    MfLoadingModule,
    MfMenuModule,
    MfSnackBarModule,
    RouterModule,
    MfFooterModule,
    MfErrorModule,
  ],
  exports: [
    MfRootComponent,
    MfHeaderMainRightDef,
    MfHeaderSubRightDef,
    MfHeaderMainLeftDef,
    MfHeaderSubLeftDef,
    MfRootEndOfContentsDef
  ],
  providers: [
    { provide: MF_ROOT_CONFIG_TOKEN, useValue: MF_ROOT_DEFAULT_CONFIG },
    { provide: APP_BASE_HREF, useFactory: (pl: PlatformLocation) => pl.getBaseHrefFromDOM(), deps: [PlatformLocation] },
    MfRootSideDrawerService,
  ]
})
export class MfRootModule {
}