export type MfOptionValueTypes = string | number | boolean | bigint;

export function mfSortOptions<TValue extends MfOptionValueTypes>(options: MfBaseOption<TValue>[]): MfBaseOption<TValue>[] {
  return options.sort((a, b) => a.label.localeCompare(b.label));
}

export type MfBaseOption<TValue extends MfOptionValueTypes> = {
  [key: string]: unknown | undefined;
  value: TValue;
  label: string;
}