import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { EComNotApprovedComponent } from "@e-commerce/landing/notApproved/not.approved.component";

@NgModule({
  declarations: [
    EComNotApprovedComponent
  ],
  exports: [
    EComNotApprovedComponent
  ],
  imports: [
    BrowserModule,
  ],
  providers: [
  ]
})
export class EComNotApprovedModule { }