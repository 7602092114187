<div class="ecom-page-container ecom-cart-container">
  <div class="ecom-page-account-select">
    <ecom-account-select></ecom-account-select>
  </div>
  <div class="ecom-page-header">
    <div class="ecom-title">
      Your Payment
      <div class="ecom-subtitle">
        Payment Ref: 00794026USD
      </div>
    </div>
    <div class="mf-flex-fill"></div>
    <div class="ecom-actions">
      <button mat-raised-button
              class="mf-default-margin-right"
              (click)="_goToInvoices()">Add More Invoices</button>
      <button mat-raised-button
              color="accent"
              (click)="_proceedToCheckout()">Proceed To Checkout</button>
    </div>
  </div>
  <div>
    <mf-table #cartProductsTable
              viewManagerEnabled="true"
              paginationEnabled="false"
              [displayColumns]="['select']"
              [locationKey]="_locationKey"
              [modelConfig]="_cartLineModelConfig"
              (onRowValueChanged)="_rowEditorValueChanged($event)">
      <ng-container mfTableColumnDef="select"
                    width="50"
                    side="left">
        <ng-container mf-table-header-cell
                      *mfTableHeaderCellDef>
          <mat-checkbox></mat-checkbox>
        </ng-container>
        <ng-container mf-table-cell
                      *mfTableCellDef>
          <mat-checkbox></mat-checkbox>
        </ng-container>
      </ng-container>
      <ng-container mfTableColumnSlideOutActionsDef
                    stickyEnd="true">
        <ng-container mf-table-cell
                      *mfTableCellDef="let item"
                      contentAlign="center">
          <div class="mf-column mf-default-margin">
            <button mat-stroked-button
                    (click)="_removeFromCart(item)">
              <mf-icon class="mf-default-margin-right"
                       [icon]="_iconsConfig.removeFromCartIcon"></mf-icon>
              Remove From Cart
            </button>
          </div>
        </ng-container>
      </ng-container>
    </mf-table>
  </div>
</div>