import { Component, EventEmitter, Inject, Injector, Input, Output, ViewEncapsulation } from "@angular/core";
import { MF_ANIMATION_FADE_IN, MF_ANIMATION_FADE_OUT } from "@material-framework/animations/animations";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfSortTypes } from "@material-framework/common/sort/sort.types";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfOnSortIndexChangingEvent } from "@material-framework/table/row/header/menu/table.row.header.menu.sort.component";
import { MfTableFieldColumn, MfTableFilterExpressionEvent, MfTableFilterGroupEvent } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";
import { MfTableFilterModeTypes } from "@material-framework/table/table.types";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowHeaderMenuComponent" };

@Component({
  selector: "mf-table-row-header-menu",
  templateUrl: "table.row.header.menu.component.html",
  encapsulation: ViewEncapsulation.None,
  animations: [
    MF_ANIMATION_FADE_IN,
    MF_ANIMATION_FADE_OUT,
  ]
})
export class MfTableRowHeaderMenuComponent extends MfBaseComponent {
  @Input()
  public column?: MfTableFieldColumn;

  @Input()
  public filterMode: MfTableFilterModeTypes = MfTableFilterModeTypes.tableHeader;

  // @Input()
  // public filterGroup?: MfFilterGroup;

  @Output()
  public onClickOutside: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onSortCleared: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onSortDirectionChanged: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onSortIndexChanging: EventEmitter<MfOnSortIndexChangingEvent> = new EventEmitter();

  @Output()
  public onSortIndexChanged: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onWidthChanged: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onFilterChange: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterGroupChange: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterRemoveGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterAddGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterExpressionChange: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onFilterAddExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onFilterRemoveExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onFilterEnterKey: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public onClose: EventEmitter<boolean> = new EventEmitter();

  public sortTypes = MfSortTypes;

  public constructor(
    protected _modelConfigService: MfModelConfigService,
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    protected _config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public get extendedEnabled(): boolean {
    return true;
  }

  public get sortEnabled(): boolean {
    return !mfTypeIsUndefined(this.column) &&
      !mfTypeIsUndefined(this.column.sort) &&
      (mfTypeIsUndefined(this.column.modelFieldConfig) || mfTypeIsUndefined(this.column.modelFieldConfig.calculated));
  }

  public get filterEnabled(): boolean {
    return this.filterMode === MfTableFilterModeTypes.rowHeader && !mfTypeIsUndefined(this.column) && !mfTypeIsUndefined(this.column.modelFieldConfig) && !mfTypeIsUndefined(this.column.modelFieldConfig.filter) && (mfTypeIsUndefined(this.column.modelFieldConfig.filter.disabled) || this.column.modelFieldConfig.filter.disabled === false) && !mfTypeIsUndefined(this.column.filter);
  }

  public get widthEnabled(): boolean {
    return !mfTypeIsUndefined(this.column) && !mfTypeIsUndefined(this.column.width) && !mfTypeIsUndefined(this.column.width.hasAdjustment) && this.column.width.hasAdjustment == true;
  }

  public onClickedOutside(): void {
    if (!mfTypeIsUndefined(this.column)) {
      this.onClickOutside.emit(this.column);
    }
  }

  protected _onCloseClick(): void {
    this.onClose.emit(true);
  }
}