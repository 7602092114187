import { Component, Inject, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPathValue } from "@material-framework/common/utils/object.utils";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfTableRowCellBaseFormatterComponent } from "@material-framework/table/row/cell/value/table.row.cell.base.formatter.component";
import { MfTableRowCellFormatterBaseOptions } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowCellStringFormatterComponent" };

export type MfTableRowCellStringFormatterOptions = MfTableRowCellFormatterBaseOptions & {

}

@Component({
  selector: "mf-table-row-cell-string-formatter-component",
  templateUrl: "table.row.cell.base.formatter.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowCellStringFormatterComponent<TModel extends MfModelBase> extends MfTableRowCellBaseFormatterComponent<MfTableRowCellStringFormatterOptions, TModel> implements OnInit {
  public constructor(
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    this._displayValue = mfObjectGetPropertyPathValue<string>(this.fieldColumn?.fieldPath, this.rowItem) ?? "";
  }
}