import { Component, Inject, Injector, Input, ViewEncapsulation } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfTableGroupColumn } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowGroupHeaderCellComponent" };

@Component({
  selector: "mf-table-row-group-header-cell",
  templateUrl: "table.row.group.header.cell.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowGroupHeaderCellComponent extends MfBaseComponent {
  @Input()
  public groupColumn?: MfTableGroupColumn;

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }
}