import { NgModule } from "@angular/core";
import { MfFillHeightDirective } from "@material-framework/resized/fill.height.directive";
import { MfResizedDirective } from "@material-framework/resized/resized.directive";


@NgModule({
  declarations: [
    MfResizedDirective,
    MfFillHeightDirective,
  ],
  exports: [
    MfResizedDirective,
    MfFillHeightDirective,
  ]
})
export class MfResizedModule {
}