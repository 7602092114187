import { EventEmitter, Injectable, Injector } from "@angular/core";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfViewState, MfViewStateData } from "@material-framework/viewManager/view.manager";
import { MfLocationKey } from "@material-framework/viewManager/view.manager.component";
import { Observable } from "rxjs";

@Injectable()
export abstract class MfViewManagerStorageService<TData extends MfViewStateData> extends MfBaseService {

  public abstract onViewStateUpdated: EventEmitter<MfViewState<TData>>;
  public abstract onViewStateNeedsCaching: EventEmitter<MfLocationKey>;

  public constructor(
    protected override _typeInfo: MfTypeInfo,
    protected override _injector: Injector,
  ) {
    super(_typeInfo, _injector);
  }

  public abstract get(locationKey: string): Observable<MfViewState<TData>[]>;
  public abstract setAsDefault(locationKey: string, id: number): Observable<boolean>;
  public abstract update(locationKey: string, viewState: MfViewState<TData>): Observable<MfViewState<TData> | undefined>;
  public abstract updateCache(locationKey: string, viewState: MfViewState<TData>): Observable<MfViewState<TData> | undefined>;
  public abstract add(locationKey: string, viewState: MfViewState<TData>): Observable<MfViewState<TData> | undefined>;
  public abstract delete(locationKey: string, id: number | undefined): Observable<boolean>;
}