import { APP_BASE_HREF } from "@angular/common";
import { Inject, Injectable, Injector } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, GuardsCheckEnd, Router } from "@angular/router";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfBreadcrumb } from "@material-framework/breadcrumb/breadcrumb";
import { MfBreadcrumbLabelFunction, MfBreadcrumbRouteConfig } from "@material-framework/breadcrumb/breadcrumb.config";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeHasOwnProperty, mfTypeIsFunction, mfTypeIsNonEmpty, mfTypeIsNullOrUndefined, mfTypeIsString, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfRoute } from "@material-framework/router/route";
import { BehaviorSubject, Observable, filter } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "MfBreadcrumbService" };

const PATH_PARAM_PREFIX = ":";

@Injectable()
export class MfBreadcrumbService extends MfBaseService {
  public breadcrumbs: Observable<MfBreadcrumb[]>;

  protected _breadcrumbs = new BehaviorSubject<MfBreadcrumb[]>([]);
  protected _breadcrumbsStore: MfBreadcrumb[] = [];

  public constructor(
    protected override _injector: Injector,
    protected _activatedRoute: ActivatedRoute,
    protected _router: Router,
    @Inject(APP_BASE_HREF)
    protected _baseHref: string,
  ) {
    super(TYPE_INFO, _injector);
    this.breadcrumbs = this._breadcrumbs.asObservable();
    this._detectRouteChanges();
  }

  public update(alias: string, value: string | MfBreadcrumbRouteConfig | MfBreadcrumbLabelFunction) {
    const breadcrumbConfig = this._extractObject(value);
    this._updateCurrentBreadcrumbs("alias", { ...breadcrumbConfig, alias: alias });
  }

  protected _detectRouteChanges() {
    this._setupBreadcrumbs(this._activatedRoute.snapshot);

    this._sub(this._router.events
      .pipe(
        filter(
          (event): event is GuardsCheckEnd => event instanceof GuardsCheckEnd
        )
      ), {
      next: (event) => {
        if (event.shouldActivate) {
          this._setupBreadcrumbs(event.state.root);
        }
      }
    });
  }

  protected _setupBreadcrumbs(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const rootBreadcrumb = this._getRootBreadcrumb();
    this._breadcrumbsStore = !mfTypeIsUndefined(rootBreadcrumb) ? [rootBreadcrumb] : [];
    this._prepareBreadcrumbList(activatedRouteSnapshot, this._baseHref);
  }

  protected _prepareBreadcrumbList(activatedRouteSnapshot: ActivatedRouteSnapshot, routeLinkPrefix: string): MfBreadcrumb[] | void {
    const breadcrumbItem = this._prepareBreadcrumbItem(activatedRouteSnapshot, routeLinkPrefix);
    if (!mfTypeIsUndefined(breadcrumbItem)) {
      this._breadcrumbsStore.push(breadcrumbItem);
    }

    if (activatedRouteSnapshot.firstChild) {
      return this._prepareBreadcrumbList(activatedRouteSnapshot.firstChild, "/");
    }

    const breadcrumbsToShow = this._breadcrumbsStore.filter(
      (breadcrumb) => !breadcrumb.skip
    );

    this._breadcrumbs.next(breadcrumbsToShow);
  }

  protected _prepareBreadcrumbItem(activatedRouteSnapshot: ActivatedRouteSnapshot, routeLinkPrefix: string): MfBreadcrumb | undefined {

    const path = activatedRouteSnapshot.routeConfig?.path;
    const breadcrumb = (!mfTypeIsNullOrUndefined(activatedRouteSnapshot.routeConfig) && mfTypeHasOwnProperty(activatedRouteSnapshot.routeConfig, "breadcrumb") ? activatedRouteSnapshot.routeConfig.breadcrumb : undefined) as MfBreadcrumbRouteConfig | undefined;

    if (!mfTypeIsNullOrUndefined(path) && !mfTypeIsUndefined(breadcrumb)) {

      const resolvedSegment = this._resolvePathSegment(path, activatedRouteSnapshot);
      const routeLink = `${routeLinkPrefix}${resolvedSegment}`;

      const label = this._extractLabel(breadcrumb.label || "", resolvedSegment);

      const newBreadcrumb: MfBreadcrumb = {
        ...breadcrumb,
        label,
        routeLink,
      };

      return newBreadcrumb;
    }

    return;
  }

  protected _updateCurrentBreadcrumbs(key: keyof MfBreadcrumb, breadcrumb: MfBreadcrumb) {
    const itemIndex = this._breadcrumbsStore.findIndex((item) => item.alias === breadcrumb.alias);
    if (itemIndex > -1) {
      this._breadcrumbsStore[itemIndex] = {
        ...this._breadcrumbsStore[itemIndex],
        ...breadcrumb,
      };
      const breadcrumbsToShow = this._breadcrumbsStore.filter(
        (item) => !item.skip
      );
      this._breadcrumbs.next([...breadcrumbsToShow]);
    }
  }

  protected _resolvePathSegment(segment: string, activatedRouteSnapshot: ActivatedRouteSnapshot) {
    if (segment.includes(PATH_PARAM_PREFIX)) {
      Object.entries(activatedRouteSnapshot.params).forEach(([key, value]) => {
        segment = segment.replace(`:${key}`, `${value}`);
      });
    }
    return segment;
  }

  protected _extractLabel(config: MfBreadcrumb | MfBreadcrumbLabelFunction | string, resolvedParam?: string): string | undefined {
    const label = typeof config === "object" ? config.label : config;
    if (typeof label === "function") {
      return label(resolvedParam);
    }
    return label;
  }

  protected _getRootBreadcrumb(): MfBreadcrumb | undefined {
    const rootRoute: MfRoute | undefined = this._router.config.find((config: MfRoute) => config.path === "");
    if (!mfTypeIsUndefined(rootRoute) && !mfTypeIsUndefined(rootRoute.breadcrumb)) {
      const rootBreadcrumb = rootRoute.breadcrumb;

      if (mfTypeIsNonEmpty(rootBreadcrumb)) {
        const breadcrumb: MfBreadcrumb = {
          ...rootBreadcrumb,
          routeLink: this._baseHref,
        };

        return breadcrumb;
      }
    }

    return;
  }

  protected _extractObject(value: string | MfBreadcrumbRouteConfig | MfBreadcrumbLabelFunction): MfBreadcrumb {
    if (mfTypeIsString(value) || mfTypeIsFunction(value)) {
      return { routeLink: "", label: (value as string | MfBreadcrumbLabelFunction) };
    }
    return (value as MfBreadcrumb) || {};
  }
}