import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { EComShelfLifeDaysFormatterComponent } from "@e-commerce/table/cell/shelf.life.days.cell.formatter.component";
import { MfDialogService } from "@material-framework/dialog/dialog.service";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MF_TABLE_CELL_FORMATTERS_CONFIG } from "@material-framework/table/table.config.cell.formatter";
import { MfTinyMCEEditorModule } from "@material-framework/tinyMCEEditor/tiny.mce.editor.module";

MF_TABLE_CELL_FORMATTERS_CONFIG["ecomShelfLifeDaysFormatter"] = EComShelfLifeDaysFormatterComponent;

@NgModule({
  declarations: [
    EComShelfLifeDaysFormatterComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MatTooltipModule,
    MfIconModule,
    MatButtonModule,
    MatDialogModule,
    MfTinyMCEEditorModule,
    FormsModule,
  ],
  exports: [
  ],
  providers: [
    MfDialogService,
  ]
})
export class EComTableCellModule { }