import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MF_DIALOG_CONFIG_TOKEN, MF_DIALOG_DEFAULT_CONFIG } from "@material-framework/dialog/dialog.config";
import { MfDialogOkCancelComponent } from "@material-framework/dialog/dialog.ok.cancel.component";
import { MfDialogService } from "@material-framework/dialog/dialog.service";
import { MfPipesModule } from "@material-framework/pipes/pipes.module";

@NgModule({
  declarations: [
    MfDialogOkCancelComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FontAwesomeModule,
    MatTooltipModule,
    MatButtonModule,
    MatDialogModule,
    MfPipesModule,
  ],
  exports: [
  ],
  providers: [
    { provide: MF_DIALOG_CONFIG_TOKEN, useValue: MF_DIALOG_DEFAULT_CONFIG },
    MfDialogService,
  ]
})
export class MfDialogModule {
}