<mat-card #container
          @fadeIn
          @fadeOut
          (mfClickOutside)="onClickedOutside()"
          [exclude]="'.cdk-overlay-pane,.mf-clear-value-button,.mat-calendar,.mat-mdc-menu-panel,.mat-mdc-form-field,.cdk-overlay-containe,.mf-clear-value-button,.mat-calendar,.mat-mdc-menu-panel,.mat-calendar'"
          [excludeBeforeClick]="true"
          class="mf-table-row-header-menu">
  <mat-card-header>
    <mat-card-title>
      <div class="mf-row mf-table-row-header-menu-header">
        <div>{{ column?.modelFieldConfig?.display?.displayName }}</div>
        <div class="mf-flex-fill"></div>
        <div>
          <button mat-icon-button
                  (click)="_onCloseClick()">
            <mf-icon [icon]="_config.row.header.closeIcon"></mf-icon>
          </button>
        </div>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-header *ngIf="sortEnabled">
    <mat-card-subtitle>{{ _config.row.header.sort.displayName }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="sortEnabled">
    <mf-table-row-header-menu-sort (onSortDirectionChanged)="onSortDirectionChanged.emit($event)"
                                   (onSortIndexChanged)="onSortIndexChanged.emit($event)"
                                   (onSortIndexChanging)="onSortIndexChanging.emit($event)"
                                   (onSortCleared)="onSortCleared.emit($event)"
                                   [column]="column!"></mf-table-row-header-menu-sort>
  </mat-card-content>
  <mat-card-header *ngIf="filterEnabled">
    <mat-card-subtitle>{{ _config.row.header.filter.displayName }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="filterEnabled">
    <mf-table-row-header-menu-filter (onFilterAddExpression)="onFilterAddExpression.emit($event)"
                                     (onFilterAddGroup)="onFilterAddGroup.emit($event)"
                                     (onFilterExpressionChange)="onFilterExpressionChange.emit($event)"
                                     (onFilterChange)="onFilterChange.emit($event)"
                                     (onFilterGroupChange)="onFilterGroupChange.emit($event)"
                                     (onFilterRemoveExpression)="onFilterRemoveExpression.emit($event)"
                                     (onFilterRemoveGroup)="onFilterRemoveGroup.emit($event)"
                                     (onFilterEnterKey)="onFilterEnterKey.emit($event)"
                                     [column]="column!"></mf-table-row-header-menu-filter>
  </mat-card-content>
  <mat-card-header *ngIf="widthEnabled">
    <mat-card-subtitle>{{ _config.row.header.resize.displayName }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="widthEnabled">
    <mf-table-row-header-menu-width (onWidthChanged)="onWidthChanged.emit($event)"
                                    [column]="column!"></mf-table-row-header-menu-width>
  </mat-card-content>
</mat-card>