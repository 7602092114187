import { MfError } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfFilterOperatorTypes } from "@material-framework/filter/filter.types";

const TYPE_INFO: MfTypeInfo = { className: "PortalsFilter" };

export type MfPortalsFilter = {
  expressions: MfPortalsFilterExpression[];
}

export type MfPortalsFilterExpression = {
  operator: MfPortalsFilteringOperatorTypes,
  value: unknown;
  field: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfPortalsFilteringOperatorTypes = {
  eq: "eq",
  like: "like",
  startsWith: "sw",
} as const;

export type MfPortalsFilteringOperatorTypes = typeof MfPortalsFilteringOperatorTypes[keyof typeof MfPortalsFilteringOperatorTypes]

export function mfFilterOperatorTypeToMfPortalsFilteringOperatorType(operatorType: MfFilterOperatorTypes): MfPortalsFilteringOperatorTypes {
  switch (operatorType) {
    case MfFilterOperatorTypes.eq:
      return MfPortalsFilteringOperatorTypes.eq;
    case MfFilterOperatorTypes.contains:
      return MfPortalsFilteringOperatorTypes.like;
    case MfFilterOperatorTypes.startsWith:
      return MfPortalsFilteringOperatorTypes.startsWith;
    default:
      throw new MfError(TYPE_INFO, "mfFilterOperatorTypeToMfPortalsFilteringOperatorType", `No mapped MfFilterOperatorType: ${operatorType}`);
  }
}

export function mfPortalsFilteringOperatorTypeToMfFilterOperatorType(operator: MfPortalsFilteringOperatorTypes): MfFilterOperatorTypes {
  switch (operator) {
    case MfPortalsFilteringOperatorTypes.eq:
      return MfFilterOperatorTypes.eq;
    case MfPortalsFilteringOperatorTypes.like:
      return MfFilterOperatorTypes.contains;
    case MfPortalsFilteringOperatorTypes.startsWith:
      return MfFilterOperatorTypes.startsWith;
    default:
      throw new MfError(TYPE_INFO, "mfFilterOperatorTypeToMfPortalsFilteringOperatorType", `No mapped MfPortalsFilteringOperatorTypes: ${operator}`);
  }
}