import { mfTypeIsNumber, mfTypeIsString } from "@material-framework/common/utils/type.utils";

export const MF_NUMBER_SBYTE_MAX_VALUE: number = 127 as const;
export const MF_NUMBER_SBYTE_MIN_VALUE: number = -128 as const;

export const MF_NUMBER_BYTE_MAX_VALUE: number = 255 as const;
export const MF_NUMBER_BYTE_MIN_VALUE: number = 0 as const;

export const MF_NUMBER_SHORT_MAX_VALUE: number = 32767 as const;
export const MF_NUMBER_SHORT_MIN_VALUE: number = -32768 as const;

export const MF_NUMBER_USHORT_MAX_VALUE: number = 65535 as const;
export const MF_NUMBER_USHORT_MIN_VALUE: number = 0 as const;

export const MF_NUMBER_INT_MAX_VALUE: number = 2147483647 as const;
export const MF_NUMBER_INT_MIN_VALUE: number = -2147483648 as const;

export const MF_NUMBER_UINT_MAX_VALUE: number = 4294967295 as const;
export const MF_NUMBER_UINT_MIN_VALUE: number = 0 as const;

export const MF_NUMBER_LONG_MAX_VALUE: number = 9.223372e+18 as const;
export const MF_NUMBER_LONG_MIN_VALUE: number = -9.223372e+18 as const;

export const MF_NUMBER_ULONG_MAX_VALUE: number = 1.8446744e+19 as const;
export const MF_NUMBER_ULONG_MIN_VALUE: number = 0 as const;

export const MF_NUMBER_FLOAT_MAX_VALUE: number = 3.4028234663852886E+38 as const;
export const MF_NUMBER_FLOAT_MIN_VALUE: number = -3.4028234663852886E+38 as const;

export const MF_NUMBER_DOUBLE_MAX_VALUE: number = 1.7976931348623157E+308 as const;
export const MF_NUMBER_DOUBLE_MIN_VALUE: number = -1.7976931348623157E+308 as const;

export const MF_NUMBER_DECIMAL_MAX_VALUE: number = 1.7976931348623157E+308 as const;
export const MF_NUMBER_DECIMAL_MIN_VALUE: number = -1.7976931348623157E+308 as const;

export function mfNumberStringClean(value: string): string {
  return value.replace(",", "");
}

export function mfNumberIsNumberConvert(value: unknown): number | null {
  if (mfTypeIsNumber(value)) {
    return value;
  } else if (mfTypeIsString(value)) {
    const parsedNumber = parseFloat(mfNumberStringClean(value));
    if (!isNaN(parsedNumber)) {
      return parsedNumber;
    }
  }
  return null;
}

export function mfNumberIsParsable(value: unknown): boolean {
  return mfNumberIsNumberConvert(value) !== null;
}

export function mfNumberGetNumberWithOrdinal(n: number): string {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function mfNumberStartToFinalDifferencePercentage(final: number, start: number): number {
  if (final === 0 && start === 0) return 0;

  const diff = mfNumberStartToFinalDifference(final, start);

  if (start === 0) {
    return final > 0 ? Infinity : -Infinity;
  }

  const percentageChange = (diff / Math.abs(start));

  return percentageChange;
}

export function mfNumberStartToFinalDifference(final: number, start: number): number {
  return final - start;
}
