<div class="mf-width-container">
  <div class="min-max">
    <div class="min">{{config.row.header.resize.minDisplayName}}:{{config.row.header.resize.minWidth}}</div>
    <div class="max">{{config.row.header.resize.maxDisplayName}}:{{config.row.header.resize.maxWidth}}</div>
  </div>
  <mat-slider [min]="config.row.header.resize.minWidth"
              [max]="config.row.header.resize.maxWidth"
              discrete="true">
    <input matSliderThumb
           (input)="onWidthValueChange($event)"
           [value]="_isUndefinedThenDefault(column.width?.width, config.row.header.resize.minWidth)">
  </mat-slider>
</div>