// eslint-disable-next-line @typescript-eslint/naming-convention
export const EComOrderStatusesTypes = {
  all: "",
  cancelled: "C",
  held: "H",
  placed: "P",
  shipped: "S",
  inProgress: "W",
} as const;

export type EComOrderStatusesTypes = typeof EComOrderStatusesTypes[keyof typeof EComOrderStatusesTypes];

export function eComOrderStatusesTypeToDisplayName(value: EComOrderStatusesTypes): string {
  switch (value) {
    case EComOrderStatusesTypes.all:
      return "All";
    case EComOrderStatusesTypes.cancelled:
      return "Cancelled";
    case EComOrderStatusesTypes.held:
      return "Held";
    case EComOrderStatusesTypes.placed:
      return "Placed";
    case EComOrderStatusesTypes.shipped:
      return "Shipped";
    case EComOrderStatusesTypes.inProgress:
      return "In Progress";
  }
}