import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelFieldConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";

export function mfGetUserPersonaRequiredFields(modelFieldConfig: MfModelFieldConfigMapped, modelConfigService: MfModelConfigService): string[] {
  if (!mfTypeIsUndefined(modelFieldConfig.model)) {
    const modelConfig = modelConfigService.getUnMapped(modelFieldConfig.model.key);

    if (!mfTypeIsUndefined(modelConfig.userPersona) && !mfTypeIsUndefined(modelFieldConfig.model) && !mfTypeIsUndefined(modelFieldConfig.model.fields)) {
      const fieldPaths: string[] = [];

      const displayNameMapConfig = modelConfigService.findModelFieldConfigByRelativePath(modelFieldConfig.model.fields, modelConfig.userPersona.mappings.displayNameMap);
      if (!mfTypeIsUndefined(displayNameMapConfig)) {
        const displayNameMapFieldPath = displayNameMapConfig.fieldPath;
        if (!mfTypeIsUndefined(displayNameMapFieldPath)) {
          fieldPaths.push(displayNameMapFieldPath);
        }
      }

      const idMapConfig = modelConfigService.findModelFieldConfigByRelativePath(modelFieldConfig.model.fields, modelConfig.userPersona.mappings.idMap);
      if (!mfTypeIsUndefined(idMapConfig)) {
        const idMapFieldPath = idMapConfig.fieldPath;
        if (!mfTypeIsUndefined(idMapFieldPath)) {
          fieldPaths.push(idMapFieldPath);
        }
      }

      if (!mfTypeIsUndefined(modelConfig.userPersona.mappings.emailMap)) {
        const emailMapConfig = modelConfigService.findModelFieldConfigByRelativePath(modelFieldConfig.model.fields, modelConfig.userPersona.mappings.emailMap);
        if (!mfTypeIsUndefined(emailMapConfig)) {
          const emailMapFieldPath = emailMapConfig.fieldPath;
          if (!mfTypeIsUndefined(emailMapFieldPath)) {
            fieldPaths.push(emailMapFieldPath);
          }
        }
      }

      if (!mfTypeIsUndefined(modelConfig.userPersona.mappings.initialsMap)) {
        const initialsMapConfig = modelConfigService.findModelFieldConfigByRelativePath(modelFieldConfig.model.fields, modelConfig.userPersona.mappings.initialsMap);
        if (!mfTypeIsUndefined(initialsMapConfig)) {
          const initialsMapFieldPath = initialsMapConfig.fieldPath;
          if (!mfTypeIsUndefined(initialsMapFieldPath)) {
            fieldPaths.push(initialsMapFieldPath);
          }
        }
      }

      if (!mfTypeIsUndefined(modelConfig.userPersona.mappings.jobTitleMap)) {
        const jobTitleMapConfig = modelConfigService.findModelFieldConfigByRelativePath(modelFieldConfig.model.fields, modelConfig.userPersona.mappings.jobTitleMap);
        if (!mfTypeIsUndefined(jobTitleMapConfig)) {
          const jobTitleMapFieldPath = jobTitleMapConfig.fieldPath;
          if (!mfTypeIsUndefined(jobTitleMapFieldPath)) {
            fieldPaths.push(jobTitleMapFieldPath);
          }
        }
      }
      if (!mfTypeIsUndefined(modelConfig.userPersona.mappings.photoMap)) {
        const photoMapConfig = modelConfigService.findModelFieldConfigByFieldPath(modelFieldConfig.model.fields, modelConfig.userPersona.mappings.photoMap);
        if (!mfTypeIsUndefined(photoMapConfig)) {
          const photoMapFieldPath = photoMapConfig.fieldPath;
          if (!mfTypeIsUndefined(photoMapFieldPath)) {
            fieldPaths.push(photoMapFieldPath);
          }
        }
      }

      return fieldPaths;
    }
  }
  return [];
}