import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { BrowserModule } from "@angular/platform-browser";
import { EComAccountSelectModule } from "@e-commerce/accountSelect/account.select.module";
import { EComInvoicesComponent } from "@e-commerce/invoices/invoices.component";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MfTableModule } from "@material-framework/table/table.module";

@NgModule({
  declarations: [
    EComInvoicesComponent
  ],
  exports: [
    EComInvoicesComponent
  ],
  imports: [
    BrowserModule,
    EComAccountSelectModule,
    MatButtonModule,
    MfIconModule,
    MfTableModule,
  ],
  providers: [
  ]
})
export class EComInvoicesModule { }