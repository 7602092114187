import { Component, EventEmitter, Injector, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { EComOrderCheckoutData } from "@e-commerce/checkout/order/checkout.order";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";

const TYPE_INFO: MfTypeInfo = { className: "EComCheckoutSummaryComponent" };

@Component({
  selector: "ecom-checkout-summary",
  templateUrl: "./checkout.summary.component.html",
  styleUrls: ["./checkout.summary.component.scss"]
})
export class EComCheckoutSummaryComponent extends MfBaseComponent {
  @Input()
  public formGroup?: FormGroup;

  @Input()
  public buttonLabel?: string;

  @Input()
  public title?: string;

  @Input()
  public showTC?: boolean;

  @Input()
  public tcValue?: string;

  @Input()
  public tcLink?: string;

  @Input()
  public checkoutData?: EComOrderCheckoutData;

  @Output()
  public onContinueClick: EventEmitter<void> = new EventEmitter();

  public tcChecked: boolean = false;

  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }

  public continueClick(): void {
    this.onContinueClick.next();
  }

  public tcCheckedChanged(event: MatCheckboxChange): void {
    this.tcChecked = event.checked;
  }
}