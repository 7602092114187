import { Directive, Injector, OnDestroy } from "@angular/core";
import { MfBaseObject } from "@material-framework/base/base.object";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfErrorService } from "@material-framework/error/error.service";
import { MfLoadingService } from "@material-framework/loading/loading.service";
import { catchError, Observable, Observer, of, Subscription } from "rxjs";

class MfSubLoading<T> {
  protected _loadingObservable: Observable<T>;
  protected _subscription?: Subscription;
  protected _eventId: number;

  public constructor(
    protected _observable: Observable<T>,
    protected _safeUnsubscribe: MfBaseSafeUnsubscribe,
    protected _loadingService: MfLoadingService,
    protected _errorService: MfErrorService,
    protected _subscriptions: Subscription[],
    protected _observer?: Partial<Observer<T>>,
    protected _status?: string,
  ) {
    this._eventId = this._loadingService.nextEventId;

    this._loadingObservable = _observable.pipe(
      catchError((error: Error) => {
        this._loadingService.loadingComplete(this._eventId);
        this._errorService.handleError(error);
        return of();
      })
    );

    this._loadingService.loadingStarted(this._eventId);
    if (!mfTypeIsUndefined(_status)) {
      this._loadingService.updateStatus(_status);
    }

    setTimeout(() => {
      this._subscriptions.push(this._loadingObservable.subscribe(this._observer));
    }, 1);

  }

  public complete(): void {
    this._loadingService.loadingComplete(this._eventId);
    if (!mfTypeIsUndefined(this._subscription) && !this._subscription.closed) {
      this._subscription.unsubscribe();
    }
  }
}

const unsubscribeAll = (subscriptions: Subscription[]): void => {
  const subscriptionsLength = subscriptions.length;
  if (subscriptionsLength > 0) {
    for (let i = 0; i < subscriptionsLength; i++) {
      const sub = subscriptions[i];
      if (!sub.closed) {
        sub.unsubscribe();
      }
    }
  }
};

@Directive()
export abstract class MfBaseSafeUnsubscribe extends MfBaseObject implements OnDestroy {
  protected _loadingService: MfLoadingService;
  protected _errorService: MfErrorService;
  protected _subscriptions: Subscription[] = [];

  public constructor(
    protected override _typeInfo: MfTypeInfo,
    protected _injector: Injector,
  ) {
    super(_typeInfo);
    this._errorService = this._injector.get(MfErrorService);
    this._loadingService = this._injector.get(MfLoadingService);
  }

  public ngOnDestroy(): void {
    unsubscribeAll(this._subscriptions);
  }

  protected _subLoading<T>(observable: Observable<T>, observer?: Partial<Observer<T>>, status?: string): MfSubLoading<T> {
    return new MfSubLoading(observable, this, this._loadingService, this._errorService, this._subscriptions, observer, status);
  }

  protected _sub<T>(observable: Observable<T>, observer?: Partial<Observer<T>>): Subscription {
    observable = observable.pipe(
      catchError((error: Error) => {
        this._errorService.handleError(error);
        throw error;
      }),
    );
    return this._addSubscription(observable.subscribe(observer));
  }

  protected _addSubscription(subscription: Subscription): Subscription {
    this._subscriptions.push(subscription);
    return subscription;
  }

  protected _unSub(subscription: Subscription): void {
    const index = this._subscriptions.findIndex(i => i === subscription);
    if (index != -1) {
      const sub = this._subscriptions[index];
      if (!sub.closed) {
        sub.unsubscribe();
      }
    }
  }
}