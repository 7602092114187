
import { MfSortTypes } from "@material-framework/common/sort/sort.types";
import { MfTextAlignHorizontalTypes } from "@material-framework/common/types";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfValidatorFunction } from "@material-framework/common/validation/validation";
import { MfValidationMessage } from "@material-framework/common/validation/validation.message";
import { MfFilterExpression, MfFilterGroup } from "@material-framework/filter/filter";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelFieldConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfSelectOption } from "@material-framework/select/select.option";
import { MfTableColumnModelConfigDef } from "@material-framework/table/row/column/table.column.model.config.def.directive";
import type * as css from "csstype";

export type MfTableModelSelected = MfModelBase & {
  selected?: boolean;
}


export type MfTableFilterGroupEvent = {
  column?: MfTableFieldColumn,
  group: MfFilterGroup,
}

export type MfTableFilterExpressionEvent = {
  column?: MfTableFieldColumn,
  expression: MfFilterExpression,
}

export type MfTableRowCellValueBaseOptions = {
}

export type MfTableRowCellFormatterBaseOptions = MfTableRowCellValueBaseOptions & {
}

export type MfTableRowCellEditorBaseOptions = MfTableRowCellValueBaseOptions & {
  validators: MfTableRowCellEditorValidatorOptions[];
}

export type MfTableRowCellEditorValidatorOptions = {
  validationFunction: MfValidatorFunction,
  validationMessage: MfValidationMessage
}

export type MfTableGroupColumnMovingEvent = {
  group: MfTableGroupColumn;
  toIndex: number;
  fromIndex: number;
}

export type MfTablePagination = {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex?: number;
}

export function mfTableColumnVisible(column: MfTableGroupColumn | MfTableFieldColumn): boolean {
  if (!mfTypeIsUndefined(column.overrideVisible)) {
    return column.overrideVisible;
  } else {
    return mfTypeIsUndefined(column.visible) || column.visible === true;
  }
}

export type MfTableGroupColumn = {
  id: string;
  groupPath: string;
  label: string;
  colSpan: number;
  fieldColumns: MfTableFieldColumn[];
  textAlign?: MfTextAlignHorizontalTypes;
  visible?: boolean;
  overrideVisible?: boolean;
  stickyEnd?: boolean;
  sticky?: boolean;
  style?: css.Properties;
}

export type MfTableFieldColumn = {
  fieldKey: string;
  fieldPath: string;
  label: string;
  menu: MfTableRowHeaderCellMenu;
  modelFieldConfig?: MfModelFieldConfigMapped;
  sort?: MfTableColumnSort;
  filter?: MfTableColumnFilter;
  width?: MfTableColumnWidth;
  visible?: boolean;
  overrideVisible?: boolean;
  groupColumn?: MfTableGroupColumn;
  columnModelConfigDef?: MfTableColumnModelConfigDef;
  stickyEnd?: boolean;
  sticky?: boolean;
  inViewState?: boolean;
  mouseOverHeader?: boolean;
}

export type MfTableColumnWidth = {
  width: number;
  hasAdjustment: boolean;
}

export type MfTableColumnFilter = {
  group: MfFilterGroup;
  hasFilters: boolean;
}

export type MfTableColumnSort = {
  direction?: MfSortTypes;
  index?: number;
  options?: MfSelectOption<number>[];
}

export type MfTableRowHeaderCellMenu = {
  isOpen: boolean;
}