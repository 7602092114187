import { OverlayModule } from "@angular/cdk/overlay";
import { NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { MatRippleModule } from "@angular/material/core";
import { MatListModule } from "@angular/material/list";
import { BrowserModule } from "@angular/platform-browser";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MfUserPersonaCardComponent } from "@material-framework/userPersona/user.persona.card.component";
import { MfUserPersonaComponent } from "@material-framework/userPersona/user.persona.component";
import { MF_USER_PERSONA_CONFIG_TOKEN, MF_USER_PERSONA_DEFAULT_CONFIG } from "@material-framework/userPersona/user.persona.config";

@NgModule({
  declarations: [
    MfUserPersonaComponent,
    MfUserPersonaCardComponent,
  ],
  imports: [
    BrowserModule,
    MatCardModule,
    MatListModule,
    MatRippleModule,
    OverlayModule,
    MfIconModule,
  ],
  exports: [
    MfUserPersonaComponent,
    MfUserPersonaCardComponent,
  ],
  providers: [
    { provide: MF_USER_PERSONA_CONFIG_TOKEN, useValue: MF_USER_PERSONA_DEFAULT_CONFIG },
  ]
})
export class MfUserPersonaModule {
}