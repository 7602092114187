import {
  Component,
  ContentChild,
  Inject,
  Injector,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfHeaderSubLeftDef, MfHeaderSubRightDef, MfHeaderMainRightDef, MfHeaderMainLeftDef } from "@material-framework/header/header.def.directive";
import { MfMenuItem } from "@material-framework/menu/menu.item";
import { MF_ROOT_CONFIG_TOKEN, MfRootConfig } from "@material-framework/root/root.config";
import { MfRootEndOfContentsDef } from "@material-framework/root/root.def.directive";
import { MfSideDrawerRef, MfRootSideDrawerService } from "@material-framework/root/root.side.drawer.service";
import { MfSnackBarMessageTypes } from "@material-framework/snackbar/snackbar";
import { MfSnackBarService } from "@material-framework/snackbar/snackbar.service";

const TYPE_INFO: MfTypeInfo = { className: "MfRootComponent" };

@Component({
  selector: "mf-root",
  templateUrl: "root.component.html",
  styleUrls: ["root.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfRootComponent extends MfBaseComponent implements OnInit {
  @ViewChild("drawerLeft")
  public drawerLeft?: MatDrawer;

  @ViewChild("drawerRight")
  public drawerRight?: MatDrawer;

  @ViewChild("drawerRightHost", { read: ViewContainerRef })
  public drawerRightHost?: ViewContainerRef;

  @Input()
  public menuItems: MfMenuItem[] = [];

  @ContentChild(MfHeaderSubLeftDef)
  protected _headerSubLeftDef?: MfHeaderSubRightDef;

  @ContentChild(MfHeaderSubRightDef)
  protected _headerSubRightDef?: MfHeaderSubRightDef;

  @ContentChild(MfHeaderMainRightDef)
  protected _headerMainRightDef?: MfHeaderMainRightDef;

  @ContentChild(MfHeaderMainLeftDef)
  protected _headerMainLeftDef?: MfHeaderMainRightDef;

  @ContentChild(MfRootEndOfContentsDef)
  protected _endOfContentsDef?: MfRootEndOfContentsDef;

  protected _rightSideDrawerRef?: MfSideDrawerRef<MfBaseComponent>;
  protected _count = 1;

  public constructor(
    protected override _injector: Injector,
    protected _rootSideDrawerService: MfRootSideDrawerService,
    protected _snackBarService: MfSnackBarService,
    protected _viewContainerRef: ViewContainerRef,
    @Inject(MF_ROOT_CONFIG_TOKEN) public config: MfRootConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    this._loadingService.setViewContainerRef(this._viewContainerRef);
    this._snackBarService.setViewContainerRef(this._viewContainerRef);
    this._sub(this._rootSideDrawerService.onOpenRightSideDrawer, { next: (event) => this._openRightSideDrawer(event) });
  }

  public addSnackMessage(): void {
    this._snackBarService.addMessage({ type: MfSnackBarMessageTypes.info, message: `some snack message ${this._count}` });
    this._count++;
  }

  protected _sideMenuLeftToggle(): void {
    this.drawerLeft?.toggle();
  }

  protected _openRightSideDrawer(drawerRef: MfSideDrawerRef<MfBaseComponent>): void {
    if (!mfTypeIsUndefined(this.drawerRightHost) && !mfTypeIsUndefined(this.drawerRight)) {
      if (!mfTypeIsUndefined(this._rightSideDrawerRef)) {
        this._rightSideDrawerRef.close();
      }

      this._rightSideDrawerRef = drawerRef;

      this.drawerRightHost.clear();
      drawerRef.componentRef = this.drawerRightHost.createComponent(drawerRef.component);
      drawerRef.close = () => this._closeRightSideDrawer(drawerRef);
      this.drawerRight.open();
      drawerRef.onAfterOpened.emit(drawerRef);
      drawerRef.onAfterOpened.complete();
    }
  }

  protected _closeRightSideDrawer(drawerRef: MfSideDrawerRef<MfBaseComponent>): void {
    if (!mfTypeIsUndefined(this.drawerRightHost) && !mfTypeIsUndefined(this.drawerRight) && !mfTypeIsUndefined(drawerRef.componentRef)) {
      delete this._rightSideDrawerRef;
      drawerRef.onBeforeClosed.emit(drawerRef);
      drawerRef.onBeforeClosed.complete();
      this.drawerRight.close();
      this.drawerRightHost.clear();
      drawerRef.onAfterClosed.emit(drawerRef);
      drawerRef.onAfterClosed.complete();
    }
  }
}