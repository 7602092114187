import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";
import { MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG, MfPortalsRequestCollectionData } from "@material-framework/portals/portals";


export type EComInvoiceDetailRequest = MfPortalsRequestCollectionData & {
  key?: string;
  documentKey?: string;
};

export type EComInvoiceDetailRequestModelFieldsConfig = {
  [key in keyof Required<EComInvoiceDetailRequest>]: MfModelFieldConfig
};

export const ECOM_INVOICE_DETAIL_REQUEST_MODEL_FIELDS_CONFIG: EComInvoiceDetailRequestModelFieldsConfig = {
  ...MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG,
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  documentKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  }
};

export const ECOM_INVOICE_DETAIL_REQUEST_MODEL_CONFIG_ID = "invoiceDetailRequest";

export const ECOM_INVOICE_DETAIL_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_DETAIL_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_DETAIL_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_DETAIL_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};


export type EComInvoiceDetailModel = MfModelBase & {
  documentKey: string;
  documentReadable: string;
  documentLineKey: string;
  documentCompany: string;
  documentType: string;
  documentNumber: string;
  documentLineNumber: number;
  abridgedDocumentType: string;
  billingAccountKey: string;
  customerAccountKey: string;
  orderedItemCode: string;
  itemCode: string;
  itemNumberShort: number;
  itemDescription: string;
  orderNumber: string;
  orderType: string;
  orderCompany: string;
  customerOrder: string;
  shipDate: string | null;
  shippedQuantity: number;
  unitOfMeasure: string;
  domesticOrForeign: string;
  currencyPrice: number;
  pricingUnitOfMeasure: string;
  currencyValue: number;
  currencyCode: string;
  carrierAccountKey: string;
  carrierName: string;
  trackingNumber: string;
}

export type EComInvoiceDetailModelFieldsConfig = {
  [key in keyof Required<EComInvoiceDetailModel>]: MfModelFieldConfig
};

export const ECOM_INVOICE_DETAIL_MODEL_FIELDS_CONFIG: EComInvoiceDetailModelFieldsConfig = {
  documentKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  documentReadable: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  documentLineKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  documentCompany: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  documentType: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  documentNumber: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  documentLineNumber: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
    display: {
      displayName: "Line #",
    }
  },
  abridgedDocumentType: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  billingAccountKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  customerAccountKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  orderedItemCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  itemCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Part",
    }
  },
  itemNumberShort: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    }
  },
  itemDescription: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  orderNumber: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Order",
    }
  },
  orderType: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  orderCompany: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  customerOrder: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  shipDate: {
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    },
    display: {
      displayName: "Ship Date",
    }
  },
  shippedQuantity: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
    display: {
      displayName: "Qty"
    }
  },
  unitOfMeasure: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  domesticOrForeign: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  currencyPrice: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
      currency: {},
    },
    display: {
      displayName: "Price"
    }
  },
  pricingUnitOfMeasure: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  currencyValue: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
      currency: {},
    },
    display: {
      displayName: "Value"
    }
  },
  currencyCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Currency"
    }
  },
  carrierAccountKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  carrierName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Shipment Method"
    }
  },
  trackingNumber: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Tracking Number"
    }
  }
};

export const ECOM_INVOICE_DETAIL_MODEL_CONFIG_ID = "invoiceDetail";

export const ECOM_INVOICE_DETAIL_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_DETAIL_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_DETAIL_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_DETAIL_MODEL_CONFIG",
  },
  portals: {}
};