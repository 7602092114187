import { EComCartValidationOrderItemModel } from "@e-commerce/modelConfigs/checkout.order.model.config";
import { MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";
import { MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG, MfPortalsRequestCollectionData } from "@material-framework/portals/portals";

export type EComCartLineRequest = MfPortalsRequestCollectionData & {
  cartKey?: string;
  key?: string;
}

export type EComCartLineRequestModelFieldsConfig = {
  [key in keyof Required<EComCartLineRequest>]: MfModelFieldConfig
};

export const ECOM_CART_LINE_REQUEST_MODEL_FIELDS_CONFIG: EComCartLineRequestModelFieldsConfig = {
  ...MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG,
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  }
};

export const ECOM_CART_LINE_REQUEST_MODEL_CONFIG_ID = "cartLineRequest";

export const ECOM_CART_LINE_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CART_LINE_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_CART_LINE_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CART_LINE_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComCartDeleteMultipleItemsRequest = {
  key: string;
  cartKey: string;
  cartItemKeys: string[];
}

export type EComCartDeleteMultipleItemsRequestModelFieldsConfig = {
  [key in keyof Required<EComCartDeleteMultipleItemsRequest>]: MfModelFieldConfig
};

export const ECOM_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_FIELDS_CONFIG: EComCartDeleteMultipleItemsRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartItemKeys: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.string,
    },
  },
};

export const ECOM_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID = "cartDeleteMultipleItemsRequest";

export const ECOM_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ADD_CART_ITEM_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};


export type EComCreateCartRequest = {
  key: string;
}

export type EComCreateCartRequestModelFieldsConfig = {
  [key in keyof Required<EComCreateCartRequest>]: MfModelFieldConfig
};

export const ECOM_CREATE_CART_REQUEST_MODEL_FIELDS_CONFIG: EComCreateCartRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
};

export const ECOM_CREATE_CART_REQUEST_MODEL_CONFIG_ID = "createCartRequest";

export const ECOM_CREATE_CART_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CREATE_CART_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_CREATE_CART_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CREATE_CART_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComAddCartItemsRequest = {
  key: string;
  items: Omit<EComAddCartItemRequest, "key">[];
}

export type EComAddCartItemsRequestModelFieldsConfig = {
  [key in keyof Required<EComAddCartItemsRequest>]: MfModelFieldConfig
};

export const ECOM_ADD_CART_ITEMS_REQUEST_MODEL_FIELDS_CONFIG: EComAddCartItemsRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  items: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.object,
    },
  },
};

export const ECOM_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG_ID = "addCartItemsRequest";

export const ECOM_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_ADD_CART_ITEMS_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComAddCartItemRequest = {
  key: string;
  itemSpecKey: string;
  quantity: number;
  requiredDate: Date;
}

export type EComAddCartItemRequestModelFieldsConfig = {
  [key in keyof Required<EComAddCartItemRequest>]: MfModelFieldConfig
};

export const ECOM_ADD_CART_ITEM_REQUEST_MODEL_FIELDS_CONFIG: EComAddCartItemRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemSpecKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  quantity: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  requiredDate: {
    dataType: {
      type: MfModelFieldDataTypes.date
    },
  }
};

export const ECOM_ADD_CART_ITEM_REQUEST_MODEL_CONFIG_ID = "addCartItemRequest";

export const ECOM_ADD_CART_ITEM_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ADD_CART_ITEM_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_ADD_CART_ITEM_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ADD_CART_ITEM_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComUpdateCartItemRequest = {
  key: string;
  cartKey: string;
  cartItemKey: string;
  newQuantity?: number;
  quantity: number;
  requiredDate: Date;
}

export type EComUpdateCartItemRequestModelFieldsConfig = {
  [key in keyof Required<EComUpdateCartItemRequest>]: MfModelFieldConfig
};

export const ECOM_UPDATE_CART_ITEM_REQUEST_MODEL_FIELDS_CONFIG: EComUpdateCartItemRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartItemKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  newQuantity: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  quantity: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  requiredDate: {
    dataType: {
      type: MfModelFieldDataTypes.date,
    },
  }
};

export const ECOM_UPDATE_CART_ITEM_REQUEST_MODEL_CONFIG_ID = "updateCartItemRequest";

export const ECOM_UPDATE_CART_ITEM_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_UPDATE_CART_ITEM_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_UPDATE_CART_ITEM_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_UPDATE_CART_ITEM_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComCartUpdateMultipleItemsRequest = {
  key: string;
  cartKey: string;
  cartItemKeys: string[];
  requiredDate: Date;
}

export type EComCartUpdateMultipleItemsRequestModelFieldsConfig = {
  [key in keyof Required<EComCartUpdateMultipleItemsRequest>]: MfModelFieldConfig
};

export const ECOM_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_FIELDS_CONFIG: EComCartUpdateMultipleItemsRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartItemKeys: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  requiredDate: {
    dataType: {
      type: MfModelFieldDataTypes.date,
      valueFormatter: {
        dataTypes: {
          date: {

          }
        }
      }
    },
  }
};

export const ECOM_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID = "cartUpdateMultipleItemsRequest";

export const ECOM_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComCartActionResult = {
  cartKey: string;
  itemCount: number;
  items?: EComCartValidationOrderItemModel[],
  errors?: string[];

}

export type EComCartCountResponseModelFieldsConfig = {
  [key in keyof Required<EComCartActionResult>]: MfModelFieldConfig
};

export const ECOM_CART_RESPONSE_MODEL_FIELDS_CONFIG: EComCartCountResponseModelFieldsConfig = {
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemCount: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  items: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.object,
    },
  },
  errors: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.string,
    },
  },
};

export const ECOM_CART_RESPONSE_MODEL_CONFIG_ID = "cartActionResult";

export const ECOM_CART_RESPONSE_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CART_RESPONSE_MODEL_CONFIG_ID,
  fields: ECOM_CART_RESPONSE_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CART_RESPONSE_MODEL_CONFIG",
  },
  portals: {}
};

export type EComCartLineModel = {
  cartItemKey: string,
  itemKey: string;
  createdBy: string;
  createdDate: Date,
  currency: string;
  currencyUpdated?: string;
  itemCode: string;
  modifiedBy: string;
  modifiedDate: Date,
  price: number,
  priceUnitOfMeasure: string;
  priceUnitOfMeasureUpdated?: string;
  priceUpdated?: number,
  quantity: number,
  availableQuantityUpdated?: number,
  requiredDate: Date,
  requiredDateUpdated?: Date,
  revision: string;
  shelfLifeDays: number,
  site: string;
  unitOfMeasure: string;
  unitOfMeasureUpdated?: string;
  value: number;
}

export type EComCartLineModelFieldsConfig = {
  [key in keyof Required<EComCartLineModel>]: MfModelFieldConfig
};

export const ECOM_CART_LINE_MODEL_FIELDS_CONFIG: EComCartLineModelFieldsConfig = {
  quantity: {
    display: {
      displayName: "Qty Order",
    },
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  requiredDate: {
    display: {
      displayName: "Required Date",
    },
    dataType: {
      type: MfModelFieldDataTypes.date
    },
  },
  requiredDateUpdated: {
    display: {
      displayName: "Required Date Change",
    },
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    },
  },
  site: {
    display: {
      displayName: "Site",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemCode: {
    display: {
      displayName: "Part",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  revision: {
    display: {
      displayName: "Revision",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  shelfLifeDays: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  price: {
    display: {
      displayName: "Price",
    },
    dataType: {
      type: MfModelFieldDataTypes.int,
      currency: {},
    },
  },
  createdDate: {
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    },
  },
  modifiedDate: {
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    },
  },
  cartItemKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  createdBy: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  modifiedBy: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  priceUnitOfMeasure: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  unitOfMeasure: {
    display: {
      displayName: "UoM",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  currency: {
    display: {
      displayName: "Currency",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  value: {
    display: {
      displayName: "Value",
    },
    dataType: {
      type: MfModelFieldDataTypes.decimal,
      currency: {}
    },
  },
  currencyUpdated: {
    display: {
      displayName: "Currency Change",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  priceUnitOfMeasureUpdated: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  priceUpdated: {
    display: {
      displayName: "Price Changed",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  availableQuantityUpdated: {
    display: {
      displayName: "Available Quantity Changed",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  unitOfMeasureUpdated: {
    display: {
      displayName: "UoM Changed",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
};

export const ECOM_CART_LINE_MODEL_CONFIG_ID = "cartLine";

export const ECOM_CART_LINE_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CART_LINE_MODEL_CONFIG_ID,
  fields: ECOM_CART_LINE_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CART_LINE_MODEL_CONFIG",
  },
  portals: {}
};