import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from "@angular-material-components/datetime-picker";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { EComAccountSelectModule } from "@e-commerce/accountSelect/account.select.module";
import { EComCartOrderComponent } from "@e-commerce/cart/order/cart.order.component";
import { EComRequiredDateDialogComponent } from "@e-commerce/cart/order/dialog/required.date.dialog.component";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MfTableModule } from "@material-framework/table/table.module";

@NgModule({
  declarations: [
    EComCartOrderComponent,
    EComRequiredDateDialogComponent,
  ],
  exports: [
    EComCartOrderComponent,
  ],
  imports: [
    BrowserModule,
    EComAccountSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MfIconModule,
    MfTableModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  providers: [
  ]
})
export class EComCartOrderModule { }