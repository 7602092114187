<div class="mf-column ecom-checkout-part-container">
  <div class="ecom-page-header">
    <div class="ecom-title">
      Order Review
    </div>
  </div>
  <div class="mf-row">
    <div class="mf-column ecom-split">
      <div class="ecom-shiping-address">
        <mat-card class="ecom-card-left">
          <mat-card-header>
            <mat-card-title>Shipping Address</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mf-column mf-large-margin-top">
              <div>
                {{checkoutData?.customerDetail?.shippingName}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.address1}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.address2}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.address3}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.address4}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.address5}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.city}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.region}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.postalCode}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.countryCode}}
              </div> 
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="ecom-shipping-method">
        <mat-card class="ecom-card-left">
          <mat-card-header>
            <mat-card-title>Shipping Method</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mf-column mf-large-margin-top">
              <div>
                {{ checkoutData?.carrierName }}
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="mf-column ecom-split">
      <div class="ecom-billing-address">
        <mat-card class="ecom-card-right">
          <mat-card-header>
            <mat-card-title>Billing Address</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mf-column mf-large-margin-top">
              <div>
                {{checkoutData?.customerDetail?.billingName}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.address1}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.address2}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.address3}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.address4}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.address5}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.city}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.region}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.postalCode}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.countryCode}}
              </div> 
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="ecom-purchase-order-reference">
        <mat-card class="ecom-card-right">
          <mat-card-header>
            <mat-card-title>Purchase Order Reference</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mf-column mf-large-margin-top">
              <div>
                {{ checkoutData?.purchaseOrderRef }}
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="ecom-Payment-Method">
        <mat-card class="ecom-card-right">
          <mat-card-header>
            <mat-card-title>Payment Method</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mf-column mf-large-margin-top">
              <div>
                {{ checkoutData?.paymentMethodName }}
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>