import { DatePipe } from "@angular/common";
import { Component, Inject, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPathValue } from "@material-framework/common/utils/object.utils";
import { mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfTableRowCellBaseFormatterComponent } from "@material-framework/table/row/cell/value/table.row.cell.base.formatter.component";
import { MfTableRowCellFormatterBaseOptions } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowCellDateFormatterComponent" };

export type MfTableRowCellDateFormatterOptions = MfTableRowCellFormatterBaseOptions & {
  dateFormat?: string;
}

@Component({
  selector: "mf-table-row-cell-date-formatter-component",
  templateUrl: "table.row.cell.base.formatter.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowCellDateFormatterComponent<TModel extends MfModelBase> extends MfTableRowCellBaseFormatterComponent<MfTableRowCellDateFormatterOptions, TModel> implements OnInit {
  public constructor(
    protected override _injector: Injector,
    protected _datePipe: DatePipe,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    const date = mfObjectGetPropertyPathValue<Date>(this.fieldColumn?.fieldPath, this.rowItem);
    if (!mfTypeIsUndefined(date)) {
      const transformed = this._datePipe.transform(date, this.options?.dateFormat);
      if (!mfTypeIsNullOrUndefined(transformed)) {
        this._displayValue = transformed;
      }
    } else {
      this._displayValue = "";
    }
  }
}