import { Injectable, Injector } from "@angular/core";

import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfCookieGetCookie } from "@material-framework/common/utils/cookie.utils";


const TYPE_INFO: MfTypeInfo = { className: "MfAuthXsrfTokenService" };

@Injectable()
export class MfAuthXsrfTokenService extends MfBaseService {
  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }

  public get(): string {
    return mfCookieGetCookie("XSRF-RequestToken");
  }
}