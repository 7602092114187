import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFilterGroup } from "@material-framework/filter/filter";
import { MfModelFieldsConfig } from "@material-framework/modelConfig/model.config";
import { MfTableHeaderFilterMenuComponent } from "@material-framework/table/header/table.header.filter.menu.component";
import { MfTableFieldColumn, MfTableFilterGroupEvent, MfTableFilterExpressionEvent } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableHeaderFilterComponent" };

@Component({

  selector: "mf-table-header-filter",
  templateUrl: "table.header.filter.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableHeaderFilterComponent extends MfBaseComponent {
  @ViewChild("container")
  public container?: ElementRef;

  @Input()
  public get filterGroup(): MfFilterGroup | undefined {
    return this._filterGroup;
  }
  public set filterGroup(value: MfFilterGroup | undefined) {
    this._filterGroup = value;
    if (!mfTypeIsUndefined(this._filterGroup) && !mfTypeIsUndefined(this._menuRef)) {
      this._menuRef.setInput("filterGroup", this._filterGroup);
    }
  }

  @Input()
  public get modelFieldsConfig(): MfModelFieldsConfig | undefined {
    return this._modelFieldsConfig;
  }
  public set modelFieldsConfig(value: MfModelFieldsConfig | undefined) {
    this._modelFieldsConfig = value;
    if (!mfTypeIsUndefined(this._modelFieldsConfig) && !mfTypeIsUndefined(this._menuRef)) {
      this._menuRef.setInput("modelFieldsConfig", this._modelFieldsConfig);
    }
  }

  @Output()
  public onMenuOpen: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onMenuClose: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onFilterChange: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterGroupChange: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterRemoveGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterAddGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterExpressionChange: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onFilterAddExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onFilterRemoveExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  public isOpen = false;

  protected _overlayRef?: OverlayRef;
  protected _menuPortal?: ComponentPortal<MfTableHeaderFilterMenuComponent>;
  protected _menuRef?: ComponentRef<MfTableHeaderFilterMenuComponent>;
  protected _menu?: MfTableHeaderFilterMenuComponent;
  protected _filterGroup?: MfFilterGroup | undefined;
  protected _modelFieldsConfig?: MfModelFieldsConfig | undefined;

  public constructor(
    protected override _injector: Injector,
    protected _overlay: Overlay,
    protected _viewContainerRef: ViewContainerRef,
    @Inject(MF_TABLE_CONFIG_TOKEN) public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public onHeaderClicked() {
    this._open();
  }

  protected _getConfigOverlay(container: ElementRef): OverlayConfig {
    const positionStrategy = this._overlay
      .position()
      .flexibleConnectedTo(container)
      .withLockedPosition()
      .withViewportMargin(20)
      .withGrowAfterOpen(false)
      .withPush(false)
      .withPositions([{
        originX: "start",
        originY: "bottom",
        overlayX: "start",
        overlayY: "top",
      }]);

    return new OverlayConfig({
      scrollStrategy: this._overlay.scrollStrategies.block(),
      panelClass: ["mf-scroll-panel-y", "mat-elevation-z8"],
      positionStrategy: positionStrategy,
    });
  }

  protected _open(): void {
    if (mfTypeIsUndefined(this._overlayRef) && !mfTypeIsUndefined(this.container)) {

      const overlayConfig = this._getConfigOverlay(this.container);

      this._overlayRef = this._overlay.create(overlayConfig);
      this._menuPortal = new ComponentPortal(MfTableHeaderFilterMenuComponent, this._viewContainerRef, this._injector);
      this._menuRef = this._overlayRef.attach(this._menuPortal);
      this._menu = this._menuRef.instance;

      this._menuRef.setInput("filterGroup", this._filterGroup);
      this._menuRef.setInput("modelFieldsConfig", this._modelFieldsConfig);

      this._sub(this._menuRef.instance.onFilterChange, { next: (e) => this.onFilterChange.emit(e) });
      this._sub(this._menuRef.instance.onFilterGroupChange, { next: (e) => this.onFilterGroupChange.emit(e) });
      this._sub(this._menuRef.instance.onFilterRemoveGroup, { next: (e) => this.onFilterRemoveGroup.emit(e) });
      this._sub(this._menuRef.instance.onFilterAddGroup, { next: (e) => this.onFilterAddGroup.emit(e) });
      this._sub(this._menuRef.instance.onFilterExpressionChange, { next: (e) => this.onFilterExpressionChange.emit(e) });
      this._sub(this._menuRef.instance.onFilterAddExpression, { next: (e) => this.onFilterAddExpression.emit(e) });
      this._sub(this._menuRef.instance.onFilterRemoveExpression, { next: (e) => this.onFilterRemoveExpression.emit(e) });

      setTimeout(() => {
        if (!mfTypeIsUndefined(this._menuRef)) {
          this._sub(this._menuRef.instance.onClickOutside, { next: () => this._close() });
        }
      }, 1);

      this.isOpen = true;
      this.onMenuOpen.emit();
    }
  }

  protected _close(): void {
    if (!mfTypeIsUndefined(this._overlayRef)) {
      this._overlayRef.dispose();
      delete this._overlayRef;
      this.isOpen = false;
      this.onMenuClose.emit();
    }
  }
}