import { DatePipe } from "@angular/common";
import { Inject, InjectionToken, Pipe, PipeTransform } from "@angular/core";

export const MF_DATE_PIPE_CONFIG_TOKEN = new InjectionToken<MfDatePipeConfig>("MfDatePipeConfig");
export const MF_DATE_PIPE_DEFAULT_CONFIG: MfDatePipeConfig = {

};

export type MfDatePipeConfig = {
  format?: string;
  timezone?: string;
  locale?: string;
}

@Pipe({ name: "mfDate" })
export class MfDatePipe implements PipeTransform {
  public constructor(
    protected _datePipe: DatePipe,
    @Inject(MF_DATE_PIPE_CONFIG_TOKEN)
    protected _config: MfDatePipeConfig,
  ) { }

  public transform(value: Date | string | number | undefined) {
    return this._datePipe.transform(value, this._config.format, this._config.timezone, this._config.locale);
  }
}