import { AfterViewInit, Component, Inject, Injector, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComCartLineModel } from "@e-commerce/modelConfigs/cart.line.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComCartService } from "@e-commerce/services/cart.service";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelConfigMapped, MfModelFieldExtendedConfig } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfPortalsTableComponent } from "@material-framework/portals/table/portals.table.utils";
import { MfTableRowCellEditorValueChangeEvent } from "@material-framework/table/row/cell/value/editor/table.row.cell.editor";
import { mfTableReIndexModelFieldExtendedConfig } from "@material-framework/table/table.model.config";

const TYPE_INFO: MfTypeInfo = { className: "EComCartInvoiceComponent" };

const ECOM_PRODUCTS_LOCATION_KEY = "invoice.cart";

const ECOM_PRODUCT_CART_EXTENDED_MODEL_CONFIG: MfModelFieldExtendedConfig[] = [

];

@Component({
  selector: "ecom-cart-invoice",
  templateUrl: "./cart.invoice.component.html",
  styleUrls: ["./cart.invoice.component.scss"]
})
export class EComCartInvoiceComponent extends MfBaseComponent implements AfterViewInit {
  @ViewChild("cartProductsTable", { static: true })
  public get cartProductsTable(): MfPortalsTableComponent<EComCartLineModel, string, number> | undefined {
    return this._cartProductsTable;
  }
  public set cartProductsTable(value: MfPortalsTableComponent<EComCartLineModel, string, number> | undefined) {
    this._cartProductsTable = value;
    this._initializeTable();
  }

  protected _cartLineModelConfig: MfModelConfigMapped = this._modelConfigService.get<EComModelsConfig>("cartLine");
  protected _cartProductsTable?: MfPortalsTableComponent<EComCartLineModel, string, number>;
  protected _locationKey = ECOM_PRODUCTS_LOCATION_KEY;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _modelConfigService: MfModelConfigService,
    protected _cartService: EComCartService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._setModelConfigs();
  }

  public ngAfterViewInit(): void {
    this._cartProductsTable?.loadData();
  }

  protected _goToInvoices(): void {
    this._router.navigate([EcomRouteNames.routeInvoicesList]);
  }

  protected _removeFromCart(cartProduct: EComCartLineModel): void {

  }

  protected _proceedToCheckout(): void {
    this._router.navigate([EcomRouteNames.routeInvoiceCheckoutBillingPayment]);
  }

  protected _rowEditorValueChanged(event: MfTableRowCellEditorValueChangeEvent<EComCartLineModel>): void {
    event.value;
  }

  protected _setModelConfigs(): void {
    mfTableReIndexModelFieldExtendedConfig(ECOM_PRODUCT_CART_EXTENDED_MODEL_CONFIG);
    this._modelConfigService.setExtendedConfigs(this._cartLineModelConfig, ECOM_PRODUCT_CART_EXTENDED_MODEL_CONFIG);
  }

  protected _initializeTable(): void {
    if (!mfTypeIsUndefined(this._cartProductsTable)) {
      this._cartProductsTable.dataSource.url = `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}`;
    }
  }
}