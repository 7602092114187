import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { BrowserModule } from "@angular/platform-browser";
import { EComConfirmationComponent } from "@e-commerce/landing/confirmation/confirmation.component";
import { MfIconModule } from "@material-framework/icon/icon.module";

@NgModule({
  declarations: [
    EComConfirmationComponent
  ],
  exports: [
    EComConfirmationComponent
  ],
  imports: [
    BrowserModule,
    MatCardModule,
    MfIconModule,
    MatButtonModule,
  ],
  providers: [
  ]
})
export class EComConfirmationModule { }