<div class="mf-landing-container"
     [style.color]="_config.login.color"
     [style.backgroundImage]="'url(' + _config.login.backgroundImageUrl + ')'">
  <div *ngIf="_config.login.showSlogan === true"
       class="mf-column mf-slogan">
    <div class="mf-header">
      {{ _config.login.sloganHeaderText }}
    </div>
    <div class="mf-text">
      {{ _config.login.sloganText }}
    </div>
  </div>
  <div *ngIf="!_config.login.showLoginButton"
       class="mf-column mf-message">
    You are not logged in
  </div>
  <div *ngIf="_config.login.showLoginButton"
       class="mf-column mf-message">
    <button color="accent"
            (click)="_onLoginClicked()"
            mat-flat-button>Login</button>
  </div>
</div>