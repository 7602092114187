import { DecimalPipe } from "@angular/common";
import { Inject, InjectionToken, Pipe, PipeTransform } from "@angular/core";

export const MF_DECIMAL_PIPE_CONFIG_TOKEN = new InjectionToken<MfDecimalPipeConfig>("mfIntPipeConfig");
export const MF_DECIMAL_PIPE_DEFAULT_CONFIG: MfDecimalPipeConfig = {
  digitsInfo: "1.2-2",
};

export type MfDecimalPipeConfig = {
  digitsInfo: string;
  locale?: string,
}

@Pipe({ name: "mfDecimal" })
export class MfDecimalPipe implements PipeTransform {
  public constructor(
    protected _decimalPipe: DecimalPipe,
    @Inject(MF_DECIMAL_PIPE_CONFIG_TOKEN)
    protected _config: MfDecimalPipeConfig,
  ) { }

  public transform(value: string | number | null | undefined) {
    return this._decimalPipe.transform(value, this._config.digitsInfo, this._config.locale);
  }
}