import { MfTypeInfo } from "@material-framework/common/type.info";

export class MfError extends Error {
  public typeInfo: MfTypeInfo;
  public methodName: string;
  public errors?: Error | Error[];
  public args?: unknown[];
  public hideFromSnackBar: boolean = false;


  public constructor(
    _typeInfo: MfTypeInfo,
    _methodName: string,
    _message: string,
    _error?: Error | Error[],
    _args?: unknown[]
  ) {
    super();

    this.typeInfo = _typeInfo;
    this.methodName = _methodName;
    this.message = `${_typeInfo.className}.${_methodName} - ${_message}`;
    this.errors = _error;
    this.args = _args;
  }
}

export class MfErrorHttpResponseError extends MfError {
  public statusCode: number;
  public constructor(
    _typeInfo: MfTypeInfo,
    _methodName: string,
    _statusCode: number,
    _message: string,
    _error?: Error | Error[],
    _args?: unknown[]
  ) {
    super(_typeInfo, _methodName, _message, _error, _args);

    this.typeInfo = _typeInfo;
    this.methodName = _methodName;
    this.message = `${_typeInfo.className}.${_methodName} - ${_message}`;
    this.errors = _error;
    this.args = _args;
    this.statusCode = _statusCode;
  }
}

export class MfErrorNotImplemented extends Error {
  public typeInfo: MfTypeInfo;
  public methodName: string;
  public args?: unknown[];

  public constructor(
    _typeInfo: MfTypeInfo,
    _methodName: string,
    _message?: string,
    _args?: unknown[]
  ) {
    super();

    this.typeInfo = _typeInfo;
    this.methodName = _methodName;
    this.message = `${_typeInfo.className}.${_methodName} - Method not implemented - ${_message}`;
    this.args = _args;
  }
}

export class MfErrorInvalidInputParameters extends Error {
  public typeInfo: MfTypeInfo;
  public methodName: string;
  public args?: unknown[];

  public constructor(
    _typeInfo: MfTypeInfo,
    _methodName: string,
    _args?: unknown[]
  ) {
    super();

    this.typeInfo = _typeInfo;
    this.methodName = _methodName;
    this.message = `${_typeInfo.className}.${_methodName} - Invalid input parameters`;
    this.args = _args;
  }
}