import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { Component, ElementRef, EmbeddedViewRef, EventEmitter, Inject, Injector, Input, OnDestroy, Output, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfTableColumnSlideOutActionsDef } from "@material-framework/table/row/column/table.column.actions.def.directive";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowCellSlideOutActionsComponent" };

export type MfTableRowCellSlideOutActionsOpenEvent = {
  component: MfTableRowCellSlideOutActionsComponent,
}

@Component({
  selector: "mf-table-row-cell-slide-out-actions",
  templateUrl: "table.row.cell.slide.out.actions.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowCellSlideOutActionsComponent extends MfBaseComponent implements OnDestroy {
  @Input()
  public columnSlideOutActionsDef?: MfTableColumnSlideOutActionsDef;

  @Input()
  public item?: MfModelBase;

  @Input()
  public location?: "start" | "end";

  @Output()
  public onOpen: EventEmitter<MfTableRowCellSlideOutActionsOpenEvent> = new EventEmitter();

  @ViewChild("slideOutOverlayTemplate", { static: true })
  protected _slideOutOverlayTemplate!: TemplateRef<unknown>;

  @ViewChild("matIconButton", { static: true, read: ElementRef })
  protected _matIconButton!: ElementRef;

  protected _isOpen = false;
  protected _overlayRef?: OverlayRef;
  protected _overlayPortal?: TemplatePortal<unknown>;
  protected _overlayEmbeddedViewRef?: EmbeddedViewRef<unknown>;

  public constructor(
    protected override _injector: Injector,
    protected _viewContainerRef: ViewContainerRef,
    protected _overlay: Overlay,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    protected _config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (!mfTypeIsNullOrUndefined(this._overlayRef)) {
      this._overlayRef.dispose();
    }
  }

  public close(): void {
    this._detachOverlay();
  }

  public open(event?: MouseEvent): void {
    if (!mfTypeIsUndefined(event)) {
      event.stopPropagation();
    }
    if (mfTypeIsUndefined(this._overlayEmbeddedViewRef)) {
      this._attachOverlay();
      this.onOpen.emit({ component: this });
    } else {
      this._detachOverlay();
    }
  }

  protected _attachOverlay(): void {
    if (mfTypeIsNullOrUndefined(this._overlayEmbeddedViewRef)) {
      if (mfTypeIsNullOrUndefined(this._overlayRef)) {
        const config = this._getConfigOverlay();
        this._overlayRef = this._overlay.create(config);
        this._overlayPortal = new TemplatePortal(this._slideOutOverlayTemplate, this._viewContainerRef);
      }
      this._overlayEmbeddedViewRef = this._overlayRef.attach(this._overlayPortal);
      this._isOpen = true;
    }
  }

  protected _detachOverlay(): void {
    if (!mfTypeIsNullOrUndefined(this._overlayEmbeddedViewRef) && !mfTypeIsNullOrUndefined(this._overlayRef)) {
      this._isOpen = false;
      delete this._overlayEmbeddedViewRef;
      this._overlayRef.detach();
    }
  }

  protected _getConfigOverlay(): OverlayConfig {
    const positionStrategy = this._overlay
      .position()
      .flexibleConnectedTo(this._matIconButton)
      .withLockedPosition(true)
      .withViewportMargin(20)
      .withGrowAfterOpen(true)
      .withPush(false)
      .withPositions([{
        originX: "start",
        originY: "top",
        overlayX: "end",
        overlayY: "top",
        offsetX: -20,
        offsetY: -10,
      }]);

    return new OverlayConfig({
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
      panelClass: ["mf-table-slide-out-actions-overlay"],
      positionStrategy: positionStrategy,
    });
  }
}