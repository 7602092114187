<div class="mf-table-footer-container">
  <div class="mf-table-footer-left"
       *ngIf="!_isUndefined(footerLeftDef)">
    <ng-template [ngTemplateOutlet]="footerLeftDef.template">
    </ng-template>
  </div>
  <div class="mf-flex-fill"></div>
  <div class="mf-table-footer-right"
       *ngIf="!_isUndefined(footerRightDef)">
    <ng-template [ngTemplateOutlet]="footerRightDef.template">
    </ng-template>
  </div>
  <div *ngIf="_paginationEnabled"
       class="mf-table-footer-paginator">
    <mat-paginator [class.mf-table-footer-hide-range]="!config.footer.pagination.showRange"
                   [class.mf-table-footer-hide-first]="!config.footer.pagination.showFirstButton"
                   [class.mf-table-footer-hide-last]="!config.footer.pagination.showLastButton"
                   showFirstLastButtons="true"
                   (page)="onPageChange.emit($event)"
                   [pageIndex]="pagination?.pageIndex"
                   [pageSize]="pagination?.pageSize"
                   [length]="pagination?.length"
                   [pageSizeOptions]="config.footer.pagination.pageSizeOptions"></mat-paginator>
  </div>
</div>