<div class="mf-filter-container">
  <ul class="mf-filter">
    <li class="mf-filter-group-main">
      <mf-filter-group (onAddExpression)="_addExpression($event)"
                       (onAddGroup)="_addGroup($event)"
                       (onExpressionChange)="_expressionChange($event)"
                       (onGroupChange)="_groupChange($event)"
                       (onRemoveExpression)="_removeExpression($event)"
                       (onRemoveGroup)="_removeGroup($event)"
                       (onEnterKey)="onEnterKey.emit($event)"
                       [firstLevelGroup]="true"
                       [singleFieldFilter]="singleFieldFilter"
                       [singleFieldPath]="singleFieldPath"
                       [modelFieldsConfig]="modelFieldsConfig"
                       [group]="group"></mf-filter-group>
    </li>
  </ul>
</div>