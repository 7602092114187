import { EComPayPalCardTypes } from "@e-commerce/common/paypal.card.types";
import { EComPayPalResultTypes } from "@e-commerce/common/paypal.result.types";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";

export type EComCheckoutOrderBeginPaymentRequestModel = MfModelBase & {
  key: string;
  cartKey: string;
  purchaseOrderRef: string;
  carrierKey: string;
  paymentMethod: number;
}

export type EComCheckoutOrderBeginPaymentRequestModelFieldsConfig = {
  [key in keyof Required<EComCheckoutOrderBeginPaymentRequestModel>]: MfModelFieldConfig
};

export const ECOM_CHECKOUT_ORDER_BEGIN_PAYMENT_REQUEST_MODEL_FIELDS_CONFIG: EComCheckoutOrderBeginPaymentRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  purchaseOrderRef: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  carrierKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  paymentMethod: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  }
};

export const ECOM_CHECKOUT_ORDER_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG_ID = "checkoutOrderBeginPaymentRequest";

export const ECOM_CHECKOUT_ORDER_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CHECKOUT_ORDER_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_CHECKOUT_ORDER_BEGIN_PAYMENT_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CHECKOUT_ORDER_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComCheckoutOrderBeginPaymentModel = MfModelBase & {
  paymentUrl: string;
}

export type EComCheckoutOrderBeginPaymentModelFieldsConfig = {
  [key in keyof Required<EComCheckoutOrderBeginPaymentModel>]: MfModelFieldConfig
};

export const ECOM_CHECKOUT_ORDER_BEGIN_PAYMENT_MODEL_FIELDS_CONFIG: EComCheckoutOrderBeginPaymentModelFieldsConfig = {
  paymentUrl: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
};

export const ECOM_CHECKOUT_ORDER_BEGIN_PAYMENT_CONFIG_ID = "checkoutOrderBeginPayment";

export const ECOM_CHECKOUT_ORDER_BEGIN_PAYMENT_CONFIG: MfModelConfig = {
  key: ECOM_CHECKOUT_ORDER_BEGIN_PAYMENT_CONFIG_ID,
  fields: ECOM_CHECKOUT_ORDER_BEGIN_PAYMENT_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CHECKOUT_ORDER_BEGIN_PAYMENT_CONFIG",
  },
  portals: {}
};

export type EComPayPalPaymentInfo = MfModelBase & {
  acct: string;
  address: string;
  amt: string;
  authCode: string;
  avsAddr: string;
  avsData: string;
  avsZip: string;
  billToCity: string;
  billToCountry: number;
  billToEmail: string;
  billToFirstName: string;
  billToLastName: string;
  billToName: string;
  billToPhone: string;
  billToState: string;
  billToStreet: string;
  billToZip: string;
  cardType: EComPayPalCardTypes;
  city: string;
  country: number;
  email: string;
  expDate: string;
  firstName: string;
  hostCode: string;
  iavs: string;
  invNum: string;
  invoice: string;
  lastName: string;
  method: string;
  name: string;
  phone: string;
  pnRef: string;
  poNum: string;
  procAvs: string;
  respMsg: string;
  respText: string;
  result: EComPayPalResultTypes;
  secureToken: string;
  secureTokenId: string;
  state: string;
  tax: number;
  tender: string;
  transTime: Date;
  trxType: string;
  type: string;
  visaCardLevel: string;
  zip: string;
}


export type EComCheckoutOrderEndPaymentRequestModel = MfModelBase & {
  key: string;
  cartKey: string;
  paymentInfo: EComPayPalPaymentInfo;
}

export type EComCheckoutOrderEndPaymentRequestModelFieldsConfig = {
  [key in keyof Required<EComCheckoutOrderEndPaymentRequestModel>]: MfModelFieldConfig
};

export const ECOM_CHECKOUT_ORDER_END_PAYMENT_REQUEST_MODEL_FIELDS_CONFIG: EComCheckoutOrderEndPaymentRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  paymentInfo: {
    dataType: {
      type: MfModelFieldDataTypes.object
    },
  },
};

export const ECOM_CHECKOUT_ORDER_END_PAYMENT_REQUEST_MODEL_CONFIG_ID = "checkoutOrderEndPaymentRequest";

export const ECOM_CHECKOUT_ORDER_END_PAYMENT_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CHECKOUT_ORDER_END_PAYMENT_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_CHECKOUT_ORDER_END_PAYMENT_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CHECKOUT_ORDER_END_PAYMENT_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};
























export type EComCheckoutOrderEndPaymentModel = MfModelBase & {
}

export type EComCheckoutOrderEndPaymentModelFieldsConfig = {
  [key in keyof Required<EComCheckoutOrderEndPaymentModel>]: MfModelFieldConfig
};

export const ECOM_CHECKOUT_ORDER_END_PAYMENT_MODEL_FIELDS_CONFIG: EComCheckoutOrderEndPaymentModelFieldsConfig = {

};

export const ECOM_CHECKOUT_ORDER_END_PAYMENT_CONFIG_ID = "checkoutOrderEndPayment";

export const ECOM_CHECKOUT_ORDER_END_PAYMENT_CONFIG: MfModelConfig = {
  key: ECOM_CHECKOUT_ORDER_END_PAYMENT_CONFIG_ID,
  fields: ECOM_CHECKOUT_ORDER_END_PAYMENT_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CHECKOUT_ORDER_END_PAYMENT_CONFIG",
  },
  portals: {}
};

