import { NGX_MAT_DATE_FORMATS } from "@angular-material-components/datetime-picker";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CSP_NONCE, Inject, NgModule } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { EComAccountSelectModule } from "@e-commerce/accountSelect/account.select.module";
import { EComCartInvoiceModule } from "@e-commerce/cart/invoice/cart.invoice.module";
import { EComCartOrderModule } from "@e-commerce/cart/order/cart.order.module";
import { EComCheckoutInvoiceModule } from "@e-commerce/checkout/invoice/checkout.invoice.module";
import { EComCheckoutOrderModule } from "@e-commerce/checkout/order/checkout.order.module";
import { ECOM_AUTH_CODE_FLOW_CONFIG, ECOM_AUTH_LANDING_CONFIG, ECOM_CONFIG_TOKEN, ECOM_CURRENCY_PIPE_CONFIG, ECOM_DATE_FORMATS, ECOM_DEFAULT_CONFIG, ECOM_ICONS_CONFIG_TOKEN, ECOM_ICONS_DEFAULT_CONFIG, ECOM_INPUT_DEFAULT_CONFIG, ECOM_LINKS_CONFIG_TOKEN, ECOM_LINKS_DEFAULT_CONFIG, ECOM_LOADING_CONFIG, ECOM_PORTALS_DEFAULT_CONFIG, ECOM_TABLE_CONFIG, ECOM_VIEW_MANAGER_CONFIG, EComConfig } from "@e-commerce/ecom.config";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { EComRoutingModule } from "@e-commerce/ecom.routing.module";
import { EComEntryPointComponent } from "@e-commerce/entryPoint/entry.point.component";
import { EComEntryPointModule } from "@e-commerce/entryPoint/entry.point.module";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComFooterModule } from "@e-commerce/footer/footer.module";
import { EComHomeModule } from "@e-commerce/home/home.module";
import { EComInvoiceDetailModule } from "@e-commerce/invoiceDetail/invoice.detail.module";
import { EComInvoiceDetailOrdersModule } from "@e-commerce/invoiceDetailOrders/invoice.detail.orders.module";
import { EComInvoicesModule } from "@e-commerce/invoices/invoices.module";
import { EComConfirmationModule } from "@e-commerce/landing/confirmation/confirmation.module";
import { EComNotApprovedModule } from "@e-commerce/landing/notApproved/not.approved.module";
import { EComPaypalCancelModule } from "@e-commerce/landing/paypal/cancel/paypal.cancel.module";
import { EComPaypalErrorModule } from "@e-commerce/landing/paypal/error/paypal.error.module";
import { EComPaypalReturnModule } from "@e-commerce/landing/paypal/return/paypal.return.module";
import { EComPaypalSilentPostModule } from "@e-commerce/landing/paypal/silentPost/paypal.silent.post.module";
import { ECOM_MODEL_CONFIG } from "@e-commerce/modelConfigs/model.config";
import { EComOrdersModule } from "@e-commerce/orders/orders.module";
import { EComProductDetailModule } from "@e-commerce/productDetail/product.detail.module";
import { EComProductsModule } from "@e-commerce/products/products.module";
import { EComRootAuthnModule } from "@e-commerce/root/authn/root.authn.module";
import { EComRootUnAuthnModule } from "@e-commerce/root/unauthn/root.unauthn.module";
import { EComServiceModule } from "@e-commerce/services/services.module";
import { EComUserService } from "@e-commerce/services/user.service";
import { EComTableCellModule } from "@e-commerce/table/cell/tabel.cell.module";
import { MfAuthAccessTokenInjectorInterceptor } from "@material-framework/auth/auth.access.token.injector.interceptor";
import { MF_AUTH_CONFIG_TOKEN } from "@material-framework/auth/auth.config";
import { MF_AUTHENTICATED_URLS_EXCLUDE_TOKEN, MF_AUTHENTICATED_URLS_TOKEN, MfAuthService } from "@material-framework/auth/auth.service";
import { MfAuthXsrfTokenInjectorInterceptor } from "@material-framework/auth/auth.xsrf.token.injector.interceptor";
import { MF_AUTH_LANDING_CONFIG_TOKEN } from "@material-framework/auth/landing/auth.landing.config";
import { MfFillRouterOutletModule } from "@material-framework/fillRouterOutlet/fill.router.outlet.module";
import { MF_FILTER_CONFIG_TOKEN } from "@material-framework/filter/filter.config";
import { MF_FILTER_CONVERTER_SERVICE_TOKEN } from "@material-framework/filter/services/filter.loader";
import { MF_INPUT_CONFIG_TOKEN } from "@material-framework/input/input.config";
import { MF_LOADING_CONFIG_TOKEN } from "@material-framework/loading/loading.config";
import { MF_MODEL_CONFIGS_TOKEN } from "@material-framework/modelConfig/model.config";
import { MF_CURRENCY_PIPE_CONFIG_TOKEN } from "@material-framework/pipes/currency.pipe";
import { MfPortalsFilterConverterService } from "@material-framework/portals/filter/portals.filter.converter.service";
import { MF_PORTALS_FILTER_CONFIG } from "@material-framework/portals/portals.filter.config";
import { MfPortalsModule } from "@material-framework/portals/portals.module";
import { MfSessionStorageService } from "@material-framework/storage/session.storage.service";
import { MF_TABLE_CONFIG_TOKEN } from "@material-framework/table/table.config";
import { MF_VIEW_MANAGER_LOCAL_STORAGE_KEY, MfViewManagerSessionStorageService } from "@material-framework/viewManager/view.manager.base.storage.service";
import { MF_VIEW_MANAGER_CONFIG_TOKEN } from "@material-framework/viewManager/view.manager.config";
import { MfViewManagerStorageService } from "@material-framework/viewManager/view.manager.storage.service";
import { AuthConfig, OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";
import { MF_PORTALS_CONFIG_TOKEN } from "../../../material-framework/src/lib/portals/portals.config";

const nonce = (
  document.querySelector("meta[name=\"CSP_NONCE\"]") as HTMLMetaElement
)?.content;

@NgModule({
  imports: [
    BrowserModule,
    EComAccountSelectModule,
    EComCartInvoiceModule,
    EComCartOrderModule,
    EComCheckoutInvoiceModule,
    EComCheckoutOrderModule,
    EComConfirmationModule,
    EComEntryPointModule,
    EComFooterModule,
    EComHomeModule,
    EComInvoiceDetailModule,
    EComInvoiceDetailOrdersModule,
    EComInvoicesModule,
    EComNotApprovedModule,
    EComOrdersModule,
    EComPaypalCancelModule,
    EComPaypalErrorModule,
    EComPaypalReturnModule,
    EComPaypalReturnModule,
    EComPaypalSilentPostModule,
    EComProductDetailModule,
    EComProductsModule,
    EComRootAuthnModule,
    EComRootUnAuthnModule,
    EComRoutingModule,
    EComServiceModule,
    EComTableCellModule,
    MfFillRouterOutletModule,
    MfPortalsModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    // eslint-disable-next-line @typescript-eslint/naming-convention
    { provide: MF_PORTALS_CONFIG_TOKEN, useValue: ECOM_PORTALS_DEFAULT_CONFIG },
    { provide: OAuthStorage, useValue: localStorage },
    { provide: CSP_NONCE, useValue: nonce, },
    { provide: ECOM_CONFIG_TOKEN, useValue: ECOM_DEFAULT_CONFIG },
    { provide: ECOM_ICONS_CONFIG_TOKEN, useValue: ECOM_ICONS_DEFAULT_CONFIG },
    { provide: ECOM_LINKS_CONFIG_TOKEN, useValue: ECOM_LINKS_DEFAULT_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: MfAuthAccessTokenInjectorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MfAuthXsrfTokenInjectorInterceptor, multi: true },
    { provide: MF_AUTH_CONFIG_TOKEN, useValue: ECOM_AUTH_CODE_FLOW_CONFIG },
    { provide: MF_AUTH_LANDING_CONFIG_TOKEN, useValue: ECOM_AUTH_LANDING_CONFIG },
    { provide: MF_AUTHENTICATED_URLS_EXCLUDE_TOKEN, useValue: ECOM_ENVIRONMENT.authenticatedUrlsExclude },
    { provide: MF_AUTHENTICATED_URLS_TOKEN, useValue: ECOM_ENVIRONMENT.authenticatedUrls },
    { provide: MF_CURRENCY_PIPE_CONFIG_TOKEN, useValue: ECOM_CURRENCY_PIPE_CONFIG },
    { provide: MF_FILTER_CONFIG_TOKEN, useValue: MF_PORTALS_FILTER_CONFIG },
    { provide: MF_FILTER_CONVERTER_SERVICE_TOKEN, useClass: MfPortalsFilterConverterService },
    { provide: MF_INPUT_CONFIG_TOKEN, useValue: ECOM_INPUT_DEFAULT_CONFIG },
    { provide: MF_LOADING_CONFIG_TOKEN, useValue: ECOM_LOADING_CONFIG },
    { provide: MF_MODEL_CONFIGS_TOKEN, useValue: ECOM_MODEL_CONFIG },
    { provide: MF_TABLE_CONFIG_TOKEN, useValue: ECOM_TABLE_CONFIG },
    { provide: MF_VIEW_MANAGER_CONFIG_TOKEN, useValue: ECOM_VIEW_MANAGER_CONFIG },
    { provide: MfViewManagerStorageService, useClass: MfViewManagerSessionStorageService },
    { provide: NGX_MAT_DATE_FORMATS, useValue: ECOM_DATE_FORMATS },
  ],
  bootstrap: [
    EComEntryPointComponent
  ]
})
export class EComModule {
  public constructor(
    protected _authService: MfAuthService,
    @Inject(MF_AUTH_CONFIG_TOKEN)
    protected _authConfig: AuthConfig,
    protected _userService: EComUserService,
    protected _router: Router,
    protected _storageService: MfSessionStorageService,
    protected _title: Title,
    @Inject(ECOM_CONFIG_TOKEN)
    protected _config: EComConfig,
  ) {
    this._authService.configure(_authConfig);
    this._authService.tokenValidationHandler = new JwksValidationHandler();

    this._userService.isApproved.subscribe({
      next: (isApproved) => {
        if (isApproved === false) {
          this._router.navigate([EcomRouteNames.routeNotApproved]);
        }
      }
    });

    this._authService.onLoggedOut.subscribe({
      next: () => {
        this._storageService.remove(MF_VIEW_MANAGER_LOCAL_STORAGE_KEY);
      }
    });

    this._title.setTitle(this._config.name);
  }
}
