export const MF_KEYBOARD_KEY_CODE_0: string = "0" as const;
export const MF_KEYBOARD_KEY_CODE_1: string = "1" as const;
export const MF_KEYBOARD_KEY_CODE_2: string = "2" as const;
export const MF_KEYBOARD_KEY_CODE_3: string = "3" as const;
export const MF_KEYBOARD_KEY_CODE_4: string = "4" as const;
export const MF_KEYBOARD_KEY_CODE_5: string = "5" as const;
export const MF_KEYBOARD_KEY_CODE_6: string = "6" as const;
export const MF_KEYBOARD_KEY_CODE_7: string = "7" as const;
export const MF_KEYBOARD_KEY_CODE_8: string = "8" as const;
export const MF_KEYBOARD_KEY_CODE_9: string = "9" as const;
export const MF_KEYBOARD_KEY_CODE_ARROW_DOWN: string = "ArrowDown" as const;
export const MF_KEYBOARD_KEY_CODE_ARROW_LEFT: string = "ArrowLeft" as const;
export const MF_KEYBOARD_KEY_CODE_ARROW_RIGHT: string = "ArrowRight" as const;
export const MF_KEYBOARD_KEY_CODE_ARROW_UP: string = "ArrowUp" as const;
export const MF_KEYBOARD_KEY_CODE_BACKSPACE: string = "Backspace" as const;
export const MF_KEYBOARD_KEY_CODE_COPY: string = "Copy" as const;
export const MF_KEYBOARD_KEY_CODE_CUT: string = "Cut" as const;
export const MF_KEYBOARD_KEY_CODE_DELETE: string = "Delete" as const;
export const MF_KEYBOARD_KEY_CODE_PASTE: string = "Paste" as const;
export const MF_KEYBOARD_KEY_CODE_TAB: string = "Tab" as const;
export const MF_KEYBOARD_KEY_CODE_CONTROL: string = "Control" as const;
export const MF_KEYBOARD_KEY_CODE_ALT: string = "Alt" as const;
export const MF_KEYBOARD_KEY_CODE_SHIFT: string = "Shift" as const;

export const MF_KEYBOARD_KEY_CODE_NUMBERS = [
  MF_KEYBOARD_KEY_CODE_0,
  MF_KEYBOARD_KEY_CODE_1,
  MF_KEYBOARD_KEY_CODE_2,
  MF_KEYBOARD_KEY_CODE_3,
  MF_KEYBOARD_KEY_CODE_4,
  MF_KEYBOARD_KEY_CODE_5,
  MF_KEYBOARD_KEY_CODE_6,
  MF_KEYBOARD_KEY_CODE_7,
  MF_KEYBOARD_KEY_CODE_8,
  MF_KEYBOARD_KEY_CODE_9
];


export function mfKeyboardIsNumberKeyCode(key: string): boolean {
  return MF_KEYBOARD_KEY_CODE_NUMBERS.indexOf(key) !== -1;
}