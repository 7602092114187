import { Injector } from "@angular/core";
import { MfError } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfFilterGroup } from "@material-framework/filter/filter";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfTableSelectionManager } from "@material-framework/table/selectionManager/table.selection.manager";
import { MfTableFieldColumn } from "@material-framework/table/table";
import { MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfGraphQLTableSelectAllSelectionManager" };

export const MF_TABLE_GRAPH_QL_SELECTION_MANAGER_ALIAS_POSTFIX = "_SelectionManager";
export const MF_TABLE_GRAPH_QL_SELECTION_MANAGER_LOCAL_STORAGE_POSTFIX = "_SelectionManager";

export class MfPortalsTableSelectionManager<TModel extends MfModelBase, TTrackBy = unknown> extends MfTableSelectionManager<TModel, TTrackBy> {

  public isPortalsSelectionManager = true;

  public constructor(
    protected override _injector: Injector,
    protected override _config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector, _config);
  }

  public get isAllSelected(): boolean {
    throw new MfError(this.typeInfo, "isAllSelected", "Method not implemented.");
  }

  public get selectedCount(): number {
    throw new MfError(this.typeInfo, "selectedCount", "Method not implemented.");
  }

  public get selected(): TTrackBy[] {
    throw new MfError(this.typeInfo, "selected", "Method not implemented.");
  }

  public get all(): TTrackBy[] {
    throw new MfError(this.typeInfo, "all", "Method not implemented.");
  }

  public clearSelection(): void {
    throw new MfError(this.typeInfo, "clearSelection", "Method not implemented.");
  }

  public cancel(): void {
    throw new MfError(this.typeInfo, "cancel", "Method not implemented.");
  }

  public load(group: MfFilterGroup, fieldColumns: MfTableFieldColumn[]): void {
  }

  public isSelected(item: TModel): boolean {
    throw new MfError(this.typeInfo, "isSelected", "Method not implemented.");
  }

  public isTracked(item: TModel): boolean {
    throw new MfError(this.typeInfo, "isTracked", "Method not implemented.");
  }

  public setSelectedItems(items: TModel[]): void {
    throw new MfError(this.typeInfo, "setSelectedItems", "Method not implemented.");
  }

  public setSelectedByTrackedBy(trackedByValues: TTrackBy[], clearSelection: boolean): void {
    throw new MfError(this.typeInfo, "setSelectedByTrackedBy", "Method not implemented.");
  }

  public setAllItemsSelected(checked: boolean, modelConfig: MfModelConfigMapped): void {
    throw new MfError(this.typeInfo, "setAllItemsSelected", "Method not implemented.");
  }

  public setItemSelected(items: TModel, checked: boolean): void {
    throw new MfError(this.typeInfo, "setItemSelected", "Method not implemented.");
  }

  public setModelConfig(modelConfig: MfModelConfigMapped): void {
    throw new MfError(this.typeInfo, "setModelConfig", "Method not implemented.");
  }

  public getItemTrackedByValue(item: TModel): TTrackBy | undefined {
    throw new MfError(this.typeInfo, "getItemTrackedByValue", "Method not implemented.");
  }
}