<div #container
     class="mf-user-persona-container">
  <mat-card [class.mf-user-persona-info-card-enabled]="infoCardEnabled"
            [class.mf-user-persona-info-card-hover]="_infoCardIsOpen"
            (click)="_toggleInfoCard()">
    <mat-card-header class="mf-user-persona-header">
      <div mat-card-avatar
           class="mf-user-persona-avatar">
        <img *ngIf="!_isNullOrUndefined(persona) && !_isNullOrUndefined(persona.photo)"
             class="mf-user-persona-avatar-img"
             [src]="persona.photo" />
        <div class="mf-user-persona-avatar-initials"
             *ngIf="!_isNullOrUndefined(persona) && _isNullOrUndefined(persona.photo)">
          {{ persona.initials }}
        </div>
      </div>
      <mat-card-title class="mf-user-persona-title">{{ persona?.displayName }}</mat-card-title>
      <mat-card-subtitle *ngIf="!_isUndefined(persona) && !_isUndefined(persona.eMail)"
                         class="mf-user-persona-subtitle">{{ persona.eMail }}</mat-card-subtitle>
      <mat-card-subtitle *ngIf="!_isUndefined(persona) && _isUndefined(persona.eMail) && !_isUndefined(persona.jobTitle)"
                         class="mf-user-persona-subtitle">{{ persona.jobTitle }}</mat-card-subtitle>
    </mat-card-header>
  </mat-card>
</div>