import { ErrorHandler, EventEmitter, Inject, Injectable, NgZone } from "@angular/core";
import { MfBaseObject } from "@material-framework/base/base.object";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MF_ERROR_CONFIG_TOKEN, MfErrorConfig } from "@material-framework/error/error.config";

const TYPE_INFO: MfTypeInfo = { className: "MfErrorService" };

const onGlobalError: EventEmitter<Error> = new EventEmitter();

export class MfGlobalErrorHandler implements ErrorHandler {
  public handleError(error: Error): void {
    onGlobalError.emit(error);
  }
}

@Injectable()
export class MfErrorService extends MfBaseObject {
  public onError: EventEmitter<Error> = new EventEmitter();

  public constructor(
    protected _zone: NgZone,
    @Inject(MF_ERROR_CONFIG_TOKEN)
    public config: MfErrorConfig,
  ) {
    super(TYPE_INFO);
    onGlobalError.subscribe({
      next: (error: Error) => this.handleError(error)
    });
  }

  public handleError(error: Error): void {
    if (this.config.logErrorToConsole === true) {
      console.error(error);
    }
    if (this.config.showInSnackbar === true) {
      this._zone.run(() => {
        this.onError.emit(error);
      });
    }
  }
}