import { Component, Inject, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";

const TYPE_INFO: MfTypeInfo = { className: "EComConfirmationComponent" };

@Component({
  selector: "ecom-confirmation",
  templateUrl: "./confirmation.component.html",
  styleUrls: ["./confirmation.component.scss"]
})
export class EComConfirmationComponent extends MfBaseComponent {
  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected _goHome(): void {
    this._router.navigate([EcomRouteNames.routeHome]);
  }
}