import { Directive, Injector } from "@angular/core";
import { FormControl, FormControlStatus, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeHasOwnProperty, mfTypeIsNull, mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfValidatorFunction } from "@material-framework/common/validation/validation";
import { MfValidationMessage } from "@material-framework/common/validation/validation.message";



@Directive()
export abstract class MfBaseValidationComponent extends MfBaseComponent {
  protected _errorMessages: { [formControlName: string]: string } = {};
  protected _validationMessages: { [formControlName: string]: MfValidationMessage[] } = {};

  public constructor(
    protected override _typeInfo: MfTypeInfo,
    protected override _injector: Injector,
  ) {
    super(_typeInfo, _injector);
  }

  protected _addMfValidatorFunction(formControlName: string, formControl: FormControl, validatorFunction: MfValidatorFunction, validationMessage: MfValidationMessage): void {
    switch (validatorFunction.type) {
      case "min":
        this._addValidation(formControlName, formControl, Validators.min(validatorFunction.params.min), validationMessage);
        break;
      case "max":
        this._addValidation(formControlName, formControl, Validators.max(validatorFunction.params.max), validationMessage);
        break;
      case "minLength":
        this._addValidation(formControlName, formControl, Validators.minLength(validatorFunction.params.minLength), validationMessage);
        break;
      case "maxLength":
        this._addValidation(formControlName, formControl, Validators.maxLength(validatorFunction.params.maxLength), validationMessage);
        break;
      case "pattern":
        this._addValidation(formControlName, formControl, Validators.pattern(validatorFunction.params.pattern), validationMessage);
        break;
      case "required":
        this._addValidation(formControlName, formControl, Validators.required, validationMessage);
        break;
      case "requiredTrue":
        this._addValidation(formControlName, formControl, Validators.requiredTrue, validationMessage);
        break;
      case "email":
        this._addValidation(formControlName, formControl, Validators.email, validationMessage);
        break;
      case "nullValidator":
        this._addValidation(formControlName, formControl, Validators.nullValidator, validationMessage);
        break;
    }
  }

  protected _addValidation(formControlName: string, formControl: FormControl, validationFunction: ValidatorFn, validationMessage: MfValidationMessage): void {
    formControl.addValidators(validationFunction);

    if (mfTypeIsUndefined(this._validationMessages[formControlName])) {
      this._validationMessages[formControlName] = [];
    }

    let message = validationMessage.message;

    if (!mfTypeIsNullOrUndefined(message)) {
      if (!mfTypeIsNull(message) && !mfTypeIsNullOrUndefined(validationMessage.messageParams)) {
        const messageParamsLength = validationMessage.messageParams?.length;
        for (let pI = 0; pI < messageParamsLength; pI++) {
          message = message.replace(`{${pI}}`, validationMessage.messageParams[pI].toString());
        }
      }

      validationMessage.displayMessage = message;
    }

    this._validationMessages[formControlName].push(validationMessage);
  }

  protected _addFormControlToFormGroup(formGroup: FormGroup, formControlName: string, formControl: FormControl): void {
    formGroup.addControl(formControlName, formControl);
    formControl.markAsTouched();
  }

  protected _addFormControlErrorMessageHandling(formControlName: string, formControl: FormControl): void {
    if (formControl.status === "INVALID") {
      this._setErrorMessages(formControlName, formControl);
    } else {
      delete this._errorMessages[formControlName];
    }

    this._sub(formControl.statusChanges, {
      next: (status: FormControlStatus) => {
        if (status === "INVALID") {
          this._setErrorMessages(formControlName, formControl);
        } else {
          delete this._errorMessages[formControlName];
        }
      }
    });
  }

  protected _setFormControlValue<TValue>(formControl: FormControl, value: TValue): void {
    formControl.setValue(value);
  }

  protected _setErrorMessages(formControlName: string, formControl: FormControl): void {
    if (!mfTypeIsNullOrUndefined(formControl.errors)) {
      const validationMessages: MfValidationMessage[] = [];

      const errorKeys = Object.keys(formControl.errors);
      const errorKeysLength = errorKeys.length;
      for (let kI = 0; kI < errorKeysLength; kI++) {
        const formControlValidationMessages = this._validationMessages[formControlName];
        const errorKey = errorKeys[kI];
        const error = formControl.errors[errorKey];
        const messagesLength = formControlValidationMessages.length;
        for (let mI = 0; mI < messagesLength; mI++) {
          const validationMessage = formControlValidationMessages[mI];

          if (mfTypeHasOwnProperty(error, "message")) {
            validationMessage.displayMessage = error.message;
          }

          if (validationMessage.validatorName.toLocaleLowerCase() === errorKey.toLocaleLowerCase()) {
            validationMessages.push(validationMessage);
          }
        }
      }

      if (validationMessages.length > 0) {
        const validationMessage = validationMessages.reduce((prev, curr) => prev.priority < curr.priority ? prev : curr);
        if (!mfTypeIsUndefined(validationMessage.displayMessage)) {
          this._errorMessages[formControlName] = validationMessage.displayMessage;
        }
      }
    } else {
      delete this._errorMessages[formControlName];
    }
  }
}