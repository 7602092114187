import { Component, ContentChild, EventEmitter, Inject, Injector, Input, Output, ViewEncapsulation } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfMouseEvent } from "@material-framework/common/mouse.event";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MF_HEADER_CONFIG_TOKEN, MfHeaderConfig } from "@material-framework/header/header.config";
import { MfHeaderMainLeftDef, MfHeaderMainRightDef, MfHeaderSubLeftDef, MfHeaderSubRightDef } from "@material-framework/header/header.def.directive";


const TYPE_INFO: MfTypeInfo = { className: "MfHeaderComponent" };

@Component({
  selector: "mf-header",
  templateUrl: "header.component.html",
  styleUrls: ["header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfHeaderComponent extends MfBaseComponent {
  @Input()
  public menuOpen = false;

  @Input()
  public menuItemsCount?: number;

  @Output()
  public onSideMenuToggleClicked: EventEmitter<MfMouseEvent> = new EventEmitter();

  @ContentChild(MfHeaderMainLeftDef)
  protected _headerMainLeftDef?: MfHeaderMainLeftDef;

  @ContentChild(MfHeaderMainRightDef)
  protected _headerMainRightDef?: MfHeaderMainRightDef;

  @ContentChild(MfHeaderSubLeftDef)
  protected _headerSubLeftDef?: MfHeaderSubLeftDef;

  @ContentChild(MfHeaderSubRightDef)
  protected _headerSubRightDef?: MfHeaderSubRightDef;

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_HEADER_CONFIG_TOKEN)
    protected _config: MfHeaderConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected _onSideMenuToggleClick(event: MouseEvent): void {
    this.menuOpen = !this.menuOpen;
    this.onSideMenuToggleClicked.next(event);
  }
}