import { InjectionToken } from "@angular/core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { MfBreadcrumb } from "@material-framework/breadcrumb/breadcrumb";
import { MfIcon } from "@material-framework/icon/icon";
import { MfIconTypes } from "@material-framework/icon/icon.types";


export const MF_BREADCRUMB_CONFIG_TOKEN = new InjectionToken<MfBreadcrumbCompConfig>("MfBreadcrumbCompConfig");
export const MF_BREADCRUMB_DEFAULT_CONFIG: MfBreadcrumbCompConfig = {
  nextIcon: {
    type: MfIconTypes.fontAwesome,
    icon: faChevronRight,
  }
};

export type MfBreadcrumbCompConfig = {
  nextIcon: MfIcon;
}

/**
 * Breadcrumb config provided as part of App Route Config
 */
export type MfBreadcrumbRouteConfig = {
  /**
   * breadcrumb label for a route
   */
  label?: string | MfBreadcrumbLabelFunction;
  /**
   * unique alias name for a route path that can be used to dynamically update a route's breadcrumb label via breadcrumbService.set()
   */
  alias?: string;
  /**
   * hide or show the breadcrumb item
   */
  skip?: boolean;
  /**
   * disable a certain breadcrumb in the list. Not clickable.
   * It may be needed when the routing has the path, but navigating to that path is of no use
   */
  disable?: boolean;
  /**
   * breadcrumb icon for a route
   */
  icon?: MfIcon;
  /**
   * Interceptor for breadcrumb click action that returns the dynamic path
   * Consumers can change the breadcrumb routing dynamically with this approach
   */
  routeInterceptor?: (routeLink: string, breadcrumb: MfBreadcrumb) => string;
}

// resolved label for a route can further be enhanced based on a function
export type MfBreadcrumbLabelFunction = (resolvedLabel?: string) => string;