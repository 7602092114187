import { Component, EventEmitter, Injector, Input, Output, ViewEncapsulation } from "@angular/core";
import { MfActionCardAction, MfActionCardActions } from "@material-framework/actionCards/action.cards";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";

const TYPE_INFO: MfTypeInfo = { className: "MfActionCardsComponent" };

@Component({
  selector: "mf-action-cards",
  templateUrl: "action.cards.component.html",
  styleUrls: ["action.cards.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfActionCardsComponent extends MfBaseComponent {
  @Input()
  public actions?: MfActionCardActions;

  @Output()
  public onActionClicked: EventEmitter<MfActionCardAction> = new EventEmitter();

  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }
}