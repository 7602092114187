import { Injectable, Injector } from "@angular/core";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfStringIsEmptyOrWhitespace, mfStringTrimEnd, mfStringTrimStart } from "@material-framework/common/utils/string.utils";
import { mfTypeIsString, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFilterExpression, MfFilterGroup } from "@material-framework/filter/filter";
import { MfFilterConverterService } from "@material-framework/filter/services/filter.loader";
import { MfFilterService } from "@material-framework/filter/services/filter.service";
import { MfModelFieldDataTypes, MfModelFieldsConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfPortalsFilteringOperatorTypes, mfFilterOperatorTypeToMfPortalsFilteringOperatorType, mfPortalsFilteringOperatorTypeToMfFilterOperatorType } from "@material-framework/portals/filter/portals.filter";


const TYPE_INFO: MfTypeInfo = { className: "MfPortalsFilterConverterService" };
const MF_PORTALS_FILTER_CONVERTER_AND = " AND ";

@Injectable()
export class MfPortalsFilterConverterService extends MfBaseService implements MfFilterConverterService<string> {
  public constructor(
    protected override _injector: Injector,
    protected _filterService: MfFilterService,
    protected _modelConfigService: MfModelConfigService,
  ) {
    super(TYPE_INFO, _injector);
  }

  public toGroup(portalsFiltering: string, modelConfigOrModelKey: string | MfModelFieldsConfigMapped): MfFilterGroup {
    const group = this._filterService.getNewFilterGroup();

    if (!mfStringIsEmptyOrWhitespace(portalsFiltering)) {

      const modelConfig = mfTypeIsString(modelConfigOrModelKey) ? this._modelConfigService.get(modelConfigOrModelKey).fields : modelConfigOrModelKey;

      const expressions = portalsFiltering.split(MF_PORTALS_FILTER_CONVERTER_AND);
      const expressionsLength = expressions.length;

      for (let expressionIndex = 0; expressionIndex < expressionsLength; expressionIndex++) {
        const groupPart = this._filterService.getNewFilterGroup();

        const strExpression = expressions[expressionIndex];
        const strExpressionParts = strExpression.split(" ");
        const filedKey = strExpressionParts[0] as string;
        const operatorKey = mfPortalsFilteringOperatorTypeToMfFilterOperatorType(strExpressionParts[1] as MfPortalsFilteringOperatorTypes);
        const value = mfStringTrimEnd(mfStringTrimStart(strExpressionParts[2] as string, "'"), "'");

        const modelFieldConfig = this._modelConfigService.getModelFieldConfigByFieldKey(modelConfig, filedKey);

        const expression = this._filterService.getNewFilterExpression(filedKey, filedKey, operatorKey, value, modelFieldConfig);

        groupPart.expressions.push(expression);

        group.groups.push(groupPart);
      }
    }


    return group;
  }

  public fromGroup(group: MfFilterGroup, includeDisabled: boolean = false): string | undefined {
    let filter = "";
    const groups = group.groups;

    for (let expressionIndex = 0; expressionIndex < group.expressions.length; expressionIndex++) {
      const expression = group.expressions[expressionIndex];
      if (!mfTypeIsUndefined(expression)) {
        filter += this._fromExpression(expression);
      }
    }

    for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
      const groupA = group.groups[groupIndex];
      if (groupA.expressions.length > 0) {
        for (let expressionIndex = 0; expressionIndex < groupA.expressions.length; expressionIndex++) {
          const expression = groupA.expressions[expressionIndex];
          if (!mfTypeIsUndefined(expression)) {
            filter += this._fromExpression(expression, includeDisabled);
          }
        }
      }
    }

    return mfStringTrimEnd(filter, MF_PORTALS_FILTER_CONVERTER_AND);
  }

  protected _fromExpression(expression: MfFilterExpression, includeDisabled: boolean = false): string {
    if (includeDisabled === true || (mfTypeIsUndefined(expression.modelFieldConfig.filter?.disabled) || expression.modelFieldConfig.filter?.disabled === false)) {
      return `${expression.fieldKey} ${mfFilterOperatorTypeToMfPortalsFilteringOperatorType(expression.operatorKey)} ${expression.modelFieldConfig.dataType.type === MfModelFieldDataTypes.string ? "'" + expression.value + "'" : expression.value}${MF_PORTALS_FILTER_CONVERTER_AND}`;
    } else {
      return "";
    }
  }
}