import { CdkTreeModule } from "@angular/cdk/tree";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from "@angular/router";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MfMenuComponent } from "@material-framework/menu/menu.component";
import { MF_MENU_CONFIG_TOKEN, MF_MENU_DEFAULT_CONFIG } from "@material-framework/menu/menu.config";

@NgModule({
  declarations: [
    MfMenuComponent
  ],
  imports: [
    CdkTreeModule,
    MatButtonModule,
    RouterModule,
    MfIconModule,
    MatTooltipModule,
  ],
  exports: [
    MfMenuComponent
  ],
  providers: [
    { provide: MF_MENU_CONFIG_TOKEN, useValue: MF_MENU_DEFAULT_CONFIG },
  ]
})
export class MfMenuModule {
}