import { MfTextAlignHorizontalTypes } from "@material-framework/common/types";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelFieldConfigMapped, MfModelFieldExtendedConfig } from "@material-framework/modelConfig/model.config";
import { MfTableRowCellDateEditorOptions } from "@material-framework/table/row/cell/value/editor/table.row.cell.date.editor.component";
import { MfTableRowCellNumberEditorOptions } from "@material-framework/table/row/cell/value/editor/table.row.cell.number.editor.component";
import { MfTableRowCellArrayFormatterOptions } from "@material-framework/table/row/cell/value/formatter/table.row.cell.array.formatter.component";
import { MfTableRowCellBooleanFormatterOptions } from "@material-framework/table/row/cell/value/formatter/table.row.cell.boolean.formatter.component";
import { MfTableRowCellCurrencyFormatterOptions } from "@material-framework/table/row/cell/value/formatter/table.row.cell.currency.formatter.component";
import { MfTableRowCellDateFormatterOptions } from "@material-framework/table/row/cell/value/formatter/table.row.cell.date.formatter.component";
import { MfTableRowCellEnumFormatterOptions } from "@material-framework/table/row/cell/value/formatter/table.row.cell.enum.formatter.component";
import { MfTableRowCellHtmlDialogFormatterOptions } from "@material-framework/table/row/cell/value/formatter/table.row.cell.html.dialog.formatter.component";
import { MfTableRowCellHtmlFormatterOptions } from "@material-framework/table/row/cell/value/formatter/table.row.cell.html.formatter.component";
import { MfTableRowCellNumberFormatterOptions } from "@material-framework/table/row/cell/value/formatter/table.row.cell.number.formatter.component";
import { MfTableRowCellPercentageFormatterOptions } from "@material-framework/table/row/cell/value/formatter/table.row.cell.percentage.formatter.component";
import { MfTableRowCellStatusChipFormatterOptions } from "@material-framework/table/row/cell/value/formatter/table.row.cell.status.chip.formatter.component";
import { MfTableRowCellStringFormatterOptions } from "@material-framework/table/row/cell/value/formatter/table.row.cell.string.formatter.component";
import { MfTableRowCellUserFormatterOptions } from "@material-framework/table/row/cell/value/formatter/table.row.cell.user.formatter.component";
import { MfTableRowCellValueBaseOptions } from "@material-framework/table/table";
import { MfTableCellValueFormatterTypes } from "@material-framework/table/table.config";
import type * as css from "csstype";


export function mfTableReIndexModelFieldExtendedConfig(configs: MfModelFieldExtendedConfig[]): void {
  let index = 0;
  configs.forEach((config) => {
    if (!mfTypeIsUndefined(config.table) && !mfTypeIsUndefined(config.table.index)) {
      config.table.index = index;
      index++;
    }
  });
}

export function mfTableHeaderCanSort(modelFieldConfig: MfModelFieldConfigMapped): boolean {
  if (mfTypeIsUndefined(modelFieldConfig.table)) {
    return false;
  } else if (!mfTypeIsUndefined(modelFieldConfig.table.cell) && !mfTypeIsUndefined(modelFieldConfig.table.cell.formatter) && modelFieldConfig.table.cell.formatter.type === "array") {
    return false;
  } else if (mfTypeIsUndefined(modelFieldConfig.table.header) || mfTypeIsUndefined(modelFieldConfig.table.header.sortable) || modelFieldConfig.table.header.sortable === true) {
    return true;
  } else {
    return false;
  }
}

export function mfTableGetVisible(modelFieldConfig: MfModelFieldConfigMapped): boolean {
  if (!mfTypeIsUndefined(modelFieldConfig.table) && !mfTypeIsUndefined(modelFieldConfig.table.visible)) {
    return modelFieldConfig.table.visible;
  } else {
    return true;
  }
}

export type MfModelFieldTableConfig = {
  cell?: MfTableRowCellModelConfig;
  header?: MfTableRowHeaderModelConfig;
  selection?: MfTableSelectionConfig;
  showHideOrder?: MfTableShowHideOrderConfig;
  hidden?: boolean;
  index?: number;
  visible?: boolean;
}

export type MfTableShowHideOrderConfig = {
  visible?: boolean;
}

export type MfTableSelectionConfig = {
  trackBy: boolean;
}

export type MfTableRowCellFormatterTypesModelConfig =
  MfTableRowCellHtmlFormatterModelConfig |
  MfTableRowCellEditorDateFormatterModelConfig |
  MfTableRowCellEditorNumberFormatterModelConfig |
  MfTableRowCellCurrencyFormatterModelConfig |
  MfTableRowCellUserFormatterModelConfig |
  MfTableRowCellStringFormatterModelConfig |
  MfTableRowCellStatusChipFormatterModelConfig |
  MfTableRowCellPercentageFormatterModelConfig |
  MfTableRowCellNumberFormatterModelConfig |
  MfTableRowCellHtmlDialogFormatterModelConfig |
  MfTableRowCellEnumFormatterModelConfig |
  MfTableRowCellDateFormatterModelConfig |
  MfTableRowCellBooleanFormatterModelConfig |
  MfTableRowCellArrayFormatterAModelConfig |
  MfTableRowCellValueFormatterTypeModelConfig<MfTableRowCellValueBaseOptions>;

export type MfTableRowCellModelConfig = {
  formatter?: MfTableRowCellFormatterTypesModelConfig;
  width?: number;
  contentAlign?: MfTextAlignHorizontalTypes;
  style?: css.Properties;
}

export type MfTableRowCellCurrencyFormatterModelConfig = {
  type: "currency";
  options?: MfTableRowCellCurrencyFormatterOptions;
}

export type MfTableRowCellUserFormatterModelConfig = {
  type: "userPersons";
  componentType: "base",
  options?: MfTableRowCellUserFormatterOptions;
}

export type MfTableRowCellStringFormatterModelConfig = {
  type: "string";
  componentType: "base",
  options?: MfTableRowCellStringFormatterOptions;
}

export type MfTableRowCellStatusChipFormatterModelConfig = {
  type: "statusChip";
  componentType: "base",
  options?: MfTableRowCellStatusChipFormatterOptions;
}

export type MfTableRowCellPercentageFormatterModelConfig = {
  type: "percentage";
  componentType: "base",
  options?: MfTableRowCellPercentageFormatterOptions;
}

export type MfTableRowCellNumberFormatterModelConfig = {
  type: "number";
  componentType: "base",
  options?: MfTableRowCellNumberFormatterOptions;
}

export type MfTableRowCellHtmlDialogFormatterModelConfig = {
  type: "htmlDialog";
  componentType: "base",
  options?: MfTableRowCellHtmlDialogFormatterOptions;
}

export type MfTableRowCellEnumFormatterModelConfig = {
  type: "enum";
  componentType: "base",
  options?: MfTableRowCellEnumFormatterOptions;
}

export type MfTableRowCellDateFormatterModelConfig = {
  type: "date";
  componentType: "base",
  options?: MfTableRowCellDateFormatterOptions;
}

export type MfTableRowCellBooleanFormatterModelConfig = {
  type: "boolean";
  componentType: "base",
  options?: MfTableRowCellBooleanFormatterOptions;
}

export type MfTableRowCellArrayFormatterAModelConfig = {
  type: "array";
  componentType: "base",
  options?: MfTableRowCellArrayFormatterOptions;
}

export type MfTableRowCellHtmlFormatterModelConfig = {
  type: "html";
  componentType: "base",
  options?: MfTableRowCellHtmlFormatterOptions;
}

export type MfTableRowCellEditorDateFormatterModelConfig = {
  type: "dateEditor";
  componentType: "validator",
  options?: MfTableRowCellDateEditorOptions;
}

export type MfTableRowCellEditorNumberFormatterModelConfig = {
  type: "numberEditor";
  componentType: "validator",
  options?: MfTableRowCellNumberEditorOptions;
}

export type MfTableRowCellValueFormatterTypeModelConfig<TOptions extends MfTableRowCellValueBaseOptions> = {
  type: MfTableCellValueFormatterTypes;
  options?: TOptions;
}

export type MfTableRowHeaderModelConfig = {
  sortable?: boolean;
  hasWidthAdjustment?: boolean;
  contentAlign?: MfTextAlignHorizontalTypes;
  group?: MfTableRowHeaderGroupModelConfig;
  style?: css.Properties;
}

export type MfTableRowHeaderGroupModelConfig = {
  id: string;
  displayName: string;
  contentAlign?: MfTextAlignHorizontalTypes;
  style?: css.Properties;
}