import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";

export type EComAddressModel = MfModelBase & {
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  city: string;
  region: string;
  postalCode: string;
  countryCode: string;
}

export type EComAddressModelFieldsConfig = {
  [key in keyof Required<EComAddressModel>]: MfModelFieldConfig
};

export const ECOM_ADDRESS_MODEL_FIELDS_CONFIG: EComAddressModelFieldsConfig = {
  address1: {
    dataType: {
      type: MfModelFieldDataTypes.object
    },
  },
  address2: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  address3: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  address4: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  address5: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  city: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  region: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  postalCode: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    },
  },
  countryCode: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    },
  },
};

export const ECOM_ADDRESS_MODEL_CONFIG_ID = "address";

export const ECOM_ADDRESS_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ADDRESS_MODEL_CONFIG_ID,
  fields: ECOM_ADDRESS_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ADDRESS_MODEL_CONFIG",
  },
  portals: {}
};