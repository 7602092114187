import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsNullOrUndefined } from "@material-framework/common/utils/type.utils";


const TYPE_INFO: MfTypeInfo = { className: "StringUtils" };

export function mfStringReplaceFromTo(originalString: string, startIndex: number, endIndex: number, replacement: string): string {
  const before = originalString.substring(0, startIndex);
  const after = originalString.substring(endIndex);
  return before + replacement + after;
}

export function mfStringStartsWith(statsWith: string, value?: string | null): boolean {
  if (mfTypeIsNullOrUndefined(value)) {
    return false;
  }
  return value.startsWith(statsWith);
}

export function mfStringCountChar(string: string, char: string): number {
  return mfStringIsEmptyOrWhitespace(string) ? 0 : string.split(char).length - 1;
}

export function mfStringGetInitials(fullName: string): string {
  const words = fullName.split(" ");

  if (words.length === 1) {
    return words[0].substring(0, 2).toUpperCase();
  } else {
    return words
      .map((word) => word.charAt(0).toUpperCase())
      .join("");
  }
}

export function mfStringIsEmptyOrWhitespace(value?: string | null): value is undefined | null {
  return mfTypeIsNullOrUndefined(value) || !value.toString().trim();
}

export function mfStringTrimStartFirst(value: string, remove: string): string {
  while (value.startsWith(remove) && value !== "" && remove !== "") {
    value = value.slice(remove.length);
    return value;
  }
  return value;
}

export function mfStringTrimEndFirst(value: string, remove: string): string {
  while (value.endsWith(remove) && value !== "" && remove !== "") {
    value = value.slice(0, -remove.length);
    return value;
  }
  return value;
}

export function mfStringTrimStart(value: string, remove: string): string {
  while (value.startsWith(remove) && value !== "" && remove !== "") {
    value = value.slice(remove.length);
  }
  return value;
}

export function mfStringTrimEnd(value: string, remove: string): string {
  while (value.endsWith(remove) && value !== "" && remove !== "") {
    value = value.slice(0, -remove.length);
  }
  return value;
}