import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { MfFooterComponent } from "@material-framework/footer/footer.component";


@NgModule({
  declarations: [
    MfFooterComponent,
  ],
  imports: [
    BrowserModule,
  ],
  exports: [
    MfFooterComponent,
  ],
  providers: [
  ]
})
export class MfFooterModule {
}