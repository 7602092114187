<div class="ecom-paypal-cancel-container">
  <div class="ecom-paypal-cancel">
    <mat-card>
      <mat-card-content>
        <div class="mf-row ecom-content-center">
          <mf-icon [icon]="_iconsConfig.paypalIcon"
                   [size]="40"
                   color="#6EC6AA"></mf-icon>
        </div>
      </mat-card-content>
      <mat-card-title>
        <div class="mf-row mf-x-large-margin-top mf-x-large-margin-bottom ecom-content-center ecom-title">
          TRANSACTION CANCELED!
        </div>
      </mat-card-title>
      <mat-card-content>
        <div class="mf-column">
          <div class="mf-row ecom-content-center">
            Transaction canceled
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button
                class="mf-large-margin-top"
                style="width: 100%;"
                color="accent"
                (click)="_goCart()">Cart</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>