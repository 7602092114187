import { DOCUMENT } from "@angular/common";
import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MfError } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsInstanceOf } from "@material-framework/common/utils/type.utils";

const TYPE_INFO: MfTypeInfo = { className: "MfClickOutsideDirective" };

@Directive({
  selector: "[mfClickOutside]",
})
export class MfClickOutsideDirective implements OnInit, OnChanges, OnDestroy {
  @Input()
  public clickOutsideEnabled = true;
  @Input()
  public attachOutsideOnClick = false;
  @Input()
  public delayClickOutsideInit = false;
  @Input()
  public emitOnBlur = false;
  @Input()
  public exclude = "";
  @Input()
  public excludeBeforeClick = false;
  @Input()
  public clickOutsideEvents = "";

  @Output()
  public mfClickOutside: EventEmitter<Event> = new EventEmitter<Event>();

  protected _nodesExcluded: Array<HTMLElement> = [];
  protected _events: Array<string> = ["click"];

  public constructor(
    protected _el: ElementRef,
    protected _ngZone: NgZone,
    @Inject(DOCUMENT)
    protected _document: Document) {
    this._initOnClickBody = this._initOnClickBody.bind(this);
    this._onClickBody = this._onClickBody.bind(this);
    this._onWindowBlur = this._onWindowBlur.bind(this);
  }

  public ngOnInit() {
    this._init();
  }

  public ngOnDestroy() {
    this._removeClickOutsideListener();
    this._removeAttachOutsideOnClickListener();
    this._removeWindowBlurListener();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes["attachOutsideOnClick"] || changes["exclude"] || changes["emitOnBlur"]) {
      this._init();
    }
  }

  protected _init() {
    if (this.clickOutsideEvents !== "") {
      this._events = this.clickOutsideEvents.split(",").map(e => e.trim());
    }

    this._excludeCheck();

    if (this.attachOutsideOnClick) {
      this._initAttachOutsideOnClickListener();
    } else {
      this._initOnClickBody();
    }

    if (this.emitOnBlur) {
      this._initWindowBlurListener();
    }
  }

  protected _initOnClickBody() {
    if (this.delayClickOutsideInit) {
      setTimeout(this._initClickOutsideListener.bind(this));
    } else {
      this._initClickOutsideListener();
    }
  }

  protected _excludeCheck() {
    if (this.exclude) {
      try {
        const nodes = Array.from(this._document.querySelectorAll(this.exclude)) as Array<HTMLElement>;
        if (nodes) {
          this._nodesExcluded = nodes;
        }
      } catch (err) {
        throw new MfError(TYPE_INFO, "_excludeCheck", "Check your exclude selector syntax");
      }
    }
  }

  protected _onClickBody(ev: Event) {
    if (!this.clickOutsideEnabled) {
      return;
    }

    if (this.excludeBeforeClick) {
      this._excludeCheck();
    }

    if (!this._el.nativeElement.contains(ev.target) && !this._shouldExclude(ev.target)) {
      this._emit(ev);

      if (this.attachOutsideOnClick) {
        this._removeClickOutsideListener();
      }
    }
  }

  protected _onWindowBlur(ev: Event) {
    setTimeout(() => {
      if (!this._document.hidden) {
        this._emit(ev);
      }
    });
  }

  protected _emit(ev: Event) {
    if (!this.clickOutsideEnabled) {
      return;
    }

    this._ngZone.run(() => this.mfClickOutside.emit(ev));
  }

  protected _shouldExclude(target: EventTarget | null): boolean {
    if (mfTypeIsInstanceOf(target, Element)) {
      if (!document.contains(target)) {
        return true;
      }
      for (const excludedNode of this._nodesExcluded) {
        if (excludedNode.contains(target)) {
          return true;
        }
      }
      if (this._shouldExcludeParent(target)) {
        return true;
      }
    }

    return false;
  }

  protected _shouldExcludeParent(target: Element): boolean {
    const parent = target.parentElement;
    if (this._el.nativeElement === parent) {
      return false;
    }
    return this._shouldExclude(target.parentElement);
  }

  protected _initClickOutsideListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => this._document.addEventListener(e, this._onClickBody));
    });
  }

  protected _removeClickOutsideListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => this._document.removeEventListener(e, this._onClickBody));
    });
  }

  protected _initAttachOutsideOnClickListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => this._el.nativeElement.addEventListener(e, this._initOnClickBody));
    });
  }

  protected _removeAttachOutsideOnClickListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => this._el.nativeElement.removeEventListener(e, this._initOnClickBody));
    });
  }

  protected _initWindowBlurListener() {
    this._ngZone.runOutsideAngular(() => {
      window.addEventListener("blur", this._onWindowBlur);
    });
  }

  protected _removeWindowBlurListener() {
    this._ngZone.runOutsideAngular(() => {
      window.removeEventListener("blur", this._onWindowBlur);
    });
  }
}