import { NgModule } from "@angular/core";
import { MfTinyMceEditorComponent } from "@material-framework/tinyMCEEditor/tiny.mce.editor.component";
import { MF_TINY_MCE_EDITOR_CONFIG_TOKEN, MF_TINY_MCE_EDITOR_DEFAULT_CONFIG } from "@material-framework/tinyMCEEditor/tiny.mce.editor.config";
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";


@NgModule({
  declarations: [
    MfTinyMceEditorComponent
  ],
  exports: [
    MfTinyMceEditorComponent,
  ],
  imports: [
    EditorModule
  ],
  providers: [
    { provide: MF_TINY_MCE_EDITOR_CONFIG_TOKEN, useValue: MF_TINY_MCE_EDITOR_DEFAULT_CONFIG },
    { provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" },
  ]
})
export class MfTinyMCEEditorModule {

}