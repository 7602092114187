import { Injectable, Injector } from "@angular/core";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComUserApprovedModel } from "@e-commerce/modelConfigs/user.approved.model.config";
import { MfAuthService } from "@material-framework/auth/auth.service";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfPortalsClientService } from "@material-framework/portals/portals.client.service";
import { map, Observable, of, share, Subject } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComUserService" };

@Injectable({ providedIn: "root" })
export class EComUserService extends MfBaseService {
  protected _userApprovedModelConfig: MfModelConfigMapped;
  protected _isApproved?: boolean;
  protected _isApproved$?: Observable<boolean>;
  protected _isApprovedSubject: Subject<boolean> = new Subject<boolean>;

  public constructor(
    protected override _injector: Injector,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    protected _authService: MfAuthService,
  ) {
    super(TYPE_INFO, _injector);
    this._userApprovedModelConfig = this._modelConfigService.get<EComModelsConfig>("userApproved");
    this._sub(this._authService.onLoggedOut, {
      next: () => {
        delete this._isApproved;
        delete this._isApproved$;
      }
    });
  }

  public get isApproved(): Observable<boolean> {
    if (!mfTypeIsUndefined(this._isApproved)) {
      return of(this._isApproved);
    } else {
      if (mfTypeIsUndefined(this._isApproved$)) {
        this._isApproved$ = this._portalsClientService.getItemGet<EComUserApprovedModel>(
          this._userApprovedModelConfig,
          `${ECOM_ENVIRONMENT.portalsUserRootUrl}/MyAccount/User/Check`
        ).pipe(
          map((userApproved) => {
            this._isApproved = userApproved.active;
            return this._isApproved;
          }),
          share(),
        );
      }
      return this._isApproved$;
    }
  }
}