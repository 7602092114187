import { NgModule } from "@angular/core";
import { MfClickOutsideDirective } from "@material-framework/clickOutside/click.outside.directive";


@NgModule({
  declarations: [
    MfClickOutsideDirective
  ],
  exports: [
    MfClickOutsideDirective
  ]
})
export class MfClickOutsideModule {
}