import { Component, Inject, Injector, ViewEncapsulation } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfStatusChipStatus } from "@material-framework/statusChip/status.chip";
import { MfTableRowCellBaseFormatterComponent } from "@material-framework/table/row/cell/value/table.row.cell.base.formatter.component";
import { MfTableRowCellFormatterBaseOptions } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowCellStatusChipFormatterComponent" };

export type MfTableRowCellStatusChipFormatterOptions = MfTableRowCellFormatterBaseOptions & {
  statuses: MfStatusChipStatus[],
}

@Component({
  selector: "mf-table-row-cell-status-chip-formatter-component",
  templateUrl: "table.row.cell.status.chip.formatter.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowCellStatusChipFormatterComponent<TModel extends MfModelBase> extends MfTableRowCellBaseFormatterComponent<MfTableRowCellStatusChipFormatterOptions, TModel> {
  public constructor(
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }
}