import { Component, Inject, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { EComCartModes } from "@e-commerce/common/cart.modes";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { EComCartService } from "@e-commerce/services/cart.service";
import { MfActionCardAction, MfActionCardActions } from "@material-framework/actionCards/action.cards";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfStringIsEmptyOrWhitespace } from "@material-framework/common/utils/string.utils";


const TYPE_INFO: MfTypeInfo = { className: "EComHomeComponent" };

type EComActionCardAction = MfActionCardAction & {
  redirectUrl?: string;
};

type EComActionCardActions = MfActionCardActions & {
  cards: EComActionCardAction[];
};

@Component({
  selector: "ecom-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class EComHomeComponent extends MfBaseComponent {
  protected _actions: EComActionCardActions;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _cartService: EComCartService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _config: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._cartService.setMode(EComCartModes.order);
    this._actions = {
      iconSize: 30,
      minWidth: 300,
      maxWidth: 300,
      cards: [
        {
          title: "Shop",
          description: "Purchase from our extensive range of products",
          icon: _config.shopIcon,
          redirectUrl: EcomRouteNames.routeProductsList,
        },
        // {
        //   title: "Upload Order",
        //   description: "Bulk order your requirements to quickly add them to your cart",
        //   icon: _config.uploadOrderIcon,
        //   redirectUrl: "",
        // },
        {
          title: "Order History",
          description: "View your order details",
          icon: _config.orderHistoryIcon,
          redirectUrl: EcomRouteNames.routeOrders,
        },
        {
          title: "Invoices",
          description: "View and pay your outstanding invoices",
          icon: _config.invoicesIcon,
          redirectUrl: EcomRouteNames.routeInvoicesList,
        },
      ]
    };
  }

  protected _actionClicked(action: EComActionCardAction): void {
    if (!mfStringIsEmptyOrWhitespace(action.redirectUrl)) {
      this._router.navigate([action.redirectUrl]);
    }
  }
}