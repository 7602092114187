import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { BrowserModule } from "@angular/platform-browser";
import { EComPaypalErrorComponent } from "@e-commerce/landing/paypal/error/paypal.error.component";
import { MfIconModule } from "@material-framework/icon/icon.module";

@NgModule({
  declarations: [
    EComPaypalErrorComponent
  ],
  exports: [
    EComPaypalErrorComponent
  ],
  imports: [
    BrowserModule,
    MatCardModule,
    MfIconModule,
    MatButtonModule,
  ],
  providers: [
  ]
})
export class EComPaypalErrorModule { }