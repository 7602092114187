import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { EComInvoiceDetailOrdersComponent } from "@e-commerce/invoiceDetailOrders/invoice.detail.orders.component";

@NgModule({
  declarations: [
    EComInvoiceDetailOrdersComponent
  ],
  exports: [
    EComInvoiceDetailOrdersComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
  ],
})
export class EComInvoiceDetailOrdersModule {
}