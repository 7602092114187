import { ArrayDataSource } from "@angular/cdk/collections";
import { NestedTreeControl } from "@angular/cdk/tree";
import { Component, Inject, Injector, Input, ViewEncapsulation } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MF_MENU_CONFIG_TOKEN, MfMenuConfig } from "@material-framework/menu/menu.config";
import { MfMenuItem } from "@material-framework/menu/menu.item";

const TYPE_INFO: MfTypeInfo = { className: "MfMenuComponent" };

@Component({
  selector: "mf-menu",
  templateUrl: "menu.component.html",
  styleUrls: ["menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfMenuComponent extends MfBaseComponent {
  @Input("items")
  public get items(): MfMenuItem[] | undefined {
    return this._items;
  }
  public set items(value: MfMenuItem[] | undefined) {
    this._items = value;
    if (!mfTypeIsUndefined(this._items)) {
      this._updateDepths(this._items);
      this._dataSource = new ArrayDataSource(this._items);
    }
  }

  protected _treeControl = new NestedTreeControl<MfMenuItem>(node => node.children);
  protected _dataSource = new ArrayDataSource<MfMenuItem>([]);
  protected _items?: MfMenuItem[];

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_MENU_CONFIG_TOKEN)
    public config: MfMenuConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected _hasChild(_: number, node: MfMenuItem) {
    return !!node.children && node.children.length > 0;
  }

  protected _updateDepths(items: MfMenuItem[], parentDepth?: number): void {
    const length = items.length;
    for (let itemIndex = 0; itemIndex < length; itemIndex++) {
      const depth = mfTypeIsUndefined(parentDepth) ? 1 : parentDepth + 1;
      const item = items[itemIndex];
      item.depth = depth;
      if (!mfTypeIsUndefined(item.children)) {
        this._updateDepths(item.children, depth);
      }
    }
  }
}