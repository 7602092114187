import { ChangeDetectorRef, Directive, HostBinding, Injector, Input } from "@angular/core";
import { MfBaseSafeUnsubscribe } from "@material-framework/base/base.safe.unsubscribe";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfStringIsEmptyOrWhitespace } from "@material-framework/common/utils/string.utils";
import { mfTypeIsNull, mfTypeIsNullOrUndefined, mfTypeIsUndefined, MfTypeNonUndefined } from "@material-framework/common/utils/type.utils";

@Directive()
export abstract class MfBaseComponent extends MfBaseSafeUnsubscribe {
  @Input()
  @HostBinding("id")
  public get id(): string {
    return this._id;
  }
  public set id(value: string) {
    this._id = value;
  }

  protected _id = "";
  protected _cdRef: ChangeDetectorRef;

  public constructor(
    protected override _typeInfo: MfTypeInfo,
    protected override _injector: Injector,
  ) {
    super(_typeInfo, _injector);
    this._cdRef = this._injector.get(ChangeDetectorRef);
  }

  protected _isEmptyOrWhitespace(value: string | null | undefined): boolean {
    return mfStringIsEmptyOrWhitespace(value);
  }

  protected _isNullOrUndefined(value: unknown): value is null | undefined {
    return mfTypeIsNullOrUndefined(value);
  }

  protected _isUndefined(value: unknown): value is undefined {
    return mfTypeIsUndefined(value);
  }

  protected _isNull(value: unknown): value is null {
    return mfTypeIsNull(value);
  }

  protected _isUndefinedThenDefault<T>(value: T, defaultValue: T): MfTypeNonUndefined<T> {
    return (mfTypeIsUndefined(value) ? defaultValue : value) as MfTypeNonUndefined<T>;
  }

  protected _isNullOrUndefinedThenDefault<T>(value: T, defaultValue: T): MfTypeNonUndefined<T> {
    return (mfTypeIsNullOrUndefined(value) ? defaultValue : value) as MfTypeNonUndefined<T>;
  }

  protected _isUndefinedThen<T, TThen>(value: T, then: TThen): MfTypeNonUndefined<T | TThen> {
    return (mfTypeIsUndefined(value) ? then : value) as MfTypeNonUndefined<T | TThen>;
  }
}