<div class="ecom-page-container ecom-cart-container">
  <div class="ecom-page-account-select">
    <ecom-account-select [disabled]="_isLoading"></ecom-account-select>
  </div>
  <div class="ecom-page-header">
    <div class="ecom-title">
      Your Cart
      <!-- <div class="ecom-subtitle">
        CartId: 00123455
      </div> -->
    </div>
    <div class="mf-flex-fill"></div>
    <div class="ecom-actions">
      <button mat-raised-button
              class="mf-default-margin-right"
              [disabled]="_isLoading"
              (click)="_continueShopping()">Continue Shopping</button>
      <button mat-raised-button
              color="accent"
              [disabled]="_cartItemsCount === 0 ||_isLoading || _hasValidationErrors === true"
              (click)="_checkoutOrProceed()"> Proceed To Checkout</button>
    </div>
  </div>
  <div class="ecom-page-errors">
    <div *ngFor="let validationError of _validationErrors"
         class="ecom-page-error">
      {{ validationError }}
    </div>
  </div>
  <div>
    <mf-table #cartLinesTable
              viewManagerEnabled="true"
              paginationEnabled="false"
              [displayColumns]="['select']"
              [locationKey]="_locationKey"
              [modelConfig]="_cartLineModelConfig"
              [postModelConfig]="_cartLineRequestModelConfig"
              (onDataLoaded)="_onDataLoaded()"
              (onRowValueChanged)="_rowEditorValueChanged($event)">
      <ng-container *mfTableHeaderLeftDef>
        <button mat-icon-button
                (click)="_deleteSelectedItems()"
                [disabled]="!_hasSelected"
                matTooltip="Remove Selected Items From cart">
          <mf-icon [icon]="_iconsConfig.removeFromCartIcon"></mf-icon>
        </button>
        <button mat-icon-button
                (click)="_updateRequiredDateSelectedItems()"
                [disabled]="!_hasSelected"
                matTooltip="Set Required Date For Selected Items">
          <mf-icon [icon]="_iconsConfig.dateIcon"></mf-icon>
        </button>
      </ng-container>
      <ng-container mfTableColumnDef="select"
                    width="50"
                    side="left">
        <ng-container mf-table-header-cell
                      *mfTableHeaderCellDef>
          <mat-checkbox (change)="_toggleAllSelected($event.checked)"
                        [checked]="_hasSelected"></mat-checkbox>
        </ng-container>
        <ng-container mf-table-cell
                      *mfTableCellDef="let item">
          <mat-checkbox (change)="_toggleItemChangeSelected(item)"
                        [checked]="item.isSelected"></mat-checkbox>
        </ng-container>
      </ng-container>
      <ng-container mfTableColumnSlideOutActionsDef
                    stickyEnd="true">
        <ng-container mf-table-cell
                      *mfTableCellDef="let item"
                      contentAlign="center">
          <div class="mf-column mf-default-margin">
            <button class="mf-default-margin-bottom"
                    mat-stroked-button
                    (click)="_removeFromCart(item)">
              <mf-icon class="mf-default-margin-right"
                       [icon]="_iconsConfig.removeFromCartIcon"></mf-icon>
              Remove From Cart
            </button>
            <button mat-stroked-button
                    (click)="_gotoProductDetail(item)">
              <mf-icon class="mf-default-margin-right"
                       [icon]="_iconsConfig.productDetailIcon"></mf-icon>
              Product Detail
            </button>
          </div>
        </ng-container>
      </ng-container>
    </mf-table>
  </div>
</div>