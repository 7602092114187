<div [formGroup]="formGroup"
     class="mf-column ecom-checkout-part-container">
  <div class="ecom-page-header">
    <div class="ecom-title">
      Billing & Payment
    </div>
  </div>
  <div class="mf-column">
    <div class="ecom-billing-address">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Billing Address</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="mf-column mf-large-margin-top">
            <div>
              UNIT 7 Stoney Gate Road
            </div>
            <div>
              Spondon
            </div>
            <div>
              Derby
            </div>
            <div>
              United Kingdom
            </div>
            <div>
              DE22 5GH
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="mf-row mf-large-margin-top">
      <mat-form-field style="width: 500px;"
                      class="mf-default-margin-right">
        <mat-label>Payment Amount</mat-label>
        <mf-input id="purchase-order-reference"
                  [formControlName]="_paymentAmountName"></mf-input>
        <mat-error *ngIf="_paymentAmountFormControl.invalid">{{_errorMessages[_paymentAmountName]}}</mat-error>
      </mat-form-field>
      <mat-form-field style="width: 500px;">
        <mat-label>Currency</mat-label>
        <mf-input id="purchase-order-reference"
                  [disabled]="true"></mf-input>
      </mat-form-field>
    </div>
  </div>
  <div class="ecom-page-header">
    <div class="ecom-subheader">
      Select Payment Method
    </div>
  </div>
  <mat-radio-group [formControlName]="_paymentTypeName">
    <div class="mf-large-margin-bottom">
      <mat-card (click)="_paymentTypeFormControl.setValue(_paymentTypeCreditCard)"
                class="ecom-option-card">
        <mat-card-content>
          <div class="mf-column">
            <div class="mf-row">
              <div>
                <mat-radio-button class="ecom-option"
                                  #creditCartRB
                                  [value]="_paymentTypeCreditCard">
                  <div class="ecom-option-label">
                    Credit Card
                  </div>
                </mat-radio-button>
              </div>
              <div class="mf-flex-fill"></div>
              <div class="mf-row">
                <mf-icon class="ecom-icon"
                         [icon]="_iconsConfig.visaIcon"
                         [size]="28"></mf-icon>
                <mf-icon class="ecom-icon"
                         [icon]="_iconsConfig.amexIcon"
                         [size]="28"></mf-icon>
                <mf-icon class="ecom-icon"
                         [icon]="_iconsConfig.masterCardIcon"
                         [size]="28"></mf-icon>
                <mf-icon class="ecom-icon"
                         [icon]="_iconsConfig.paypalIcon"
                         [size]="28"></mf-icon>
              </div>
            </div>
            <div class="mf-row">
              You will be redirected to the PayPal website to complete your purchase securely.
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-radio-group>
</div>