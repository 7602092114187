import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectClone } from "@material-framework/common/utils/object.utils";
import { mfStringIsEmptyOrWhitespace } from "@material-framework/common/utils/string.utils";
import { mfTypeIsArray, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfDialogService } from "@material-framework/dialog/dialog.service";
import { MfSelectOption } from "@material-framework/select/select.option";
import { MfViewState, MfViewStateData } from "@material-framework/viewManager/view.manager";
import { MF_VIEW_MANAGER_CONFIG_TOKEN, MfViewManagerConfig } from "@material-framework/viewManager/view.manager.config";
import { MfViewManagerEditViewComponent } from "@material-framework/viewManager/view.manager.edit.view.component";
import { MfViewManagerStorageService } from "@material-framework/viewManager/view.manager.storage.service";
import { pulseAnimation } from "angular-animations";
import { concatMap, map, of } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "MfViewManagerComponent" };

export type MfLocationKey = string;

@Component({
  selector: "mf-view-manager",
  templateUrl: "view.manager.component.html",
  styleUrls: ["view.manager.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    pulseAnimation({ anchor: "viewNeedsSaving", scale: 1.3 })
  ]
})
export class MfViewManagerComponent<TData extends MfViewStateData> extends MfBaseComponent implements OnInit {
  public get viewNeedsSaving(): boolean | undefined {
    return this._viewNeedsSaving;
  }
  public set viewNeedsSaving(value: BooleanInput) {
    this._viewNeedsSaving = coerceBooleanProperty(value);
    if (this._viewNeedsSaving === true) {
      this._viewNeedsSavingTimer = setInterval(() => {
        this._viewNeedsSavingState = false;
        setTimeout(() => {
          this._viewNeedsSavingState = true;
        }, 1);
      }, 1000);
    } else {
      clearInterval(this._viewNeedsSavingTimer);
    }
  }

  @Input()
  public viewState?: MfViewState<TData>;

  @Input()
  public locationKey?: MfLocationKey;

  @Output()
  public onSelectedViewStateChanged: EventEmitter<MfViewState<TData>> = new EventEmitter();

  @Output()
  public onViewStateBeforeUpdate: EventEmitter<MfViewState<TData>> = new EventEmitter();

  @Output()
  public onViewStateAfterUpdate: EventEmitter<MfViewState<TData>> = new EventEmitter();

  protected _options: MfSelectOption<number>[] = [];
  protected _viewNeedsSavingState: boolean = false;
  protected _viewNeedsSaving?: boolean;
  protected _viewNeedsSavingTimer?: any;
  protected _viewStates?: MfViewState<TData>[];

  public constructor(
    protected override _injector: Injector,
    protected _dialogService: MfDialogService,
    @Inject(MF_VIEW_MANAGER_CONFIG_TOKEN)
    public config: MfViewManagerConfig,
    protected _viewManagerStorageService: MfViewManagerStorageService<TData>,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    this._sub(this._viewManagerStorageService.onViewStateNeedsCaching, {
      next: (locationKey) => {
        if (locationKey === this.locationKey) {
          this._onViewStateNeedsCaching();
        }
      }
    });
    this._getViewStates(true);
  }

  protected get _noSelection(): boolean {
    return mfTypeIsUndefined(this.viewState);
  }

  protected _onToggleDefault(): void {
    if (!mfTypeIsUndefined(this.locationKey) && !mfTypeIsUndefined(this.viewState) && !mfTypeIsUndefined(this.viewState.id)) {
      this._sub(this._viewManagerStorageService.setAsDefault(this.locationKey, this.viewState.id), { next: () => this._getViewStates(false) });
    }
  }

  protected _onOptionChanged(option: MfSelectOption<number> | MfSelectOption<number>[] | undefined): void {
    if (!mfTypeIsUndefined(option) && !mfTypeIsArray(option)) {
      this.viewState = this._viewStates?.find(i => i.id === option.value);
      if (!mfTypeIsUndefined(this.viewState) && !mfTypeIsUndefined(this.locationKey)) {
        this.viewState.isPrevious = true;
        this.onSelectedViewStateChanged.emit(this.viewState);
        this._sub(this._viewManagerStorageService.get(this.locationKey), {
          next: (viewStates: MfViewState<TData>[]) => {
            const cacheViewState = () => {
              if (!mfTypeIsUndefined(this.locationKey)) {
                this._viewManagerStorageService.onViewStateNeedsCaching.emit(this.locationKey);
              }
            };
            const previousViewState = viewStates.find(i => i.isPrevious === true);
            if (!mfTypeIsUndefined(previousViewState) && !mfTypeIsUndefined(this.locationKey)) {
              delete previousViewState.isPrevious;
              this._sub(this._viewManagerStorageService.updateCache(this.locationKey, previousViewState), {
                next: () => {
                  cacheViewState();
                }
              });
            } else {
              cacheViewState();
            }
          }
        });
      }
    }
  }

  protected _onSaveClicked(): void {
    if (!mfTypeIsUndefined(this.locationKey) && !mfTypeIsUndefined(this.viewState) && this.viewState.displayName !== this.config.defaultViewDisplayName) {
      this.onViewStateBeforeUpdate.emit(this.viewState);
      this._sub(this._viewManagerStorageService.update(this.locationKey, this.viewState), {
        next: () => {
          this.onViewStateAfterUpdate.emit(this.viewState);
        }
      });
    }
  }

  protected _onSaveAsClicked(): void {
    if (!mfTypeIsUndefined(this.viewState) && !mfTypeIsUndefined(this.locationKey)) {
      const dialogRef = this._dialogService.openOkCancel({
        data: {
          title: "Save View As", contentComponent: MfViewManagerEditViewComponent,
          inputs: {
            name: this.viewState.displayName
          },
          outputs: ["name"]
        }
      });
      this._sub(dialogRef.afterClosed(), {
        next: (result) => {
          if (!mfTypeIsUndefined(this.viewState) && !mfTypeIsUndefined(result) && !mfTypeIsUndefined(result.outputs) && result.ok === true) {
            const addViewState = mfObjectClone<MfViewState<TData>>(this.viewState);
            const displayName = result.outputs["name"] as string;
            if (!mfTypeIsUndefined(displayName) && !mfStringIsEmptyOrWhitespace(displayName)) {
              delete addViewState.id;
              addViewState.isDefaultViewState = false;
              addViewState.isDefaultViewStateCached = false;
              addViewState.displayName = displayName;
              addViewState.isDefault = false;
              this.onViewStateBeforeUpdate.emit(addViewState);
              this._sub(this._viewManagerStorageService.add(this.locationKey!, addViewState), {
                next: (newViewState) => {
                  this.viewState = newViewState;
                  this._getViewStates(false);
                  this.onViewStateAfterUpdate.emit(this.viewState);
                },
              });
            }
          }
        }
      });
    }
  }

  protected _onDeleteClicked(): void {
    if (!mfTypeIsUndefined(this.locationKey) && !mfTypeIsUndefined(this.viewState) && !this._isUndefined(this.viewState.id)) {
      const loading = this._subLoading(of(this.viewState).pipe(
        concatMap((viewState) => {
          if (viewState.isDefault === true) {
            const defaultView = this._viewStates?.find(i => i.locationKey === this.locationKey && i.isDefaultViewState === true);
            if (!mfTypeIsUndefined(this.locationKey) && !mfTypeIsUndefined(defaultView) && !mfTypeIsUndefined(defaultView.id)) {
              return this._viewManagerStorageService.setAsDefault(this.locationKey, defaultView.id);
            }
          }

          return of(true);
        }),
        concatMap(() => {
          if (!mfTypeIsUndefined(this.locationKey) && !mfTypeIsUndefined(this.viewState) && !mfTypeIsUndefined(this.viewState.id)) {
            return this._viewManagerStorageService.delete(this.locationKey!, this.viewState.id).pipe(
              map(() => {
                this._getViewStates(true);
                loading.complete();
                return true;
              })
            );
          }

          loading.complete();
          return of(false);
        }),
      ));
    }
  }

  protected _getViewStates(setPreviousOrDefault: boolean): void {
    if (!mfTypeIsUndefined(this.locationKey)) {
      this._sub(this._viewManagerStorageService.get(this.locationKey), {
        next: (viewStates) => {
          this._viewStates = viewStates;

          if (viewStates.length === 0) {
            const defaultViewState = this._getDefaultViewState();
            if (!mfTypeIsUndefined(defaultViewState)) {
              this.onViewStateBeforeUpdate.emit(this.viewState);
              this._sub(this._viewManagerStorageService.add(this.locationKey!, defaultViewState), {
                next: (addedViewState) => {
                  if (!mfTypeIsUndefined(addedViewState)) {
                    viewStates.push(addedViewState);
                    this.onViewStateAfterUpdate.emit(this.viewState);
                  }
                },
              });
            }
          }

          if (setPreviousOrDefault === true && !mfTypeIsUndefined(viewStates[0])) {
            const previousViewState = viewStates.find(i => i.isPrevious === true);
            if (!mfTypeIsUndefined(previousViewState)) {
              this.viewState = previousViewState;
              this.onSelectedViewStateChanged.emit(this.viewState);
            } else {
              let defaultViewState = viewStates.find(i => i.isDefault === true);
              if (mfTypeIsUndefined(defaultViewState)) {
                defaultViewState = viewStates[0];
              }
              if (!mfTypeIsUndefined(defaultViewState)) {
                this.viewState = defaultViewState;
                this.onSelectedViewStateChanged.emit(this.viewState);
              }
            }
          }

          this._options = viewStates.map(i => { return { label: i.displayName, value: i.id! }; });
        }
      });
    }
  }

  protected _getDefaultViewState(): MfViewState<TData> | undefined {
    if (!mfTypeIsUndefined(this.locationKey)) {
      return this.viewState = {
        displayName: this.config.defaultViewDisplayName,
        locationKey: this.locationKey,
        isDefault: true,
        isDefaultViewState: true,
      };
    }
    return;
  }

  protected _onViewStateNeedsCaching(): void {
    if (!mfTypeIsUndefined(this.locationKey) && !mfTypeIsUndefined(this.viewState)) {
      this.viewNeedsSaving = true;
      this.onViewStateBeforeUpdate.emit(this.viewState);
      this._sub(this._viewManagerStorageService.updateCache(this.locationKey, this.viewState), {
        next: () => {
          this.onViewStateAfterUpdate.emit(this.viewState);
        }
      });
    }
  }
}
