// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfTableCellFormatterTypes = {
  boolean: "boolean",
  enum: "enum",
  string: "string",
  date: "date",
  number: "number",
  currency: "currency",
  custom: "custom",
} as const;
export type MfTableCellFormatterTypes = typeof MfTableCellFormatterTypes[keyof typeof MfTableCellFormatterTypes];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfTableFilterModeTypes = {
  tableHeader: "tableHeader",
  rowHeader: "rowHeader",
} as const;
export type MfTableFilterModeTypes = typeof MfTableFilterModeTypes[keyof typeof MfTableFilterModeTypes];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfTableDataReloadTypes = {
  filterChange: "filterChange",
  sortChange: "sortChange",
  visibleChange: "visibleChange",
  viewChange: "viewChange",
  paginationChange: "paginationChange",
} as const;
export type MfTableDataReloadTypes = typeof MfTableDataReloadTypes[keyof typeof MfTableDataReloadTypes];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfTableHeaderItem = {
  reloadDateButton: "reloadDateButton",
  orderShowHideButton: "orderShowHideButton",
  viewManager: "viewManager",
  filter: "filter",
} as const;
export type MfTableHeaderItem = typeof MfTableHeaderItem[keyof typeof MfTableHeaderItem];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfTableLoadingTypes = {
  spinner: "spinner",
  service: "service",
} as const;
export type MfTableLoadingTypes = typeof MfTableLoadingTypes[keyof typeof MfTableLoadingTypes];