import { NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { EComOrderReviewComponent } from "@e-commerce/checkout/order/orderReview/order.review.component";


@NgModule({
  declarations: [
    EComOrderReviewComponent
  ],
  exports: [
    EComOrderReviewComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    MatCardModule,
  ],
  providers: [
  ]
})
export class EComOrderReviewModule { }