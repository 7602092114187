import { Inject, Injectable, Injector } from "@angular/core";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfError } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectClone, mfObjectGetPropertyPath } from "@material-framework/common/utils/object.utils";
import { mfTypeGetKeys, mfTypeHasOwnProperty, mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelBase } from "@material-framework/model/model.base";
import { MF_MODEL_CONFIGS_TOKEN, MF_MODEL_DEFAULTS_CONFIG_TOKEN, MfModelConfig, MFModelConfigFieldKey, MFModelConfigFieldPath, MfModelConfigMapped, MfModelDefaultsConfig, MfModelFieldConfig, MfModelFieldConfigMapped, MfModelFieldExtendedConfig, MfModelFieldsConfig, MfModelFieldsConfigMapped, MfModelsConfig } from "@material-framework/modelConfig/model.config";
import { MF_MODEL_REQUIRED_FIELD_RETRIEVER_TYPES_TOKEN, MfModelRequiredFieldRetrieverFunction, MfModelRequiredFieldRetrieverTypes } from "@material-framework/modelConfig/model.config.required.fields";


const TYPE_INFO: MfTypeInfo = { className: "MfModelConfigService" };

export const MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR = ".";

export type MfModelConfigForEachModelFilter<TModel> = {
  selector: (model: TModel, modelFieldConfig: MfModelFieldConfigMapped) => boolean;
  model: TModel;
}

/**
 * @class
 * @extends MfBaseService
 * @description
 * The `MfModelConfigService` class is responsible for managing and providing access to model configurations.
 * It offers methods to retrieve, manipulate, and cache model configurations, as well as handling field paths and extended configurations.
 */
@Injectable()
export class MfModelConfigService extends MfBaseService {
  private _configCache: { key: string; modelConfig: MfModelConfigMapped }[] = [];

  /**
   * @constructor
   * @param {Injector} _injector - The Angular injector used to inject dependencies.
   * @param {MfModelsConfig} modelsConfig - The configuration for all models.
   * @param {MfModelRequiredFieldRetrieverTypes} modelRequiredFieldRetrieverTypes - A set of functions used to retrieve required field paths.
   * @param {MfModelDefaultsConfig} config - Default configuration settings for the models.
   */
  public constructor(
    protected override _injector: Injector,
    @Inject(MF_MODEL_CONFIGS_TOKEN)
    public modelsConfig: MfModelsConfig,
    @Inject(MF_MODEL_REQUIRED_FIELD_RETRIEVER_TYPES_TOKEN)
    public modelRequiredFieldRetrieverTypes: MfModelRequiredFieldRetrieverTypes,
    @Inject(MF_MODEL_DEFAULTS_CONFIG_TOKEN)
    public config: MfModelDefaultsConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  /**
   * @method getOnlyUseForBuildMapped
   * @description Retrieves the mapped model configuration for a specified model key without using the cache.
   * This method also assigns field paths and trims the configuration depth.
   * @param {keyof TModelsConfig} modelKey - The key identifying the model configuration.
   * @returns {MfModelConfigMapped} The mapped model configuration.
   * @throws {MfError} If the model configuration for the specified key does not exist.
   */
  public getOnlyUseForBuildMapped<TModelsConfig extends MfModelsConfig>(modelKey: keyof TModelsConfig): MfModelConfigMapped {
    if (mfTypeHasOwnProperty(this.modelsConfig, modelKey)) {
      const modelConfig = this.modelsConfig[modelKey] as MfModelConfig;
      const modelConfigClone = mfObjectClone<MfModelConfig>(modelConfig);
      this._assignFieldPathsAndTrimDepth(modelConfigClone);

      return modelConfigClone as MfModelConfigMapped;
    }
    throw new MfError(this.typeInfo, "get", `There is no model config for modelKey:${modelKey.toString()}`);
  }

  /**
   * @method get
   * @description Retrieves the cached or fresh mapped model configuration for a specified model key.
   * If the configuration is not cached, it will clone, assign field paths, and cache it.
   * @param {keyof TModelsConfig} modelKey - The key identifying the model configuration.
   * @returns {MfModelConfigMapped} The mapped model configuration.
   * @throws {MfError} If the model configuration for the specified key does not exist.
   */
  public get<TModelsConfig extends MfModelsConfig>(modelKey: keyof TModelsConfig): MfModelConfigMapped {
    if (mfTypeHasOwnProperty(this.modelsConfig, modelKey)) {
      let configCache = this._configCache.find(i => i.key === modelKey);
      if (mfTypeIsUndefined(configCache)) {
        const modelConfig = this.modelsConfig[modelKey] as MfModelConfig;
        const modelConfigClone = mfObjectClone<MfModelConfig>(modelConfig);
        this._assignFieldPathsAndTrimDepth(modelConfigClone);

        configCache = { key: modelKey as string, modelConfig: modelConfigClone as MfModelConfigMapped };

        this._configCache.push(configCache);
      }

      return mfObjectClone(configCache.modelConfig);
    }
    throw new MfError(this.typeInfo, "get", `There is no model config for modelKey:${modelKey.toString()}`);
  }

  /**
   * @method getUnMapped
   * @description Retrieves the original, unmapped model configuration for a specified model key.
   * @param {keyof TModelsConfig} modelKey - The key identifying the model configuration.
   * @returns {MfModelConfig} The original model configuration.
   * @throws {MfError} If the model configuration for the specified key does not exist.
   */
  public getUnMapped<TModelsConfig extends MfModelsConfig>(modelKey: keyof TModelsConfig): MfModelConfig {
    if (mfTypeHasOwnProperty(this.modelsConfig, modelKey)) {
      const modelConfig = this.modelsConfig[modelKey] as MfModelConfig;
      if (mfTypeIsUndefined(modelConfig)) {
        throw new MfError(this.typeInfo, "getUnMapped", `There is no model config for modelKey:${modelKey.toString()}`);
      }
      return mfObjectClone<MfModelConfig>(modelConfig);
    }
    throw new MfError(this.typeInfo, "getUnMapped", `There is no model config for modelKey:${modelKey.toString()}`);
  }

  /**
   * @method removeLastFieldPathSegment
   * @description Removes the last segment of a field path.
   * @param {MFModelConfigFieldPath} fieldPath - The field path from which to remove the last segment.
   * @returns {MFModelConfigFieldPath | undefined} The updated field path, or undefined if there was no segment to remove.
   */
  public removeLastFieldPathSegment(fieldPath: MFModelConfigFieldPath): MFModelConfigFieldPath | undefined {
    const lastIndexOf = fieldPath.lastIndexOf(MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR);
    if (lastIndexOf === -1) {
      return;
    }
    return fieldPath.substring(0, lastIndexOf);
  }

  /**
   * @method getRequiredFieldPaths
   * @description Retrieves all required field paths for a given model field configuration.
   * @param {MfModelFieldConfigMapped} modelFieldConfig - The model field configuration to analyze.
   * @returns {string[]} An array of required field paths.
   */
  public getRequiredFieldPaths(modelFieldConfig: MfModelFieldConfigMapped): string[] {
    const fieldPaths: string[] = [];

    if (!mfTypeIsUndefined(modelFieldConfig.fieldPath)) {
      fieldPaths.push(modelFieldConfig.fieldPath);
    }

    const keys = mfTypeGetKeys(this.modelRequiredFieldRetrieverTypes);
    const keysLength = keys.length;
    for (let keyIndex = 0; keyIndex < keysLength; keyIndex++) {
      const key = keys[keyIndex];
      const retrieverFunction: MfModelRequiredFieldRetrieverFunction = this.modelRequiredFieldRetrieverTypes[key];
      fieldPaths.push(...retrieverFunction(modelFieldConfig, this));
    }

    return fieldPaths;
  }

  /**
   * @method setExtendedConfigs
   * @description Applies extended configuration settings to a model configuration.
   * @param {MfModelConfigMapped} modelConfig - The model configuration to update.
   * @param {MfModelFieldExtendedConfig[]} modelFieldExtendedConfigs - The extended configurations to apply.
   * @throws {MfError} If a field path in the extended configuration cannot be found.
   */
  public setExtendedConfigs(modelConfig: MfModelConfigMapped, modelFieldExtendedConfigs: MfModelFieldExtendedConfig[]): void {
    const modelFieldExtendedConfigsClone = mfObjectClone(modelFieldExtendedConfigs);
    const modelFieldExtendedConfigsLength = modelFieldExtendedConfigsClone.length;
    for (let modelFieldExtendedConfigIndex = 0; modelFieldExtendedConfigIndex < modelFieldExtendedConfigsLength; modelFieldExtendedConfigIndex++) {
      const modelFieldExtendedConfig = modelFieldExtendedConfigsClone[modelFieldExtendedConfigIndex];
      const modelFieldConfig = this.findModelFieldConfigByFieldPath(modelConfig.fields, modelFieldExtendedConfig.fieldPath);
      if (!mfTypeIsUndefined(modelFieldConfig)) {
        const keys = mfTypeGetKeys(modelFieldExtendedConfig).filter(k => k !== mfObjectGetPropertyPath<MfModelFieldConfigMapped>("fieldPath"));
        const keysLength = keys.length;
        for (let keysIndex = 0; keysIndex < keysLength; keysIndex++) {
          const key = keys[keysIndex];
          (modelFieldConfig as any)[key] = modelFieldExtendedConfig[key];
        }
      } else {
        throw new MfError(this.typeInfo, "setExtendedConfigs", `Unable to find fieldPath:${modelFieldExtendedConfig.fieldPath}`);
      }
    }
  }

  /**
   * @method findModelFieldConfigByRelativePath
   * @description Finds a model field configuration by its relative path.
   * @param {MfModelFieldsConfigMapped} modelFieldsConfig - The model fields configuration to search within.
   * @param {string} relativePath - The relative path to search for.
   * @param {number} [sectionIndex=0] - The current section index during the search (used internally).
   * @returns {MfModelFieldConfigMapped | undefined} The found model field configuration, or undefined if not found.
   */
  public findModelFieldConfigByRelativePath(modelFieldsConfig: MfModelFieldsConfigMapped, relativePath: string, sectionIndex: number = 0): MfModelFieldConfigMapped | undefined {
    const keySections = relativePath.split(MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR);
    const keySection = keySections[sectionIndex];

    const modelFieldKeys = mfTypeGetKeys(modelFieldsConfig);
    const modelFieldKeysLength = modelFieldKeys.length;
    for (let keyIndex = 0; keyIndex < modelFieldKeysLength; keyIndex++) {
      const modelFieldKey = modelFieldKeys[keyIndex];
      const modelFieldConfig = modelFieldsConfig[modelFieldKey];
      if (modelFieldKey === keySection) {
        if (this._modelFieldConfigHasModelFields(modelFieldConfig)) {
          const subModelFieldConfig = this.findModelFieldConfigByRelativePath(modelFieldConfig.model.fields, relativePath, sectionIndex + 1);
          if (!mfTypeIsUndefined(subModelFieldConfig)) {
            return subModelFieldConfig;
          }
        } else if (sectionIndex === (keySections.length - 1)) {
          return modelFieldConfig;
        }
      }
    }

    return;
  }

  /**
   * @method findModelFieldConfigByFieldPath
   * @description Finds a model field configuration by its full field path.
   * @param {MfModelFieldsConfigMapped} modelFieldsConfig - The model fields configuration to search within.
   * @param {MFModelConfigFieldPath} fieldPath - The full field path to search for.
   * @returns {MfModelFieldConfigMapped | undefined} The found model field configuration, or undefined if not found.
   */
  public findModelFieldConfigByFieldPath(modelFieldsConfig: MfModelFieldsConfigMapped, fieldPath: MFModelConfigFieldPath): MfModelFieldConfigMapped | undefined {
    const result = this.forEachModelFieldRecursive(modelFieldsConfig, (modelFieldConfig) => {
      if (modelFieldConfig.fieldPath === fieldPath) {
        return modelFieldConfig;
      }
      return;
    });

    return result;
  }

  /**
   * @method getModelFieldConfigByFieldKey
   * @description Retrieves a model field configuration by its field key.
   * @param {MfModelFieldsConfigMapped} modelFieldsConfig - The model fields configuration to search within.
   * @param {MFModelConfigFieldKey} fieldKey - The key identifying the field configuration.
   * @returns {MfModelFieldConfigMapped} The found model field configuration.
   * @throws {MfError} If the field key does not exist in the model fields configuration.
   */
  public getModelFieldConfigByFieldKey(modelFieldsConfig: MfModelFieldsConfigMapped, fieldKey: MFModelConfigFieldKey): MfModelFieldConfigMapped {
    if (mfTypeHasOwnProperty(modelFieldsConfig, fieldKey)) {
      const config = modelFieldsConfig[fieldKey];
      if (!mfTypeIsUndefined(config)) {
        return config;
      }
    }
    throw new MfError(this.typeInfo, "getModelFieldConfigByFieldKey", `modelFieldsConfig does not contain fieldKey:${fieldKey}`);
  }

  /**
  * Recursively iterates over each field in the provided model fields configuration, applying the specified action.
  * 
  * This method traverses through the entire hierarchy of the model's fields, including nested objects.
  * It applies the `action` function to each field configuration. If the `action` function returns
  * a value that is not `undefined`, the iteration stops and that value is returned.
  * 
  * @template TReturn - The type of the return value of the `action` function.
  * @template TModel - The type of the model, which extends `MfModelBase`. Defaults to `MfModelBase`.
  * 
  * @param {MfModelFieldsConfigMapped} modelFieldsConfig - The configuration of the model fields to iterate over.
  * @param {(modelFieldConfig: MfModelFieldConfigMapped) => TReturn | undefined} action - The function to apply to each model field configuration. 
  * The function can return a value to stop further iteration.
  * @param {MfModelConfigForEachModelFilter<TModel>} [filter] - An optional filter that determines whether a field should be processed.
  * If provided, only fields that match the filter are processed.
  * 
  * @returns {TReturn | undefined} - The first non-undefined value returned by the `action` function, or `undefined` if no such value is returned.
  * 
  * @public
  * @memberof MfTableComponent
  */
  public forEachModelFieldRecursive<TReturn, TModel = MfModelBase>(modelFieldsConfig: MfModelFieldsConfigMapped, action: (modelFieldConfig: MfModelFieldConfigMapped) => TReturn | undefined, filter?: MfModelConfigForEachModelFilter<TModel>): TReturn | undefined {
    const modelFieldKeys = mfTypeGetKeys(modelFieldsConfig);
    const modelFieldKeysLength = modelFieldKeys.length;
    const hasFilter = !mfTypeIsUndefined(filter);

    for (let keyIndex = 0; keyIndex < modelFieldKeysLength; keyIndex++) {
      const modelFieldKey = modelFieldKeys[keyIndex];
      const modelFieldConfig = modelFieldsConfig[modelFieldKey];

      if (hasFilter === true && !filter!.selector(filter!.model, modelFieldConfig)) {
        continue;
      }

      const actionResultA = action(modelFieldConfig);
      if (!mfTypeIsUndefined(actionResultA)) {
        return actionResultA;
      }

      const nestedModelFields = modelFieldConfig.model?.fields;
      if (!mfTypeIsUndefined(nestedModelFields)) {
        const actionResultB = this.forEachModelFieldRecursive(nestedModelFields, action);
        if (!mfTypeIsUndefined(actionResultB)) {
          return actionResultB;
        }
      }

    }

    return;
  }

  /**
  * Iterates over each field in the provided model fields configuration, applying the specified action.
  * 
  * This method only processes the top-level fields in the model fields configuration and does not
  * recurse into nested objects. It applies the `action` function to each field configuration. 
  * If the `action` function returns a value that is not `undefined`, the iteration stops and that 
  * value is returned.
  * 
  * @template TReturn - The type of the return value of the `action` function.
  * @template TModel - The type of the model, which extends `MfModelBase`. Defaults to `MfModelBase`.
  * 
  * @param {MfModelFieldsConfigMapped} modelFieldsConfig - The configuration of the model fields to iterate over.
  * @param {(modelFieldConfig: MfModelFieldConfigMapped) => TReturn | undefined} action - The function to apply to each model field configuration. 
  * The function can return a value to stop further iteration.
  * @param {MfModelConfigForEachModelFilter<TModel>} [filter] - An optional filter that determines whether a field should be processed.
  * If provided, only fields that match the filter are processed.
  * 
  * @returns {TReturn | undefined} - The first non-undefined value returned by the `action` function, or `undefined` if no such value is returned.
  * 
  * @public
  * @memberof MfTableComponent
  */
  public forEachModelFieldNonRecursive<TReturn, TModel = MfModelBase>(modelFieldsConfig: MfModelFieldsConfigMapped, action: (modelFieldConfig: MfModelFieldConfigMapped) => TReturn | undefined, filter?: MfModelConfigForEachModelFilter<TModel>): TReturn | undefined {
    const modelFieldKeys = mfTypeGetKeys(modelFieldsConfig);
    const modelFieldKeysLength = modelFieldKeys.length;
    const hasFilter = !mfTypeIsUndefined(filter);

    for (let keyIndex = 0; keyIndex < modelFieldKeysLength; keyIndex++) {
      const modelFieldKey = modelFieldKeys[keyIndex];
      const modelFieldConfig = modelFieldsConfig[modelFieldKey];

      if (hasFilter === true && !filter!.selector(filter!.model, modelFieldConfig)) {
        continue;
      }

      const resultA = action(modelFieldConfig);
      if (!mfTypeIsUndefined(resultA)) {
        return resultA;
      }
    }

    return;
  }

  /**
   * @method _modelFieldConfigHasModelFields
   * @protected
   * @description Checks if a model field configuration has nested model fields.
   * @template TModelConfig
   * @template TModelFieldConfig
   * @template TModelFieldsConfig
   * @param {TModelFieldConfig} modelFieldConfig - The model field configuration to check.
   * @returns {boolean} True if the model field configuration has nested model fields, otherwise false.
   */
  protected _modelFieldConfigHasModelFields<TModelConfig extends MfModelConfig, TModelFieldConfig extends MfModelFieldConfig, TModelFieldsConfig extends MfModelFieldsConfig>(modelFieldConfig: TModelFieldConfig):
    modelFieldConfig is { model: { fields: TModelFieldsConfig } & TModelConfig } & TModelFieldConfig {
    return !mfTypeIsNullOrUndefined(modelFieldConfig.model) && !mfTypeIsNullOrUndefined(modelFieldConfig.model.fields);
  }

  /**
   * @method _assignFieldPathsAndTrimDepth
   * @protected
   * @description Assigns field paths to a model configuration and trims the configuration depth based on the maxModelDepth setting.
   * @param {MfModelConfig} modelConfig - The model configuration to update.
   * @param {number} [depth=0] - The current depth during recursion (used internally).
   * @param {MfModelFieldConfig} [parentModelFieldConfig] - The parent model field configuration (used internally).
   * @param {MfModelConfig} [rootModelConfig] - The root model configuration (used internally).
   */
  protected _assignFieldPathsAndTrimDepth(modelConfig: MfModelConfig, depth: number = 0, parentModelFieldConfig?: MfModelFieldConfig, rootModelConfig?: MfModelConfig): void {
    const modelFieldKeys = mfTypeGetKeys(modelConfig.fields);

    for (let keyIndex = 0; keyIndex < modelFieldKeys.length; keyIndex++) {
      const modelFieldKey = modelFieldKeys[keyIndex];
      const modelFieldConfig = modelConfig.fields[modelFieldKey];

      modelFieldConfig.fieldKey = modelFieldKey.toString();

      if (this._modelFieldConfigHasModelFields(modelFieldConfig)) {
        if (depth > this.config.maxModelDepth || rootModelConfig?.key === modelFieldConfig.model.key) {
          delete modelConfig.fields[modelFieldKey];
          modelFieldKeys.splice(keyIndex, 1);
          keyIndex--;
          continue;
        } else {
          modelFieldConfig.model = mfObjectClone(modelFieldConfig.model);
        }
      }



      if (!mfTypeIsUndefined(parentModelFieldConfig)) {
        modelFieldConfig.fieldPath = `${!mfTypeIsUndefined(modelFieldConfig.fieldPath) ? modelFieldConfig.fieldPath + MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR : ""}${parentModelFieldConfig.fieldPath}.${modelFieldKey}`;

        if (!mfTypeIsUndefined(modelFieldConfig.display) && !mfTypeIsUndefined(parentModelFieldConfig.display)) {
          modelFieldConfig.display.displayName = `${parentModelFieldConfig.display.displayName} ${modelFieldConfig.display.displayName}`;
        }
      } else {
        modelFieldConfig.fieldPath = modelFieldKey as string;
      }

      if (this._modelFieldConfigHasModelFields(modelFieldConfig)) {
        if (depth <= this.config.maxModelDepth) {
          this._assignFieldPathsAndTrimDepth(modelFieldConfig.model, depth + 1, modelFieldConfig, modelConfig);
        }
      }
    }
  }
}