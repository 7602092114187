import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeGetKeys, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFilterBaseComponent } from "@material-framework/filter/base/filter.base.component";
import { MfFilterExpression } from "@material-framework/filter/filter";
import { MF_FILTER_CONFIG_TOKEN, MfFilterConfig } from "@material-framework/filter/filter.config";
import { MfFilterOperatorTypes } from "@material-framework/filter/filter.types";
import { MfFilterService } from "@material-framework/filter/services/filter.service";
import { MfModelFieldsConfigMapped, MfModelFieldConfigMapped, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfSelectOption } from "@material-framework/select/select.option";

const TYPE_INFO: MfTypeInfo = { className: "MfFilterExpressionComponent" };

export function mfFilterFieldSelectGetOptions(modelFieldsConfig: MfModelFieldsConfigMapped): MfFilterFieldOption[] {
  const options = [];
  const fieldKeys = mfTypeGetKeys(modelFieldsConfig);
  const fieldKeysLength = fieldKeys.length;
  for (let i = 0; i < fieldKeysLength; i++) {
    const fieldKey = fieldKeys[i] as string;
    const modelFieldConfig = modelFieldsConfig[fieldKey];
    if (!mfTypeIsUndefined(modelFieldConfig) && !mfTypeIsUndefined(modelFieldConfig.filter) && !mfTypeIsUndefined(modelFieldConfig.display)) {
      if (!mfTypeIsUndefined(modelFieldConfig.model) && !mfTypeIsUndefined(modelFieldConfig.model.fields) && !mfTypeIsUndefined(modelFieldConfig.filter.flatten)) {
        options.push(...mfFilterFieldSelectGetOptions(modelFieldConfig.model.fields));
      } else {
        options.push({ value: modelFieldConfig.fieldPath!, fieldKey, modelFieldConfig: modelFieldConfig, label: modelFieldConfig.filter.displayName || modelFieldConfig.display.displayName });
      }
    }
  }

  return options;
}

export type MfFilterFieldOption = MfSelectOption<string> & {
  modelFieldConfig: MfModelFieldConfigMapped,
  fieldKey: string,
}

@Component({
  selector: "mf-filter-field-select",
  templateUrl: "filter.field.select.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfFilterFieldSelectComponent extends MfFilterBaseComponent implements OnInit {
  @Input()
  public modelFieldsConfig?: MfModelFieldsConfigMapped;

  @Input()
  public expression?: MfFilterExpression;

  @Output()
  public onFieldChanged: EventEmitter<MfFilterExpression> = new EventEmitter();

  protected _fields: MfFilterFieldOption[] = [];

  public constructor(
    protected override _injector: Injector,
    protected _modelConfigService: MfModelConfigService,
    protected _filterService: MfFilterService,
    protected _changeDetectorRef: ChangeDetectorRef,
    @Inject(MF_FILTER_CONFIG_TOKEN) public config: MfFilterConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    if (!mfTypeIsUndefined(this.modelFieldsConfig)) {
      this._fields = mfFilterFieldSelectGetOptions(this.modelFieldsConfig);
    }
  }

  protected _onSelectionChange(option: MfFilterFieldOption | undefined): void {
    if (!mfTypeIsUndefined(this.expression) && !mfTypeIsUndefined(option)) {
      this.expression.fieldKey = option.fieldKey;
      this.expression.modelFieldConfig = option.modelFieldConfig;
      this.expression.fieldPath = this.expression.modelFieldConfig.fieldPath!;

      if (this.expression.modelFieldConfig.dataType.type === MfModelFieldDataTypes.object) {
        this.expression.subGroup = this._filterService.getNewFilterGroup();
        this.expression.operatorKey = MfFilterOperatorTypes.none;
      } else {
        delete this.expression.subGroup;
      }

      this._changeDetectorRef.detectChanges();
      this.onFieldChanged.emit(this.expression);
    }
  }
}