<div class="mf-column ecom-page-container ecom-product-detail-container">
  <div class="ecom-page-header">
    <div class="ecom-title">
      Product Detail
    </div>
  </div>
  <div class="mf-row mf-large-margin-bottom detail"
       style="margin-bottom: 62px;">
    <div class="mf-column mf-large-margin-right image">
      <img width="500"
           [src]="_itemDetail?.imageUrl">

    </div>
    <div class="mf-column mf-large-margin-left">
      <div class="mf-row head-line mat-headline-4">
        <div class="mf-x-large-margin-right">
          {{ _itemDetail?.itemCode }}
        </div>
        <div class="mf-flex-fill"></div>
        <div class="mf-x-large-margin-left">
          {{
          (
          _isUndefined(_itemDetail) ||
          _isUndefined(_itemDetail.priceBreaks) ||
          _isUndefined(_itemDetail.priceBreaks[0]
          )
          ? 0 : _itemDetail.priceBreaks[0].price) | mfCurrency }} {{
          (
          _isUndefined(_itemDetail) ||
          _isUndefined(_itemDetail.priceBreaks) ||
          _isUndefined(_itemDetail.priceBreaks[0]
          )
          ? '' : _itemDetail.priceBreaks[0].currencyCode) }}
        </div>
      </div>
      <div class="mf-large-margin-bottom">
        {{ _itemDetail?.itemDescription }}
      </div>
      <div class="mf-row mf-large-margin-bottom">
        <div class="mf-large-margin-bottom mf-x-large-margin-right ecom-features-container">
          <div class="mat-subtitle-1">
            Product Features
          </div>
          <div>
            <table class="mf-table-simple-lined">
              <tr *ngFor="let feature of _itemFeatures">
                <td> {{ feature.label }} </td>
                <td> {{ feature.value }} </td>
              </tr>
              <tr *ngIf="_itemFeatures.length === 0">
                <td> No Product Features Listed </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="mf-x-large-margin-left">
          <table class="mf-table-simple-lined">
            <tr>
              <th style="text-align: left;">
                Qty
              </th>
              <th style="text-align: right;">
                Price
              </th>
            </tr>
            <tr *ngFor="let priceBreak of _itemDetail?.priceBreaks">
              <td class="ecom-qty">
                {{ priceBreak.quantity }}
              </td>
              <td class="ecom-price">
                {{ priceBreak.price | mfCurrency }} {{priceBreak.currencyCode}}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="actions">
        <button mat-raised-button
                *ngIf="!_isUndefined(_itemSpecKey)"
                [disabled]="_isLoading"
                (click)="_onShowAllStockLocationsClicked()"
                class="mf-default-margin-right">
          {{_showAllStockLocations === true ? 'Hide Additional Stock' : 'Show all stock'}}
        </button>
        <button mat-raised-button
                class="mf-default-margin-right"
                (click)="_onContinueShoppingClicked()">Continue Shopping</button>
        <button mat-raised-button
                *ngIf="_hasSinglePriceBreak"
                [disabled]="!_selectionOrderValid"
                (click)="_onAddToCartClicked()"
                color="accent">Add To Cart</button>
        <button mat-raised-button
                color="accent"
                (click)="_onGetAQuoteClicked()"
                *ngIf="!_isUndefined(_itemSpecKey) && !_hasSinglePriceBreak">Request A Quote</button>
      </div>
    </div>
  </div>
  <div>
    <mf-table #stockSummeryTable
              viewManagerEnabled="true"
              paginationEnabled="false"
              (onRowsChanged)="_onRowsChanged($event)"
              (onRowValueInput)="_onRowValueChanged()"
              [locationKey]="_locationKey"
              [modelConfig]="_stockSummeryModelConfig"
              [postModelConfig]="_stockSummeryRequestModelConfig"
              (onItemDetailExpand)="_onItemDetailExpand($event)">
      <ng-container mfTableColumnExpandDef>
        <ng-container *mfTableCellDef="let item">
          <ecom-manufacturer-detail-line #manufacturerDetails
                                         [stockSummery]="item"></ecom-manufacturer-detail-line>
        </ng-container>
      </ng-container>
    </mf-table>
  </div>
</div>