import { InjectionToken } from "@angular/core";
import { MfSortTypes } from "@material-framework/common/sort/sort.types";
import { mfTypeGetKeys, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelFieldFilterConfig } from "@material-framework/filter/filter.model.config";
import { MfFunctionPointer } from "@material-framework/functionRef/function.ref";
import { MfGraphQlModelConfig, MfGraphQlModelFieldConfig } from "@material-framework/graphQL/graph.ql.model.config";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfPortalsModelConfig } from "@material-framework/portals/portals.model.config";
import { MfModelFieldStatusChipConfig } from "@material-framework/statusChip/status.chip.model.config";
import { MfModelFieldTableConfig } from "@material-framework/table/table.model.config";
import { MfModelFieldUserPersonsConfig } from "@material-framework/userPersona/user.persona.model.config";

/**
 * @function mfModelLinkModelConfig
 * @description Links a model configuration from one model field configuration to another model field configuration.
 * This is useful for associating configurations between different models.
 * @param {MfModelFieldsConfig} setToModelFieldsConfig - The model fields configuration where the model should be set.
 * @param {Extract<keyof TModel, string>} name - The name of the model field.
 * @param {MfModelConfig} fromModelConfig - The model configuration to link.
 */
export function mfModelLinkModelConfig<TModel>(setToModelFieldsConfig: MfModelFieldsConfig, name: Extract<keyof TModel, string>, fromModelConfig: MfModelConfig): void {
  const setToModelFieldConfig = setToModelFieldsConfig[name];
  setToModelFieldConfig.model = fromModelConfig;
}

/**
 * @constant MF_MODEL_CONFIGS_TOKEN
 * @description Injection token used to provide model configurations throughout the application.
 * @type {InjectionToken<MfModelsConfig>}
 */
export const MF_MODEL_CONFIGS_TOKEN = new InjectionToken<MfModelsConfig>("MfModelConfigs");

/**
 * @constant MF_MODEL_CONFIGS
 * @description Default empty object to hold model configurations.
 * @type {MfModelsConfig}
 */
export const MF_MODEL_CONFIGS: MfModelsConfig = {};

/**
 * @constant MF_MODEL_DEFAULTS_CONFIG_TOKEN
 * @description Injection token used to provide default model configurations throughout the application.
 * @type {InjectionToken<MfModelDefaultsConfig>}
 */
export const MF_MODEL_DEFAULTS_CONFIG_TOKEN = new InjectionToken<MfModelDefaultsConfig>("MfModelDefaultsConfig");

/**
 * @constant MF_MODEL_DEFAULTS_CONFIG
 * @description Default configuration settings for models, including data types and maximum model depth.
 * @type {MfModelDefaultsConfig}
 */
export const MF_MODEL_DEFAULTS_CONFIG: MfModelDefaultsConfig = {
  dataTypes: {
    array: {},
    boolean: {},
    byte: {},
    date: {
      format: "YYYY-MM-DD",
    },
    dateTime: {},
    decimal: {},
    double: {},
    float: {},
    int: {},
    long: {},
    object: {},
    sbyte: {},
    short: {},
    string: {},
    uint: {},
    ulong: {},
    ushort: {},
  },
  maxModelDepth: 3,
};

/**
 * @constant MfModelFieldArrayType
 * @description Defines a constant object that holds the available array types for model fields.
 * @type {{ array: string }}
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfModelFieldArrayType = {
  array: "array",
} as const;

/**
 * @type MfModelFieldArrayType
 * @description Type definition for the available array types for model fields.
 */
export type MfModelFieldArrayType = typeof MfModelFieldArrayType[keyof typeof MfModelFieldArrayType];

/**
 * @constant MfModelFieldOtherTypes
 * @description Defines a constant object that holds the available primitive types for model fields.
 * @type {{ string: string, int: string, decimal: string, boolean: string, object: string, date: string }}
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfModelFieldOtherTypes = {
  string: "string",
  boolean: "boolean",
  object: "object",
  dateTime: "dateTime",
  date: "date",
} as const;

/**
 * @type MfModelFieldOtherTypes
 * @description Type definition for the available primitive types for model fields.
 */
export type MfModelFieldOtherTypes = typeof MfModelFieldOtherTypes[keyof typeof MfModelFieldOtherTypes];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfModelFieldIntegralTypes = {
  sbyte: "sbyte",
  byte: "byte",
  short: "short",
  ushort: "ushort",
  int: "int",
  uint: "uint",
  long: "long",
  ulong: "ulong",
} as const;

export type MfModelFieldIntegralTypes = typeof MfModelFieldIntegralTypes[keyof typeof MfModelFieldIntegralTypes];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfModelFieldFloatingPointTypes = {
  float: "float",
  double: "double",
  decimal: "decimal",
} as const;

export type MfModelFieldFloatingPointTypes = typeof MfModelFieldFloatingPointTypes[keyof typeof MfModelFieldFloatingPointTypes];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfModelFieldNumberTypes = {
  ...MfModelFieldIntegralTypes,
  ...MfModelFieldFloatingPointTypes,
} as const;

export type MfModelFieldNumberTypes = typeof MfModelFieldNumberTypes[keyof typeof MfModelFieldNumberTypes];

export function mfModelFieldDataTypeIsFloatingPoint(type: MfModelFieldDataTypes): boolean {
  return !mfTypeIsUndefined(mfTypeGetKeys(MfModelFieldFloatingPointTypes).find(i => i === type));
}

export function mfModelFieldDataTypeIsIntegral(type: MfModelFieldDataTypes): boolean {
  return !mfTypeIsUndefined(mfTypeGetKeys(MfModelFieldIntegralTypes).find(i => i === type));
}

export function mfModelFieldDataTypeIsNumber(type: MfModelFieldDataTypes): boolean {
  return !mfTypeIsUndefined(mfTypeGetKeys(MfModelFieldNumberTypes).find(i => i === type));
}

/**
 * @constant MfModelFieldDataTypes
 * @description Combines the array types and primitive types into a single object that holds all possible data types for model fields.
 * @type {typeof MfModelFieldDataTypes}
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfModelFieldDataTypes = {
  ...MfModelFieldArrayType,
  ...MfModelFieldOtherTypes,
  ...MfModelFieldNumberTypes,
} as const;

/**
 * @type MfModelFieldDataTypes
 * @description Type definition for all possible data types for model fields.
 */
export type MfModelFieldDataTypes = typeof MfModelFieldDataTypes[keyof typeof MfModelFieldDataTypes];

/**
 * @type MfModelFieldDataTypesForValueFormatters
 * @description Type definition for the data types used in value formatters.
 */
export type MfModelFieldDataTypesForValueFormatters = "string" | "number" | "decimal" | "boolean" | "date" | "moment";

/**
 * @type MfModelFieldTypesForValueFormatters
 * @description Type definition for the possible types that can be used in value formatters.
 */
export type MfModelFieldTypesForValueFormatters = string | number | boolean | Date | moment.Moment;

/**
 * @type MFModelConfigKey
 * @description Type alias for a string representing a model configuration key.
 */
export type MFModelConfigKey = string;

/**
 * @type MFModelConfigFieldPath
 * @description Type alias for a string representing a model configuration field path.
 */
export type MFModelConfigFieldPath = string;

/**
 * @type MFModelConfigFieldKey
 * @description Type alias for a string representing a model configuration field key.
 */
export type MFModelConfigFieldKey = string;

/**
 * @type MfModelDefaultsConfig
 * @description Type definition for the default configuration of a model, including data types and maximum depth.
 */
export type MfModelDefaultsConfig = {
  dataTypes: MfModelDataTypesDefaultsConfig;
  maxModelDepth: number;
}

/**
 * @type MfModelDataTypesDefaultsConfig
 * @description Type definition for the default data types configuration of a model.
 */
export type MfModelDataTypesDefaultsConfig = {
  [K in keyof typeof MfModelFieldDataTypes]: MfModelDataTypesDefaultsConfigTypesMap[K];
}

export type MfModelDataTypesDefaultsConfigTypesMap = {
  [MfModelFieldDataTypes.array]: MfModelDataTypeArrayDefaultsConfig;
  [MfModelFieldDataTypes.boolean]: MfModelDataTypeBooleanDefaultsConfig;
  [MfModelFieldDataTypes.byte]: MfModelDataTypeByteDefaultsConfig;
  [MfModelFieldDataTypes.date]: MfModelDataTypeDateDefaultsConfig;
  [MfModelFieldDataTypes.dateTime]: MfModelDataTypeDateTimeDefaultsConfig;
  [MfModelFieldDataTypes.decimal]: MfModelDataTypeDecimalDefaultsConfig;
  [MfModelFieldDataTypes.double]: MfModelDataTypeDoubleDefaultsConfig
  [MfModelFieldDataTypes.float]: MfModelDataTypeFloatDefaultsConfig
  [MfModelFieldDataTypes.int]: MfModelDataTypeIntDefaultsConfig;
  [MfModelFieldDataTypes.long]: MfModelDataTypeLongDefaultsConfig
  [MfModelFieldDataTypes.object]: MfModelDataTypeObjectDefaultsConfig;
  [MfModelFieldDataTypes.sbyte]: MfModelDataTypeSByteDefaultsConfig
  [MfModelFieldDataTypes.short]: MfModelDataTypeShortDefaultsConfig
  [MfModelFieldDataTypes.string]: MfModelDataTypeStringDefaultsConfig;
  [MfModelFieldDataTypes.uint]: MfModelDataTypeUintDefaultsConfig
  [MfModelFieldDataTypes.ulong]: MfModelDataTypeUlongDefaultsConfig
  [MfModelFieldDataTypes.ushort]: MfModelDataTypeUshortDefaultsConfig
}

export type MfModelDataTypeArrayDefaultsConfig = {}
export type MfModelDataTypeBooleanDefaultsConfig = {}
export type MfModelDataTypeByteDefaultsConfig = {}
export type MfModelDataTypeDateDefaultsConfig = {
  format: string
}
export type MfModelDataTypeDateTimeDefaultsConfig = {}
export type MfModelDataTypeDecimalDefaultsConfig = {}
export type MfModelDataTypeDoubleDefaultsConfig = {}
export type MfModelDataTypeFloatDefaultsConfig = {}
export type MfModelDataTypeIntDefaultsConfig = {}
export type MfModelDataTypeLongDefaultsConfig = {}
export type MfModelDataTypeObjectDefaultsConfig = {}
export type MfModelDataTypeSByteDefaultsConfig = {}
export type MfModelDataTypeShortDefaultsConfig = {}
export type MfModelDataTypeStringDefaultsConfig = {}
export type MfModelDataTypeUintDefaultsConfig = {}
export type MfModelDataTypeUlongDefaultsConfig = {}
export type MfModelDataTypeUshortDefaultsConfig = {}


/**
 * @type MfModelConfigFieldPathOrder
 * @template TFieldPath
 * @description Type definition for a field path order configuration, which includes the field path and sort direction.
 */
export type MfModelConfigFieldPathOrder<TFieldPath = MFModelConfigFieldPath> = {
  fieldPath: TFieldPath;
  direction: MfSortTypes;
}

/**
 * @type MfModelFieldDisplayConfig
 * @description Type definition for the display configuration of a model field, including display name and optional tooltip.
 */
export type MfModelFieldDisplayConfig = {
  displayName: string;
  tooltip?: string;
}

/**
 * @type MfModelFieldDataTypeEnumConfig
 * @description Type definition for the enum configuration of a model field's data type.
 */
export type MfModelFieldDataTypeEnumConfig = {
  mappings: MfModelFieldDataTypeEnumMappingConfig[];
}

/**
 * @type MfModelFieldDataTypeEnumMappingConfig
 * @description Type definition for the mapping configuration of an enum value and its display value in a model field.
 */
export type MfModelFieldDataTypeEnumMappingConfig = {
  value: string | number;
  displayValue: string;
}

/**
 * @type MfModelsConfig
 * @description Type definition for the configuration of all models in the application.
 */
export type MfModelsConfig = {}

/**
 * @type MfModelConfigMappedGenOptions
 * @description Type definition for the options used when generating mapped model configurations.
 */
export type MfModelConfigMappedGenOptions = {
  constName: string;
}

/**
 * @type MfModelConfig
 * @template TFieldPath
 * @description Type definition for the configuration of a model, including fields and optional configurations for GraphQL, portals, and user personas.
 */
export type MfModelConfig<TFieldPath = MFModelConfigFieldPath> = {
  fields: MfModelFieldsConfig<TFieldPath>;
  key: MFModelConfigKey;
  autoMapping: MfModelConfigMappedGenOptions;
  graphQl?: MfGraphQlModelConfig;
  portals?: MfPortalsModelConfig;
  userPersona?: MfModelFieldUserPersonsConfig;
}

/**
 * @type MfModelConfigMapped
 * @template TFieldPath
 * @description Type definition for a mapped model configuration, extending the basic model configuration with mapped fields.
 */
export type MfModelConfigMapped<TFieldPath = MFModelConfigFieldPath> = MfModelConfig<TFieldPath> & {
  fields: MfModelFieldsConfigMapped<TFieldPath>;
}

/**
 * @type MfModelFieldsConfigMapped
 * @template TFieldPath
 * @description Type definition for the mapped configuration of model fields, using field keys to reference field configurations.
 */
export type MfModelFieldsConfigMapped<TFieldPath = MFModelConfigFieldPath> = {
  [fieldKey: MFModelConfigFieldKey]: MfModelFieldConfigMapped<TFieldPath>;
}

/**
 * @type MfModelFieldsConfig
 * @template TFieldPath
 * @description Type definition for the configuration of model fields, using field keys to reference field configurations.
 */
export type MfModelFieldsConfig<TFieldPath = MFModelConfigFieldPath> = {
  [fieldKey: MFModelConfigFieldKey]: MfModelFieldConfig<TFieldPath>;
}

/**
 * @type MfModelFieldOptionsConfig
 * @template TFieldPath
 * @description Type definition for the configuration options of a model field, including display, table, filter, and status chip configurations.
 */
export type MfModelFieldOptionsConfig<TFieldPath = MFModelConfigFieldPath> = {
  isId?: boolean;
  options?: MfModelFieldOptions;
  display?: MfModelFieldDisplayConfig;
  model?: MfModelConfig<TFieldPath>;
  table?: MfModelFieldTableConfig;
  filter?: MfModelFieldFilterConfig;
  statusChip?: MfModelFieldStatusChipConfig;
  calculated?: MfModelFieldCalculated;
  fieldKey?: string;
  graphQl?: MfGraphQlModelFieldConfig;
}

/**
 * @type MfModelFieldExtendedConfig
 * @template TFieldPath
 * @description Type definition for the extended configuration of a model field, extending the basic options with a required field path.
 */
export type MfModelFieldExtendedConfig<TFieldPath = MFModelConfigFieldPath> = MfModelFieldOptionsConfig<TFieldPath> & {
  fieldPath: TFieldPath;
}

/**
 * @type MfModelFieldConfig
 * @template TFieldPath
 * @description Type definition for the configuration of a model field, including its data type and optional configurations.
 */
export type MfModelFieldConfig<TFieldPath = MFModelConfigFieldPath> = MfModelFieldOptionsConfig<TFieldPath> & {
  dataType: MfModelFieldDataTypeConfig<TFieldPath>;
  fieldPath?: TFieldPath;
}

/**
 * @type MfModelFieldDataTypeConfig
 * @template TFieldPath
 * @description Type definition for the configuration of a model field's data type, which can be a primitive type or an array type.
 */
export type MfModelFieldDataTypeConfig<TFieldPath = MFModelConfigFieldPath> = MfModelFieldDataTypeConfigBase<TFieldPath> & {
  type: MfModelFieldOtherTypes;
} | MfModelFieldDataTypeConfigBase<TFieldPath> & {
  type: MfModelFieldNumberTypes;
} | MfModelFieldDataTypeConfigBase<TFieldPath> & {
  type: MfModelFieldArrayType;
  arrayItemType: MfModelFieldDataTypes;
}

/**
 * @type MfModelFieldDataTypeConfigBase
 * @template TFieldPath
 * @description Type definition for the base configuration of a model field's data type, including optional enum, custom format, and percentage/currency/user persona settings.
 */
export type MfModelFieldDataTypeConfigBase<TFieldPath = MFModelConfigFieldPath> = {
  enum?: MfModelFieldDataTypeEnumConfig;
  customFormateFunctionPointer?: MfFunctionPointer<MfModelFieldTypesForValueFormatters, unknown, MfModelFieldConfig<TFieldPath>>,
  percentage?: MfModelFieldDataTypePercentageConfig;
  currency?: MfModelFieldDataTypeCurrencyConfig;
  userPersons?: MfModelFieldDataTypeUserPersonsConfig;
  valueFormatter?: MfModelFieldDataTypeValueFormatterConfig;
}

export type MfModelFieldDataTypeValueFormatterConfig = {
  converter?: (value: unknown) => unknown;
  dataTypes?: MfModelFieldDataTypeValueFormatterTypesConfig;
}

export type MfModelFieldDataTypeValueFormatterTypesConfig = {
  [K in keyof typeof MfModelFieldDataTypes]?: MfModelFieldDataTypeValueFormatterTypesMap[K];
}

export type MfModelFieldDataTypeValueFormatterTypesMap = {
  [MfModelFieldDataTypes.array]: MfModelFieldDataTypeValueFormatterTypeArrayConfig;
  [MfModelFieldDataTypes.boolean]: MfModelFieldDataTypeValueFormatterTypeBooleanConfig;
  [MfModelFieldDataTypes.byte]: MfModelFieldDataTypeValueFormatterTypeByteConfig;
  [MfModelFieldDataTypes.date]: MfModelFieldDataTypeValueFormatterTypeDateConfig;
  [MfModelFieldDataTypes.dateTime]: MfModelFieldDataTypeValueFormatterTypeDateTimeConfig;
  [MfModelFieldDataTypes.decimal]: MfModelFieldDataTypeValueFormatterTypeDecimalConfig;
  [MfModelFieldDataTypes.double]: MfModelFieldDataTypeValueFormatterTypeDoubleConfig
  [MfModelFieldDataTypes.float]: MfModelFieldDataTypeValueFormatterTypeFloatConfig
  [MfModelFieldDataTypes.int]: MfModelFieldDataTypeValueFormatterTypeIntConfig;
  [MfModelFieldDataTypes.long]: MfModelFieldDataTypeValueFormatterTypeLongConfig
  [MfModelFieldDataTypes.object]: MfModelFieldDataTypeValueFormatterTypeObjectConfig;
  [MfModelFieldDataTypes.sbyte]: MfModelFieldDataTypeValueFormatterTypeSbyteConfig
  [MfModelFieldDataTypes.short]: MfModelFieldDataTypeValueFormatterTypeShortConfig
  [MfModelFieldDataTypes.string]: MfModelFieldDataTypeValueFormatterTypeStringConfig;
  [MfModelFieldDataTypes.uint]: MfModelFieldDataTypeValueFormatterTypeUintConfig
  [MfModelFieldDataTypes.ulong]: MfModelFieldDataTypeValueFormatterTypeUlongConfig
  [MfModelFieldDataTypes.ushort]: MfModelFieldDataTypeValueFormatterTypeUshortConfig
}

export type MfModelFieldDataTypeValueFormatterTypeUshortConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeUlongConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeUintConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeShortConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeSbyteConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeLongConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeFloatConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeDoubleConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeByteConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeArrayConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeBooleanConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeDateConfig = {
  format?: string
}

export type MfModelFieldDataTypeValueFormatterTypeDateTimeConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeDecimalConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeIntConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeObjectConfig = {

}

export type MfModelFieldDataTypeValueFormatterTypeStringConfig = {

}

/**
 * @type MfModelFieldDataTypePercentageConfig
 * @description Type definition for the percentage configuration of a model field's data type.
 */
export type MfModelFieldDataTypePercentageConfig = {}

/**
 * @type MfModelFieldDataTypeCurrencyConfig
 * @description Type definition for the currency configuration of a model field's data type.
 */
export type MfModelFieldDataTypeCurrencyConfig = {}

/**
 * @type MfModelFieldDataTypeUserPersonsConfig
 * @description Type definition for the user persona configuration of a model field's data type.
 */
export type MfModelFieldDataTypeUserPersonsConfig = {}

/**
 * @type MfModelFieldConfigMapped
 * @template TFieldPath
 * @description Type definition for a mapped model field configuration, extending the basic configuration with a required field path and field key.
 */
export type MfModelFieldConfigMapped<TFieldPath = MFModelConfigFieldPath> = MfModelFieldConfig<TFieldPath> & {
  fieldPath: TFieldPath;
  fieldKey: string;
  model?: MfModelConfigMapped<TFieldPath>;
}

/**
 * @type MfModelFieldOptions
 * @description Type definition for the options configuration of a model field, such as whether it should always be retrieved.
 */
export type MfModelFieldOptions = {
  alwaysRetrieve?: boolean;
}

/**
 * @type MfModelFieldCalculated
 * @description Type definition for the calculated field configuration, including a function pointer to retrieve the calculated value.
 */
export type MfModelFieldCalculated = {
  getValueFunctionPointer: MfFunctionPointer<number | string | Date | boolean, MfModelBase>;
}
