import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Inject,
  Injector,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfDialogOkCancelData } from "@material-framework/dialog/dialog";
import { MfDialogHostBaseComponent } from "@material-framework/dialog/dialog.host.base.component";

const TYPE_INFO: MfTypeInfo = { className: "MfDialogOkCancelComponent" };

@Component({
  selector: "mf-dialog-ok-cancel",
  templateUrl: "dialog.ok.cancel.component.html",
  styleUrls: ["dialog.ok.cancel.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfDialogOkCancelComponent<TComponent extends MfDialogHostBaseComponent> extends MfBaseComponent implements OnDestroy {
  @Output()
  public createdContentComponent: EventEmitter<void> = new EventEmitter();

  @ViewChild("contentHost", { read: ViewContainerRef })
  protected get _contentHost(): ViewContainerRef | undefined {
    return this._contentHostRef;
  }
  protected set _contentHost(value: ViewContainerRef | undefined) {
    if (this._contentHostRef !== value) {
      this._contentHostRef = value;
      if (!mfTypeIsUndefined(this._contentHostRef)) {
        this._onCreateComponents();
      }
    }
  }

  public contentComponentRef?: ComponentRef<TComponent>;

  protected _contentHostRef?: ViewContainerRef;

  public constructor(
    protected override _injector: Injector,
    protected _changeDetectorRef: ChangeDetectorRef,
    protected _dialogRef: MatDialogRef<MfDialogOkCancelComponent<TComponent>>,
    @Inject(MAT_DIALOG_DATA)
    public data: MfDialogOkCancelData<TComponent>,
  ) {
    super(TYPE_INFO, _injector);
  }

  public override ngOnDestroy(): void {
    if (!this._isUndefined(this.contentComponentRef)) {
      this.contentComponentRef.destroy();
    }
  }

  protected get _isValid(): boolean {
    if (!mfTypeIsUndefined(this.data.isValid)) {
      return this.data.isValid;
    }
    if (!mfTypeIsUndefined(this.contentComponentRef)) {
      return this.contentComponentRef.instance.isValid;
    }
    return false;
  }

  protected _onOkClicked(): void {
    this._dialogRef.close({ ok: true });
  }

  protected _onCancelClicked(): void {
    this._dialogRef.close({ ok: false });
  }

  protected _onCreateComponents(): void {
    if (!this._isUndefined(this.data.contentComponent) && !this._isUndefined(this._contentHost)) {
      this.contentComponentRef = this._contentHost.createComponent(this.data.contentComponent) as ComponentRef<TComponent>;
      this.createdContentComponent.emit();
      this._changeDetectorRef.detectChanges();
    }
  }
}