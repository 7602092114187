import { NgModule } from "@angular/core";
import { EComCarrierService } from "@e-commerce/services/carrier.service";
import { EComCartService } from "@e-commerce/services/cart.service";
import { EComCheckoutService } from "@e-commerce/services/checkout.service";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { EComInvoiceService } from "@e-commerce/services/invoice.service";
import { EComManufacturerService } from "@e-commerce/services/manufacturer.service";
import { EComOrderService } from "@e-commerce/services/order.service";
import { EComProductService } from "@e-commerce/services/product.service";
import { EComUserService } from "@e-commerce/services/user.service";

@NgModule({
  imports: [
  ],
  providers: [
    EComCarrierService,
    EComCartService,
    EComCheckoutService,
    EComCustomerService,
    EComInvoiceService,
    EComManufacturerService,
    EComOrderService,
    EComProductService,
    EComUserService,
  ]
})
export class EComServiceModule { }