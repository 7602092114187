import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, Component, Inject, Injector, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ecomCartGetDefaultRequiredDateEarliest, ecomCartGetDefaultRequiredDateMidday } from "@e-commerce/cart/cart";
import { EComRequiredDateDialogComponent } from "@e-commerce/cart/order/dialog/required.date.dialog.component";
import { EComCartModes } from "@e-commerce/common/cart.modes";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComCartLineModel, EComCartLineRequest } from "@e-commerce/modelConfigs/cart.line.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComCartItemActionResult } from "@e-commerce/services/cart.actions";
import { EComCartService } from "@e-commerce/services/cart.service";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPath } from "@material-framework/common/utils/object.utils";
import { mfStringIsEmptyOrWhitespace } from "@material-framework/common/utils/string.utils";
import { mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { ERROR_MESSAGE_REQUIRED } from "@material-framework/common/validation/validation.const";
import { MfDialogService } from "@material-framework/dialog/dialog.service";
import { MfModelConfigMapped, MfModelFieldExtendedConfig } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfPortalsTableComponent } from "@material-framework/portals/table/portals.table.utils";
import { MfTableRowCellEditorValueChangeEvent } from "@material-framework/table/row/cell/value/editor/table.row.cell.editor";
import { MfTableModelBase } from "@material-framework/table/table.component";
import { mfTableReIndexModelFieldExtendedConfig } from "@material-framework/table/table.model.config";
import { concatMap, map, Observable } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComCartOrderComponent" };

const ECOM_CART_LOCATION_KEY = "cart.order";
const ECOM_CART_UPDATED_COLOR = "red";


const ECOM_CART_ITEM_EXTENDED_MODEL_CONFIG: MfModelFieldExtendedConfig[] = [
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComCartLineModel>("itemCode")
  },
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComCartLineModel>("site")
  },
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComCartLineModel>("revision")
  },
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComCartLineModel>("unitOfMeasure")
  },
  {
    table: {
      index: 0,
      visible: false,
      cell: { style: { color: ECOM_CART_UPDATED_COLOR } },
      header: { style: { color: ECOM_CART_UPDATED_COLOR } },
      showHideOrder: { visible: false }
    },
    fieldPath: mfObjectGetPropertyPath<EComCartLineModel>("unitOfMeasureUpdated")
  },
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComCartLineModel>("price")
  },
  {
    table: {
      index: 0,
      visible: false,
      cell: { style: { color: ECOM_CART_UPDATED_COLOR } },
      header: { style: { color: ECOM_CART_UPDATED_COLOR } },
      showHideOrder: { visible: false }
    },
    fieldPath: mfObjectGetPropertyPath<EComCartLineModel>("priceUpdated")
  },
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComCartLineModel>("currency")
  },
  {
    table: {
      index: 0,
      visible: false,
      cell: { style: { color: ECOM_CART_UPDATED_COLOR } },
      header: { style: { color: ECOM_CART_UPDATED_COLOR } },
      showHideOrder: { visible: false }
    },
    fieldPath: mfObjectGetPropertyPath<EComCartLineModel>("currencyUpdated")
  },
  {
    table: {
      index: 0,
      cell: {
        formatter: {
          type: "numberEditor",
          options: {
            width: 120,
            clearValue: 1,
            minValue: 1,
            validators: [
              {
                validationFunction: { type: "required" },
                validationMessage: { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED }
              }
            ]
          },
        }
      }
    },
    fieldPath: mfObjectGetPropertyPath<EComCartLineModel>("quantity")
  },
  {
    table: {
      index: 0,
      visible: false,
      cell: { style: { color: ECOM_CART_UPDATED_COLOR } },
      header: { style: { color: ECOM_CART_UPDATED_COLOR } },
      showHideOrder: { visible: false }
    },
    fieldPath: mfObjectGetPropertyPath<EComCartLineModel>("availableQuantityUpdated")
  },
  {
    table: {
      index: 0,
      cell: {
        formatter: {
          type: "dateEditor",
          options: {
            width: 165,
            hideTime: true,
            min: ecomCartGetDefaultRequiredDateEarliest(),
            validators: [
              {
                validationFunction: { type: "required" },
                validationMessage: { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED }
              }
            ]
          },
        }
      }
    },
    fieldPath: mfObjectGetPropertyPath<EComCartLineModel>("requiredDate")
  },
  {
    table: {
      index: 0,
      visible: false,
      cell: { style: { color: ECOM_CART_UPDATED_COLOR } },
      header: { style: { color: ECOM_CART_UPDATED_COLOR } },
      showHideOrder: { visible: false }
    },
    fieldPath: mfObjectGetPropertyPath<EComCartLineModel>("requiredDateUpdated")
  },
  {
    table: { index: 0, },
    fieldPath: mfObjectGetPropertyPath<EComCartLineModel>("value")
  },
];

type EComCartLineViewModel = EComCartLineModel & MfTableModelBase & {
  isSelected?: boolean;
};

@Component({
  selector: "ecom-cart-order",
  templateUrl: "./cart.order.component.html",
  styleUrls: ["./cart.order.component.scss"]
})
export class EComCartOrderComponent extends MfBaseComponent implements OnInit, AfterViewInit {
  @ViewChild("cartLinesTable", { static: true })
  protected get _cartLinesTable(): MfPortalsTableComponent<EComCartLineViewModel, string, number> | undefined {
    return this._cartLinesTableInit;
  }
  protected set _cartLinesTable(value: MfPortalsTableComponent<EComCartLineViewModel, string, number> | undefined) {
    this._cartLinesTableInit = value;
    this._initializeTable();
  }

  protected _cartLineModelConfig: MfModelConfigMapped;
  protected _cartLineRequestModelConfig: MfModelConfigMapped;
  protected _cartLinesTableInit?: MfPortalsTableComponent<EComCartLineViewModel, string, number>;
  protected _locationKey = ECOM_CART_LOCATION_KEY;
  protected _hasSelected = false;
  protected _isLoadingInit = false;
  protected _hasValidationErrors: boolean = false;
  protected _validationError?: EComCartItemActionResult;
  protected _validationErrors?: string[];

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected _modelConfigService: MfModelConfigService,
    protected _cartService: EComCartService,
    protected _customerService: EComCustomerService,
    protected _dialogService: MfDialogService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._cartService.setMode(EComCartModes.order);
    this._cartLineModelConfig = this._modelConfigService.get<EComModelsConfig>("cartLine");
    this._cartLineRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("cartLineRequest");
    this._setModelConfigs();
    this._getQueryParams();
    this._sub(this._customerService.onSelectedChange, { next: () => this._tableLoadData() });
  }

  public ngAfterViewInit(): void {
    this._setValidationErrorsColumnsVisibleState(false, false, false, false, false);
  }

  public ngOnInit(): void {
    this._sub(this._customerService.hasSelection, {
      next: (hasSelection) => {
        if (hasSelection) {
          this._tableLoadData();
        }
      }
    });
  }

  protected get _cartItemsCount(): number {
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      return this._cartLinesTableInit.dataSource.data.length;
    }
    return 0;
  }

  protected _getQueryParams(): void {
    this._sub(this._activatedRoute.queryParams, {
      next: (queryParams) => {
        const autoValidation = coerceBooleanProperty(queryParams[EcomRouteNames.queryParamNameAutoValidation]);
        if (!mfTypeIsNullOrUndefined(autoValidation) && autoValidation === true) {
          const loading = this._subLoading(this._checkout(), {
            next: () => {
              loading.complete();
            },
            error: () => {
              loading.complete();
            },
          }, "Validating Cart");
        }
      }
    });
  }

  protected _cartActionCompleted(): Observable<boolean> {
    return this._checkout().pipe(
      map((result) => {
        if (result === true) {
          this._isLoadingInit = false;
          this._tableLoadData();
          return true;
        } else {
          this._clearValidationError();
          this._tableLoadData();
          this._isLoadingInit = false;
          return false;
        }
      }),
    );
  }

  protected _deleteSelectedItems(): void {
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      this._isLoadingInit = true;
      const selectedItems = this._cartLinesTableInit.dataSource.data.filter(i => i.isSelected === true);

      const osb$ = this._cartService.remove(selectedItems.map(i => ({ cartItemKey: i.cartItemKey }))).pipe(
        concatMap(() => this._cartActionCompleted()),
      );

      const loading = this._subLoading(osb$, {
        next: () => {
          loading.complete();
        },
        error: () => {
          loading.complete();
        }
      }, "Removing Selected Items From Cart");
    }
  }

  protected _updateRequiredDateSelectedItems(): void {
    this._isLoadingInit = true;
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      const selectedItems = this._cartLinesTableInit.dataSource.data.filter(i => i.isSelected === true);
      this._sub(this._dialogService.openOkCancel({
        disableClose: true,
        data: {
          contentComponent: EComRequiredDateDialogComponent,
          title: "Required Date",
          inputs: {
            requiredDate: ecomCartGetDefaultRequiredDateMidday(),
          },
          outputs: ["requiredDate"]
        },
      }).afterClosed(), {
        next: (result) => {
          if (result.ok === true && !mfTypeIsUndefined(result.outputs)) {
            const requiredDate = result.outputs["requiredDate"];

            const osb$ = this._cartService.update(selectedItems.map(i => ({ cartItemKey: i.cartItemKey, requiredDate: requiredDate }))).pipe(
              concatMap(() => this._cartActionCompleted()),
            );

            const loading = this._subLoading(osb$,
              {
                next: () => {
                  loading.complete();
                },
                error: () => {
                  loading.complete();
                },
              },
              "Updating Required Date For Selected Items In Cart"
            );
          }
        }
      });
    }
  }

  protected _rowEditorValueChanged(event: MfTableRowCellEditorValueChangeEvent<EComCartLineViewModel>): void {
    this._isLoadingInit = true;
    const isDateUpdate = event.fieldColumn.fieldKey === "requiredDate" ? true : false;

    const obs$ = this._cartService.update(
      {
        cartItemKey: event.rowItem.cartItemKey,
        quantity: isDateUpdate ? event.rowItem.quantity : event.originalValue as number,
        newQuantity: isDateUpdate ? undefined : event.rowItem.quantity,
        requiredDate: event.rowItem.requiredDate,
      }
    ).pipe(concatMap(() => this._cartActionCompleted()));

    const loading = this._subLoading(obs$,
      {
        next: () => {
          loading.complete();
        },
        error: () => {
          loading.complete();
        },
      },
      "Updating Item In Cart"
    );
  }

  protected _removeFromCart(cartLine: EComCartLineViewModel): void {
    this._isLoadingInit = true;

    const obs$ = this._cartService.remove({ cartItemKey: cartLine.cartItemKey }).pipe(
      concatMap(() => this._cartActionCompleted()),
    );

    const loading = this._subLoading(
      obs$,
      {
        next: () => {
          loading.complete();
        },
        error: () => {
          loading.complete();
        },
      },
      "Removing Item From Cart"
    );
  }

  protected _gotoProductDetail(cartLine: EComCartLineViewModel): void {
    this._router.navigate([EcomRouteNames.getProductDetailRouteItemSpecKey(cartLine.itemKey, cartLine.cartItemKey)]);
  }

  protected _toggleAllSelected(state: boolean): void {
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      this._cartLinesTableInit.dataSource.data.forEach(i => i.isSelected = state);
      this._hasSelected = this._cartLinesTableInit.dataSource.data.some(i => i.isSelected === true);
    }
  }

  protected _toggleItemChangeSelected(cartLine: EComCartLineViewModel): void {
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      cartLine.isSelected = mfTypeIsUndefined(cartLine.isSelected) ? true : !cartLine.isSelected;
      this._hasSelected = this._cartLinesTableInit.dataSource.data.some(i => i.isSelected === true);
    }
  }

  protected _continueShopping(): void {
    this._router.navigate([EcomRouteNames.routeProducts]);
  }

  protected _checkoutOrProceed(): void {
    if (this._hasValidationErrors === false) {
      const loading = this._subLoading(this._checkout(), {
        next: (result) => {
          if (result === false) {
            this._router.navigate([EcomRouteNames.routeShopCheckoutShippingMethod]);
          }
          loading.complete();
        },
        error: () => {
          loading.complete();
        }
      }, "Validating Cart");
    } else {
      this._router.navigate([EcomRouteNames.routeShopCheckoutShippingMethod]);
    }
  }

  protected _checkout(): Observable<boolean> {
    return this._cartService.checkout().pipe(
      map((checkoutResponse) => {
        if (this._cartService.hasValidationErrors(checkoutResponse) === true) {
          this._loadValidationError(checkoutResponse);
          return true;
        } else {
          this._clearValidationError();
          return false;
        }
      }),
    );
  }

  protected _loadValidationError(validationError: EComCartItemActionResult): void {
    this._hasValidationErrors = true;
    this._validationErrors = validationError.errors;
    this._validationError = validationError;
    this._loadValidationErrorsSetModelStates(validationError);
    this._loadValidationErrorsSetColumnStates(validationError);
  }

  protected _clearValidationError(): void {
    if (!mfTypeIsUndefined(this._validationError)) {
      this._hasValidationErrors = false;
      this._validationErrors = [];
      this._validationError.items = [];
      this._validationError.errors = [];
      this._loadValidationErrorsSetModelStates(this._validationError);
      this._loadValidationErrorsSetColumnStates(this._validationError);
    }
  }

  protected _loadValidationErrorsSetModelStates(validationError: EComCartItemActionResult): void {
    if (!mfTypeIsUndefined(this._cartLinesTable)) {
      if (!mfTypeIsUndefined(validationError) && !mfTypeIsUndefined(validationError.items)) {
        this._cartLinesTable.dataSource.data.forEach((model) => {
          const validationItem = validationError!.items!.find(validationItem => validationItem.cartItemKey === model.cartItemKey);
          if (!mfTypeIsUndefined(validationItem)) {
            model.unitOfMeasureUpdated = validationItem.latestItemUom;
            model.priceUpdated = validationItem.latestPrice;
            model.currencyUpdated = validationItem.latestPriceCurrency;
            model.availableQuantityUpdated = validationItem.latestQuantityAvailable;
            model.requiredDateUpdated = validationItem.isRequiredDateValid === false ? model.requiredDate : undefined;
            model.error = true;
          } else {
            this._clearModelValidationUpdatedFields(model);
          }
        });
      } else {
        if (!mfTypeIsUndefined(this._cartLinesTable)) {
          this._cartLinesTable.dataSource.data.forEach((model) => this._clearModelValidationUpdatedFields(model));
        }
      }
    }
  }

  protected _clearModelValidationUpdatedFields(model: EComCartLineViewModel): void {
    delete model.unitOfMeasureUpdated;
    delete model.priceUpdated;
    delete model.currencyUpdated;
    delete model.availableQuantityUpdated;
    delete model.requiredDateUpdated;
    model.error = false;
  }

  protected _loadValidationErrorsSetColumnStates(validationError: EComCartItemActionResult): void {
    if (!mfTypeIsUndefined(this._cartLinesTable) && !mfTypeIsUndefined(validationError) && !mfTypeIsUndefined(validationError.items)) {
      let unitOfMeasureUpdatedSome = false;
      let priceUpdatedSome = false;
      let currencyUpdatedSome = false;
      let availableQuantitySome = false;
      let requiredDateUpdatedSome = false;

      const length = validationError!.items.length;
      for (let index = 0; index < length; index++) {
        const validationItem = validationError!.items[index];

        if (!mfTypeIsNullOrUndefined(validationItem.latestItemUom) && !mfStringIsEmptyOrWhitespace(validationItem.latestItemUom)) {
          unitOfMeasureUpdatedSome = true;
        }
        if (!mfTypeIsNullOrUndefined(validationItem.latestPrice)) {
          priceUpdatedSome = true;
        }
        if (!mfTypeIsNullOrUndefined(validationItem.latestPriceCurrency) && !mfStringIsEmptyOrWhitespace(validationItem.latestPriceCurrency)) {
          currencyUpdatedSome = true;
        }
        if (!mfTypeIsNullOrUndefined(validationItem.latestQuantityAvailable)) {
          availableQuantitySome = true;
        }
        if (!mfTypeIsNullOrUndefined(validationItem.isRequiredDateValid) && validationItem.isRequiredDateValid === false) {
          requiredDateUpdatedSome = true;
        }
        if (unitOfMeasureUpdatedSome === true && priceUpdatedSome === true && currencyUpdatedSome === true && availableQuantitySome === true && requiredDateUpdatedSome === true) {
          break;
        }
      }

      this._setValidationErrorsColumnsVisibleState(unitOfMeasureUpdatedSome, priceUpdatedSome, currencyUpdatedSome, availableQuantitySome, requiredDateUpdatedSome);
    }
  }

  protected _setValidationErrorsColumnsVisibleState(
    unitOfMeasureUpdatedVisible: boolean,
    priceUpdatedVisible: boolean,
    currencyUpdatedVisible: boolean,
    availableQuantityUpdatedVisible: boolean,
    requiredDateUpdatedVisible: boolean
  ): void {
    if (!mfTypeIsUndefined(this._cartLinesTable)) {
      const unitOfMeasureUpdated = this._cartLinesTable.findFieldColumnByFieldPath(mfObjectGetPropertyPath<EComCartLineModel>("unitOfMeasureUpdated"));
      if (!mfTypeIsUndefined(unitOfMeasureUpdated)) {
        unitOfMeasureUpdated.visible = unitOfMeasureUpdatedVisible;
        this._cartLinesTable.onFieldColumnVisibleChanged(unitOfMeasureUpdated);
      }

      const priceUpdated = this._cartLinesTable.findFieldColumnByFieldPath(mfObjectGetPropertyPath<EComCartLineModel>("priceUpdated"));
      if (!mfTypeIsUndefined(priceUpdated)) {
        priceUpdated.visible = priceUpdatedVisible;
        this._cartLinesTable.onFieldColumnVisibleChanged(priceUpdated);
      }

      const currencyUpdated = this._cartLinesTable.findFieldColumnByFieldPath(mfObjectGetPropertyPath<EComCartLineModel>("currencyUpdated"));
      if (!mfTypeIsUndefined(currencyUpdated)) {
        currencyUpdated.visible = currencyUpdatedVisible;
        this._cartLinesTable.onFieldColumnVisibleChanged(currencyUpdated);
      }

      const availableQuantityUpdated = this._cartLinesTable.findFieldColumnByFieldPath(mfObjectGetPropertyPath<EComCartLineModel>("availableQuantityUpdated"));
      if (!mfTypeIsUndefined(availableQuantityUpdated)) {
        availableQuantityUpdated.visible = availableQuantityUpdatedVisible;
        this._cartLinesTable.onFieldColumnVisibleChanged(availableQuantityUpdated);
      }

      const requiredDateUpdated = this._cartLinesTable.findFieldColumnByFieldPath(mfObjectGetPropertyPath<EComCartLineModel>("requiredDateUpdated"));
      if (!mfTypeIsUndefined(requiredDateUpdated)) {
        requiredDateUpdated.visible = requiredDateUpdatedVisible;
        this._cartLinesTable.onFieldColumnVisibleChanged(requiredDateUpdated);
      }
    }
  }

  protected _setModelConfigs(): void {
    mfTableReIndexModelFieldExtendedConfig(ECOM_CART_ITEM_EXTENDED_MODEL_CONFIG);
    this._modelConfigService.setExtendedConfigs(this._cartLineModelConfig, ECOM_CART_ITEM_EXTENDED_MODEL_CONFIG);
  }

  protected get _isLoading(): boolean {
    return this._cartLinesTableInit?.isLoading === true || this._isLoadingInit === true;
  }

  protected _tableLoadData(): void {
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      this._hasSelected = false;
      this._cartLinesTableInit.blockDataLoad = false;
      this._cartLinesTableInit.loadData();
      this._cdRef.detectChanges();
    }
  }

  protected _onDataLoaded(): void {
    if (!mfTypeIsUndefined(this._validationError)) {
      this._loadValidationErrorsSetModelStates(this._validationError);
    }
  }

  protected _initializeTable(): void {
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      this._cartLinesTableInit.blockDataLoad = true;
      this._cartLinesTableInit.dataSource.url = `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}`;

      this._cartLinesTableInit.dataSource.buildPostData = this._updatePostDate;
    }
  }

  protected _updatePostDate = (data: EComCartLineRequest): Observable<EComCartLineRequest> => {
    return this._customerService.selected.pipe(
      concatMap((selected) => {
        return this._cartService.id.pipe(
          map((cartKey) => {
            data.cartKey = cartKey;
            data.key = selected.key;
            return data;
          })
        );
      })
    );
  };
}