import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MfDialogModule } from "@material-framework/dialog/dialog.module";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MfInputModule } from "@material-framework/input/input.module";
import { MfSelectModule } from "@material-framework/select/select.module";
import { MfStorageModule } from "@material-framework/storage/storage.module";
import { MfViewManagerLocalStorageService, MfViewManagerSessionStorageService } from "@material-framework/viewManager/view.manager.base.storage.service";
import { MfViewManagerComponent } from "@material-framework/viewManager/view.manager.component";
import { MF_VIEW_MANAGER_CONFIG_TOKEN, MF_VIEW_MANAGER_DEFAULT_CONFIG } from "@material-framework/viewManager/view.manager.config";
import { MfViewManagerEditViewComponent } from "@material-framework/viewManager/view.manager.edit.view.component";
import { MfViewManagerInMemoryService } from "@material-framework/viewManager/view.manager.inmemory.service";

@NgModule({
  declarations: [
    MfViewManagerComponent,
    MfViewManagerEditViewComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MfDialogModule,
    MfIconModule,
    MfInputModule,
    MfStorageModule,
    MfSelectModule,
    ReactiveFormsModule,
  ],
  exports: [
    MfViewManagerComponent,
  ],
  providers: [
    { provide: MF_VIEW_MANAGER_CONFIG_TOKEN, useValue: MF_VIEW_MANAGER_DEFAULT_CONFIG },
    MfViewManagerInMemoryService,
    MfViewManagerLocalStorageService,
    MfViewManagerSessionStorageService,
  ]
})
export class MfViewManagerModule {
}