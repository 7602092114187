import * as moment from "moment";

export function mfDateCurrent(): Date {
  return new Date();
}

export function mfDateToMidday(value: Date | moment.Moment): Date {
  return moment(value).set("hours", 12).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).toDate();
}

export function mfDateToEarliest(value: Date | moment.Moment): Date {
  return moment(value).startOf("day").toDate();
}

export function mfDateToLatest(value: Date | moment.Moment): Date {
  return moment(value).endOf("day").toDate();
}

export function mfDateSetTimeToZeros(value: Date | moment.Moment): Date {
  return moment(value).set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).toDate();
}

export function mfDateIsSameDate(a: Date | moment.Moment, b: Date | moment.Moment): boolean {
  return moment(a).isSame(moment(b), "date");
}

export function mfDateIsSameMonthYear(a: Date | moment.Moment, b: Date | moment.Moment): boolean {
  const mA = moment(a);
  const mB = moment(b);
  return mA.year() === mB.year() && mA.month() === mB.month();
}

export function mfDateIsBetweenDates(value: Date | moment.Moment, from: Date | moment.Moment, to: Date | moment.Moment, inclusivity: "()" | "[)" | "(]" | "[]" = "[]"): boolean {
  return moment(value).isBetween(moment(from), moment(to), "date", inclusivity);
}

export function mfDateIsBetweenMonthYear(value: Date | moment.Moment, from: Date | moment.Moment, to: Date | moment.Moment, inclusivity: "()" | "[)" | "(]" | "[]" = "[]"): boolean {
  return moment(value).isBetween(moment(from), moment(to), "month", inclusivity);
}