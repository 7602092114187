import { Component, Inject, Injector, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComUserModel } from "@e-commerce/modelConfigs/user.model.config";
import { EComRootService } from "@e-commerce/root/authn/root.authn.service";
import { EComUserService } from "@e-commerce/services/user.service";
import { MfAuthService } from "@material-framework/auth/auth.service";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfStringIsEmptyOrWhitespace } from "@material-framework/common/utils/string.utils";
import { mfTypeHasOwnProperty, mfTypeIsNullOrUndefined } from "@material-framework/common/utils/type.utils";
import { MfMenuItem } from "@material-framework/menu/menu.item";
import { MfModelConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfUserPersonaCardAction } from "@material-framework/userPersona/user.persona";


const TYPE_INFO: MfTypeInfo = { className: "EComRootAuthnComponent" };

@Component({
  selector: "ecom-root-authn",
  templateUrl: "./root.authn.component.html",
  styleUrls: ["./root.authn.component.scss"]
})
export class EComRootAuthnComponent extends MfBaseComponent implements OnInit {
  protected _userModelConfig: MfModelConfigMapped;
  protected _showFooter = true;
  protected _menuItems: MfMenuItem[] = [];
  protected _user?: EComUserModel;
  protected _userPersonaActions: MfUserPersonaCardAction[];

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _modelConfigService: MfModelConfigService,
    protected _rootService: EComRootService,
    protected _authService: MfAuthService,
    protected _userService: EComUserService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._sub(this._userService.isApproved, {
      next: (isApproved) => {
        if (isApproved === false) {
          this._router.navigate([EcomRouteNames.routeNotApproved]);
        }
      }
    });
    this._menuItems = [
      {
        label: "Shop",
        tooltip: "Purchase from our extensive range of products",
        icon: _iconsConfig.shopIcon,
        routerLink: { link: EcomRouteNames.routeProductsList, }
      },
      // {
      //   label: "Upload Order",
      //   tooltip: "Bulk order your requirements to quickly add them to your cart",
      //   icon: _iconsConfig.uploadOrderIcon,
      //   routerLink: { link: EcomRouteNames.routeProductsList },
      // },
      {
        label: "Order History",
        tooltip: "View your order details",
        icon: _iconsConfig.orderHistoryIcon,
        routerLink: { link: EcomRouteNames.routeOrders },
      },
      {
        label: "Invoices",
        tooltip: "View and pay your outstanding invoices",
        icon: _iconsConfig.invoicesIcon,
        routerLink: { link: EcomRouteNames.routeInvoicesList },
      },
    ];
    this._userPersonaActions = [
      // {
      //   id: "",
      //   type: "action",
      //   title: "Manage Account",
      //   icon: _iconsConfig.userIcon
      // },
      // {
      //   id: "personalSettings",
      //   type: "action",
      //   title: "Personal settings"
      // },
      // {
      //   id: "notifications",
      //   type: "action",
      //   title: "Notifications"
      // },
      // {
      //   id: "divider",
      //   type: "divider"
      // },
      {
        id: "logout",
        type: "action",
        title: "Log out"
      }
    ];
    this._userModelConfig = this._modelConfigService.get<EComModelsConfig>("user");
    this._sub(this._rootService.showFooterChanged, { next: (showFooter) => { this._showFooter = showFooter; } });
    this._sub(this._authService.onTokenReceived, { next: () => this._getUser() });
  }

  public ngOnInit(): void {
    this._getUser();
  }

  protected _onPersonaActionClicked(event: MfUserPersonaCardAction): void {
    switch (event.id) {
      case "logout":
        this._authService.logout();
        break;
    }
  }

  protected _goHome(): void {
    this._router.navigate([EcomRouteNames.routeHome]);
  }

  protected _getUser(): void {
    const userClaims = this._authService.getIdentityClaims();
    if (!mfTypeIsNullOrUndefined(userClaims) &&
      (
        mfTypeHasOwnProperty(userClaims, "given_name") &&
        mfTypeHasOwnProperty(userClaims, "family_name") &&
        mfTypeHasOwnProperty(userClaims, "http://schemas.microsoft.com/identity/claims/email")
      )
    ) {
      const givenName = userClaims.given_name as string;
      const surname = userClaims.family_name as string;
      const mail = userClaims["http://schemas.microsoft.com/identity/claims/email"] as string;

      this._user = { givenName, surname, mail, };
      this._user.displayName = "";

      if (!mfStringIsEmptyOrWhitespace(givenName)) {
        this._user.displayName += givenName;
      }

      if (!mfStringIsEmptyOrWhitespace(surname)) {
        this._user.displayName += " " + surname;
      }

      if (!mfStringIsEmptyOrWhitespace(givenName) && !mfStringIsEmptyOrWhitespace(surname)) {
        this._user.initials = `${givenName.charAt(0)}${surname?.charAt(0)}`;
      }
    }
  }
}
