import { NgModule } from "@angular/core";
import { MatTabsModule } from "@angular/material/tabs";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { EComOrderBillingPaymentModule } from "@e-commerce/checkout/order/billingPayment/order.billing.payment.module";
import { EComCheckoutOrderComponent } from "@e-commerce/checkout/order/checkout.order.component";
import { EComOrderReviewModule } from "@e-commerce/checkout/order/orderReview/order.review.module";
import { EComOrderShippingMethodModule } from "@e-commerce/checkout/order/shippingMethod/order.shipping.method.module";
import { EComCheckoutSummaryModule } from "@e-commerce/checkout/summary/checkout.summary.module";


@NgModule({
  declarations: [
    EComCheckoutOrderComponent
  ],
  exports: [
    EComCheckoutOrderComponent
  ],
  imports: [
    BrowserModule,
    EComCheckoutSummaryModule,
    EComOrderBillingPaymentModule,
    EComOrderReviewModule,
    EComOrderShippingMethodModule,
    MatTabsModule,
    RouterModule,
  ],
  providers: [
  ]
})
export class EComCheckoutOrderModule { }