import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { EComPaypalReturnComponent } from "@e-commerce/landing/paypal/return/paypal.return.component";

@NgModule({
  declarations: [
    EComPaypalReturnComponent
  ],
  exports: [
    EComPaypalReturnComponent
  ],
  imports: [
    BrowserModule,
  ],
  providers: [
  ]
})
export class EComPaypalReturnModule { }