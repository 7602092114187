import { DecimalPipe } from "@angular/common";
import { Inject, InjectionToken, Pipe, PipeTransform } from "@angular/core";

export const MF_INT_PIPE_CONFIG_TOKEN = new InjectionToken<MfIntPipeConfig>("MfIntPipeConfig");
export const MF_INT_PIPE_DEFAULT_CONFIG: MfIntPipeConfig = {
  digitsInfo: "1.0-0",
};

export type MfIntPipeConfig = {
  digitsInfo: string;
  locale?: string,
}

@Pipe({ name: "mfInt" })
export class MfIntPipe implements PipeTransform {
  public constructor(
    protected _decimalPipe: DecimalPipe,
    @Inject(MF_INT_PIPE_CONFIG_TOKEN)
    protected _config: MfIntPipeConfig,
  ) { }

  public transform(value: string | number | null | undefined) {
    return this._decimalPipe.transform(value, this._config.digitsInfo, this._config.locale);
  }
}