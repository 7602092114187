import { MfSortTypes } from "@material-framework/common/sort/sort.types";
import { MfModelFieldConfig, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";

export type MFPortalsFileBlob = {
  blob: Blob;
  extension: string;
}


// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfPortalsSortingTypes = {
  asc: "asc",
  desc: "desc",
} as const;

export type MfPortalsSortingTypes = typeof MfPortalsSortingTypes[keyof typeof MfPortalsSortingTypes]

export function mfPortalsSortTypesToMfPortalsSortingTypes(mfSortTypes: MfSortTypes): MfPortalsSortingTypes {
  switch (mfSortTypes) {
    case MfSortTypes.asc:
      return MfPortalsSortingTypes.asc;
    case MfSortTypes.desc:
      return MfPortalsSortingTypes.desc;
  }
}

export type MfPortalsPaging = {
  nextAvailable?: boolean,
  prevAvailable?: boolean,
  page: number,
  pageSize: number,
}

export type MfPortalsRequestCollectionData = {
  filter?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  select?: string;
}

export type MfPortalsRequestCollectionDataFieldsConfig = {
  [key in keyof Required<MfPortalsRequestCollectionData>]: MfModelFieldConfig
};

export const MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG: MfPortalsRequestCollectionDataFieldsConfig = {
  filter: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  page: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  pageSize: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  sort: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  select: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  }
};

export type MfPortalsCollectionResponseData<TModel extends object, TModel1 = unknown, TModel2 = unknown, TModel3 = unknown> = MfPortalsCollectionResponseNamedData<TModel, TModel1, TModel2, TModel3> & {
  filter?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  results?: TModel[];
}

export type MfPortalsCollectionResponseNamedData<TModel, TModel1, TModel2, TModel3> = {
  [results: string]: (TModel | TModel1 | TModel2 | TModel3)[];
}
