import { Route } from "@angular/router";
import { MfBreadcrumbRouteConfig } from "@material-framework/breadcrumb/breadcrumb.config";
import { mfTypeIsArray } from "@material-framework/common/utils/type.utils";

export function mfRouteJoinRouteParts(parts: string[]): string { return parts.join("/"); }
export function mfRouteAddRouteParameter(route: string, parameterName: string | string[]): string {
  if (mfTypeIsArray(parameterName)) {
    return `${route}/:${parameterName.join("/:")}`;
  } else {
    return `${route}/:${parameterName}`;
  }
}

export type MfRoutes = MfRoute[];

export interface MfRoute extends Route {
  breadcrumb?: MfBreadcrumbRouteConfig;
  children?: MfRoute[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _loadedRoutes?: MfRoutes;
}