import { Directive } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { EComCheckoutBasModel } from "@e-commerce/checkout/base/checkout";
import { MfBaseValidationComponent } from "@material-framework/base/base.validation.component";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";

@Directive()
export abstract class EComCheckoutStepValidationBaseComponent<TCheckoutData extends EComCheckoutBasModel> extends MfBaseValidationComponent {
  public get checkoutData(): TCheckoutData | undefined {
    return this._checkoutData;
  }
  public set checkoutData(value: TCheckoutData | undefined) {
    this._checkoutData = value;
    if (!mfTypeIsUndefined(this._checkoutData)) {
      this._onCheckoutModelSet(this._checkoutData);
    }
  }

  protected _checkoutData?: TCheckoutData;
  protected readonly _isCheckoutValidationStep = true;
  protected readonly _isCheckoutStep = true;

  public abstract formGroup: FormGroup;
  protected abstract _onCheckoutModelSet(model: TCheckoutData): void;
}