import { Inject, InjectionToken, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";

export const MF_SAFE_HTML_PIPE_CONFIG_TOKEN = new InjectionToken<MfSafeHtmlPipeConfig>("MfSafeHtmlPipeConfig");
export const MF_SAFE_HTML_PIPE_DEFAULT_CONFIG: MfSafeHtmlPipeConfig = {};
export type MfSafeHtmlPipeConfig = {};

@Pipe({ name: "mfSafeHtml" })
export class MfSafeHtmlPipe implements PipeTransform {
  public constructor(
    protected _sanitizer: DomSanitizer,
    @Inject(MF_SAFE_HTML_PIPE_CONFIG_TOKEN)
    protected _config: MfSafeHtmlPipeConfig,
  ) { }

  public transform(value: string | undefined): SafeHtml {
    if (!mfTypeIsUndefined(value)) {
      return this._sanitizer.bypassSecurityTrustHtml(value);
    }
    return "";
  }
}