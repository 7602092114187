import { Component, Inject, Injector, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { EComCartModes } from "@e-commerce/common/cart.modes";
import { ECOM_ICONS_CONFIG_TOKEN, ECOM_TABLE_CONFIG, EComIconsConfig } from "@e-commerce/ecom.config";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComInvoiceModel, EComInvoiceRequest } from "@e-commerce/modelConfigs/invoice.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComCartService } from "@e-commerce/services/cart.service";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { EComInvoiceService } from "@e-commerce/services/invoice.service";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPath } from "@material-framework/common/utils/object.utils";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MF_ENUM_FILTER_CONFIG, MF_STRING_FILTER_CONFIG } from "@material-framework/filter/filter.model.config";
import { MfModelConfigMapped, MfModelFieldExtendedConfig } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfPortalsTableComponent } from "@material-framework/portals/table/portals.table.utils";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";
import { mfTableReIndexModelFieldExtendedConfig } from "@material-framework/table/table.model.config";
import { map, Observable } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComInvoicesComponent" };

const ECOM_INVOICES_DETAIL_TABLE_CONFIG: MfTableConfig = {
  ...ECOM_TABLE_CONFIG,
  row: {
    ...ECOM_TABLE_CONFIG.row,
    clickAction: {
      ...ECOM_TABLE_CONFIG.row.clickAction,
      tooltip: "View Invoice Detail",
    }
  }
};

const ECOM_INVOICES_LOCATION_KEY = "invoices";

const ECOM_INVOICE_EXTENDED_MODEL_CONFIG: MfModelFieldExtendedConfig[] = [
  {
    table: { index: 0, header: { sortable: false }, cell: { formatter: { type: "statusChip" } } },
    filter: { ...MF_ENUM_FILTER_CONFIG },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("status")
  },
  {
    table: { index: 0, header: { sortable: false } },
    filter: { ...MF_STRING_FILTER_CONFIG, input: { string: { maxLength: 8 } }, exclude: { operatorsTypes: { string: { contains: true, startsWith: true } } } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("documentNumber")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("invoiceDate")
  },
  {
    table: { index: 0, header: { sortable: false } },
    filter: { ...MF_STRING_FILTER_CONFIG },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("currency")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("currencyValue")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("currencyTaxValue")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("currencyTotalValue")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("paymentValue")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("paymentDueDate")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("paymentTerms")
  },
];

@Component({
  selector: "ecom-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.scss"],
  providers: [
    { provide: MF_TABLE_CONFIG_TOKEN, useValue: ECOM_INVOICES_DETAIL_TABLE_CONFIG },
  ]
})
export class EComInvoicesComponent extends MfBaseComponent implements OnInit {
  @ViewChild("invoicesTable", { static: true })
  protected get _invoicesTable(): MfPortalsTableComponent<EComInvoiceModel, string, number> | undefined {
    return this._invoicesTableInt;
  }
  protected set _invoicesTable(value: MfPortalsTableComponent<EComInvoiceModel, string, number> | undefined) {
    this._invoicesTableInt = value;
    this._initializeTable();
  }

  protected _invoiceModelConfig: MfModelConfigMapped;
  protected _invoiceRequestModelConfig: MfModelConfigMapped;
  protected _invoicesTableInt?: MfPortalsTableComponent<EComInvoiceModel, string, number>;
  protected _locationKey = ECOM_INVOICES_LOCATION_KEY;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _modelConfigService: MfModelConfigService,
    protected _customerService: EComCustomerService,
    protected _cartService: EComCartService,
    protected _invoiceService: EComInvoiceService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _config: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._invoiceModelConfig = this._modelConfigService.get<EComModelsConfig>("invoice");
    this._invoiceRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceRequest");
    this._cartService.setMode(EComCartModes.invoice);
    this._setModelConfigs();
    this._sub(this._customerService.onSelectedChange, { next: () => this._tableLoadData() });
  }

  public ngOnInit(): void {
    this._sub(this._customerService.hasSelection, {
      next: (hasSelection) => {
        if (hasSelection) {
          this._tableLoadData();
        }
      }
    });
  }

  protected _viewInvoice(invoice: EComInvoiceModel): void {
    const loading = this._subLoading(this._invoiceService.downloadDocument(invoice), {
      next: () => { loading.complete(); },
      error: () => { loading.complete(); },
    }, "Downloading Invoice");
  }

  protected _viewDetails(invoice: EComInvoiceModel): void {
    this._router.navigate([EcomRouteNames.getInvoiceDetailRoute(invoice.documentKey, invoice.documentNumber)]);
  }

  protected _addToPay(invoice: EComInvoiceModel): void {

  }

  protected get _isLoading(): boolean {
    return this._invoicesTable?.isLoading === true;
  }

  protected _tableLoadData(): void {
    if (!mfTypeIsUndefined(this._invoicesTable)) {
      this._invoicesTable.blockDataLoad = false;
      this._invoicesTable.loadData();
      this._cdRef.detectChanges();
    }
  }

  protected _setModelConfigs(): void {
    mfTableReIndexModelFieldExtendedConfig(ECOM_INVOICE_EXTENDED_MODEL_CONFIG);
    this._modelConfigService.setExtendedConfigs(this._invoiceModelConfig, ECOM_INVOICE_EXTENDED_MODEL_CONFIG);
  }

  protected _initializeTable(): void {
    if (!mfTypeIsUndefined(this._invoicesTable)) {
      this._invoicesTable.blockDataLoad = true;
      this._invoicesTable.dataSource.url = `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsInvoicesUrl}`;

      this._invoicesTable.dataSource.buildPostData = this._updatePostDate;
    }
  }

  protected _updatePostDate = (data: EComInvoiceRequest): Observable<EComInvoiceRequest> => {
    return this._customerService.selected.pipe(
      map((selected) => {
        data.key = selected.key;
        return data;
      })
    );
  };
}