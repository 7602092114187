<div class="ecom-page-container">
  <mf-table #invoiceDetailsTable
            viewManagerEnabled="true"
            (onRowClicked)="_viewOrderHistory($event)"
            [locationKey]="_locationKey"
            [modelConfig]="_invoiceDetailModelConfig"
            [postModelConfig]="_invoiceDetailRequestModelConfig">
    <ng-container *mfTableHeaderLeftDef>
      <div class="ecom-page-header">
        <div class="ecom-title">
          Invoice Detail - #{{_documentNumber}}
        </div>
      </div>
    </ng-container>
    <ng-container mfTableColumnSlideOutActionsDef
                  stickyEnd="true">
      <ng-container *mfTableCellDef="let item"
                    contentAlign="center">
        <div class="mf-column mf-default-margin">
          <button mat-stroked-button
                  class="mf-default-margin-bottom"
                  (click)="_viewInvoice(item)">
            Download Invoice
          </button>
          <!-- <button mat-stroked-button
                  class="mf-default-margin-bottom"
                  (click)="_viewCustomers(item)">
            Customers
          </button> -->
          <button mat-stroked-button
                  (click)="_viewOrderHistory(item)">
            Order History
          </button>
        </div>
      </ng-container>
    </ng-container>
  </mf-table>
</div>