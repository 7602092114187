import { mfDateToEarliest } from "@material-framework/common/utils/date.utils";
import * as moment from "moment";

export function ecomCartGetDefaultRequiredDateMidday(): Date {
  return moment().add(5, "days").toDate();
}

export function ecomCartGetDefaultRequiredDateEarliest(): Date {
  return mfDateToEarliest(ecomCartGetDefaultRequiredDateMidday());
}

export function ecomCartGetDefaultQty(): number {
  return 1;
}