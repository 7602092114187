import { AnimationEvent, animate, state, style, transition, trigger } from "@angular/animations";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { moveItemInArray } from "@angular/cdk/drag-drop";
import { AfterContentInit, AfterViewInit, Component, ContentChild, ContentChildren, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Optional, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation, booleanAttribute } from "@angular/core";
import { MatCheckbox } from "@angular/material/checkbox";
import { PageEvent } from "@angular/material/paginator";
import { MatTable } from "@angular/material/table";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfError, MfErrorNotImplemented } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfArrayCount, mfArrayFilterUndefinedByPath, mfArrayMaxByPath, mfArraySortByNumberPath } from "@material-framework/common/utils/array.utils";
import { MF_NUMBER_INT_MAX_VALUE } from "@material-framework/common/utils/number.utils";
import { mfObjectPropertyPathIsUndefined } from "@material-framework/common/utils/object.utils";
import { mfStringIsEmptyOrWhitespace } from "@material-framework/common/utils/string.utils";
import { mfTypeGetKeys, mfTypeIsNull, mfTypeIsUndefined, mfTypeIsUndefinedOrValue } from "@material-framework/common/utils/type.utils";
import { MfFilterGroup, MfFilterValue } from "@material-framework/filter/filter";
import { MfFilterOperatorTypes } from "@material-framework/filter/filter.types";
import { MF_FILTER_CONVERTER_SERVICE_TOKEN, MfFilterConverterService } from "@material-framework/filter/services/filter.loader";
import { MfFilterService } from "@material-framework/filter/services/filter.service";
import { MfModelBase } from "@material-framework/model/model.base";
import { MFModelConfigFieldKey, MFModelConfigFieldPath, MfModelConfigMapped, MfModelFieldConfigMapped, MfModelFieldDataTypes, MfModelFieldsConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfResizedEvent } from "@material-framework/resized/resized.directive";
import { MfSelectOption } from "@material-framework/select/select.option";
import { MfTableDataSource } from "@material-framework/table/dataSource/table.data.source";
import { MfTableFooterLeftDef } from "@material-framework/table/footer/table.footer.left.def.directive";
import { MfTableFooterRightDef } from "@material-framework/table/footer/table.footer.right.def.directive";
import { MfTableHeaderLeftDef } from "@material-framework/table/header/table.header.left.def.directive";
import { MfTableHeaderRightDef } from "@material-framework/table/header/table.header.right.def.directive";
import { MfTableQueryFilterParam } from "@material-framework/table/queryFilter/table.query.filter";
import { MfTableQueryFilterService } from "@material-framework/table/queryFilter/table.query.filter.service";
import { MfTableRowCellComponent } from "@material-framework/table/row/cell/table.row.cell.component";
import { MfTableRowCellSlideOutActionsComponent, MfTableRowCellSlideOutActionsOpenEvent } from "@material-framework/table/row/cell/table.row.cell.slide.out.actions.component";
import { MfTableRowCellEditorValueChangeEvent } from "@material-framework/table/row/cell/value/editor/table.row.cell.editor";
import { MfTableColumnSlideOutActionsDef } from "@material-framework/table/row/column/table.column.actions.def.directive";
import { MfTableColumnDef } from "@material-framework/table/row/column/table.column.def.directive";
import { MfTableColumnExpandDef } from "@material-framework/table/row/column/table.column.expand.def.directive";
import { MfTableColumnModelConfigDef } from "@material-framework/table/row/column/table.column.model.config.def.directive";
import { MfOnSortIndexChangingEvent } from "@material-framework/table/row/header/menu/table.row.header.menu.sort.component";
import { MfTableRowFieldHeaderCellComponent } from "@material-framework/table/row/header/table.row.field.header.cell.component";
import { MfTableSelectionManager } from "@material-framework/table/selectionManager/table.selection.manager";
import { MfTableFieldColumn, MfTableFilterExpressionEvent, MfTableFilterGroupEvent, MfTableGroupColumn, MfTableGroupColumnMovingEvent, MfTablePagination, mfTableColumnVisible } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";
import { MfTableRowHeaderGroupModelConfig, mfTableGetVisible, mfTableHeaderCanSort } from "@material-framework/table/table.model.config";
import { MfTableDataReloadTypes, MfTableFilterModeTypes, MfTableLoadingTypes } from "@material-framework/table/table.types";
import { MfTableColumnViewStateData, MfTableViewStateData } from "@material-framework/table/table.view.state.data";
import { MfViewState } from "@material-framework/viewManager/view.manager";
import { MfViewManagerStorageService } from "@material-framework/viewManager/view.manager.storage.service";
import { Observable, tap } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "MfTableComponent" };

export const MF_TABLE_LOCATION_KEY_PREFIX = "table" as const;

const FIELD_NAME_PREFIX = "field_";
const GROUP_NAME_PREFIX = "group_";
const INFO_NAME_PREFIX = "info_";
const EXPAND_NAME_PREFIX = "expand_";
const SELECTION_MANAGER_POSTFIX = "selection";
const SIDLE_OUT_ACTION_POSTFIX = "slideOutActions";
const EXPANDED_CONTENT_POSTFIX = "expandedContent";
const ROW_CLICK_ACTION_POSTFIX = "rowAction";

export type MfTableModelBase = MfModelBase & {
  [key: string]: unknown;
  tableComponentCanSelectItem?: boolean;
  selected?: boolean;
  error?: boolean;
}

/**
 * @class
 * @extends MfBaseComponent
 * @description
 * The `MfTableComponent` class is an Angular component for rendering and managing complex tables with features like pagination, filtering, sorting, and row selection. It supports expandable rows, custom display columns, and integrates with view state management and selection management services.
 * 
 * This component is designed to work with models extending `MfTableModelBase` and provides a comprehensive set of input properties, output events, and lifecycle hooks for flexible table management.
 * 
 * The component also supports Angular animations for row expansion and includes numerous methods for managing table behavior, such as loading data, setting filters, and handling selection.
 * 
 * @template TModel - The model type that extends `MfTableModelBase`.
 * @template TFilter - The filter type used in the table.
 * @template TTrackBy - The type used for tracking rows.
 *
 * Example of usage:
 * <example-url>/demo/mysample.component.html</example-url>
 */
@Component({
  selector: "mf-table",
  templateUrl: "table.component.html",
  styleUrls: ["table.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("detailExpand", [
      state("collapsed",
        style({ height: "0px", minHeight: "0" })
      ),
      state("expanded",
        style({ height: "*" })
      ),
      transition("expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class MfTableComponent<TModel extends MfTableModelBase, TFilter, TTrackBy = unknown> extends MfBaseComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {

  /**
  * Blocks the table from automatically loading data while set to true.
  * @default false
  * @type {boolean}
  * @memberof MfTableComponent
  */
  @Input({ transform: booleanAttribute })
  public get blockDataLoad(): boolean {
    return this._blockDataLoad;
  }
  public set blockDataLoad(value: BooleanInput | boolean) {
    this._blockDataLoad = coerceBooleanProperty(value);
  }

  /**
   * Sets or gets view state manager state.
   * Determines whether the view manager is enabled, allowing the table's view state to be saved and restored.
   * @default false
   * @type {boolean}
   * @memberof MfTableComponent
   */
  @Input({ transform: booleanAttribute })
  public get viewManagerEnabled(): boolean {
    return this._viewManagerEnabled;
  }
  public set viewManagerEnabled(value: BooleanInput) {
    this._viewManagerEnabled = coerceBooleanProperty(value);
  }

  /**
   * Sets or gets pagination state.
   * Controls whether pagination is enabled for the table.
   * @default false
   * @type {boolean}
   * @memberof MfTableComponent
   */
  @Input({ transform: booleanAttribute })
  public get paginationEnabled(): boolean {
    return this._paginationEnabled;
  }
  public set paginationEnabled(value: BooleanInput) {
    this._paginationEnabled = coerceBooleanProperty(value);
  }

  /**
   * Sets or gets selection manager state.
   * Controls whether the selection manager is enabled, allowing for row selection in the table.
   * @default false
   * @type {boolean}
   * @memberof MfTableComponent
   */
  @Input({ transform: booleanAttribute })
  public get selectionManagerEnabled(): boolean {
    if (!mfTypeIsUndefined(this._selectionManager)) {
      return this._selectionManager.enabled;
    }
    return false;
  }
  public set selectionManagerEnabled(value: BooleanInput) {
    if (!mfTypeIsUndefined(this._selectionManager)) {
      this._selectionManager.enabled = coerceBooleanProperty(value);
      this._selectionManagerSetModelConfig();
    }
  }

  /**
   * Function that determines if a selection manager can display an item as selectable.
   * If provided, this function will be used to decide whether an item can be selected.
   * @type {(item: TModel) => boolean}
   * @memberof MfTableComponent
   */
  @Input()
  public selectionManagerCanSelectItem?: (item: TModel) => boolean;

  /**
   * Sets or gets the selection column to sticky at the end of the table.
   * If true, the selection column will stick to the end of the table when scrolling horizontally.
   * @default false
   * @type {boolean}
   * @memberof MfTableComponent
   */
  @Input({ transform: booleanAttribute })
  public get selectionStickyEnd(): boolean {
    return this._selectionStickyEnd;
  }
  public set selectionStickyEnd(value: BooleanInput) {
    this._selectionStickyEnd = coerceBooleanProperty(value);
  }

  /**
   * Sets or gets the selection column to sticky at the start of the table.
   * If true, the selection column will stick to the start of the table when scrolling horizontally.
   * @default true
   * @type {boolean}
   * @memberof MfTableComponent
   */
  @Input({ transform: booleanAttribute })
  public get selectionSticky(): boolean {
    return this._selectionSticky;
  }
  public set selectionSticky(value: BooleanInput) {
    this._selectionSticky = coerceBooleanProperty(value);
  }

  /**
   * Sets or gets the location key used to identify the table in storage.
   * Used for saving and retrieving the table's state, such as selected items, column widths, etc.
   * @type {string | undefined}
   * @memberof MfTableComponent
   */
  @Input()
  public get locationKey(): string | undefined {
    return this._locationKey;
  }
  public set locationKey(value: string | undefined) {
    this._locationKey = `${MF_TABLE_LOCATION_KEY_PREFIX}.${value}`;
  }

  /**
   * Sets or gets the storage key for the selection manager.
   * This key is used to store the selection state in persistent storage.
   * @type {string | undefined}
   * @memberof MfTableComponent
   */
  @Input()
  public get selectionManagerStorageKey(): string | undefined {
    if (!mfTypeIsUndefined(this._selectionManager) && this._selectionManager.enabled === true) {
      return this._selectionManager.selectedServiceKey;
    }
    return;
  }
  public set selectionManagerStorageKey(value: string | undefined) {
    if (!mfTypeIsUndefined(this._selectionManager) && this._selectionManager.enabled === true) {
      this._selectionManager.selectedServiceKey = value;
    }
  }

  /**
   * Sets or gets the model configuration for the table.
   * The model configuration defines the structure and behavior of the data displayed in the table.
   * @type {MfModelConfigMapped | undefined}
   * @memberof MfTableComponent
   */
  @Input()
  public get modelConfig(): MfModelConfigMapped | undefined {
    return this._modelConfig;
  }
  public set modelConfig(value: MfModelConfigMapped | undefined) {
    this._modelConfig = value;
    this._selectionManagerSetModelConfig();
  }

  /**
   * Sets or gets the post model configuration for the table.
   * This configuration is applied after the primary model configuration.
   * @type {MfModelConfigMapped | undefined}
   * @memberof MfTableComponent
   */
  @Input()
  public get postModelConfig(): MfModelConfigMapped | undefined {
    return this._postModelConfig;
  }
  public set postModelConfig(value: MfModelConfigMapped | undefined) {
    this._postModelConfig = value;
  }

  /**
   * Sets or gets the row mouse over highlight state.
   * Controls whether rows will be highlighted when the mouse is over them.
   * @default true
   * @type {boolean}
   * @memberof MfTableComponent
   */
  @Input({ transform: booleanAttribute })
  public get rowMouseOverHighlight(): boolean {
    return this._rowMouseOverHighlight;
  }
  public set rowMouseOverHighlight(value: BooleanInput) {
    this._rowMouseOverHighlight = coerceBooleanProperty(value);
  }

  /**
   * Defines the columns to be displayed in the table.
   * This array contains the field paths of the columns that should be shown.
   * @type {string[]}
   * @memberof MfTableComponent
   */
  @Input()
  public displayColumns: string[] = [];

  /**
   * Sets or gets the loading type for the table.
   * Determines how the table displays its loading state, such as showing a spinner or other indicators.
   * @type {MfTableLoadingTypes}
   * @memberof MfTableComponent
   */
  @Input()
  public loadingType: MfTableLoadingTypes = MfTableLoadingTypes.spinner;

  /**
   * Emits when a row is clicked.
   * The event payload contains the clicked row's model data.
   * @type {EventEmitter<TModel>}
   * @memberof MfTableComponent
   */
  @Output()
  public onRowClicked: EventEmitter<TModel> = new EventEmitter();

  /**
   * Emits when the filter group has changed.
   * This event is triggered when any filter in the table is modified.
   * @type {EventEmitter<MfTableFilterGroupEvent>}
   * @memberof MfTableComponent
   */
  @Output()
  public onFilterChanged: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  /**
   * Emits when a filter expression is removed.
   * The event payload contains details about the removed filter expression.
   * @type {EventEmitter<MfTableFilterExpressionEvent>}
   * @memberof MfTableComponent
   */
  @Output()
  public onFilterRemoveExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  /**
   * Emits when a filter group is removed.
   * The event payload contains details about the removed filter group.
   * @type {EventEmitter<MfTableFilterGroupEvent>}
   * @memberof MfTableComponent
   */
  @Output()
  public onFilterRemoveGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  /**
   * Emits when the field columns are loaded.
   * This event is triggered when the table's field columns are initialized.
   * @type {EventEmitter<MfTableFieldColumn[]>}
   * @memberof MfTableComponent
   */
  @Output()
  public onFieldColumnsLoaded: EventEmitter<MfTableFieldColumn[]> = new EventEmitter();

  /**
   * Emits when the filter group is loaded.
   * This event is triggered after the table's filter group has been fully loaded.
   * @type {EventEmitter<MfFilterGroup>}
   * @memberof MfTableComponent
   */
  @Output()
  public onFilterLoaded: EventEmitter<MfFilterGroup> = new EventEmitter();

  /**
   * Emits when the view state is saved.
   * This event is triggered when the table's view state is successfully saved.
   * @type {EventEmitter<void>}
   * @memberof MfTableComponent
   */
  @Output()
  public onViewStateSaved: EventEmitter<void> = new EventEmitter();

  /**
   * Emits when the view state is loaded.
   * This event is triggered when the table's view state is successfully loaded.
   * @type {EventEmitter<void>}
   * @memberof MfTableComponent
   */
  @Output()
  public onViewStateLoaded: EventEmitter<void> = new EventEmitter();

  /**
   * Emits when the selection changes.
   * The event payload contains an array of the tracked by values for the selected rows, or undefined if no rows are selected.
   * @type {EventEmitter<TTrackBy[] | undefined>}
   * @memberof MfTableComponent
   */
  @Output()
  public onSelectionChange: EventEmitter<TTrackBy[] | undefined> = new EventEmitter();

  /**
   * Emits when a row's value changes.
   * The event payload contains details about the row that was changed and the new value.
   * @type {EventEmitter<MfTableRowCellEditorValueChangeEvent<TModel>>}
   * @memberof MfTableComponent
   */
  @Output()
  public onRowValueChanged: EventEmitter<MfTableRowCellEditorValueChangeEvent<TModel>> = new EventEmitter();

  /**
   * Emits when a row's value is being input.
   * The event payload contains details about the row that is being edited and the input value.
   * @type {EventEmitter<MfTableRowCellEditorValueChangeEvent<TModel>>}
   * @memberof MfTableComponent
   */
  @Output()
  public onRowValueInput: EventEmitter<MfTableRowCellEditorValueChangeEvent<TModel>> = new EventEmitter();

  /**
   * Emits when a row's details are expanded.
   * The event payload contains the model data of the item that was expanded.
   * @type {EventEmitter<TModel>}
   * @memberof MfTableComponent
   */
  @Output()
  public onItemDetailExpand: EventEmitter<TModel> = new EventEmitter();

  /**
   * Emits when a row's details are about to collapse.
   * The event payload contains the model data of the item that is collapsing.
   * @type {EventEmitter<TModel>}
   * @memberof MfTableComponent
   */
  @Output()
  public onItemDetailCollapsing: EventEmitter<TModel> = new EventEmitter();

  /**
   * Emits when a row's details have collapsed.
   * The event payload contains the model data of the item that was collapsed.
   * @type {EventEmitter<TModel>}
   * @memberof MfTableComponent
   */
  @Output()
  public onItemDetailCollapsed: EventEmitter<TModel> = new EventEmitter();

  /**
   * Emits when data is loaded into the table.
   * The event payload contains an array of the loaded model data.
   * @type {EventEmitter<TModel[]>}
   * @memberof MfTableComponent
   */
  @Output()
  public onDataLoaded: EventEmitter<TModel[]> = new EventEmitter();

  /**
   * Emits when the rows in the table change.
   * The event payload contains an array of the row cell components currently rendered in the table.
   * @type {EventEmitter<MfTableRowCellComponent<TModel>[]>}
   * @memberof MfTableComponent
   */
  @Output()
  public onRowsChanged: EventEmitter<MfTableRowCellComponent<TModel>[]> = new EventEmitter();

  @ViewChild(MatTable)
  protected _table?: MatTable<TModel>;

  @ContentChild(MfTableColumnSlideOutActionsDef)
  protected _columnSlideOutActionsDef?: MfTableColumnSlideOutActionsDef;

  @ContentChild(MfTableColumnExpandDef)
  protected _columnExpandDef?: MfTableColumnExpandDef;

  @ContentChildren(MfTableColumnDef)
  protected _columnDefQueryList?: QueryList<MfTableColumnDef>;

  @ContentChildren(MfTableColumnModelConfigDef)
  protected _columnModelConfigDef?: QueryList<MfTableColumnModelConfigDef>;

  @ContentChild(MfTableHeaderLeftDef)
  protected _headerLeftDef!: MfTableHeaderLeftDef;

  @ContentChild(MfTableHeaderRightDef)
  protected _headerRightDef!: MfTableHeaderRightDef;

  @ContentChild(MfTableFooterLeftDef)
  protected _footerLeftDef!: MfTableFooterLeftDef;

  @ContentChild(MfTableFooterRightDef)
  protected _footerRightDef!: MfTableFooterRightDef;

  @ViewChildren(MfTableRowCellSlideOutActionsComponent)
  protected _slideOutActionsComponents?: QueryList<MfTableRowCellSlideOutActionsComponent>;

  @ViewChildren("fieldHeaderCell")
  protected _fieldHeaderCells?: QueryList<MfTableRowFieldHeaderCellComponent>;

  @ViewChildren(MfTableRowCellComponent)
  protected _rowCells?: QueryList<MfTableRowCellComponent<TModel>>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _EXPAND_NAME_PREFIX = EXPAND_NAME_PREFIX;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _FIELD_NAME_PREFIX = FIELD_NAME_PREFIX;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _GROUP_NAME_PREFIX = GROUP_NAME_PREFIX;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _INFO_NAME_PREFIX = INFO_NAME_PREFIX;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _SELECTION_MANAGER_POSTFIX = SELECTION_MANAGER_POSTFIX;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _EXPANDED_CONTENT_POSTFIX = EXPANDED_CONTENT_POSTFIX;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _SIDLE_OUT_ACTION_POSTFIX = SIDLE_OUT_ACTION_POSTFIX;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _ROW_CLICK_ACTION_POSTFIX = ROW_CLICK_ACTION_POSTFIX;

  protected _filterModeTypes = MfTableFilterModeTypes;

  protected get _filterGroup(): MfFilterGroup | undefined {
    return this._filterGroupInit;
  }
  protected set _filterGroup(value: MfFilterGroup | undefined) {
    this._filterGroupInit = value;
  }
  protected _filterGroupInit?: MfFilterGroup | undefined;

  protected _blockDataLoad = false;
  protected _columnDefs?: MfTableColumnDef[] = [];
  protected _dataNeedsReload = false;
  protected _dataSource: MfTableDataSource<TModel>;
  protected _expandColumns: string[] = [];
  protected _expandColumnsUpdate: string[] = [];
  protected _expandedItem: TModel | null = null;
  protected _fieldDisplayColumns: string[] = [];
  protected _fieldDisplayColumnsUpdate: string[] = [];
  protected _fieldInfoDisplayColumns: string[] = [];
  protected _fieldInfoDisplayColumnsUpdate: string[] = [];
  protected _filterChanged = false;
  protected _groupColumns: MfTableGroupColumn[] = [];
  protected _groupDisplayColumns: string[] = [];
  protected _groupDisplayColumnsUpdate: string[] = [];
  protected _hasData = false;
  protected _hasGroupedColumns = false;
  protected _isLoading = false;
  protected _lastClickedTrackedByIndex: number | undefined;
  protected _loadingEventId?: number;
  protected _locationKey?: string | undefined;
  protected _postModelConfig?: MfModelConfigMapped | undefined;
  protected _modelConfig?: MfModelConfigMapped | undefined;
  protected _pagination: MfTablePagination = { pageIndex: 0, pageSize: 10, length: 0 };
  protected _paginationEnabled: boolean = true;
  protected _rowMouseOverHighlight = true;
  protected _selectionManager?: MfTableSelectionManager<TModel, TTrackBy>;
  protected _selectionManagerIsLoading = false;
  protected _selectionSticky = true;
  protected _selectionStickyEnd = false;
  protected _shrinkSticky = false;
  protected _viewManagerEnabled = false;
  protected _viewState?: MfViewState<MfTableViewStateData<TFilter>>;
  protected _initialViewStateLoaded = false;
  protected _applyQueryParamFilters$?: Observable<MfTableQueryFilterParam[]>;

  /**
    * @constructor
    * @param {Injector} _injector - The Angular injector used to inject dependencies.
    * @param {MfTableQueryFilterService} _queryFilterService - Service for handling table query filters.
    * @param {MfFilterService} _filterService - Service for handling table filters.
    * @param {MfModelConfigService} _modelConfigService - Service for managing model configurations.
    * @param {MfViewManagerStorageService<MfTableViewStateData<TFilter>>} _viewManagerService - Service for managing view state.
    * @param {MfTableConfig} _config - Configuration settings for the table.
    * @param {Optional} _filterConverterService - Optional service for converting filters.
    */
  public constructor(
    protected override _injector: Injector,
    protected _queryFilterService: MfTableQueryFilterService,
    protected _filterService: MfFilterService,
    protected _modelConfigService: MfModelConfigService,
    protected _viewManagerService: MfViewManagerStorageService<MfTableViewStateData<TFilter>>,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    protected _config: MfTableConfig,
    @Optional() @Inject(MF_FILTER_CONVERTER_SERVICE_TOKEN)
    protected _filterConverterService?: MfFilterConverterService<TFilter>,
  ) {
    super(TYPE_INFO, _injector);
    this._dataSource = this._initDataSource();
    this._selectionManager = this._initSelectionManager();

    this._setDefaultGroupFilter();
  }

  /**
   * @override
   * @description Cleans up resources when the component is destroyed, including canceling the selection manager if enabled.
   */
  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (!mfTypeIsUndefined(this._selectionManager) && this._selectionManager.enabled === true) {
      this._selectionManager.cancel();
    }
  }

  /**
   * @description Lifecycle hook that is called after Angular has initialized all data-bound properties of the component.
   * Sets up subscriptions to data source and initializes columns and filters.
   */
  public ngOnInit(): void {
    this._sub(this._dataSource.hasData, { next: (hasData) => setTimeout(() => this._hasData = hasData, 1) });
    this._createColumns();
    this._setDisplayColumns();
    this._setDefaultGroupFilter();
  }

  /**
   * @description Lifecycle hook that is called after Angular has fully initialized all content of the component.
   * Sets up column definitions and display columns based on the content children.
   */
  public ngAfterContentInit(): void {
    this._setColumnDefs();
    this._setCustomDisplayColumns();
    this._updateDisplayColumns();
    this._setColumnsColumnModelConfigDefs();
  }

  /**
   * @description Lifecycle hook that is called after Angular has fully initialized the view of the component.
   * Sets up row change detection and emits an event when rows change.
   */
  public ngAfterViewInit(): void {
    if (!mfTypeIsUndefined(this._rowCells)) {
      this._sub(this._rowCells.changes, { next: () => this.onRowsChanged.emit(this._rowCells!.toArray()) });
    }
  }

  /**
   * @description Gets the loading state of the table.
   * @returns {boolean} Whether the table is currently loading data.
   */
  public get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   * @description Gets the current filter group applied to the table.
   * @returns {MfFilterGroup} The current filter group.
   */
  public get filterGroup(): MfFilterGroup {
    return this._filterGroup!;
  }

  /**
   * @description Gets the data source associated with the table.
   * @returns {MfTableDataSource<TModel>} The data source used by the table.
   */
  public get dataSource(): MfTableDataSource<TModel> {
    return this._dataSource;
  }

  /**
   * @description Gets the selection manager associated with the table.
   * @returns {MfTableSelectionManager<TModel, TTrackBy> | undefined} The selection manager if enabled, otherwise undefined.
   */
  public get selectionManager(): MfTableSelectionManager<TModel, TTrackBy> | undefined {
    return this._selectionManager;
  }

  /**
   * @description Disables filtering for a specified field by its path.
   * @param {MFModelConfigFieldPath} fieldPath - The path of the field to disable filtering for.
   */
  public filterDisable(fieldPath: MFModelConfigFieldPath): void {
    const fieldColumn = this.findFieldColumnByFieldPath(fieldPath);
    if (!mfTypeIsUndefined(fieldColumn) && !mfTypeIsUndefined(fieldColumn.modelFieldConfig) && !mfTypeIsUndefined(fieldColumn.modelFieldConfig.filter)) {
      fieldColumn.modelFieldConfig.filter.disabled = true;
    }
  }

  /**
   * @description Enables filtering for a specified field by its path.
   * @param {MFModelConfigFieldPath} fieldPath - The path of the field to enable filtering for.
   */
  public filterEnable(fieldPath: MFModelConfigFieldPath): void {
    const fieldColumn = this.findFieldColumnByFieldPath(fieldPath);
    if (!mfTypeIsUndefined(fieldColumn) && !mfTypeIsUndefined(fieldColumn.modelFieldConfig) && !mfTypeIsUndefined(fieldColumn.modelFieldConfig.filter)) {
      fieldColumn.modelFieldConfig.filter.disabled = false;
    }
  }

  /**
   * @description Sets a filter for a specific field in the table.
   * @param {MFModelConfigFieldPath} fieldPath - The path of the field to filter.
   * @param {number | string | Date | null | undefined} value - The value to filter by.
   * @param {MfFilterOperatorTypes} operator - The operator to use for the filter.
   * @throws {MfErrorNotImplemented} If the filter mode is not supported.
   */
  public setFieldFilter(fieldPath: MFModelConfigFieldPath, value: MfFilterValue, operator: MfFilterOperatorTypes): void {
    if (!mfTypeIsUndefined(this._table) && this._config.filter.mode === MfTableFilterModeTypes.rowHeader) {
      const fieldColumn = this.findFieldColumnByFieldPath(fieldPath);
      if (!mfTypeIsUndefined(fieldColumn) && !mfTypeIsUndefined(fieldColumn.filter) && !mfTypeIsUndefined(fieldColumn.modelFieldConfig)) {
        if (!mfStringIsEmptyOrWhitespace(value as string)) {
          let expression = fieldColumn.filter.group.expressions.find(e => e.fieldPath === fieldPath);
          if (mfTypeIsUndefined(expression)) {
            expression = this._filterService.getNewFilterExpression(fieldPath, fieldPath, operator, value, fieldColumn.modelFieldConfig);
            fieldColumn.filter.group.expressions.push(expression);
          } else {
            expression.value = value;
          }
        } else {
          fieldColumn.filter.group.expressions = [];
        }

        this._updateFilterGroup();
      }
    } else {
      throw new MfErrorNotImplemented(this._typeInfo, "setFieldFilter", "For tableHeader filter");
    }
  }

  /**
   * @description Closes all slide-out action components within the table.
   */
  public slideOutActionsClose(): void {
    this._slideOutActionsComponents?.forEach((slideOutActionsComponent) => {
      slideOutActionsComponent.close();
    });
  }

  /**
   * @description Finds a field column by its field path.
   * @param {MFModelConfigFieldKey} fieldPath - The path of the field to find.
   * @returns {MfTableFieldColumn | undefined} The found field column, or undefined if not found.
   */
  public findFieldColumnByFieldPath(fieldPath: MFModelConfigFieldKey): MfTableFieldColumn | undefined {
    const groupColumnsLength = this._groupColumns.length;
    for (let groupColumnIndex = 0; groupColumnIndex < groupColumnsLength; groupColumnIndex++) {
      const groupColumn = this._groupColumns[groupColumnIndex];
      if (!mfTypeIsUndefined(groupColumn.fieldColumns)) {
        const fieldColumnsLength = groupColumn.fieldColumns.length;
        for (let fieldColumnIndex = 0; fieldColumnIndex < fieldColumnsLength; fieldColumnIndex++) {
          const fieldColumn = groupColumn.fieldColumns[fieldColumnIndex];
          if (fieldColumn.fieldPath === fieldPath) {
            return fieldColumn;
          }
        }
      }
    }
    return;
  }

  /**
   * @description Selects or deselects all items in the table.
   * @param {boolean} checked - Whether to select (true) or deselect (false) all items.
   */
  public setAllItemsSelected(checked: boolean): void {
    if (!mfTypeIsUndefined(this._selectionManager) && !mfTypeIsUndefined(this.modelConfig)) {
      this._selectionManager.setAllItemsSelected(checked, this.modelConfig);
    }
  }

  /**
   * @description Selects or deselects a specific item in the table.
   * @param {TModel} item - The item to select or deselect.
   * @param {boolean} checked - Whether to select (true) or deselect (false) the item.
   */
  public setItemSelected(item: TModel, checked: boolean): void {
    if (!mfTypeIsUndefined(this._selectionManager) && !mfTypeIsUndefined(this.modelConfig)) {
      this._selectionManager.setItemSelected(item, checked);
    }
  }

  /**
   * @description Loads data into the table based on the current pagination, filter, and model configurations.
   */
  public loadData(): void {
    if (this._canLoadData()) {
      if (this._paginationEnabled === false) {
        this._pagination.pageIndex = 0;
        this._pagination.pageSize = MF_NUMBER_INT_MAX_VALUE;
      }
      this._updateFieldColumnHasFilters();
      this._setDisplayColumns();
      const fieldColumns = this._getFieldColumns();
      this.dataSource.load(this._pagination, this._filterGroup!, fieldColumns, this.modelConfig!, this.postModelConfig);
      if (!mfTypeIsUndefined(this.selectionManager)) {
        this.selectionManager.load(this._filterGroup!, fieldColumns);
      }
      this._dataNeedsReload = false;
      this._cdRef.detectChanges();
    }
  }

  /**
   * @description Handles changes to the visibility of a field column.
   * @param {MfTableFieldColumn} fieldColumn - The field column whose visibility has changed.
   */
  public onFieldColumnVisibleChanged(fieldColumn: MfTableFieldColumn): void {
    this._updateGroupColumnVisibleFromFieldColumn(fieldColumn);
    this._setDisplayColumns();
    this._viewManagerService.onViewStateNeedsCaching.emit(this.locationKey);
    this._needsDataReload(MfTableDataReloadTypes.visibleChange);
  }

  protected get _hasRowClickAction(): boolean {
    return this.onRowClicked.observed;
  }

  protected _canLoadData(): boolean {
    const canLoad = this.blockDataLoad === false 
      && this._isLoading === false 
      && !mfTypeIsUndefined(this._filterGroup) 
      && !mfTypeIsUndefined(this.modelConfig);

    if (this._viewManagerEnabled) {
      return canLoad && this._initialViewStateLoaded;
    } else {
      return canLoad;
    }
  }

  /**
  * @protected
  * @description Applies query parameter filters to the table if not already applied.
  */
  protected _applyQueryParamFilters(): void {
    if (mfTypeIsUndefined(this._applyQueryParamFilters$) && !mfTypeIsUndefined(this._modelConfig) && !mfTypeIsUndefined(this._locationKey) && this._config.filter.mode === MfTableFilterModeTypes.rowHeader) {
      this._applyQueryParamFilters$ = this._queryFilterService.applyQueryParamFilters(this._modelConfig, this._locationKey).pipe(
        tap((results) => {
          results.forEach((result) => {
            this._filterService.removeExpressionWithFieldPath(this._filterGroup!, result.fieldPath);
            const fieldColumn = this.findFieldColumnByFieldPath(result.fieldPath);
            if (!mfTypeIsUndefined(fieldColumn)) {
              const expression = this._filterService.getNewFilterExpression(result.fieldPath, result.fieldPath, result.operator, result.value, result.modelFieldConfig);
              fieldColumn.filter?.group.expressions.push(expression);
            }
          });

          this._updateFilterGroup();
          this._updateFieldColumnHasFilters();
          this._viewManagerService.onViewStateNeedsCaching.emit(this.locationKey);
          this._needsDataReload(MfTableDataReloadTypes.filterChange);
        })
      );
      this._sub(this._applyQueryParamFilters$);
    }
  }

  /**
   * @protected
   * @description Initializes the selection manager for the table based on the configuration.
   * @returns {MfTableSelectionManager<TModel, TTrackBy> | undefined} The initialized selection manager, or undefined if not configured.
   */
  protected _initSelectionManager(): MfTableSelectionManager<TModel, TTrackBy> | undefined {
    if (mfTypeIsUndefined(this._config.selectionManagerType)) {
      this.selectionManagerEnabled = false;
      return;
    } else {
      const selectionManager = new this._config.selectionManagerType(this._injector, this._config) as MfTableSelectionManager<TModel, TTrackBy>;

      this._sub(selectionManager.onSelectionChanged, { next: (event) => this.onSelectionChange.emit(event as TTrackBy[]) });
      this._sub(selectionManager.isLoading, { next: (isLoading) => this._selectionManagerIsLoading = isLoading });

      return selectionManager;
    }
  }

  /**
   * @protected
   * @description Initializes the data source for the table based on the configuration.
   * @returns {MfTableDataSource<TModel>} The initialized data source.
   * @throws {MfError} If the data source type is not set in the configuration.
   */
  protected _initDataSource(): MfTableDataSource<TModel> {
    if (mfTypeIsUndefined(this._config.dataSourceType)) {
      throw new MfError(this.typeInfo, "constructor", "dataSourceType is no set in MfTableConfig");
    } else {
      const dataSource = new this._config.dataSourceType(this._injector, this._config) as MfTableDataSource<TModel>;

      this._sub(dataSource.onDataLoaded, { next: (event) => this.onDataLoaded.emit(event as TModel[]) });
      this._sub(dataSource.isLoading, { next: (isLoading) => this._isLoadingStateChanged(isLoading) });

      return dataSource;
    }
  }

  /**
   * @protected
   * @description Updates the loading state of the table and handles the loading event if necessary.
   * @param {boolean} isLoading - Whether the table is currently loading data.
   */
  protected _isLoadingStateChanged(isLoading: boolean): void {
    this._isLoading = isLoading;

    if (this.loadingType === MfTableLoadingTypes.service) {
      if (isLoading === true && mfTypeIsUndefined(this._loadingEventId)) {
        this._loadingEventId = this._loadingService.nextEventId;
        this._loadingService.loadingStarted(this._loadingEventId);
      } else if (isLoading === false && !mfTypeIsUndefined(this._loadingEventId)) {
        this._loadingService.loadingComplete(this._loadingEventId);
      }
    }
  }

  /**
   * @protected
   * @description Toggles the expansion state of a row in the table.
   * @param {MouseEvent} event - The mouse event that triggered the toggle.
   * @param {TModel} item - The item associated with the row being toggled.
   */
  protected _toggleExpandableRow(event: MouseEvent, item: TModel): void {
    this._expandedItem = this._expandedItem === item ? null : item;
    event.stopPropagation();

    if (!mfTypeIsNull(this._expandedItem)) {
      this.onItemDetailExpand.emit(item);
    } else {
      this.onItemDetailCollapsing.emit(item);
    }
  }

  /**
   * @protected
   * @description Handles the completion of the expandable row animation and emits the appropriate event.
   * @param {AnimationEvent} event - The animation event that occurred.
   * @param {TModel} item - The item associated with the row that completed its animation.
   */
  protected _onExpandableRowAnimationDone(event: AnimationEvent, item: TModel): void {
    if (event.fromState === "expanded") {
      this.onItemDetailCollapsed.emit(item);
    }
  }

  /**
   * @protected
   * @description Opens the header menu for a specific field column.
   * @param {MfTableFieldColumn} fieldColumn - The field column whose header menu should be opened.
   */
  protected _openHeaderMenu(fieldColumn: MfTableFieldColumn) {
    if (this._config.filter.mode === MfTableFilterModeTypes.rowHeader) {
      const fieldHeaderCell = this._fieldHeaderCells?.find(i => i.fieldColumn?.fieldPath == fieldColumn.fieldPath);
      if (!mfTypeIsUndefined(fieldHeaderCell)) {
        fieldHeaderCell.openMenu();
      }
    }
  }

  /**
   * @protected
   * @description Handles the event when the filter group changes and updates the filter group accordingly.
   * @param {MfTableFilterGroupEvent} event - The event representing the filter group change.
   */
  protected _onFilterChanged(event: MfTableFilterGroupEvent): void {
    if (!mfTypeIsUndefined(this._filterGroup)) {
      this._updateFilterGroup();
      this._filterChanged = true;
      this._updateFieldColumnHasFilters();
      this._viewManagerService.onViewStateNeedsCaching.emit(this.locationKey);
      this.onFilterChanged.emit(event);
    }
  }

  /**
   * @protected
   * @description Handles the closing of the table filter menu and reloads the table data if necessary.
   */
  protected _onTableFilterMenuClose(): void {
    if (!mfTypeIsUndefined(this._filterGroup) && this._filterChanged === true) {
      this._pagination.pageIndex = 0;
      this._needsDataReload(MfTableDataReloadTypes.filterChange);
      this._filterChanged = false;
    }
  }

  /**
   * @protected
   * @description Updates the view state of the table before it is saved.
   * @param {MfViewState<MfTableViewStateData<TFilter>>} viewState - The view state to be updated.
   */
  protected _onViewStateBeforeUpdate(viewState: MfViewState<MfTableViewStateData<TFilter>>): void {
    if (this._viewManagerEnabled === true) {
      this._updateViewState(viewState);
    }
  }

  /**
   * @protected
   * @description Handles the event when a group column is moved, updating the order of columns accordingly.
   * @param {MfTableGroupColumnMovingEvent} event - The event representing the movement of a group column.
   */
  protected _onGroupColumnMoving(event: MfTableGroupColumnMovingEvent): void {
    this._moveItemInGroupColumns(event.fromIndex, event.toIndex);
    this._setDisplayColumns();
    this._viewManagerService.onViewStateNeedsCaching.emit(this.locationKey);
  }

  /**
   * @protected
   * @description Handles changes to the visibility of multiple field columns and reloads the table data if necessary.
   * @param {MfTableFieldColumn[]} fieldColumns - The field columns whose visibility has changed.
   */
  protected _onFieldsColumnVisibleChanged(fieldColumns: MfTableFieldColumn[]): void {
    const observable = new Observable<boolean>((subscribe) => {
      fieldColumns.forEach((fieldColumn) => {
        this._updateGroupColumnVisibleFromFieldColumn(fieldColumn);
      });

      this._setDisplayColumns();
      this._viewManagerService.onViewStateNeedsCaching.emit(this.locationKey);
      this._needsDataReload(MfTableDataReloadTypes.visibleChange);

      setTimeout(() => {
        subscribe.next(true);
        subscribe.complete();
      }, 10);
    });

    const loading = this._subLoading(observable, {
      next: () => {
        loading.complete();
      }
    });
  }

  /**
   * @protected
   * @description Handles the clearing of sort indices and reloads the table data if necessary.
   */
  protected _onSortCleared(): void {
    this._fillSortIndex();
    this._needsDataReload(MfTableDataReloadTypes.sortChange);
    this._viewManagerService.onViewStateNeedsCaching.emit(this.locationKey);
  }

  /**
   * @protected
   * @description Handles changes to the sort direction and reloads the table data if necessary.
   */
  protected _onSortDirectionChanged(): void {
    this._updateSortIndex();
    this._needsDataReload(MfTableDataReloadTypes.sortChange);
    this._viewManagerService.onViewStateNeedsCaching.emit(this.locationKey);
  }

  /**
   * @protected
   * @description Handles the event when the sort index is changing, reassigning the sort index accordingly.
   * @param {MfOnSortIndexChangingEvent} event - The event representing the sort index change.
   */
  protected _onSortIndexChanging(event: MfOnSortIndexChangingEvent): void {
    this._reassignSortIndex(event.oldIndex, event.newIndex);
  }

  /**
   * @protected
   * @description Handles changes to the sort index and reloads the table data if necessary.
   */
  protected _onSortIndexChanged(): void {
    this._needsDataReload(MfTableDataReloadTypes.sortChange);
    this._viewManagerService.onViewStateNeedsCaching.emit(this.locationKey);
  }

  /**
   * @protected
   * @description Handles the event when a new view state is selected and loads the corresponding view state.
   * @param {MfViewState<MfTableViewStateData<TFilter>>} viewState - The view state that was selected.
   */
  protected _onSelectedViewStateChanged(viewState: MfViewState<MfTableViewStateData<TFilter>>): void {
    if (this._viewManagerEnabled === true) {
      this._loadViewState(viewState);
      this._needsDataReload(MfTableDataReloadTypes.viewChange);
    }
  }

  /**
   * @protected
   * @description Handles the resizing of the table and updates the sticky header row styles.
   * @param {MfResizedEvent} event - The event representing the resize operation.
   */
  protected _onMeasureResized(event: MfResizedEvent) {
    this._table?.updateStickyHeaderRowStyles();
    if (event.newRect.width < 660) {
      this._shrinkSticky = true;
    } else {
      this._shrinkSticky = false;
    }
  }

  /**
   * @protected
   * @description Handles pagination changes and reloads the table data accordingly.
   * @param {PageEvent} event - The pagination event that occurred.
   */
  protected _onPageChange(event: PageEvent): void {
    if (this._pagination.pageSize != event.pageSize) {
      this._pagination.pageIndex = 0;
    } else {
      this._pagination.pageIndex = event.pageIndex;
    }
    this._pagination.pageSize = event.pageSize;
    this._pagination.previousPageIndex = event.previousPageIndex;
    this._viewManagerService.onViewStateNeedsCaching.emit(this.locationKey);
    this._needsDataReload(MfTableDataReloadTypes.paginationChange);
  }

  /**
   * @protected
   * @description Handles the event when a row is clicked, emitting the corresponding event.
   * @param {MouseEvent} event - The mouse event that triggered the row click.
   * @param {TModel} row - The row that was clicked.
   */
  protected _onRowClick(event: MouseEvent, row: TModel): void {
    this.onRowClicked.emit(row);
  }

  /**
   * @protected
   * @description Checks whether the table has any filters applied.
   * @returns {boolean} True if there are filters applied, otherwise false.
   */
  protected get _hasFilters(): boolean {
    return this._groupColumns.some(groupColumn => !mfTypeIsUndefined(groupColumn.fieldColumns) && groupColumn.fieldColumns.some(fieldColumn => fieldColumn.filter?.hasFilters === true));
  }

  /**
   * @protected
   * @description Handles the event when an item is selected, including handling shift-click for range selection.
   * @param {TModel} item - The item that was selected or deselected.
   * @param {MouseEvent} event - The mouse event that triggered the selection.
   * @param {MatCheckbox} itemSelect - The checkbox associated with the item.
   */
  protected _onSelectItemClicked(item: TModel, event: MouseEvent, itemSelect: MatCheckbox): void {
    if (!mfTypeIsUndefined(this._selectionManager)) {
      if (itemSelect.checked === true) {
        const clickedTrackedByValue = this._selectionManager.getItemTrackedByValue(item);
        if (!mfTypeIsUndefined(clickedTrackedByValue)) {
          if (event.shiftKey === true && !mfTypeIsUndefined(this._lastClickedTrackedByIndex)) {
            const allTrackBy = this._selectionManager.all;
            const setSelected = [];

            const clickedTrackedByIndex = this._selectionManager.all.findIndex(i => i === clickedTrackedByValue);
            if (clickedTrackedByIndex !== -1) {
              for (let allTrackByIndex = this._lastClickedTrackedByIndex; allTrackByIndex <= clickedTrackedByIndex; allTrackByIndex++) {
                setSelected.push(allTrackBy[allTrackByIndex]);
              }
            }

            this._selectionManager.setSelectedByTrackedBy(setSelected, false);
          } else {
            this._lastClickedTrackedByIndex = this._selectionManager.all.findIndex(i => i === clickedTrackedByValue);
          }
        }
      } else {
        delete this._lastClickedTrackedByIndex;
      }

      event.stopPropagation();
    }
  }

  /**
   * @protected
   * @description Handles the event when a slide-out action is opened, closing all other slide-out actions.
   * @param {MfTableRowCellSlideOutActionsOpenEvent} event - The event representing the slide-out action open event.
   */
  protected _onActionsSlideOutOpen(event: MfTableRowCellSlideOutActionsOpenEvent): void {
    this._slideOutActionsComponents?.forEach((slideOutActionsComponent) => {
      if (slideOutActionsComponent !== event.component) {
        slideOutActionsComponent.close();
      }
    });
  }

  /**
   * @protected
   * @description Handles the input event for a row cell value, emitting the corresponding event.
   * @param {MfTableRowCellEditorValueChangeEvent<TModel>} event - The event representing the row cell value input.
   */
  protected _onRowCellValueInput(event: MfTableRowCellEditorValueChangeEvent<TModel>): void {
    this.onRowValueInput.emit(event);
  }

  /**
   * @protected
   * @description Handles the change event for a row cell value, emitting the corresponding event.
   * @param {MfTableRowCellEditorValueChangeEvent<TModel>} event - The event representing the row cell value change.
   */
  protected _onRowCellValueChanged(event: MfTableRowCellEditorValueChangeEvent<TModel>): void {
    this.onRowValueChanged.emit(event);
  }

  /**
   * @protected
   * @description Handles the event when a table reload is required due to a cell change.
   * @param {boolean} event - Whether the table needs to be reloaded.
   */
  protected _onCellRequireTableReload(event: boolean): void {
    if (event === true) {
      this._needsDataReload();
    }
  }

  /**
   * @protected
   * @description Determines whether a specific item in the table can be selected.
   * @param {TModel & MfTableModelBase} item - The item to check.
   * @returns {boolean} True if the item can be selected, otherwise false.
   */
  protected _canSelectItem(item: TModel & MfTableModelBase): boolean {
    if (!mfTypeIsUndefined(this._selectionManager)) {
      if (mfTypeIsUndefined(item.tableComponentCanSelectItem)) {
        const isTracked = this._selectionManager.isTracked(item);
        if (isTracked === true) {
          item.tableComponentCanSelectItem = isTracked;
        }
        return isTracked;
      } else {
        return item.tableComponentCanSelectItem;
      }
    }
    return false;
  }

  /**
   * @protected
   * @description Retrieves all field columns in the table.
   * @returns {MfTableFieldColumn[]} An array of all field columns.
   */
  protected _getFieldColumns(): MfTableFieldColumn[] {
    return this._groupColumns.filter(groupColumn => !mfTypeIsUndefined(groupColumn.fieldColumns)).flatMap(groupColumn => groupColumn.fieldColumns as MfTableFieldColumn[]);
  }

  /**
   * @protected
   * @description Sets the model configuration for the selection manager if enabled.
   */
  protected _selectionManagerSetModelConfig(): void {
    if (!mfTypeIsUndefined(this._selectionManager) && this._selectionManager.enabled === true && !mfTypeIsUndefined(this._modelConfig)) {
      this._selectionManager.setModelConfig(this._modelConfig);
    }
  }

  /**
   * @protected
   * @description Indicates that the table data needs to be reloaded based on a specific data reload type.
   * @param {MfTableDataReloadTypes} [dataReloadType] - The type of data reload required.
   */
  protected _needsDataReload(dataReloadType?: MfTableDataReloadTypes): void {
    if (this._blockDataLoad === false) {
      if (this._config.dataAutoReload.some(i => i === dataReloadType)) {
        this.loadData();
      } else {
        this._dataNeedsReload = true;
        this._cdRef.detectChanges();
      }
    }
  }

  /**
   * @protected
   * @description Moves an item in the group columns from one index to another.
   * @param {number} fromGroupColumnIndex - The index to move the group column from.
   * @param {number} toGroupColumnIndex - The index to move the group column to.
   */
  protected _moveItemInGroupColumns(fromGroupColumnIndex: number, toGroupColumnIndex: number): void {
    moveItemInArray(this._groupColumns, fromGroupColumnIndex, toGroupColumnIndex);
  }

  /**
   * @protected
   * @description Updates the view state of the table before it is saved.
   * @param {MfViewState<MfTableViewStateData<TFilter>>} viewState - The view state to be updated.
   */
  protected _updateViewState(viewState: MfViewState<MfTableViewStateData<TFilter>>): void {
    if (this._viewManagerEnabled === true && !mfTypeIsUndefined(this._filterConverterService)) {

      const viewStateColumns: MfTableColumnViewStateData[] = [];

      let filter: TFilter | undefined;
      if (!mfTypeIsUndefined(this._filterGroup)) {
        filter = this._filterConverterService.fromGroup(this._filterGroup, false);
      }

      const groupColumnsLength = this._groupColumns.length;
      for (let groupColumnIndex = 0; groupColumnIndex < groupColumnsLength; groupColumnIndex++) {
        const groupColumn = this._groupColumns[groupColumnIndex];
        if (!mfTypeIsUndefined(groupColumn.fieldColumns)) {
          const fieldColumnsLength = groupColumn.fieldColumns.length;
          for (let fieldColumnIndex = 0; fieldColumnIndex < fieldColumnsLength; fieldColumnIndex++) {
            const fieldColumn = groupColumn.fieldColumns[fieldColumnIndex];

            const viewColumn: MfTableColumnViewStateData = {
              fieldPath: fieldColumn.fieldPath,
              fieldIndex: fieldColumnIndex,
              groupIndex: groupColumnIndex,
              width: fieldColumn.width?.width,
              sort: mfTypeIsUndefined(fieldColumn.sort) ? undefined : {
                sortDirection: fieldColumn.sort?.direction,
                sortIndex: fieldColumn.sort?.index,
              },
              visible: fieldColumn.visible,
              sticky: fieldColumn.sticky,
              stickyEnd: fieldColumn.stickyEnd,
            };

            viewStateColumns.push(viewColumn);
          }
        }
      }

      viewState.data = { columns: viewStateColumns, filter, pageSize: this._pagination.pageSize, pageIndex: this._pagination.pageIndex };

      this.onViewStateSaved.next();
    }
  }

  /**
   * @protected
   * @description Loads a view state into the table, either as a default or as a saved state.
   * @param {MfViewState<MfTableViewStateData<TFilter>>} viewState - The view state to be loaded.
   */
  protected _loadViewState(viewState: MfViewState<MfTableViewStateData<TFilter>>): void {
    if (this._viewManagerEnabled === true) {
      if (viewState.isDefaultViewState === true && mfTypeIsUndefinedOrValue(viewState.isDefaultViewStateCached, false)) {
        this._loadDefaultViewState();
      } else {
        this._loadSavedViewState(viewState);
      }
      this._initialViewStateLoaded = true;
    }
  }

  /**
   * @protected
   * @description Loads the default view state into the table.
   */
  protected _loadDefaultViewState(): void {
    if (this._viewManagerEnabled === true) {
      this._pagination.pageSize = this._config.footer.pagination.pageSize;

      this._groupColumns = this._orderGroupColumnsAndFieldColumns(this._groupColumns);

      this._groupColumns.forEach((groupColumn) => {
        groupColumn.fieldColumns?.forEach((fieldColumn) => {
          if (!mfTypeIsUndefined(fieldColumn.modelFieldConfig)) {
            fieldColumn.visible = mfTableGetVisible(fieldColumn.modelFieldConfig);

            delete fieldColumn.sticky;
            delete fieldColumn.stickyEnd;

            if (mfTableHeaderCanSort(fieldColumn.modelFieldConfig)) {
              fieldColumn.sort = { options: [] };
            } else {
              delete fieldColumn.sort;
            }
          }
        });
      });

      this._updateGroupColumnOrderAndVisible();

      this._setDisplayColumns();
      this._setDefaultGroupFilter();
      this._applyQueryParamFilters();
      this._updateFieldColumnHasFilters();

      this._updateSortIndex();

      this.onViewStateLoaded.emit();
    }
  }

  /**
   * @protected
   * @description Loads a saved view state into the table.
   * @param {MfViewState<MfTableViewStateData<TFilter>>} viewState - The saved view state to be loaded.
   */
  protected _loadSavedViewState(viewState: MfViewState<MfTableViewStateData<TFilter>>): void {
    if (this._viewManagerEnabled === true && !mfTypeIsUndefined(this._filterConverterService)) {
      if (!mfTypeIsUndefined(viewState.data)) {

        this._pagination.pageSize = viewState.data.pageSize;
        if (!mfTypeIsUndefined(viewState.data.pageIndex)) {
          this._pagination.pageIndex = viewState.data.pageIndex;
        }

        if (!mfTypeIsUndefined(viewState.data.columns)) {
          const viewColumnsLength = viewState.data.columns.length;
          for (let viewColumnIndex = 0; viewColumnIndex < viewColumnsLength; viewColumnIndex++) {
            const viewColumn = viewState.data.columns[viewColumnIndex];
            const groupColumnIndex = this._groupColumns.findIndex(groupColumn => !mfTypeIsUndefined(groupColumn.fieldColumns) && groupColumn.fieldColumns[0].fieldPath === viewColumn.fieldPath);
            if (groupColumnIndex != -1 && !mfTypeIsUndefined(viewColumn.groupIndex) && !mfTypeIsUndefined(viewColumn.fieldIndex)) {
              const groupColumn = this._groupColumns[groupColumnIndex];
              if (!mfTypeIsUndefined(groupColumn) && !mfTypeIsUndefined(groupColumn.fieldColumns)) {
                const fieldColumn = groupColumn.fieldColumns[0];
                if (!mfTypeIsUndefined(fieldColumn)) {
                  this._moveItemInGroupColumns(groupColumnIndex, viewColumn.groupIndex);

                  fieldColumn.visible = viewColumn.visible;
                  fieldColumn.sticky = viewColumn.sticky;
                  fieldColumn.stickyEnd = viewColumn.stickyEnd;

                  if (mfTypeIsUndefined(viewColumn.sort)) {
                    delete fieldColumn.sort;
                  } else {
                    fieldColumn.sort = { direction: viewColumn.sort.sortDirection, index: viewColumn.sort.sortIndex };
                  }

                  if (!mfTypeIsUndefined(viewColumn.width)) {
                    fieldColumn.width = { width: viewColumn.width, hasAdjustment: fieldColumn.width?.hasAdjustment || true };
                  }
                }
              }
            }
          }

          this._updateGroupColumnOrderAndVisible();

          this._setDisplayColumns();
        }

        if (!mfTypeIsUndefined(viewState.data.filter) && !mfTypeIsUndefined(this.modelConfig)) {
          this._setFilterGroup(this._filterConverterService.toGroup(viewState.data.filter, this.modelConfig.fields));
          this._applyQueryParamFilters();
          this._updateFieldColumnHasFilters();
        }

        this._updateSortIndex();

        this.onViewStateLoaded.emit();
      }
    }
  }

  /**
   * @protected
   * @description Updates the filter group based on the applied filters in the table.
   */
  protected _updateFilterGroup(): void {
    if (!mfTypeIsUndefined(this._filterGroup) && this._config.filter.mode === MfTableFilterModeTypes.rowHeader) {
      this._pagination.pageIndex = 0;

      this._filterGroup.expressions = [];
      this._filterGroup.groups = [];

      const groupColumnsLength = this._groupColumns.length;
      for (let groupColumnIndex = 0; groupColumnIndex < groupColumnsLength; groupColumnIndex++) {
        const groupColumn = this._groupColumns[groupColumnIndex];

        if (!mfTypeIsUndefined(groupColumn.fieldColumns)) {

          const fieldColumnsLength = groupColumn.fieldColumns.length;
          for (let fieldColumnIndex = 0; fieldColumnIndex < fieldColumnsLength; fieldColumnIndex++) {
            const fieldColumn = groupColumn.fieldColumns[fieldColumnIndex];

            if (!mfTypeIsUndefined(fieldColumn.filter) && !mfTypeIsUndefined(fieldColumn.filter.group) && !mfTypeIsUndefined(fieldColumn.filter.group.expressions) && fieldColumn.filter.group.expressions.length >= 1) {
              this._filterGroup.groups.push(fieldColumn.filter.group);
            }
          }
        }
      }

      this.onFilterLoaded.emit(this._filterGroup);
      this._viewManagerService.onViewStateNeedsCaching.emit(this.locationKey);
    }
  }

  /**
   * @protected
   * @description Sets the filter group for the table.
   * @param {MfFilterGroup} fromGroup - The filter group to be set.
   */
  protected _setFilterGroup(fromGroup: MfFilterGroup): void {
    this._filterGroup = fromGroup;

    if (this._config.filter.mode === MfTableFilterModeTypes.rowHeader) {
      const groupsLength = this._filterGroup.groups.length;

      for (let groupIndex = 0; groupIndex < groupsLength; groupIndex++) {
        const group = this._filterGroup.groups[groupIndex];

        const expressionsLength = group.expressions.length;
        for (let expressionIndex = 0; expressionIndex < expressionsLength; expressionIndex++) {
          const expression = group.expressions[expressionIndex];

          const fieldColumns = this._groupColumns.map(gc => gc.fieldColumns?.find(fc => fc.fieldPath === expression.fieldPath)).filter(i => !mfTypeIsUndefined(i));

          if (!mfTypeIsUndefined(fieldColumns) && fieldColumns.length === 1) {
            const fieldColumn = fieldColumns[0];
            if (!mfTypeIsUndefined(fieldColumn)) {
              fieldColumn.filter?.group.expressions.push(expression);
            }
          }
        }
      }
    }

    this.onFilterLoaded.emit(this._filterGroup);
  }

  /**
   * @protected
   * @description Fills the sort index for all sortable columns in the table.
   */
  protected _fillSortIndex(): void {
    const fieldColumns = this._getFieldColumns();
    if (!mfTypeIsUndefined(fieldColumns)) {
      const columns = mfArraySortByNumberPath(mfArrayFilterUndefinedByPath(fieldColumns, "sort.index"), "sort.index");
      const columnsLength = columns.length;
      for (let columnIndex = 0; columnIndex < columnsLength; columnIndex++) {
        const column = columns[columnIndex];
        if (!mfTypeIsUndefined(column.sort) && !mfTypeIsUndefined(column.sort.direction) && !mfTypeIsUndefined(column.sort.index)) {
          column.sort.index = columnIndex;
        }
      }

      if (columnsLength === 1) {
        fieldColumns.forEach(column => {
          if (!mfTypeIsUndefined(column.sort) && !mfTypeIsUndefined(column.sort.direction)) {
            column.sort.options = [];
          }
        });
      }
    }
  }



  /**
   * @protected
   * @description Reassigns the sort index for a column when the sort order changes.
   * @param {number} previousIndex - The previous sort index.
   * @param {number} currentIndex - The new sort index.
   */
  protected _reassignSortIndex(previousIndex: number, currentIndex: number): void {
    const fieldColumns = this._getFieldColumns();
    if (!mfTypeIsUndefined(fieldColumns)) {
      const currentColumn = fieldColumns.find(i => i.sort?.index === currentIndex);
      if (!mfTypeIsUndefined(currentColumn) && !mfTypeIsUndefined(currentColumn.sort)) {
        currentColumn.sort.index = previousIndex;
      }
    }
  }

  /**
   * @protected
   * @description Updates the sort index for all sortable columns in the table.
   */
  protected _updateSortIndex(): void {
    const fieldColumns = this._getFieldColumns();
    if (!mfTypeIsUndefined(fieldColumns)) {
      const columns = mfArraySortByNumberPath(fieldColumns.filter(i => !mfTypeIsUndefined(i.sort) && !mfTypeIsUndefined(i.sort.direction)), "sort.index");
      const columnsMaxIndex = mfArrayMaxByPath(columns, "sort.index");
      const nextIndex = mfTypeIsUndefined(columnsMaxIndex) ? 0 : columnsMaxIndex + 1;
      const orderOptions: MfSelectOption<number>[] = [];

      const columnsLength = columns.length;
      for (let columnIndex = 0; columnIndex < columnsLength; columnIndex++) {
        const column = columns[columnIndex];
        if (!mfTypeIsUndefined(column.sort) && mfTypeIsUndefined(column.sort.index)) {
          column.sort.index = nextIndex;
        }
        orderOptions.push({ value: columnIndex, label: (columnIndex + 1).toString() });
      }

      if (orderOptions.length > 1) {
        fieldColumns.forEach(column => {
          if (!mfTypeIsUndefined(column.sort) && !mfTypeIsUndefined(column.sort.direction)) {
            column.sort.options = orderOptions;
          }
        });
      } else {
        fieldColumns.forEach(column => {
          if (!mfTypeIsUndefined(column.sort) && !mfTypeIsUndefined(column.sort.direction)) {
            delete column.sort.options;
          }
        });
      }
    }
  }

  /**
   * @protected
   * @description Updates the 'hasFilters' property for each field column based on the current filter group.
   */
  protected _updateFieldColumnHasFilters(): void {
    if (!mfTypeIsUndefined(this._filterGroup)) {
      const fieldColumns = this._getFieldColumns();
      const columnsLength = fieldColumns.length;
      for (let columnIndex = 0; columnIndex < columnsLength; columnIndex++) {
        const column = fieldColumns[columnIndex];
        if (!mfTypeIsUndefined(column.filter)) {
          column.filter.hasFilters = this._filterService.expressionHasFieldPathInGroup(this._filterGroup, column.fieldPath);
        }
      }
    }
  }

  /**
   * @protected
   * @description Sets the model configuration definitions for each column based on the input column model config definitions.
   */
  protected _setColumnsColumnModelConfigDefs(): void {
    const fieldColumns = this._getFieldColumns();
    if (!mfTypeIsUndefined(this._columnModelConfigDef)) {
      const length = fieldColumns.length;
      for (let index = 0; index < length; index++) {
        const fieldColumn = fieldColumns[index];
        const fieldColumnModelConfigDef = this._columnModelConfigDef.find(i => i.fieldPath === fieldColumn.fieldPath);
        if (!mfTypeIsUndefined(fieldColumnModelConfigDef)) {
          fieldColumn.columnModelConfigDef = fieldColumnModelConfigDef;
        }
      }
    }
  }

  /**
   * @protected
   * @description Creates columns for the table based on the provided model field configurations.
   * @param {MfTableFieldColumn[]} [fieldColumns] - The array to populate with created field columns.
   * @param {MfTableGroupColumn[]} [groupColumns] - The array to populate with created group columns.
   * @param {MfModelFieldsConfigMapped} [modelFieldsConfig] - The model fields configuration to use for creating columns.
   * @param {MfModelFieldConfigMapped} [parentModelFieldConfig] - The parent model field configuration.
   */
  protected _createColumns(fieldColumns?: MfTableFieldColumn[], groupColumns?: MfTableGroupColumn[], modelFieldsConfig?: MfModelFieldsConfigMapped, parentModelFieldConfig?: MfModelFieldConfigMapped): void {
    const fieldsConfig = modelFieldsConfig || this.modelConfig?.fields;
    fieldColumns = fieldColumns || [];
    groupColumns = groupColumns || [];

    if (!mfTypeIsUndefined(fieldsConfig)) {
      const fieldKeys = mfTypeGetKeys(fieldsConfig) as string[];
      const fieldKeysLength = fieldKeys.length;
      for (let fieldKeyIndex = 0; fieldKeyIndex < fieldKeysLength; fieldKeyIndex++) {
        const fieldKey = fieldKeys[fieldKeyIndex];
        const modelFieldConfig = this._modelConfigService.getModelFieldConfigByFieldKey(fieldsConfig, fieldKey);
        if (!mfTypeIsUndefined(modelFieldConfig)) {
          if (
            (
              modelFieldConfig.dataType.type === MfModelFieldDataTypes.object ||
              (
                modelFieldConfig.dataType.type === MfModelFieldDataTypes.array &&
                modelFieldConfig.dataType.arrayItemType === MfModelFieldDataTypes.object
              )
            ) && !mfTypeIsUndefined(modelFieldConfig.model) && !mfTypeIsUndefined(modelFieldConfig.model.fields)) {
            this._createColumns(fieldColumns, groupColumns, modelFieldConfig.model.fields, modelFieldConfig);
          }

          if (!mfTypeIsUndefined(modelFieldConfig.table) && (mfTypeIsUndefined(modelFieldConfig.table.hidden) || modelFieldConfig.table.hidden === false)) {
            const fieldColumn = this._getNewFieldColumn(fieldKey, modelFieldConfig);

            if (!mfTypeIsUndefined(this._columnModelConfigDef)) {
              const fieldColumnModelConfigDef = this._columnModelConfigDef.find(i => i.fieldPath === fieldColumn.fieldPath);
              fieldColumn.columnModelConfigDef = fieldColumnModelConfigDef;
            }

            if (!mfTypeIsUndefined(modelFieldConfig.table) && !mfTypeIsUndefined(modelFieldConfig.table.header) && !mfTypeIsUndefined(modelFieldConfig.table.header.group)) {
              const groupColumn = groupColumns.find(i => i.id === modelFieldConfig.table!.header!.group!.id);
              if (mfTypeIsUndefined(groupColumn)) {
                const newGroupColumn = this._getNewGroupColumn(modelFieldConfig.table.header.group);
                newGroupColumn.textAlign = modelFieldConfig.table.header.group.contentAlign;
                newGroupColumn.style = modelFieldConfig.table.header.group.style;
                newGroupColumn.fieldColumns = [fieldColumn];
                fieldColumn.groupColumn = newGroupColumn;
                groupColumns.push(newGroupColumn);
              } else {
                groupColumn.colSpan++;
                fieldColumn.groupColumn = groupColumn;
                groupColumn.fieldColumns!.push(fieldColumn);
              }
            } else {
              const dummyGroupColumn = this._getNewGroupColumn({ id: modelFieldConfig.fieldPath, displayName: "" });
              dummyGroupColumn.fieldColumns = [fieldColumn];
              fieldColumn.groupColumn = dummyGroupColumn;
              groupColumns.push(dummyGroupColumn);
            }

            fieldColumns.push(fieldColumn);
          }
        }
      }

      if (mfTypeIsUndefined(parentModelFieldConfig)) {
        this._groupColumns.push(...this._orderGroupColumnsAndFieldColumns(groupColumns));
        this._hasGroupedColumns = this._groupColumns.some(i => i.colSpan > 1);

        this.onFieldColumnsLoaded.emit(this._getFieldColumns());
      }
    }

    this._updateGroupColumnOrderAndVisible();
  }

  /**
   * @protected
   * @description Orders group columns and their field columns according to their index or label.
   * @param {MfTableGroupColumn[]} groupColumns - The group columns to order.
   * @returns {MfTableGroupColumn[]} The ordered group columns.
   */
  protected _orderGroupColumnsAndFieldColumns(groupColumns: MfTableGroupColumn[]): MfTableGroupColumn[] {
    const groupsWithIndex = groupColumns.filter(group => !mfTypeIsUndefined(group.fieldColumns) && !mfTypeIsUndefined(group.fieldColumns[0].modelFieldConfig?.table?.index));
    const groupsWithoutIndex = groupColumns.filter(group => !mfTypeIsUndefined(group.fieldColumns) && mfTypeIsUndefined(group.fieldColumns[0].modelFieldConfig?.table?.index));

    groupsWithoutIndex.sort((groupA, groupB) => {
      if (groupA.fieldColumns && groupB.fieldColumns && groupA.fieldColumns.length > 0 && groupB.fieldColumns.length > 0) {
        return groupA.fieldColumns[0].label.localeCompare(groupB.fieldColumns[0].label);
      } else {
        return groupA.label.localeCompare(groupB.label);
      }
    });

    groupsWithIndex.sort((groupA, groupB) => (groupA.fieldColumns![0].modelFieldConfig!.table!.index! - groupB.fieldColumns![0].modelFieldConfig!.table!.index!));

    const sortedGroups: MfTableGroupColumn[] = [];

    for (const group of groupsWithIndex) {
      sortedGroups[group.fieldColumns![0].modelFieldConfig!.table!.index!] = group;
    }

    let currentIndex = 0;

    for (const group of groupsWithoutIndex) {
      while (!mfTypeIsUndefined(sortedGroups[currentIndex])) {
        currentIndex++;
      }
      sortedGroups[currentIndex] = group;
    }

    for (let index = 0; index < sortedGroups.length; index++) {
      if (mfTypeIsUndefined(sortedGroups[index])) {
        sortedGroups.splice(index, 1);
        index--;
      }
    }

    sortedGroups.forEach((groupColumn) => {
      if (!mfTypeIsUndefined(groupColumn.fieldColumns)) {
        groupColumn.fieldColumns = this._orderFieldColumns(groupColumn.fieldColumns);
      }
    });

    return sortedGroups;
  }

  /**
   * @protected
   * @description Orders field columns within a group according to their index or label.
   * @param {MfTableFieldColumn[]} fieldColumns - The field columns to order.
   * @returns {MfTableFieldColumn[]} The ordered field columns.
   */
  protected _orderFieldColumns(fieldColumns: MfTableFieldColumn[]): MfTableFieldColumn[] {
    const fieldsWithIndex = fieldColumns.filter(field => !mfTypeIsUndefined(field.modelFieldConfig?.table?.index));
    const fieldsWithoutIndex = fieldColumns.filter(field => mfTypeIsUndefined(field.modelFieldConfig?.table?.index));

    fieldsWithoutIndex.sort((a, b) => {
      if (a.groupColumn && a.groupColumn.fieldColumns && a.groupColumn.fieldColumns.length > 1 && b.groupColumn && b.groupColumn.fieldColumns && b.groupColumn.fieldColumns.length > 1) {
        return a.groupColumn.label.localeCompare(b.groupColumn.label);
      } else {
        return a.label.localeCompare(b.label);
      }
    });

    fieldsWithIndex.sort((a, b) => (a.modelFieldConfig!.table!.index! - b.modelFieldConfig!.table!.index!));

    const sortedFields: MfTableFieldColumn[] = [];

    for (const field of fieldsWithIndex) {
      sortedFields[field.modelFieldConfig!.table!.index!] = field;
    }

    let currentIndex = 0;

    for (const field of fieldsWithoutIndex) {
      while (!mfTypeIsUndefined(sortedFields[currentIndex])) {
        currentIndex++;
      }
      sortedFields[currentIndex] = field;
    }

    for (let index = 0; index < sortedFields.length; index++) {
      if (mfTypeIsUndefined(sortedFields[index])) {
        sortedFields.splice(index, 1);
        index--;
      }
    }

    return sortedFields;
  }

  /**
   * @protected
   * @description Sets the display columns for the table, including custom and group columns.
   */
  protected _setDisplayColumns(): void {
    this._setFieldDisplayColumns();
    this._setGroupDisplayColumns();
    this._setCustomDisplayColumns();
    this._updateDisplayColumns();
  }

  /**
   * @protected
   * @description Updates the display columns with the latest configuration.
   */
  protected _updateDisplayColumns(): void {
    setTimeout(() => {
      this._expandColumns = this._expandColumnsUpdate;
      this._fieldDisplayColumns = this._fieldDisplayColumnsUpdate;
      this._groupDisplayColumns = this._groupDisplayColumnsUpdate;
      this._fieldInfoDisplayColumns = this._fieldInfoDisplayColumnsUpdate;
    }, 1);
  }

  /**
   * @protected
   * @description Sets the display columns for the fields in the table.
   */
  protected _setFieldDisplayColumns(): void {
    const fieldColumns = this._getFieldColumns();
    this._fieldDisplayColumnsUpdate = fieldColumns.filter(column => mfTableColumnVisible(column)).map(column => column.fieldPath);
    this._fieldInfoDisplayColumnsUpdate = this._fieldDisplayColumnsUpdate.map(i => `${INFO_NAME_PREFIX}${i}`);
  }

  /**
   * @protected
   * @description Sets the display columns for the group columns in the table.
   */
  protected _setGroupDisplayColumns(): void {
    if (this._hasGroupedColumns === true) {
      this._groupDisplayColumnsUpdate = this._groupColumns.filter(column => mfTableColumnVisible(column)).map(column => column.groupPath);
    } else {
      this._groupDisplayColumnsUpdate = [];
    }
  }

  /**
   * @protected
   * @description Adds a custom display column to the beginning of the specified column array.
   * @param {string[]} to - The array of column names to modify.
   * @param {string} name - The name of the column to add.
   */
  protected _spliceCustomDisplayColumn(to: string[], name: string): void {
    if (!to.some(i => i === name)) {
      to.splice(0, 0, name);
    }
  }

  /**
   * @protected
   * @description Adds a custom display column to the end of the specified column array.
   * @param {string[]} to - The array of column names to modify.
   * @param {string} name - The name of the column to add.
   */
  protected _pushCustomDisplayColumn(to: string[], name: string): void {
    if (!to.some(i => i === name)) {
      to.push(name);
    }
  }

  /**
   * @protected
   * @description Sets the custom display columns for the table, such as selection and expansion columns.
   */
  protected _setCustomDisplayColumns(): void {
    if (this.selectionManagerEnabled === true) {
      this._spliceCustomDisplayColumn(this._fieldDisplayColumnsUpdate, `${FIELD_NAME_PREFIX}${SELECTION_MANAGER_POSTFIX}`);
      this._spliceCustomDisplayColumn(this._groupDisplayColumnsUpdate, `${GROUP_NAME_PREFIX}${SELECTION_MANAGER_POSTFIX}`);
      this._spliceCustomDisplayColumn(this._fieldInfoDisplayColumnsUpdate, `${INFO_NAME_PREFIX}${SELECTION_MANAGER_POSTFIX}`);
    }

    if (!mfTypeIsUndefined(this._columnExpandDef)) {
      this._pushCustomDisplayColumn(this._expandColumnsUpdate, EXPAND_NAME_PREFIX + EXPANDED_CONTENT_POSTFIX);
      this._pushCustomDisplayColumn(this._fieldDisplayColumnsUpdate, `${FIELD_NAME_PREFIX}${EXPANDED_CONTENT_POSTFIX}`);
      this._pushCustomDisplayColumn(this._groupDisplayColumnsUpdate, `${GROUP_NAME_PREFIX}${EXPANDED_CONTENT_POSTFIX}`);
      this._pushCustomDisplayColumn(this._fieldInfoDisplayColumnsUpdate, `${INFO_NAME_PREFIX}${EXPANDED_CONTENT_POSTFIX}`);
    }

    if (!mfTypeIsUndefined(this._columnSlideOutActionsDef)) {
      this._pushCustomDisplayColumn(this._fieldDisplayColumnsUpdate, `${FIELD_NAME_PREFIX}${SIDLE_OUT_ACTION_POSTFIX}`);
      this._pushCustomDisplayColumn(this._groupDisplayColumnsUpdate, `${GROUP_NAME_PREFIX}${SIDLE_OUT_ACTION_POSTFIX}`);
      this._pushCustomDisplayColumn(this._fieldInfoDisplayColumnsUpdate, `${INFO_NAME_PREFIX}${SIDLE_OUT_ACTION_POSTFIX}`);
    }

    if (this._hasRowClickAction) {
      this._pushCustomDisplayColumn(this._fieldDisplayColumnsUpdate, `${FIELD_NAME_PREFIX}${ROW_CLICK_ACTION_POSTFIX}`);
      this._pushCustomDisplayColumn(this._groupDisplayColumnsUpdate, `${GROUP_NAME_PREFIX}${ROW_CLICK_ACTION_POSTFIX}`);
      this._pushCustomDisplayColumn(this._fieldInfoDisplayColumnsUpdate, `${INFO_NAME_PREFIX}${ROW_CLICK_ACTION_POSTFIX}`);
    }

    if (!mfTypeIsUndefined(this._columnDefQueryList)) {
      this._columnDefQueryList.forEach(columnDef => {
        if (this.displayColumns.some(i => i === columnDef.name)) {
          if (columnDef.side === "left") {
            this._spliceCustomDisplayColumn(this._fieldDisplayColumnsUpdate, `${FIELD_NAME_PREFIX}${columnDef.name}`);
            this._spliceCustomDisplayColumn(this._groupDisplayColumnsUpdate, `${GROUP_NAME_PREFIX}${columnDef.name}`);
            this._spliceCustomDisplayColumn(this._fieldInfoDisplayColumnsUpdate, `${INFO_NAME_PREFIX}${columnDef.name}`);
          } else {
            this._pushCustomDisplayColumn(this._fieldDisplayColumnsUpdate, `${FIELD_NAME_PREFIX}${columnDef.name}`);
            this._pushCustomDisplayColumn(this._groupDisplayColumnsUpdate, `${GROUP_NAME_PREFIX}${columnDef.name}`);
            this._pushCustomDisplayColumn(this._fieldInfoDisplayColumnsUpdate, `${INFO_NAME_PREFIX}${columnDef.name}`);
          }
        }
      });
    }
  }

  /**
   * @protected
   * @description Sets the column definitions based on the provided list of column definitions.
   */
  protected _setColumnDefs(): void {
    if (!mfTypeIsUndefined(this._columnDefQueryList)) {
      this._columnDefs = this._columnDefQueryList.filter(columnDef => this.displayColumns.some(i => i === columnDef.name)).map(columnDef => columnDef);
    }
  }

  /**
   * @protected
   * @description Creates a new group column based on the provided configuration.
   * @param {MfTableRowHeaderGroupModelConfig} group - The group model configuration.
   * @returns {MfTableGroupColumn} The created group column.
   */
  protected _getNewGroupColumn(group: MfTableRowHeaderGroupModelConfig): MfTableGroupColumn {
    const column = {
      id: group.id,
      groupPath: `${GROUP_NAME_PREFIX}${group.id}`,
      label: group.displayName,
      colSpan: 0,
      fieldColumns: [],
    };
    return column;
  }

  /**
   * @protected
   * @description Creates a new field column based on the provided field key and model field configuration.
   * @param {string} fieldKey - The key of the field.
   * @param {MfModelFieldConfigMapped} modelFieldConfig - The configuration for the model field.
   * @returns {MfTableFieldColumn} The created field column.
   */
  protected _getNewFieldColumn(fieldKey: string, modelFieldConfig: MfModelFieldConfigMapped): MfTableFieldColumn {
    const column = {
      label: mfTypeIsUndefined(modelFieldConfig.display) ? fieldKey : modelFieldConfig.display.displayName,
      modelFieldConfig: modelFieldConfig,
      fieldKey: fieldKey,
      fieldPath: modelFieldConfig.fieldPath!,
      menu: { isOpen: false },
      filter: !mfTypeIsUndefined(modelFieldConfig?.filter) ? {
        group: this._filterService.getNewFilterGroup(),
        hasFilters: false,
      } : undefined,
      sort: mfTableHeaderCanSort(modelFieldConfig) ? {
        options: [],
      } : undefined,
      index: modelFieldConfig.table?.index,
      width: {
        width: mfObjectPropertyPathIsUndefined("table.cell.width", modelFieldConfig) ? this._config.row.header.resize.minWidth : modelFieldConfig.table!.cell!.width!,
        hasAdjustment: mfObjectPropertyPathIsUndefined("table.header.hasWidthAdjustment", modelFieldConfig) || modelFieldConfig.table?.header?.hasWidthAdjustment === true ? true : false,
      },
      visible: mfTableGetVisible(modelFieldConfig),
    };
    return column;
  }

  /**
   * @protected
   * @description Sets the default group filter for the table.
   */
  protected _setDefaultGroupFilter(): void {
    this._setFilterGroup(this._filterService.getNewFilterGroup());
  }

  /**
   * @protected
   * @description Updates the visibility and colspan of a group column based on its field columns.
   * @param {MfTableFieldColumn} fieldColumn - The field column that may have changed visibility.
   */
  protected _updateGroupColumnVisibleFromFieldColumn(fieldColumn: MfTableFieldColumn): void {
    if (!mfTypeIsUndefined(fieldColumn.groupColumn)) {
      this._updateGroupColumnVisibleAndColSpan(fieldColumn.groupColumn);
    }
  }

  /**
   * @protected
   * @description Updates the visibility and colspan of a group column based on the visibility of its field columns.
   * @param {MfTableGroupColumn} groupColumn - The group column to update.
   */
  protected _updateGroupColumnVisibleAndColSpan(groupColumn: MfTableGroupColumn): void {
    if (!mfTypeIsUndefined(groupColumn.fieldColumns)) {
      const visibleCount = mfArrayCount(groupColumn.fieldColumns, (item) => mfTableColumnVisible(item));
      if (visibleCount > 0) {
        groupColumn.colSpan = visibleCount;
      }

      groupColumn.visible = (visibleCount !== 0);

      groupColumn.sticky = groupColumn.fieldColumns.some(i => i.sticky === true) ? true : undefined;
      groupColumn.stickyEnd = groupColumn.fieldColumns.some(i => i.stickyEnd === true) ? true : undefined;
    }
  }

  /**
   * @protected
   * @description Updates the order and visibility of group columns in the table.
   */
  protected _updateGroupColumnOrderAndVisible(): void {
    const fieldColumns = this._getFieldColumns();

    this._groupColumns = this._groupColumns.sort((groupColumnA, groupColumnB) => {
      return fieldColumns.findIndex(fieldColumn => fieldColumn === groupColumnA.fieldColumns![0]) -
        fieldColumns.findIndex(fieldColumn => fieldColumn === groupColumnB.fieldColumns![0]);
    });

    const groupFieldsLength = this._groupColumns.length;
    for (let groupFieldIndex = 0; groupFieldIndex < groupFieldsLength; groupFieldIndex++) {
      this._updateGroupColumnVisibleAndColSpan(this._groupColumns[groupFieldIndex]);
    }
  }
}
