<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
        <ng-template #contentHost></ng-template>
        <div [innerHTML]="data.messageInnerHTML | mfSafeHtml"></div>
</div>
<div mat-dialog-actions>
        <button *ngIf="_isUndefined(data.cancelShow) || data.cancelShow === true"
                mat-button
                mat-dialog-close
                cdkFocusInitial
                (click)="_onCancelClicked()">{{ data.cancelDisplayName }}</button>
        <div class="mf-flex-fill"></div>
        <button *ngIf="_isUndefined(data.okShow) || data.okShow === true"
                [disabled]="!_isValid"
                mat-button
                mat-dialog-close
                (click)="_onOkClicked()">{{ data.okDisplayName }}</button>
</div>