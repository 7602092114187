import { EventEmitter, Injectable, Injector } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfArrayMaxByPath } from "@material-framework/common/utils/array.utils";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfLocalStorageService } from "@material-framework/storage/local.storage.service";
import { MfSessionStorageService } from "@material-framework/storage/session.storage.service";
import { MfStorageService } from "@material-framework/storage/storage";
import { MfViewState, MfViewStateData } from "@material-framework/viewManager/view.manager";
import { MfLocationKey } from "@material-framework/viewManager/view.manager.component";
import { MfViewManagerStorageService } from "@material-framework/viewManager/view.manager.storage.service";
import { Observable, of } from "rxjs";


const TYPE_INFO_LOCAL: MfTypeInfo = { className: "MfViewManagerLocalStorageService" };
const TYPE_INFO_SESSION: MfTypeInfo = { className: "MfViewManagerLocalStorageService" };

export const MF_VIEW_MANAGER_LOCAL_STORAGE_KEY = "mf_view_manager_local_storage";
let viewManagerLocalStorageServiceNextId = 1;


export abstract class MfViewManagerBaseStorageService extends MfViewManagerStorageService<MfViewStateData> {
  public onViewStateUpdated: EventEmitter<MfViewState<MfViewStateData>> = new EventEmitter();
  public onViewStateNeedsCaching: EventEmitter<MfLocationKey> = new EventEmitter();

  protected _storeMemory?: MfViewState<MfViewStateData>[];

  public constructor(
    protected override _typeInfo: MfTypeInfo,
    protected override _injector: Injector,
    protected _storageService: MfStorageService,
  ) {
    super(_typeInfo, _injector);
  }

  public get<TData extends MfViewStateData>(locationKey: string): Observable<MfViewState<TData>[]> {
    return of(this._store.filter(i => i.locationKey === locationKey) as MfViewState<TData>[]);
  }

  public setAsDefault(locationKey: string, id: number): Observable<boolean> {
    const viewState = this._store.find(i => i.locationKey === locationKey && i.id === id);
    if (!mfTypeIsUndefined(viewState)) {
      this._store.filter(i => i.locationKey === viewState.locationKey).forEach(i => i.isDefault = false);
      viewState.isDefault = true;
      this._saveStore();
      return of(true);
    }
    return of(false);
  }

  public updateCache<TData extends MfViewStateData>(locationKey: string, viewState: MfViewState<TData>): Observable<MfViewState<TData> | undefined> {
    if (viewState.isDefaultViewState === true) {
      viewState.isDefaultViewStateCached = true;
    }
    return this.update(locationKey, viewState);
  }

  public update<TData extends MfViewStateData>(locationKey: string, viewState: MfViewState<TData>): Observable<MfViewState<TData> | undefined> {
    const index = this._store.findIndex(i => i.locationKey === locationKey && i.locationKey === viewState.locationKey && i.id === viewState.id);
    if (index != -1) {
      this._store[index] = viewState;
      this._saveStore();
      this.onViewStateUpdated.emit(viewState);
      return of(viewState);
    } else {
      return this.add(locationKey, viewState);
    }
  }

  public add<TData extends MfViewStateData>(locationKey: string, viewState: MfViewState<TData>): Observable<MfViewState<TData> | undefined> {
    if (mfTypeIsUndefined(viewState.id)) {
      viewState.id = viewManagerLocalStorageServiceNextId;
      viewManagerLocalStorageServiceNextId++;
    }
    this._store.push(viewState);
    this._saveStore();
    return of(viewState);
  }

  public delete(locationKey: string, id: number | undefined): Observable<boolean> {
    const index = this._store.findIndex(i => i.locationKey === locationKey && i.id === id);
    if (index != -1) {
      this._store.splice(index, 1);
      this._saveStore();
      return of(true);
    }
    return of(false);
  }

  protected get _store(): MfViewState<MfViewStateData>[] {
    if (mfTypeIsUndefined(this._storeMemory)) {
      this._storeMemory = this._storageService.get<MfViewState<MfViewStateData>[]>(MF_VIEW_MANAGER_LOCAL_STORAGE_KEY);
      if (mfTypeIsUndefined(this._storeMemory)) {
        this._storeMemory = [];
      }
      const max = mfArrayMaxByPath(this._storeMemory, "id");
      if (!mfTypeIsUndefined(max)) {
        viewManagerLocalStorageServiceNextId = (max + 1);
      }
    }
    return this._storeMemory;
  }

  protected _saveStore() {
    if (!mfTypeIsUndefined(this._storeMemory)) {
      this._storageService.addUpdate(MF_VIEW_MANAGER_LOCAL_STORAGE_KEY, this._storeMemory);
      delete this._storeMemory;
    }
  }
}


@Injectable()
export class MfViewManagerLocalStorageService extends MfViewManagerBaseStorageService {
  public constructor(
    protected override _injector: Injector,
    protected override _storageService: MfLocalStorageService,
  ) {
    super(TYPE_INFO_LOCAL, _injector, _storageService);
  }
}

@Injectable()
export class MfViewManagerSessionStorageService extends MfViewManagerBaseStorageService {
  public constructor(
    protected override _injector: Injector,
    protected override _storageService: MfSessionStorageService,
  ) {
    super(TYPE_INFO_SESSION, _injector, _storageService);
  }
}