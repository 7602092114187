import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ecomCartGetDefaultQty, ecomCartGetDefaultRequiredDateMidday } from "@e-commerce/cart/cart";
import { EComCartModes, EComCartPaymentTypes } from "@e-commerce/common/cart.modes";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComAddCartItemRequest, EComAddCartItemsRequest, EComCartActionResult, EComCartDeleteMultipleItemsRequest, EComCartUpdateMultipleItemsRequest, EComCreateCartRequest, EComUpdateCartItemRequest } from "@e-commerce/modelConfigs/cart.line.model.config";
import { EComCartValidationOrderModel, EComCheckoutOrderRequestModel } from "@e-commerce/modelConfigs/checkout.order.model.config";
import { EComCheckoutOrderBeginPaymentModel, EComCheckoutOrderBeginPaymentRequestModel, EComCheckoutOrderEndPaymentModel, EComCheckoutOrderEndPaymentRequestModel } from "@e-commerce/modelConfigs/checkout.order.payment.model.config";
import { EComCompleteOrderModel } from "@e-commerce/modelConfigs/complete.order.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComCartBeginPaymentAction, EComCartBeginPaymentOrderAction, EComCartCompleteAction, EComCartCompleteOrderAction, EComCartEndPaymentOrderAction, EComCartItemActionResult, EComCartItemAddActions, EComCartItemAddOrderAction, EComCartItemRemoveActions, EComCartItemRemoveOrderAction, EComCartItemUpdateActions, EComCartItemUpdateOrderAction, EComCartItemUpdateRequiredDateOrderAction } from "@e-commerce/services/cart.actions";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfError } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsArray, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfPortalsClientService } from "@material-framework/portals/portals.client.service";
import { catchError, concatMap, map, Observable, of, share, Subject, tap } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComCartService" };

@Injectable({ providedIn: "root" })
export class EComCartService extends MfBaseService {
  public onCartModeChanged: Subject<EComCartModes> = new Subject();
  public onCountChanged: Subject<number> = new Subject();
  public onItemAdded: Subject<EComCartItemAddActions> = new Subject();
  public onItemRemoved: Subject<EComCartItemRemoveActions> = new Subject();
  public onItemUpdated: Subject<EComCartItemUpdateActions> = new Subject();

  protected _addCartItemRequestModelConfig: MfModelConfigMapped;
  protected _cartActionResultModelConfig: MfModelConfigMapped;
  protected _cartDeleteMultipleItemsRequestModelConfig: MfModelConfigMapped;
  protected _cartValidationOrderModelConfig: MfModelConfigMapped;
  protected _checkoutOrderBeginPaymentModelConfig: MfModelConfigMapped;
  protected _checkoutOrderBeginPaymentResponseModelConfig: MfModelConfigMapped;
  protected _checkoutOrderEndPaymentModelConfig: MfModelConfigMapped;
  protected _checkoutOrderRequestModel: MfModelConfigMapped;
  protected _completeOrderModelConfig: MfModelConfigMapped;
  protected _checkoutOrderEndPaymentRequestModelConfig: MfModelConfigMapped;
  protected _updateCartItemRequestModelConfig: MfModelConfigMapped;
  protected _cartUpdateMultipleItemsRequestModelConfig: MfModelConfigMapped;
  protected _addCartItemsRequestModelConfig: MfModelConfigMapped;
  protected _count?: number;
  protected _count$?: Observable<number>;
  protected _create$?: Observable<string>;
  protected _createCartRequestModelConfig: MfModelConfigMapped;
  protected _idInt?: string;
  protected _mode: EComCartModes = EComCartModes.order;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    protected _customerService: EComCustomerService,
    @Inject(DOCUMENT)
    protected _document: Document,
  ) {
    super(TYPE_INFO, _injector);
    this._addCartItemRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("addCartItemRequest");
    this._cartActionResultModelConfig = this._modelConfigService.get<EComModelsConfig>("cartActionResult");
    this._cartDeleteMultipleItemsRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("cartDeleteMultipleItemsRequest");
    this._cartValidationOrderModelConfig = this._modelConfigService.get<EComModelsConfig>("cartValidationOrder");
    this._checkoutOrderBeginPaymentModelConfig = this._modelConfigService.get<EComModelsConfig>("checkoutOrderBeginPayment");
    this._checkoutOrderBeginPaymentResponseModelConfig = this._modelConfigService.get<EComModelsConfig>("checkoutOrderBeginPaymentRequest");
    this._checkoutOrderEndPaymentModelConfig = this._modelConfigService.get<EComModelsConfig>("checkoutOrderEndPayment");
    this._checkoutOrderRequestModel = this._modelConfigService.get<EComModelsConfig>("checkoutOrderRequest");
    this._completeOrderModelConfig = this._modelConfigService.get<EComModelsConfig>("completeOrder");
    this._createCartRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("createCartRequest");
    this._checkoutOrderEndPaymentRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("checkoutOrderEndPaymentRequest");
    this._updateCartItemRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("updateCartItemRequest");
    this._cartUpdateMultipleItemsRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("cartUpdateMultipleItemsRequest");
    this._addCartItemsRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("addCartItemsRequest");

    this._sub(this._customerService.selected, {
      next: () => {
        this._sub(this._customerService.onSelectedChange, { next: () => this._onSelectedCustomerChange() });
      }
    });
  }

  public get mode(): EComCartModes {
    return this._mode;
  }

  public setMode(mode: EComCartModes): void {
    if (this._mode !== mode) {
      this._clearnCartCache();
      this._mode = mode;
      this.onCartModeChanged.next(this._mode);
    }
  }

  public get count(): Observable<number> {
    if (this._mode === EComCartModes.invoice) {
      //TODO: just hack to fix invoice cart
      return of(0);
    } else {
      return this._getCountOrder();
    }
  }

  public get id(): Observable<string> {
    return of(this._idInt).pipe(
      concatMap((id) => {
        if (!mfTypeIsUndefined(id)) {
          return of(id);
        } else {
          return this._getCreateCartOrder();
        }
      }),
      share(),
    );
  }

  public checkout(): Observable<EComCartValidationOrderModel> {
    return this._checkoutOrder();
  }

  public complete(action: EComCartCompleteAction): Observable<boolean> {
    return this._completeOrder(action).pipe(
      map((result) => {
        this._redirectIfValidationErrors(result);
        return true;
      })
    );
  }

  public beginPayment(action: EComCartBeginPaymentAction): Observable<EComCheckoutOrderEndPaymentModel> {
    return this._beginPaymentOrder(action).pipe(
      map((beginPaymentResult) => {
        if (!mfTypeIsUndefined(beginPaymentResult.paymentUrl) && action.paymentMethod === EComCartPaymentTypes.creditCard) {
          this._document.location.href = beginPaymentResult.paymentUrl;
          return true;
        } else if (action.paymentMethod === EComCartPaymentTypes.account) {
          return true;
        } else {
          throw new MfError(this._typeInfo, "complete", "Unabel to determin payment routing");
        }
      })
    );
  }

  public endPayment(action: EComCartEndPaymentOrderAction): Observable<boolean> {
    return this._endPaymentOrder(action).pipe(
      map((endPaymentResult) => {
        this._router.navigate([EcomRouteNames.routeShopCheckoutOrderReview], {
          queryParams: { [EcomRouteNames.queryParamNameLockNavigation]: true }
        }
        );
        return endPaymentResult;
      })
    );
  }

  public add(actions: EComCartItemAddActions): Observable<EComCartItemActionResult> {
    let add$: Observable<EComCartItemActionResult>;

    if (mfTypeIsArray(actions)) {
      add$ = this._addItemsOrder(actions);
    } else {
      add$ = this._addItemOrder(actions);
    }

    return add$.pipe(
      tap((result) => {
        this.onItemAdded.next(actions);
        this._redirectIfValidationErrors(result);
      })
    );
  }

  public remove(actions: EComCartItemRemoveActions): Observable<EComCartItemActionResult> {
    let remove$: Observable<EComCartItemActionResult>;

    if (mfTypeIsArray(actions)) {
      remove$ = this._removeItemsOrder(actions);
    } else {
      remove$ = this._removeItemOrder(actions);
    }

    return remove$.pipe(
      tap((result) => {
        this.onItemRemoved.next(actions);
        this._redirectIfValidationErrors(result);
      })
    );
  }

  public update(actions: EComCartItemUpdateActions): Observable<EComCartItemActionResult> {
    let update$: Observable<EComCartItemActionResult>;

    if (mfTypeIsArray(actions)) {
      update$ = this._updateItemsOrder(actions);
    } else {
      update$ = this._updateItemOrder(actions);
    }

    return update$.pipe(
      tap((result) => {
        this.onItemUpdated.next(actions);
        this._redirectIfValidationErrors(result);
      })
    );
  }

  public hasValidationErrors(result: EComCartItemActionResult): boolean {
    return !mfTypeIsUndefined(result.items) && result.items.length > 0 || !mfTypeIsUndefined(result.errors) && result.errors.length > 0;
  }

  protected _onSelectedCustomerChange(): void {
    this._clearnCartCache();
    this.onCountChanged.next(0);
  }

  protected _clearnCartCache(): void {
    delete this._idInt;
    delete this._count$;
    delete this._create$;
    delete this._count;
  }

  protected _checkoutOrder(): Observable<EComCartValidationOrderModel> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComCheckoutOrderRequestModel = {
              key: selectedCustomer.key,
              cartKey: cartKey,
            };

            return this._portalsClientService.getItemPost<EComCartValidationOrderModel, EComCheckoutOrderRequestModel>(
              this._cartValidationOrderModelConfig,
              this._checkoutOrderRequestModel,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/checkout`)
              .pipe(
                map((checkoutResponse) => {
                  return checkoutResponse;
                })
              );
          })
        );
      })
    );
  }

  protected _completeOrder(checkout: EComCartCompleteOrderAction): Observable<EComCartValidationOrderModel> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComCompleteOrderModel = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              carrierKey: checkout.carrierKey,
              paymentMethod: checkout.paymentMethod,
              purchaseOrderRef: checkout.purchaseOrderRef,
            };

            return this._portalsClientService.getItemPost<EComCartValidationOrderModel, EComCompleteOrderModel>(
              this._cartValidationOrderModelConfig,
              this._completeOrderModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/complete`)
              .pipe(
                map((cartResponse) => {
                  this._clearnCartCache();
                  this.onCountChanged.next(0);
                  return cartResponse;
                })
              );
          })
        );
      })
    );
  }

  protected _beginPaymentOrder(action: EComCartBeginPaymentOrderAction): Observable<EComCheckoutOrderBeginPaymentModel> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComCheckoutOrderBeginPaymentRequestModel = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              carrierKey: action.carrierKey,
              purchaseOrderRef: action.purchaseOrderRef,
              paymentMethod: action.paymentMethod,
            };

            return this._portalsClientService.getItemPost<EComCheckoutOrderBeginPaymentModel, EComCheckoutOrderBeginPaymentRequestModel>(
              this._checkoutOrderBeginPaymentModelConfig,
              this._checkoutOrderBeginPaymentResponseModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/beginPayment`
            );
          })
        );
      })
    );
  }

  protected _endPaymentOrder(action: EComCartEndPaymentOrderAction): Observable<boolean> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComCheckoutOrderEndPaymentRequestModel = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              paymentInfo: action.paymentInfo,
            };

            return this._portalsClientService.post<EComCheckoutOrderEndPaymentRequestModel>(
              this._checkoutOrderEndPaymentRequestModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/endPayment`
            );
          })
        );
      })
    );
  }

  protected _addItemOrder(action: EComCartItemAddOrderAction): Observable<EComCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        const data: EComAddCartItemRequest = {
          key: selectedCustomer.key,
          itemSpecKey: action.itemSpecKey,
          quantity: action.quantity || ecomCartGetDefaultQty(),
          requiredDate: action.requiredDate || ecomCartGetDefaultRequiredDateMidday()
        };

        return this._portalsClientService.getItemPost<EComCartActionResult, EComAddCartItemRequest>(
          this._cartActionResultModelConfig,
          this._addCartItemRequestModelConfig,
          data,
          `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/add`)
          .pipe(
            map((cartResponse) => {
              this._count = cartResponse.itemCount;
              this.onCountChanged.next(this._count);
              return cartResponse;
            })
          );
      }),
    );
  }

  protected _addItemsOrder(actions: EComCartItemAddOrderAction[]): Observable<EComCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        const data: EComAddCartItemsRequest = {
          key: selectedCustomer.key,
          items: actions.map(action => ({
            itemSpecKey: action.itemSpecKey,
            quantity: action.quantity || ecomCartGetDefaultQty(),
            requiredDate: action.requiredDate || ecomCartGetDefaultRequiredDateMidday()
          })),
        };

        return this._portalsClientService.updateItemPost<EComCartActionResult, EComAddCartItemsRequest>(
          this._cartActionResultModelConfig,
          this._addCartItemsRequestModelConfig,
          data,
          `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/addmulti`)
          .pipe(map(
            (cartResponse) => {
              this._count = cartResponse.itemCount;
              this.onCountChanged.next(this._count);
              return cartResponse;
            }
          ));
      }),
    );
  }

  protected _updateItemOrder(action: EComCartItemUpdateOrderAction): Observable<EComCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComUpdateCartItemRequest = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              cartItemKey: action.cartItemKey,
              newQuantity: action.newQuantity,
              quantity: action.quantity,
              requiredDate: action.requiredDate || ecomCartGetDefaultRequiredDateMidday()
            };

            return this._portalsClientService.updateItemPut<EComCartActionResult, EComUpdateCartItemRequest>(
              this._cartActionResultModelConfig,
              this._updateCartItemRequestModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/${ECOM_ENVIRONMENT.portalsLinesUrl}`)
              .pipe(map(
                (cartResponse) => {
                  this._count = cartResponse.itemCount;
                  this.onCountChanged.next(this._count);
                  return cartResponse;
                }
              ));
          })
        );
      }),
    );
  }

  protected _updateItemsOrder(actions: EComCartItemUpdateRequiredDateOrderAction[]): Observable<EComCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComCartUpdateMultipleItemsRequest = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              cartItemKeys: actions.map(i => i.cartItemKey),
              requiredDate: actions[0].requiredDate,
            };

            return this._portalsClientService.updateItemPut<EComCartActionResult, EComCartUpdateMultipleItemsRequest>(
              this._cartActionResultModelConfig,
              this._cartUpdateMultipleItemsRequestModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/${ECOM_ENVIRONMENT.portalsLinesUrl}/requireddate`)
              .pipe(map(
                (cartResponse) => {
                  this._count = cartResponse.itemCount;
                  this.onCountChanged.next(this._count);
                  return cartResponse;
                }
              ));
          })
        );
      }),
    );
  }


  protected _removeItemOrder(action: EComCartItemRemoveOrderAction): Observable<EComCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            return this._portalsClientService.deleteItemDelete<EComCartActionResult>(
              this._cartActionResultModelConfig,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${selectedCustomer.key}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/${cartKey}//${ECOM_ENVIRONMENT.portalsLinesUrl}/${action.cartItemKey}`)
              .pipe(map(
                (cartResponse) => {
                  this._count = cartResponse.itemCount;
                  this.onCountChanged.next(this._count);
                  return cartResponse;
                }
              ));
          }),
        );
      }),
    );
  }

  protected _removeItemsOrder(action: EComCartItemRemoveOrderAction[]): Observable<EComCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComCartDeleteMultipleItemsRequest = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              cartItemKeys: action.map(i => i.cartItemKey)
            };
            return this._portalsClientService.getItemPost<EComCartActionResult, EComCartDeleteMultipleItemsRequest>(
              this._cartActionResultModelConfig,
              this._cartDeleteMultipleItemsRequestModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/${ECOM_ENVIRONMENT.portalsLinesUrl}/delete`)
              .pipe(
                map((cartResponse) => {
                  this._count = cartResponse.itemCount;
                  this.onCountChanged.next(this._count);
                  return cartResponse;
                })
              );
          })
        );
      }),
    );
  }

  protected _getCountOrder(): Observable<number> {
    if (!mfTypeIsUndefined(this._count)) {
      return of(this._count);
    } else {
      if (mfTypeIsUndefined(this._count$)) {
        this._count$ = this.id.pipe(
          concatMap((id) => {
            return this._portalsClientService.getItemGet<EComCartActionResult>(
              this._cartActionResultModelConfig,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/${id}`)
              .pipe(
                map((cartCountResponse) => {
                  this._count = cartCountResponse.itemCount;
                  return this._count;
                })
              );
          }),
          share(),
        );
      }
      return this._count$;
    }
  }


  protected _getCreateCartOrder(): Observable<string> {
    if (mfTypeIsUndefined(this._create$)) {
      this._create$ = this._customerService.selected.pipe(
        concatMap((selectedCustomer) => {
          const data: EComCreateCartRequest = { key: selectedCustomer.key };
          return this._portalsClientService.getItemPost<EComCartActionResult, EComCreateCartRequest>(
            this._cartActionResultModelConfig,
            this._createCartRequestModelConfig,
            data,
            `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/create`)
            .pipe(
              map((cartCreateResponse) => {
                this._idInt = cartCreateResponse.cartKey;
                this._count = cartCreateResponse.itemCount;
                this.onCountChanged.next(0);
                return this._idInt;
              })
            );
        }),
        catchError((error) => {
          delete this._idInt;
          this.onCountChanged.next(0);
          throw error;
        }),
        share(),
      );
    }
    return this._create$;
  }

  protected _redirectIfValidationErrors(result: EComCartItemActionResult): void {
    if (this.hasValidationErrors(result) === true) {
      this._router.navigate([EcomRouteNames.routeShopCartList], { queryParams: { [EcomRouteNames.queryParamNameAutoValidation]: true } });
    }
  }
}