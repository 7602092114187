import { Component, Inject, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPathValue } from "@material-framework/common/utils/object.utils";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfDialogService } from "@material-framework/dialog/dialog.service";
import { MfIcon } from "@material-framework/icon/icon";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfTableRowCellBaseFormatterComponent } from "@material-framework/table/row/cell/value/table.row.cell.base.formatter.component";
import { MfTableRowCellFormatterBaseOptions } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowCellHtmlDialogFormatterComponent" };

export type MfTableRowCellHtmlDialogFormatterOptions = MfTableRowCellFormatterBaseOptions & {
  button?: {
    icon?: MfIcon,
    label?: string;
    tooltip?: string;
  }
  dialog: {
    title: string;
    cancelDisplayName?: string;
    cancelShow?: boolean;
    okDisplayName?: string;
    okShow?: boolean;
  }
}

@Component({
  selector: "mf-table-row-cell-html-dialog-formatter-component",
  templateUrl: "table.row.cell.html.dialog.formatter.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowCellHtmlDialogFormatterComponent<TModel extends MfModelBase> extends MfTableRowCellBaseFormatterComponent<MfTableRowCellHtmlDialogFormatterOptions, TModel> implements OnInit {
  public constructor(
    protected override _injector: Injector,
    protected _dialogService: MfDialogService,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    this._displayValue = mfObjectGetPropertyPathValue<string>(this.fieldColumn?.fieldPath, this.rowItem) ?? "";
  }

  protected _onButtonClicked(event: MouseEvent): void {
    event.stopPropagation();
    if (!mfTypeIsUndefined(this.options)) {
      this._dialogService.openOkCancel({
        data: {
          isValid: true,
          messageInnerHTML: this._displayValue,
          title: this.options.dialog.title,
          cancelDisplayName: this.options.dialog.cancelDisplayName,
          cancelShow: this.options.dialog.cancelShow,
          okDisplayName: this.options.dialog.okDisplayName,
          okShow: this.options.dialog.okShow,
        }
      });
    }
  }
}