import { InjectionToken } from "@angular/core";

export const MF_ACTION_CARDS_CONFIG_TOKEN = new InjectionToken<MfActionCardsConfig>("MfActionCardsConfig");
export const MF_ACTION_CARDS_DEFAULT_CONFIG: MfActionCardsConfig = {

};


export type MfActionCardsConfig = {

}