import { MF_FILTER_CONTAINS_DEFAULT_DISPLAYNAME, MF_FILTER_DEFAULT_CONFIG, MF_FILTER_EQ_DEFAULT_DISPLAYNAME, MF_FILTER_STARTSWITH_DEFAULT_DISPLAYNAME, MfFilterConfig } from "@material-framework/filter/filter.config";

export const MF_PORTALS_FILTER_CONFIG: MfFilterConfig = {
  ...MF_FILTER_DEFAULT_CONFIG,
  group: {
    ...MF_FILTER_DEFAULT_CONFIG.group,
    canAddGroup: false,
  },
  logic: {
    ...MF_FILTER_DEFAULT_CONFIG.logic,
    canAddLogic: false,
  },
  expression: {
    ...MF_FILTER_DEFAULT_CONFIG.expression,
    maxFilters: 1,
  },
  operatorsTypes: {
    boolean: {
      eq: {
        displayName: MF_FILTER_EQ_DEFAULT_DISPLAYNAME,
      },
    },
    enum: {
      eq: {
        displayName: MF_FILTER_EQ_DEFAULT_DISPLAYNAME,
      },
    },
    string: {
      eq: {
        displayName: MF_FILTER_EQ_DEFAULT_DISPLAYNAME,
      },
      contains: {
        displayName: MF_FILTER_CONTAINS_DEFAULT_DISPLAYNAME,
      },
      startsWith: {
        displayName: MF_FILTER_STARTSWITH_DEFAULT_DISPLAYNAME,
      }
    },
    date: {
      eq: {
        displayName: MF_FILTER_EQ_DEFAULT_DISPLAYNAME,
      },
    },
    comparable: {
      eq: {
        displayName: MF_FILTER_EQ_DEFAULT_DISPLAYNAME,
      },
    },
    array: {},
    none: {}
  }
};