import { Injector } from "@angular/core";
import { MfBaseSafeUnsubscribe } from "@material-framework/base/base.safe.unsubscribe";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfFilterGroup } from "@material-framework/filter/filter";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfTableFieldColumn } from "@material-framework/table/table";
import { MfTableConfig } from "@material-framework/table/table.config";
import { Subject } from "rxjs";

export abstract class MfTableSelectionManager<TModel extends MfModelBase, TTrackBy = unknown> extends MfBaseSafeUnsubscribe {
  public isLoading: Subject<boolean> = new Subject();
  public onLoadCompleted: Subject<void> = new Subject();
  public onSelectionChanged: Subject<TTrackBy[] | undefined> = new Subject();
  public enabled = false;
  public canSelectAll = false;
  public selectedServiceKey?: string;

  public constructor(
    protected override _typeInfo: MfTypeInfo,
    protected override _injector: Injector,
    protected _config: MfTableConfig,
  ) {
    super(_typeInfo, _injector);
  }

  public abstract get isAllSelected(): boolean;
  public abstract get selectedCount(): number;
  public abstract get selected(): TTrackBy[]
  public abstract get all(): TTrackBy[]
  public abstract clearSelection(): void;
  public abstract cancel(): void;
  public abstract load(group: MfFilterGroup, fieldColumns: MfTableFieldColumn[]): void;
  public abstract isSelected(item: TModel): boolean;
  public abstract isTracked(item: TModel): boolean;
  public abstract setSelectedItems(items: TModel[]): void
  public abstract setSelectedByTrackedBy(trackedByValues: TTrackBy[], clearSelection: boolean): void
  public abstract setAllItemsSelected(checked: boolean, modelConfig: MfModelConfigMapped): void;
  public abstract setItemSelected(items: TModel, checked: boolean): void;
  public abstract setModelConfig(modelConfig: MfModelConfigMapped): void;
  public abstract getItemTrackedByValue(item: TModel): TTrackBy | undefined;
}