import { mfRouteAddRouteParameter, mfRouteJoinRouteParts } from "@material-framework/router/route";

export class EcomRouteNames {
  public static readonly parameterNameItemKey = "itemKey" as const;
  public static readonly parameterNameItemSpecKey = "itemSpecKey" as const;
  public static readonly parameterNameDocumentNumber = "documentNumber" as const;
  public static readonly parameterNameDocumentKey = "documentKey" as const;

  public static readonly queryParamNameAutoValidation = "auto-validation" as const;
  public static readonly queryParamNameLockNavigation = "lock-navigation" as const;
  public static readonly queryParamNameFilterOrderNumber = "filter-ordernumber" as const;

  public static readonly partNameNotApproved = "not-approved" as const;

  public static readonly partNamePaypalReturn = "paypal-return" as const;
  public static readonly partNamePaypalCancel = "paypal-cancel" as const;
  public static readonly partNamePaypalError = "paypal-error" as const;

  public static readonly partNameCart = "cart" as const;
  public static readonly partNameCheckout = "checkout" as const;
  public static readonly partNameDetail = "detail" as const;
  public static readonly partNameHome = "home" as const;
  public static readonly partNameInvoices = "invoices" as const;
  public static readonly partNameList = "list" as const;
  public static readonly partNameOrders = "orders" as const;
  public static readonly partNameProduct = "product" as const;
  public static readonly partNameProducts = "products" as const;
  public static readonly partNameRequests = "requests" as const;
  public static readonly partNameShop = "shop" as const;
  public static readonly partNameConfirmation = "confirmation" as const;
  public static readonly partNameShippingMethod = "shipping-method" as const;
  public static readonly partNameBillingPayment = "billing-payment" as const;
  public static readonly partNameOrderReview = "order-review" as const;

  public static readonly partNameParameterProductDetailItemKey = mfRouteAddRouteParameter(this.partNameDetail, [this.parameterNameItemKey]);
  public static readonly partNameParameterProductDetailItemSpecKey = mfRouteAddRouteParameter(this.partNameDetail, [this.parameterNameItemKey, this.parameterNameItemSpecKey]);

  public static readonly partNameInvoiceDetail = this.partNameDetail;
  public static readonly partNameInvoiceDetailItemKey = mfRouteAddRouteParameter(this.partNameDetail, [this.parameterNameDocumentKey, this.parameterNameDocumentNumber]);

  public static readonly routeHome = this.partNameHome;

  public static readonly routeOrders = this.partNameOrders;

  public static readonly routeInvoices = this.partNameInvoices;
  public static readonly routeInvoicesList = mfRouteJoinRouteParts([this.routeInvoices, this.partNameList]);
  public static readonly routeInvoiceDetail = mfRouteJoinRouteParts([this.routeInvoices, this.partNameDetail]);
  public static readonly routeInvoiceCart = mfRouteJoinRouteParts([this.routeInvoices, this.partNameCart]);
  public static readonly routeInvoiceCartList = mfRouteJoinRouteParts([this.routeInvoiceCart, this.partNameList]);
  public static readonly routeInvoiceCheckout = mfRouteJoinRouteParts([this.routeInvoiceCart, this.partNameCheckout]);
  public static readonly routeInvoiceCheckoutBillingPayment = mfRouteJoinRouteParts([this.routeInvoiceCheckout, this.partNameBillingPayment]);
  public static readonly routeInvoiceDetailOrdersRoute = mfRouteJoinRouteParts([this.routeInvoiceDetail, this.partNameOrders]);

  public static readonly routeNotApproved = this.partNameNotApproved;

  public static readonly routeShop = this.partNameShop;
  public static readonly routeProducts = mfRouteJoinRouteParts([this.routeShop, this.partNameProducts]);
  public static readonly routeProductsList = mfRouteJoinRouteParts([this.routeProducts, this.partNameList]);
  public static readonly routeProductDetail = mfRouteJoinRouteParts([this.routeProducts, this.partNameDetail]);

  public static readonly routeShopCart = mfRouteJoinRouteParts([this.routeShop, this.partNameCart]);
  public static readonly routeShopCartList = mfRouteJoinRouteParts([this.routeShopCart, this.partNameList]);
  public static readonly routeShopCheckout = mfRouteJoinRouteParts([this.routeShopCart, this.partNameCheckout]);
  public static readonly routeShopCheckoutShippingMethod = mfRouteJoinRouteParts([this.routeShopCheckout, this.partNameShippingMethod]);
  public static readonly routeShopCheckoutBillingPayment = mfRouteJoinRouteParts([this.routeShopCheckout, this.partNameBillingPayment]);
  public static readonly routeShopCheckoutOrderReview = mfRouteJoinRouteParts([this.routeShopCheckout, this.partNameOrderReview]);

  public static readonly routeOrderConfirmation = mfRouteJoinRouteParts([this.routeShop, this.partNameConfirmation]);

  public static getProductDetailRouteItemSpecKey = (itemKey: string, itemSpecKey: string) => mfRouteJoinRouteParts([this.routeProductDetail, itemKey, itemSpecKey]);
  public static getProductDetailRouteItemKey = (itemKey: string) => mfRouteJoinRouteParts([this.routeProductDetail, itemKey]);

  public static getInvoiceDetailRoute = (documentKey: string, documentNumber: string) => mfRouteJoinRouteParts([this.routeInvoiceDetail, documentKey, documentNumber]);
  public static getInvoiceDetailOrdersRoute = (documentKey: string, documentNumber: string) => mfRouteJoinRouteParts([this.routeInvoiceDetail, documentKey, documentNumber, this.partNameOrders]);


}
