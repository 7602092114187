import { InjectionToken } from "@angular/core";
import { faFileExport, faFloppyDisk, faTrash } from "@fortawesome/free-solid-svg-icons";
import { MfIcon } from "@material-framework/icon/icon";
import { MfIconTypes } from "@material-framework/icon/icon.types";

export const MF_VIEW_MANAGER_CONFIG_TOKEN = new InjectionToken<MfViewManagerConfig>("MfViewManagerConfig");
export const MF_VIEW_MANAGER_DEFAULT_CONFIG: MfViewManagerConfig = {
  controls: {
    saveTooltip: "Save to this view",
    saveAsTooltip: "Save to a new view",
    deleteTooltip: "Delete this view",
    defaultTooltip: "Set view as default",
    selectTooltip: "Select active view",
    selectPlaceholder: "Active view",
    defaultDisplayName: "Default",
    saveAsDialogTitle: "Save view as",
    viewNamePlaceholder: "View name",
    saveIcon: { icon: faFloppyDisk, type: MfIconTypes.fontAwesome },
    saveAsIcon: { icon: faFileExport, type: MfIconTypes.fontAwesome },
    deleteIcon: { icon: faTrash, type: MfIconTypes.fontAwesome },
    showSelect: true,
    showDefaultToggle: true,
    showSave: true,
    showSaveAs: true,
    showDelete: true
  },
  defaultViewDisplayName: "Default view",
};

export type MfViewManagerConfig = {
  defaultViewDisplayName: string;
  controls: MfViewManagerControlsConfig;
}

export type MfViewManagerControlsConfig = {
  saveTooltip: string;
  saveAsTooltip: string;
  deleteTooltip: string;
  defaultTooltip: string;
  defaultDisplayName: string;
  selectTooltip: string;
  selectPlaceholder: string;
  viewNamePlaceholder: string;
  saveIcon: MfIcon;
  saveAsIcon: MfIcon;
  deleteIcon: MfIcon;
  saveAsDialogTitle: string;
  showSelect: boolean;
  showDefaultToggle: boolean;
  showSave: boolean;
  showSaveAs: boolean;
  showDelete: boolean;
}