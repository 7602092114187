<div class="mf-input-container"
     [attr.aria-labelledby]="formField?.getLabelId()"
     (focusin)="_onFocusIn()"
     (focusout)="_onFocusOut($event)">
       <mat-label>{{ label }}</mat-label>
       <input #input
              [placeholder]="placeholder"
              [id]="id"
              [value]="_getDisplayValue()"
              [disabled]="disabled"
              [required]="required"
              type="text"
              (blur)="_blur()"
              (change)="_change()"
              (wheel)="_mouseWheel($event)"
              (paste)="_paste($any($event))"
              (keydown)="_keyDown($event)"
              (keyup.enter)="onEnterKey.emit(true)"
              (input)="_valueInput(input.value)"
              matInput>

       <mf-clear-value-button matSuffix
                              class="mf-clear-value-input-button"
                              [style.visibility]="disabled === false && input.value !== '' ? 'unset' : 'hidden'"
                              (onClearClick)="_onClearValueClicked()">
       </mf-clear-value-button>
       <mf-icon *ngIf="percentage === true"
                matSuffix
                [icon]="_iconPercentage"></mf-icon>
</div>