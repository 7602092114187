import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from "@angular-material-components/datetime-picker";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { LayoutModule } from "@angular/cdk/layout";
import { OverlayModule } from "@angular/cdk/overlay";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatCommonModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSliderModule } from "@angular/material/slider";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MfClickOutsideModule } from "@material-framework/clickOutside/click.outside.module";
import { MfDatePickerModule } from "@material-framework/datePicker/date.picker.module";
import { MfFilterModule } from "@material-framework/filter/filter.module";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MfInputModule } from "@material-framework/input/input.module";
import { MfPipesModule } from "@material-framework/pipes/pipes.module";
import { MfResizedModule } from "@material-framework/resized/resized.module";
import { MfSelectModule } from "@material-framework/select/select.module";
import { MfStatusChipModule } from "@material-framework/statusChip/status.chip.module";
import { MfStorageModule } from "@material-framework/storage/storage.module";
import { MfTableFooterComponent } from "@material-framework/table/footer/table.footer.component";
import { MfTableFooterLeftDef } from "@material-framework/table/footer/table.footer.left.def.directive";
import { MfTableFooterRightDef } from "@material-framework/table/footer/table.footer.right.def.directive";
import { MfTableHeaderComponent } from "@material-framework/table/header/table.header.component";
import { MfTableHeaderFilterComponent } from "@material-framework/table/header/table.header.filter.component";
import { MfTableHeaderFilterMenuComponent } from "@material-framework/table/header/table.header.filter.menu.component";
import { MfTableHeaderLeftDef } from "@material-framework/table/header/table.header.left.def.directive";
import { MfTableHeaderRightDef } from "@material-framework/table/header/table.header.right.def.directive";
import { MfTableQueryFilterService } from "@material-framework/table/queryFilter/table.query.filter.service";
import { MfTableCell, MfTableCellDef } from "@material-framework/table/row/cell/table.cell.def.directive";
import { MfTableRowCellComponent } from "@material-framework/table/row/cell/table.row.cell.component";
import { MfTableRowCellSlideOutActionsComponent } from "@material-framework/table/row/cell/table.row.cell.slide.out.actions.component";
import { MfTableRowCellDateEditorComponent } from "@material-framework/table/row/cell/value/editor/table.row.cell.date.editor.component";
import { MfTableRowCellNumberEditorComponent } from "@material-framework/table/row/cell/value/editor/table.row.cell.number.editor.component";
import { MfTableRowCellArrayFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.array.formatter.component";
import { MfTableRowCellBooleanFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.boolean.formatter.component";
import { MfTableRowCellCurrencyFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.currency.formatter.component";
import { MfTableRowCellDateFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.date.formatter.component";
import { MfTableRowCellEnumFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.enum.formatter.component";
import { MfTableRowCellFormatterDirective } from "@material-framework/table/row/cell/value/formatter/table.row.cell.formatter.directive";
import { MfTableRowCellHtmlDialogFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.html.dialog.formatter.component";
import { MfTableRowCellHtmlFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.html.formatter.component";
import { MfTableRowCellNumberFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.number.formatter.component";
import { MfTableRowCellPercentageFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.percentage.formatter.component";
import { MfTableRowCellStatusChipFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.status.chip.formatter.component";
import { MfTableRowCellStringFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.string.formatter.component";
import { MfTableRowCellUserFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.user.formatter.component";
import { MfTableColumnSlideOutActionsDef } from "@material-framework/table/row/column/table.column.actions.def.directive";
import { MfTableColumnDef } from "@material-framework/table/row/column/table.column.def.directive";
import { MfTableColumnExpandDef } from "@material-framework/table/row/column/table.column.expand.def.directive";
import { MfTableColumnModelConfigDef } from "@material-framework/table/row/column/table.column.model.config.def.directive";
import { MfTableRowHeaderMenuComponent } from "@material-framework/table/row/header/menu/table.row.header.menu.component";
import { MfTableRowHeaderMenuFilterComponent } from "@material-framework/table/row/header/menu/table.row.header.menu.filter.component";
import { MfTableRowHeaderMenuSortComponent } from "@material-framework/table/row/header/menu/table.row.header.menu.sort.component";
import { MfTableRowHeaderMenuWidthComponent } from "@material-framework/table/row/header/menu/table.row.header.menu.width.component";
import { MfTableRowFieldHeaderCellComponent } from "@material-framework/table/row/header/table.row.field.header.cell.component";
import { MfTableRowFieldHeaderCellInfoComponent } from "@material-framework/table/row/header/table.row.field.header.cell.info.component";
import { MfTableRowGroupHeaderCellComponent } from "@material-framework/table/row/header/table.row.group.header.cell.component";
import { MfTableColumnModelConfigHeaderActionsDef, MfTableHeaderCellActionsModelConfig } from "@material-framework/table/row/header/table.row.header.cell.actions.model.config.def.directive";
import { MfTableHeaderCell, MfTableHeaderCellDef } from "@material-framework/table/row/header/table.row.header.cell.def.directive";
import { MfTableColumnsShowHideOrderComponent } from "@material-framework/table/showHideOrder/table.columns.show.hide.order.component";
import { MfTableComponent } from "@material-framework/table/table.component";
import { MF_TABLE_CONFIG_TOKEN, MF_TABLE_DEFAULT_CONFIG } from "@material-framework/table/table.config";
import { MF_TABLE_CELL_FORMATTERS_CONFIG, MF_TABLE_CELL_FORMATTERS_CONFIG_TOKEN } from "@material-framework/table/table.config.cell.formatter";
import { MF_TABLE_CELL_FORMATTER_TO_MODEL_DATATYPE_CONFIG, MF_TABLE_CELL_FORMATTER_TO_MODEL_DATATYPE_CONFIG_TOKEN } from "@material-framework/table/table.config.cell.formatter.model.datatype";
import { MfUserPersonaModule } from "@material-framework/userPersona/user.persona.module";
import { MfViewManagerModule } from "@material-framework/viewManager/view.manager.module";
import { ApolloModule } from "apollo-angular";

@NgModule({
    declarations: [
        MfTableCell,
        MfTableCellDef,
        MfTableColumnDef,
        MfTableColumnExpandDef,
        MfTableColumnModelConfigDef,
        MfTableColumnModelConfigHeaderActionsDef,
        MfTableColumnSlideOutActionsDef,
        MfTableColumnsShowHideOrderComponent,
        MfTableComponent,
        MfTableFooterComponent,
        MfTableFooterLeftDef,
        MfTableFooterRightDef,
        MfTableHeaderCell,
        MfTableHeaderCellActionsModelConfig,
        MfTableHeaderCellDef,
        MfTableHeaderComponent,
        MfTableHeaderFilterComponent,
        MfTableHeaderFilterMenuComponent,
        MfTableHeaderLeftDef,
        MfTableHeaderRightDef,
        MfTableRowCellArrayFormatterComponent,
        MfTableRowCellBooleanFormatterComponent,
        MfTableRowCellComponent,
        MfTableRowCellCurrencyFormatterComponent,
        MfTableRowCellDateEditorComponent,
        MfTableRowCellDateFormatterComponent,
        MfTableRowCellEnumFormatterComponent,
        MfTableRowCellFormatterDirective,
        MfTableRowCellHtmlDialogFormatterComponent,
        MfTableRowCellHtmlFormatterComponent,
        MfTableRowCellNumberEditorComponent,
        MfTableRowCellNumberFormatterComponent,
        MfTableRowCellPercentageFormatterComponent,
        MfTableRowCellSlideOutActionsComponent,
        MfTableRowCellStatusChipFormatterComponent,
        MfTableRowCellStringFormatterComponent,
        MfTableRowCellUserFormatterComponent,
        MfTableRowCellUserFormatterComponent,
        MfTableRowFieldHeaderCellComponent,
        MfTableRowFieldHeaderCellInfoComponent,
        MfTableRowGroupHeaderCellComponent,
        MfTableRowHeaderMenuComponent,
        MfTableRowHeaderMenuFilterComponent,
        MfTableRowHeaderMenuSortComponent,
        MfTableRowHeaderMenuWidthComponent,
    ],
    exports: [
        MfTableCell,
        MfTableCellDef,
        MfTableColumnDef,
        MfTableColumnExpandDef,
        MfTableColumnModelConfigDef,
        MfTableColumnModelConfigHeaderActionsDef,
        MfTableColumnSlideOutActionsDef,
        MfTableComponent,
        MfTableFooterLeftDef,
        MfTableFooterRightDef,
        MfTableHeaderCell,
        MfTableHeaderCellActionsModelConfig,
        MfTableHeaderCellDef,
        MfTableHeaderLeftDef,
        MfTableHeaderRightDef,
    ],
    imports: [
        ApolloModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        DragDropModule,
        FontAwesomeModule,
        LayoutModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatCommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        MatTableModule,
        MatTooltipModule,
        MfClickOutsideModule,
        MfDatePickerModule,
        MfFilterModule,
        MfIconModule,
        MfInputModule,
        MfPipesModule,
        MfResizedModule,
        MfSelectModule,
        MfStatusChipModule,
        MfStorageModule,
        MfUserPersonaModule,
        MfViewManagerModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        OverlayModule,
        ReactiveFormsModule,
        ScrollingModule
    ],
    providers: [
        { provide: MF_TABLE_CELL_FORMATTERS_CONFIG_TOKEN, useValue: MF_TABLE_CELL_FORMATTERS_CONFIG },
        { provide: MF_TABLE_CELL_FORMATTER_TO_MODEL_DATATYPE_CONFIG_TOKEN, useValue: MF_TABLE_CELL_FORMATTER_TO_MODEL_DATATYPE_CONFIG },
        { provide: MF_TABLE_CONFIG_TOKEN, useValue: MF_TABLE_DEFAULT_CONFIG },
        CurrencyPipe,
        DatePipe,
        DecimalPipe,
        PercentPipe,
        MfTableQueryFilterService,
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class MfTableModule {
}