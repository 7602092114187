import { NgModule } from "@angular/core";
import { MatChipsModule } from "@angular/material/chips";
import { BrowserModule } from "@angular/platform-browser";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MfStatusChipComponent } from "@material-framework/statusChip/status.chip.component";
import { MF_STATUS_CHIP_CONFIG_TOKEN, MF_STATUS_CHIP_PERSONA_DEFAULT_CONFIG } from "@material-framework/statusChip/status.chip.config";

@NgModule({
  declarations: [
    MfStatusChipComponent
  ],
  imports: [
    BrowserModule,
    MatChipsModule,
    MfIconModule,
  ],
  exports: [
    MfStatusChipComponent
  ],
  providers: [
    { provide: MF_STATUS_CHIP_CONFIG_TOKEN, useValue: MF_STATUS_CHIP_PERSONA_DEFAULT_CONFIG },
  ]
})
export class MfStatusChipModule {
}