import { Directive, TemplateRef } from "@angular/core";

@Directive({
  selector: "[mfHeaderMainLeftDef]"
})
export class MfHeaderMainLeftDef {
  public constructor(public template: TemplateRef<unknown>) { }
}

@Directive({
  selector: "[mfHeaderSubLeftDef]"
})
export class MfHeaderSubLeftDef {
  public constructor(public template: TemplateRef<unknown>) { }
}

@Directive({
  selector: "[mfHeaderMainRightDef]"
})
export class MfHeaderMainRightDef {
  public constructor(public template: TemplateRef<unknown>) { }
}

@Directive({
  selector: "[mfHeaderSubRightDef]"
})
export class MfHeaderSubRightDef {
  public constructor(public template: TemplateRef<unknown>) { }
}