<div class="action-card-container">
  <mat-card class="action-card"
            matRipple
            (click)="_actionClicked()"
            [style.minWidth.px]="minWidth"
            [style.maxWidth.px]="maxWidth">
    <mat-card-content class="action-card-content">
      <div class="mat-h2">
        {{actionCard?.title}}
      </div>
      <div class="mf-flex-fill"></div>
      <mf-icon [size]="iconSize"
               [icon]="actionCard?.icon"></mf-icon>
    </mat-card-content>
    <mat-card-header>
      <mat-card-subtitle>{{ actionCard?.description }}</mat-card-subtitle>
    </mat-card-header>
    <div class="border-bottom-container">
    </div>
  </mat-card>
</div>