<div class="mf-page-fill">
  <mf-header [menuOpen]="drawerRight.opened"
             [menuItemsCount]="menuItems.length"
             (onSideMenuToggleClicked)="_sideMenuLeftToggle()">
    <ng-container *mfHeaderMainRightDef>
      <ng-template *ngIf="!_isUndefined(_headerMainRightDef)"
                   [ngTemplateOutlet]="_headerMainRightDef.template">
      </ng-template>
    </ng-container>

    <ng-container *mfHeaderMainLeftDef>
      <ng-template *ngIf="!_isUndefined(_headerMainLeftDef)"
                   [ngTemplateOutlet]="_headerMainLeftDef.template">
      </ng-template>
    </ng-container>

    <ng-container *mfHeaderSubRightDef>
      <ng-template *ngIf="!_isUndefined(_headerSubRightDef)"
                   [ngTemplateOutlet]="_headerSubRightDef.template">
      </ng-template>
    </ng-container>

    <ng-container *mfHeaderSubLeftDef>
      <ng-template *ngIf="!_isUndefined(_headerSubLeftDef)"
                   [ngTemplateOutlet]="_headerSubLeftDef.template">
      </ng-template>
    </ng-container>
  </mf-header>
  <mat-drawer-container class="mf-drawer-container"
                        autosize>
    <mat-drawer #drawerLeft
                position="start"
                mode="side">
      <mf-menu [items]="menuItems"></mf-menu>
    </mat-drawer>
    <div class="mf-contents">
      <div class="mf-main">
        <router-outlet></router-outlet>
      </div>
      <div class="mf-end-of-content">
        <ng-template *ngIf="!_isUndefined(_endOfContentsDef)"
                     [ngTemplateOutlet]="_endOfContentsDef.template">
        </ng-template>
      </div>
    </div>
    <mat-drawer #drawerRight
                position="end"
                mode="side">
      <ng-template #drawerRightHost></ng-template>
    </mat-drawer>
  </mat-drawer-container>
</div>