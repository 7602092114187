import { InjectionToken } from "@angular/core";
import { AuthConfig } from "angular-oauth2-oidc";

export const MF_AUTH_CONFIG_TOKEN = new InjectionToken<MfAuthConfig>("MfAuthConfig");
export const MF_AUTH_CONFIG_DEFAULT: MfAuthConfig = {
  flow: "code",
  useAutomaticSilentRefresh: false,
};

export type MfAuthConfig = AuthConfig & {
  flow: "code" | "implicit" | "login";
  useAutomaticSilentRefresh: boolean;
};