import { Directive, Inject, TemplateRef } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableHeaderCellActionsModelConfig" };

@Directive({
  selector: "[mf-table-header-model-config-cell-actions]",
})
export class MfTableHeaderCellActionsModelConfig {
  public constructor(
    @Inject(MF_TABLE_CONFIG_TOKEN) public config: MfTableConfig) {
  }
}

@Directive({
  selector: "[mfTableColumnModelConfigHeaderActionsDef]"
})
export class MfTableColumnModelConfigHeaderActionsDef {
  public constructor(public template: TemplateRef<unknown>) { }
}