import { ChangeDetectorRef, Component, Input, ViewEncapsulation } from "@angular/core";
import { MfBaseObject } from "@material-framework/base/base.object";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfStringIsEmptyOrWhitespace } from "@material-framework/common/utils/string.utils";

const TYPE_INFO: MfTypeInfo = { className: "MfLoadingComponent" };

@Component({
  selector: "mf-loading",
  templateUrl: "loading.component.html",
  styleUrls: ["loading.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfLoadingComponent extends MfBaseObject {

  @Input()
  public get status() {
    return this._status;
  }
  public set status(value) {
    this._status = value;
    this._cdRef.detectChanges();
  }

  @Input()
  public get eta() {
    return this._eta;
  }
  public set eta(value) {
    this._eta = value;
    this._cdRef.detectChanges();
  }

  protected _status = "";
  protected _eta = "";
  protected _isEmptyOrWhitespace = mfStringIsEmptyOrWhitespace;

  public constructor(
    protected _cdRef: ChangeDetectorRef
  ) {
    super(TYPE_INFO);
  }
}