<button #matIconButton
        (click)="open($event)"
        [matTooltip]="_config.row.cell.actions.tooltip"
        mat-icon-button>
  <mf-icon [icon]="_config.row.cell.actions.menuIcon"></mf-icon>
</button>
<ng-template #slideOutOverlayTemplate>
  <div (mfClickOutside)="close()"
       class="mf-table-slide-out-actions mat-elevation-z3">
    <ng-template [ngTemplateOutlet]="columnSlideOutActionsDef!.cellDef!.template"
                 [ngTemplateOutletContext]="{$implicit: item}">
    </ng-template>
  </div>
</ng-template>