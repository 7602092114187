import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";

export class MfResizedEvent {
  public newRect: DOMRectReadOnly;
  public oldRect?: DOMRectReadOnly;
  public isFirst: boolean;

  public constructor(newRect: DOMRectReadOnly, oldRect: DOMRectReadOnly | undefined) {
    this.newRect = newRect;
    this.oldRect = oldRect;
    this.isFirst = oldRect == null;
  }
}

const TYPE_INFO: MfTypeInfo = { className: "MfResizedDirective" };

@Directive({
  selector: "[mfResized]"
})
export class MfResizedDirective implements OnInit, OnDestroy {
  protected _observer: ResizeObserver;
  protected _oldRect?: DOMRectReadOnly;

  @Output()
  public mfResized: EventEmitter<MfResizedEvent> = new EventEmitter();

  public constructor(
    protected _element: ElementRef,
    protected _zone: NgZone,
  ) {
    this._observer = new ResizeObserver(entries => this._zone.run(() => this._observe(entries)));
  }

  public ngOnInit(): void {
    this._observer.observe(this._element.nativeElement);
  }

  public ngOnDestroy(): void {
    this._observer.disconnect();
  }

  protected _observe(entries: ResizeObserverEntry[]): void {
    const domSize = entries[0];
    const resizedEvent = new MfResizedEvent(domSize.contentRect, this._oldRect);
    this._oldRect = domSize.contentRect;
    this.mfResized.emit(resizedEvent);
  }
}