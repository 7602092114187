
export function mfDocumentChangeFavicon(src: string): void {
  const link = document.getElementById("favicon");
  link?.setAttribute("href", src);
}

type MfFavicon = {
  defaultPause: number;
  change(iconURL: string, optionalDocTitle?: string): void;
  animate(icons: string[], optionalDelay?: number): void;
  stopAnimate(): void;
}

class MfFaviconService {
  private _doc = document;
  private _head = this._doc.getElementsByTagName("head")[0];
  private _loopTimeout?: number = undefined;

  public changeFavicon(iconURL: string): void {
    const newLink = this._doc.createElement("link");
    newLink.type = "image/x-icon";
    newLink.rel = "icon";
    newLink.href = iconURL;
    this.removeExistingFavicon();
    this._head.appendChild(newLink);
  }

  public removeExistingFavicon(): void {
    const links = this._head.getElementsByTagName("link");
    for (let i = 0; i < links.length; i++) {
      if (/\bicon\b/i.test(links[i].getAttribute("rel") || "")) {
        this._head.removeChild(links[i]);
      }
    }
  }

  public defaultPause = 2000;

  public change(iconURL: string, optionalDocTitle?: string): void {
    clearTimeout(this._loopTimeout);
    if (optionalDocTitle) {
      this._doc.title = optionalDocTitle;
    }
    if (iconURL !== "") {
      this.changeFavicon(iconURL);
    }
  }

  public animate(icons: string[], optionalDelay?: number): void {
    clearTimeout(this._loopTimeout);
    // preload icons
    icons.forEach((icon) => {
      new Image().src = icon;
    });
    optionalDelay = optionalDelay || this.defaultPause;
    const iconIndex = 0;
    this.changeFavicon(icons[iconIndex]);
    this._loopTimeout = setTimeout(() => {
      this._animateFunc(icons, iconIndex, optionalDelay || this.defaultPause);
    }, optionalDelay, undefined);
  }

  private _animateFunc(icons: string[], iconIndex: number, delay: number): void {
    iconIndex = (iconIndex + 1) % icons.length;
    this.changeFavicon(icons[iconIndex]);
    this._loopTimeout = setTimeout(
      () => this._animateFunc(icons, iconIndex, delay),
      delay, undefined
    );
  }

  public stopAnimate(): void {
    clearTimeout(this._loopTimeout);
  }
}

export const mfFavicon: MfFavicon = new MfFaviconService();