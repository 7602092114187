import { NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { MatRippleModule } from "@angular/material/core";
import { BrowserModule } from "@angular/platform-browser";
import { MfActionCardComponent } from "@material-framework/actionCards/action.card.component";
import { MfActionCardsComponent } from "@material-framework/actionCards/action.cards.component";
import { MF_ACTION_CARDS_CONFIG_TOKEN, MF_ACTION_CARDS_DEFAULT_CONFIG } from "@material-framework/actionCards/action.cards.config";
import { MfIconModule } from "@material-framework/icon/icon.module";


@NgModule({
  declarations: [
    MfActionCardsComponent,
    MfActionCardComponent,
  ],
  imports: [
    BrowserModule,
    MatCardModule,
    MfIconModule,
    MatRippleModule,
  ],
  exports: [
    MfActionCardsComponent,
  ],
  providers: [
    { provide: MF_ACTION_CARDS_CONFIG_TOKEN, useValue: MF_ACTION_CARDS_DEFAULT_CONFIG },
  ]
})
export class MFActionCardsModule {
}