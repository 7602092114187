import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { BrowserModule } from "@angular/platform-browser";
import { EComFooterComponent } from "@e-commerce/footer/footer.component";
import { MfIconModule } from "@material-framework/icon/icon.module";

@NgModule({
  declarations: [
    EComFooterComponent
  ],
  exports: [
    EComFooterComponent
  ],
  imports: [
    BrowserModule,
    MfIconModule,
    MatButtonModule,
  ],
})
export class EComFooterModule {
}