import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { MfFillRouterOutletComponent } from "@material-framework/fillRouterOutlet/fill.router.outlet.component";

@NgModule({
  declarations: [
    MfFillRouterOutletComponent
  ],
  exports: [
    MfFillRouterOutletComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
  ],
})
export class MfFillRouterOutletModule {
}