import { Injectable, Injector } from "@angular/core";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsNullOrUndefined } from "@material-framework/common/utils/type.utils";
import { MfStorageService } from "@material-framework/storage/storage";

const TYPE_INFO: MfTypeInfo = { className: "MfSessionStorageService" };

@Injectable()
export class MfSessionStorageService extends MfBaseService implements MfStorageService {
  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }

  public addUpdate<TDate>(key: string, data: TDate): void {
    return sessionStorage.setItem(key, JSON.stringify(data));
  }

  public get<TDate>(key: string): TDate | undefined {
    const rawValue = sessionStorage.getItem(key);
    if (!mfTypeIsNullOrUndefined(rawValue)) {
      const obj = JSON.parse(rawValue);
      if (!mfTypeIsNullOrUndefined(obj)) {
        return obj;
      }
    }
    return;
  }

  public remove(key: string): void {
    sessionStorage.removeItem(key);
  }
}