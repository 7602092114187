import { EComSourceSystemTypes } from "@e-commerce/common/source.system";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelFieldConfig, MfModelFieldDataTypes, MfModelConfig } from "@material-framework/modelConfig/model.config";


export type EComCustomerModel = MfModelBase & {
  key: string;
  displayName: string;
  sourceSystem: EComSourceSystemTypes;
}

export type EComCustomerModelFieldsConfig = {
  [key in keyof Required<EComCustomerModel>]: MfModelFieldConfig
};

export const ECOM_CUSTOMER_MODEL_FIELDS_CONFIG: EComCustomerModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Key"
    }
  },
  displayName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Display Name"
    }
  },
  sourceSystem: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Source System"
    }
  },
};

export const ECOM_CUSTOMER_MODEL_CONFIG_ID = "customer";

export const ECOM_CUSTOMER_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CUSTOMER_MODEL_CONFIG_ID,
  fields: ECOM_CUSTOMER_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CUSTOMER_MODEL_CONFIG",
  },
  portals: {}
};