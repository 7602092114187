import { BooleanInput, NumberInput, coerceBooleanProperty, coerceNumberProperty } from "@angular/cdk/coercion";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
  booleanAttribute,
  numberAttribute,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { ThemePalette } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MfBaseValidationComponent } from "@material-framework/base/base.validation.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { ERROR_MESSAGE_REQUIRED } from "@material-framework/common/validation/validation.const";
import { MF_DATE_PICKER_CONFIG_TOKEN, MfDatePickerConfig } from "@material-framework/datePicker/date.picker.config";
import * as moment from "moment";
import { Moment } from "moment";

const TYPE_INFO: MfTypeInfo = { className: "MfDatePickerComponent" };

export type MfDatePickerTypes = "Moment";
export type MfDatePickerDataTypes = null | Moment;

@Component({
  selector: "mf-datepicker",
  templateUrl: "date.picker.component.html",
  styleUrls: ["date.picker.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfDatePickerComponent extends MfBaseValidationComponent implements AfterViewInit {
  @Input()
  public get value(): MfDatePickerDataTypes {
    return this._formControl.value;
  }
  public set value(value: MfDatePickerDataTypes | Date) {
    if (value instanceof Date) {
      this._formControl.setValue(moment(value), { emitEvent: false });
    } else {
      this._formControl.setValue(value, { emitEvent: false });
    }
  }

  @Input({ transform: booleanAttribute })
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this._formControl.disable();
    } else {
      this._formControl.enable();
    }
  }

  @Input({ transform: booleanAttribute })
  public get showSpinners(): boolean {
    return this._showSpinners;
  }
  public set showSpinners(value: BooleanInput) {
    this._showSpinners = coerceBooleanProperty(value);
  }

  @Input({ transform: booleanAttribute })
  public get showSeconds(): boolean {
    return this._showSeconds;
  }
  public set showSeconds(value: BooleanInput) {
    this._showSeconds = coerceBooleanProperty(value);
  }

  @Input({ transform: booleanAttribute })
  public get disableMinute(): boolean {
    return this._disableMinute;
  }
  public set disableMinute(value: BooleanInput) {
    this._disableMinute = coerceBooleanProperty(value);
  }

  @Input({ transform: numberAttribute })
  public get stepHour(): number {
    return this._stepHour;
  }
  public set stepHour(value: NumberInput) {
    this._stepHour = coerceNumberProperty(value);
  }

  @Input()
  public get stepMinute(): number {
    return this._stepMinute;
  }
  public set stepMinute(value: NumberInput) {
    this._stepMinute = coerceNumberProperty(value);
  }

  @Input()
  public get stepSecond(): number {
    return this._stepSecond;
  }
  public set stepSecond(value: NumberInput) {
    this._stepSecond = coerceNumberProperty(value);
  }

  @Input()
  public color?: ThemePalette;

  @Input({ transform: booleanAttribute })
  public get enableMeridian(): boolean {
    return this._enableMeridian;
  }
  public set enableMeridian(value: BooleanInput) {
    this._enableMeridian = coerceBooleanProperty(value);
  }

  @Input({ transform: booleanAttribute })
  public get hideTime(): boolean {
    return this._hideTime;
  }
  public set hideTime(value: BooleanInput) {
    this._hideTime = coerceBooleanProperty(value);
  }

  @Input({ transform: booleanAttribute })
  public get touchUi(): boolean {
    return this._touchUi;
  }
  public set touchUi(value: BooleanInput) {
    this._touchUi = coerceBooleanProperty(value);
  }

  @Input()
  public get placeholder(): string {
    return this._placeholder;
  }
  public set placeholder(value: string) {
    this._placeholder = value;
  }

  @Input()
  public requiredMessage: string = ERROR_MESSAGE_REQUIRED;

  @Input({ transform: booleanAttribute })
  public get readonly(): boolean {
    return this._readonly;
  }
  public set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
    if (this._readonly) {
      this._formControl.disable();
    } else {
      this._formControl.enable();
    }
  }

  @Output()
  public onChanged: EventEmitter<MfDatePickerDataTypes> = new EventEmitter();

  @Input()
  public defaultTime = [0, 0, 0];

  @ViewChild("fauxPicker")
  protected _fauxPicker?: MatDatepicker<null>;

  protected _formControl = new FormControl(moment());
  protected _hideTime: boolean = false;
  protected _readonly: boolean = false;
  protected _disabled: boolean = false;
  protected _enableMeridian: boolean = false;
  protected _showSpinners: boolean = true;
  protected _showSeconds: boolean = true;
  protected _disableMinute: boolean = false;
  protected _stepHour: number = 1;
  protected _stepMinute: number = 1;
  protected _stepSecond: number = 1;
  protected _touchUi: boolean = false;
  protected _placeholder: string = "";

  public constructor(
    protected override _injector: Injector,
    protected _changeDetectorRef: ChangeDetectorRef,
    @Inject(MF_DATE_PICKER_CONFIG_TOKEN)
    protected _config: MfDatePickerConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._sub(this._formControl.valueChanges, {
      next: (value) => {
        this.onChanged.emit(value);
      }
    });
  }

  public ngAfterViewInit(): void {
    if (this._fauxPicker !== undefined) {
      this._fauxPicker.open();
      this._changeDetectorRef.detectChanges();
      this._fauxPicker.close();
      this._changeDetectorRef.detectChanges();
    }
  }
}