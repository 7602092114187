import { Component, Injector, ViewEncapsulation } from "@angular/core";
import { MfAuthService } from "@material-framework/auth/auth.service";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";

const TYPE_INFO: MfTypeInfo = { className: "MfAuthRedirectComponent" };

@Component({
  selector: "mf-auth-redirect",
  templateUrl: "auth.redirect.component.html",
  styleUrls: ["auth.redirect.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfAuthRedirectComponent extends MfBaseComponent {
  public constructor(
    protected override _injector: Injector,
    protected _authService: MfAuthService,
  ) {
    super(TYPE_INFO, _injector);
    this._sub(this._authService.runInitialLoginSequence());
  }
}