import { Injectable, Injector } from "@angular/core";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComOrderDocumentDownloadInfoModel, EComOrderDocumentModel, EComOrderDocumentsModel, EComOrderDownloadDocumentRequest } from "@e-commerce/modelConfigs/order.document.model.config";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfFileNameClean } from "@material-framework/common/utils/file.utils";
import { MfModelConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfPortalsClientService } from "@material-framework/portals/portals.client.service";
import { saveAs } from "file-saver";
import { concatMap, map, Observable, of } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComOrderService" };

type EComOrderDocumentExtendedModel = EComOrderDocumentModel & {
  customerKey: string;
  orderLineKey: string;
}

@Injectable({ providedIn: "root" })
export class EComOrderService extends MfBaseService {
  protected _orderDocumentsModelConfig: MfModelConfigMapped;
  protected _orderDocumentModelConfig: MfModelConfigMapped;
  protected _orderDocumentDownloadInfoModelConfig: MfModelConfigMapped;
  protected _orderDownloadDocumentRequestModelConfig: MfModelConfigMapped;
  protected _orderDocuments: EComOrderDocumentExtendedModel[] = [];

  public constructor(
    protected override _injector: Injector,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    protected _customerService: EComCustomerService,
  ) {
    super(TYPE_INFO, _injector);
    this._orderDocumentsModelConfig = this._modelConfigService.get<EComModelsConfig>("orderDocuments");
    this._orderDocumentModelConfig = this._modelConfigService.get<EComModelsConfig>("orderDocument");
    this._orderDocumentDownloadInfoModelConfig = this._modelConfigService.get<EComModelsConfig>("orderDocumentDownloadInfo");
    this._orderDownloadDocumentRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("orderDownloadDocumentRequest");
  }

  public downloadDocument(orderLineKey: string, document: EComOrderDocumentModel): Observable<boolean> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this._getDownloadInfo(selectedCustomer.key, orderLineKey, document).pipe(
          concatMap((orderDocumentDownloadInfo) => {
            return this._downloadDocument(orderDocumentDownloadInfo, document);
          }),
        );
      })
    );
  }

  public getDocuments(orderLineKey: string): Observable<EComOrderDocumentModel[]> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        const cachedOrderDocuments = this._orderDocuments.filter(i => i.customerKey === selectedCustomer.key && i.orderLineKey === orderLineKey);

        if (cachedOrderDocuments.length > 0) {
          return of(cachedOrderDocuments);
        }

        return this._portalsClientService.getItemGet<EComOrderDocumentsModel>(
          this._orderDocumentsModelConfig,
          `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/${selectedCustomer.key}/${ECOM_ENVIRONMENT.portalsLinesUrl}/${orderLineKey}/${ECOM_ENVIRONMENT.portalsDocumentsUrl}`
        ).pipe(
          map((orderDocuments) => {
            this._orderDocuments.push(...orderDocuments.documents.map(i => ({ ...i, customerKey: selectedCustomer.key, orderLineKey })));
            return orderDocuments.documents;
          })
        );
      }),
    );
  }

  protected _getDownloadInfo(customerkey: string, orderLineKey: string, document: EComOrderDocumentModel): Observable<EComOrderDocumentDownloadInfoModel> {
    return this._portalsClientService.getItemGet<EComOrderDocumentDownloadInfoModel>(
      this._orderDocumentDownloadInfoModelConfig,
      `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/${customerkey}/${ECOM_ENVIRONMENT.portalsLinesUrl}/${orderLineKey}/${ECOM_ENVIRONMENT.portalsDocumentsUrl}/${document.documentKey}`
    );
  }

  protected _downloadDocument(orderDocumentDownloadInfo: EComOrderDocumentDownloadInfoModel, document: EComOrderDocumentModel): Observable<boolean> {
    const data: EComOrderDownloadDocumentRequest = {
      key: orderDocumentDownloadInfo.payLoad.key,
      orderLineKey: orderDocumentDownloadInfo.payLoad.orderLineKey,
      documentKey: orderDocumentDownloadInfo.payLoad.documentKey,
    };

    return this._portalsClientService.getFileBlobPost<EComOrderDownloadDocumentRequest>(
      this._orderDownloadDocumentRequestModelConfig,
      data,
      `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${orderDocumentDownloadInfo.downloadUrl}`,
    ).pipe(
      map((response) => {
        saveAs(response.blob, mfFileNameClean(`${document.name}.${response.extension}`));
        return true;
      })
    );
  }
}