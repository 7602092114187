<div class="mf-filter-toolbar">
  <div class="mf-toolbar">
    <div class="mf-filter-toolbar-item"
         *ngIf="!singleFieldFilter">
      <mf-filter-field-select #fieldSelect
                              class="mf-filter-input"
                              (onFieldChanged)="_onFieldChanged($event)"
                              [modelFieldsConfig]="modelFieldsConfig"
                              [expression]="expression"></mf-filter-field-select>
    </div>
    <div class="mf-filter-toolbar-item"
         *ngIf="modelFieldConfig?.filter?.operator?.type != 'none'">
      <mf-filter-operator-select #operatorSelect
                                 class="mf-filter-input"
                                 (onOperatorChanged)="_onOperatorChanged($event)"
                                 [expression]="expression"></mf-filter-operator-select>
    </div>
    <div class="mf-filter-toolbar-item">
      <mf-filter-value-input #valueInput
                             class="mf-filter-input-last"
                             (onValueChange)="_onValueChange($event)"
                             (onEnterKey)="onEnterKey.emit($event)"
                             [expression]="expression"></mf-filter-value-input>
    </div>
    <div class="mf-filter-toolbar-item">
      <button matTooltip="{{_config.expression.removeTooltip}}"
              (click)="_onRemoveExpressionClicked()"
              mat-icon-button>
        <mf-icon [icon]="_config.expression.removeIcon"></mf-icon>
      </button>
    </div>
  </div>
</div>