import { Inject, InjectionToken, Pipe, PipeTransform } from "@angular/core";
import { mfNumberGetNumberWithOrdinal } from "@material-framework/common/utils/number.utils";

export const MF_NUMBER_WITH_ORDINAL_PIPE_CONFIG_TOKEN = new InjectionToken<mfIntWithOrdinalPipeConfig>("mfIntWithOrdinalPipeConfig");
export const MF_NUMBER_WITH_ORDINAL_PIPE_DEFAULT_CONFIG: mfIntWithOrdinalPipeConfig = {
};

export type mfIntWithOrdinalPipeConfig = {};

@Pipe({ name: "mfIntWithOrdinal" })
export class mfIntWithOrdinalPipe implements PipeTransform {
  public constructor(
    @Inject(MF_NUMBER_WITH_ORDINAL_PIPE_CONFIG_TOKEN)
    protected _config: mfIntWithOrdinalPipeConfig,
  ) { }

  public transform(value: number): string {
    return mfNumberGetNumberWithOrdinal(value);
  }
}