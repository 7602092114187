import { Component, EventEmitter, Injector, Input, Output, ViewEncapsulation } from "@angular/core";
import { MfActionCardAction } from "@material-framework/actionCards/action.cards";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";


const TYPE_INFO: MfTypeInfo = { className: "MfActionCardComponent" };

@Component({
  selector: "mf-action-card",
  templateUrl: "action.card.component.html",
  styleUrls: ["action.card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfActionCardComponent extends MfBaseComponent {
  @Input()
  public actionCard?: MfActionCardAction;

  @Input()
  public minWidth?: number;

  @Input()
  public maxWidth?: number;

  @Input()
  public iconSize?: number;

  @Output()
  public onActionClicked: EventEmitter<MfActionCardAction> = new EventEmitter();

  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected _actionClicked(): void {
    this.onActionClicked.emit(this.actionCard);
  }
}