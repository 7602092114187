<div class="mf-landing-container"
     [style.color]="_config.loggedOut.color"
     [style.backgroundImage]="'url(' + _config.loggedOut.backgroundImageUrl + ')'">
  <div *ngIf="_config.loggedOut.showSlogan === true"
       class="mf-column mf-slogan">
    <div class="mf-header">
      {{ _config.loggedOut.sloganHeaderText }}
    </div>
    <div class="mf-text">
      {{ _config.loggedOut.sloganText }}
    </div>
  </div>
  <div class="mf-column mf-message">
    <div><b>You've signed out of your account</b></div>
    <br />
    <div>It's a good idea to close all browser windows.</div>
  </div>
</div>