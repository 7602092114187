import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, Component, Inject, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";
import { isStepComponent, isValidatingComponent } from "@e-commerce/checkout/base/checkout";
import { EComCheckoutBaseComponent } from "@e-commerce/checkout/base/checkout.base.component";
import { EComOrderBillingPaymentComponent } from "@e-commerce/checkout/order/billingPayment/order.billing.payment.component";
import { EComOrderCheckoutData } from "@e-commerce/checkout/order/checkout.order";
import { EComOrderReviewComponent } from "@e-commerce/checkout/order/orderReview/order.review.component";
import { EComOrderShippingMethodComponent } from "@e-commerce/checkout/order/shippingMethod/order.shipping.method.component";
import { EComCartPaymentTypes } from "@e-commerce/common/cart.modes";
import { ECOM_LINKS_CONFIG_TOKEN, EComLinksConfig } from "@e-commerce/ecom.config";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { EComRootService } from "@e-commerce/root/authn/root.authn.service";
import { EComCarrierService } from "@e-commerce/services/carrier.service";
import { EComCartService } from "@e-commerce/services/cart.service";
import { EComCheckoutService } from "@e-commerce/services/checkout.service";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfSessionStorageService } from "@material-framework/storage/session.storage.service";
import { concatMap, map } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComCheckoutOrderComponent" };

const CHECKOUT_DATA_STORAGE_KEY = "checkoutData" as const;

@Component({
  selector: "ecom-checkout-order",
  templateUrl: "./checkout.order.component.html",
  styleUrls: ["./checkout.order.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EComCheckoutOrderComponent extends EComCheckoutBaseComponent implements OnInit, AfterViewInit {
  protected _checkoutData?: EComOrderCheckoutData;
  protected _lockNavigation: boolean = false;

  public constructor(
    protected override _injector: Injector,
    protected override _rootService: EComRootService,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected _cartService: EComCartService,
    protected _carrierService: EComCarrierService,
    protected _customerService: EComCustomerService,
    protected _checkoutService: EComCheckoutService,
    protected _storageService: MfSessionStorageService,
    @Inject(ECOM_LINKS_CONFIG_TOKEN)
    protected _linksConfig: EComLinksConfig,
  ) {
    super(TYPE_INFO, _injector, _rootService);
    this._getQueryParams();
  }

  public ngOnInit(): void {
    this._getCheckoutData();
  }

  public ngAfterViewInit(): void {
    this._routerActivate();
  }

  protected _getQueryParams(): void {
    this._sub(this._activatedRoute.queryParams, {
      next: (queryParams) => {
        this._lockNavigation = coerceBooleanProperty(queryParams[EcomRouteNames.queryParamNameLockNavigation]);
      },
    });
  }

  protected _summaryContinueClick(): void {
    if (!mfTypeIsUndefined(this._routerOutletComponent)) {
      if (this._isEComShippingMethodComponent(this._routerOutletComponent)) {
        this._router.navigate([EcomRouteNames.partNameBillingPayment], { relativeTo: this._activatedRoute });
        return;
      }
      if (this._isEComBillingPaymentComponent(this._routerOutletComponent)) {
        this._beginPayment();
        if (this._checkoutData?.paymentMethod === EComCartPaymentTypes.creditCard) {
          this._beginPayment();
        } else {
          this._router.navigate([EcomRouteNames.partNameOrderReview], { relativeTo: this._activatedRoute });
        }
        return;
      }
      if (this._isEComOrderReviewComponent(this._routerOutletComponent)) {
        this._completeOrder();
      }
    }
  }

  protected _beginPayment(): void {
    if (!mfTypeIsUndefined(this._checkoutData)) {
      this._storageService.addUpdate(CHECKOUT_DATA_STORAGE_KEY, this._checkoutData);
      const loading = this._subLoading(this._cartService.beginPayment(this._checkoutData), {
        next: () => { loading.complete(); },
        error: () => { loading.complete(); }
      }, "Start Payment");
    }
  }

  protected _getCheckoutData(): void {
    const storeCheckoutData = this._storageService.get<EComOrderCheckoutData>(CHECKOUT_DATA_STORAGE_KEY);

    if (!mfTypeIsUndefined(storeCheckoutData)) {
      this._checkoutData = storeCheckoutData;
    } else {
      const sub$ = this._carrierService.carriers.pipe(
        concatMap((carriers) => {
          return this._customerService.details.pipe(
            concatMap((customerDetails) => {
              return this._checkoutService.summery.pipe(
                map((checkoutSummery) => {
                  this._checkoutData = {
                    customerDetail: customerDetails,
                    carrierKey: customerDetails.primaryCarrierKey,
                    carrierName: customerDetails.primaryCarrierName,
                    paymentMethod: EComCartPaymentTypes.account,
                    purchaseOrderRef: "",
                    carriers: carriers,
                    summery: checkoutSummery,
                  };
                  if (!mfTypeIsUndefined(this._routerOutletComponent)) {
                    this._routerOutletComponent.checkoutData = this._checkoutData;
                  }
                })
              );
            })
          );
        })
      );

      const loading = this._subLoading(sub$, {
        next: () => {
          loading.complete();
        }
      }, "Loading Checkout");
    }
  }

  protected _completeOrder(): void {
    if (!mfTypeIsUndefined(this._checkoutData)) {
      const loading = this._subLoading(this._cartService.complete(this._checkoutData), {
        next: () => {
          this._storageService.remove(CHECKOUT_DATA_STORAGE_KEY);
          loading.complete();
          this._router.navigate([EcomRouteNames.routeOrderConfirmation]);
        },
        error: () => {
          this._storageService.remove(CHECKOUT_DATA_STORAGE_KEY);
          loading.complete();
        }
      }, "Placing Order");
    }
  }

  protected _setComponentChangeStates(routerOutlet: RouterOutlet): void {
    setTimeout(() => {
      const component = routerOutlet.component;
      if (isStepComponent<EComOrderCheckoutData>(component)) {
        component.checkoutData = this._checkoutData;

        if (isValidatingComponent<EComOrderCheckoutData>(component)) {
          this._formGroup = component.formGroup;
        } else {
          this._formGroup = new FormGroup([]);
        }

        if (this._isEComOrderReviewComponent(component)) {
          this._summaryButtonLabel = "Place Order";
          this._summaryShowTC = true;
        } else {
          this._summaryButtonLabel = "Continue";
          this._summaryShowTC = false;
        }

        this._routerOutletComponent = component;
      }
    }, 1);
  }

  protected _isEComShippingMethodComponent(component: object): component is EComOrderShippingMethodComponent {
    if (component instanceof EComOrderShippingMethodComponent) {
      return true;
    }
    return false;
  }

  protected _isEComBillingPaymentComponent(component: object): component is EComOrderBillingPaymentComponent {
    if (component instanceof EComOrderBillingPaymentComponent) {
      return true;
    }
    return false;
  }

  protected _isEComOrderReviewComponent(component: object): component is EComOrderReviewComponent {
    if (component instanceof EComOrderReviewComponent) {
      return true;
    }
    return false;
  }
}