import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { EComModule } from "@e-commerce/ecom.module";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";



if (ECOM_ENVIRONMENT.production === true) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(EComModule)
  .catch(err => console.error(err));