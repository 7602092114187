import { Component, Injector } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { EComCheckoutStepValidationBaseComponent } from "@e-commerce/checkout/base/checkout.step.validation.base.component";
import { EComOrderCheckoutData } from "@e-commerce/checkout/order/checkout.order";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { ERROR_MESSAGE_REQUIRED } from "@material-framework/common/validation/validation.const";
import { MfSelectOption } from "@material-framework/select/select.option";

const TYPE_INFO: MfTypeInfo = { className: "EComOrderShippingMethodComponent" };

@Component({
  selector: "ecom-order-shipping.method",
  templateUrl: "./order.shipping.method.component.html",
  styleUrls: ["./order.shipping.method.component.scss"]
})
export class EComOrderShippingMethodComponent extends EComCheckoutStepValidationBaseComponent<EComOrderCheckoutData> {
  public formGroup: FormGroup = new FormGroup([]);
  protected _shippingMethodFormControl = new FormControl();
  protected readonly _shippingMethodName = "shippingMethod" as const;
  protected _carrierOptions: MfSelectOption<string>[] = [];

  public constructor(
    protected override _injector: Injector,
    protected _customerService: EComCustomerService,
  ) {
    super(TYPE_INFO, _injector);
    this._buildFormGroup();
  }

  protected _buildFormGroup(): void {
    this._addFormControlToFormGroup(this.formGroup, this._shippingMethodName, this._shippingMethodFormControl);
    this._addValidation(this._shippingMethodName, this._shippingMethodFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._shippingMethodName, this._shippingMethodFormControl);
    this._sub(this._shippingMethodFormControl.valueChanges, {
      next: () => {
        if (!mfTypeIsUndefined(this.checkoutData)) {
          this.checkoutData.carrierKey = this._shippingMethodFormControl.value;
          const carrier = this.checkoutData.carriers.find(i => i.carrierKey === this.checkoutData!.carrierKey);
          if (!mfTypeIsUndefined(carrier)) {
            this.checkoutData.carrierName = carrier.carrierName;
          }
        }
      }
    });
  }

  protected override _onCheckoutModelSet(model: EComOrderCheckoutData): void {
    this._setFormControlValue(this._shippingMethodFormControl, model.carrierKey);
    this._carrierOptions = model.carriers.map(i => ({ value: i.carrierKey, label: i.carrierName }));
  }
}