<div class="mf-view-manager-container">
  <mat-form-field *ngIf="config.controls.showSelect"
                  class="mf-slim-mdc-form-field">
    <mf-select (onOptionChanged)="_onOptionChanged($event)"
               [value]="viewState?.id"
               [clearEnabled]="false"
               [options]="_options"
               [matTooltip]="config.controls.selectTooltip"
               [placeholder]="config.controls.selectPlaceholder"></mf-select>
  </mat-form-field>
  <mat-slide-toggle *ngIf="config.controls.showDefaultToggle"
                    class="mf-view-manager-default"
                    [disabled]="_noSelection || viewState?.isDefault"
                    [checked]="_isUndefinedThenDefault(viewState?.isDefault, false)"
                    (toggleChange)="_onToggleDefault()"
                    [matTooltip]="config.controls.defaultTooltip">
    {{ config.controls.defaultDisplayName }}
  </mat-slide-toggle>
  <div *ngIf="config.controls.showSave"
       [matTooltip]="config.controls.saveTooltip">
    <button [@viewNeedsSaving]="viewState?.isDefaultViewState === false && _viewNeedsSavingState"
            class="mf-view-manager-save"
            mat-icon-button
            [disabled]="_noSelection || viewState?.isDefaultViewState === true"
            (click)="_onSaveClicked()">
      <mf-icon [icon]="config.controls.saveIcon"></mf-icon>
    </button>
  </div>
  <div *ngIf="config.controls.showSaveAs"
       matTooltip="{{config.controls.saveAsTooltip}}">
    <button class="mf-view-manager-saveas"
            mat-icon-button
            (click)="_onSaveAsClicked()">
      <mf-icon [icon]="config.controls.saveAsIcon"></mf-icon>
    </button>
  </div>
  <div *ngIf="config.controls.showDelete"
       matTooltip="{{config.controls.deleteTooltip}}">
    <button class="mf-view-manager-delete"
            mat-icon-button
            [disabled]="_noSelection || _options.length < 2 || viewState?.isDefaultViewState === true"
            (click)="_onDeleteClicked()">
      <mf-icon [icon]="config.controls.deleteIcon"></mf-icon>
    </button>
  </div>
</div>