import { FocusMonitor } from "@angular/cdk/a11y";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { A, NINE, SPACE, Z, ZERO } from "@angular/cdk/keycodes";
import {
  booleanAttribute,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl } from "@angular/forms";
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from "@angular/material/form-field";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfOptionValueTypes, mfSortOptions } from "@material-framework/common/option/base.option";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsArray, mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MF_SELECT_CONFIG_TOKEN, MfSelectConfig } from "@material-framework/select/select.config";
import { MfSelectOption } from "@material-framework/select/select.option";
import { MfSelectOptionDirective } from "@material-framework/select/select.option.directive";
import { Subject } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "MfSelectComponent" };


@Component({
  selector: "mf-select",
  templateUrl: "select.component.html",
  styleUrls: ["select.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MatFormFieldControl, useExisting: MfSelectComponent }],
})
export class MfSelectComponent<TOptionValue extends MfOptionValueTypes> extends MfBaseComponent implements ControlValueAccessor, MatFormFieldControl<TOptionValue | TOptionValue[] | undefined>, OnInit, OnDestroy {
  @ViewChild("select")
  public select?: MatSelect;

  @Input()
  public noResultsMessage = "No results";

  @Input()
  public noOptionsMessage?: string;

  @Input({ transform: booleanAttribute })
  public get multiple(): boolean {
    return this._multiple;
  }
  public set multiple(value: BooleanInput) {
    this._multiple = coerceBooleanProperty(value);
  }

  @Input({ transform: booleanAttribute })
  public get caseSensitive(): boolean {
    return this._caseSensitive;
  }
  public set caseSensitive(value: BooleanInput) {
    this._caseSensitive = coerceBooleanProperty(value);
  }

  @Input({ transform: booleanAttribute })
  public get hideLabel(): boolean {
    return this._hideLabel;
  }
  public set hideLabel(value: BooleanInput) {
    this._hideLabel = coerceBooleanProperty(value);
  }

  @Input({ transform: booleanAttribute })
  public get showSpinner(): boolean {
    return this._showSpinner;
  }
  public set showSpinner(value: BooleanInput) {
    this._showSpinner = coerceBooleanProperty(value);
  }

  @Input()
  public get placeholder(): string {
    return this._placeholder;
  }
  public set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  public get label(): string | undefined {
    return this._label;
  }
  public set label(value: string | undefined) {
    this._label = value;
  }

  @Input()
  public get searchPlaceholder(): string {
    return this._searchPlaceholder;
  }
  public set searchPlaceholder(value: string) {
    this._searchPlaceholder = value;
    this.stateChanges.next();
  }

  @Input()
  public get options(): MfSelectOption<TOptionValue>[] | undefined {
    return this._options;
  }
  public set options(value: MfSelectOption<TOptionValue>[] | undefined) {
    this._options = value;
    if (!mfTypeIsUndefined(this._options)) {
      this.filteredOptions = mfSortOptions(this._options.slice());
    }
  }

  @Input()
  public get value(): TOptionValue | TOptionValue[] | undefined {
    return this._value;
  }
  public set value(value: TOptionValue | TOptionValue[] | undefined) {
    this._value = value;
    this.stateChanges.next();
  }

  @Input({ transform: booleanAttribute })
  public get required(): boolean {
    return this._required;
  }
  public set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input({ transform: booleanAttribute })
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input({ transform: booleanAttribute })
  public get clearEnabled(): boolean {
    return this._clearEnabled;
  }
  public set clearEnabled(value: BooleanInput) {
    this._clearEnabled = coerceBooleanProperty(value);
  }

  @Output()
  public onOptionChanged: EventEmitter<MfSelectOption<TOptionValue> | undefined> = new EventEmitter();

  @Output()
  public onOptionsChanged: EventEmitter<MfSelectOption<TOptionValue>[] | undefined> = new EventEmitter();

  @Output()
  public onEnterKey: EventEmitter<boolean> = new EventEmitter();

  @ContentChildren(MfSelectOptionDirective<TOptionValue>)
  protected get _selectOptionDirectives(): QueryList<MfSelectOptionDirective<TOptionValue>> | undefined {
    return this._selectOptionDirectivesList;
  }
  protected set _selectOptionDirectives(value: QueryList<MfSelectOptionDirective<TOptionValue>> | undefined) {
    this._selectOptionDirectivesList = value;
    if (!mfTypeIsUndefined(this._selectOptionDirectivesList)) {
      this._updateOptionsFromDirectives();
      this._sub(this._selectOptionDirectivesList.changes, { next: () => this._updateOptionsFromDirectives() });
    }
  }

  public stateChanges = new Subject<void>();
  public focused = false;
  public touched = false;
  public noOptions = false;
  public noResults = false;
  public localSpinner = false;
  public filteredOptions: MfSelectOption<TOptionValue>[] = [];
  public searchForm: FormGroup;
  public controlType?: string;
  public autofill?: boolean;

  protected _value?: TOptionValue | TOptionValue[];
  protected _options?: MfSelectOption<TOptionValue>[];
  protected _label?: string;
  protected _placeholder = "";
  protected _searchPlaceholder = "";
  protected _required = false;
  protected _disabled = false;
  protected _multiple = false;
  protected _showSpinner = true;
  protected _hideLabel = false;
  protected _caseSensitive = false;
  protected _clearEnabled = true;
  protected _selectOptionDirectivesList?: QueryList<MfSelectOptionDirective<TOptionValue>>;

  public constructor(
    protected override _injector: Injector,
    protected _elementRef: ElementRef<HTMLElement>,
    protected _focusMonitor: FocusMonitor,
    protected _formBuilder: FormBuilder,
    @Inject(MF_SELECT_CONFIG_TOKEN)
    public config: MfSelectConfig,
    @Optional() @Self()
    public ngControl: NgControl,
    @Optional() @Inject(MAT_FORM_FIELD)
    public formField: MatFormField,
  ) {
    super(TYPE_INFO, _injector);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this as ControlValueAccessor;
    }

    this.searchForm = _formBuilder.group({
      value: ""
    });
  }

  public onChange = (value: TOptionValue) => value;

  public onTouched = () => { };

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.stateChanges.complete();
  }

  public ngOnInit(): void {
    this._sub(this.searchForm.valueChanges, {
      next: (values) => this._onSearchFormValueChanges(values["value"])
    });
  }

  public setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector(
      ".mf-select-container",
    )!;
    controlElement.setAttribute("aria-describedby", ids.join(" "));
  }

  public onContainerClick(): void {
    if (!mfTypeIsUndefined(this.select)) {
      this._focusMonitor.focusVia(this.select._elementRef, "program");
      this.select.open();
    }
  }

  public writeValue(value: TOptionValue | TOptionValue[] | undefined): void {
    this.value = value;
  }

  public registerOnChange(fn: (value: TOptionValue) => TOptionValue): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public get errorState(): boolean {
    if (!mfTypeIsNullOrUndefined(this.ngControl)) {
      return this.ngControl.invalid === true && this.touched;
    }
    return false;
  }

  public get empty(): boolean {
    return mfTypeIsUndefined(this.value) || (mfTypeIsArray(this.value) && this.value.length === 0);
  }

  public get shouldLabelFloat(): boolean {
    return this.focused === true || !this.empty;
  }

  public setSelectedOption(value?: TOptionValue | TOptionValue[]): void {
    this.value = value;
    this.onChange(this.value as TOptionValue);
    if (mfTypeIsArray(value)) {
      this.onOptionsChanged.emit(this.options?.filter(o => value.some(v => v === o.value)));
    } else {
      this.onOptionChanged.emit(this.options?.find(o => o.value === value));
    }
  }

  protected _onClearSearchClicked(): void {
    this.searchForm.controls["value"].setValue("");
  }

  protected _onFocusIn(): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  protected _onFocusOut(event: FocusEvent): void {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  protected _onSelectionChange(event: MatSelectChange): void {
    this.setSelectedOption(event.value);
  }

  protected _onClearValueClicked(event: MouseEvent): void {
    this.setSelectedOption();
    event.stopPropagation();
  }

  protected _onSearchKeydown(event: KeyboardEvent) {
    if ((event.key && event.key.length === 1) ||
      (event.keyCode >= A && event.keyCode <= Z) ||
      (event.keyCode >= ZERO && event.keyCode <= NINE) ||
      (event.keyCode === SPACE)) {
      event.stopPropagation();
    }
  }

  protected _onSearchFormValueChanges(value: string): void {
    if (!mfTypeIsUndefined(this.options)) {
      if (this.showSpinner) {
        this.localSpinner = true;
      }

      if (value) {
        this.filteredOptions = this.options.filter(option => (this.caseSensitive ? option.label : option.label.toLowerCase()).includes((this.caseSensitive ? value : value.toLowerCase())));
        this.noResults = this.filteredOptions == null || this.filteredOptions.length === 0;
      } else {
        this.filteredOptions = this.options.slice();
        this.noResults = false;
      }

      this.filteredOptions = mfSortOptions(this.filteredOptions);

      setTimeout(() => {
        if (this.showSpinner) {
          this.localSpinner = false;
        }
      }, 2000);
    }
  }

  protected _updateOptionsFromDirectives(): void {
    if (!mfTypeIsUndefined(this._selectOptionDirectivesList) && this._selectOptionDirectivesList.length > 0) {
      if (mfTypeIsUndefined(this._options)) {
        this._options = [];
      }

      const dirLength = this._selectOptionDirectivesList.length;
      for (let dirIndex = 0; dirIndex < dirLength; dirIndex++) {
        const selectOptionDirective = this._selectOptionDirectivesList.get(dirIndex);
        if (!mfTypeIsUndefined(selectOptionDirective) && !mfTypeIsUndefined(selectOptionDirective.value) && !mfTypeIsUndefined(selectOptionDirective.label)) {
          const option = { value: selectOptionDirective.value, label: selectOptionDirective.label };
          if (!this._options.some(i => i.label === option.label && i.value === option.value)) {
            this._options.push(option);
          }
        }
      }

      let optionsLength = this._options.length;
      for (let optionIndex = 0; optionIndex < optionsLength; optionIndex++) {
        const option = this._options[optionIndex];
        const dir = this._selectOptionDirectivesList.find(i => i.label === option.label && i.value === option.value);
        if (mfTypeIsUndefined(dir)) {
          this._options.splice(optionIndex, 1);
          optionsLength--;
          optionIndex--;
        }
      }

      this.filteredOptions = mfSortOptions(this._options);
    }
  }
}