import { Component, Inject, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";


const TYPE_INFO: MfTypeInfo = { className: "EComPaypalCancelComponent" };

@Component({
  selector: "ecom-paypal-cancel",
  templateUrl: "./paypal.cancel.component.html",
  styleUrls: ["./paypal.cancel.component.scss"]
})
export class EComPaypalCancelComponent extends MfBaseComponent {
  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
  }


  protected _goCart(): void {
    this._router.navigate([EcomRouteNames.routeShopCartList]);
  }
}