import { InjectionToken } from "@angular/core";

export const MF_DIALOG_CONFIG_TOKEN = new InjectionToken<MfDialogConfig>("MfDialogConfig");
export const MF_DIALOG_DEFAULT_CONFIG: MfDialogConfig = {
  okCancel: {
    okDisplayName: "Ok",
    cancelDisplayName: "Cancel",
  }
};

export type MfDialogConfig = {
  okCancel: MfDialogOkCancelDialog;
}

export type MfDialogOkCancelDialog = {
  okDisplayName?: string;
  cancelDisplayName?: string;
}