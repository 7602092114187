import { Injectable, Injector } from "@angular/core";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfFunctionPointer, mfFunctionRefGetRegisteredFunctionRef } from "@material-framework/functionRef/function.ref";
import { MfModelBase } from "@material-framework/model/model.base";
import { Observable, of } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "MfFunctionRefService" };

@Injectable()
export class MfFunctionRefService extends MfBaseService {
  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }

  public getValueForModel<TReturn, TModel extends MfModelBase, TArg2 = unknown, TArg3 = unknown, TArg4 = unknown, TArg5 = unknown>(
    model: TModel,
    functionPointer: MfFunctionPointer<TReturn, TModel, TArg2, TArg3, TArg4, TArg5>,
  ): Observable<TReturn> {
    const funcRef = mfFunctionRefGetRegisteredFunctionRef<TReturn, TModel, TArg2, TArg3, TArg4, TArg5>(functionPointer);

    if (funcRef.isObservable === true) {
      return (funcRef.function(this._injector, model) as Observable<TReturn>);
    } else {
      return of(funcRef.function(this._injector, model) as TReturn);
    }
  }
}