import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";

export type EComCheckoutSummeryRequestModel = MfModelBase & {
  key: string;
  cartKey: string;
}

export type EComCheckoutSummeryModel = MfModelBase & {
  totalItems?: number;
  totalQuantity?: number;
  netTotal?: number;
  taxTotal?: number;
  freightTotal?: number;
  orderTotal?: number;
  currencyCode?: string;
}

export type EComCheckoutSummeryModelFieldsConfig = {
  [key in keyof Required<EComCheckoutSummeryModel>]: MfModelFieldConfig
};

export const ECOM_CHECKOUT_SUMMERY_MODEL_FIELDS_CONFIG: EComCheckoutSummeryModelFieldsConfig = {
  totalItems: {
    dataType: {
      type: MfModelFieldDataTypes.int,
    }
  },
  totalQuantity: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    }
  },
  netTotal: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    }
  },
  taxTotal: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    }
  },
  freightTotal: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    }
  },
  orderTotal: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    }
  },
  currencyCode: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
};

export const ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG_ID = "checkoutSummery";

export const ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG_ID,
  fields: ECOM_CHECKOUT_SUMMERY_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG",
  },
  portals: {}
};