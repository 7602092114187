import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { MfBreadcrumbsComponent } from "@material-framework/breadcrumb/breadcrumb.component";
import { MF_BREADCRUMB_CONFIG_TOKEN, MF_BREADCRUMB_DEFAULT_CONFIG } from "@material-framework/breadcrumb/breadcrumb.config";
import { MfBreadcrumbService } from "@material-framework/breadcrumb/breadcrumb.service";
import { MfIconModule } from "@material-framework/icon/icon.module";

@NgModule({
  declarations: [
    MfBreadcrumbsComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    MfIconModule,
  ],
  exports: [
    MfBreadcrumbsComponent,
  ],
  providers: [
    { provide: MF_BREADCRUMB_CONFIG_TOKEN, useValue: MF_BREADCRUMB_DEFAULT_CONFIG },
    MfBreadcrumbService,
  ],
})
export class MfBreadcrumbModule {
}