import { Component, Inject, Injector, Input } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MF_ROOT_CONFIG_TOKEN, MfRootConfig } from "@material-framework/root/root.config";
import { MfModelConfigMapped, MfModelFieldConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { mfObjectGetPropertyPath } from "@material-framework/common/utils/object.utils";
import { EComOrderModel } from "@e-commerce/modelConfigs/order.model.config";

const TYPE_INFO: MfTypeInfo = { className: "EComOrderDetailDetailsComponent" };

@Component({
  selector: "ecom-order-details",
  templateUrl: "./order.details.component.html",
  styleUrls: ["./order.details.component.scss"]
})
export class EComOrderDetailDetailsComponent extends MfBaseComponent {
  @Input()
  public order?: EComOrderModel;

  protected _orderStatusModelFieldConfig: MfModelFieldConfigMapped;
  protected _orderModelConfig: MfModelConfigMapped;

  public constructor(
    protected override _injector: Injector,
    protected _modelConfigService: MfModelConfigService,
    @Inject(MF_ROOT_CONFIG_TOKEN) public rootConfig: MfRootConfig
  ) {
    super(TYPE_INFO, _injector);
    this._orderModelConfig = this._modelConfigService.get<EComModelsConfig>("order");
    this._orderStatusModelFieldConfig = this._modelConfigService.getModelFieldConfigByFieldKey(this._orderModelConfig.fields, mfObjectGetPropertyPath<EComOrderModel>("status"));
  }
}