<mat-form-field class="mf-slim-mdc-form-field"
                *ngIf="_type === 'number' || _type === 'string'">
  <mf-input [type]="_inputType"
            [maxLength]="_maxLength"
            [minusAllowed]="_minusAllowed"
            [periodAllowed]="_periodAllowed"
            [percentage]="_percentage"
            [placeholder]="_isNullOrUndefinedThenDefault(placeholder, '')"
            [value]="_inputValue"
            [pattern]="_pattern"
            [disabled]="disabled"
            (onChanged)="_onChanged($event)"
            (onEnterKey)="onEnterKey.emit($event)"></mf-input>
</mat-form-field>

<mat-button-toggle-group *ngIf="_type === 'boolean'"
                         class="mf-filter-toolbar-item-and-or"
                         (change)="_onToggleValueChanged($event)"
                         [value]="$any(expression!.value)"
                         [disabled]="disabled">
  <mat-button-toggle [value]="true">True</mat-button-toggle>
  <mat-button-toggle [value]="false">False</mat-button-toggle>
</mat-button-toggle-group>

<mat-form-field *ngIf="_type === 'select' && _multiple === false"
                class="mf-slim-mdc-form-field">
  <mf-select [multiple]="false"
             [disabled]="disabled"
             [value]="$any(expression!.value)"
             [options]="_options"
             (onEnterKey)="onEnterKey.emit($event)"
             (onOptionChanged)="_onSelectOptionChange($event)">
  </mf-select>
</mat-form-field>

<mat-form-field *ngIf="_type === 'select' && _multiple === true"
                class="mf-slim-mdc-form-field">
  <mf-select [multiple]="true"
             [disabled]="disabled"
             [value]="$any(expression!.value)"
             [options]="_options"
             (onEnterKey)="onEnterKey.emit($event)"
             (onOptionsChanged)="_onSelectOptionChange($event)">
  </mf-select>
</mat-form-field>

<mf-datepicker *ngIf="_type === 'date'"
               class="mf-slim-mdc-form-field"
               (onChanged)="_onDatePickerValueChanged($event)"
               [disabled]="disabled"
               [value]="$any(expression!.value)">
</mf-datepicker>