import { Component, Inject, Injector, Input, OnInit, ViewContainerRef, ViewEncapsulation } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfSortTypes } from "@material-framework/common/sort/sort.types";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfTableFieldColumn } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowFieldHeaderCellComponent" };

@Component({
  selector: "mf-table-row-field-header-cell-info",
  templateUrl: "table.row.field.header.cell.info.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowFieldHeaderCellInfoComponent extends MfBaseComponent implements OnInit {

  @Input()
  public fieldColumn?: MfTableFieldColumn;

  @Input()
  public item?: any;

  public sortTypes = MfSortTypes;

  public ascTooltip = "";
  public descTooltip = "";

  public constructor(
    protected override _injector: Injector,
    protected _modelConfigService: MfModelConfigService,
    protected _viewContainerRef: ViewContainerRef,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    protected _config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    if (!mfTypeIsUndefined(this.fieldColumn) && !mfTypeIsUndefined(this.fieldColumn.modelFieldConfig)) {
      const sort = (this._config.row.header.sort as any)[this.fieldColumn.modelFieldConfig.dataType.type];
      if (!mfTypeIsUndefined(sort)) {
        this.ascTooltip = sort.ascTooltip;
        this.descTooltip = sort.descTooltip;
      }
    }
  }

  public get showSortOrder(): boolean {
    return !mfTypeIsUndefined(this.fieldColumn) && !mfTypeIsUndefined(this.fieldColumn.sort) && !mfTypeIsUndefined(this.fieldColumn.sort.index);
  }
}