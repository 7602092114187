import { InjectionToken } from "@angular/core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { MfIcon } from "@material-framework/icon/icon";
import { MfIconTypes } from "@material-framework/icon/icon.types";

export const MF_TABLE_SIMPLE_SEARCH_CONFIG_TOKEN = new InjectionToken<MfTableSimpleSearchConfig>("MfTableSimpleSearchConfig");
export const MF_TABLE_SIMPLE_SEARCH_DEFAULT_CONFIG: MfTableSimpleSearchConfig = {
  searchIcon: { type: MfIconTypes.fontAwesome, icon: faSearch }
};

export type MfTableSimpleSearchConfig = {
  searchIcon: MfIcon;
};