import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Inject, Injector, Input, Output, ViewEncapsulation } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeHasOwnProperty, mfTypeIsArray, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfDialogData } from "@material-framework/dialog/dialog";
import { MfModelFieldsConfig } from "@material-framework/modelConfig/model.config";
import { MF_ROOT_CONFIG_TOKEN, MfRootConfig } from "@material-framework/root/root.config";
import { MfTableFieldColumn, MfTableGroupColumn, MfTableGroupColumnMovingEvent } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableColumnsShowHideOrderComponent" };

export type MfShowHideOrderColumnsDialogData = MfDialogData & {
  columns: MfTableFieldColumn[]
  modelFieldsConfig: MfModelFieldsConfig;
}

@Component({
  selector: "mf-table-columns-show-hide-order",
  templateUrl: "table.columns.show.hide.order.component.html",
  styleUrls: ["table.columns.show.hide.order.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfTableColumnsShowHideOrderComponent extends MfBaseComponent {
  @Input()
  public get groupColumns(): MfTableGroupColumn[] | undefined {
    return this._groupColumns;
  }
  public set groupColumns(value: MfTableGroupColumn[] | undefined) {
    this._groupColumns = value;
  }

  @Input()
  public get modelFieldsConfig(): MfModelFieldsConfig | undefined {
    return this._modelFieldsConfig;
  }
  public set modelFieldsConfig(value: MfModelFieldsConfig | undefined) {
    this._modelFieldsConfig = value;
  }

  @Output()
  public onGroupColumnMoving: EventEmitter<MfTableGroupColumnMovingEvent> = new EventEmitter();

  @Output()
  public onFieldColumnVisibleChanged: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onFieldsColumnVisibleChanged: EventEmitter<MfTableFieldColumn[]> = new EventEmitter();

  @Output()
  public onFieldColumnStickyChanged: EventEmitter<MfTableFieldColumn | MfTableGroupColumn> = new EventEmitter();

  @Output()
  public onClose: EventEmitter<void> = new EventEmitter();

  protected _groupColumns?: MfTableGroupColumn[];
  protected _modelFieldsConfig?: MfModelFieldsConfig;
  protected _isDragging = false;


  public constructor(
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    protected _config: MfTableConfig,
    @Inject(MF_ROOT_CONFIG_TOKEN)
    protected _rootConfig: MfRootConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected _setVisibleAll(column: MfTableGroupColumn, visible: boolean): void {
    const columnsChange: MfTableFieldColumn[] = [];

    column.fieldColumns?.forEach(c => {
      if (c.visible != visible) {
        c.visible = visible;
        columnsChange.push(c);
      }
    });

    if (columnsChange.length > 0) {
      this.onFieldsColumnVisibleChanged.emit(columnsChange);
    }
  }

  protected _onDropListDropped(event: CdkDragDrop<string[], string[], MfTableGroupColumn>): void {
    this.onGroupColumnMoving.emit({ group: event.item.data, toIndex: event.currentIndex, fromIndex: event.previousIndex });
  }

  protected _toggleVisible(fieldColumn: MfTableFieldColumn): void {
    fieldColumn.visible = mfTypeIsUndefined(fieldColumn.visible) ? false : !fieldColumn.visible;
    this.onFieldColumnVisibleChanged.emit(fieldColumn);
  }

  protected _stick(column: MfTableFieldColumn | MfTableGroupColumn): void {
    this._setFieldColumnSticky(column, true);
    if (mfTypeHasOwnProperty(column, "fieldColumns") && mfTypeIsArray(column.fieldColumns) && column.fieldColumns.length > 0) {
      column.fieldColumns.forEach(i => this._setFieldColumnSticky(i as MfTableFieldColumn, true));
    }
    this.onFieldColumnStickyChanged.emit(column);
  }

  protected _stickRemove(column: MfTableFieldColumn | MfTableGroupColumn): void {
    this._setFieldColumnSticky(column, false);
    if (mfTypeHasOwnProperty(column, "fieldColumns") && mfTypeIsArray(column.fieldColumns) && column.fieldColumns.length > 0) {
      column.fieldColumns.forEach(i => this._setFieldColumnSticky(i as MfTableFieldColumn, false));
    }
    this.onFieldColumnStickyChanged.emit(column);
  }

  protected _setFieldColumnSticky(column: MfTableFieldColumn | MfTableGroupColumn, state: boolean): void {
    if (state === true) {
      column.sticky = true;
      column.stickyEnd = true;
    } else {
      delete column.sticky;
      delete column.stickyEnd;
    }

    if (mfTypeHasOwnProperty(column, "groupColumn")) {
      this._setFieldColumnSticky(column.groupColumn as MfTableGroupColumn, state);
    }
  }

  protected _onCloseClicked(): void {
    this.onClose.emit();
  }
}