import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { EComCartPreviewModule } from "@e-commerce/cartPreview/cart.preview.module";
import { EComFooterModule } from "@e-commerce/footer/footer.module";
import { EComRootUnAuthnComponent } from "@e-commerce/root/unauthn/root.unauthn.component";
import { MfAuthModule } from "@material-framework/auth/auth.module";
import { MfFooterModule } from "@material-framework/footer/footer.module";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MfRootModule } from "@material-framework/root/root.module";
import { MfUserPersonaModule } from "@material-framework/userPersona/user.persona.module";


@NgModule({
  declarations: [
    EComRootUnAuthnComponent
  ],
  exports: [
    EComRootUnAuthnComponent
  ],
  imports: [
    BrowserModule,
    MfRootModule,
    MfAuthModule,
    MfUserPersonaModule,
    MfIconModule,
    MfFooterModule,
    MatButtonModule,
    EComCartPreviewModule,
    RouterModule,
    EComFooterModule,
  ],
})
export class EComRootUnAuthnModule {
}