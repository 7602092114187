import { Injectable, Injector } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfStringStartsWith } from "@material-framework/common/utils/string.utils";
import { mfTypeGetKeys, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFilterOperatorTypes } from "@material-framework/filter/filter.types";
import { MFModelConfigFieldPath, MfModelConfigMapped } from "@material-framework/modelConfig/model.config";
import { MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR, MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfTableQueryFilterParam, MfTableQueryFilterParamNameParts } from "@material-framework/table/queryFilter/table.query.filter";
import { MF_TABLE_LOCATION_KEY_PREFIX } from "@material-framework/table/table.component";
import { map, Observable } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "MfTableQueryFilterService" };

@Injectable()
export class MfTableQueryFilterService extends MfBaseService {
  public constructor(
    protected override _injector: Injector,
    protected _activatedRoute: ActivatedRoute,
    protected _modelConfigService: MfModelConfigService,
  ) {
    super(TYPE_INFO, _injector);
  }

  public getQueryParamName(fieldPath: MFModelConfigFieldPath, operator: MfFilterOperatorTypes, locationKey: string): string {
    return `${MF_TABLE_LOCATION_KEY_PREFIX}${MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR}${locationKey}${MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR}${fieldPath}${MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR}${operator}`;
  }

  public applyQueryParamFilters(modelConfig: MfModelConfigMapped, locationKey: string): Observable<MfTableQueryFilterParam[]> {
    return this._activatedRoute.queryParams.pipe(
      map((queryParams) => {
        const tableQueryParams = this._getQueryParams(modelConfig, queryParams, locationKey);
        this._updateModelConfig(tableQueryParams);
        return tableQueryParams;
      })
    );
  }

  protected _getQueryParams(modelConfig: MfModelConfigMapped, queryParams: Params, locationKey: string): MfTableQueryFilterParam[] {
    const keys = mfTypeGetKeys(queryParams);
    const tableKeys = keys.filter(key => mfStringStartsWith(locationKey, key as string));

    const tableQueryParams: MfTableQueryFilterParam[] = [];

    tableKeys.forEach(tableKey => {
      const paramNameParts = this._getFieldPathFromQueryParamKey(tableKey as string, locationKey);
      const modelFieldConfig = this._modelConfigService.findModelFieldConfigByFieldPath(modelConfig.fields, paramNameParts.fieldPath);

      if (!mfTypeIsUndefined(modelFieldConfig)) {
        tableQueryParams.push({
          fieldPath: paramNameParts.fieldPath,
          modelFieldConfig: modelFieldConfig,
          key: tableKey as string,
          operator: paramNameParts.operator,
          value: queryParams[tableKey],
        });
      }
    });

    return tableQueryParams;
  }

  protected _getFieldPathFromQueryParamKey(tableKey: string, locationKey: string): MfTableQueryFilterParamNameParts {
    const endKey = tableKey.replace(`${locationKey}${MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR}`, "");

    const operatorSeparatorIndex = endKey.lastIndexOf(MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR);
    const operator = endKey.substring(operatorSeparatorIndex + 1, endKey.length) as MfFilterOperatorTypes;
    const fieldPath = endKey.substring(0, operatorSeparatorIndex) as MFModelConfigFieldPath;

    return { operator, fieldPath, };
  }

  protected _updateModelConfig(tableQueryParams: MfTableQueryFilterParam[]): void {
    tableQueryParams.forEach(tableQueryParam => {
      if (!mfTypeIsUndefined(tableQueryParam.modelFieldConfig.filter)) {
        tableQueryParam.modelFieldConfig.filter.disabled = true;
      }
    });
  }
}