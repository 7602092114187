import { InjectionToken } from "@angular/core";
import {
  faBackwardFast,
  faBackwardStep,
  faChevronDown,
  faChevronUp,
  faCircleExclamation,
  faCircleInfo,
  faForwardFast,
  faForwardStep,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { MfIcon } from "@material-framework/icon/icon";
import { MfIconTypes } from "@material-framework/icon/icon.types";
import { MfSnackBarMessageTypes } from "@material-framework/snackbar/snackbar";

export const MF_SNACKBAR_CONFIG_TOKEN = new InjectionToken<MfSnackBarConfig>("MfSnackBarConfig");
export const MF_SNACKBAR_DEFAULT_CONFIG: MfSnackBarConfig = {
  showMoreInfoIcon: { type: MfIconTypes.fontAwesome, icon: faChevronUp },
  showMoreInfoTooltip: "Show more info",
  hideMoreInfoIcon: { type: MfIconTypes.fontAwesome, icon: faChevronDown },
  hideMoreInfoTooltip: "Hide more info",
  deleteIcon: { type: MfIconTypes.fontAwesome, icon: faTrash },
  deleteTooltip: "Clear message",
  firstIcon: { type: MfIconTypes.fontAwesome, icon: faBackwardFast },
  firstTooltip: "Goto first message",
  previousIcon: { type: MfIconTypes.fontAwesome, icon: faBackwardStep },
  previousTooltip: "Goto previous message",
  nextIcon: { type: MfIconTypes.fontAwesome, icon: faForwardStep },
  nextTooltip: "Goto next message",
  lastIcon: { type: MfIconTypes.fontAwesome, icon: faForwardFast },
  lastTooltip: "Goto last message",
  types: {
    info: {
      icon: { type: MfIconTypes.fontAwesome, icon: faCircleInfo },
      color: "#1B5E20",
    },
    error: {
      icon: { type: MfIconTypes.fontAwesome, icon: faCircleExclamation },
      color: "#BF360C",
    },
    errorMf: {
      icon: { type: MfIconTypes.fontAwesome, icon: faCircleExclamation },
      color: "#BF360C",
    }
  }
};

export type MfSnackBarConfig = {
  showMoreInfoIcon: MfIcon;
  showMoreInfoTooltip: string;
  hideMoreInfoIcon: MfIcon;
  hideMoreInfoTooltip: string;
  deleteIcon: MfIcon;
  deleteTooltip: string;
  firstIcon: MfIcon;
  firstTooltip: string;
  previousIcon: MfIcon;
  previousTooltip: string;
  nextIcon: MfIcon;
  nextTooltip: string;
  lastIcon: MfIcon;
  lastTooltip: string;
  types: MfSnackBarMessageTypesConfig;
}

export type MfSnackBarMessageTypesConfig = {
  [MfSnackBarMessageTypes.info]: MfSnackBarMessageTypeConfig;
  [MfSnackBarMessageTypes.error]: MfSnackBarMessageTypeConfig;
  [MfSnackBarMessageTypes.errorMf]: MfSnackBarMessageTypeConfig;
}

export type MfSnackBarMessageTypeConfig = {
  icon: MfIcon;
  color: string;
}