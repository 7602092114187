import { InjectionToken } from "@angular/core";

export const MF_ERROR_CONFIG_TOKEN = new InjectionToken<MfErrorConfig>("MfErrorConfig");
export const MF_ERROR_DEFAULT_CONFIG: MfErrorConfig = {
  showInSnackbar: true,
  logErrorToConsole: true,
};

export type MfErrorConfig = {
  showInSnackbar?: boolean;
  logErrorToConsole: boolean;
}