<div class="mf-menu">
  <!-- <div class="mf-menu-header">
    menu-header
  </div> -->
  <div class="mf-menu-tree">
    <cdk-tree [dataSource]="_dataSource"
              [treeControl]="_treeControl">
      <cdk-nested-tree-node class="mf-menu-tree-node"
                            *cdkTreeNodeDef="let node"
                            [matTooltip]="_isUndefinedThenDefault(node.tooltip,'')"
                            [routerLink]="node.routerLink?.link"
                            [queryParams]="node.routerLink?.params"
                            [fragment]="node.routerLink?.fragment">
        <div class="mf-menu-tree-item">
          <div [style.marginLeft.px]="node.depth * 8"
               class="mf-menu-tree-end">
            <div class="mf-default-margin-right">
              <mf-icon [icon]="node.icon"></mf-icon>
            </div>
            <div class="mf-menu-tree-label">
              {{node.label}}
            </div>
          </div>
        </div>
      </cdk-nested-tree-node>
      <cdk-nested-tree-node class="mf-menu-tree-node"
                            [matTooltip]="_isUndefinedThenDefault(node.tooltip,'')"
                            *cdkTreeNodeDef="let node; when: _hasChild">
        <div class="mf-menu-tree-item">
          <div [style.marginLeft.px]="node.depth * 8"
               cdkTreeNodeToggle
               class="mf-menu-tree-expandable">
            <div class="mf-default-margin-right">
              <mf-icon [icon]="node.icon"></mf-icon>
            </div>
            <div class="mf-menu-tree-label">
              {{node.label}}
            </div>
            <div class="mf-spacer"></div>
            <div class="mf-menu-tree-chevron">
              <mf-icon [icon]="_treeControl.isExpanded(node) ? config.expandIcon : config.collapseIcon"></mf-icon>
            </div>
          </div>
        </div>

        <div [class.mf-menu-tree-invisible]="!_treeControl.isExpanded(node)">
          <ng-container cdkTreeNodeOutlet></ng-container>
        </div>
      </cdk-nested-tree-node>
    </cdk-tree>
  </div>
  <!-- <div class="mf-menu-footer">
    menu-footer
  </div> -->
</div>