<div class="ecom-order-summary-container">
  <div class="ecom-title mat-headline-5">
    {{title}}
  </div>
  <div class="ecom-summary">
    <table cellPadding="0"
           cellSpacing="0">
      <tr>
        <td class="ecom-label">Items</td>
        <td class="ecom-value">
          {{ _isNullOrUndefinedThenDefault(checkoutData?.summery?.totalItems, 0) | mfInt }}
        </td>
      </tr>
      <tr>
        <td class="ecom-label">Total Qty</td>
        <td class="ecom-value">
          {{ _isNullOrUndefinedThenDefault(checkoutData?.summery?.totalQuantity, 0) | mfInt }}
        </td>
      </tr>
      <tr>
        <td class="ecom-label">Currency</td>
        <td class="ecom-value">
          {{ _isNullOrUndefinedThenDefault(checkoutData?.summery?.currencyCode, "USD") }}
        </td>
      </tr>
      <tr>
        <td class="ecom-label">Subtotal</td>
        <td class="ecom-value">
          {{ _isNullOrUndefinedThenDefault(checkoutData?.summery?.netTotal, 0) | mfCurrency }}
        </td>
      </tr>
      <!-- <tr class="ecom-taxes">
        <td class="ecom-label">Shipping</td>
        <td class="ecom-value">
          {{
          _isNullOrUndefined(checkoutData?.summery?.freightTotal) ?
          "TBD" :
          checkoutData?.summery?.freightTotal | mfCurrency
          }}
        </td>
      </tr>
      <tr class="ecom-taxes">
        <td class="ecom-label">Taxes</td>
        <td class="ecom-value">
          {{
          _isNullOrUndefined(checkoutData?.summery?.taxTotal) ?
          "TBD" :
          checkoutData?.summery?.taxTotal | mfCurrency
          }}
        </td>
      </tr> -->
      <tr class="ecom-total">
        <td class="ecom-label">
          Total Value
        </td>
        <td class="ecom-value">
          {{ _isNullOrUndefinedThenDefault(checkoutData?.summery?.orderTotal, 0 ) | mfCurrency }}
        </td>
      </tr>
    </table>
  </div>
  <div class="mf-large-margin-top"
       [style.visibility]="showTC === true ? 'unset' : 'hidden'">
    <mat-checkbox style="max-width: 280px;"
                  (change)="tcCheckedChanged($event)"
                  [checked]="tcChecked">
      {{ tcValue }} <a *ngIf="!_isEmptyOrWhitespace(tcLink)"
         [href]="tcLink"
         target="_blank">Terms & Conditions</a>
    </mat-checkbox>
  </div>
  <div class="ecom-actions">
    <button [disabled]="(showTC === true && tcChecked === false) || _isUndefined(formGroup) || formGroup.invalid"
            (click)="continueClick()"
            color="accent"
            class="mf-x-large-margin-top"
            style="width: 100%;"
            mat-raised-button>{{buttonLabel}}</button>
  </div>
</div>