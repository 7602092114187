/* eslint-disable @typescript-eslint/naming-convention */
import { InjectionToken } from "@angular/core";
import { TinyMCE } from "tinymce";

declare type EditorOptions = Parameters<TinyMCE["init"]>[0];

export const MF_TINY_MCE_EDITOR_CONFIG_TOKEN = new InjectionToken<MfTinyMCEEditorConfig>("MfTinyMCEEditorConfig");
export const MF_TINY_MCE_EDITOR_DEFAULT_CONFIG: MfTinyMCEEditorConfig = {
  init: {
    base_url: "/tinymce",
    plugins: "image lists advlist anchor link autolink emoticons insertdatetime link lists pagebreak searchreplace table charmap",
    selector: "textarea",
    toolbar: "undo redo | fontfamily fontsize styles | bold italic  | alignleft aligncenter alignright alignjustify | numlist bullist",
    menu: {
      file: { title: "File", items: "export print" }
    },
    promotion: false,
    branding: false,
    browser_spellcheck: true,
  }
};

export type MfTinyMCEEditorConfig = {
  init: EditorOptions;
}