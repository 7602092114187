import { Params } from "@angular/router";
import { EComPayPalPaymentInfo } from "@e-commerce/modelConfigs/checkout.order.payment.model.config";

//https://developer.paypal.com/api/nvp-soap/payflow/integration-guide/transaction-responses/

const ACCT = "ACCT" as const;
const ADD_L_MSGS = "ADDLMSGS" as const;
const AMEX_ID = "CORRELATIONID" as const;
const AMEX_POS_DATA = "AMEXPOSDATA" as const;
const AMT = "AMT" as const;
const AUTH_CODE = "AUTHCODE" as const;
const AVS_ADDR = "AVSADDR" as const;
const AVS_DATA = "AVSDATA" as const;
const AVS_ZIP = "AVSZIP" as const;
const BILL_TO_COUNTRY = "BILLTOCOUNTRY" as const;
const BILL_TO_EMAIL = "BILLTOEMAIL" as const;
const BILL_TO_FIRST_NAME = "BILLTOFIRSTNAME" as const;
const BILL_TO_LAST_NAME = "BILLTOLASTNAME" as const;
const BILL_TO_NAME = "BILLTONAME" as const;
const CARD_TYPE = "CARDTYPE" as const;
const CC_TRANS_ID = "CCTRANSID" as const;
const CC_TRANS_POS_DATA = "CCTRANS_POSDATA" as const;
const CORRELATION_ID = "CORRELATIONID" as const;
const COUNTRY = "COUNTRY" as const;
const CVV2MATCH = "CVV2MATCH" as const;
const DATE_TO_SETTLE = "DATE_TO_SETTLE" as const;
const DUPLICATE = "DUPLICATE" as const;
const EMAIL = "EMAIL" as const;
const EMAIL_MATCH = "EMAILMATCH" as const;
const EXP_DATE = "EXPDATE" as const;
const EXT_RSP_MSG = "EXTRSPMSG" as const;
const FIRST_NAME = "FIRSTNAME" as const;
const HOST_CODE = "HOSTCODE" as const;
const I_AVS = "IAVS" as const;
const INV_NUM = "INVNUM" as const;
const INVOICE = "INVOICE" as const;
const LAST_NAME = "LASTNAME" as const;
const METHOD = "METHOD" as const;
const NAME = "NAME" as const;
const ORIG_AMT = "ORIGAMT" as const;
const PAYMENT_ADVICE_CODE = "PAYMENTADVICECODE" as const;
const PAYMENT_TYPE = "PAYMENTTYPE" as const;
const PHONE_MATCH = "PHONEMATCH" as const;
const PN_REF = "PNREF" as const;
const PP_REF = "PPREF" as const;
const PROC_AVS = "PROCAVS" as const;
const PROC_CARD_SECURE = "PROCCARDSECURE" as const;
const PROC_CVV_2 = "PROCCVV2" as const;
const RESP_MSG = "RESPMSG" as const;
const RESP_TEXT = "RESPTEXT" as const;
const RESULT = "RESULT" as const;
const SECURE_TOKEN = "SECURETOKEN" as const;
const SECURE_TOKEN_ID = "SECURETOKENID" as const;
const TAX = "TAX" as const;
const TENDER = "TENDER" as const;
const TRANS_STATE = "TRANSSTATE" as const;
const TRANS_TIME = "TRANSTIME" as const;
const TRX_TYPE = "TRXTYPE" as const;
const TYPE = "TYPE" as const;
const VISA_CARD_LEVEL = "VISACARDLEVEL" as const;

export function eComPayPalGetPaymentInfoFromQueryPrams(queryParams: Params): EComPayPalPaymentInfoExtended {
  return {
    acct: queryParams[ACCT],
    addLMsgs: queryParams[ADD_L_MSGS],
    address: "",
    amexId: queryParams[AMEX_ID],
    amexPosData: queryParams[AMEX_POS_DATA],
    amt: queryParams[AMT],
    authCode: queryParams[AUTH_CODE],
    avsAddr: queryParams[AVS_ADDR],
    avsData: queryParams[AVS_DATA],
    avsZip: queryParams[AVS_ZIP],
    billToCity: "",
    //billToCountry: queryParams[BILL_TO_COUNTRY],
    billToCountry: 0,
    billToEmail: queryParams[BILL_TO_EMAIL],
    billToFirstName: queryParams[BILL_TO_FIRST_NAME],
    billToLastName: queryParams[BILL_TO_LAST_NAME],
    billToName: queryParams[BILL_TO_NAME],
    billToPhone: "",
    billToState: "",
    billToStreet: "",
    billToZip: "",
    cardType: queryParams[CARD_TYPE],
    ccTransId: queryParams[CC_TRANS_ID],
    ccTransPosData: queryParams[CC_TRANS_POS_DATA],
    city: "",
    correlationId: queryParams[CORRELATION_ID],
    //country: queryParams[COUNTRY],
    country: 0,
    cvv2match: queryParams[CVV2MATCH],
    dateToSettle: queryParams[DATE_TO_SETTLE],
    duplicate: queryParams[DUPLICATE],
    email: queryParams[EMAIL],
    emailMatch: queryParams[EMAIL_MATCH],
    expDate: queryParams[EXP_DATE],
    extRspMsg: queryParams[EXT_RSP_MSG],
    firstName: queryParams[FIRST_NAME],
    hostCode: queryParams[HOST_CODE],
    iavs: queryParams[I_AVS],
    invNum: queryParams[INV_NUM],
    invoice: queryParams[INVOICE],
    lastName: queryParams[LAST_NAME],
    method: queryParams[METHOD],
    name: queryParams[NAME],
    origAmt: queryParams[ORIG_AMT],
    paymentAdviceCode: queryParams[PAYMENT_ADVICE_CODE],
    paymentType: queryParams[PAYMENT_TYPE],
    phone: "",
    phoneMatch: queryParams[PHONE_MATCH],
    pnRef: queryParams[PN_REF],
    poNum: "",
    ppRef: queryParams[PP_REF],
    procAvs: queryParams[PROC_AVS],
    procCardSecure: queryParams[PROC_CARD_SECURE],
    procCvv2: queryParams[PROC_CVV_2],
    respMsg: queryParams[RESP_MSG],
    respText: queryParams[RESP_TEXT],
    result: queryParams[RESULT],
    secureToken: queryParams[SECURE_TOKEN],
    secureTokenId: queryParams[SECURE_TOKEN_ID],
    state: "",
    tax: queryParams[TAX],
    tender: queryParams[TENDER],
    transState: queryParams[TRANS_STATE],
    transTime: queryParams[TRANS_TIME],
    trxType: queryParams[TRX_TYPE],
    type: queryParams[TYPE],
    visaCardLevel: queryParams[VISA_CARD_LEVEL],
    zip: "",
  } as EComPayPalPaymentInfoExtended;
}

export type EComPayPalPaymentInfoExtended = EComPayPalPaymentInfo & {
  addLMsgs: string,
  amexId: string,
  amexPosData: string,
  ccTransId: string,
  ccTransPosData: string,
  correlationId: string,
  cvv2match: string,
  dateToSettle: string,
  duplicate: string,
  emailMatch: string,
  extRspMsg: string,
  origAmt: string,
  paymentAdviceCode: string,
  paymentType: string,
  phoneMatch: string,
  ppRef: string,
  procCardSecure: string,
  procCvv2: string,
  transState: string,
};