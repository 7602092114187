import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { booleanAttribute, Component, EventEmitter, Inject, Injector, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeGetKeys, mfTypeHasOwnProperty, mfTypeIsArray, mfTypeIsString, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfDatePickerDataTypes } from "@material-framework/datePicker/date.picker.component";
import { MfFilterBaseComponent } from "@material-framework/filter/base/filter.base.component";
import { MfFilterExpression } from "@material-framework/filter/filter";
import { MF_FILTER_CONFIG_TOKEN, MfFilterConfig } from "@material-framework/filter/filter.config";
import { MfFilterModelInputTypeStringConfig } from "@material-framework/filter/filter.model.config";
import { MfFilterService } from "@material-framework/filter/services/filter.service";
import { MfInputDataTypes } from "@material-framework/input/input.component";
import { MfInputTypes } from "@material-framework/input/input.types";
import { MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfSelectOption } from "@material-framework/select/select.option";

const TYPE_INFO: MfTypeInfo = { className: "MfFilterValueInputComponent" };

// eslint-disable-next-line @typescript-eslint/naming-convention
const MfFilterValueInputTypes = {
  [MfModelFieldDataTypes.string]: MfModelFieldDataTypes.string,
  [MfModelFieldDataTypes.boolean]: MfModelFieldDataTypes.boolean,
  [MfModelFieldDataTypes.date]: MfModelFieldDataTypes.date,
  number: "number",
  select: "select",
  none: "none",
} as const;

export type MfFilterValueInputTypes = typeof MfFilterValueInputTypes[keyof typeof MfFilterValueInputTypes]

@Component({
  selector: "mf-filter-value-input",
  templateUrl: "filter.value.input.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfFilterValueInputComponent extends MfFilterBaseComponent implements OnInit {
  @Input()
  public expression?: MfFilterExpression;

  @Input()
  public placeholder?: string;

  @Input({ transform: booleanAttribute })
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Output()
  public onValueChange: EventEmitter<MfFilterExpression> = new EventEmitter();

  @Output()
  public onEnterKey: EventEmitter<boolean> = new EventEmitter();

  protected _pattern?: RegExp;
  protected _multiple = false;
  protected _type: MfFilterValueInputTypes = MfFilterValueInputTypes.string;
  protected _options: MfSelectOption<number | string>[] = [];
  protected _inputValue?: string;
  protected _periodAllowed: boolean = false;
  protected _percentage: boolean = false;
  protected _minusAllowed: boolean = false;
  protected _disabled: boolean = false;
  protected _maxLength?: number;

  public constructor(
    protected override _injector: Injector,
    protected _modelConfigService: MfModelConfigService,
    protected _filterService: MfFilterService,
    @Inject(MF_FILTER_CONFIG_TOKEN) public config: MfFilterConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    this._setupInput();
    this._setValues();
  }

  public reset(clearValue: boolean = true): void {
    if (!mfTypeIsUndefined(this.expression)) {
      this._setupInput();
      this._setValues();
      if (clearValue === true) {
        const resetValue = this._filterService.getExpressionResetValue(this.expression);
        if (!mfTypeIsUndefined(resetValue)) {
          this._inputValue = resetValue as string;
          this.expression.value = resetValue;
        }
      }
    }
  }

  public update(): void {
    this._setValues();
  }

  protected get _inputType(): MfInputTypes {
    return this._type as MfInputTypes;
  }

  protected _onDatePickerValueChanged(value: MfDatePickerDataTypes): void {
    if (!mfTypeIsUndefined(this.expression)) {
      if (this.expression.modelFieldConfig.dataType.type === MfModelFieldDataTypes.dateTime || this.expression.modelFieldConfig.dataType.type === MfModelFieldDataTypes.date) {
        this.expression.value = value?.toDate();
      } else {
        this.expression.value = value;
      }
      this.onValueChange.emit(this.expression);
    }
  }

  protected _onChanged(value: MfInputDataTypes): void {
    if (!mfTypeIsUndefined(this.expression) && !mfTypeIsUndefined(this.expression.modelFieldConfig.filter)) {
      if (this._multiple === true && mfTypeIsString(value)) {
        if (mfTypeHasOwnProperty(this.expression.modelFieldConfig.filter.input, MfFilterValueInputTypes.number)) {
          this.expression.value = value.split(",").filter(i => !isNaN(Number.parseFloat(i))).map(i => Number.parseFloat(i));
        } else {
          this.expression.value = value.split(",");
        }
      } else {
        this.expression.value = value;
      }
      this.onValueChange.emit(this.expression);
    }
  }

  protected _onToggleValueChanged(event: MatButtonToggleChange): void {
    if (!mfTypeIsUndefined(this.expression)) {
      this.expression.value = event.value;
      this.onValueChange.emit(this.expression);
    }
  }

  protected _onSelectOptionChange(option: MfSelectOption<number | string> | MfSelectOption<number | string>[] | undefined): void {
    if (!mfTypeIsUndefined(this.expression) && !mfTypeIsUndefined(option)) {
      if (mfTypeIsArray(option)) {
        this.expression.value = option.map(o => o.value);
      } else {
        this.expression.value = option.value;
      }
      this.onValueChange.emit(this.expression);
    }
  }

  protected _setValues(): void {
    if (!mfTypeIsUndefined(this.expression) && !mfTypeIsUndefined(this.expression.modelFieldConfig.filter)) {
      if (this._multiple === true && mfTypeIsArray(this.expression.value)) {
        this._inputValue = this.expression.value.join(",");
      } else {
        this._inputValue = this.expression.value as string;
      }
    }
  }

  protected _setupInput(): void {
    if (!mfTypeIsUndefined(this.expression) && !mfTypeIsUndefined(this.expression.modelFieldConfig.filter)) {
      this._pattern = undefined;
      this._options = [];
      this._multiple = false;

      if (this.expression.operatorKey === "in" || this.expression.operatorKey === "nin") {
        this._multiple = true;
      }

      switch (mfTypeGetKeys(this.expression.modelFieldConfig.filter.input)[0]) {
        case MfFilterValueInputTypes.number:
          if (this.expression.operatorKey === "in" || this.expression.operatorKey === "nin") {
            this._type = MfFilterValueInputTypes.string;
          } else {
            this._type = MfFilterValueInputTypes.number;
            this._periodAllowed = this.expression.modelFieldConfig.dataType.type === MfModelFieldDataTypes.decimal;
            this._percentage = !mfTypeIsUndefined(this.expression.modelFieldConfig.dataType.percentage);
            this._minusAllowed = true;
          }
          break;
        case MfFilterValueInputTypes.string:
          this._type = MfFilterValueInputTypes.string;
          if (mfTypeHasOwnProperty(this.expression.modelFieldConfig.filter.input, MfFilterValueInputTypes.string)) {
            const stringConfig = this.expression.modelFieldConfig.filter.input.string as MfFilterModelInputTypeStringConfig;
            if (!mfTypeIsUndefined(stringConfig.maxLength)) {
              this._maxLength = stringConfig.maxLength;
            }
          }
          break;
        case MfFilterValueInputTypes.boolean:
          this._type = MfFilterValueInputTypes.boolean;
          break;
        case MfFilterValueInputTypes.date:
          this._type = MfFilterValueInputTypes.date;
          break;
        case MfFilterValueInputTypes.select:
          this._type = MfFilterValueInputTypes.select;
          if (!mfTypeIsUndefined(this.expression.modelFieldConfig.dataType.enum)) {
            this._options = this.expression.modelFieldConfig.dataType.enum.mappings.map(s => {
              return { value: s.value, label: s.displayValue } as MfSelectOption<number | string>;
            });
          } else if (!mfTypeIsUndefined(this.expression.modelFieldConfig.statusChip)) {
            this._options = this.expression.modelFieldConfig.statusChip.statuses.map(s => {
              return { value: s.value, label: s.displayName } as MfSelectOption<number | string>;
            });
          }
          break;
        case MfFilterValueInputTypes.none:
          if (this.expression?.operatorKey === "any") {
            this._type = MfFilterValueInputTypes.boolean;
          } else {
            this._type = MfFilterValueInputTypes.none;
          }
          break;
      }
    }
  }
}