import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { MF_ERROR_CONFIG_TOKEN, MF_ERROR_DEFAULT_CONFIG } from "@material-framework/error/error.config";
import { MfGlobalErrorHandler, MfErrorService } from "@material-framework/error/error.service";

@NgModule({
  declarations: [
  ],
  imports: [
    BrowserModule,
  ],
  exports: [
  ],
  providers: [
    { provide: MF_ERROR_CONFIG_TOKEN, useValue: MF_ERROR_DEFAULT_CONFIG },
    { provide: ErrorHandler, useClass: MfGlobalErrorHandler, },
    MfErrorService,
  ]
})
export class MfErrorModule {
}