import { Directive, Inject, Input, TemplateRef } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfTextAlignHorizontalTypes } from "@material-framework/common/types";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableCell" };

@Directive({
  selector: "[mf-table-cell]",
})
export class MfTableCell {
  @Input()
  public get contentAlign(): MfTextAlignHorizontalTypes {
    return this._contentAlign;
  }
  public set contentAlign(value: MfTextAlignHorizontalTypes) {
    setTimeout(() => {
      this._contentAlign = value;
    }, 1);
  }

  protected _contentAlign: MfTextAlignHorizontalTypes;

  public constructor(
    @Inject(MF_TABLE_CONFIG_TOKEN) public config: MfTableConfig) {
    this._contentAlign = this.config.row.cell.contentAlign;
  }
}

@Directive({
  selector: "[mfTableCellDef]"
})
export class MfTableCellDef {
  public constructor(public template: TemplateRef<unknown>) { }
}