<div [formGroup]="formGroup"
     class="mf-column ecom-checkout-part-container">
  <div class="ecom-page-header">
    <div class="ecom-title">
      Billing & Payment
    </div>
  </div>
  <div class="mf-column">
    <div class="ecom-billing-address">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Billing Address</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="mf-column mf-large-margin-top">
            <div>
              {{checkoutData?.customerDetail?.billingName}}
            </div>
            <div>
              {{checkoutData?.customerDetail?.billingAddress?.address1}}
            </div>
            <div>
              {{checkoutData?.customerDetail?.billingAddress?.address2}}
            </div>
            <div>
              {{checkoutData?.customerDetail?.billingAddress?.address3}}
            </div>
            <div>
              {{checkoutData?.customerDetail?.billingAddress?.address4}}
            </div>
            <div>
              {{checkoutData?.customerDetail?.billingAddress?.address5}}
            </div>
            <div>
              {{checkoutData?.customerDetail?.billingAddress?.city}}
            </div>
            <div>
              {{checkoutData?.customerDetail?.billingAddress?.region}}
            </div>
            <div>
              {{checkoutData?.customerDetail?.billingAddress?.postalCode}}
            </div>
            <div>
              {{checkoutData?.customerDetail?.billingAddress?.countryCode}}
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="mf-large-margin-top">
      <mat-form-field style="width: 500px;">
        <mat-label>Purchase Order Reference</mat-label>
        <mf-input id="purchase-order-reference"
                  [formControlName]="_purchaseOrderReferenceName"></mf-input>
        <mat-error
                   *ngIf="_purchaseOrderReferenceFormControl.invalid">{{_errorMessages[_purchaseOrderReferenceName]}}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="ecom-page-header">
    <div class="ecom-subheader">
      Select Payment Method
    </div>
  </div>
  <mat-radio-group [formControlName]="_paymentTypeName">
    <div class="mf-large-margin-bottom">
      <mat-card (click)="_paymentTypeFormControl.setValue(_cartPaymentTypes.creditCard)"
                class="ecom-option-card">
        <mat-card-content>
          <div class="mf-column">
            <div class="mf-row">
              <div>
                <mat-radio-button class="ecom-option"
                                  #creditCartRB
                                  [value]="_cartPaymentTypes.creditCard">
                  <div class="ecom-option-label">
                    Credit Card
                  </div>
                </mat-radio-button>
              </div>
              <div class="mf-flex-fill"></div>
              <div class="mf-row">
                <mf-icon class="ecom-icon"
                         [icon]="_iconsConfig.visaIcon"
                         [size]="28"></mf-icon>
                <mf-icon class="ecom-icon"
                         [icon]="_iconsConfig.amexIcon"
                         [size]="28"></mf-icon>
                <mf-icon class="ecom-icon"
                         [icon]="_iconsConfig.masterCardIcon"
                         [size]="28"></mf-icon>
                <mf-icon class="ecom-icon"
                         [icon]="_iconsConfig.paypalIcon"
                         [size]="28"></mf-icon>
              </div>
            </div>
            <div class="mf-row">
              You will be redirected to the PayPal website to complete your purchase securely.
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div>
      <mat-card (click)="_paymentTypeFormControl.setValue(_cartPaymentTypes.account)"
                class="ecom-option-card">
        <mat-card-content>
          <div class="mf-column">
            <div class="mf-row">
              <div>
                <mat-radio-button class="ecom-option"
                                  #accountRB
                                  [value]="_cartPaymentTypes.account">
                  <div class="ecom-option-label">
                    Account (Invoice)
                  </div>
                </mat-radio-button>
              </div>
              <div class="mf-flex-fill"></div>
              <div class="mf-row">
                <mf-icon class="ecom-icon"
                         [icon]="_iconsConfig.accountIcon"
                         [size]="28"></mf-icon>
              </div>
            </div>
            <div class="mf-row">
              You will be invoiced upon shipment of your order lines.
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-radio-group>
</div>