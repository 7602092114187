import { InjectionToken } from "@angular/core";
import { MF_NUMBER_BYTE_MAX_VALUE, MF_NUMBER_BYTE_MIN_VALUE, MF_NUMBER_DECIMAL_MAX_VALUE, MF_NUMBER_DECIMAL_MIN_VALUE, MF_NUMBER_DOUBLE_MAX_VALUE, MF_NUMBER_DOUBLE_MIN_VALUE, MF_NUMBER_FLOAT_MAX_VALUE, MF_NUMBER_FLOAT_MIN_VALUE, MF_NUMBER_INT_MAX_VALUE, MF_NUMBER_INT_MIN_VALUE, MF_NUMBER_LONG_MAX_VALUE, MF_NUMBER_LONG_MIN_VALUE, MF_NUMBER_SBYTE_MAX_VALUE, MF_NUMBER_SBYTE_MIN_VALUE, MF_NUMBER_SHORT_MAX_VALUE, MF_NUMBER_SHORT_MIN_VALUE, MF_NUMBER_UINT_MAX_VALUE, MF_NUMBER_UINT_MIN_VALUE, MF_NUMBER_ULONG_MAX_VALUE, MF_NUMBER_ULONG_MIN_VALUE, MF_NUMBER_USHORT_MAX_VALUE, MF_NUMBER_USHORT_MIN_VALUE } from "@material-framework/common/utils/number.utils";
import { MfModelFieldNumberTypes } from "@material-framework/modelConfig/model.config";

export const MF_INPUT_CONFIG_TOKEN = new InjectionToken<MfInputConfig>("MfInputConfig");
export const MF_INPUT_DEFAULT_CONFIG: MfInputConfig = {
  modelNumberTypes: {
    sbyte: {
      min: MF_NUMBER_SBYTE_MIN_VALUE,
      max: MF_NUMBER_SBYTE_MAX_VALUE,
    },
    byte: {
      min: MF_NUMBER_BYTE_MIN_VALUE,
      max: MF_NUMBER_BYTE_MAX_VALUE,
    },
    short: {
      min: MF_NUMBER_SHORT_MIN_VALUE,
      max: MF_NUMBER_SHORT_MAX_VALUE,
    },
    ushort: {
      min: MF_NUMBER_USHORT_MIN_VALUE,
      max: MF_NUMBER_USHORT_MAX_VALUE,
    },
    int: {
      min: MF_NUMBER_INT_MIN_VALUE,
      max: MF_NUMBER_INT_MAX_VALUE,
    },
    uint: {
      min: MF_NUMBER_UINT_MIN_VALUE,
      max: MF_NUMBER_UINT_MAX_VALUE,
    },
    long: {
      min: MF_NUMBER_LONG_MIN_VALUE,
      max: MF_NUMBER_LONG_MAX_VALUE,
    },
    ulong: {
      min: MF_NUMBER_ULONG_MIN_VALUE,
      max: MF_NUMBER_ULONG_MAX_VALUE,
    },
    float: {
      min: MF_NUMBER_FLOAT_MIN_VALUE,
      max: MF_NUMBER_FLOAT_MAX_VALUE,
    },
    double: {
      min: MF_NUMBER_DOUBLE_MIN_VALUE,
      max: MF_NUMBER_DOUBLE_MAX_VALUE,
    },
    decimal: {
      min: MF_NUMBER_DECIMAL_MIN_VALUE,
      max: MF_NUMBER_DECIMAL_MAX_VALUE,
    },
  }
};

export type MfInputConfig = {
  modelNumberTypes: MfInputModelFieldNumberTypesMapConfig;
  mouseWheelDisabled?: boolean;
}

export type MfInputModelFieldNumberTypesMapConfig = {
  [key in MfModelFieldNumberTypes]: MfInputModelFieldNumberTypeMapConfig;
}

export type MfInputModelFieldNumberTypeMapConfig = {
  min: number;
  max: number;
}