<button *ngIf="!_isUndefined(options?.button?.label)"
        [matTooltip]="_isUndefinedThenDefault(options?.button?.tooltip, '')"
        (click)="_onButtonClicked($event)"
        mat-raised-button>
  <mf-icon [icon]="options?.button?.icon" />
  {{ options?.button?.label }}
</button>

<button *ngIf="_isUndefined(options?.button?.label)"
        [matTooltip]="_isUndefinedThenDefault(options?.button?.tooltip, '')"
        (click)="_onButtonClicked($event)"
        mat-icon-button>
  <mf-icon [icon]="options?.button?.icon" />
</button>