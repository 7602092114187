<div [formGroup]="_formGroup">
  <mat-form-field [style.width.px]="options?.width"
                  [matTooltip]="_errorMessages[_inputName]"
                  [matTooltipDisabled]="_inputFormControl.valid"
                  class="mf-slim-mdc-form-field">
    <mf-input (onInput)="_onInput($event)"
              (onChanged)="_onChanged($event)"
              [clearValue]="_options?.clearValue"
              [formControlName]="_inputName"
              [type]="_inputType"></mf-input>
  </mat-form-field>
</div>