<mat-chip [style.color]="status?.color"
          [style.backgroundColor]=" _isUndefined(status) ? '' :  _addAlpha(status.color, 0.35)"
          [style.borderColor]="status?.color"
          [style.borderWidth]="'1px'"
          [style.borderStyle]="'solid'"
          disableRipple="true">
  <mf-icon *ngIf="!_isUndefined(status?.icon)"
           [style.color]="status?.color"
           [icon]="status?.icon"></mf-icon>
  {{ status?.displayName }}
</mat-chip>