import { NgModule } from "@angular/core";
import { MfFunctionRefModule } from "@material-framework/functionRef/function.ref.module";
import { MF_MODEL_CONFIGS, MF_MODEL_CONFIGS_TOKEN, MF_MODEL_DEFAULTS_CONFIG, MF_MODEL_DEFAULTS_CONFIG_TOKEN } from "@material-framework/modelConfig/model.config";
import { MF_MODEL_REQUIRED_FIELD_RETRIEVER_TYPES, MF_MODEL_REQUIRED_FIELD_RETRIEVER_TYPES_TOKEN } from "@material-framework/modelConfig/model.config.required.fields";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfModelConfigValueFormatterService } from "@material-framework/modelConfig/model.config.value.formatter.service";

@NgModule({
  declarations: [
  ],
  imports: [
    MfFunctionRefModule,
  ],
  exports: [
  ],
  providers: [
    { provide: MF_MODEL_CONFIGS_TOKEN, useValue: MF_MODEL_CONFIGS },
    { provide: MF_MODEL_DEFAULTS_CONFIG_TOKEN, useValue: MF_MODEL_DEFAULTS_CONFIG },
    { provide: MF_MODEL_REQUIRED_FIELD_RETRIEVER_TYPES_TOKEN, useValue: MF_MODEL_REQUIRED_FIELD_RETRIEVER_TYPES },
    MfModelConfigService,
    MfModelConfigValueFormatterService,
  ]
})
export class MfModelConfigModule {
}