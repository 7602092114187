import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { EComOrderShippingMethodComponent } from "@e-commerce/checkout/order/shippingMethod/order.shipping.method.component";
import { MfSelectModule } from "@material-framework/select/select.module";


@NgModule({
  declarations: [
    EComOrderShippingMethodComponent
  ],
  exports: [
    EComOrderShippingMethodComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MfSelectModule,
  ],
  providers: [
  ]
})
export class EComOrderShippingMethodModule { }