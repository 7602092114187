import { Component, Injector } from "@angular/core";
import { EComCheckoutStepBaseComponent } from "@e-commerce/checkout/base/checkout.step.base.component";
import { EComOrderCheckoutData } from "@e-commerce/checkout/order/checkout.order";
import { MfTypeInfo } from "@material-framework/common/type.info";

const TYPE_INFO: MfTypeInfo = { className: "EComOrderReviewComponent" };

@Component({
  selector: "ecom-order-review",
  templateUrl: "./order.review.component.html",
  styleUrls: ["./order.review.component.scss"]
})
export class EComOrderReviewComponent extends EComCheckoutStepBaseComponent<EComOrderCheckoutData> {
  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected override _onCheckoutModelSet(model: EComOrderCheckoutData): void {
  }
}