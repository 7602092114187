import { Component, EventEmitter, Inject, Injector, Input, Output, ViewEncapsulation } from "@angular/core";
import { MF_ANIMATION_FADE_IN, MF_ANIMATION_FADE_OUT } from "@material-framework/animations/animations";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFilterGroup } from "@material-framework/filter/filter";
import { MfModelFieldsConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfTableFilterGroupEvent, MfTableFilterExpressionEvent } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableHeaderFilterMenuComponent" };

@Component({
  selector: "mf-table-header-filter-menu",
  templateUrl: "table.header.filter.menu.component.html",
  encapsulation: ViewEncapsulation.None,
  animations: [
    MF_ANIMATION_FADE_IN,
    MF_ANIMATION_FADE_OUT,
  ]
})
export class MfTableHeaderFilterMenuComponent extends MfBaseComponent {
  @Input()
  public filterGroup?: MfFilterGroup;

  @Input()
  public modelFieldsConfig?: MfModelFieldsConfigMapped;

  @Output()
  public onClickOutside: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterChange: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterGroupChange: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterRemoveGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterAddGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterExpressionChange: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onFilterAddExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onFilterRemoveExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN) public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public onClickedOutside(): void {
    if (!mfTypeIsUndefined(this.filterGroup)) {
      this.onClickOutside.emit({ group: this.filterGroup });
    }
  }
}