import { MfTypeInfo } from "@material-framework/common/type.info";

export abstract class MfBaseObject {

  public get typeInfo(): MfTypeInfo {
    return this._typeInfo;
  }

  public constructor(
    protected _typeInfo: MfTypeInfo
  ) { }
}