import { MfError } from "@material-framework/common/error/error";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfSnackBarMessageTypes = {
  info: "info",
  error: "error",
  errorMf: "errorMf"
} as const;

export type MfSnackBarMessageTypes = typeof MfSnackBarMessageTypes[keyof typeof MfSnackBarMessageTypes]

export type MfSnackBarMessage = {
  type: MfSnackBarMessageTypes;
  message: string;
}

export type MfSnackBarErrorMessage = MfSnackBarMessage & {
  error: Error;
}

export type MfSnackBarMfErrorMessage = MfSnackBarMessage & {
  error: MfError;
}