import { EComInvoiceStatusesTypes, eComInvoiceStatusesTypeToDisplayName } from "@e-commerce/common/invoice.status";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";
import { MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG, MfPortalsRequestCollectionData } from "@material-framework/portals/portals";

export type EComInvoiceRequest = MfPortalsRequestCollectionData & {
  key?: string;
}

export type EComInvoiceRequestFieldsConfig = {
  [key in keyof Required<EComInvoiceRequest>]: MfModelFieldConfig
};

export const ECOM_INVOICE_REQUEST_MODEL_FIELDS_CONFIG: EComInvoiceRequestFieldsConfig = {
  ...MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG,
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  }
};

export const ECOM_INVOICE_REQUEST_MODEL_CONFIG_ID = "invoiceRequest";

export const ECOM_INVOICE_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};


export type EComInvoiceModel = MfModelBase & {
  billingAccountComposite: string;
  currency: string;
  currencyTaxValue: number;
  currencyTotalValue: number;
  currencyValue: number;
  daysPastDue: number;
  documentCompany: string;
  documentNumber: string;
  documentType: string;
  documentKey: string;
  domesticOrForeign: string;
  invoiceDate: Date;
  paymentDate: Date;
  paymentDueDate: Date;
  paymentMethod: string;
  paymentMethodText: string;
  paymentTerms: string;
  paymentTermsText: string;
  paymentValue: number;
  portalTransactionNumber: number;
  status: EComInvoiceStatusesTypes;
  statusText: string;
  uniqueIdentifier: number;
}

export type EComInvoiceModelFieldsConfig = {
  [key in keyof Required<EComInvoiceModel>]: MfModelFieldConfig
};

export const ECOM_INVOICE_MODEL_FIELDS_CONFIG: EComInvoiceModelFieldsConfig = {
  billingAccountComposite: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  currency: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Currency"
    }
  },
  currencyTaxValue: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
      currency: {},
    },
    display: {
      displayName: "Tax"
    }
  },
  currencyTotalValue: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
      currency: {},
    },
    display: {
      displayName: "Total Value"
    }
  },
  currencyValue: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
      currency: {},
    },
    display: {
      displayName: "Value"
    }
  },
  daysPastDue: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    }
  },
  documentCompany: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  documentNumber: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Invoice"
    }
  },
  documentType: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  domesticOrForeign: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  invoiceDate: {
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    },
    display: {
      displayName: "Invoice Date"
    }
  },
  paymentDate: {
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    }
  },
  paymentDueDate: {
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    },
    display: {
      displayName: "Payment Due Date"
    }
  },
  paymentMethod: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Payment Method"
    }
  },
  paymentMethodText: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  paymentTerms: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Payment Terms"
    }
  },
  paymentTermsText: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  paymentValue: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
      currency: {},
    },
    display: {
      displayName: "Total Value"
    }
  },
  portalTransactionNumber: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    }
  },
  status: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    },
    display: {
      displayName: "Status"
    },
    statusChip: {
      statuses: [
        { color: "#292f56", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.approvedForPayment), value: EComInvoiceStatusesTypes.approvedForPayment },
        { color: "#253a65", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.balanceForwardSummarized), value: EComInvoiceStatusesTypes.balanceForwardSummarized },
        { color: "#1c4674", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.checkBeingWritten), value: EComInvoiceStatusesTypes.checkBeingWritten },
        { color: "#025381", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.creditPending), value: EComInvoiceStatusesTypes.creditPending },
        { color: "#005f8d", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.disputedTaxes), value: EComInvoiceStatusesTypes.disputedTaxes },
        { color: "#006c95", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.draftAccepted), value: EComInvoiceStatusesTypes.draftAccepted },
        { color: "#007899", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.draftDepositedNotDue), value: EComInvoiceStatusesTypes.draftDepositedNotDue },
        { color: "#00849e", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.draftExpiredNotCollected), value: EComInvoiceStatusesTypes.draftExpiredNotCollected },
        { color: "#0091a1", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.draftReceiptVoucher), value: EComInvoiceStatusesTypes.draftReceiptVoucher },
        { color: "#009da4", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.heldPendingApproval), value: EComInvoiceStatusesTypes.heldPendingApproval },
        { color: "#00aaa4", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.heldVarianceInReceiptMatch), value: EComInvoiceStatusesTypes.heldVarianceInReceiptMatch },
        { color: "#00b8a2", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.needTaxExemptionCertificate), value: EComInvoiceStatusesTypes.needTaxExemptionCertificate },
        { color: "#00c69d", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.ownerPayment), value: EComInvoiceStatusesTypes.ownerPayment },
        { color: "#00d295", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.paidInFull), value: EComInvoiceStatusesTypes.paidInFull },
        { color: "#3bdd8c", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.retainage), value: EComInvoiceStatusesTypes.retainage },
        { color: "#65e783", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.tempPaymentStatus), value: EComInvoiceStatusesTypes.tempPaymentStatus },
        { color: "#89f179", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.thresholdExceeded), value: EComInvoiceStatusesTypes.thresholdExceeded },
        { color: "#acfa70", displayName: eComInvoiceStatusesTypeToDisplayName(EComInvoiceStatusesTypes.withholdingApplies), value: EComInvoiceStatusesTypes.withholdingApplies }
      ]
    }
  },
  statusText: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  uniqueIdentifier: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    }
  },
  documentKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
};

export const ECOM_INVOICE_MODEL_CONFIG_ID = "invoice";

export const ECOM_INVOICE_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_MODEL_CONFIG",
  },
  portals: {}
};