// eslint-disable-next-line @typescript-eslint/naming-convention
export const EComCartModes = {
  invoice: "invoice",
  order: "order",
} as const;

export type EComCartModes = typeof EComCartModes[keyof typeof EComCartModes]

// eslint-disable-next-line @typescript-eslint/naming-convention
export const EComCartPaymentTypes = {
  account: 1,
  creditCard: 2,
} as const;

export type EComCartPaymentTypes = typeof EComCartPaymentTypes[keyof typeof EComCartPaymentTypes]