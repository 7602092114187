import { InjectionToken } from "@angular/core";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { MfIcon } from "@material-framework/icon/icon";
import { MfIconTypes } from "@material-framework/icon/icon.types";

export const MF_ROOT_CONFIG_TOKEN = new InjectionToken<MfRootConfig>("MfRootConfig");
export const MF_ROOT_DEFAULT_CONFIG: MfRootConfig = {
  slideDrawer: {
    closeIcon: { icon: faXmark, type: MfIconTypes.fontAwesome, },
  }
};

export type MfRootConfig = {
  slideDrawer: MfRootSlideDrawerConfig;
}

export type MfRootSlideDrawerConfig = {
  closeIcon: MfIcon;
}