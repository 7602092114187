import { Component, Inject, Injector, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfColorAddAlpha } from "@material-framework/common/utils/color.utils";
import { mfObjectGetPropertyPathValue } from "@material-framework/common/utils/object.utils";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelFieldConfig } from "@material-framework/modelConfig/model.config";
import { MfStatusChipStatus } from "@material-framework/statusChip/status.chip";
import { MF_STATUS_CHIP_CONFIG_TOKEN, MfStatusChipConfig } from "@material-framework/statusChip/status.chip.config";

const TYPE_INFO: MfTypeInfo = { className: "MfStatusChipComponent" };

@Component({
  selector: "mf-status-chip",
  templateUrl: "status.chip.component.html",
  styleUrls: ["status.chip.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfStatusChipComponent extends MfBaseComponent implements OnInit {
  @Input()
  public status?: MfStatusChipStatus;

  @Input()
  public modelFieldConfig?: MfModelFieldConfig;

  @Input()
  public get model(): MfModelBase | undefined {
    return this._model;
  }
  public set model(value: MfModelBase | undefined) {
    this._model = value;
    this._setStatus();
  }

  @Input()
  public statuses?: MfStatusChipStatus[];

  protected _addAlpha = mfColorAddAlpha;
  protected _model?: MfModelBase | undefined;

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_STATUS_CHIP_CONFIG_TOKEN)
    protected _config: MfStatusChipConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    this._setStatus();
  }

  protected _setStatus(): void {
    if (!mfTypeIsUndefined(this.model) && !mfTypeIsUndefined(this.modelFieldConfig) && !mfTypeIsUndefined(this.modelFieldConfig.statusChip)) {
      const rawValue = mfObjectGetPropertyPathValue<string | number>(this.modelFieldConfig?.fieldPath, this.model);
      const optionStatus = this.modelFieldConfig.statusChip.statuses?.find(status => status.value === rawValue);
      if (!mfTypeIsUndefined(optionStatus)) {
        this.status = optionStatus;
      }
    }
  }
}