<div *ngIf="_isUndefined(options?.icons)"
     [style.textAlign]="_isUndefinedThenDefault(fieldColumn?.modelFieldConfig?.table?.cell?.contentAlign, config.row.cell.contentAlign)">
   {{ _displayValue }}
</div>
<div
     *ngIf="!_isUndefined(options) && !_isUndefined(options.icons) && !_isUndefined(options.icons.true) && !_isUndefined(options.icons.false)">
   <mf-icon *ngIf="_value === true"
            [style.fontSize.px]="_isUndefinedThenDefault(options.icons.fontSize, 20)"
            [icon]="options.icons.true"></mf-icon>
   <mf-icon *ngIf="_value === false"
            [style.fontSize.px]="_isUndefinedThenDefault(options.icons.fontSize, 20)"
            [icon]="options.icons.false"></mf-icon>
</div>