import { Component, EventEmitter, Inject, Injector, Input, Output, ViewEncapsulation } from "@angular/core";
import { MF_ANIMATION_FADE_IN, MF_ANIMATION_FADE_OUT } from "@material-framework/animations/animations";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfArraySortByStringPath } from "@material-framework/common/utils/array.utils";
import { mfTypeGetKeys, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFilterBaseComponent } from "@material-framework/filter/base/filter.base.component";
import { MfFilterFieldOption, mfFilterFieldSelectGetOptions } from "@material-framework/filter/fieldSelect/filter.field.select.component";
import { MfFilterExpression, MfFilterGroup } from "@material-framework/filter/filter";
import { MF_FILTER_CONFIG_TOKEN, MfFilterConfig } from "@material-framework/filter/filter.config";
import { MfFilterService } from "@material-framework/filter/services/filter.service";
import { MfModelFieldConfigMapped, MfModelFieldsConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";


const TYPE_INFO: MfTypeInfo = { className: "MfFilterGroupComponent" };

@Component({
  selector: "mf-filter-group",
  templateUrl: "filter.group.component.html",
  encapsulation: ViewEncapsulation.None,
  animations: [
    MF_ANIMATION_FADE_IN,
    MF_ANIMATION_FADE_OUT,
  ]
})
export class MfFilterGroupComponent extends MfFilterBaseComponent {
  @Input()
  public modelFieldsConfig?: MfModelFieldsConfigMapped;

  @Input()
  public singleFieldFilter = false;

  @Input()
  public firstLevelGroup = false;

  @Input()
  public singleFieldPath?: string;

  @Input()
  public group?: MfFilterGroup;

  @Output()
  public onGroupChange: EventEmitter<MfFilterGroup> = new EventEmitter();

  @Output()
  public onRemoveGroup: EventEmitter<MfFilterGroup> = new EventEmitter();

  @Output()
  public onAddGroup: EventEmitter<MfFilterGroup> = new EventEmitter();

  @Output()
  public onExpressionChange: EventEmitter<MfFilterExpression> = new EventEmitter();

  @Output()
  public onAddExpression: EventEmitter<MfFilterExpression> = new EventEmitter();

  @Output()
  public onRemoveExpression: EventEmitter<MfFilterExpression> = new EventEmitter();

  @Output()
  public onEnterKey: EventEmitter<boolean> = new EventEmitter();

  public constructor(
    protected override _injector: Injector,
    protected _modelConfigService: MfModelConfigService,
    protected _filterService: MfFilterService,
    @Inject(MF_FILTER_CONFIG_TOKEN)
    protected _config: MfFilterConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected get _expressionCount(): number {
    return mfTypeIsUndefined(this.group) ? 0 : this.group.expressions.length;
  }

  protected get _showCollapseExpand(): boolean {
    return !mfTypeIsUndefined(this.group) && (this.group.expressions.length > 0 || this.group.groups.length > 0);
  }

  protected _toggleGroupCollapsed(): void {
    if (!mfTypeIsUndefined(this.group)) {
      this.group.isCollapsed = !this.group.isCollapsed;
    }
  }

  protected _andOrChange(): void {
    this.onGroupChange.emit(this.group);
  }

  protected _addFilterClicked(): void {
    if (!mfTypeIsUndefined(this.modelFieldsConfig)) {
      const options = mfArraySortByStringPath(mfFilterFieldSelectGetOptions(this.modelFieldsConfig), "label");
      let option: MfFilterFieldOption | undefined = undefined;

      if (this.singleFieldFilter === true && !mfTypeIsUndefined(this.singleFieldPath)) {
        option = options.find(o => o.fieldKey === this.singleFieldPath);
      } else {
        option = options[0];
      }

      if (!mfTypeIsUndefined(option) && !mfTypeIsUndefined(option.modelFieldConfig.filter)) {
        const operatorsConfigKeys = mfTypeGetKeys(this._config.operatorsTypes[option.modelFieldConfig.filter.operator.type]);
        const expression = this._filterService.getNewFilterExpression(option.fieldKey, option.modelFieldConfig.fieldPath, operatorsConfigKeys[0], 0, option.modelFieldConfig);
        this._filterService.resetExpressionValue(expression);
        this._addFilterExpression(expression);
        this.onAddExpression.emit(expression);
        this.onGroupChange.emit(this.group);
      }
    }
  }

  protected _addGroupClicked(): void {
    if (!mfTypeIsUndefined(this.group)) {
      const group = this._filterService.getNewFilterGroup();
      this.group.groups.push(group);
      this.onAddGroup.emit(group);
      this.onGroupChange.emit(this.group);
    }
  }

  protected _removeGroupClicked(): void {
    this.onRemoveGroup.emit(this.group);
  }

  protected _removeChildGroup(filterGroup: MfFilterGroup): void {
    this._removeFilterGroup(filterGroup);
    this.onRemoveGroup.emit(filterGroup);
    this.onGroupChange.emit(this.group);
  }

  protected _removeExpression(filterExpression: MfFilterExpression): void {
    this._removeFilterExpression(filterExpression);
    this.onRemoveExpression.emit(filterExpression);
    this.onGroupChange.emit(this.group);
  }

  protected _getFirstKeyWithFilter(modelFieldsConfig: MfModelFieldsConfigMapped): string | undefined {
    const modelFieldConfigs: { modelFieldConfig: MfModelFieldConfigMapped, key: string }[] = [];

    this._modelConfigService.forEachModelFieldNonRecursive(modelFieldsConfig, (modelFieldConfig) => {
      if (!mfTypeIsUndefined(modelFieldConfig.filter)) {
        modelFieldConfigs.push({ modelFieldConfig, key: modelFieldConfig.fieldKey as string });
      }
    });

    return mfArraySortByStringPath(modelFieldConfigs, "modelFieldConfig.display.displayName")[0].key;
  }

  protected _removeFilterGroup(filterGroup: MfFilterGroup): void {
    if (!mfTypeIsUndefined(this.group)) {
      const groupIndex = this.group.groups.findIndex(fg => fg.id === filterGroup.id);
      if (groupIndex != -1) {
        this.group.groups.splice(groupIndex, 1);
      }
    }
  }

  protected _removeFilterExpression(filterExpression: MfFilterExpression): void {
    if (!mfTypeIsUndefined(this.group)) {
      const expressionIndex = this.group.expressions.findIndex(fe => fe.id === filterExpression.id);
      if (expressionIndex != -1) {
        this.group.expressions.splice(expressionIndex, 1);
      }
    }
  }

  protected _addFilterExpression(filterExpression: MfFilterExpression): void {
    if (!mfTypeIsUndefined(this.group)) {
      this.group.expressions.push(filterExpression);
    }
  }
}