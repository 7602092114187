import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsInstanceOfIdentifier, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfTableRowCellEditorValueChangeEvent } from "@material-framework/table/row/cell/value/editor/table.row.cell.editor";
import { MfTableRowCellFormatterDirective } from "@material-framework/table/row/cell/value/formatter/table.row.cell.formatter.directive";
import { MfTableRowCellBaseEditorComponent } from "@material-framework/table/row/cell/value/table.row.cell.base.editor.component";
import { MfTableRowCellBaseFormatter } from "@material-framework/table/row/cell/value/Table.row.cell.base.formatter";
import { MfTableFieldColumn, MfTableRowCellValueBaseOptions } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableCellFormatterConfig, MfTableCellFormatterModelDataTypeConfig, MfTableConfig } from "@material-framework/table/table.config";
import { MF_TABLE_CELL_FORMATTERS_CONFIG_TOKEN } from "@material-framework/table/table.config.cell.formatter";
import { MF_TABLE_CELL_FORMATTER_TO_MODEL_DATATYPE_CONFIG_TOKEN } from "@material-framework/table/table.config.cell.formatter.model.datatype";
import { MfTableRowCellValueFormatterTypeModelConfig } from "@material-framework/table/table.model.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowCellComponent" };

@Component({
  selector: "mf-table-row-cell",
  templateUrl: "table.row.cell.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowCellComponent<TModel extends MfModelBase> extends MfBaseComponent implements OnInit {

  @Input()
  public fieldColumn?: MfTableFieldColumn;

  @Input()
  public rowItem?: TModel;

  @Input()
  public isLoading?: boolean;

  @Output()
  public requireTableReload: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public onValueChange: EventEmitter<MfTableRowCellEditorValueChangeEvent<TModel>> = new EventEmitter();

  @Output()
  public onValueInput: EventEmitter<MfTableRowCellEditorValueChangeEvent<TModel>> = new EventEmitter();

  @ViewChild(MfTableRowCellFormatterDirective, { static: true })
  protected _formatterDirective!: MfTableRowCellFormatterDirective;

  protected _cellComponent?: MfTableRowCellBaseFormatter<MfTableRowCellValueBaseOptions>;

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    public config: MfTableConfig,
    @Inject(MF_TABLE_CELL_FORMATTERS_CONFIG_TOKEN)
    public formattersConfig: MfTableCellFormatterConfig,
    @Inject(MF_TABLE_CELL_FORMATTER_TO_MODEL_DATATYPE_CONFIG_TOKEN)
    public formatterModelDataTypeConfigs: MfTableCellFormatterModelDataTypeConfig[],
  ) {
    super(TYPE_INFO, _injector);
  }

  public get formatter(): MfTableRowCellBaseFormatter<MfTableRowCellValueBaseOptions> | undefined {
    return this._cellComponent;
  }

  public ngOnInit(): void {
    this._loadComponent();
  }

  protected _loadComponent() {
    if (!mfTypeIsUndefined(this.fieldColumn) && !mfTypeIsUndefined(this.fieldColumn.modelFieldConfig)) {
      const modelFieldConfig = this.fieldColumn.modelFieldConfig;

      let valueFormatterTypeModelConfig: undefined | MfTableRowCellValueFormatterTypeModelConfig<MfTableRowCellValueBaseOptions>;
      if (!mfTypeIsUndefined(this.fieldColumn) && !mfTypeIsUndefined(this.fieldColumn.modelFieldConfig) && !mfTypeIsUndefined(this.fieldColumn.modelFieldConfig.table) && !mfTypeIsUndefined(this.fieldColumn.modelFieldConfig.table.cell) && !mfTypeIsUndefined(this.fieldColumn.modelFieldConfig.table.cell.formatter)) {
        valueFormatterTypeModelConfig = this.fieldColumn.modelFieldConfig.table.cell.formatter;
      } else {

        const formatterModelDataTypeConfig = this.formatterModelDataTypeConfigs.find(i => i.types.indexOf(modelFieldConfig.dataType.type) != -1);
        if (!mfTypeIsUndefined(modelFieldConfig.dataType.percentage)) {
          valueFormatterTypeModelConfig = { type: "percentage" };
        } else if (!mfTypeIsUndefined(modelFieldConfig.dataType.currency)) {
          valueFormatterTypeModelConfig = { type: "currency" };
        } else if (!mfTypeIsUndefined(modelFieldConfig.dataType.enum)) {
          valueFormatterTypeModelConfig = { type: "enum" };
        } else if (!mfTypeIsUndefined(modelFieldConfig.dataType.userPersons)) {
          valueFormatterTypeModelConfig = { type: "userPersons" };
        } else if (!mfTypeIsUndefined(formatterModelDataTypeConfig)) {
          valueFormatterTypeModelConfig = { type: formatterModelDataTypeConfig.formatter };
        }
      }

      if (!mfTypeIsUndefined(valueFormatterTypeModelConfig)) {
        const formatter = this.formattersConfig[valueFormatterTypeModelConfig.type];

        if (!mfTypeIsUndefined(formatter)) {
          const viewContainerRef = this._formatterDirective.viewContainerRef;
          viewContainerRef.clear();

          const componentRef = viewContainerRef.createComponent(formatter);
          this._cellComponent = componentRef.instance;

          componentRef.setInput("fieldColumn", this.fieldColumn);
          componentRef.setInput("rowItem", this.rowItem);
          componentRef.setInput("options", valueFormatterTypeModelConfig.options);

          this._sub(componentRef.instance.requireTableReload, { next: (event) => this.requireTableReload.emit(event) });

          if (mfTypeIsInstanceOfIdentifier<MfTableRowCellBaseEditorComponent<MfTableRowCellValueBaseOptions, TModel>>(componentRef.instance, "isEditor")) {
            this._sub(componentRef.instance.onChanged, { next: (event) => this.onValueChange.emit(event as MfTableRowCellEditorValueChangeEvent<TModel>) });
            this._sub(componentRef.instance.onInput, { next: (event) => this.onValueInput.emit(event as MfTableRowCellEditorValueChangeEvent<TModel>) });

            componentRef.setInput("isLoading", this.isLoading);
          }
        }
      }
    }
  }
}