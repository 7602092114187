import { Inject, Injectable, Injector } from "@angular/core";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfStringIsEmptyOrWhitespace } from "@material-framework/common/utils/string.utils";
import { mfTypeHasToString, mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFilterExpression, MfFilterGroup, MfFilterValue } from "@material-framework/filter/filter";
import { MF_FILTER_CONFIG_TOKEN, MfFilterConfig } from "@material-framework/filter/filter.config";
import { MfFilterOperatorTypes } from "@material-framework/filter/filter.types";
import { MFModelConfigFieldKey, MFModelConfigFieldPath, MfModelFieldConfigMapped, mfModelFieldDataTypeIsNumber, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";
import * as moment from "moment";

const TYPE_INFO: MfTypeInfo = { className: "MfFilterService" };

let lastGroupId = 1;
let lastFilterId = 1;

@Injectable()
export class MfFilterService extends MfBaseService {
  public constructor(
    protected override _injector: Injector,
    @Inject(MF_FILTER_CONFIG_TOKEN) protected _config: MfFilterConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public removeExpressionWithFieldPath(group: MfFilterGroup, fieldPath: MFModelConfigFieldKey): void {
    this._removeGroupExpressionsWithFieldPath(group, fieldPath);
  }

  public getExpressionsWithFieldPathInGroup(group: MfFilterGroup, fieldPath: MFModelConfigFieldPath): MfFilterExpression[] | undefined {
    const expressions: MfFilterExpression[] = [];

    const expressionsLength = group.expressions.length;
    for (let expressionIndex = 0; expressionIndex < expressionsLength; expressionIndex++) {
      const subExpression = group.expressions[expressionIndex];
      const subExpressions = this.getExpressionsWithFieldPathInExpression(subExpression, fieldPath);
      if (!mfTypeIsUndefined(subExpressions)) {
        expressions.push(...subExpressions);
      }
    }

    const groupsLength = group.groups.length;
    for (let groupIndex = 0; groupIndex < groupsLength; groupIndex++) {
      const subGroup = group.groups[groupIndex];
      const subExpressions = this.getExpressionsWithFieldPathInGroup(subGroup, fieldPath);
      if (!mfTypeIsUndefined(subExpressions)) {
        expressions.push(...subExpressions);
      }
    }

    return expressions.length === 0 ? undefined : expressions;
  }

  public expressionHasFieldPathInGroup(group: MfFilterGroup, fieldPath: MFModelConfigFieldPath): boolean {
    if (!mfTypeIsUndefined(this.getExpressionsWithFieldPathInGroup(group, fieldPath))) {
      return true;
    }
    return false;
  }

  public getExpressionsWithFieldPathInExpression(expression: MfFilterExpression, fieldPath: MFModelConfigFieldPath): MfFilterExpression[] | undefined {
    if (!mfTypeIsUndefined(expression.subGroup)) {
      const subExpressions = this.getExpressionsWithFieldPathInGroup(expression.subGroup, fieldPath);
      if (!mfTypeIsUndefined(subExpressions)) {
        return subExpressions;
      }
    } else if (expression.fieldPath === fieldPath) {
      return [expression];
    }
    return;
  }

  public expressionHasFieldPathInExpression(expression: MfFilterExpression, fieldPath: MFModelConfigFieldPath): boolean {
    if (!mfTypeIsUndefined(this.getExpressionsWithFieldPathInExpression(expression, fieldPath))) {
      return true;
    }
    return false;
  }

  public getNewFilterExpression(fieldKey: MFModelConfigFieldKey, fieldPath: MFModelConfigFieldPath, operatorKey: MfFilterOperatorTypes, value: MfFilterValue, modelFieldConfig: MfModelFieldConfigMapped, subGroup?: MfFilterGroup): MfFilterExpression {
    const expression: MfFilterExpression = { id: lastFilterId, fieldKey, fieldPath, operatorKey, value, subGroup, modelFieldConfig };
    lastFilterId++;
    return expression;
  }

  public getNewFilterGroup(): MfFilterGroup {
    const expression = { id: lastGroupId, groups: [], expressions: [], andOr: this._config.group.addGroupDefaultLogic, isCollapsed: false };
    lastGroupId++;
    return expression;
  }

  public getExpressionResetValue(expression: MfFilterExpression): MfFilterValue {
    if (!mfTypeIsUndefined(expression.modelFieldConfig.filter)) {
      if (expression.operatorKey === "in" || expression.operatorKey === "nin") {
        return "";
      } else {
        if (mfModelFieldDataTypeIsNumber(expression.modelFieldConfig.dataType.type)) {
          return 0;
        }

        switch (expression.modelFieldConfig.dataType.type) {
          case MfModelFieldDataTypes.string:
            return "";
          case MfModelFieldDataTypes.boolean:
            return false;
          case MfModelFieldDataTypes.dateTime:
          case MfModelFieldDataTypes.date:
            return moment().set("hour", 0).set("minute", 0).set("second", 0).toDate();
          case MfModelFieldDataTypes.array:
            if (expression.operatorKey === MfFilterOperatorTypes.any) {
              return false;
            }
            break;
        }
      }
    }
    return;
  }

  public resetExpressionValue(expression: MfFilterExpression): void {
    const resetValue = this.getExpressionResetValue(expression);
    if (!mfTypeIsUndefined(resetValue)) {
      expression.value = resetValue;
    }
  }

  public removeEmptyExpressions(group: MfFilterGroup): number {
    let removedCount = this._removeEmptyExpressions(group);
    const length = group.groups.length;
    for (let index = 0; index < length; index++) {
      removedCount += this.removeEmptyExpressions(group.groups[index]);
    }
    return removedCount;
  }

  protected _removeExpressionWithFieldPath(expressions: MfFilterExpression[], fieldPath: MFModelConfigFieldKey): void {
    const length = expressions.length;
    for (let index = length - 1; index >= 0; index--) {
      const expression = expressions[index];
      if (!mfTypeIsUndefined(expression) && expression.fieldPath === fieldPath) {
        expressions.splice(index, 1);
      }
    }
  }

  protected _removeGroupExpressionsWithFieldPath(group: MfFilterGroup, fieldPath: MFModelConfigFieldKey): void {
    this._removeExpressionWithFieldPath(group.expressions, fieldPath);
    group.groups.forEach(g => this._removeExpressionWithFieldPath(g.expressions, fieldPath));
  }

  protected _removeEmptyExpressions(group: MfFilterGroup): number {
    let removedCount = 0;
    const length = group.expressions.length;
    for (let index = length - 1; index >= 0; index--) {
      const value = group.expressions[index].value;
      if (mfTypeIsNullOrUndefined(value) || (mfTypeHasToString(value) && mfStringIsEmptyOrWhitespace(value.toString()))) {
        group.expressions.splice(index, 1);
        removedCount++;
      }
    }
    return removedCount;
  }
}