import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MfClearValueButtonComponent } from "@material-framework/clearValueButton/clear.value.button.component";
import { MF_CLEAR_VALUE_BUTTON_CONFIG_TOKEN, MF_CLEAR_VALUE_BUTTON_DEFAULT_CONFIG } from "@material-framework/clearValueButton/clear.value.button.config";
import { MfIconModule } from "@material-framework/icon/icon.module";

@NgModule({
  declarations: [
    MfClearValueButtonComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MfIconModule,
    MatTooltipModule,
  ],
  exports: [
    MfClearValueButtonComponent
  ],
  providers: [
    { provide: MF_CLEAR_VALUE_BUTTON_CONFIG_TOKEN, useValue: MF_CLEAR_VALUE_BUTTON_DEFAULT_CONFIG },
  ]
})
export class MfClearValueButtonModule {
}