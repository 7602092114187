import { Component, Inject, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPathValue } from "@material-framework/common/utils/object.utils";
import { mfTypeIsArray, mfTypeIsBoolean, mfTypeIsDate, mfTypeIsNumber, mfTypeIsObject, mfTypeIsString, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfTableRowCellBaseFormatterComponent } from "@material-framework/table/row/cell/value/table.row.cell.base.formatter.component";
import { MfTableRowCellFormatterBaseOptions } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";
import { isMoment } from "moment";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowCellArrayFormatterComponent" };

export type MfTableRowCellArrayFormatterStringListOptions = {
  delimiter: string;
};

export type MfTableRowCellArrayFormatterOptions = MfTableRowCellFormatterBaseOptions & {
  arrayFieldPath?: string;
  valueFieldPath?: string;
  firstOrDefault?: boolean;
  stringList?: MfTableRowCellArrayFormatterStringListOptions;
};

@Component({
  selector: "mf-table-row-cell-boolean-formatter-component",
  templateUrl: "table.row.cell.base.formatter.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowCellArrayFormatterComponent<TModel extends MfModelBase> extends MfTableRowCellBaseFormatterComponent<MfTableRowCellArrayFormatterOptions, TModel> implements OnInit {
  public constructor(
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    if (!mfTypeIsUndefined(this.options)) {
      const rawArray = mfObjectGetPropertyPathValue<unknown[]>(this.options.arrayFieldPath || this.fieldColumn?.fieldPath, this.rowItem);
      if (mfTypeIsArray(rawArray) && rawArray.length > 0) {
        if (this.options.firstOrDefault === true) {
          this._setFirstOrDefault(rawArray, this.options);
        } else if (!mfTypeIsUndefined(this.options.stringList)) {
          this._setStringList(rawArray, this.options);
        }
      }
    }
  }

  protected _setStringList(rawArray: unknown[], options: MfTableRowCellArrayFormatterOptions): void {
    if (!mfTypeIsUndefined(options.stringList)) {
      let stringList = "";

      const rawArrayLength = rawArray.length;
      for (let index = 0; index < rawArrayLength; index++) {
        const rawArrayItem = rawArray[index];
        if (!mfTypeIsUndefined(options.valueFieldPath) && mfTypeIsObject(rawArrayItem)) {
          stringList += `${mfObjectGetPropertyPathValue<unknown[]>(options.valueFieldPath, rawArrayItem)}${options.stringList.delimiter}`;
        } else {
          stringList += `${rawArrayItem}${options.stringList.delimiter}`;
        }
      }

      this._setValue(stringList);
    }
  }

  protected _setFirstOrDefault(rawArray: unknown[], options: MfTableRowCellArrayFormatterOptions): void {
    const first = rawArray[0];

    if (!mfTypeIsUndefined(options.valueFieldPath) && mfTypeIsObject(first)) {
      this._setValue(mfObjectGetPropertyPathValue<unknown[]>(options.valueFieldPath, first));
    } else {
      this._setValue(first);
    }
  }

  protected _setValue(rawValue: unknown): void {
    if (mfTypeIsString(rawValue)) {
      this._displayValue = rawValue;
    } else if (mfTypeIsDate(rawValue) || isMoment(rawValue) || mfTypeIsNumber(rawValue) || mfTypeIsBoolean(rawValue)) {
      this._displayValue = rawValue.toString();
    }
  }
}