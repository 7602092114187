import { Component, EventEmitter, Inject, Injector, Input, Output, ViewEncapsulation } from "@angular/core";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EComOrderModel } from "@e-commerce/modelConfigs/order.model.config";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MF_ROOT_CONFIG_TOKEN, MfRootConfig } from "@material-framework/root/root.config";

const TYPE_INFO: MfTypeInfo = { className: "EComOrderDetailHostComponent" };

@Component({
  selector: "ecom-order-detail-host",
  templateUrl: "./order.detail.host.component.html",
  styleUrls: ["./order.detail.host.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EComOrderDetailHostComponent extends MfBaseComponent {
  @Input()
  public order?: EComOrderModel;

  @Output()
  public onClose: EventEmitter<void> = new EventEmitter();

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_ROOT_CONFIG_TOKEN)
    protected _rootConfig: MfRootConfig,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected _onCloseClicked(): void {
    this.onClose.emit();
  }
}