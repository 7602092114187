import { InjectionToken } from "@angular/core";
import { MfIcon } from "@material-framework/icon/icon";
import { MfIconTypes } from "@material-framework/icon/icon.types";


export const MF_MENU_CONFIG_TOKEN = new InjectionToken<MfMenuConfig>("MfMenuConfig");
export const MF_MENU_DEFAULT_CONFIG: MfMenuConfig = {
  expandIcon: {
    type: MfIconTypes.materialIcons,
    icon: "expand_more",
  },
  collapseIcon: {
    type: MfIconTypes.materialIcons,
    icon: "chevron_right",
  }
};

export type MfMenuConfig = {
  expandIcon: MfIcon,
  collapseIcon: MfIcon,
}