<mat-toolbar>
  <mat-toolbar-row class="mf-main-header">
    <div [style.display]="!_isUndefined(menuItemsCount) && menuItemsCount > 0 ? 'visible' : 'hidden'"
         class="mf-menu-action">
      <button (click)="_onSideMenuToggleClick($event)"
              mat-icon-button>
        <mf-icon [icon]="menuOpen ? _config.openIcon : _config.closedIcon"></mf-icon>
      </button>
    </div>
    <div class="mf-left-content">
      <ng-template *ngIf="!_isUndefined(_headerMainLeftDef)"
                   [ngTemplateOutlet]="_headerMainLeftDef.template">
      </ng-template>
    </div>
    <div class="mf-spacer">

    </div>
    <div class="mf-right-content">
      <ng-template *ngIf="!_isUndefined(_headerMainRightDef)"
                   [ngTemplateOutlet]="_headerMainRightDef.template">
      </ng-template>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row class="mf-sub-header">
    <div class="mf-breadcrumb">
      <mf-breadcrumb></mf-breadcrumb>
    </div>
    <div class="mf-left-content">
      <ng-template *ngIf="!_isUndefined(_headerSubLeftDef)"
                   [ngTemplateOutlet]="_headerSubLeftDef.template">
      </ng-template>
    </div>
    <div class="mf-spacer">

    </div>
    <div class="mf-right-content">
      <ng-template *ngIf="!_isUndefined(_headerSubRightDef)"
                   [ngTemplateOutlet]="_headerSubRightDef.template">
      </ng-template>
    </div>
  </mat-toolbar-row>