import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { MfClickOutsideModule } from "@material-framework/clickOutside/click.outside.module";
import { MfDatePickerModule } from "@material-framework/datePicker/date.picker.module";
import { MfFilterExpressionComponent } from "@material-framework/filter/expression/filter.expression.component";
import { MfFilterFieldSelectComponent } from "@material-framework/filter/fieldSelect/filter.field.select.component";
import { MfFilterComponent } from "@material-framework/filter/filter.component";
import { MF_FILTER_CONFIG_TOKEN, MF_FILTER_DEFAULT_CONFIG } from "@material-framework/filter/filter.config";
import { MfFilterGroupComponent } from "@material-framework/filter/group/filter.group.component";
import { MfFilterOperatorSelectComponent } from "@material-framework/filter/operatorSelect/filter.operator.select.component";
import { MfFilterService } from "@material-framework/filter/services/filter.service";
import { MfFilterValueInputComponent } from "@material-framework/filter/valueInput/filter.value.input.component";
import { MfGraphQLFilterConverterService } from "@material-framework/graphQL/filter/graph.ql.filter.converter.service";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MfInputModule } from "@material-framework/input/input.module";
import { MfModelConfigModule } from "@material-framework/modelConfig/model.config.module";
import { MfSelectModule } from "@material-framework/select/select.module";


@NgModule({
  declarations: [
    MfFilterComponent,
    MfFilterExpressionComponent,
    MfFilterFieldSelectComponent,
    MfFilterGroupComponent,
    MfFilterOperatorSelectComponent,
    MfFilterValueInputComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MfDatePickerModule,
    MfInputModule,
    MfSelectModule,
    MfModelConfigModule,
    MfIconModule,
    MatTooltipModule,
    MfClickOutsideModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  exports: [
    MfFilterComponent,
    MfFilterValueInputComponent,
  ],
  providers: [
    { provide: MF_FILTER_CONFIG_TOKEN, useValue: MF_FILTER_DEFAULT_CONFIG },
    MfFilterService,
    MfGraphQLFilterConverterService,
  ]
})
export class MfFilterModule {
}