import { HttpErrorResponse } from "@angular/common/http";
import { concatMap, delay, EMPTY, Observable, of } from "rxjs";
import { MfErrorHttpResponseError } from "../common/error/error";
import { MfTypeInfo } from "../common/type.info";
import { mfTypeIsUndefined } from "../common/utils/type.utils";
import { MfHTTPClientHTTPRetryConfig } from "./http.client.config";

export type MfRetryHTTPRequestOptions = {
  retriesCount: number;
}


export function mfRetryHTTPRequest<T>(typeInfo: MfTypeInfo, httpError: HttpErrorResponse, methodName: string, caught: Observable<T>, httpRetryConfig: MfHTTPClientHTTPRetryConfig, options: MfRetryHTTPRequestOptions): Observable<T> {
  const statusCode = httpError.status;
  const statusConfig = httpRetryConfig[statusCode];

  options.retriesCount++;

  if (!mfTypeIsUndefined(statusConfig)) {
    if (options.retriesCount <= statusConfig.retryCount) {
      if (!mfTypeIsUndefined(statusConfig.delay)) {
        return of(EMPTY).pipe(
          delay(statusConfig.delay),
          concatMap(() => caught)
        );
      }
      return caught;
    }
  }

  throw new MfErrorHttpResponseError(typeInfo, methodName, httpError.status, httpError.message, httpError);
}