import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFilterService } from "@material-framework/filter/services/filter.service";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfTableRowHeaderMenuComponent } from "@material-framework/table/row/header/menu/table.row.header.menu.component";
import { MfOnSortIndexChangingEvent } from "@material-framework/table/row/header/menu/table.row.header.menu.sort.component";
import { MfTableFieldColumn, MfTableFilterExpressionEvent, MfTableFilterGroupEvent } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";
import { MfTableFilterModeTypes } from "@material-framework/table/table.types";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowFieldHeaderCellComponent" };

@Component({
  selector: "mf-table-row-field-header-cell",
  templateUrl: "table.row.field.header.cell.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowFieldHeaderCellComponent extends MfBaseComponent {
  @ViewChild("container")
  public container?: ElementRef;

  @Input()
  public fieldColumn?: MfTableFieldColumn;

  // @Input()
  // public filterGroup?: MfFilterGroup;

  @Input()
  public get filterMode(): MfTableFilterModeTypes {
    return this._filterMode;
  }
  public set filterMode(value: MfTableFilterModeTypes) {
    this._filterMode = value;
    if (!mfTypeIsUndefined(this._menuRef)) {
      this._menuRef.setInput("filterMode", this._filterMode);
    }
  }

  @Output()
  public onClickOutside: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onMenuOpen: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onMenuClose: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onSortCleared: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onSortDirectionChanged: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onSortIndexChanged: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onSortIndexChanging: EventEmitter<MfOnSortIndexChangingEvent> = new EventEmitter();

  @Output()
  public onWidthChanged: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onFilterChange: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterGroupChange: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterRemoveGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterAddGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterExpressionChange: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onFilterAddExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onFilterRemoveExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();


  protected _overlayRef?: OverlayRef;
  protected _menuPortal?: ComponentPortal<MfTableRowHeaderMenuComponent>;
  protected _menuRef?: ComponentRef<MfTableRowHeaderMenuComponent>;
  protected _menu?: MfTableRowHeaderMenuComponent;
  protected _filterMode: MfTableFilterModeTypes = MfTableFilterModeTypes.tableHeader;

  public constructor(
    protected override _injector: Injector,
    protected _modelConfigService: MfModelConfigService,
    protected _overlay: Overlay,
    protected _viewContainerRef: ViewContainerRef,
    protected _filterService: MfFilterService,
    @Inject(MF_TABLE_CONFIG_TOKEN) public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public openMenu(): void {
    this._open();
  }

  protected _onHeaderClicked() {
    this._open();
  }

  protected _getConfigOverlay(container: ElementRef): OverlayConfig {
    const positionStrategy = this._overlay.position()
      .flexibleConnectedTo(container)
      .withPositions([{
        originX: "start",
        originY: "bottom",
        overlayX: "start",
        overlayY: "top",
      }]);

    return new OverlayConfig({
      scrollStrategy: this._overlay.scrollStrategies.block(),
      panelClass: ["mat-elevation-z8"],
      positionStrategy: positionStrategy,
    });
  }

  protected _open(): void {
    if (mfTypeIsUndefined(this._overlayRef) && !mfTypeIsUndefined(this.container)) {

      const overlayConfig = this._getConfigOverlay(this.container);

      this._overlayRef = this._overlay.create(overlayConfig);
      this._menuPortal = new ComponentPortal(MfTableRowHeaderMenuComponent, this._viewContainerRef, this._injector);
      this._menuRef = this._overlayRef.attach(this._menuPortal);
      this._menu = this._menuRef.instance;

      this._menuRef.setInput("column", this.fieldColumn);
      this._menuRef.setInput("filterMode", this.filterMode);

      this._sub(this._menuRef.instance.onSortCleared, { next: (e) => this.onSortCleared.emit(e) });
      this._sub(this._menuRef.instance.onSortDirectionChanged, { next: (e) => this.onSortDirectionChanged.emit(e) });
      this._sub(this._menuRef.instance.onSortIndexChanged, { next: (e) => this.onSortIndexChanged.emit(e) });
      this._sub(this._menuRef.instance.onSortIndexChanging, { next: (e) => this.onSortIndexChanging.emit(e) });
      this._sub(this._menuRef.instance.onWidthChanged, { next: (e) => this.onWidthChanged.emit(e) });
      this._sub(this._menuRef.instance.onFilterEnterKey, { next: () => this._close() });
      this._sub(this._menuRef.instance.onClose, { next: () => this._close() });

      this._sub(this._menuRef.instance.onFilterChange, { next: (e) => this.onFilterChange.emit(e) });
      this._sub(this._menuRef.instance.onFilterGroupChange, { next: (e) => this.onFilterGroupChange.emit(e) });
      this._sub(this._menuRef.instance.onFilterRemoveGroup, { next: (e) => this.onFilterRemoveGroup.emit(e) });
      this._sub(this._menuRef.instance.onFilterAddGroup, { next: (e) => this.onFilterAddGroup.emit(e) });
      this._sub(this._menuRef.instance.onFilterExpressionChange, { next: (e) => this.onFilterExpressionChange.emit(e) });
      this._sub(this._menuRef.instance.onFilterAddExpression, { next: (e) => this.onFilterAddExpression.emit(e) });
      this._sub(this._menuRef.instance.onFilterRemoveExpression, { next: (e) => this.onFilterRemoveExpression.emit(e) });

      setTimeout(() => {
        if (!mfTypeIsUndefined(this._menuRef)) {
          this._sub(this._menuRef.instance.onClickOutside, { next: () => this._close() });
        }
      }, 1);

      if (!mfTypeIsUndefined(this.fieldColumn)) {
        this.fieldColumn.menu.isOpen = true;
      }

      this.onMenuOpen.emit(this.fieldColumn);
    }
  }

  protected _close(): void {
    if (!mfTypeIsUndefined(this._overlayRef)) {
      if (!mfTypeIsUndefined(this.fieldColumn) && !mfTypeIsUndefined(this.fieldColumn.filter) && !mfTypeIsUndefined(this.fieldColumn.filter.group)) {
        const count = this._filterService.removeEmptyExpressions(this.fieldColumn.filter.group);
        if (count > 0) {
          this.onFilterChange.emit({ group: this.fieldColumn.filter.group });
        }
      }
      this._overlayRef.dispose();
      delete this._overlayRef;
      this.onMenuClose.emit(this.fieldColumn);
      if (!mfTypeIsUndefined(this.fieldColumn)) {
        this.fieldColumn.menu.isOpen = false;
      }
    }
  }
}