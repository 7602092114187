import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { MfAuthService } from "@material-framework/auth/auth.service";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { EMPTY, Observable } from "rxjs";
import { mfStringIsEmptyOrWhitespace } from "../common/utils/string.utils";
import { mfTypeIsNullOrUndefined } from "../common/utils/type.utils";

const TYPE_INFO: MfTypeInfo = { className: "MfAuthAccessTokenInjectorInterceptor" };

@Injectable()
export class MfAuthAccessTokenInjectorInterceptor extends MfBaseService implements HttpInterceptor {
  public constructor(
    protected _authService: MfAuthService,
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this._authService.isUrlAuthenticated(request.url)) {
      return next.handle(request);
    }

    const bearerToken = this._authService.getAccessToken();

    if (mfTypeIsNullOrUndefined(bearerToken) && mfStringIsEmptyOrWhitespace(bearerToken)) {
      return EMPTY;
    }

    const requestWithToken = request.clone({
      headers: request.headers.append("Authorization", "Bearer " + bearerToken)
    });

    return next.handle(requestWithToken);
  }
}