/* eslint-disable @typescript-eslint/no-explicit-any */
export type MfTypeNonNullable<T> = T extends null | undefined ? never : T;
export type MfTypeNonUndefined<T> = T extends undefined ? never : T;
export type MfTypePickKeysByValue<T, V> = { [K in keyof T]: T[K] extends V ? K : never }[keyof T];
/** Pick all properties of given type in object type */
export type MfTypePickProperties<T, P> = Pick<T, MfTypePickKeysByValue<T, P>>;
/** Mark some properties as required, leaving others unchanged */
export type MfTypeMarkRequired<T, RK extends keyof T> = T extends T ? Omit<T, RK> & Required<Pick<T, RK>> : never;
export type MfTypeMarkOptional<T, RK extends keyof T> = T extends T ? Omit<T, RK> & Partial<Pick<T, RK>> : never;

export type MfTypeNumberKeys<T> = { [K in keyof T]: T[K] extends number ? K : never; };
export type MfTypeNumberOptionalKeys<T> = { [K in keyof T]-?: T[K] extends number | undefined ? K : never; };

type MfTypeJoin<
  Key,
  Previous,
  TKey extends number | string = string
> = Key extends TKey
  ? Previous extends TKey
  ? `${Key}${"" extends Previous ? "" : "."}${Previous}`
  : never
  : never;

type Previous = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, ...0[]];

export type MfTypePaths<
  TEntity,
  TDepth extends number = 3,
  TKey extends number | string = string
> = [TDepth] extends [never]
  ? never
  : TEntity extends object
  ? {
    [Key in keyof TEntity]-?: Key extends TKey
    ? `${Key}` | MfTypeJoin<Key, MfTypePaths<TEntity[Key], Previous[TDepth]>>
    : never;
  }[keyof TEntity]
  : "";

export function mfTypeHasToString(obj: unknown): obj is { toString: () => string } {
  return (
    (typeof obj === "object" && obj !== null && "toString" in obj && typeof (obj as { toString: unknown }).toString === "function") ||
    typeof obj === "string" ||
    typeof obj === "number" ||
    typeof obj === "boolean" ||
    typeof obj === "symbol" ||
    typeof obj === "bigint"
  );
}

export function mfTypeIsArray(value: unknown): value is unknown[] {
  return Array.isArray(value);
}
export function mfTypeIsDate(value: unknown): value is Date {
  return !mfTypeIsNullOrUndefined(value) && Object.prototype.toString.call(value) === "[object Date]" && !Number.isNaN(value);
}
export function mfTypeIsBoolean(value: unknown): value is boolean {
  return typeof value === "boolean";
}
export function mfTypeIsFunction(value: unknown): boolean {
  return typeof value === "function";
}
export function mfTypeIsNull(value: unknown): value is null {
  return value === null;
}
export function mfTypeIsNullOrUndefined(value: unknown): value is null | undefined {
  return mfTypeIsUndefined(value) || mfTypeIsNull(value);
}
export function mfTypeIsNumber(value: unknown): value is number {
  return typeof value === "number";
}
export function mfTypeIsNumeric(value: unknown): value is number {
  return mfTypeIsNullOrUndefined(value) ? false : mfTypeIsNumber(value) ? true : mfTypeIsString(value) ? /^[0-9.]*$/g.test(value) : false;
}
export function mfTypeIsObject(value: unknown): value is object {
  return !mfTypeIsNullOrUndefined(value) && typeof value === "object";
}
export function mfTypeIsPrimitive(value: unknown): boolean {
  return (typeof value !== "object" && typeof value !== "function") || mfTypeIsNull(value);
}
export function mfTypeIsString(value: unknown): value is string {
  return typeof value === "string";
}
export function mfTypeIsSymbol(value: unknown): value is symbol {
  return typeof value === "symbol";
}
export function mfTypeIsUndefined(value: unknown): value is undefined {
  return value === undefined;
}
export function mfTypeIsUndefinedOrValue<TValue>(item: unknown, value: TValue): item is undefined {
  return item === undefined || item === value;
}
export function mfTypeIsNonEmpty(obj: object): boolean {
  return !mfTypeIsUndefined(obj) && mfTypeGetKeys(obj).length > 0;
}
export function mfTypeHasOwnProperty<TObject extends object, Y extends PropertyKey, TValue = unknown>(obj: TObject, prop: Y): obj is TObject & Record<Y, TValue> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}
export function mfTypeGetKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}
export function mfTypeIsInstanceOfIdentifier<T extends object>(obj: object, identifierKey: keyof T): obj is T {
  return !mfTypeIsNullOrUndefined(obj) && mfTypeHasOwnProperty(obj, identifierKey) && obj[identifierKey] === true;
}
export function mfTypeIsInstanceOfValueNotUndefinedIdentifier<T extends object>(obj: object, identifierKey: keyof T): obj is T {
  return !mfTypeIsNullOrUndefined(obj) && mfTypeHasOwnProperty(obj, identifierKey) && !mfTypeIsUndefined(obj[identifierKey]);
}
export function mfTypeIsInstanceOf<T>(value: unknown, constructor: new (...args: unknown[]) => T): value is T {
  return !mfTypeIsNullOrUndefined(value) && value instanceof constructor;
}
export function mfTypeCheckIsInstanceOf<T>(value: unknown, checker: (value: T) => boolean): value is T {
  return checker(value as T);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function mfTypeHasFunction<TObject>(obj: TObject, functionName: keyof TObject): functionName is keyof TObject & Function {
  return typeof obj[functionName] === "function";
}
export function mfTypeHasToStringMethod(value: unknown): value is { toString(): string } {
  if (typeof value === "object" && value !== null) {
    return typeof (value as { toString?: () => string }).toString === "function";
  } else {
    return ["string", "number", "bigint", "boolean", "symbol"].includes(typeof value);
  }
}