<ng-template #layout
             let-data>
  <div *ngIf="data.groupColumn.fieldColumns.length > 1"
       [style]="data.groupColumn.style"
       [ngClass]="{'mf-show-hide-item-group': true,'mf-show-hide-item': true, 'mf-show-hide-item-hover': !_isDragging}">
    <div class="mf-column-label mat-body-2">
      {{ data.groupColumn.label }}
    </div>
    <div class="mf-flex-fill"></div>

    <div (click)="_setVisibleAll(data.groupColumn, false)"
         class="mf-column-icon">
      <mf-icon [matTooltip]="_config.header.orderShowHide.hideAllTooltip"
               [icon]="_config.header.orderShowHide.hiddenIcon"></mf-icon>
    </div>

    <div (click)="_setVisibleAll(data.groupColumn, true)"
         class="mf-column-icon">
      <mf-icon [matTooltip]="_config.header.orderShowHide.showAllTooltip"
               [icon]="_config.header.orderShowHide.visibleIcon"></mf-icon>
    </div>

    <div *ngIf="_isUndefined(data.groupColumn.sticky) && _isUndefined(data.groupColumn.stickyEnd)"
         (click)="_stick(data.groupColumn)"
         [matTooltip]="_config.header.orderShowHide.stickTooltip"
         class="mf-column-icon">
      <mf-icon [icon]="_config.header.orderShowHide.stickIcon"></mf-icon>
    </div>
    <div *ngIf="!_isUndefined(data.groupColumn.sticky) && !_isUndefined(data.groupColumn.stickyEnd)"
         (click)="_stickRemove(data.groupColumn)"
         [matTooltip]="_config.header.orderShowHide.stickRemoveTooltip"
         class="mf-column-icon">
      <mf-icon [icon]="_config.header.orderShowHide.stickRemoveIcon"></mf-icon>
    </div>
    <div [matTooltip]="_config.header.orderShowHide.moveTooltip"
         class="mf-column-icon">
      <mf-icon [icon]="_config.header.orderShowHide.moveIcon"></mf-icon>
    </div>
  </div>

  <div *ngFor="let fieldColumn of data.groupColumn.fieldColumns">
    <div [style.paddingLeft.px]="data.groupColumn.fieldColumns.length === 1 ? 8 : 16"
         [ngClass]="{'mf-show-hide-item-field': true, 'mf-show-hide-item': true, 'mf-show-hide-item-hover': !_isDragging}"
         [style]="fieldColumn.modelFieldConfig?.table?.header?.style">
      <div class="mf-column-label mat-body">
        {{ fieldColumn.label }}
      </div>
      <div class="mf-flex-fill"></div>
      <div (click)="_toggleVisible(fieldColumn)"
           class="mf-column-icon">
        <mf-icon [matTooltip]="_isUndefined(fieldColumn.visible) || fieldColumn.visible === true ? _config.header.orderShowHide.hideTooltip : _config.header.orderShowHide.showTooltip"
                 [icon]="_isUndefined(fieldColumn.visible) || fieldColumn.visible === true ? _config.header.orderShowHide.visibleIcon : _config.header.orderShowHide.hiddenIcon"></mf-icon>
      </div>
      <div *ngIf="data.groupColumn.fieldColumns.length === 1 && _isUndefined(fieldColumn.sticky) && _isUndefined(fieldColumn.stickyEnd)"
           (click)="_stick(fieldColumn)"
           [matTooltip]="_config.header.orderShowHide.stickTooltip"
           class="mf-column-icon">
        <mf-icon [icon]="_config.header.orderShowHide.stickIcon"></mf-icon>
      </div>
      <div *ngIf="data.groupColumn.fieldColumns.length === 1 && !_isUndefined(fieldColumn.sticky) && !_isUndefined(fieldColumn.stickyEnd)"
           (click)="_stickRemove(fieldColumn)"
           [matTooltip]="_config.header.orderShowHide.stickRemoveTooltip"
           class="mf-column-icon">
        <mf-icon [icon]="_config.header.orderShowHide.stickRemoveIcon"></mf-icon>
      </div>
      <div [matTooltip]="_config.header.orderShowHide.moveTooltip"
           *ngIf="data.groupColumn.fieldColumns.length === 1"
           class="mf-column-icon">
        <mf-icon [icon]="_config.header.orderShowHide.moveIcon"></mf-icon>
      </div>
    </div>
  </div>
</ng-template>

<div class="mf-side-draw-content-container mf-show-hide-order-container">
  <div class="mf-header">
    <h3 class="mf-header-label">
      {{ _config.header.orderShowHide.title }}
    </h3>
    <div class="mf-flex-fill"></div>
    <button mat-icon-button
            (click)="_onCloseClicked()"
            matTooltip="{{_config.header.orderShowHide.closeTooltip}}">
      <mf-icon class="mf-header-icon"
               [icon]="_config.header.orderShowHide.closeIcon || _rootConfig.slideDrawer.closeIcon"></mf-icon>
    </button>
  </div>
  <div class="mf-columns-list"
       cdkScrollable>
    <div cdkDropList
         [cdkDropListAutoScrollDisabled]="false"
         [cdkDropListAutoScrollStep]="10"
         cdkDragLockAxis="y"
         previewClass=""
         (cdkDropListDropped)="_onDropListDropped($event)">
      <div cdkDrag
           (cdkDragStarted)="_isDragging = true"
           (cdkDragEnded)="_isDragging = false"
           [cdkDragData]="groupColumn"
           class="mf-drag-item"
           [style.height.px]="!_isUndefined(groupColumn?.fieldColumns![0].modelFieldConfig?.table?.showHideOrder?.visible) && groupColumn?.fieldColumns![0].modelFieldConfig?.table?.showHideOrder?.visible === false ? 0 : groupColumn.fieldColumns!.length > 1 ? (groupColumn.fieldColumns!.length + 1) * 48 : 48"
           *ngFor="let groupColumn of groupColumns">
        <div>
          <div *cdkDragPreview></div>
          <div *cdkDragPlaceholder>
            <div class="mf-drag-preview">
              <ng-template
                           *ngTemplateOutlet="layout; context: {$implicit: { groupColumn: groupColumn   }}"></ng-template>
            </div>
          </div>

          <ng-template *ngTemplateOutlet="layout; context: {$implicit: { groupColumn: groupColumn   }}"></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>