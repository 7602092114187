import { Component, Injector } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";


const TYPE_INFO: MfTypeInfo = { className: "EComEntryPointComponent" };

@Component({
  selector: "ecom-entry-point",
  templateUrl: "./entry.point.component.html",
  styleUrls: ["./entry.point.component.scss"]
})
export class EComEntryPointComponent extends MfBaseComponent {
  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }
}