import { Component, Injector } from "@angular/core";
import { EComCheckoutStepBaseComponent } from "@e-commerce/checkout/base/checkout.step.base.component";
import { EComCompleteInvoiceModel } from "@e-commerce/modelConfigs/complete.invoice.model.config";
import { MfTypeInfo } from "@material-framework/common/type.info";

const TYPE_INFO: MfTypeInfo = { className: "EComInvoiceOrderReviewComponent" };

@Component({
  selector: "ecom-invoice-order-review",
  templateUrl: "./invoice.order.review.component.html",
  styleUrls: ["./invoice.order.review.component.scss"]
})
export class EComInvoiceOrderReviewComponent extends EComCheckoutStepBaseComponent<EComCompleteInvoiceModel> {
  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected override _onCheckoutModelSet(model: EComCompleteInvoiceModel): void {
  }
}