<mat-form-field class="mf-slim-mdc-form-field"
                matTooltip="Select account"
                [style.width.ch]="2 + (_selectedAccount?.displayName?.length || 0)"
                [style.minWidth.px]="100">
  <mf-select (onOptionChanged)="_optionChanged($event)"
             clearEnabled="false"
             [disabled]="disabled"
             [options]="_options"
             [value]="_selectedId"
             placeholder="Account Name">
  </mf-select>
</mat-form-field>