import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { EComOrderBillingPaymentComponent } from "@e-commerce/checkout/order/billingPayment/order.billing.payment.component";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MfInputModule } from "@material-framework/input/input.module";


@NgModule({
  declarations: [
    EComOrderBillingPaymentComponent
  ],
  exports: [
    EComOrderBillingPaymentComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCardModule,
    MfInputModule,
    MatRadioModule,
    MfIconModule,
  ],
  providers: [
  ]
})
export class EComOrderBillingPaymentModule { }