<div class="mf-filter-toolbar">
  <div class="mf-toolbar">
    <div *ngIf="_config.logic.canAddLogic === true"
         class="mf-filter-toolbar-item">
      <mat-button-toggle-group class="mf-filter-toolbar-item-and-or"
                               (change)="_andOrChange()"
                               [(value)]="group!.andOr">
        <mat-button-toggle value="and">{{ _config.logic.and.displayName }}</mat-button-toggle>
        <mat-button-toggle value="or">{{ _config.logic.or.displayName }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="mf-filter-toolbar-item">
      <button [disabled]="!_isUndefined(_config.expression.maxFilters) && _expressionCount >= _config.expression.maxFilters"
              (click)="_addFilterClicked()"
              [matTooltip]="_config.group.addFilterTooltip"
              mat-stroked-button>{{ _config.group.addFilterDisplayName }}</button>
    </div>
    <div *ngIf="_config.group.canAddGroup === true"
         class="mf-filter-toolbar-item">
      <button (click)="_addGroupClicked()"
              [matTooltip]="_config.group.addGroupTooltip"
              mat-stroked-button>{{ _config.group.addGroupDisplayName }}</button>
    </div>
    <div *ngIf="!firstLevelGroup"
         class="mf-filter-toolbar-item">
      <button (click)="_removeGroupClicked()"
              [matTooltip]="_config.group.removeGroupTooltip"
              mat-icon-button>
        <mf-icon [icon]="_config.group.removeIcon"></mf-icon>
      </button>
    </div>
    <div *ngIf="_showCollapseExpand"
         class="mf-filter-toolbar-item">
      <button (click)="_toggleGroupCollapsed()"
              matTooltip="{{group?.isCollapsed ? _config.group.expandTooltip : _config.group.collapseTooltip}}"
              mat-icon-button>
        <mf-icon [icon]="group?.isCollapsed ? _config.group.expandIcon : _config.group.collapseIcon"></mf-icon>
      </button>
    </div>
  </div>
</div>
<div *ngIf="!group?.isCollapsed"
     @fadeIn
     @fadeOut>
  <ul class="mf-filter-lines">
    <ng-container *ngFor="let expression of group?.expressions">
      <li class="mf-filter-item">
        <mf-filter-expression (onRemoveExpression)="_removeExpression($event)"
                              (onExpressionChange)="onExpressionChange.emit($event)"
                              (onEnterKey)="onEnterKey.emit($event)"
                              [singleFieldFilter]="singleFieldFilter"
                              [singleFieldPath]="singleFieldPath"
                              [modelFieldsConfig]="modelFieldsConfig"
                              [modelFieldConfig]="expression.modelFieldConfig"
                              [expression]="expression"></mf-filter-expression>
      </li>
      <ul *ngIf="!_isUndefined(expression.subGroup)"
          class="mf-filter-lines mf-filter-lines-subgroup">
        <li class="mf-filter-item">
          <mf-filter-group (onGroupChange)="onGroupChange.emit($event)"
                           (onRemoveGroup)="_removeChildGroup($event)"
                           (onAddGroup)="onAddGroup.emit($event)"
                           (onExpressionChange)="onExpressionChange.emit($event)"
                           (onAddExpression)="onAddExpression.emit($event)"
                           (onRemoveExpression)="onRemoveExpression.emit($event)"
                           (onEnterKey)="onEnterKey.emit($event)"
                           [firstLevelGroup]="true"
                           [group]="expression.subGroup"
                           [modelFieldsConfig]="expression.modelFieldConfig.model?.fields"></mf-filter-group>
        </li>
      </ul>
    </ng-container>
    <li class="mf-filter-item"
        *ngFor="let group of group?.groups">
      <mf-filter-group (onGroupChange)="onGroupChange.emit($event)"
                       (onRemoveGroup)="_removeChildGroup($event)"
                       (onAddGroup)="onAddGroup.emit($event)"
                       (onExpressionChange)="onExpressionChange.emit($event)"
                       (onAddExpression)="onAddExpression.emit($event)"
                       (onRemoveExpression)="onRemoveExpression.emit($event)"
                       (onEnterKey)="onEnterKey.emit($event)"
                       [group]="group"
                       [singleFieldFilter]="singleFieldFilter"
                       [singleFieldPath]="singleFieldPath"
                       [modelFieldsConfig]="modelFieldsConfig"></mf-filter-group>
    </li>
  </ul>
</div>