import { Directive, Inject, Input } from "@angular/core";
import { MfTableColumnDef } from "@material-framework/table/row/column/table.column.def.directive";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

@Directive({
  selector: "[mfTableColumnSlideOutActionsDef]",
})
export class MfTableColumnSlideOutActionsDef extends MfTableColumnDef {

  @Input("mfTableColumnSlideOutActionsDef")
  public override name?: string;

  @Input()
  public headerLabel: string = "";

  public constructor(@Inject(MF_TABLE_CONFIG_TOKEN) public override config: MfTableConfig) {
    super(config);
    this.headerLabel = "Actions";
  }
}