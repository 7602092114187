<mat-form-field class="date-time-field"
                subscriptSizing="dynamic"
                style=" visibility: hidden; position: absolute; top: -10000px; left: -10000px;">
       <input matInput
              [matDatepicker]="fauxPicker">
       <mat-datepicker-toggle matIconSuffix
                              [for]="fauxPicker"></mat-datepicker-toggle>
       <mat-datepicker #fauxPicker></mat-datepicker>
</mat-form-field>

<mat-form-field>
       <input matInput
              #input
              [formControl]="_formControl"
              [ngxMatDatetimePicker]="picker"
              [placeholder]="placeholder">
       <mat-datepicker-toggle matSuffix
                              [for]="$any(picker)"></mat-datepicker-toggle>
       <ngx-mat-datetime-picker #picker
                                [defaultTime]="defaultTime"
                                [showSpinners]="showSpinners"
                                [showSeconds]="showSeconds"
                                [stepHour]="stepHour"
                                [stepMinute]="stepMinute"
                                [stepSecond]="stepSecond"
                                [touchUi]="touchUi"
                                [color]="color"
                                [enableMeridian]="enableMeridian"
                                [disableMinute]="disableMinute"
                                [hideTime]="hideTime">
       </ngx-mat-datetime-picker>
</mat-form-field>