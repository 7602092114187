<div #snackbarContainer
     class="mf-snackbar">
  <div *ngIf="_extendedInfoIsOpen === true"
       @fadeIn
       @fadeOut
       class="mf-snackbar-extended-info mat-elevation-z3">
    <div class="mf-column mf-snackbar-info-column">
      <div class="mf-row">
        <div class="mf-default-margin-right mf-snackbar-info-label">
          Class Name
        </div>
        <div>
          {{ _selectedMfErrorMessage?.error?.typeInfo?.className?? 'None' }}
        </div>
      </div>
    </div>
    <div class="mf-column mf-snackbar-info-column">
      <div class="mf-row">
        <div class="mf-default-margin-right mf-snackbar-info-label">
          Method Name
        </div>
        <div>
          {{ _selectedMfErrorMessage?.error?.methodName?? 'None' }}
        </div>
      </div>
    </div>
    <div class="mf-column mf-snackbar-info-column">
      <div class="mf-row">
        <div class="mf-default-margin-right mf-snackbar-info-label">
          Message
        </div>
        <div>
          {{ _selectedMfErrorMessage?.error?.message?? 'None' }}
        </div>
      </div>
    </div>
    <div class="mf-column mf-snackbar-info-column">
      <div class="mf-row">
        <div class="mf-default-margin-right mf-snackbar-info-label">
          Stack
        </div>
        <div>
          {{ _selectedMfErrorMessage?.error?.stack ?? 'None' }}
        </div>
      </div>
    </div>
  </div>
  <div class="mf-snackbar-item"
       *ngIf="!_isUndefined(_selectedMessageTypeConfig) && !_isUndefined(_selectedMessageTypeConfig.icon)">
    <mf-icon [size]="25"
             [icon]="_selectedMessageTypeConfig.icon"
             [style.color]="_selectedMessageTypeConfig.color"></mf-icon>
  </div>
  <div class="mf-snackbar-item mf-flex-fill">
    {{ selectedMessage?.message }}
  </div>
  <div class="mf-snackbar-action">
    <button mat-icon-button
            [matTooltip]="_config.firstTooltip"
            [style]="{ color: !_canFirst ? '#9f9f9f' : 'unset'}"
            [disabled]="!_canFirst"
            (click)="_firstMessage()">
      <mf-icon [icon]="_config.firstIcon"></mf-icon>
    </button>
  </div>
  <div class="mf-snackbar-action">
    <button mat-icon-button
            [matTooltip]="_config.previousTooltip"
            [style]="{ color: !_canPrevious ? '#9f9f9f' : 'unset'}"
            [disabled]="!_canPrevious"
            (click)="_previousMessage()">
      <mf-icon [icon]="_config.previousIcon"></mf-icon>
    </button>
  </div>
  <div class="mf-snackbar-item">
    {{ !_isUndefined(selectedIndex) ? selectedIndex + 1 : 0 }} of {{ _snackBarService.messageCount }}
  </div>
  <div class="mf-snackbar-action">
    <button mat-icon-button
            [matTooltip]="_config.nextTooltip"
            [style]="{ color: !_canNext ? '#9f9f9f' : 'unset'}"
            [disabled]="!_canNext"
            (click)="_nextMessage()">
      <mf-icon [icon]="_config.nextIcon"></mf-icon>
    </button>

  </div>
  <div class="mf-snackbar-action">
    <button mat-icon-button
            [matTooltip]="_config.lastTooltip"
            [style]="{ color: !_canLast ? '#9f9f9f' : 'unset'}"
            [disabled]="!_canLast"
            (click)="_lastMessage()">
      <mf-icon [icon]="_config.lastIcon"></mf-icon>
    </button>
  </div>
  <div class="mf-snackbar-action">
    <button mat-icon-button
            [matTooltip]="_config.deleteTooltip"
            (click)="_deleteMessage()">
      <mf-icon [icon]="_config.deleteIcon"></mf-icon>
    </button>
  </div>
  <div *ngIf="!_isUndefined(selectedMessage) && (selectedMessage.type === _snackBarMessageTypes.errorMf || selectedMessage.type === _snackBarMessageTypes.error)"
       class="mf-snackbar-action">
    <button #extendedInfoButton
            mat-icon-button
            [matTooltip]="this._extendedInfoIsOpen ? _config.hideMoreInfoTooltip :_config.showMoreInfoTooltip"
            (click)="_openExtendedInfo()">
      <mf-icon [icon]="this._extendedInfoIsOpen ? _config.hideMoreInfoIcon : _config.showMoreInfoIcon"></mf-icon>
    </button>
  </div>
</div>