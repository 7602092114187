import { EventEmitter, Injectable, Injector } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfViewState, MfViewStateData } from "@material-framework/viewManager/view.manager";
import { MfLocationKey } from "@material-framework/viewManager/view.manager.component";
import { MfViewManagerStorageService } from "@material-framework/viewManager/view.manager.storage.service";
import { Observable, of } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "MfViewManagerInMemoryService" };
let viewManagerInMemoryServiceNextId = 1;

@Injectable()
export class MfViewManagerInMemoryService extends MfViewManagerStorageService<MfViewStateData> {
  public onViewStateUpdated: EventEmitter<MfViewState<MfViewStateData>> = new EventEmitter();
  public onViewStateNeedsCaching: EventEmitter<MfLocationKey> = new EventEmitter();

  protected readonly _store: MfViewState<MfViewStateData>[] = [];

  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }

  public get<TData extends MfViewStateData>(locationKey: string): Observable<MfViewState<TData>[]> {
    return of(this._store.filter(i => i.locationKey === locationKey) as MfViewState<TData>[]);
  }

  public setAsDefault(locationKey: string, id: number): Observable<boolean> {
    const viewState = this._store.find(i => i.locationKey === locationKey && i.id === id);
    if (!mfTypeIsUndefined(viewState)) {
      this._store.filter(i => i.locationKey === viewState.locationKey).forEach(i => i.isDefault = false);
      viewState.isDefault = true;
      return of(true);
    }
    return of(false);
  }

  public updateCache<TData extends MfViewStateData>(locationKey: string, viewState: MfViewState<TData>): Observable<MfViewState<TData> | undefined> {
    return of();
  }

  public update<TData extends MfViewStateData>(locationKey: string, viewState: MfViewState<TData>): Observable<MfViewState<TData> | undefined> {
    const index = this._store.findIndex(i => i.locationKey === viewState.locationKey && i.id === viewState.id);
    if (index != -1) {
      this._store[index] = viewState;
      this.onViewStateUpdated.emit(viewState);
      return of(viewState);
    }
    return of(undefined);
  }

  public add<TData extends MfViewStateData>(locationKey: string, viewState: MfViewState<TData>): Observable<MfViewState<TData> | undefined> {
    viewState.id = viewManagerInMemoryServiceNextId;
    this._store.push(viewState);
    viewManagerInMemoryServiceNextId++;
    return of(viewState);
  }

  public delete(locationKey: string, id: number): Observable<boolean> {
    const index = this._store.findIndex(i => i.locationKey === locationKey && i.id === id);
    if (index != -1) {
      this._store.splice(index, 1);
      return of(true);
    }
    return of(false);
  }
}