import { Directive, Injector } from "@angular/core";
import { MfBaseSafeUnsubscribe } from "@material-framework/base/base.safe.unsubscribe";
import { MfTypeInfo } from "@material-framework/common/type.info";

@Directive()
export abstract class MfBaseService extends MfBaseSafeUnsubscribe {
  public constructor(
    protected override _typeInfo: MfTypeInfo,
    protected override _injector: Injector,
  ) {
    super(_typeInfo, _injector);
  }
}