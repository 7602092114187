import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from "@angular-material-components/datetime-picker";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MfDatePickerComponent } from "@material-framework/datePicker/date.picker.component";
import { MF_DATE_PICKER_CONFIG_TOKEN, MF_DATE_PICKER_DEFAULT_CONFIG } from "@material-framework/datePicker/date.picker.config";

@NgModule({
  declarations: [
    MfDatePickerComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    ReactiveFormsModule,
    MatNativeDateModule,
  ],
  exports: [
    MfDatePickerComponent,
  ],
  providers: [
    { provide: MF_DATE_PICKER_CONFIG_TOKEN, useValue: MF_DATE_PICKER_DEFAULT_CONFIG },
  ]
})
export class MfDatePickerModule {
}