import { InjectionToken } from "@angular/core";
import { MfModelFieldConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { mfGetUserPersonaRequiredFields } from "@material-framework/userPersona/user.persona.required.fields";

/**
 * @constant MF_MODEL_REQUIRED_FIELD_RETRIEVER_TYPES_TOKEN
 * @description Injection token used to provide a collection of field retriever functions, which determine the required fields for different types of model fields.
 * @type {InjectionToken<MfModelRequiredFieldRetrieverTypes>}
 */
export const MF_MODEL_REQUIRED_FIELD_RETRIEVER_TYPES_TOKEN = new InjectionToken<MfModelRequiredFieldRetrieverTypes>("MfModelRequiredFieldRetrieverTypes");

/**
 * @constant MF_MODEL_REQUIRED_FIELD_RETRIEVER_TYPES
 * @description A collection of field retriever functions mapped by type. These functions are responsible for identifying required fields for specific model field types.
 * @type {MfModelRequiredFieldRetrieverTypes}
 */
export const MF_MODEL_REQUIRED_FIELD_RETRIEVER_TYPES: MfModelRequiredFieldRetrieverTypes = {
  userPersona: mfGetUserPersonaRequiredFields,
};

/**
 * @typedef {Function} MfModelRequiredFieldRetrieverFunction
 * @description A function type used to determine the required fields for a given model field configuration.
 * @param {MfModelFieldConfigMapped} modelFieldConfig - The model field configuration to analyze.
 * @param {MfModelConfigService} modelConfigService - The model configuration service used to assist in retrieving required fields.
 * @returns {string[]} An array of strings representing the required field paths.
 */
export type MfModelRequiredFieldRetrieverFunction = (modelFieldConfig: MfModelFieldConfigMapped, modelConfigService: MfModelConfigService) => string[];

/**
 * @typedef {Object} MfModelRequiredFieldRetrieverTypes
 * @description A mapping of different types of model field retrievers to their respective functions. Each key represents a field type, and the value is the function that retrieves the required fields.
 * @property {MfModelRequiredFieldRetrieverFunction} userPersona - A function that retrieves required fields for user persona model fields.
 */
export type MfModelRequiredFieldRetrieverTypes = {
  userPersona: MfModelRequiredFieldRetrieverFunction;
}

/**
 * @typedef {Object} MfModelRequiredFieldRetrieverConfig
 * @description Configuration object for specifying the type of field retriever function to use.
 * @property {string} type - The type of field retriever function to use, corresponding to a key in MfModelRequiredFieldRetrieverTypes.
 */
export type MfModelRequiredFieldRetrieverConfig = {
  type: string;
}