import { InjectionToken } from "@angular/core";
import { mfTypeGetKeys } from "@material-framework/common/utils/type.utils";
import { MfModelFieldDataTypes, MfModelFieldNumberTypes } from "@material-framework/modelConfig/model.config";
import { MfTableCellFormatterModelDataTypeConfig } from "@material-framework/table/table.config";

export const MF_TABLE_CELL_FORMATTER_TO_MODEL_DATATYPE_CONFIG_TOKEN = new InjectionToken<MfTableCellFormatterModelDataTypeConfig[]>("MfTableCellFormatterModelDataTypeConfigs");
export const MF_TABLE_CELL_FORMATTER_TO_MODEL_DATATYPE_CONFIG: MfTableCellFormatterModelDataTypeConfig[] = [
  { types: [MfModelFieldDataTypes.array], formatter: "array" },
  { types: [MfModelFieldDataTypes.boolean], formatter: "boolean" },
  { types: [MfModelFieldDataTypes.dateTime], formatter: "date" },
  { types: [MfModelFieldDataTypes.date], formatter: "date" },
  { types: mfTypeGetKeys(MfModelFieldNumberTypes), formatter: "number" },
  { types: [MfModelFieldDataTypes.string], formatter: "string" },
];