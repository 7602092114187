import { Component, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { eComPayPalGetPaymentInfoFromQueryPrams } from "@e-commerce/common/paypal";
import { EComCartService } from "@e-commerce/services/cart.service";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { concatMap } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComPaypalReturnComponent" };

@Component({
  selector: "ecom-paypal-return",
  templateUrl: "./paypal.return.component.html",
  styleUrls: ["./paypal.return.component.scss"]
})
export class EComPaypalReturnComponent extends MfBaseComponent {
  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected _cartService: EComCartService,
    protected _customerService: EComCustomerService,
  ) {
    super(TYPE_INFO, _injector);
    this._endPayment();
  }

  protected _endPayment(): void {
    const loading = this._subLoading(
      this._activatedRoute.queryParams.pipe(
        concatMap((queryParams) => {
          return this._cartService.endPayment({ paymentInfo: eComPayPalGetPaymentInfoFromQueryPrams(queryParams) });
        })
      ),
      {
        next: () => { loading.complete(); },
        error: () => { loading.complete(); },
      }
    );
  }
}