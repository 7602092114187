<div class="mf-table-row-field-header-cell-info">
  <div *ngIf="showSortOrder"
       class="mf-table-header-info-sort"
       [matTooltip]="(fieldColumn!.sort!.index! + 1 | mfIntWithOrdinal) + ' ' + ascTooltip">
    <mf-icon *ngIf="fieldColumn?.sort?.direction === sortTypes.asc"
             [icon]="_config.row.header.sort.ascIcon"></mf-icon>
    <mf-icon *ngIf="fieldColumn?.sort?.direction === sortTypes.desc"
             [icon]="_config.row.header.sort.descIcon"></mf-icon>
    {{showSortOrder ? fieldColumn!.sort!.index! + 1 : ""}}
  </div>
  <div *ngIf="!_isUndefined(fieldColumn?.filter)"
       class="mf-table-header-info-filter">
    <mf-icon [matTooltip]="fieldColumn?.filter?.hasFilters ? _config.row.header.filter.hasFilterTooltip : ''"
             [color]="fieldColumn?.filter?.hasFilters ? _config.row.header.filter.filterAppliedColor : _config.row.header.filter.filterUnappliedColor"
             [icon]="_config.row.header.filter.hasFilterIcon"></mf-icon>
  </div>
  <div class="mf-flex-fill"></div>
  <div *ngIf="!_isUndefined(fieldColumn!.columnModelConfigDef) && !_isUndefined(fieldColumn!.columnModelConfigDef.headerCellActionsDef)"
       class="mf-table-header-info-actions">
    <ng-template [ngTemplateOutlet]="fieldColumn!.columnModelConfigDef!.headerCellActionsDef!.template!"
                 [ngTemplateOutletContext]="{$implicit: item}">
    </ng-template>
  </div>
</div>