import { OverlayModule } from "@angular/cdk/overlay";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { MfErrorModule } from "@material-framework/error/error.module";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MfSnackBarComponent } from "@material-framework/snackbar/snackbar.component";
import { MF_SNACKBAR_CONFIG_TOKEN, MF_SNACKBAR_DEFAULT_CONFIG } from "@material-framework/snackbar/snackbar.config";
import { MfSnackBarService } from "@material-framework/snackbar/snackbar.service";

@NgModule({
  declarations: [
    MfSnackBarComponent
  ],
  imports: [
    BrowserModule,
    OverlayModule,
    MfErrorModule,
    MfIconModule,
    MatTooltipModule,
    MatButtonModule,
  ],
  exports: [
    MfSnackBarComponent
  ],
  bootstrap: [
    MfSnackBarComponent
  ],
  providers: [
    { provide: MF_SNACKBAR_CONFIG_TOKEN, useValue: MF_SNACKBAR_DEFAULT_CONFIG },
    MfSnackBarService,
  ]
})
export class MfSnackBarModule {
}