import { InjectionToken } from "@angular/core";
import { MfTableRowCellDateEditorComponent } from "@material-framework/table/row/cell/value/editor/table.row.cell.date.editor.component";
import { MfTableRowCellNumberEditorComponent } from "@material-framework/table/row/cell/value/editor/table.row.cell.number.editor.component";
import { MfTableRowCellArrayFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.array.formatter.component";
import { MfTableRowCellBooleanFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.boolean.formatter.component";
import { MfTableRowCellCurrencyFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.currency.formatter.component";
import { MfTableRowCellDateFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.date.formatter.component";
import { MfTableRowCellEnumFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.enum.formatter.component";
import { MfTableRowCellHtmlDialogFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.html.dialog.formatter.component";
import { MfTableRowCellHtmlFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.html.formatter.component";
import { MfTableRowCellNumberFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.number.formatter.component";
import { MfTableRowCellPercentageFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.percentage.formatter.component";
import { MfTableRowCellStatusChipFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.status.chip.formatter.component";
import { MfTableRowCellStringFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.string.formatter.component";
import { MfTableRowCellUserFormatterComponent } from "@material-framework/table/row/cell/value/formatter/table.row.cell.user.formatter.component";
import { MfTableCellFormatterConfig } from "@material-framework/table/table.config";


export const MF_TABLE_CELL_FORMATTERS_CONFIG_TOKEN = new InjectionToken<MfTableCellFormatterConfig>("MfTableCellFormatterConfig");
export const MF_TABLE_CELL_FORMATTERS_CONFIG: MfTableCellFormatterConfig = {
  array: MfTableRowCellArrayFormatterComponent,
  boolean: MfTableRowCellBooleanFormatterComponent,
  date: MfTableRowCellDateFormatterComponent,
  dateEditor: MfTableRowCellDateEditorComponent,
  enum: MfTableRowCellEnumFormatterComponent,
  htmlDialog: MfTableRowCellHtmlDialogFormatterComponent,
  html: MfTableRowCellHtmlFormatterComponent,
  number: MfTableRowCellNumberFormatterComponent,
  numberEditor: MfTableRowCellNumberEditorComponent,
  percentage: MfTableRowCellPercentageFormatterComponent,
  statusChip: MfTableRowCellStatusChipFormatterComponent,
  string: MfTableRowCellStringFormatterComponent,
  userPersons: MfTableRowCellUserFormatterComponent,
  currency: MfTableRowCellCurrencyFormatterComponent,
};