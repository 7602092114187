import { Component, Inject, Injector } from "@angular/core";
import { ECOM_ICONS_CONFIG_TOKEN, ECOM_LINKS_CONFIG_TOKEN, EComIconsConfig, EComLinksConfig } from "@e-commerce/ecom.config";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfDateCurrent } from "@material-framework/common/utils/date.utils";

const TYPE_INFO: MfTypeInfo = { className: "EComFooterComponent" };

@Component({
  selector: "ecom-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class EComFooterComponent extends MfBaseComponent {
  protected _mfDateCurrent = mfDateCurrent;

  public constructor(
    protected override _injector: Injector,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
    @Inject(ECOM_LINKS_CONFIG_TOKEN)
    protected _linksConfig: EComLinksConfig,
  ) {
    super(TYPE_INFO, _injector);
  }
}