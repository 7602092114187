import { APP_BASE_HREF } from "@angular/common";
import { Inject, Injectable, InjectionToken, Injector, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { MfAuthState } from "@material-framework/auth/auth";
import { MF_AUTH_CONFIG_TOKEN, MfAuthConfig } from "@material-framework/auth/auth.config";
import { MfAuthRouteNames } from "@material-framework/auth/auth.route.names";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfError } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfStringIsEmptyOrWhitespace } from "@material-framework/common/utils/string.utils";
import { mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { AuthConfig, OAuthService, OAuthStorage, ValidationHandler } from "angular-oauth2-oidc";
import { BehaviorSubject, combineLatest, from, Observable, Subject } from "rxjs";
import { concatMap, filter, map, share } from "rxjs/operators";

const TYPE_INFO: MfTypeInfo = { className: "MfAuthService" };

export type MFAuthUrls = string[];

export const MF_AUTHENTICATED_URLS_TOKEN = new InjectionToken<MFAuthUrls>("MfAuthenticatedUrls");
export const MF_AUTHENTICATED_URLS_EXCLUDE_TOKEN = new InjectionToken<MFAuthUrls>("MfAuthenticatedExcludeUrls");

@Injectable({ providedIn: "root", })
export class MfAuthService extends MfBaseService {
  public canActivateProtectedRoutes: Observable<boolean>;
  public onCodeError: Subject<void> = new Subject();
  public onDiscoveryDocumentLoaded: Subject<void> = new Subject();
  public onDiscoveryDocumentLoadError: Subject<void> = new Subject();
  public onDiscoveryDocumentValidationError: Subject<void> = new Subject();
  public onHasValidAccessToken: Observable<boolean>;
  public onInvalidNonceInState: Subject<void> = new Subject();
  public onJwksLoadError: Subject<void> = new Subject();
  public onLoggedOut: Subject<void> = new Subject();
  public onPopupBlocked: Subject<void> = new Subject();
  public onPopupClosed: Subject<void> = new Subject();
  public onSessionChanged: Subject<void> = new Subject();
  public onSessionError: Subject<void> = new Subject();
  public onSessionTerminated: Subject<void> = new Subject();
  public onSessionUnchanged: Subject<void> = new Subject();
  public onSilentlyRefreshed: Subject<void> = new Subject();
  public onSilentRefreshError: Subject<void> = new Subject();
  public onSilentRefreshTimeout: Subject<void> = new Subject();
  public onTokenError: Subject<void> = new Subject();
  public onTokenExpires: Subject<void> = new Subject();
  public onTokenReceived: Subject<void> = new Subject();
  public onTokenRefreshed: Subject<void> = new Subject();
  public onTokenRefreshError: Subject<void> = new Subject();
  public onTokenRevokeError: Subject<void> = new Subject();
  public onTokenValidationError: Subject<void> = new Subject();
  public onUserProfileLoaded: Subject<void> = new Subject();
  public onUserProfileLoadError: Subject<void> = new Subject();

  protected _state?: MfAuthState;
  protected _isAuthenticated: Observable<boolean>;
  protected _isDoneLoading: Observable<boolean>;
  protected _isDoneLoadingSubject = new BehaviorSubject<boolean>(false);
  protected _isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  protected _runInitialLoginSequence$?: Observable<boolean>;

  public constructor(
    protected override _injector: Injector,
    @Inject(APP_BASE_HREF)
    protected _baseHREF: string,
    protected _oauthService: OAuthService,
    protected _router: Router,
    protected _storageService: OAuthStorage,
    @Optional()
    @Inject(MF_AUTHENTICATED_URLS_TOKEN)
    protected _authenticatedUrls: string[],
    @Optional()
    @Inject(MF_AUTHENTICATED_URLS_EXCLUDE_TOKEN)
    protected _authenticatedExcludeUrls: string[],
    @Inject(MF_AUTH_CONFIG_TOKEN)
    protected _authConfig: MfAuthConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._isAuthenticated = this._isAuthenticatedSubject.asObservable();
    this._isDoneLoading = this._isDoneLoadingSubject.asObservable();

    this.onHasValidAccessToken = combineLatest([
      this._isAuthenticated,
      this._isDoneLoading
    ]).pipe(
      map(values => values.every(b => b)),
      filter((value) => value === true)
    );

    this.canActivateProtectedRoutes = combineLatest([
      this._isAuthenticated,
      this._isDoneLoading
    ]).pipe(
      map(values => values.every(b => b)),
    );

    window.addEventListener("storage", (event) => {
      if (event.key !== "access_token" && event.key !== null) {
        return;
      }

      console.warn("Noticed changes to access_token (most likely from another tab), updating isAuthenticated");
      this._isAuthenticatedSubject.next(this._oauthService.hasValidAccessToken());

      if (!this._oauthService.hasValidAccessToken()) {
        this._initFlow();
      }
    });

    this._addEventsHandlers();

    if (this._authConfig.useAutomaticSilentRefresh === true) {
      this._oauthService.setupAutomaticSilentRefresh();
    }

    this._isAuthenticatedSubject.next(this._oauthService.hasValidAccessToken());
    this._isDoneLoadingSubject.next(true);
  }

  public getRedirectUri(): string {
    return `${window.location.origin}${this._baseHREF}${MfAuthRouteNames.partNameRedirect}`;
  }

  public get tokenValidationHandler(): ValidationHandler {
    return this._oauthService.tokenValidationHandler;
  }
  public set tokenValidationHandler(value: ValidationHandler) {
    this._oauthService.tokenValidationHandler = value;
  }

  public getIdToken(): string {
    return this._oauthService.getIdToken();
  }

  public getAccessToken(): string {
    return this._oauthService.getAccessToken();
  }

  public getIdentityClaims(): Record<string, unknown> {
    return this._oauthService.getIdentityClaims();
  }

  public isUrlAuthenticated(url: string): boolean {
    return (mfTypeIsNullOrUndefined(this._authenticatedExcludeUrls) || !this._authenticatedExcludeUrls.some(i => url.toLocaleLowerCase() === i.toLocaleLowerCase())) && (!mfTypeIsNullOrUndefined(this._authenticatedUrls) && this._authenticatedUrls.some(i => url.toLocaleLowerCase().startsWith(i.toLocaleLowerCase())));
  }

  public clearCredentials(): void {
    this._storageService.removeItem("access_token");
    this._storageService.removeItem("id_token");
  }

  public runInitialLoginSequence(redirectUrl?: string): Observable<boolean> {
    if (mfTypeIsUndefined(this._runInitialLoginSequence$)) {
      this._runInitialLoginSequence$ = from(this._oauthService.loadDiscoveryDocument(this._authConfig.issuer + ".well-known/openid-configuration")).pipe(
        concatMap(() => {
          return from(this._oauthService.tryLogin()).pipe(
            map(() => {
              const hasValidAccessToken = this._oauthService.hasValidAccessToken();
              if (!hasValidAccessToken) {
                this._initFlow(redirectUrl);
                delete this._runInitialLoginSequence$;
                return false;
              } else {
                this._isDoneLoadingSubject.next(true);
                this._isAuthenticatedSubject.next(this._oauthService.hasValidAccessToken());
                this._redirectToRedirectURL();
                delete this._runInitialLoginSequence$;
                return true;
              }
            }),
          );
        }),
        share(),
      );
    }

    return this._runInitialLoginSequence$;
  }

  public configure(config: AuthConfig) {
    this._oauthService.configure(config);
  }

  public logout() {
    this._oauthService.logOut();
  }

  public refresh() {
    this._oauthService.silentRefresh();
  }

  public hasValidAccessToken() {
    return this._oauthService.hasValidAccessToken();
  }

  protected _addEventsHandlers(): void {
    this._sub(this._oauthService.events, {
      next: (event) => {
        switch (event.type) {
          case "code_error":
            this.onCodeError.next();
            break;
          case "discovery_document_load_error":
            this.onDiscoveryDocumentLoadError.next();
            break;
          case "discovery_document_loaded":
            this.onDiscoveryDocumentLoaded.next();
            break;
          case "discovery_document_validation_error":
            this.onDiscoveryDocumentValidationError.next();
            break;
          case "invalid_nonce_in_state":
            this.onInvalidNonceInState.next();
            break;
          case "jwks_load_error":
            this.onJwksLoadError.next();
            break;
          case "logout":
            this.onLoggedOut.next();
            break;
          case "popup_blocked":
            this.onPopupBlocked.next();
            break;
          case "popup_closed":
            this.onPopupClosed.next();
            break;
          case "session_changed":
            this.onSessionChanged.next();
            break;
          case "session_error":
            this.onSessionError.next();
            break;
          case "session_terminated":
            this.onSessionTerminated.next();
            break;
          case "session_unchanged":
            this.onSessionUnchanged.next();
            break;
          case "silent_refresh_error":
            this.onSilentRefreshError.next();
            break;
          case "silent_refresh_timeout":
            this.onSilentRefreshTimeout.next();
            break;
          case "silently_refreshed":
            this.onSilentlyRefreshed.next();
            break;
          case "token_error":
            this.onTokenError.next();
            break;
          case "token_expires":
            this.onTokenExpires.next();
            this.clearCredentials();
            this._sub(this.runInitialLoginSequence());
            break;
          case "token_received":
            this.onTokenReceived.next();
            this._isAuthenticatedSubject.next(this._oauthService.hasValidAccessToken());
            break;
          case "token_refresh_error":
            this.onTokenRefreshError.next();
            break;
          case "token_refreshed":
            this.onTokenRefreshed.next();
            break;
          case "token_revoke_error":
            this.onTokenRevokeError.next();
            break;
          case "token_validation_error":
            this.onTokenValidationError.next();
            break;
          case "user_profile_load_error":
            this.onUserProfileLoadError.next();
            break;
          case "user_profile_loaded":
            this.onUserProfileLoaded.next();
            break;
        }
      }
    });
  }

  protected _initFlow(redirectUrl?: string): void {
    switch (this._authConfig.flow) {
      case "code":
        this._oauthService.initCodeFlow(JSON.stringify(this._getState(redirectUrl)));
        break;
      case "implicit":
        this._oauthService.initImplicitFlow(JSON.stringify(this._getState(redirectUrl)));
        break;
      case "login":
        this._oauthService.initLoginFlow(JSON.stringify(this._getState(redirectUrl)));
        break;
      default:
        throw new MfError(this._typeInfo, "initFlow", "No valid flow set in MfAuthConfig");
    }
  }

  protected _redirectToRedirectURL(): void {
    if (!mfTypeIsUndefined(this._oauthService.state) && !mfStringIsEmptyOrWhitespace(this._oauthService.state)) {
      const state = JSON.parse(decodeURIComponent(this._oauthService.state)) as MfAuthState;
      if (!mfTypeIsUndefined(state) && !mfTypeIsUndefined(state.redirectURL)) {
        this._router.navigate([state.redirectURL!]);
      }
    }
  }

  protected _getState(redirectUrl?: string): MfAuthState {
    if (mfTypeIsUndefined(this._state)) {
      this._state = {};
    }
    if (mfTypeIsUndefined(this._state.redirectURL)) {
      this._state.redirectURL = redirectUrl || this._router.url;
    }
    return this._state;
  }
}