import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, provideRouter, withEnabledBlockingInitialNavigation } from "@angular/router";
import { EComCartInvoiceComponent } from "@e-commerce/cart/invoice/cart.invoice.component";
import { EComCartOrderComponent } from "@e-commerce/cart/order/cart.order.component";
import { EComInvoiceBillingPaymentComponent } from "@e-commerce/checkout/invoice/billingPayment/invoice.billing.payment.component";
import { EComCheckoutInvoiceComponent } from "@e-commerce/checkout/invoice/checkout.invoice.component";
import { EComInvoiceOrderReviewComponent } from "@e-commerce/checkout/invoice/orderReview/invoice.order.review.component";
import { EComOrderBillingPaymentComponent } from "@e-commerce/checkout/order/billingPayment/order.billing.payment.component";
import { EComCheckoutOrderComponent } from "@e-commerce/checkout/order/checkout.order.component";
import { EComOrderReviewComponent } from "@e-commerce/checkout/order/orderReview/order.review.component";
import { EComOrderShippingMethodComponent } from "@e-commerce/checkout/order/shippingMethod/order.shipping.method.component";

import { ECOM_BREADCRUMB_KEY_CART, ECOM_BREADCRUMB_KEY_CHECKOUT, ECOM_BREADCRUMB_KEY_CONFIRMATION, ECOM_BREADCRUMB_KEY_HOME, ECOM_BREADCRUMB_KEY_INVOICES, ECOM_BREADCRUMB_KEY_INVOICE_DETAILS, ECOM_BREADCRUMB_KEY_ORDERS, ECOM_BREADCRUMB_KEY_PRODUCT_DETAILS, ECOM_BREADCRUMB_KEY_SHOP } from "@e-commerce/ecom.route.keys";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { EComHomeComponent } from "@e-commerce/home/home.component";
import { EComInvoiceDetailComponent } from "@e-commerce/invoiceDetail/invoice.detail.component";
import { EComInvoiceDetailOrdersComponent } from "@e-commerce/invoiceDetailOrders/invoice.detail.orders.component";
import { EComInvoicesComponent } from "@e-commerce/invoices/invoices.component";
import { EComConfirmationComponent } from "@e-commerce/landing/confirmation/confirmation.component";
import { EComNotApprovedComponent } from "@e-commerce/landing/notApproved/not.approved.component";
import { EComPaypalCancelComponent } from "@e-commerce/landing/paypal/cancel/paypal.cancel.component";
import { EComPaypalErrorComponent } from "@e-commerce/landing/paypal/error/paypal.error.component";
import { EComPaypalReturnComponent } from "@e-commerce/landing/paypal/return/paypal.return.component";
import { EComOrdersComponent } from "@e-commerce/orders/orders.component";
import { EComProductDetailComponent } from "@e-commerce/productDetail/product.detail.component";
import { EComProductsComponent } from "@e-commerce/products/products.component";
import { EComRootAuthnComponent } from "@e-commerce/root/authn/root.authn.component";
import { EComRootUnAuthnComponent } from "@e-commerce/root/unauthn/root.unauthn.component";
import { MfAuthGuardService } from "@material-framework/auth/auth.guard.service";
import { MF_UNAUTHN_ROUTES } from "@material-framework/auth/auth.routing.module";
import { MfFillRouterOutletComponent } from "@material-framework/fillRouterOutlet/fill.router.outlet.component";
import { MfRoutes } from "@material-framework/router/route";



const ROUTING_CONFIGURATION: ExtraOptions = {
  paramsInheritanceStrategy: "always"
};

const ECOM_ROUTES: MfRoutes = [
  {
    path: "",
    redirectTo: EcomRouteNames.routeHome,
    pathMatch: "full",
  },
  {
    component: EComRootUnAuthnComponent,
    path: "",
    children: [
      {
        path: EcomRouteNames.partNameNotApproved,
        component: EComNotApprovedComponent,
      },
      ...MF_UNAUTHN_ROUTES,
    ],
  },
  {
    component: EComRootAuthnComponent,
    path: "",
    children: [
      {
        path: EcomRouteNames.partNameHome,
        component: EComHomeComponent,
        canActivate: [MfAuthGuardService],
        breadcrumb: {
          alias: ECOM_BREADCRUMB_KEY_HOME,
          label: "Home",
        },
      },
      {
        path: EcomRouteNames.partNamePaypalReturn,
        component: EComPaypalReturnComponent,
      },
      {
        path: EcomRouteNames.partNamePaypalCancel,
        component: EComPaypalCancelComponent,
      },
      {
        path: EcomRouteNames.partNamePaypalError,
        component: EComPaypalErrorComponent,
      },
      {
        path: "",
        component: MfFillRouterOutletComponent,
        canActivate: [MfAuthGuardService],
        breadcrumb: {
          alias: ECOM_BREADCRUMB_KEY_HOME,
          label: "Home",
          routeInterceptor: () => EcomRouteNames.routeHome,
        },
        children: [
          {
            path: "",
            redirectTo: EcomRouteNames.partNameOrders,
            pathMatch: "full",
          },
          {
            path: EcomRouteNames.partNameOrders,
            component: MfFillRouterOutletComponent,
            canActivate: [MfAuthGuardService],
            breadcrumb: {
              alias: ECOM_BREADCRUMB_KEY_ORDERS,
              label: "Orders",
            },
            children: [
              {
                path: "",
                redirectTo: EcomRouteNames.partNameList,
                pathMatch: "full",
              },
              {
                path: EcomRouteNames.partNameList,
                component: EComOrdersComponent,
                canActivate: [MfAuthGuardService],
              },
            ]
          },
          {
            path: EcomRouteNames.partNameInvoices,
            component: MfFillRouterOutletComponent,
            canActivate: [MfAuthGuardService],
            breadcrumb: {
              alias: ECOM_BREADCRUMB_KEY_INVOICES,
              label: "Invoices",
              routeInterceptor: () => EcomRouteNames.routeInvoices,
            },
            children: [
              {
                path: "",
                redirectTo: EcomRouteNames.partNameList,
                pathMatch: "full",
              },
              {
                path: EcomRouteNames.partNameList,
                component: EComInvoicesComponent,
                canActivate: [MfAuthGuardService],
              },
              {
                path: EcomRouteNames.partNameInvoiceDetailItemKey,
                component: EComInvoiceDetailComponent,
                canActivate: [MfAuthGuardService],
                breadcrumb: {
                  alias: ECOM_BREADCRUMB_KEY_INVOICE_DETAILS,
                  label: "Detail",
                },
              },
              {
                path: EcomRouteNames.partNameInvoiceDetailItemKey,
                component: EComInvoiceDetailOrdersComponent,
                canActivate: [MfAuthGuardService],
                breadcrumb: {
                  alias: ECOM_BREADCRUMB_KEY_INVOICE_DETAILS,
                  label: "Detail",
                  routeInterceptor: (routeLink: string) => {
                    return "invoices/" + routeLink;
                  },
                },
                children: [
                  {
                    path: EcomRouteNames.partNameOrders,
                    component: MfFillRouterOutletComponent,
                    canActivate: [MfAuthGuardService],
                    breadcrumb: {
                      alias: ECOM_BREADCRUMB_KEY_ORDERS,
                      label: "Orders",
                    },
                    children: [
                      {
                        path: "",
                        redirectTo: EcomRouteNames.partNameList,
                        pathMatch: "full",
                      },
                      {
                        path: EcomRouteNames.partNameList,
                        component: EComOrdersComponent,
                        canActivate: [MfAuthGuardService],
                      },
                    ]
                  },
                ]
              },
              {
                path: EcomRouteNames.partNameCart,
                component: MfFillRouterOutletComponent,
                canActivate: [MfAuthGuardService],
                breadcrumb: {
                  alias: ECOM_BREADCRUMB_KEY_CART,
                  label: "Cart",
                  disable: true,
                },
                children: [
                  {
                    path: "",
                    redirectTo: EcomRouteNames.routeInvoiceCartList,
                    pathMatch: "full",
                  },
                  {
                    path: EcomRouteNames.partNameList,
                    component: EComCartInvoiceComponent,
                    canActivate: [MfAuthGuardService],
                  },
                  {
                    path: EcomRouteNames.partNameCheckout,
                    component: EComCheckoutInvoiceComponent,
                    canActivate: [MfAuthGuardService],
                    breadcrumb: {
                      alias: ECOM_BREADCRUMB_KEY_CHECKOUT,
                      label: "Checkout",
                    },
                    children: [
                      {
                        path: "",
                        redirectTo: EcomRouteNames.partNameBillingPayment,
                        pathMatch: "full",
                      },
                      {
                        path: EcomRouteNames.partNameBillingPayment,
                        component: EComInvoiceBillingPaymentComponent,
                        canActivate: [MfAuthGuardService],
                      },
                      {
                        path: EcomRouteNames.partNameOrderReview,
                        component: EComInvoiceOrderReviewComponent,
                        canActivate: [MfAuthGuardService],
                      }
                    ]
                  },
                ]
              },
            ]
          },
        ]
      },
      {
        path: "",
        component: MfFillRouterOutletComponent,
        canActivate: [MfAuthGuardService],
        breadcrumb: {
          alias: ECOM_BREADCRUMB_KEY_HOME,
          label: "Home",
          routeInterceptor: () => EcomRouteNames.routeHome
        },
        children: [
          {
            path: EcomRouteNames.partNameShop,
            component: MfFillRouterOutletComponent,
            canActivate: [MfAuthGuardService],
            breadcrumb: {
              alias: ECOM_BREADCRUMB_KEY_SHOP,
              label: "Shop",
              routeInterceptor: () => EcomRouteNames.routeProductsList
            },
            children: [
              {
                path: "",
                redirectTo: EcomRouteNames.routeShopCart,
                pathMatch: "full",
              },
              {
                path: EcomRouteNames.partNameConfirmation,
                component: EComConfirmationComponent,
                canActivate: [MfAuthGuardService],
                breadcrumb: {
                  alias: ECOM_BREADCRUMB_KEY_CONFIRMATION,
                  label: "Confirmation",
                },
              },
              {
                path: EcomRouteNames.partNameCart,
                component: MfFillRouterOutletComponent,
                canActivate: [MfAuthGuardService],
                breadcrumb: {
                  alias: ECOM_BREADCRUMB_KEY_CART,
                  label: "Cart",
                  routeInterceptor: () => EcomRouteNames.routeShopCartList
                },
                children: [
                  {
                    path: "",
                    redirectTo: EcomRouteNames.routeShopCartList,
                    pathMatch: "full",
                  },
                  {
                    path: EcomRouteNames.partNameList,
                    component: EComCartOrderComponent,
                    canActivate: [MfAuthGuardService],
                  },
                  {
                    path: EcomRouteNames.partNameCheckout,
                    component: EComCheckoutOrderComponent,
                    canActivate: [MfAuthGuardService],
                    breadcrumb: {
                      alias: ECOM_BREADCRUMB_KEY_CHECKOUT,
                      label: "Checkout",
                    },
                    children: [
                      {
                        path: "",
                        redirectTo: EcomRouteNames.partNameShippingMethod,
                        pathMatch: "full",
                      },
                      {
                        path: EcomRouteNames.partNameShippingMethod,
                        component: EComOrderShippingMethodComponent,
                        canActivate: [MfAuthGuardService],
                      },
                      {
                        path: EcomRouteNames.partNameBillingPayment,
                        component: EComOrderBillingPaymentComponent,
                        canActivate: [MfAuthGuardService],
                      },
                      {
                        path: EcomRouteNames.partNameOrderReview,
                        component: EComOrderReviewComponent,
                        canActivate: [MfAuthGuardService],
                      }
                    ]
                  },
                ]
              },
              {
                path: EcomRouteNames.partNameProducts,
                component: MfFillRouterOutletComponent,
                canActivate: [MfAuthGuardService],
                children: [
                  {
                    path: "",
                    redirectTo: EcomRouteNames.partNameList,
                    pathMatch: "full",
                  },
                  {
                    path: EcomRouteNames.partNameList,
                    component: EComProductsComponent,
                    canActivate: [MfAuthGuardService],
                  },
                  {
                    path: EcomRouteNames.partNameParameterProductDetailItemSpecKey,
                    component: EComProductDetailComponent,
                    canActivate: [MfAuthGuardService],
                    breadcrumb: {
                      alias: ECOM_BREADCRUMB_KEY_PRODUCT_DETAILS,
                      label: "Product Detail",
                    },
                  },
                  {
                    path: EcomRouteNames.partNameParameterProductDetailItemKey,
                    component: EComProductDetailComponent,
                    canActivate: [MfAuthGuardService],
                    breadcrumb: {
                      alias: ECOM_BREADCRUMB_KEY_PRODUCT_DETAILS,
                      label: "Product Detail",
                    },
                  },
                ]
              },
            ]
          },
        ],
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ECOM_ROUTES, ROUTING_CONFIGURATION)],
  exports: [RouterModule],
  providers: [
    provideRouter(ECOM_ROUTES, withEnabledBlockingInitialNavigation())
  ]
})
export class EComRoutingModule { }
