import { InjectionToken } from "@angular/core";
import { MfIcon } from "@material-framework/icon/icon";
import { MfIconTypes } from "@material-framework/icon/icon.types";

export const MF_HEADER_CONFIG_TOKEN = new InjectionToken<MfHeaderConfig>("MfHeaderConfig");
export const MF_HEADER_DEFAULT_CONFIG: MfHeaderConfig = {
  openIcon: { type: MfIconTypes.materialIcons, icon: "menu_open" },
  closedIcon: { type: MfIconTypes.materialIcons, icon: "menu" },
};

export type MfHeaderConfig = {
  openIcon: MfIcon;
  closedIcon: MfIcon;
}