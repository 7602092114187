import { CurrencyPipe } from "@angular/common";
import { Inject, InjectionToken, Pipe, PipeTransform } from "@angular/core";

export const MF_CURRENCY_PIPE_CONFIG_TOKEN = new InjectionToken<MfCurrencyPipeConfig>("MfCurrencyPipeConfig");
export const MF_CURRENCY_PIPE_DEFAULT_CONFIG: MfCurrencyPipeConfig = {
  digitsInfo: "1.2-2",
};

export type MfCurrencyPipeConfig = {
  digitsInfo: string;
  currencyCode?: string;
  display?: "code" | "symbol" | "symbol-narrow" | string | boolean;
  locale?: string,
  currencyCodeAtEnd?: boolean;
}

@Pipe({ name: "mfCurrency" })
export class MfCurrencyPipe implements PipeTransform {
  public constructor(
    protected _currencyPipe: CurrencyPipe,
    @Inject(MF_CURRENCY_PIPE_CONFIG_TOKEN)
    protected _config: MfCurrencyPipeConfig,
  ) { }

  public transform(value: string | number | null | undefined) {
    if (this._config.currencyCodeAtEnd === true) {
      return `${this._currencyPipe.transform(value, "", "", this._config.digitsInfo, this._config.locale)} ${this._config.currencyCode}`;
    } else {

      return this._currencyPipe.transform(value, this._config.currencyCode, this._config.display, this._config.digitsInfo, this._config.locale);
    }
  }
}