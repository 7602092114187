import { Component, Inject, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPathValue } from "@material-framework/common/utils/object.utils";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfTableRowCellBaseFormatterComponent } from "@material-framework/table/row/cell/value/table.row.cell.base.formatter.component";
import { MfTableRowCellFormatterBaseOptions } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowCellEnumFormatterComponent" };

export type MfTableRowCellEnumFormatterOptions = MfTableRowCellFormatterBaseOptions & {

}

@Component({
  selector: "mf-table-row-cell-enum-formatter-component",
  templateUrl: "table.row.cell.base.formatter.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowCellEnumFormatterComponent<TModel extends MfModelBase> extends MfTableRowCellBaseFormatterComponent<MfTableRowCellEnumFormatterOptions, TModel> implements OnInit {
  public constructor(
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    const value = mfObjectGetPropertyPathValue<string | number>(this.fieldColumn?.fieldPath, this.rowItem);
    if (!mfTypeIsUndefined(value)) {
      const enumMappingConfig = this.fieldColumn?.modelFieldConfig?.dataType.enum?.mappings.find(i => i.value === value);
      if (!mfTypeIsUndefined(enumMappingConfig)) {
        this._displayValue = enumMappingConfig.displayValue;
      }
    } else {
      this._displayValue = "";
    }
  }
}