import { NgModule } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { EComAccountSelectComponent } from "@e-commerce/accountSelect/account.select.component";
import { MfSelectModule } from "@material-framework/select/select.module";


@NgModule({
  declarations: [
    EComAccountSelectComponent,
  ],
  exports: [
    EComAccountSelectComponent,
  ],
  imports: [
    BrowserModule,
    MfSelectModule,
    MatFormFieldModule,
    MatTooltipModule,
  ],
  providers: [
  ]
})
export class EComAccountSelectModule { }