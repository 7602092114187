import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { booleanAttribute, Component, EventEmitter, Inject, Injector, Input, Output, ViewChild } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFilterExpression } from "@material-framework/filter/filter";
import { MfFilterOperatorTypes } from "@material-framework/filter/filter.types";
import { MfFilterService } from "@material-framework/filter/services/filter.service";
import { MfFilterValueInputComponent } from "@material-framework/filter/valueInput/filter.value.input.component";
import { MFModelConfigFieldPath, MfModelConfigMapped, MfModelFieldConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfPortalsTableComponent } from "@material-framework/portals/table/portals.table.utils";
import { MF_TABLE_SIMPLE_SEARCH_CONFIG_TOKEN, MfTableSimpleSearchConfig } from "@material-framework/table/simpleSearch/table.simple.search.config";
import { MfTableFilterExpressionEvent, MfTableFilterGroupEvent } from "@material-framework/table/table";
import { MfTableModelBase } from "@material-framework/table/table.component";

const TYPE_INFO: MfTypeInfo = { className: "MfTableSimpleSearchComponent" };

@Component({
  selector: "mf-table-simple-search",
  templateUrl: "./table.simple.search.component.html",
  styleUrls: ["./table.simple.search.component.scss"]
})
export class MfTableSimpleSearchComponent<TModel extends MfTableModelBase, TFilter, TTrackBy = unknown> extends MfBaseComponent {
  @Input()
  public get table(): MfPortalsTableComponent<TModel, TFilter, TTrackBy> | undefined {
    return this._table;
  }
  public set table(value: MfPortalsTableComponent<TModel, TFilter, TTrackBy> | undefined) {
    this._table = value;
    this._initTable();
  }

  @Input()
  public get operator(): MfFilterOperatorTypes | undefined {
    return this._operator;
  }
  public set operator(value: MfFilterOperatorTypes | undefined) {
    this._operator = value;
    this._init();
  }

  @Input()
  public get modelConfig(): MfModelConfigMapped | undefined {
    return this._modelConfig;
  }
  public set modelConfig(value: MfModelConfigMapped | undefined) {
    this._modelConfig = value;
    this._init();
  }

  @Input()
  public get fieldPath(): MFModelConfigFieldPath | undefined {
    return this._fieldPath;
  }
  public set fieldPath(value: MFModelConfigFieldPath | undefined) {
    this._fieldPath = value;
    this._init();
  }

  @Input({ transform: booleanAttribute })
  public get isLoading(): boolean {
    return this._isLoading;
  }
  public set isLoading(value: BooleanInput) {
    this._isLoading = coerceBooleanProperty(value);
  }

  @Output()
  public onSearch: EventEmitter<boolean> = new EventEmitter();

  @ViewChild("filterValueInput", { static: true })
  protected _filterValueInput?: MfFilterValueInputComponent;

  protected _isLoading: boolean = false;
  protected _expression?: MfFilterExpression;
  protected _modelConfig?: MfModelConfigMapped;
  protected _fieldPath?: MFModelConfigFieldPath;
  protected _operator?: MfFilterOperatorTypes | undefined;
  protected _modelFieldConfig?: MfModelFieldConfigMapped;
  protected _table?: MfPortalsTableComponent<TModel, TFilter, TTrackBy>;

  public constructor(
    protected override _injector: Injector,
    protected _filterService: MfFilterService,
    protected _modelConfigService: MfModelConfigService,
    @Inject(MF_TABLE_SIMPLE_SEARCH_CONFIG_TOKEN)
    protected _config: MfTableSimpleSearchConfig,

  ) {
    super(TYPE_INFO, _injector);
  }

  protected _onSearch(): void {
    if (!mfTypeIsUndefined(this.table) && !mfTypeIsUndefined(this.fieldPath) && !mfTypeIsUndefined(this._expression)) {
      this.table.setFieldFilter(this.fieldPath, this._expression.value, this._expression.operatorKey);
      this.onSearch.emit();
    }
  }

  protected _init(): void {
    if (!mfTypeIsUndefined(this.modelConfig) && !mfTypeIsUndefined(this.fieldPath) && !mfTypeIsUndefined(this.operator)) {
      this._modelFieldConfig = this._modelConfigService.findModelFieldConfigByFieldPath(this.modelConfig.fields, this.fieldPath);
      if (!mfTypeIsUndefined(this._modelFieldConfig)) {
        this._expression = this._filterService.getNewFilterExpression(this.fieldPath, this.fieldPath, this.operator, "", this._modelFieldConfig);
      }
    }
  }

  protected _initTable(): void {
    if (!mfTypeIsUndefined(this._table)) {
      this._sub(this._table.onFilterRemoveExpression, { next: (event) => this._onFilterRemoved(event) });
      this._sub(this._table.onFilterChanged, { next: (event) => this._onFilterChanged(event) });
      this._sub(this._table.onFilterLoaded, { next: () => this._loadInitialSearchValue() });
    }
  }

  protected _onFilterRemoved(event: MfTableFilterExpressionEvent): void {
    if (!mfTypeIsUndefined(this._table) && !mfTypeIsUndefined(this._expression)) {
      this._expression.value = "";
      this._filterValueInput?.update();
    }
  }

  protected _onFilterChanged(event: MfTableFilterGroupEvent): void {
    if (!mfTypeIsUndefined(this.table) && !mfTypeIsUndefined(this._expression)) {
      const expression = event.group.expressions[0];
      if (!mfTypeIsUndefined(expression) && expression.fieldPath === this.fieldPath) {
        const value = event.group.expressions[0].value as string;
        if (!mfTypeIsUndefined(value)) {
          this._expression.value = value;
          this._filterValueInput?.update();
        }
      }
    }
  }

  protected _loadInitialSearchValue(): void {
    if (!mfTypeIsUndefined(this.table) && !mfTypeIsUndefined(this._expression) && !mfTypeIsUndefined(this.fieldPath)) {
      const fieldColumn = this.table.findFieldColumnByFieldPath(this.fieldPath);
      if (!mfTypeIsUndefined(fieldColumn) && !mfTypeIsUndefined(fieldColumn.filter) && !mfTypeIsUndefined(fieldColumn.modelFieldConfig)) {
        const expression = fieldColumn.filter.group.expressions[0];
        if (!mfTypeIsUndefined(expression)) {
          this._expression.value = expression.value;
          this._filterValueInput?.update();
        }
      }
    }
  }
}