<div class="mf-landing-container"
     style="color: white;"
     [style.backgroundImage]="'url(assets/eComm-Splash-1920x1080.jpg)'">
  <div *ngIf="_config.login.showSlogan"
       class="mf-column mf-slogan">
    <div class="mf-header">
      Incora Portal
    </div>
    <div class="mf-text">
      We simplify the complex.
    </div>
  </div>
  <div class="mf-column mf-message">
    Thank you for registering your account is awaiting approval
  </div>
</div>