import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { EComEntryPointComponent } from "@e-commerce/entryPoint/entry.point.component";

@NgModule({
  declarations: [
    EComEntryPointComponent
  ],
  exports: [
    EComEntryPointComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
  ],
})
export class EComEntryPointModule { }