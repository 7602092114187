import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { booleanAttribute, ContentChild, Directive, Inject, Input } from "@angular/core";
import { MfTableCellDef, MfTableCell } from "@material-framework/table/row/cell/table.cell.def.directive";
import { MfTableHeaderCellDef, MfTableHeaderCell } from "@material-framework/table/row/header/table.row.header.cell.def.directive";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";


@Directive({
  selector: "[mfTableColumnDef]",
})
export class MfTableColumnDef {

  @ContentChild(MfTableCellDef)
  public cellDef!: MfTableCellDef;

  @ContentChild(MfTableHeaderCellDef)
  public headerCellDef!: MfTableHeaderCellDef;

  @ContentChild(MfTableCell)
  public cell!: MfTableCell;

  @ContentChild(MfTableHeaderCell)
  public headerCell!: MfTableHeaderCell;

  @Input("mfTableColumnDef")
  public name?: string;

  @Input()
  public width?: string;

  @Input()
  public side?: "left" | "right";

  @Input({ transform: booleanAttribute })
  public get stickyEnd(): boolean {
    return this._stickyEnd;
  }
  public set stickyEnd(value: BooleanInput) {
    this._stickyEnd = coerceBooleanProperty(value);
  }

  @Input({ transform: booleanAttribute })
  public get sticky(): boolean {
    return this._sticky;
  }
  public set sticky(value: BooleanInput) {
    this._sticky = coerceBooleanProperty(value);
  }

  protected _stickyEnd: boolean = false;
  protected _sticky: boolean = false;
  protected _name: string = "";
  protected _width: string = "";

  public constructor(@Inject(MF_TABLE_CONFIG_TOKEN) public config: MfTableConfig) {
    this._width = `${config.row.header.resize.minWidth}px`;
  }
}