import { Component, EventEmitter, Inject, Injector, Input, Output, ViewEncapsulation } from "@angular/core";
import { MF_ANIMATION_FADE_IN, MF_ANIMATION_FADE_OUT } from "@material-framework/animations/animations";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfUserPersonaCardAction, MfUserPersonaCardActionTypes } from "@material-framework/userPersona/user.persona";
import { MF_USER_PERSONA_CONFIG_TOKEN, MfUserPersonaConfig } from "@material-framework/userPersona/user.persona.config";

const TYPE_INFO: MfTypeInfo = { className: "MfUserPersonaCardComponent" };

@Component({
  selector: "mf-user-persona-card",
  templateUrl: "user.persona.card.component.html",
  styleUrls: ["user.persona.card.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    MF_ANIMATION_FADE_IN,
    MF_ANIMATION_FADE_OUT,
  ]
})
export class MfUserPersonaCardComponent extends MfBaseComponent {
  @Input()
  public actions?: MfUserPersonaCardAction[];

  @Output()
  public onPersonaClicked: EventEmitter<void> = new EventEmitter();

  @Output()
  public onMouseLeave: EventEmitter<void> = new EventEmitter();

  @Output()
  public onActionClicked: EventEmitter<MfUserPersonaCardAction> = new EventEmitter();

  protected _actionTypes = MfUserPersonaCardActionTypes;

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_USER_PERSONA_CONFIG_TOKEN)
    protected _config: MfUserPersonaConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected _onPersonaClick(): void {
    this.onPersonaClicked.emit();
  }

  protected _mouseOver(event: MouseEvent): void {
    event.stopPropagation();
  }

  protected _mouseLeave(event: MouseEvent): void {
    event.stopPropagation();
    this.onMouseLeave.emit();
  }

  protected _actionClicked(action: MfUserPersonaCardAction): void {
    this.onActionClicked.emit(action);
  }
}