<div class="mf-side-draw-content-container ecom-order-detain-host-container">
  <div class="mf-header">
    <div class="mf-header-label">
      Order Detail - #{{order?.orderNumber}} - {{order?.itemCode}}
    </div>
    <div class="mf-flex-fill"></div>
    <button mat-icon-button
            (click)="_onCloseClicked()">
      <mf-icon class="mf-header-icon"
               [icon]="_rootConfig.slideDrawer.closeIcon"></mf-icon>
    </button>
  </div>
  <div class="mf-side-draw-content">
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="ecom-tab-label-container">
            <mf-icon [icon]="_iconsConfig.detailsIcon"></mf-icon>
            Order Details
          </div>
        </ng-template>
        <ecom-order-details [order]="order"></ecom-order-details>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="ecom-tab-label-container">
            <mf-icon [icon]="_iconsConfig.documentationIcon"></mf-icon>
            Documentation
          </div>
        </ng-template>
        <ecom-order-documentation [order]="order"></ecom-order-documentation>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>