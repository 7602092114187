import { Component, EventEmitter, Inject, Injector, Input, Output, ViewEncapsulation } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfFilterService } from "@material-framework/filter/services/filter.service";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfTableFieldColumn, MfTableFilterExpressionEvent, MfTableFilterGroupEvent } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";


const TYPE_INFO: MfTypeInfo = { className: "MfTableRowHeaderMenuFilterComponent" };

@Component({
  selector: "mf-table-row-header-menu-filter",
  templateUrl: "table.row.header.menu.filter.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowHeaderMenuFilterComponent extends MfBaseComponent {
  @Input()
  public get column(): MfTableFieldColumn {
    return this._column;
  }
  public set column(value: MfTableFieldColumn) {
    this._column = value;
    //this._initFieldGroup();
  }

  // @Input()
  // public get filterGroup(): MfFilterGroup | undefined {
  //   return this._filterGroup;
  // }
  // public set filterGroup(value: MfFilterGroup | undefined) {
  //   this._filterGroup = value;
  //   this._initFieldGroup();
  // }

  @Output()
  public onFilterChange: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterGroupChange: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterRemoveGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterAddGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterExpressionChange: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onFilterAddExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onFilterRemoveExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onFilterEnterKey: EventEmitter<boolean> = new EventEmitter();

  protected _column!: MfTableFieldColumn;
  //protected _filterGroup?: MfFilterGroup;

  public constructor(
    protected _modelConfigService: MfModelConfigService,
    protected _filterService: MfFilterService,
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN) public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public onAddExpression(event: MfTableFilterExpressionEvent): void {
    this.onFilterAddExpression.emit(event);
  }

  public onAddGroup(event: MfTableFilterGroupEvent): void {
    this.onFilterAddGroup.emit(event);
  }

  public onExpressionChange(event: MfTableFilterExpressionEvent): void {
    this.onFilterExpressionChange.emit(event);
  }

  public onFilterChangeHandler(event: MfTableFilterGroupEvent): void {
    this.onFilterChange.emit(event);
  }

  public onGroupChange(event: MfTableFilterGroupEvent): void {
    this.onFilterGroupChange.emit(event);
  }

  public onRemoveExpression(event: MfTableFilterExpressionEvent): void {
    this.onFilterRemoveExpression.emit(event);
  }

  public onRemoveGroup(event: MfTableFilterGroupEvent): void {
    this.onFilterRemoveGroup.emit(event);
  }

  // protected _initFieldGroup(): void {
  //   if (!mfTypeIsUndefined(this._column) && !mfTypeIsUndefined(this._column.filter) && !mfTypeIsUndefined(this._filterGroup)) {
  //     const index = this._filterGroup.groups.findIndex(g => g.expressions.some(e => e.fieldKey === this._column.fieldKey));
  //     if (index === -1) {
  //       this._filterGroup.groups.push(this._column.filter.group);
  //     }
  //   }
  // }
}