import { Directive, EventEmitter, Injector, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MfBaseValidationComponent } from "@material-framework/base/base.validation.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfTableRowCellEditorValueChangeEvent } from "@material-framework/table/row/cell/value/editor/table.row.cell.editor";
import { MfTableRowCellBaseFormatter } from "@material-framework/table/row/cell/value/Table.row.cell.base.formatter";
import { MfTableFieldColumn, MfTableRowCellEditorValidatorOptions, MfTableRowCellValueBaseOptions } from "@material-framework/table/table";

@Directive()
export abstract class MfTableRowCellBaseEditorComponent<TOptions extends MfTableRowCellValueBaseOptions, TModel extends MfModelBase> extends MfBaseValidationComponent implements MfTableRowCellBaseFormatter<TOptions, TModel> {
  @Input()
  public get options(): TOptions | undefined {
    return this._options;
  }
  public set options(value: TOptions | undefined) {
    if (this._options !== value) {
      this._options = value;
      this._onOptionsSet(value);
    }
  }

  @Input()
  public get fieldColumn(): MfTableFieldColumn | undefined {
    return this._fieldColumn;
  }
  public set fieldColumn(value: MfTableFieldColumn | undefined) {
    if (this._fieldColumn !== value) {
      this._fieldColumn = value;
      this._onFieldColumnSet(value);
    }
  }

  @Input()
  public get rowItem(): TModel | undefined {
    return this._rowItem;
  }
  public set rowItem(value: TModel | undefined) {
    if (this._rowItem !== value) {
      this._rowItem = value;
      this._onRowItemSet(value);
    }
  }

  @Input()
  public get isLoading(): boolean | undefined {
    return this._isLoading;
  }
  public set isLoading(value: boolean | undefined) {
    if (this._isLoading !== value) {
      this._isLoading = value;
      this._onIsLoadingSet(value);
    }
  }

  @Output()
  public requireTableReload: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public onChanged: EventEmitter<MfTableRowCellEditorValueChangeEvent<TModel>> = new EventEmitter();

  @Output()
  public onInput: EventEmitter<MfTableRowCellEditorValueChangeEvent<TModel>> = new EventEmitter();

  public isEditor = true;
  public isFormatter = false;

  protected _options?: TOptions | undefined;
  protected _fieldColumn?: MfTableFieldColumn | undefined;
  protected _rowItem?: TModel | undefined;
  private _isLoading?: boolean;

  public constructor(
    protected override _typeInfo: MfTypeInfo,
    protected override _injector: Injector,
  ) {
    super(_typeInfo, _injector);
  }

  protected _addEditorOptionsValidators(formControlName: string, formControl: FormControl, validators: MfTableRowCellEditorValidatorOptions[]): void {
    const length = validators.length;
    for (let index = 0; index < length; index++) {
      const validator = validators[index];
      this._addMfValidatorFunction(formControlName, formControl, validator.validationFunction, validator.validationMessage);
    }
  }

  protected _onOptionsSet(value: TOptions | undefined): void { }
  protected _onFieldColumnSet(value: MfTableFieldColumn | undefined): void { }
  protected _onRowItemSet(value: TModel | undefined): void { }
  protected _onIsLoadingSet(value: boolean | undefined): void { }

  public abstract addValidators(validators: MfTableRowCellEditorValidatorOptions[]): void;
}