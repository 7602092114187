import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfSortTypes } from "@material-framework/common/sort/sort.types";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfSelectOption } from "@material-framework/select/select.option";
import { MfTableFieldColumn } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowHeaderMenuSortComponent" };

export type MfOnSortIndexChangingEvent = {
  column: MfTableFieldColumn,
  newIndex: number,
  oldIndex: number,
}

@Component({
  selector: "mf-table-row-header-menu-sort",
  templateUrl: "table.row.header.menu.sort.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowHeaderMenuSortComponent extends MfBaseComponent implements OnInit {
  @Input()
  public column?: MfTableFieldColumn;

  @Output()
  public onSortCleared: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onSortDirectionChanged: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onSortIndexChanging: EventEmitter<MfOnSortIndexChangingEvent> = new EventEmitter();

  @Output()
  public onSortIndexChanged: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  public ascDisplayName = "";
  public descDisplayName = "";
  public ascTooltip = "";
  public descTooltip = "";
  public sortTypes = MfSortTypes;

  public constructor(
    protected _modelConfigService: MfModelConfigService,
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN) public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    if (!mfTypeIsUndefined(this.column) && !mfTypeIsUndefined(this.column.modelFieldConfig)) {
      const sortDataType = this.config.row.header.sort.dataTypes[this.column.modelFieldConfig.dataType.type];
      if (!mfTypeIsUndefined(sortDataType)) {
        this.ascDisplayName = sortDataType.ascDisplayName;
        this.descDisplayName = sortDataType.descDisplayName;
        this.ascTooltip = sortDataType.ascTooltip;
        this.descTooltip = sortDataType.descTooltip;
      }
    }
  }

  public get orderEnabled(): boolean {
    return !mfTypeIsUndefined(this.column) && !mfTypeIsUndefined(this.column.sort) && !mfTypeIsUndefined(this.column.sort.options) && this.column.sort.options.length > 1;
  }

  public onClearClicked(): void {
    if (!mfTypeIsUndefined(this.column) && !mfTypeIsUndefined(this.column.sort)) {
      this.column.sort = {};
      this.onSortCleared.emit(this.column);
    }
  }

  public onSortIndexOptionClicked(option: MfSelectOption<number>): void {
    if (!mfTypeIsUndefined(this.column) && !mfTypeIsUndefined(this.column.sort) && !mfTypeIsUndefined(this.column.sort.index)) {
      this.onSortIndexChanging.emit({ column: this.column, newIndex: option.value, oldIndex: this.column.sort.index });
      this.column.sort.index = option.value;
      this.onSortIndexChanged.emit(this.column);
    }
  }

  public onAscClicked(): void {
    if (!mfTypeIsUndefined(this.column)) {
      if (mfTypeIsUndefined(this.column.sort)) {
        this.column.sort = { direction: MfSortTypes.asc };
      } else {
        this.column.sort.direction = MfSortTypes.asc;
      }
      this.onSortDirectionChanged.emit(this.column);
    }
  }

  public onDescClicked(): void {
    if (!mfTypeIsUndefined(this.column)) {
      if (mfTypeIsUndefined(this.column.sort)) {
        this.column.sort = { direction: MfSortTypes.desc };
      } else {
        this.column.sort.direction = MfSortTypes.desc;
      }
      this.onSortDirectionChanged.emit(this.column);
    }
  }
}