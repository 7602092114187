<div class="ecom-page-container ecom-home-container">
  <div class="ecom-page-account-select">
    <ecom-account-select></ecom-account-select>
  </div>
  <div class="ecom-page-header">
    <div class="ecom-title">
      Home
    </div>
  </div>
  <mf-action-cards [actions]="_actions"
                   (onActionClicked)="_actionClicked($event)">
  </mf-action-cards>
</div>