import { Injector } from "@angular/core";
import { MfError } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeGetKeys, mfTypeHasOwnProperty, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { Observable } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "FunctionRef" };

const MF_FUNCTION_REFS: MfFunctionStore<unknown, unknown, unknown, unknown, unknown, unknown> = {

};



export type MfFunctionCall<TReturn = unknown, TArg1 = unknown, TArg2 = unknown, TArg3 = unknown, TArg4 = unknown, TArg5 = unknown> =
  (injector: Injector, arg1?: TArg1, arg2?: TArg2, arg3?: TArg3, arg4?: TArg4, arg5?: TArg5) => TReturn | Observable<TReturn>;

export type MfFunctionStoreItem = {

  name: string;
  isObservable?: boolean;
}

export type MfFunctionStore<TReturn = unknown, TArg1 = unknown, TArg2 = unknown, TArg3 = unknown, TArg4 = unknown, TArg5 = unknown> = {
  [name: string]: MfFunctionRef<TReturn, TArg1, TArg2, TArg3, TArg4, TArg5>;
}

export type MfFunctionRef<TReturn = unknown, TArg1 = unknown, TArg2 = unknown, TArg3 = unknown, TArg4 = unknown, TArg5 = unknown> = {
  function: MfFunctionCall<TReturn, TArg1, TArg2, TArg3, TArg4, TArg5>;
  isObservable?: boolean;
  name: string;
};

export type MfFunctionPointer<TReturn = unknown, TArg1 = unknown, TArg2 = unknown, TArg3 = unknown, TArg4 = unknown, TArg5 = unknown> = {
  name: string;
  isFunctionPointer: true;
}

export function mfFunctionRefIsFunctionRef<TReturn = unknown, TArg1 = unknown, TArg2 = unknown, TArg3 = unknown, TArg4 = unknown, TArg5 = unknown>(value: unknown): value is MfFunctionPointer<TReturn, TArg1, TArg2, TArg3, TArg4, TArg5> {
  if (!mfTypeIsUndefined(value) && value instanceof Object && mfTypeHasOwnProperty(value, "isFunctionPointer") && value.isFunctionPointer === true) {
    return true;
  }

  return false;
}

export function mfFunctionRefRegisterFunctionRef<TReturn = unknown, TArg1 = unknown, TArg2 = unknown, TArg3 = unknown, TArg4 = unknown, TArg5 = unknown>(ref: MfFunctionRef<TReturn, TArg1, TArg2, TArg3, TArg4, TArg5>): MfFunctionPointer<TReturn, TArg1, TArg2, TArg3, TArg4, TArg5> {
  const functionNames = mfTypeGetKeys(MF_FUNCTION_REFS);
  if (functionNames.some(i => i === ref.name)) {
    throw new MfError(TYPE_INFO, "registerFunctionRef", `There is already a function with the name ${ref.name} registered`);
  }

  MF_FUNCTION_REFS[ref.name] = ref as MfFunctionRef<unknown, unknown, unknown, unknown, unknown, unknown>;

  return { name: ref.name, isFunctionPointer: true };
}

export function mfFunctionRefGetRegisteredFunctionRef<TReturn = unknown, TArg1 = unknown, TArg2 = unknown, TArg3 = unknown, TArg4 = unknown, TArg5 = unknown>(pointer: MfFunctionPointer<TReturn, TArg1, TArg2, TArg3, TArg4, TArg5>): MfFunctionRef<TReturn, TArg1, TArg2, TArg3, TArg4, TArg5> {
  return MF_FUNCTION_REFS[pointer.name] as MfFunctionRef<TReturn, TArg1, TArg2, TArg3, TArg4, TArg5>;
}