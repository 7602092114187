// eslint-disable-next-line @typescript-eslint/naming-convention
export const EComInvoiceStatusesTypes = {
  approvedForPayment: "A",
  balanceForwardSummarized: "S",
  checkBeingWritten: "#",
  creditPending: "C",
  disputedTaxes: "T",
  draftAccepted: "D",
  draftDepositedNotDue: "G",
  draftExpiredNotCollected: "E",
  draftReceiptVoucher: "X",
  heldPendingApproval: "H",
  heldVarianceInReceiptMatch: "V",
  needTaxExemptionCertificate: "N",
  ownerPayment: "O",
  paidInFull: "P",
  retainage: "R",
  tempPaymentStatus: "Z",
  thresholdExceeded: "W",
  withholdingApplies: "%",
} as const;

export type EComInvoiceStatusesTypes = typeof EComInvoiceStatusesTypes[keyof typeof EComInvoiceStatusesTypes]

export function eComInvoiceStatusesTypeToDisplayName(value: EComInvoiceStatusesTypes): string {
  switch (value) {
    case EComInvoiceStatusesTypes.approvedForPayment:
      return "Approved For Payment";
    case EComInvoiceStatusesTypes.balanceForwardSummarized:
      return "Balance Forward";
    case EComInvoiceStatusesTypes.checkBeingWritten:
      return "Check Being Written";
    case EComInvoiceStatusesTypes.creditPending:
      return "Credit Pending";
    case EComInvoiceStatusesTypes.disputedTaxes:
      return "Disputed Taxes";
    case EComInvoiceStatusesTypes.draftAccepted:
      return "Draft Accepted";
    case EComInvoiceStatusesTypes.draftDepositedNotDue:
      return "Draft Deposited Not Due";
    case EComInvoiceStatusesTypes.draftExpiredNotCollected:
      return "Draft Expired Not Collected";
    case EComInvoiceStatusesTypes.draftReceiptVoucher:
      return "Draft Receipt Voucher";
    case EComInvoiceStatusesTypes.heldPendingApproval:
      return "Held Pending Approval";
    case EComInvoiceStatusesTypes.heldVarianceInReceiptMatch:
      return "Held Variance In Receipt Match";
    case EComInvoiceStatusesTypes.needTaxExemptionCertificate:
      return "Need Tax Exemption Certificate";
    case EComInvoiceStatusesTypes.ownerPayment:
      return "Owner Payment";
    case EComInvoiceStatusesTypes.paidInFull:
      return "Paid In Full";
    case EComInvoiceStatusesTypes.retainage:
      return "Retainage";
    case EComInvoiceStatusesTypes.tempPaymentStatus:
      return "Temp Payment Status";
    case EComInvoiceStatusesTypes.thresholdExceeded:
      return "Threshold Exceeded";
    case EComInvoiceStatusesTypes.withholdingApplies:
      return "With Holding Applies";
    default:
      return "NOT MAPPED";
  }
}