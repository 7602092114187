import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";

export type EComInvoiceDownloadDocumentRequest = {
  key: string;
  documentKey: string;
}

export type EComInvoiceDownloadDocumentRequestModelFieldsConfig = {
  [key in keyof Required<EComInvoiceDownloadDocumentRequest>]: MfModelFieldConfig
};

export const ECOM_INVOICE_DOWNLOAD_DOCUMENT_REQUEST_MODEL_FIELDS_CONFIG: EComInvoiceDownloadDocumentRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
  documentKey: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
};

export const ECOM_INVOICE_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG_ID = "invoiceDownloadDocumentRequest";

export const ECOM_INVOICE_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_DOWNLOAD_DOCUMENT_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComInvoiceDocumentDownloadInfoPayload = {
  key: string;
  documentKey: string;
}

export type EComInvoiceDocumentDownloadInfoModel = MfModelBase & {
  httpMethod: string;
  downloadUrl: string;
  payLoad: EComInvoiceDocumentDownloadInfoPayload;
}

export type EComInvoiceDocumentDownloadInfoFieldsConfig = {
  [key in keyof Required<EComInvoiceDocumentDownloadInfoModel>]: MfModelFieldConfig
};

export const ECOM_INVOICE_DOCUMENT_DOWNLOAD_INFO_MODEL_FIELDS_CONFIG: EComInvoiceDocumentDownloadInfoFieldsConfig = {
  httpMethod: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
  downloadUrl: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
  payLoad: {
    dataType: {
      type: MfModelFieldDataTypes.object,
    }
  },
};

export const ECOM_INVOICE_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG_ID = "invoiceDocumentDownloadInfo";

export const ECOM_INVOICE_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_DOCUMENT_DOWNLOAD_INFO_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG",
  },
  portals: {}
};

export type EComInvoiceDocumentsModel = MfModelBase & {
  documents: EComInvoiceDocumentModel[]
}

export type EComInvoiceDocumentsModelFieldsConfig = {
  [key in keyof Required<EComInvoiceDocumentsModel>]: MfModelFieldConfig
};

export const ECOM_INVOICE_DOCUMENTS_MODEL_FIELDS_CONFIG: EComInvoiceDocumentsModelFieldsConfig = {
  documents: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.object,
    }
  },
};

export const ECOM_INVOICE_DOCUMENTS_MODEL_CONFIG_ID = "invoiceDocuments";

export const ECOM_INVOICE_DOCUMENTS_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_DOCUMENTS_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_DOCUMENTS_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_DOCUMENTS_MODEL_CONFIG",
  },
  portals: {}
};

export type EComInvoiceDocumentModel = MfModelBase & {
  documentKey: string;
  name: string;
}

export type EComInvoiceDocumentModelFieldsConfig = {
  [key in keyof Required<EComInvoiceDocumentModel>]: MfModelFieldConfig
};

export const ECOM_INVOICE_DOCUMENT_MODEL_FIELDS_CONFIG: EComInvoiceDocumentModelFieldsConfig = {
  documentKey: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
  name: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
};

export const ECOM_INVOICE_DOCUMENT_MODEL_CONFIG_ID = "invoiceDocument";

export const ECOM_INVOICE_DOCUMENT_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_DOCUMENT_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_DOCUMENT_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_DOCUMENT_MODEL_CONFIG",
  },
  portals: {}
};