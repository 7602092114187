import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";
import { MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG, MfPortalsRequestCollectionData } from "@material-framework/portals/portals";

export type EComStockSummeryRequest = MfPortalsRequestCollectionData & {
  key?: string;
  customerAccountComposite?: string;
  site?: string;
  itemCode?: string;
  viewModelColumns?: string;
  id?: string;
}

export type EComStockSummeryRequestFieldsConfig = {
  [key in keyof Required<EComStockSummeryRequest>]: MfModelFieldConfig
};

export const ECOM_STOCK_SUMMERY_REQUEST_MODEL_FIELDS_CONFIG: EComStockSummeryRequestFieldsConfig = {
  ...MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG,
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  customerAccountComposite: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  site: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  itemCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  viewModelColumns: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  id: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  }
};

export const ECOM_STOCK_SUMMERY_REQUEST_MODEL_CONFIG_ID = "stockSummeryRequest";

export const ECOM_STOCK_SUMMERY_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_STOCK_SUMMERY_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_STOCK_SUMMERY_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_STOCK_SUMMERY_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComStockSummeryModel = MfModelBase & {
  branchCode: string,
  buildProjectReference: string,
  certificateDescription: string,
  certificationKey: string,
  currencyCode: string;
  dfarCode: string,
  itemCode: string;
  itemCodeShort: number,
  itemDescription: string;
  itemKey: string,
  itemSpecKey: string;
  itemType: string,
  manufacturerAccountComposite: string,
  manufacturerAccountKey: string,
  manufacturerName: string;
  portalTransactionNumber: number;
  priceBreakPrice1: number;
  pricingUnitOfMeasure: string,
  quantityAvailable: number;
  revision: string;
  shelfLifeDays: number;
  shipToAccountComposite: string,
  shipToAccountKey: string,
  site: string;
  uniqueIdentifier: number,
  unitOfMeasure: string,
  orderQty: number;
}

export type EComStockSummeryModelFieldsConfig = {
  [key in keyof Required<EComStockSummeryModel>]: MfModelFieldConfig
};

export const ECOM_STOCK_SUMMERY_MODEL_FIELDS_CONFIG: EComStockSummeryModelFieldsConfig = {
  branchCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Branch Code"
    }
  },
  buildProjectReference: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  certificateDescription: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Certification Description"
    }
  },
  certificationKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  currencyCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Currency"
    }
  },
  dfarCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "DFAR",
    }
  },
  itemCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Part"
    }
  },
  itemCodeShort: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemDescription: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Description"
    }
  },
  itemKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemSpecKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemType: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Type"
    }
  },
  manufacturerAccountComposite: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  manufacturerAccountKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  manufacturerName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Manufacturer"
    }
  },
  portalTransactionNumber: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  priceBreakPrice1: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  pricingUnitOfMeasure: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  quantityAvailable: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
    display: {
      displayName: "Qty Available"
    }
  },
  revision: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Revision"
    }
  },
  shelfLifeDays: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
    display: {
      displayName: "Shelf Life"
    }
  },
  shipToAccountComposite: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  shipToAccountKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  site: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Site"
    }
  },
  uniqueIdentifier: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  unitOfMeasure: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "UOM"
    }
  },
  orderQty: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
    display: {
      displayName: "Qty Order"
    }
  },
};

export const ECOM_STOCK_SUMMERY_MODEL_CONFIG_ID = "stockSummery";

export const ECOM_STOCK_SUMMERY_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_STOCK_SUMMERY_MODEL_CONFIG_ID,
  fields: ECOM_STOCK_SUMMERY_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_STOCK_SUMMERY_MODEL_CONFIG",
  },
  portals: {}
};