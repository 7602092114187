import { MfIcon } from "@material-framework/icon/icon";


export type MfUserPersona = {
  userId: string | number;
  displayName: string;
  jobTitle?: string;
  eMail?: string;
  photo?: string;
  initials?: string;
}

export type MfUserPersonaFieldMappings = {
  idMap: string;
  displayNameMap: string;
  emailMap?: string;
  photoMap?: string;
  jobTitleMap?: string;
  initialsMap?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MfUserPersonaCardActionTypes = {
  action: "action",
  divider: "divider",
} as const;
export type MfUserPersonaCardActionTypes = typeof MfUserPersonaCardActionTypes[keyof typeof MfUserPersonaCardActionTypes];

export type MfUserPersonaCardAction = {
  id: string,
  type: MfUserPersonaCardActionTypes,
  title?: string;
  name?: string;
  icon?: MfIcon,
  disableCloseOnClick?: boolean;
}