import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";

export type EComCheckoutOrderRequestModel = MfModelBase & {
  key: string;
  cartKey: string;
};

export type EComCheckoutOrderRequestModelFieldsConfig = {
  [key in keyof Required<EComCheckoutOrderRequestModel>]: MfModelFieldConfig
};

export const ECOM_CHECKOUT_ORDER_REQUEST_MODEL_FIELDS_CONFIG: EComCheckoutOrderRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
};

export const ECOM_CHECKOUT_ORDER_REQUEST_MODEL_CONFIG_ID = "checkoutOrderRequest";

export const ECOM_CHECKOUT_ORDER_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CHECKOUT_ORDER_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_CHECKOUT_ORDER_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CHECKOUT_ORDER_REQUEST_CONFIG",
  },
  portals: {}
};

export type EComCartValidationOrderModel = MfModelBase & {
  cartKey?: string;
  orderReference?: string;
  items?: EComCartValidationOrderItemModel[],
}

export type EComCartValidationOrderModelFieldsConfig = {
  [key in keyof Required<EComCartValidationOrderModel>]: MfModelFieldConfig
};

export const ECOM_CART_VALIDATION_ORDER_MODEL_FIELDS_CONFIG: EComCartValidationOrderModelFieldsConfig = {
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  orderReference: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  items: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.object,
    },
  },
};

export const ECOM_CART_VALIDATION_ORDER_MODEL_CONFIG_ID = "cartValidationOrder";

export const ECOM_CART_VALIDATION_ORDER_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CART_VALIDATION_ORDER_MODEL_CONFIG_ID,
  fields: ECOM_CART_VALIDATION_ORDER_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CART_VALIDATION_ORDER_MODEL_CONFIG",
  },
  portals: {}
};

export type EComCartValidationOrderItemModel = MfModelBase & {
  cartItemKey: string,
  latestQuantityAvailable?: number;
  latestPrice?: number;
  latestPriceCurrency?: string;
  latestPriceUom?: string;
  latestItemUom?: string;
  isRequiredDateValid?: boolean;
  errorMessage?: string;
}

export type EComCartValidationOrderItemModelFieldsConfig = {
  [key in keyof Required<EComCartValidationOrderItemModel>]: MfModelFieldConfig
};

export const ECOM_CART_VALIDATION_ORDER_ITEM_MODEL_FIELDS_CONFIG: EComCartValidationOrderItemModelFieldsConfig = {
  cartItemKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  latestQuantityAvailable: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  latestPrice: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  latestPriceCurrency: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  latestPriceUom: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  latestItemUom: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  errorMessage: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  isRequiredDateValid: {
    dataType: {
      type: MfModelFieldDataTypes.boolean
    },
  },
};

export const ECOM_CART_VALIDATION_ORDER_ITEM_MODEL_CONFIG_ID = "cartValidationOrderItem";

export const ECOM_CART_VALIDATION_ORDER_ITEM_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CART_VALIDATION_ORDER_ITEM_MODEL_CONFIG_ID,
  fields: ECOM_CART_VALIDATION_ORDER_ITEM_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CART_VALIDATION_ORDER_ITEM_MODEL_CONFIG",
  },
  portals: {}
};