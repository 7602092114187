import { Component, Inject, Injector, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ECOM_ICONS_CONFIG_TOKEN, ECOM_TABLE_CONFIG, EComIconsConfig } from "@e-commerce/ecom.config";
import { ECOM_BREADCRUMB_KEY_INVOICE_DETAILS } from "@e-commerce/ecom.route.keys";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComInvoiceDetailModel, EComInvoiceDetailRequest } from "@e-commerce/modelConfigs/invoice.detail.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { ECOM_ORDERS_LOCATION_KEY } from "@e-commerce/orders/orders.component";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { EComInvoiceService } from "@e-commerce/services/invoice.service";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfBreadcrumbService } from "@material-framework/breadcrumb/breadcrumb.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPath } from "@material-framework/common/utils/object.utils";
import { mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MF_STRING_FILTER_CONFIG } from "@material-framework/filter/filter.model.config";
import { MfFilterOperatorTypes } from "@material-framework/filter/filter.types";
import { MFModelConfigFieldPath, MfModelConfigMapped, MfModelFieldExtendedConfig } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfPortalsTableComponent } from "@material-framework/portals/table/portals.table.utils";
import { MfTableQueryFilterService } from "@material-framework/table/queryFilter/table.query.filter.service";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";
import { mfTableReIndexModelFieldExtendedConfig } from "@material-framework/table/table.model.config";
import { map, Observable } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComInvoiceDetailComponent" };

const ECOM_INVOICE_DETAIL_TABLE_CONFIG: MfTableConfig = {
  ...ECOM_TABLE_CONFIG,
  row: {
    ...ECOM_TABLE_CONFIG.row,
    clickAction: {
      ...ECOM_TABLE_CONFIG.row.clickAction,
      tooltip: "View Order History",
    }
  }
};

const ECOM_INVOICE_DETAIL_LOCATION_KEY = "invoice.detail";

const ECOM_INVOICE_DETAIL_EXTENDED_MODEL_CONFIG: MfModelFieldExtendedConfig[] = [
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceDetailModel>("documentLineNumber")
  },
  {
    table: { index: 0, header: { sortable: false } },
    filter: { ...MF_STRING_FILTER_CONFIG, input: { string: { maxLength: 25 } } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceDetailModel>("itemCode")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceDetailModel>("orderNumber")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceDetailModel>("shipDate")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceDetailModel>("currencyCode")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceDetailModel>("shippedQuantity")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceDetailModel>("currencyPrice")
  },
  // {
  //   table: { index: 0, header: { sortable: false } },
  //   fieldPath: mfObjectGetPropertyPath<EComInvoiceDetailModel>("tax")
  // },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceDetailModel>("currencyValue")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceDetailModel>("carrierName")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceDetailModel>("trackingNumber")
  },
];

@Component({
  selector: "ecom-invoice-detail",
  templateUrl: "./invoice.detail.component.html",
  styleUrls: ["./invoice.detail.component.scss"],
  providers: [
    { provide: MF_TABLE_CONFIG_TOKEN, useValue: ECOM_INVOICE_DETAIL_TABLE_CONFIG },
  ]
})
export class EComInvoiceDetailComponent extends MfBaseComponent implements OnInit {
  @ViewChild("invoiceDetailsTable", { static: true })
  protected get _invoiceDetailsTable(): MfPortalsTableComponent<EComInvoiceDetailModel, string, number> | undefined {
    return this._invoiceDetailsInt;
  }
  protected set _invoiceDetailsTable(value: MfPortalsTableComponent<EComInvoiceDetailModel, string, number> | undefined) {
    this._invoiceDetailsInt = value;
    this._initializeTable();
  }

  protected _invoiceDetailModelConfig: MfModelConfigMapped;
  protected _invoiceDetailRequestModelConfig: MfModelConfigMapped;
  protected _invoiceDetailsInt?: MfPortalsTableComponent<EComInvoiceDetailModel, string, number>;
  protected _locationKey = ECOM_INVOICE_DETAIL_LOCATION_KEY;
  protected _documentKey?: string;
  protected _documentNumber?: string;
  protected _orderNumberFieldPath: MFModelConfigFieldPath;

  public constructor(
    protected override _injector: Injector,
    protected _activatedRoute: ActivatedRoute,
    protected _router: Router,
    protected _breadcrumbService: MfBreadcrumbService,
    protected _modelConfigService: MfModelConfigService,
    protected _customerService: EComCustomerService,
    protected _invoiceService: EComInvoiceService,
    protected _tableQueryFilterService: MfTableQueryFilterService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _config: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._invoiceDetailModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceDetail");
    this._invoiceDetailRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceDetailRequest");
    this._orderNumberFieldPath = mfObjectGetPropertyPath<EComInvoiceDetailModel>("orderNumber");
    this._setModelConfigs();
    this._getRouteParams();
    this._tableLoadData();
  }

  public ngOnInit(): void {
    this._sub(this._customerService.hasSelection, {
      next: (hasSelection) => {
        if (hasSelection) {
          this._tableLoadData();
        }
      }
    });
  }

  protected _viewInvoice(invoice: EComInvoiceDetailModel): void {
    const loading = this._subLoading(this._invoiceService.downloadDocument(invoice), {
      next: () => { loading.complete(); },
      error: () => { loading.complete(); },
    }, "Downloading Invoice");
  }

  protected _viewCustomers(invoice: EComInvoiceDetailModel): void {

  }

  protected _viewOrderHistory(invoice: EComInvoiceDetailModel): void {
    this._router.navigate([EcomRouteNames.getInvoiceDetailOrdersRoute(this._documentKey!, this._documentNumber!)], { queryParams: { [this._tableQueryFilterService.getQueryParamName(this._orderNumberFieldPath, MfFilterOperatorTypes.eq, ECOM_ORDERS_LOCATION_KEY)]: invoice.orderNumber } });
  }

  protected get _isLoading(): boolean {
    return this._invoiceDetailsTable?.isLoading === true;
  }

  protected _tableLoadData(): void {
    if (!mfTypeIsUndefined(this._invoiceDetailsTable) && !mfTypeIsNullOrUndefined(this._documentKey) && !mfTypeIsNullOrUndefined(this._documentNumber)) {
      this._invoiceDetailsTable.blockDataLoad = false;
      this._invoiceDetailsTable.loadData();
    }
  }

  protected _setModelConfigs(): void {
    mfTableReIndexModelFieldExtendedConfig(ECOM_INVOICE_DETAIL_EXTENDED_MODEL_CONFIG);
    this._modelConfigService.setExtendedConfigs(this._invoiceDetailModelConfig, ECOM_INVOICE_DETAIL_EXTENDED_MODEL_CONFIG);
  }

  protected _initializeTable(): void {
    if (!mfTypeIsUndefined(this._invoiceDetailsTable)) {
      this._invoiceDetailsTable.blockDataLoad = true;
      this._invoiceDetailsTable.dataSource.url = `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsInvoicesUrl}/detail`;

      this._invoiceDetailsTable.dataSource.buildPostData = this._updatePostDate;
    }
  }

  protected _updatePostDate = (data: EComInvoiceDetailRequest): Observable<EComInvoiceDetailRequest> => {
    return this._customerService.selected.pipe(
      map((selected) => {
        data.documentKey = this._documentKey;
        data.key = selected.key;
        return data;
      })
    );
  };

  protected _getRouteParams(): void {
    this._sub(this._activatedRoute.params, {
      next: (params) => {
        const documentKey = params[EcomRouteNames.parameterNameDocumentKey];
        if (!mfTypeIsNullOrUndefined(documentKey)) {
          this._documentKey = documentKey;
        }
        const documentNumber = params[EcomRouteNames.parameterNameDocumentNumber];
        if (!mfTypeIsNullOrUndefined(documentNumber)) {
          this._documentNumber = documentNumber;
        }

        this._setBreadcrumbProductName();
        this._tableLoadData();
      }
    });
  }

  protected _setBreadcrumbProductName(): void {
    if (!mfTypeIsUndefined(this._documentNumber)) {
      this._breadcrumbService.update(ECOM_BREADCRUMB_KEY_INVOICE_DETAILS, { label: `#${this._documentNumber}` });
    }
  }
}