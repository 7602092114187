import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { EComPaypalSilentPostComponent } from "@e-commerce/landing/paypal/silentPost/paypal.silent.post.component";

@NgModule({
  declarations: [
    EComPaypalSilentPostComponent
  ],
  exports: [
    EComPaypalSilentPostComponent
  ],
  imports: [
    BrowserModule,
  ],
  providers: [
  ]
})
export class EComPaypalSilentPostModule { }