import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MfBreadcrumbModule } from "@material-framework/breadcrumb/breadcrumb.module";
import { MfHeaderComponent } from "@material-framework/header/header.component";
import { MF_HEADER_CONFIG_TOKEN, MF_HEADER_DEFAULT_CONFIG } from "@material-framework/header/header.config";
import { MfHeaderMainRightDef, MfHeaderSubRightDef, MfHeaderMainLeftDef, MfHeaderSubLeftDef } from "@material-framework/header/header.def.directive";
import { MfIconModule } from "@material-framework/icon/icon.module";

@NgModule({
  declarations: [
    MfHeaderComponent,
    MfHeaderMainRightDef,
    MfHeaderSubRightDef,
    MfHeaderMainLeftDef,
    MfHeaderSubLeftDef,
  ],
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MfBreadcrumbModule,
    MfIconModule,
    CommonModule,
  ],
  exports: [
    MfHeaderComponent,
    MfHeaderMainRightDef,
    MfHeaderSubRightDef,
    MfHeaderMainLeftDef,
    MfHeaderSubLeftDef,
  ],
  providers: [
    { provide: MF_HEADER_CONFIG_TOKEN, useValue: MF_HEADER_DEFAULT_CONFIG },
  ]
})
export class MfHeaderModule {
}