import { MfErrorInvalidInputParameters } from "@material-framework/common/error/error";
import { MfSortTypes } from "@material-framework/common/sort/sort.types";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPathValue, mfObjectGetPropertyPathValueAsDate, mfObjectGetPropertyPathValueAsNumber, mfObjectGetPropertyPathValuesAsNumbers } from "@material-framework/common/utils/object.utils";
import { mfTypeIsNullOrUndefined, mfTypeIsUndefined, MfTypeNumberOptionalKeys, MfTypePaths } from "@material-framework/common/utils/type.utils";
import * as moment from "moment";


const TYPE_INFO: MfTypeInfo = { className: "ArrayUtils" };

export type MfArrayNonEmpty<T> = [T, ...T[]];

export function mfArrayReplicateRange<T>(arr: T[], fromIndex: number, toIndex: number, count: number): T[] {
  if (!Array.isArray(arr) || fromIndex < 0 || toIndex >= arr.length || fromIndex > toIndex || count <= 0) {
    throw new MfErrorInvalidInputParameters(TYPE_INFO, "mfArrayReplicateRange");
  }

  const range = arr.slice(fromIndex, toIndex + 1);
  const replicatedArray = Array.from({ length: count }, () => [...range]).flat();

  return replicatedArray;
}

export function mfArraySum(items: number[] | undefined): number {
  if (mfTypeIsUndefined(items) || items.length === 0) return 0;
  return items.reduce((sum, value) => sum + value);
}

export function mfArraySumKey<T>(items: T[] | undefined, key: MfTypeNumberOptionalKeys<T>[keyof T]): number {
  if (mfTypeIsUndefined(items) || items.length === 0) return 0;
  const mappedItems = items.filter(i => !mfTypeIsNullOrUndefined(i[key]) && !isNaN(i[key] as any)).map(i => i[key] as number);
  if (mfTypeIsUndefined(mappedItems) || mappedItems.length === 0) return 0;
  return mappedItems.reduce((sum, value) => sum + value);
}

export function mfArrayCount<T extends object>(items: T[], handler: (item: T) => boolean): number {
  let count = 0;

  items.forEach((item) => {
    if (handler(item)) {
      count++;
    }
  });

  return count;
}

export function mfArrayMaxByPath<T extends object>(items: T[], path: MfTypePaths<T>): number | undefined {
  const values = mfObjectGetPropertyPathValuesAsNumbers(items, path);
  if (values.length > 0) {
    return Math.max(...values);
  }
  return;
}

export function mfArraySumByPath<T extends object>(items: T[], path: MfTypePaths<T>): number {
  const values = mfObjectGetPropertyPathValuesAsNumbers(items, path);
  if (values.length > 0) {
    return values.reduce((a, b) => a + b, 0);
  }
  return 0;
}

export function mfArrayAverageByPath<T extends object>(items: T[], path: MfTypePaths<T>): number | undefined {
  const sum = mfArraySumByPath(items, path);
  if (!mfTypeIsUndefined(sum)) {
    return (sum / items.length) || 0;
  }
  return;
}

export function mfArrayAverage(items: number[]): number {
  const sum = mfArraySum(items);
  return (sum / items.length) || 0;
}

export function mfArrayMinByPath<T extends object>(items: T[], path: MfTypePaths<T>): number | undefined {
  const values = mfObjectGetPropertyPathValuesAsNumbers(items, path);
  if (values.length > 0) {
    return Math.min(...values);
  }
  return;
}

export function mfArrayFilterUndefined<T>(items: T[]): T[] {
  return items.filter(i => !mfTypeIsNullOrUndefined(i));
}

export function mfArrayFilterUndefinedByPath<T extends object>(items: T[], path: MfTypePaths<T>): T[] {
  return items.filter(i => !mfTypeIsUndefined(mfObjectGetPropertyPathValue(path, i)));
}

export function mfArraySortByDatePath<T extends object>(items: T[], path: MfTypePaths<T>, direction: MfSortTypes = MfSortTypes.asc): T[] {
  return items.sort((a, b) => {
    const dateA = mfObjectGetPropertyPathValueAsDate(path, a);
    const dateB = mfObjectGetPropertyPathValueAsDate(path, b);
    const valueA = mfTypeIsUndefined(dateA) ? undefined : moment.isMoment(dateA) ? dateA.toDate().getTime() : dateA.getTime();
    const valueB = mfTypeIsUndefined(dateB) ? undefined : moment.isMoment(dateB) ? dateB.toDate().getTime() : dateB.getTime();

    if (mfTypeIsUndefined(valueA) || mfTypeIsUndefined(valueB)) {
      return 0;
    }

    return direction === MfSortTypes.asc ? valueA - valueB : valueB - valueA;
  });
}

export function mfArraySortByNumberPath<T extends object>(items: T[], path: MfTypePaths<T>, direction: MfSortTypes = MfSortTypes.asc): T[] {
  return items.sort((a, b) => {
    const valueA = mfObjectGetPropertyPathValueAsNumber(path, a);
    const valueB = mfObjectGetPropertyPathValueAsNumber(path, b);

    if (mfTypeIsUndefined(valueA) || mfTypeIsUndefined(valueB)) {
      return 0;
    }

    return direction === MfSortTypes.asc ? valueA - valueB : valueB - valueA;
  });
}

export function mfArraySortByStringPath<T extends object>(items: T[], path: MfTypePaths<T>, direction: MfSortTypes = MfSortTypes.asc): T[] {
  return items.sort((a, b) => {
    let valueA = mfObjectGetPropertyPathValue<string>(path, a);
    let valueB = mfObjectGetPropertyPathValue<string>(path, b);

    if (mfTypeIsUndefined(valueA) || mfTypeIsUndefined(valueB)) {
      return 0;
    }

    valueA = valueA.toLocaleLowerCase();
    valueB = valueB.toLocaleLowerCase();

    return direction === MfSortTypes.asc ? valueA > valueB ? 1 : -1 : valueA < valueB ? 1 : -1;
  });
}