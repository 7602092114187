import { mfTypeHasOwnProperty, mfTypeIsArray, mfTypeIsDate, mfTypeIsNumber, mfTypeIsObject, mfTypeIsUndefined, MfTypePaths } from "@material-framework/common/utils/type.utils";
import { MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR } from "@material-framework/modelConfig/model.config.service";
import * as moment from "moment";


export function mfObjectClone<T>(value: T): T {
  return structuredClone(value);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = { [key: string]: any };

export function mfObjectSetNullToUndefined(obj: AnyObject): AnyObject {
  for (const key in obj) {
    if (!mfTypeIsArray(obj) && mfTypeHasOwnProperty(obj, key)) {
      if (obj[key] === null) {
        delete obj[key];
      } else if (mfTypeIsObject(obj[key])) {
        obj[key] = mfObjectSetNullToUndefined(obj[key]);
      }
    }
  }
  return obj;
}

export function mfObjectGetPropertyPath<T>(path: MfTypePaths<T>): string {
  return path;
}

export function mfObjectPropertyPathIsUndefined(path: string, obj: object): boolean {
  return mfTypeIsUndefined(mfObjectGetPropertyPathValue(path, obj));
}

export function mfObjectGetPropertyPathValue<TValue>(path?: string, obj?: object): TValue | undefined {
  if (!mfTypeIsUndefined(obj) && !mfTypeIsUndefined(path)) {
    if (path.indexOf(MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR) !== -1) {
      const keys = path.split(MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR);
      const keysLength = keys.length;

      let previous: unknown = obj;
      for (let i = 0; i < keysLength; i++) {
        const propName = keys[i];
        if (mfTypeIsObject(previous) && mfTypeHasOwnProperty(previous, propName)) {
          previous = previous[propName];
        } else {
          return undefined;
        }
      }
      return previous as TValue;
    } else if (mfTypeIsObject(obj) && mfTypeHasOwnProperty(obj, path)) {
      return obj[path] as TValue;
    }
  }

  return undefined;
}

export function mfObjectGetPropertyPathValueAsDate(path: string, obj?: object): Date | moment.Moment | undefined {
  const value = mfObjectGetPropertyPathValue(path, obj);
  if (!mfTypeIsUndefined(value) && (moment.isMoment(value) || mfTypeIsDate(value))) {
    return value;
  }
  return;
}

export function mfObjectGetPropertyPathValueAsNumber(path: string, obj?: object): number | undefined {
  const value = mfObjectGetPropertyPathValue(path, obj);
  if (!mfTypeIsUndefined(value) && mfTypeIsNumber(value) && !isNaN(value)) {
    return value;
  }
  return;
}

export function mfObjectSetPropertyPathValue(path: string, obj: Record<string, any>, value?: unknown): void {
  if (mfTypeIsUndefined(value)) {
    return;
  }

  const keys = path.split(MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR);
  const lastKey = keys.pop() as string;

  let currentObj: Record<string, any> = obj;

  for (const propName of keys) {
    currentObj = currentObj[propName] || (currentObj[propName] = {});
  }

  currentObj[lastKey] = value;
}

export function mfObjectGetPropertyPathValuesAsNumbers<T extends object>(items: T[], path: string): number[] {
  const valuesLength = items.length;
  const values: number[] = [];
  for (let valueIndex = 0; valueIndex < valuesLength; valueIndex++) {
    const item = items[valueIndex];
    const value = mfObjectGetPropertyPathValueAsNumber(path, item);
    if (!mfTypeIsUndefined(value)) {
      values.push(value);
    }
  }
  return values;
}

export const mfObjectNameOf = <T>(name: Extract<keyof T, string>): string => name;