import { Injectable, Injector } from "@angular/core";
import { concatMap, map, Observable, of } from "rxjs";

import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { MfAuthService } from "@material-framework/auth/auth.service";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfTypeInfo } from "@material-framework/common/type.info";


const TYPE_INFO: MfTypeInfo = { className: "MfAuthGuard" };

@Injectable()
export class MfAuthGuardService extends MfBaseService {
  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _authService: MfAuthService,
  ) {
    super(TYPE_INFO, _injector);
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._authService.canActivateProtectedRoutes.pipe(
      concatMap((result) => {
        if (result === true) {
          return of(result);
        }

        return this._authService.runInitialLoginSequence(state.url).pipe(
          map(() => result)
        );
      })
    );
  }
}