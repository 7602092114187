import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeGetKeys, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFilterGroup } from "@material-framework/filter/filter";
import { MfFilterService } from "@material-framework/filter/services/filter.service";
import { MfModelFieldsConfig } from "@material-framework/modelConfig/model.config";
import { MfRootSideDrawerService, MfSideDrawerRef } from "@material-framework/root/root.side.drawer.service";
import { MfTableHeaderLeftDef } from "@material-framework/table/header/table.header.left.def.directive";
import { MfTableHeaderRightDef } from "@material-framework/table/header/table.header.right.def.directive";
import { MfTableColumnsShowHideOrderComponent } from "@material-framework/table/showHideOrder/table.columns.show.hide.order.component";
import { MfTableFieldColumn, MfTableFilterExpressionEvent, MfTableFilterGroupEvent, MfTableGroupColumn, MfTableGroupColumnMovingEvent } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";
import { MfTableDataReloadTypes, MfTableFilterModeTypes, MfTableHeaderItem } from "@material-framework/table/table.types";
import { MfTableViewStateData } from "@material-framework/table/table.view.state.data";
import { MfViewState } from "@material-framework/viewManager/view.manager";
import { MfViewManagerInMemoryService } from "@material-framework/viewManager/view.manager.inmemory.service";
import { pulseAnimation } from "angular-animations";
import { Subscription } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "MfTableHeaderComponent" };

@Component({

  selector: "mf-table-header",
  templateUrl: "table.header.component.html",
  encapsulation: ViewEncapsulation.None,
  animations: [
    pulseAnimation({ anchor: "reloadDateButton", scale: 1.3 })
  ]
})
export class MfTableHeaderComponent<TFilter> extends MfBaseComponent implements OnDestroy {
  @ViewChild("headerContainer")
  public headerContainer?: HTMLDivElement;

  @Input()
  public shrinkSticky = false;

  @Input()
  public locationKey?: string;

  @Input()
  public viewState?: MfViewState<MfTableViewStateData<TFilter>>;

  @Input()
  public filterGroup?: MfFilterGroup;

  @Input()
  public modelFieldsConfig?: MfModelFieldsConfig;

  @Input({ transform: booleanAttribute })
  public get dataNeedsReload(): boolean | undefined {
    return this._dataNeedsReload;
  }
  public set dataNeedsReload(value: BooleanInput) {
    this._dataNeedsReload = coerceBooleanProperty(value);
    if (this._dataNeedsReload === true) {
      this._animationTimer = setInterval(() => {
        this._animationState = false;
        setTimeout(() => {
          this._animationState = true;
        }, 1);
      }, 1000);
    } else {
      clearInterval(this._animationTimer);
    }
  }

  @Input()
  public fieldColumns?: MfTableFieldColumn[];

  @Input()
  public groupColumns?: MfTableGroupColumn[];

  @Input()
  public headerLeftDef!: MfTableHeaderLeftDef;

  @Input()
  public headerRightDef!: MfTableHeaderRightDef;

  @Output()
  public onSelectedViewStateChanged: EventEmitter<MfViewState<MfTableViewStateData<TFilter>>> = new EventEmitter();

  @Output()
  public onViewStateBeforeUpdate: EventEmitter<MfViewState<MfTableViewStateData<TFilter>>> = new EventEmitter();

  @Output()
  public onFilterMenuClose: EventEmitter<void> = new EventEmitter();

  @Output()
  public onFilterChange: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterGroupChange: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterRemoveGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterAddGroup: EventEmitter<MfTableFilterGroupEvent> = new EventEmitter();

  @Output()
  public onFilterExpressionChange: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onFilterAddExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onFilterRemoveExpression: EventEmitter<MfTableFilterExpressionEvent> = new EventEmitter();

  @Output()
  public onGroupColumnMoving: EventEmitter<MfTableGroupColumnMovingEvent> = new EventEmitter();

  @Output()
  public onFieldColumnVisibleChanged: EventEmitter<MfTableFieldColumn> = new EventEmitter();

  @Output()
  public onFieldsColumnVisibleChanged: EventEmitter<MfTableFieldColumn[]> = new EventEmitter();

  @Output()
  public onReloadData: EventEmitter<void> = new EventEmitter();

  protected _tableFilterModeTypes = MfTableFilterModeTypes;
  protected _tableHeaderItem = MfTableHeaderItem;
  protected _animationState = false;
  protected _tableDataReloadTypesLength = mfTypeGetKeys(MfTableDataReloadTypes).length;
  protected _animationTimer?: any;
  protected _dataNeedsReload?: boolean;
  protected _sideDrawerRef?: MfSideDrawerRef<MfTableColumnsShowHideOrderComponent>;
  protected _sideDrawerOnAfterOpened?: Subscription;
  protected _sideDrawerOnAfterClosed?: Subscription;
  protected _sideDrawerOnColumnDropListDropped?: Subscription;
  protected _sideDrawerOnColumnVisibleChanged?: Subscription;
  protected _sideDrawerOnColumnsVisibleChanged?: Subscription;
  protected _sideDrawerOnClose?: Subscription;
  protected _viewManagerEnabled = false;

  public constructor(
    protected override _injector: Injector,
    protected _viewManagerService: MfViewManagerInMemoryService,
    protected _changeDetectorRef: ChangeDetectorRef,
    protected _rootSideDrawerService: MfRootSideDrawerService,
    protected _filterService: MfFilterService,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    protected _config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._animationState = true;
  }

  public override ngOnDestroy(): void {
    if (!mfTypeIsUndefined(this._sideDrawerRef)) {
      this._sideDrawerRef.close();
    }
  }

  protected _onReloadDataClicked(): void {
    this.onReloadData.emit();
  }

  protected _onFilterMenuClose(): void {
    if (!mfTypeIsUndefined(this.filterGroup)) {
      const count = this._filterService.removeEmptyExpressions(this.filterGroup);
      if (count > 0) {
        this.onFilterChange.emit({ group: this.filterGroup });
      }
    }
    this.onFilterMenuClose.emit();
  }

  protected _toggleShowHideOrder(): void {
    if (mfTypeIsUndefined(this._sideDrawerRef)) {
      this._sideDrawerRef = this._rootSideDrawerService.open(MfTableColumnsShowHideOrderComponent);
      this._sideDrawerOnAfterOpened = this._sub(this._sideDrawerRef.onAfterOpened, {
        next: (sideDrawerRef) => {
          if (!mfTypeIsUndefined(sideDrawerRef.componentRef)) {
            sideDrawerRef.componentRef.setInput("groupColumns", this.groupColumns);
            sideDrawerRef.componentRef.setInput("modelFieldsConfig", this.modelFieldsConfig);
            this._sideDrawerOnColumnDropListDropped = this._sub(
              sideDrawerRef.componentRef.instance.onGroupColumnMoving, { next: (event) => this.onGroupColumnMoving.emit(event) }
            );
            this._sideDrawerOnColumnVisibleChanged = this._sub(
              sideDrawerRef.componentRef.instance.onFieldColumnVisibleChanged, { next: (event) => this.onFieldColumnVisibleChanged.emit(event) }
            );
            this._sideDrawerOnColumnsVisibleChanged = this._sub(
              sideDrawerRef.componentRef.instance.onFieldsColumnVisibleChanged, { next: (event) => this.onFieldsColumnVisibleChanged.emit(event) }
            );
            this._sideDrawerOnClose = this._sub(
              sideDrawerRef.componentRef.instance.onClose, { next: () => sideDrawerRef.close() }
            );
          }
        }
      });
      this._sideDrawerOnAfterClosed = this._sub(this._sideDrawerRef.onAfterClosed, {
        next: () => {
          this._hideShowHideOrder();
        }
      });
    } else {
      this._sideDrawerRef.close();
      this._hideShowHideOrder();
    }
  }

  protected _hideShowHideOrder(): void {
    if (!mfTypeIsUndefined(this._sideDrawerRef) &&
      !mfTypeIsUndefined(this._sideDrawerOnAfterOpened) &&
      !mfTypeIsUndefined(this._sideDrawerOnAfterClosed) &&
      !mfTypeIsUndefined(this._sideDrawerOnColumnDropListDropped) &&
      !mfTypeIsUndefined(this._sideDrawerOnColumnVisibleChanged) &&
      !mfTypeIsUndefined(this._sideDrawerOnColumnsVisibleChanged) &&
      !mfTypeIsUndefined(this._sideDrawerOnClose)) {
      delete this._sideDrawerRef;

      this._unSub(this._sideDrawerOnAfterOpened);
      this._unSub(this._sideDrawerOnAfterClosed);
      this._unSub(this._sideDrawerOnColumnDropListDropped);
      this._unSub(this._sideDrawerOnColumnVisibleChanged);
      this._unSub(this._sideDrawerOnColumnsVisibleChanged);
      this._unSub(this._sideDrawerOnClose);

      delete this._sideDrawerOnAfterOpened;
      delete this._sideDrawerOnAfterClosed;
      delete this._sideDrawerOnColumnDropListDropped;
      delete this._sideDrawerOnColumnVisibleChanged;
      delete this._sideDrawerOnColumnsVisibleChanged;
      delete this._sideDrawerOnClose;
    }
  }
}