import { Component, Injector } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ECOM_BREADCRUMB_KEY_INVOICE_DETAILS } from "@e-commerce/ecom.route.keys";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfBreadcrumbService } from "@material-framework/breadcrumb/breadcrumb.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";

const TYPE_INFO: MfTypeInfo = { className: "EComInvoiceDetailOrdersComponent" };

@Component({
  selector: "ecom-invoice-detail-orders",
  templateUrl: "./invoice.detail.orders.component.html",
})
export class EComInvoiceDetailOrdersComponent extends MfBaseComponent {
  protected _documentKey?: string;
  protected _documentNumber?: string;

  public constructor(
    protected override _injector: Injector,
    protected _breadcrumbService: MfBreadcrumbService,
    protected _activatedRoute: ActivatedRoute,
  ) {
    super(TYPE_INFO, _injector);
    this._getRouteParams();
  }

  protected _getRouteParams(): void {
    this._sub(this._activatedRoute.params, {
      next: (params) => {
        const documentKey = params[EcomRouteNames.parameterNameDocumentKey];
        if (!mfTypeIsNullOrUndefined(documentKey)) {
          this._documentKey = documentKey;
        }
        const documentNumber = params[EcomRouteNames.parameterNameDocumentNumber];
        if (!mfTypeIsNullOrUndefined(documentNumber)) {
          this._documentNumber = documentNumber;
        }
        this._setBreadcrumbProductName();
      }
    });
  }

  protected _setBreadcrumbProductName(): void {
    if (!mfTypeIsUndefined(this._documentNumber)) {
      this._breadcrumbService.update(ECOM_BREADCRUMB_KEY_INVOICE_DETAILS, { label: `#${this._documentNumber}` });
    }
  }
}