<mf-root>
  <ng-container *mfHeaderMainLeftDef>
    <div class="ecom-logo">
      <img height="26px"
           (click)="_goHome()"
           src="/assets/incora-logo.svg" />
    </div>
  </ng-container>
  <ng-container *mfHeaderMainRightDef>
    <button mat-button
            *ngIf="_isApproved"
            (click)="_logIn()">Login</button>
  </ng-container>
  <ng-container *mfRootEndOfContentsDef>
    <mf-footer>
      <ecom-footer></ecom-footer>
    </mf-footer>
  </ng-container>
</mf-root>