import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Injector } from "@angular/core";
import { MfBaseSafeUnsubscribe } from "@material-framework/base/base.safe.unsubscribe";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MfFilterGroup } from "@material-framework/filter/filter";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfigMapped } from "@material-framework/modelConfig/model.config";
import { MfTableFieldColumn, MfTablePagination } from "@material-framework/table/table";
import { MfTableConfig } from "@material-framework/table/table.config";
import { Observable, Subject } from "rxjs";

export abstract class MfTableDataSource<TModel extends MfModelBase> extends MfBaseSafeUnsubscribe implements DataSource<TModel> {
  public isLoading: Subject<boolean> = new Subject();
  public hasData: Subject<boolean> = new Subject();
  public onDataLoaded: Subject<MfModelBase[]> = new Subject();
  public abstract data: TModel[];

  public constructor(
    protected override _typeInfo: MfTypeInfo,
    protected override _injector: Injector,
    protected _config: MfTableConfig,
  ) {
    super(_typeInfo, _injector);
  }

  public abstract connect(collectionViewer: CollectionViewer): Observable<readonly TModel[]>;
  public abstract disconnect(collectionViewer: CollectionViewer): void;
  public abstract load(
    pagination: MfTablePagination,
    group: MfFilterGroup,
    columns: MfTableFieldColumn[],
    modelConfig: MfModelConfigMapped,
    postModelConfig?: MfModelConfigMapped): void;
}