<div class="ecom-page-container">
  <div class="ecom-page-account-select">
    <ecom-account-select [disabled]="_isLoading"></ecom-account-select>
  </div>
  <mf-table #invoicesTable
            viewManagerEnabled="true"
            (onRowClicked)="_viewDetails($event)"
            [locationKey]="_locationKey"
            [modelConfig]="_invoiceModelConfig"
            [postModelConfig]="_invoiceRequestModelConfig">
    <ng-container *mfTableHeaderLeftDef>
      <div class="ecom-page-header">
        <div class="ecom-title">
          Invoices
        </div>
      </div>
    </ng-container>
    <ng-container mfTableColumnSlideOutActionsDef
                  stickyEnd="true">
      <ng-container *mfTableCellDef="let item"
                    contentAlign="center">
        <div class="mf-column mf-default-margin">
          <!-- <button mat-stroked-button
                  class="mf-default-margin-bottom"
                  (click)="_addToPay(item)">
            Add To Pay
          </button> -->
          <button mat-stroked-button
                  class="mf-default-margin-bottom"
                  (click)="_viewDetails(item)">
            View Details
          </button>
          <button mat-stroked-button
                  (click)="_viewInvoice(item)">
            Download Invoice
          </button>
        </div>
      </ng-container>
    </ng-container>
  </mf-table>
</div>