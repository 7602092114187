import { InjectionToken, Type } from "@angular/core";
import {
  faArrowDownUpAcrossLine, faArrowsLeftRight,
  faArrowsRotate,
  faArrowsUpDown,
  faChevronRight,
  faClose,
  faEllipsisVertical,
  faEye,
  faEyeSlash,
  faFilter,
  faSortDown,
  faSortUp,
  faTable,
  faTableColumns,
  faTrash,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import { MfTextAlignHorizontalTypes } from "@material-framework/common/types";
import { MfIcon } from "@material-framework/icon/icon";
import { MfIconTypes } from "@material-framework/icon/icon.types";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";
import { MfTableDataSource } from "@material-framework/table/dataSource/table.data.source";
import { MfTableRowCellBaseEditorComponent } from "@material-framework/table/row/cell/value/table.row.cell.base.editor.component";
import { MfTableRowCellBaseFormatterComponent } from "@material-framework/table/row/cell/value/table.row.cell.base.formatter.component";
import { MfTableSelectionManager } from "@material-framework/table/selectionManager/table.selection.manager";
import { MfTableRowCellValueBaseOptions } from "@material-framework/table/table";
import { MfTableDataReloadTypes, MfTableFilterModeTypes, MfTableHeaderItem } from "@material-framework/table/table.types";
import type * as css from "csstype";

export type MfTableCellValueFormatterTypes = string;

export const MF_TABLE_CONFIG_TOKEN = new InjectionToken<MfTableConfig>("MfTableConfig");
export const MF_TABLE_DEFAULT_CONFIG: MfTableConfig = {
  dataAutoReload: [MfTableDataReloadTypes.paginationChange, MfTableDataReloadTypes.sortChange, MfTableDataReloadTypes.viewChange, MfTableDataReloadTypes.visibleChange],
  filter: {
    mode: MfTableFilterModeTypes.tableHeader,
  },
  header: {
    reloadDateIcon: { icon: faArrowsRotate, type: MfIconTypes.fontAwesome, },
    reloadDateTooltip: "Reload data with applied filters and sort",
    showOptionsFilterTooltip: "Click to show sorting filtering and size options",
    showOptionsNoFilterTooltip: "Click to show sorting and size options",
    styles: {
      main: {},
      group: {},
      field: {},
      info: {},
    },
    layout: {
      left: [
        MfTableHeaderItem.reloadDateButton,
        MfTableHeaderItem.filter,
        MfTableHeaderItem.orderShowHideButton,
      ],
      right: [
        MfTableHeaderItem.viewManager,
      ]
    },
    filter: {
      openFilterIcon: { icon: faFilter, type: MfIconTypes.fontAwesome, },
      openFilterTooltip: "Open advanced table filter",
    },
    orderShowHide: {
      title: "Order & Show/Hide",
      closeIcon: { icon: faXmark, type: MfIconTypes.fontAwesome, },
      closeTooltip: "Close Order & Show/Hide",
      icon: { icon: faEye, type: MfIconTypes.fontAwesome, },
      tooltip: "Show hide and order columns",
      moveIcon: { icon: faArrowsUpDown, type: MfIconTypes.fontAwesome, },
      moveTooltip: "Drag and drop to reorder column",
      visibleIcon: { icon: faEye, type: MfIconTypes.fontAwesome, },
      showAllTooltip: "Show all group columns in the table",
      showTooltip: "Show the column in the table",
      hiddenIcon: { icon: faEyeSlash, type: MfIconTypes.fontAwesome, },
      hideAllTooltip: "Hide all group columns in the table",
      hideTooltip: "Hide the column in the table",
      stickIcon: { icon: faTableColumns, type: MfIconTypes.fontAwesome, },
      stickTooltip: "Freeze column",
      stickRemoveIcon: { icon: faTable, type: MfIconTypes.fontAwesome, },
      stickRemoveTooltip: "Unfreeze column",
    }
  },
  footer: {
    pagination: {
      pageSize: 50,
      pageSizeOptions: [10, 20, 30, 40, 50],
      showFirstButton: true,
      showLastButton: false,
      showRange: true,
    }
  },
  row: {
    header: {
      contentAlign: MfTextAlignHorizontalTypes.left,
      groupContentAlign: MfTextAlignHorizontalTypes.center,
      closeIcon: { icon: faClose, type: MfIconTypes.fontAwesome },
      sort: {
        displayName: "Sorting",
        clearDisplayName: "Clear sort",
        clearIcon: {
          icon: faTrash,
          type: MfIconTypes.fontAwesome,
        },
        clearTooltip: "Clear column sort",
        orderDisplayName: "Order",
        orderTooltip: "Set order columns are sorted",
        orderIcon: {
          icon: faArrowDownUpAcrossLine,
          type: MfIconTypes.fontAwesome,
        },
        ascIcon: {
          icon: faSortUp,
          type: MfIconTypes.fontAwesome,
        },
        descIcon: {
          icon: faSortDown,
          type: MfIconTypes.fontAwesome,
        },
        dataTypes: {
          array: {
            ascDisplayName: "",
            ascTooltip: "",
            descDisplayName: "",
            descTooltip: ""
          },
          boolean: {
            ascDisplayName: "Sort True to False",
            ascTooltip: "Sorted True to False",
            descDisplayName: "Sort False to True",
            descTooltip: "Sorted False to True"
          },
          byte: {
            ascDisplayName: "Sort Smallest to Largest",
            ascTooltip: "Sorted Smallest to Largest",
            descDisplayName: "Sort Largest to Smallest",
            descTooltip: "Sorted Largest to Smallest"
          },
          date: {
            ascDisplayName: "Sort Oldest to Newest",
            ascTooltip: "Sorted Oldest to Newest",
            descDisplayName: "Sort Newest to Oldest",
            descTooltip: "Sorted Newest to Oldest"
          },
          dateTime: {
            ascDisplayName: "Sort Oldest to Newest",
            ascTooltip: "Sorted Oldest to Newest",
            descDisplayName: "Sort Newest to Oldest",
            descTooltip: "Sorted Newest to Oldest"
          },
          decimal: {
            ascDisplayName: "Sort Smallest to Largest",
            ascTooltip: "Sorted Smallest to Largest",
            descDisplayName: "Sort Largest to Smallest",
            descTooltip: "Sorted Largest to Smallest"
          },
          double: {
            ascDisplayName: "Sort Smallest to Largest",
            ascTooltip: "Sorted Smallest to Largest",
            descDisplayName: "Sort Largest to Smallest",
            descTooltip: "Sorted Largest to Smallest"
          },
          float: {
            ascDisplayName: "Sort Smallest to Largest",
            ascTooltip: "Sorted Smallest to Largest",
            descDisplayName: "Sort Largest to Smallest",
            descTooltip: "Sorted Largest to Smallest"
          },
          int: {
            ascDisplayName: "Sort Smallest to Largest",
            ascTooltip: "Sorted Smallest to Largest",
            descDisplayName: "Sort Largest to Smallest",
            descTooltip: "Sorted Largest to Smallest"
          },
          long: {
            ascDisplayName: "Sort Smallest to Largest",
            ascTooltip: "Sorted Smallest to Largest",
            descDisplayName: "Sort Largest to Smallest",
            descTooltip: "Sorted Largest to Smallest"
          },
          object: {
            ascDisplayName: "",
            ascTooltip: "",
            descDisplayName: "",
            descTooltip: ""
          },
          sbyte: {
            ascDisplayName: "Sort Smallest to Largest",
            ascTooltip: "Sorted Smallest to Largest",
            descDisplayName: "Sort Largest to Smallest",
            descTooltip: "Sorted Largest to Smallest"
          },
          short: {
            ascDisplayName: "Sort Smallest to Largest",
            ascTooltip: "Sorted Smallest to Largest",
            descDisplayName: "Sort Largest to Smallest",
            descTooltip: "Sorted Largest to Smallest"
          },
          string: {
            ascDisplayName: "Sort A to Z",
            ascTooltip: "Sorted A to Z",
            descDisplayName: "Sort Z to A",
            descTooltip: "Sorted Z to A"
          },
          uint: {
            ascDisplayName: "Sort Smallest to Largest",
            ascTooltip: "Sorted Smallest to Largest",
            descDisplayName: "Sort Largest to Smallest",
            descTooltip: "Sorted Largest to Smallest"
          },
          ulong: {
            ascDisplayName: "Sort Smallest to Largest",
            ascTooltip: "Sorted Smallest to Largest",
            descDisplayName: "Sort Largest to Smallest",
            descTooltip: "Sorted Largest to Smallest"
          },
          ushort: {
            ascDisplayName: "Sort Smallest to Largest",
            ascTooltip: "Sorted Smallest to Largest",
            descDisplayName: "Sort Largest to Smallest",
            descTooltip: "Sorted Largest to Smallest"
          }
        },
      },
      filter: {
        displayName: "Filter",
        hasFilterIcon: {
          icon: faFilter,
          type: MfIconTypes.fontAwesome,
        },
        hasFilterTooltip: "Column has filter applied",
        filterAppliedColor: "#000000",
        filterUnappliedColor: "#0000002b",
      },
      resize: {
        displayName: "Width",
        maxWidth: 500,
        minWidth: 60,
        maxDisplayName: "Max",
        minDisplayName: "Min",
      },
      move: {
        grabIcon: {
          icon: faArrowsLeftRight,
          type: MfIconTypes.fontAwesome,
        },
        grabTooltip: "Drag and drop to move column"
      }
    },
    cell: {
      contentAlign: MfTextAlignHorizontalTypes.left,
      actions: {
        menuIcon: { icon: faEllipsisVertical, type: MfIconTypes.fontAwesome },
        tooltip: "Show row actions",
      }
    },
    expand: {
      expandIcon: { type: MfIconTypes.materialIcons, icon: "expand_more" },
      collapseIcon: { type: MfIconTypes.materialIcons, icon: "expand_less" },
      expandButtonTooltip: "Show additional data",
      collapseButtonTooltip: "Hide additional data",
    },
    clickAction: {
      icon: { type: MfIconTypes.fontAwesome, icon: faChevronRight },
    }
  },
  selectionManager: {

  },
  dataSource: {

  },
};

export type MfTableCellFormatterModelDataTypeConfig = {
  types: MfModelFieldDataTypes[],
  formatter: MfTableCellValueFormatterTypes;
}

export type MfTableCellFormatterTypesConfig =
  MfTableRowCellBaseFormatterComponent<MfTableRowCellValueBaseOptions> |
  MfTableRowCellBaseEditorComponent<MfTableRowCellValueBaseOptions, MfModelBase>;

export type MfTableCellFormatterConfig = {
  [formatterKey: string]: Type<MfTableCellFormatterTypesConfig>;
}

export type MfTableConfig = {
  dataSourceType?: Type<MfTableDataSource<MfModelBase>>;
  selectionManagerType?: Type<MfTableSelectionManager<MfModelBase>>;
  dataAutoReload: MfTableDataReloadTypes[];
  header: MfTableHeaderConfig;
  footer: MfTableFooterConfig;
  row: MfTableRowConfig;
  selectionManager: MfTableSelectionManagerConfig;
  dataSource: MfTableDataSourceConfig;
  filter: MfTableFilterConfig;
}

export type MfTableFilterConfig = {
  mode: MfTableFilterModeTypes,
};

export type MfTableSelectionManagerConfig = {
  pageSize?: number;
}

export type MfTableDataSourceConfig = {
};

export type MfTableHeaderConfig = {
  filter: MfTableHeaderFilterConfig,
  reloadDateIcon: MfIcon,
  reloadDateTooltip: string;
  orderShowHide: MfTableHeaderOrderShowHideConfig;
  layout: MfTableHeaderLayoutConfig;
  styles: MfTableHeaderStylesConfig;
  showOptionsFilterTooltip: string,
  showOptionsNoFilterTooltip: string,
}

export type MfTableHeaderLayoutConfig = {
  left: MfTableHeaderItem[],
  right: MfTableHeaderItem[],
}

export type MfTableHeaderStylesConfig = {
  main: css.Properties,
  group: css.Properties,
  field: css.Properties,
  info: css.Properties,
}

export type MfTableHeaderOrderShowHideConfig = {
  title: string;
  closeIcon: MfIcon;
  closeTooltip: string;
  icon: MfIcon;
  tooltip: string;
  moveIcon: MfIcon;
  moveTooltip: string;
  visibleIcon: MfIcon;
  showTooltip: string;
  hiddenIcon: MfIcon;
  hideTooltip: string;
  hideAllTooltip: string;
  showAllTooltip: string;
  stickIcon: MfIcon;
  stickTooltip: string;
  stickRemoveIcon: MfIcon;
  stickRemoveTooltip: string;
}

export type MfTableFooterConfig = {
  pagination: MfTableFooterPaginationConfig
}

export type MfTableFooterPaginationConfig = {
  pageSize: number;
  pageSizeOptions: number[],
  showFirstButton: boolean,
  showLastButton: boolean,
  showRange: boolean,
}

export type MfTableHeaderFilterConfig = {
  openFilterTooltip: string;
  openFilterIcon: MfIcon;
}

export type MfTableHeaderViewManagerConfig = {
  saveButtonTooltip: string;
  saveAsButtonTooltip: string;
  deleteButtonTooltip: string;
}

export type MfTableColumnConfig = {
  resize: MfTableColumnResizeConfig;
}

export type MfTableColumnResizeConfig = {
  displayName: string;
  minWidth: number;
  maxWidth: number;
  minDisplayName: string;
  maxDisplayName: string;
}

export type MfTableColumnMoveConfig = {
  grabIcon: MfIcon;
  grabTooltip: string;
}

export type MfTableRowConfig = {
  header: MfTableRowHeaderConfig;
  cell: MfTableRowCellConfig;
  expand: MfTableRowExpandConfig;
  clickAction: MfTableRowClickActionConfig;
}

export type MfTableRowClickActionConfig = {
  icon: MfIcon,
  tooltip?: string,
}

export type MfTableRowExpandConfig = {
  expandIcon: MfIcon,
  collapseIcon: MfIcon,
  expandButtonTooltip: string,
  collapseButtonTooltip: string,
}

export type MfTableRowCellConfig = {
  actions: MfTableRowCellActionsConfig;
  contentAlign: MfTextAlignHorizontalTypes;
}

export type MfTableRowCellActionsConfig = {
  menuIcon: MfIcon;
  tooltip: string;
}

export type MfTableRowHeaderConfig = {
  sort: MfTableRowHeaderSortConfig;
  filter: MfTableRowHeaderFilterConfig;
  resize: MfTableColumnResizeConfig;
  move: MfTableColumnMoveConfig;
  contentAlign: MfTextAlignHorizontalTypes;
  groupContentAlign: MfTextAlignHorizontalTypes;
  closeIcon: MfIcon;
}

export type MfTableRowHeaderSortConfig = {
  displayName: string;
  ascIcon: MfIcon;
  descIcon: MfIcon;
  clearDisplayName: string;
  clearIcon: MfIcon;
  clearTooltip: string;
  orderDisplayName: string;
  orderTooltip: string;
  orderIcon: MfIcon;
  dataTypes: MfTableRowHeaderSortDataTypesConfig;
}

export type MfTableRowHeaderFilterConfig = {
  displayName: string;
  hasFilterIcon: MfIcon,
  hasFilterTooltip: string;
  filterUnappliedColor: string;
  filterAppliedColor: string;
}

export type MfTableRowHeaderSortDataTypesConfig = {
  [K in keyof typeof MfModelFieldDataTypes]: MfTableRowHeaderSortDataTypeConfig;
}

export type MfTableRowHeaderSortDataTypeConfig = {
  ascDisplayName: string;
  descDisplayName: string;
  ascTooltip: string;
  descTooltip: string;
}