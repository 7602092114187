import { Injectable, Injector } from "@angular/core";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfError } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfNumberIsNumberConvert } from "@material-framework/common/utils/number.utils";
import { mfStringIsEmptyOrWhitespace } from "@material-framework/common/utils/string.utils";
import { mfTypeGetKeys, mfTypeIsString, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfFilterExpression, MfFilterGroup } from "@material-framework/filter/filter";
import { MfFilterOperatorTypes } from "@material-framework/filter/filter.types";
import { MfFilterConverterService } from "@material-framework/filter/services/filter.loader";
import { MfFilterService } from "@material-framework/filter/services/filter.service";
import { MfGraphQlFilter } from "@material-framework/graphQL/filter/graph.ql.filter";
import { MFModelConfigFieldPath, MfModelFieldConfigMapped, mfModelFieldDataTypeIsNumber, MfModelFieldsConfigMapped } from "@material-framework/modelConfig/model.config";
import { MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR, MfModelConfigService } from "@material-framework/modelConfig/model.config.service";

const TYPE_INFO: MfTypeInfo = { className: "MfGraphQLFilterConverterService" };

@Injectable()
export class MfGraphQLFilterConverterService extends MfBaseService implements MfFilterConverterService<MfGraphQlFilter> {
  public constructor(
    protected _filterService: MfFilterService,
    protected _modelConfigService: MfModelConfigService,
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }

  public toGroup(graphQLFilter: MfGraphQlFilter, modelConfigOrModelKey: string | MfModelFieldsConfigMapped): MfFilterGroup {
    const modelConfig = mfTypeIsString(modelConfigOrModelKey) ? this._modelConfigService.get(modelConfigOrModelKey).fields : modelConfigOrModelKey;
    return this._graphQLFiltersToGroup(graphQLFilter, modelConfig, undefined);
  }

  public fromGroup(group: MfFilterGroup): MfGraphQlFilter {
    return this._groupToGraphQLFilters(group);
  }

  protected _graphQLFiltersToExpressions(mfQlFilters: MfGraphQlFilter[], modelFieldsConfig: MfModelFieldsConfigMapped, parentFieldPath?: string): MfFilterExpression[] {
    const expressions = [];
    const mfQlExpressions = mfQlFilters.filter(i => mfTypeIsUndefined(i.or) && mfTypeIsUndefined(i.and));
    const mfQlExpressionsLength = mfQlExpressions.length;
    for (let mfQlExpressionIndex = 0; mfQlExpressionIndex < mfQlExpressionsLength; mfQlExpressionIndex++) {
      const expression = this._graphQLFiltersToExpression(mfQlFilters[mfQlExpressionIndex], modelFieldsConfig, parentFieldPath);
      if (!mfTypeIsUndefined(expression)) {
        expressions.push(expression);
      }
    }
    return expressions;
  }

  protected _graphQLFiltersToExpression(mfQlFilter: MfGraphQlFilter, modelFieldsConfig: MfModelFieldsConfigMapped, parentFieldPath?: MFModelConfigFieldPath): MfFilterExpression | undefined {
    const fieldKey = mfTypeGetKeys(mfQlFilter)[0];
    const fieldFilter = mfQlFilter[fieldKey] as any;
    const fieldPath = mfTypeIsUndefined(parentFieldPath) ? fieldKey : `${parentFieldPath}.${fieldKey}`;
    const modelFieldConfig = this._modelConfigService.findModelFieldConfigByFieldPath(modelFieldsConfig, fieldPath);

    if (!mfTypeIsUndefined(modelFieldConfig)) {
      if (!mfTypeIsUndefined(modelFieldConfig.filter) && !mfTypeIsUndefined(modelFieldConfig.filter.flatten)) {
        return this._graphQLFiltersToExpressionFlattened(mfQlFilter[fieldKey] as any, modelFieldConfig);
      } else {
        if (!mfTypeIsUndefined(fieldFilter.or) || !mfTypeIsUndefined(fieldFilter.and)) {
          return this._filterService.getNewFilterExpression(fieldKey, fieldPath, MfFilterOperatorTypes.none, "", modelFieldConfig, this._graphQLFiltersToGroup(fieldFilter, modelFieldsConfig, fieldPath));
        } else {
          if (!mfTypeIsUndefined(fieldFilter)) {
            const operatorKey = mfTypeGetKeys(fieldFilter)[0] as MfFilterOperatorTypes;
            if (!mfTypeIsUndefined(operatorKey)) {
              if (operatorKey === MfFilterOperatorTypes.all || operatorKey === MfFilterOperatorTypes.none || operatorKey === MfFilterOperatorTypes.some) {
                return this._filterService.getNewFilterExpression(fieldKey, fieldPath, operatorKey, fieldFilter[operatorKey], modelFieldConfig, this._graphQLFiltersToGroup(fieldFilter[operatorKey], modelFieldsConfig, fieldPath));
              } else {
                return this._filterService.getNewFilterExpression(fieldKey, fieldPath, operatorKey, fieldFilter[operatorKey], modelFieldConfig);
              }
            }
          }
        }
      }
    } else {
      throw new MfError(this.typeInfo, "_graphQLFiltersToExpression", `No modelFieldConfig found for fieldPath:${fieldPath}`);
    }
    return;
  }

  protected _graphQLFiltersToExpressionFlattened(mfQlFilter: MfGraphQlFilter, modelFieldConfig: MfModelFieldConfigMapped): MfFilterExpression | undefined {
    const andOrs = mfQlFilter.and || mfQlFilter.or;
    if (!mfTypeIsUndefined(andOrs)) {
      const andOrsLength = andOrs.length;
      for (let andOrIndex = 0; andOrIndex < andOrsLength; andOrIndex++) {
        const andOr = andOrs[andOrIndex];
        const andOrKeys = mfTypeGetKeys(andOr);
        const andOrKeysLength = andOrKeys.length;
        for (let andOrKeyIndex = 0; andOrKeyIndex < andOrKeysLength; andOrKeyIndex++) {
          const andOrKey = andOrKeys[andOrKeyIndex];
          const fieldFilter = andOr[andOrKey] as any;
          const operatorKey = mfTypeGetKeys(fieldFilter)[0] as MfFilterOperatorTypes;

          if (!mfTypeIsUndefined(modelFieldConfig.model) && !mfTypeIsUndefined(modelFieldConfig.model.fields)) {
            const expModelFieldConfig = this._modelConfigService.getModelFieldConfigByFieldKey(modelFieldConfig.model.fields, andOrKey);
            if (!mfTypeIsUndefined(expModelFieldConfig) && !mfTypeIsUndefined(expModelFieldConfig.fieldPath) && !mfTypeIsUndefined(expModelFieldConfig.filter) && !mfTypeIsUndefined(expModelFieldConfig.filter.flatten) && !mfTypeIsUndefined(expModelFieldConfig.filter.flatten.fromFieldPath)) {
              return this._filterService.getNewFilterExpression(andOrKey, expModelFieldConfig.fieldPath, operatorKey, fieldFilter[operatorKey], expModelFieldConfig);
            } else if (!mfTypeIsUndefined(expModelFieldConfig.model) && !mfTypeIsUndefined(expModelFieldConfig.model.fields)) {
              const expression = this._graphQLFiltersToExpressionFlattened(fieldFilter, expModelFieldConfig);
              if (!mfTypeIsUndefined(expression)) {
                return expression;
              }
            }
          }
        }
      }
    }
    return;
  }

  protected _graphQLFiltersToGroups(mfQlFilters: MfGraphQlFilter[], modelFieldsConfig: MfModelFieldsConfigMapped, parentFieldPath?: MFModelConfigFieldPath): MfFilterGroup[] {
    const groups = [];
    const mfQlGroups = mfQlFilters.filter(i => !mfTypeIsUndefined(i.or) || !mfTypeIsUndefined(i.and));
    const mfQlGroupsLength = mfQlGroups.length;

    for (let mfQlGroupIndex = 0; mfQlGroupIndex < mfQlGroupsLength; mfQlGroupIndex++) {
      const mfQlGroup = mfQlGroups[mfQlGroupIndex];
      groups.push(this._graphQLFiltersToGroup(mfQlGroup, modelFieldsConfig, parentFieldPath));
    }
    return groups;
  }

  protected _graphQLFiltersToGroup(mfQlFilter: MfGraphQlFilter, modelFieldsConfig: MfModelFieldsConfigMapped, parentFieldPath?: MFModelConfigFieldPath): MfFilterGroup {
    const group = this._filterService.getNewFilterGroup();
    if (!mfTypeIsUndefined(mfQlFilter.and)) {
      group.andOr = "and";
      group.expressions = this._graphQLFiltersToExpressions(mfQlFilter.and, modelFieldsConfig, parentFieldPath);
      group.groups = this._graphQLFiltersToGroups(mfQlFilter.and, modelFieldsConfig, parentFieldPath);
    } else if (!mfTypeIsUndefined(mfQlFilter.or)) {
      group.andOr = "or";
      group.expressions = this._graphQLFiltersToExpressions(mfQlFilter.or, modelFieldsConfig, parentFieldPath);
      group.groups = this._graphQLFiltersToGroups(mfQlFilter.or, modelFieldsConfig, parentFieldPath);
    }
    return group;
  }

  protected _expressionsToGraphQLFilters(expressions: MfFilterExpression[]): MfGraphQlFilter[] {
    const filters = [];

    const expressionsLength = expressions.length;
    for (let expressionIndex = 0; expressionIndex < expressionsLength; expressionIndex++) {
      filters.push(this._expressionToGraphQLFilters(expressions[expressionIndex]));
    }

    return filters;
  }

  protected _expressionToGraphQLFilters(expression: MfFilterExpression): MfGraphQlFilter {
    // ToDo: this need to be added to toGroup
    if (!mfTypeIsUndefined(expression.modelFieldConfig.filter) && !mfTypeIsUndefined(expression.modelFieldConfig.filter.flatten) && !mfTypeIsUndefined(expression.modelFieldConfig.filter.flatten.fromFieldPath)) {
      return this._expressionToGraphQLFiltersFlattened(expression);
    } else {
      if (!mfTypeIsUndefined(expression.modelFieldConfig.graphQl) && !mfTypeIsUndefined(expression.modelFieldConfig.graphQl.alias)) {
        const alias = expression.modelFieldConfig.graphQl.alias;
        return { [alias.from]: { and: [{ [expression.operatorKey]: this._getExpressionValue(expression) }, { some: expression.modelFieldConfig.graphQl.alias.filter }] } };
      } else {
        if (!mfTypeIsUndefined(expression.subGroup)) {
          if (!mfStringIsEmptyOrWhitespace(expression.operatorKey)) {
            return { [expression.fieldKey]: { [expression.operatorKey]: this._groupToGraphQLFilters(expression.subGroup) } };
          } else {
            return { [expression.fieldKey]: this._groupToGraphQLFilters(expression.subGroup) };
          }
        } else {
          return { [expression.fieldKey]: { [expression.operatorKey]: this._getExpressionValue(expression) } };
        }
      }
    }
  }

  protected _expressionToGraphQLFiltersFlattened(expression: MfFilterExpression): MfGraphQlFilter {
    if (!mfTypeIsUndefined(expression.modelFieldConfig.filter) && !mfTypeIsUndefined(expression.modelFieldConfig.filter.flatten) && !mfTypeIsUndefined(expression.modelFieldConfig.filter.flatten.fromFieldPath)) {
      const fromFieldPath = expression.modelFieldConfig.filter.flatten.fromFieldPath;
      const parts = fromFieldPath.split(MF_MODEL_CONFIG_FIELD_PATH_SEPARATOR);
      const partsLength = parts.length;

      let filter: any = { [parts[0]]: { and: [] } };
      let lastFilterAnd = filter[parts[0]].and;
      for (let partIndex = 1; partIndex < partsLength; partIndex++) {
        const part = parts[partIndex];
        const nextFilter = { [part]: { and: [] } };
        lastFilterAnd.push(nextFilter);
        lastFilterAnd = nextFilter[part].and;
      }

      lastFilterAnd.push({ [expression.fieldKey]: { [expression.operatorKey]: this._getExpressionValue(expression) } });


      return filter;
    }

    throw new MfError(this.typeInfo, "_expressionToGraphQLFiltersFlattened", "No modelFieldConfig filter or flatten or fromFieldPath");
  }

  protected _groupsToGraphQLFilters(groups: MfFilterGroup[]): MfGraphQlFilter[] {
    const filters = [];

    const groupsLength = groups.length;
    for (let groupIndex = 0; groupIndex < groupsLength; groupIndex++) {
      filters.push(this._groupToGraphQLFilters(groups[groupIndex]));
    }

    return filters;
  }

  protected _groupToGraphQLFilters(group: MfFilterGroup): MfGraphQlFilter {
    const filter = {} as any;

    filter[group.andOr] = [...this._expressionsToGraphQLFilters(group.expressions), ...this._groupsToGraphQLFilters(group.groups)];

    return filter;
  }

  protected _getExpressionValue(expression: MfFilterExpression): unknown {
    if (mfModelFieldDataTypeIsNumber(expression.modelFieldConfig.dataType.type)) {
      return mfNumberIsNumberConvert(expression.value);
    } else {
      return expression.value;
    }
  }
}