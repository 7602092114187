export const ECOM_BREADCRUMB_KEY_CART = "cart" as const;
export const ECOM_BREADCRUMB_KEY_CHECKOUT = "checkout" as const;
export const ECOM_BREADCRUMB_KEY_CONFIRMATION = "confirmation" as const;
export const ECOM_BREADCRUMB_KEY_HOME = "home" as const;
export const ECOM_BREADCRUMB_KEY_INVOICES = "invoices" as const;
export const ECOM_BREADCRUMB_KEY_ORDERS = "orders" as const;
export const ECOM_BREADCRUMB_KEY_PRODUCT_DETAILS = "product-details" as const;
export const ECOM_BREADCRUMB_KEY_PRODUCTS = "products" as const;
export const ECOM_BREADCRUMB_KEY_PROFILE = "profile" as const;
export const ECOM_BREADCRUMB_KEY_SHOP = "shop" as const;
export const ECOM_BREADCRUMB_KEY_INVOICE_DETAILS = "invoice-details" as const;