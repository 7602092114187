import { Component, Inject, Injector, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { EComCartModes } from "@e-commerce/common/cart.modes";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { EComCartService } from "@e-commerce/services/cart.service";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfIcon } from "@material-framework/icon/icon";

const TYPE_INFO: MfTypeInfo = { className: "EComCartPreviewComponent" };

@Component({
  selector: "ecom-cart-preview",
  templateUrl: "./cart.preview.component.html",
  styleUrls: ["./cart.preview.component.scss"]
})
export class EComCartPreviewComponent extends MfBaseComponent implements OnInit {
  protected _cartIcon?: MfIcon;
  protected _mode?: EComCartModes;
  protected _count: number = 0;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _cartService: EComCartService,
    protected _customerService: EComCustomerService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _config: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._setMode(this._cartService.mode);

    this._sub(this._cartService.onCartModeChanged, { next: (mode) => { this._setMode(mode); this._setCount(); } });
    this._sub(this._cartService.onCountChanged, { next: () => this._setCount() });
    this._sub(this._customerService.onSelectedChange, { next: () => this._count = 0 });
  }

  public ngOnInit(): void {
    this._setCount();
  }

  protected _goToCart(): void {
    if (!mfTypeIsUndefined(this._mode)) {
      switch (this._mode) {
        case EComCartModes.order:
          this._router.navigate([EcomRouteNames.routeShopCartList]);
          break;
        case EComCartModes.invoice:
          this._router.navigate([EcomRouteNames.routeInvoiceCartList]);
          break;
      }
    }
  }

  protected _setCount(): void {
    this._sub(this._cartService.count, { next: (count) => this._count = count });
  }

  protected _setMode(mode: EComCartModes): void {
    this._mode = mode;
    switch (mode) {
      case EComCartModes.order:
        this._cartIcon = this._config.cartIcon;
        break;
      case EComCartModes.invoice:
        this._cartIcon = this._config.payIcon;
        break;
    }
  }
}