<div class="ecom-footer-container">
  <div class="mf-large-margin-left ecom-footer-item">
    © {{ _mfDateCurrent().getFullYear() }} Wesco Aircraft Hardware Corp., dba Incora. All rights reserved.
  </div>
  <div class="ecom-footer-item">
    <a mat-button
       [href]="_linksConfig.privacyPolicyUrl"
       target="_blank">Privacy Policy</a>
    <a mat-button
       [href]="_linksConfig.cookiesSettingsUrl"
       target="_blank">Cookies Settings</a>
    <a mat-button
       [href]="_linksConfig.termsOfServiceUrl"
       target="_blank">Terms of Service</a>
  </div>
  <div class="mf-large-margin-right ecom-footer-item">
    <a mat-icon-button
       [href]="_linksConfig.facebookUrl"
       target="_blank">
      <mf-icon [icon]="_iconsConfig.facebookIcon"></mf-icon>
    </a>
    <a class="mf-default-margin-left"
       mat-icon-button
       [href]="_linksConfig.xUrl"
       target="_blank">
      <mf-icon [icon]="_iconsConfig.xIcon"></mf-icon>
    </a>
    <a class="mf-default-margin-left"
       mat-icon-button
       [href]="_linksConfig.linkedInUrl"
       target="_blank">
      <mf-icon [icon]="_iconsConfig.linkedInIcon"></mf-icon>
    </a>
  </div>
</div>