import { ComponentType } from "@angular/cdk/portal";
import { ComponentRef, Inject, Injectable, Injector } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfError } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined, mfTypeIsNullOrUndefined, mfTypeGetKeys } from "@material-framework/common/utils/type.utils";
import { MfDialogData, MfDialogResult, MfDialogOkCancelData, MfDialogInputValue } from "@material-framework/dialog/dialog";
import { MF_DIALOG_CONFIG_TOKEN, MfDialogConfig } from "@material-framework/dialog/dialog.config";
import { MfDialogHostBaseComponent } from "@material-framework/dialog/dialog.host.base.component";
import { MfDialogOkCancelComponent } from "@material-framework/dialog/dialog.ok.cancel.component";

const TYPE_INFO: MfTypeInfo = { className: "MfDialogService" };

@Injectable()
export class MfDialogService extends MfBaseService {
  public constructor(
    protected override _injector: Injector,
    protected _dialog: MatDialog,
    @Inject(MF_DIALOG_CONFIG_TOKEN)
    protected _config: MfDialogConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public openRightPanel<T extends MfBaseComponent, D extends MfDialogData, R extends MfDialogResult>(component: ComponentType<T>, config?: MatDialogConfig<D>): MatDialogRef<T, R> {
    if (mfTypeIsUndefined(config)) {
      config = {};
    }
    config.position = {
      top: "0px",
      right: "0px",
      bottom: "0px",
    };
    return this._dialog.open(component, config);
  }

  public openOkCancel<TContentComponent extends MfDialogHostBaseComponent>(config: MatDialogConfig<MfDialogOkCancelData<TContentComponent>>): MatDialogRef<MfDialogOkCancelComponent<TContentComponent>> {
    if (!mfTypeIsNullOrUndefined(config.data)) {
      if (mfTypeIsUndefined(config.data.cancelDisplayName)) {
        config.data.cancelDisplayName = this._config.okCancel.cancelDisplayName;
      }
      if (mfTypeIsUndefined(config.data.okDisplayName)) {
        config.data.okDisplayName = this._config.okCancel.okDisplayName;
      }

      const componentType = MfDialogOkCancelComponent<TContentComponent>;
      const dialogRef = this._dialog.open(componentType, config);

      this._sub(dialogRef.beforeClosed(), {
        next: () => {
          if (!mfTypeIsUndefined(dialogRef.componentInstance) &&
            !mfTypeIsUndefined(dialogRef.componentInstance.contentComponentRef) &&
            !mfTypeIsUndefined(dialogRef.componentInstance.contentComponentRef.instance) &&
            !mfTypeIsUndefined(dialogRef.componentInstance.contentComponentRef.instance.onDialogClosing)) {
            dialogRef.componentInstance.contentComponentRef.instance.onDialogClosing();
          }
        }
      });

      this._sub(dialogRef.afterOpened(), {
        next: () => {
          if (!mfTypeIsNullOrUndefined(config.data) && !mfTypeIsUndefined(config.data.inputs) && !mfTypeIsUndefined(dialogRef.componentInstance.contentComponentRef)) {
            this._setInputBindings(config.data.inputs, dialogRef.componentInstance.contentComponentRef);
          }
        }
      });

      this._sub(dialogRef.afterClosed(), {
        next: (result) => {
          if (!mfTypeIsNullOrUndefined(config.data) && !mfTypeIsUndefined(config.data.outputs) && !mfTypeIsUndefined(dialogRef.componentInstance.contentComponentRef)) {
            result.outputs = {};
            this._getInputBindings(result.outputs, config.data.outputs, dialogRef.componentInstance.contentComponentRef);
          }
        }
      });

      return dialogRef;
    }
    throw new MfError(this.typeInfo, "openOkCancel", "config.data is undefined");
  }

  protected _setInputBindings(inputs: MfDialogInputValue, componentRef: ComponentRef<MfDialogHostBaseComponent>): void {
    const inputKeys = mfTypeGetKeys(inputs);
    const inputKeysLength = inputKeys.length;
    for (let inputKeyIndex = 0; inputKeyIndex < inputKeysLength; inputKeyIndex++) {
      const inputKey = inputKeys[inputKeyIndex] as string;
      componentRef.setInput(inputKey, inputs[inputKey]);
    }
  }

  protected _getInputBindings<TComponent extends MfDialogHostBaseComponent = MfDialogHostBaseComponent>(returnInputs: MfDialogInputValue, outputs: (keyof TComponent)[], componentRef: ComponentRef<MfDialogHostBaseComponent>): void {
    const inputKeys = outputs;
    const inputKeysLength = inputKeys.length;
    for (let inputKeyIndex = 0; inputKeyIndex < inputKeysLength; inputKeyIndex++) {
      const inputKey = inputKeys[inputKeyIndex] as keyof MfDialogHostBaseComponent;
      returnInputs[inputKey] = componentRef.instance[inputKey];
    }
  }
}