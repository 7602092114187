import { Directive, Injector, OnDestroy, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RouterOutlet } from "@angular/router";
import { EComCheckoutBasModel } from "@e-commerce/checkout/base/checkout";
import { EComCheckoutStepBaseComponent } from "@e-commerce/checkout/base/checkout.step.base.component";
import { EComCheckoutStepValidationBaseComponent } from "@e-commerce/checkout/base/checkout.step.validation.base.component";
import { EComCheckoutSummaryComponent } from "@e-commerce/checkout/summary/checkout.summary.component";
import { EcomRouteNames } from "@e-commerce/ecom.route.names";
import { EComRootService } from "@e-commerce/root/authn/root.authn.service";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";

@Directive()
export abstract class EComCheckoutBaseComponent extends MfBaseComponent implements OnDestroy {
  @ViewChild(RouterOutlet)
  protected get _routerOutlet(): RouterOutlet | undefined {
    return this._routerOutletInt;
  }
  protected set _routerOutlet(value: RouterOutlet | undefined) {
    this._routerOutletInt = value;
  }

  @ViewChild(EComCheckoutSummaryComponent)
  protected _orderSummaryComponent?: EComCheckoutSummaryComponent;

  protected _routerOutletComponent?: EComCheckoutStepBaseComponent<EComCheckoutBasModel> | EComCheckoutStepValidationBaseComponent<EComCheckoutBasModel>;
  protected _ecomRouteNames = EcomRouteNames;
  protected _formGroup?: FormGroup;
  protected _summaryButtonLabel: string = "Continue";
  protected _summaryShowTC: boolean = false;
  protected _errorColor = "#FF0000";

  private _routerOutletInt?: RouterOutlet;

  public constructor(
    protected override _typeInfo: MfTypeInfo,
    protected override _injector: Injector,
    protected _rootService: EComRootService,
  ) {
    super(_typeInfo, _injector);
    this._rootService.showFooter = false;
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._rootService.showFooter = true;
  }

  protected _routerActivate(): void {
    if (!mfTypeIsUndefined(this._routerOutlet) && this._routerOutlet.isActivated === true) {
      this._setComponentChangeStates(this._routerOutlet);
    }
  }

  protected abstract _setComponentChangeStates(routerOutlet: RouterOutlet): void;
}