<div class="ecom-confirmation-container">
  <div class="ecom-confirmation">
    <mat-card>
      <mat-card-content>
        <div class="mf-row ecom-content-center">
          <mf-icon [icon]="_iconsConfig.orderCompleteTickIcon"
                   [size]="40"
                   color="#6EC6AA"></mf-icon>
        </div>
      </mat-card-content>
      <mat-card-title>
        <div class="mf-row mf-x-large-margin-top mf-x-large-margin-bottom ecom-content-center ecom-title">
          THANK YOU FOR YOUR ORDER!
        </div>
      </mat-card-title>
      <mat-card-content>
        <div class="mf-column">
          <div class="mf-row ecom-content-center">
            We are currently processing your purchase. You will receive an email confirmation shortly with your order
            details. We
            appreciate your business and hope to see you again soon. If you have any questions or concerns, please
            contact our
            customer service team.
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button
                class="mf-large-margin-top"
                style="width: 100%;"
                color="accent"
                (click)="_goHome()">Done</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>