import { InjectionToken } from "@angular/core";
import { faChevronDown, faChevronUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import { MfFilterOperatorTypes, MfFilterOperatorSetTypes } from "@material-framework/filter/filter.types";
import { MfIcon } from "@material-framework/icon/icon";
import { MfIconTypes } from "@material-framework/icon/icon.types";

export const MF_FILTER_EQ_DEFAULT_DISPLAYNAME = "equal to";
export const MF_FILTER_NEQ_DEFAULT_DISPLAYNAME = "not equal to";
export const MF_FILTER_IN_DEFAULT_DISPLAYNAME = "in selection";
export const MF_FILTER_NIN_DEFAULT_DISPLAYNAME = "not in selection";
export const MF_FILTER_CONTAINS_DEFAULT_DISPLAYNAME = "contains";
export const MF_FILTER_ENDSWITH_DEFAULT_DISPLAYNAME = "ends with";
export const MF_FILTER_N_CONTAINS_DEFAULT_DISPLAYNAME = "does not contain";
export const MF_FILTER_N_ENDSWITH_DEFAULT_DISPLAYNAME = "does not end with";
export const MF_FILTER_N_STARTS_WITH_DEFAULT_DISPLAYNAME = "does not begins with";
export const MF_FILTER_STARTSWITH_DEFAULT_DISPLAYNAME = "begins with";
export const MF_FILTER_GT_DEFAULT_DISPLAYNAME = "greater than";
export const MF_FILTER_GTE_DEFAULT_DISPLAYNAME = "greater than or equal to";
export const MF_FILTER_LT_DEFAULT_DISPLAYNAME = "less than";
export const MF_FILTER_LTE_DEFAULT_DISPLAYNAME = "less than or equal to";
export const MF_FILTER_NGT_DEFAULT_DISPLAYNAME = "not greater than";
export const MF_FILTER_N_GTE_DEFAULT_DISPLAYNAME = "not greater than or equal to";
export const MF_FILTER_NLT_DEFAULT_DISPLAYNAME = "not less than";
export const MF_FILTER_N_LTE_DEFAULT_DISPLAYNAME = "not less than or equal to";
export const MF_FILTER_ALL_DEFAULT_DISPLAYNAME = "all items match";
export const MF_FILTER_NONE_DEFAULT_DISPLAYNAME = "none of the items match";
export const MF_FILTER_SOME_DEFAULT_DISPLAYNAME = "some of the items match";
export const MF_FILTER_ANY_DEFAULT_DISPLAYNAME = "has any items";

export const MF_FILTER_CONFIG_TOKEN = new InjectionToken<MfFilterConfig>("MfFilterConfig");
export const MF_FILTER_DEFAULT_CONFIG: MfFilterConfig = {
  group: {
    canAddGroup: true,
    addFilterDisplayName: "Add Filter",
    addFilterTooltip: "Adds a new filter",
    addGroupDisplayName: "Add Group",
    addGroupTooltip: "Adds a new group",
    addGroupDefaultLogic: "and",
    removeIcon: {
      type: MfIconTypes.fontAwesome,
      icon: faTrash,
    },
    removeGroupTooltip: "Remove Group",
    collapseIcon: {
      type: MfIconTypes.fontAwesome,
      icon: faChevronUp,
    },
    collapseTooltip: "Collapse group",
    expandIcon: {
      type: MfIconTypes.fontAwesome,
      icon: faChevronDown,
    },
    expandTooltip: "Expand group",
  },
  expression: {
    removeIcon: {
      type: MfIconTypes.fontAwesome,
      icon: faTrash,
    },
    removeTooltip: "Remove Filter",
  },
  logic: {
    canAddLogic: true,
    and: {
      displayName: "and",
    },
    or: {
      displayName: "or",
    },
  },
  operators: {
    selectTooltip: "Select the filter operator"
  },
  operatorsTypes: {
    boolean: {
      eq: {
        displayName: MF_FILTER_EQ_DEFAULT_DISPLAYNAME,
      },
      [MfFilterOperatorTypes.neq]: {
        displayName: MF_FILTER_NEQ_DEFAULT_DISPLAYNAME,
      }
    },
    enum: {
      eq: {
        displayName: MF_FILTER_EQ_DEFAULT_DISPLAYNAME,
      },
      [MfFilterOperatorTypes.neq]: {
        displayName: MF_FILTER_NEQ_DEFAULT_DISPLAYNAME,
      },
      [MfFilterOperatorTypes.in]: {
        displayName: MF_FILTER_IN_DEFAULT_DISPLAYNAME,
      },
      [MfFilterOperatorTypes.nin]: {
        displayName: MF_FILTER_NIN_DEFAULT_DISPLAYNAME,
      }
    },
    string: {
      contains: {
        displayName: MF_FILTER_CONTAINS_DEFAULT_DISPLAYNAME,
      },
      endsWith: {
        displayName: MF_FILTER_ENDSWITH_DEFAULT_DISPLAYNAME,
      },
      eq: {
        displayName: MF_FILTER_EQ_DEFAULT_DISPLAYNAME,
      },
      [MfFilterOperatorTypes.in]: {
        displayName: MF_FILTER_IN_DEFAULT_DISPLAYNAME,
      },
      ncontains: {
        displayName: MF_FILTER_N_CONTAINS_DEFAULT_DISPLAYNAME,
      },
      nendsWith: {
        displayName: MF_FILTER_N_ENDSWITH_DEFAULT_DISPLAYNAME,
      },
      [MfFilterOperatorTypes.neq]: {
        displayName: MF_FILTER_NEQ_DEFAULT_DISPLAYNAME,
      },
      [MfFilterOperatorTypes.nin]: {
        displayName: MF_FILTER_NIN_DEFAULT_DISPLAYNAME,
      },
      nstartsWith: {
        displayName: MF_FILTER_N_STARTS_WITH_DEFAULT_DISPLAYNAME,
      },
      startsWith: {
        displayName: MF_FILTER_STARTSWITH_DEFAULT_DISPLAYNAME,
      },
    },
    date: {
      eq: {
        displayName: MF_FILTER_EQ_DEFAULT_DISPLAYNAME,
      },
      gt: {
        displayName: MF_FILTER_GT_DEFAULT_DISPLAYNAME,
      },
      gte: {
        displayName: MF_FILTER_GTE_DEFAULT_DISPLAYNAME,
      },
      lt: {
        displayName: MF_FILTER_LT_DEFAULT_DISPLAYNAME,
      },
      lte: {
        displayName: MF_FILTER_LTE_DEFAULT_DISPLAYNAME,
      },
      [MfFilterOperatorTypes.neq]: {
        displayName: MF_FILTER_NEQ_DEFAULT_DISPLAYNAME,
      },
      ngt: {
        displayName: MF_FILTER_NGT_DEFAULT_DISPLAYNAME,
      },
      ngte: {
        displayName: MF_FILTER_N_GTE_DEFAULT_DISPLAYNAME,
      },
      nlt: {
        displayName: MF_FILTER_NLT_DEFAULT_DISPLAYNAME,
      },
      nlte: {
        displayName: MF_FILTER_N_LTE_DEFAULT_DISPLAYNAME,
      },
    },
    comparable: {
      eq: {
        displayName: MF_FILTER_EQ_DEFAULT_DISPLAYNAME,
      },
      gt: {
        displayName: MF_FILTER_GT_DEFAULT_DISPLAYNAME,
      },
      gte: {
        displayName: MF_FILTER_GTE_DEFAULT_DISPLAYNAME,
      },
      [MfFilterOperatorTypes.in]: {
        displayName: MF_FILTER_IN_DEFAULT_DISPLAYNAME,
      },
      lt: {
        displayName: MF_FILTER_LT_DEFAULT_DISPLAYNAME,
      },
      lte: {
        displayName: MF_FILTER_LTE_DEFAULT_DISPLAYNAME,
      },
      [MfFilterOperatorTypes.neq]: {
        displayName: MF_FILTER_NEQ_DEFAULT_DISPLAYNAME,
      },
      ngt: {
        displayName: MF_FILTER_NGT_DEFAULT_DISPLAYNAME,
      },
      ngte: {
        displayName: MF_FILTER_N_GTE_DEFAULT_DISPLAYNAME,
      },
      [MfFilterOperatorTypes.nin]: {
        displayName: MF_FILTER_NIN_DEFAULT_DISPLAYNAME,
      },
      nlt: {
        displayName: MF_FILTER_NLT_DEFAULT_DISPLAYNAME,
      },
      nlte: {
        displayName: MF_FILTER_N_LTE_DEFAULT_DISPLAYNAME,
      },
    },
    array: {
      all: {
        displayName: MF_FILTER_ALL_DEFAULT_DISPLAYNAME,
      },
      none: {
        displayName: MF_FILTER_NONE_DEFAULT_DISPLAYNAME,
      },
      some: {
        displayName: MF_FILTER_SOME_DEFAULT_DISPLAYNAME,
      },
      any: {
        displayName: MF_FILTER_ANY_DEFAULT_DISPLAYNAME,
      }
    },
    none: {},
  }
};


export type MfFilterConfig = {
  operatorsTypes: MfFilterOperatorTypesConfig;
  operators: MfFilterOperatorOptionsConfig;
  logic: MfFilterLogicsConfig;
  group: MfFilterGroupConfig;
  expression: MfFilterExpressionConfig;
}

export type MfFilterOperatorOptionsConfig = {
  selectTooltip: string;
}

export type MfFilterGroupConfig = {
  canAddGroup: boolean;
  addFilterDisplayName: string;
  addFilterTooltip: string;
  addGroupDisplayName: string;
  addGroupTooltip: string;
  addGroupDefaultLogic: "and" | "or";
  removeIcon: MfIcon;
  removeGroupTooltip: string;
  collapseIcon: MfIcon;
  collapseTooltip: string;
  expandIcon: MfIcon;
  expandTooltip: string;
}

export type MfFilterExpressionConfig = {
  maxFilters?: number;
  removeIcon: MfIcon;
  removeTooltip: string;
}

export type MfFilterLogicsConfig = {
  canAddLogic: boolean;
  and: MfFilterLogicConfig;
  or: MfFilterLogicConfig;
}

export type MfFilterLogicConfig = {
  displayName: string;
}

export type MfFilterOperatorTypesConfig = {
  [MfFilterOperatorSetTypes.boolean]: MfFilterBooleanOperatorsConfig;
  [MfFilterOperatorSetTypes.enum]: MfFilterEnumOperatorsConfig;
  [MfFilterOperatorSetTypes.string]: MfFilterStringOperatorsConfig;
  [MfFilterOperatorSetTypes.comparable]: MfFilterComparableOperatorsConfig;
  [MfFilterOperatorSetTypes.date]: MfFilterDateOperatorsConfig;
  [MfFilterOperatorSetTypes.array]: MfFilterArrayOperatorsConfig;
  [MfFilterOperatorSetTypes.none]: MfFilterNoneOperatorsConfig;
}

export type MfFilterNoneOperatorsConfig = {

}

export type MfFilterBooleanOperatorsConfig = {
  [MfFilterOperatorTypes.eq]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.neq]?: MfFilterOperatorConfig;
}

export type MfFilterEnumOperatorsConfig = {
  [MfFilterOperatorTypes.eq]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.neq]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.in]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.nin]?: MfFilterOperatorConfig;
}

export type MfFilterStringOperatorsConfig = {
  [MfFilterOperatorTypes.contains]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.endsWith]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.eq]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.in]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.ncontains]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.nendsWith]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.neq]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.nin]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.nstartsWith]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.startsWith]?: MfFilterOperatorConfig;
}
export type MfFilterDateOperatorsConfig = {
  [MfFilterOperatorTypes.eq]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.gt]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.gte]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.lt]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.lte]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.neq]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.ngt]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.ngte]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.nlt]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.nlte]?: MfFilterOperatorConfig;
}

export type MfFilterComparableOperatorsConfig = {
  [MfFilterOperatorTypes.eq]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.gt]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.gte]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.in]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.lt]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.lte]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.neq]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.ngt]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.ngte]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.nin]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.nlt]?: MfFilterOperatorConfig;
  [MfFilterOperatorTypes.nlte]?: MfFilterOperatorConfig;
}


export type MfFilterArrayOperatorsConfig = {
  all?: MfFilterOperatorConfig;
  none?: MfFilterOperatorConfig;
  some?: MfFilterOperatorConfig;
  any?: MfFilterOperatorConfig;
}

export type MfFilterOperatorConfig = {
  disabled?: boolean;
  displayName: string;
}

export type MfFilterTypesConfig = {
  string: MfFilterTypeConfig;
  number: MfFilterTypeConfig;
  boolean: MfFilterTypeConfig;
  date: MfFilterTypeConfig;
  list: MfFilterTypeConfig;
}

export type MfFilterTypeConfig = {

}