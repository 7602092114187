import { NgModule } from "@angular/core";
import { MF_CURRENCY_PIPE_CONFIG_TOKEN, MF_CURRENCY_PIPE_DEFAULT_CONFIG, MfCurrencyPipe } from "@material-framework/pipes/currency.pipe";
import { MF_DATE_PIPE_CONFIG_TOKEN, MF_DATE_PIPE_DEFAULT_CONFIG, MfDatePipe } from "@material-framework/pipes/date.pipe";
import { MF_DECIMAL_PIPE_CONFIG_TOKEN, MF_DECIMAL_PIPE_DEFAULT_CONFIG, MfDecimalPipe } from "@material-framework/pipes/decimal.pipe";
import { MF_INT_PIPE_CONFIG_TOKEN, MF_INT_PIPE_DEFAULT_CONFIG, MfIntPipe } from "@material-framework/pipes/int.pipe";
import { MF_NUMBER_WITH_ORDINAL_PIPE_CONFIG_TOKEN, MF_NUMBER_WITH_ORDINAL_PIPE_DEFAULT_CONFIG, mfIntWithOrdinalPipe } from "@material-framework/pipes/number.with.ordinal.pipe";
import { MF_PERCENT_PIPE_CONFIG_TOKEN, MF_PERCENT_PIPE_DEFAULT_CONFIG, MfPercentPipe } from "@material-framework/pipes/percent.pipe";
import { MF_SAFE_HTML_PIPE_CONFIG_TOKEN, MF_SAFE_HTML_PIPE_DEFAULT_CONFIG, MfSafeHtmlPipe } from "@material-framework/pipes/safe.html.pipe";

@NgModule({
  declarations: [
    mfIntWithOrdinalPipe,
    MfSafeHtmlPipe,
    MfCurrencyPipe,
    MfIntPipe,
    MfPercentPipe,
    MfDatePipe,
    MfDecimalPipe,
  ],
  imports: [
  ],
  exports: [
    mfIntWithOrdinalPipe,
    MfSafeHtmlPipe,
    MfCurrencyPipe,
    MfIntPipe,
    MfPercentPipe,
    MfDatePipe,
    MfDecimalPipe,
  ],
  providers: [
    { provide: MF_CURRENCY_PIPE_CONFIG_TOKEN, useValue: MF_CURRENCY_PIPE_DEFAULT_CONFIG },
    { provide: MF_DATE_PIPE_CONFIG_TOKEN, useValue: MF_DATE_PIPE_DEFAULT_CONFIG },
    { provide: MF_DECIMAL_PIPE_CONFIG_TOKEN, useValue: MF_DECIMAL_PIPE_DEFAULT_CONFIG },
    { provide: MF_INT_PIPE_CONFIG_TOKEN, useValue: MF_INT_PIPE_DEFAULT_CONFIG },
    { provide: MF_NUMBER_WITH_ORDINAL_PIPE_CONFIG_TOKEN, useValue: MF_NUMBER_WITH_ORDINAL_PIPE_DEFAULT_CONFIG },
    { provide: MF_PERCENT_PIPE_CONFIG_TOKEN, useValue: MF_PERCENT_PIPE_DEFAULT_CONFIG },
    { provide: MF_SAFE_HTML_PIPE_CONFIG_TOKEN, useValue: MF_SAFE_HTML_PIPE_DEFAULT_CONFIG },
    MfCurrencyPipe,
    MfIntPipe,
    mfIntWithOrdinalPipe,
    MfPercentPipe,
    MfSafeHtmlPipe,
  ]
})
export class MfPipesModule {
}