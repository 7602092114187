import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { BrowserModule } from "@angular/platform-browser";
import { MfClearValueButtonModule } from "@material-framework/clearValueButton/clear.value.button.module";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MfInputComponent } from "@material-framework/input/input.component";
import { MF_INPUT_CONFIG_TOKEN, MF_INPUT_DEFAULT_CONFIG } from "@material-framework/input/input.config";

@NgModule({
  declarations: [
    MfInputComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    MatInputModule,
    MfClearValueButtonModule,
    ReactiveFormsModule,
    MfIconModule,
  ],
  exports: [
    MfInputComponent,
    MatInputModule
  ],
  providers: [
    { provide: MF_INPUT_CONFIG_TOKEN, useValue: MF_INPUT_DEFAULT_CONFIG },
  ]
})
export class MfInputModule {
}