import { Component, Inject, Injector, Input, ViewEncapsulation } from "@angular/core";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPathValue } from "@material-framework/common/utils/object.utils";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfModelConfig } from "@material-framework/modelConfig/model.config";
import { MfModelConfigService } from "@material-framework/modelConfig/model.config.service";
import { MfTableRowCellBaseFormatterComponent } from "@material-framework/table/row/cell/value/table.row.cell.base.formatter.component";
import { MfTableFieldColumn, MfTableRowCellFormatterBaseOptions } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowCellUserFormatterComponent" };

export type MfTableRowCellUserFormatterOptions = MfTableRowCellFormatterBaseOptions & {}

@Component({
  selector: "mf-table-row-cell-user-formatter-component",
  templateUrl: "table.row.cell.user.formatter.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowCellUserFormatterComponent<TModel extends MfModelBase> extends MfTableRowCellBaseFormatterComponent<MfTableRowCellUserFormatterOptions, TModel> {
  @Input()
  public override get fieldColumn(): MfTableFieldColumn | undefined {
    return this._fieldColumn;
  }
  public override set fieldColumn(value: MfTableFieldColumn | undefined) {
    this._fieldColumn = value;
    this._getModelConfig();
    this._getModel();
  }

  @Input()
  public override get rowItem(): TModel | undefined {
    return this._rowItem;
  }
  public override set rowItem(value: TModel | undefined) {
    this._rowItem = value;
    this._getModelConfig();
    this._getModel();
  }

  protected _modelConfig?: MfModelConfig;
  protected _model?: MfModelBase;

  public constructor(
    protected override _injector: Injector,
    protected _modelConfigService: MfModelConfigService,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected _getModelConfig(): void {
    if (this._isUndefined(this._modelConfig) && !this._isUndefined(this._fieldColumn) && !this._isUndefined(this._fieldColumn.modelFieldConfig) && !this._isUndefined(this._fieldColumn.modelFieldConfig.model)) {
      this._modelConfig = this._modelConfigService.getUnMapped(this._fieldColumn.modelFieldConfig.model.key);
    }
  }

  protected _getModel(): void {
    if (this._isUndefined(this._model) && !this._isUndefined(this.rowItem) && !this._isUndefined(this._fieldColumn) && !this._isUndefined(this._fieldColumn.modelFieldConfig) && !this._isUndefined(this._fieldColumn.modelFieldConfig.model)) {
      this._model = mfObjectGetPropertyPathValue<MfModelBase>(this._fieldColumn.modelFieldConfig.fieldPath, this.rowItem);
    }
  }
}