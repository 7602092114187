import { MfFilterOperatorSetTypes, MfFilterOperatorTypes, MfFilterValueInputTypes } from "@material-framework/filter/filter.types";


export const MF_ENUM_FILTER_CONFIG: MfModelFieldFilterConfig = {
  input: {
    select: {},
  },
  operator: {
    type: MfFilterOperatorSetTypes.enum,
  }
};

export const MF_BOOLEAN_FILTER_CONFIG: MfModelFieldFilterConfig = {
  input: {
    boolean: {},
  },
  operator: {
    type: MfFilterOperatorSetTypes.boolean,
  }
};

export const MF_DATE_FILTER_CONFIG: MfModelFieldFilterConfig = {
  input: {
    date: {},
  },
  operator: {
    type: MfFilterOperatorSetTypes.date,
  }
};

export const MF_NUMBER_FILTER_CONFIG: MfModelFieldFilterConfig = {
  input: {
    number: {},
  },
  operator: {
    type: MfFilterOperatorSetTypes.comparable,
  }
};

export const MF_STRING_FILTER_CONFIG: MfModelFieldFilterConfig = {
  input: {
    string: {},
  },
  operator: {
    type: MfFilterOperatorSetTypes.string,
  }
};

export const MF_SELECT_NUMBER_FILTER_CONFIG: MfModelFieldFilterConfig = {
  input: {
    select: {},
  },
  operator: {
    type: MfFilterOperatorSetTypes.comparable,
  }
};

export const MF_SELECT_STRING_FILTER_CONFIG: MfModelFieldFilterConfig = {
  input: {
    select: {},
  },
  operator: {
    type: MfFilterOperatorSetTypes.string,
  }
};

export const MF_OBJECT_FILTER_CONFIG: MfModelFieldFilterConfig = {
  input: {
    none: {},
  },
  operator: {
    type: MfFilterOperatorSetTypes.none,
  }
};

export const MF_OBJECT_ARRAY_FILTER_CONFIG: MfModelFieldFilterConfig = {
  input: {
    none: {},
  },
  operator: {
    type: MfFilterOperatorSetTypes.array,
  }
};

export type MfModelFieldFilterConfig = {
  displayName?: string;
  input: MfFilterModelInputConfig;
  operator: MfFilterModelOperatorSetConfig;
  flatten?: MfFilterModelFlattenConfig;
  disabled?: boolean;
  exclude?: MfModelFieldFilterExcludeConfig
}

export type MfModelFieldFilterExcludeConfig = {
  operatorsTypes?: MfFilterOperatorExcludeTypesConfig;
}

export type MfFilterOperatorExcludeTypesConfig = {
  [MfFilterOperatorSetTypes.boolean]?: MfFilterBooleanOperatorsConfig;
  [MfFilterOperatorSetTypes.enum]?: MfFilterEnumOperatorsConfig;
  [MfFilterOperatorSetTypes.string]?: MfFilterStringOperatorsConfig;
  [MfFilterOperatorSetTypes.comparable]?: MfFilterComparableOperatorsConfig;
  [MfFilterOperatorSetTypes.date]?: MfFilterDateOperatorsConfig;
  [MfFilterOperatorSetTypes.array]?: MfFilterArrayOperatorsConfig;
}

export type MfFilterBooleanOperatorsConfig = {
  [MfFilterOperatorTypes.eq]?: boolean;
  [MfFilterOperatorTypes.neq]?: boolean;
}

export type MfFilterEnumOperatorsConfig = {
  [MfFilterOperatorTypes.eq]?: boolean;
  [MfFilterOperatorTypes.neq]?: boolean;
  [MfFilterOperatorTypes.in]?: boolean;
  [MfFilterOperatorTypes.nin]?: boolean;
}

export type MfFilterStringOperatorsConfig = {
  [MfFilterOperatorTypes.contains]?: boolean;
  [MfFilterOperatorTypes.endsWith]?: boolean;
  [MfFilterOperatorTypes.eq]?: boolean;
  [MfFilterOperatorTypes.in]?: boolean;
  [MfFilterOperatorTypes.ncontains]?: boolean;
  [MfFilterOperatorTypes.nendsWith]?: boolean;
  [MfFilterOperatorTypes.neq]?: boolean;
  [MfFilterOperatorTypes.nin]?: boolean;
  [MfFilterOperatorTypes.nstartsWith]?: boolean;
  [MfFilterOperatorTypes.startsWith]?: boolean;
}
export type MfFilterDateOperatorsConfig = {
  [MfFilterOperatorTypes.eq]?: boolean;
  [MfFilterOperatorTypes.gt]?: boolean;
  [MfFilterOperatorTypes.gte]?: boolean;
  [MfFilterOperatorTypes.lt]?: boolean;
  [MfFilterOperatorTypes.lte]?: boolean;
  [MfFilterOperatorTypes.neq]?: boolean;
  [MfFilterOperatorTypes.ngt]?: boolean;
  [MfFilterOperatorTypes.ngte]?: boolean;
  [MfFilterOperatorTypes.nlt]?: boolean;
  [MfFilterOperatorTypes.nlte]?: boolean;
}

export type MfFilterComparableOperatorsConfig = {
  [MfFilterOperatorTypes.eq]?: boolean;
  [MfFilterOperatorTypes.gt]?: boolean;
  [MfFilterOperatorTypes.gte]?: boolean;
  [MfFilterOperatorTypes.in]?: boolean;
  [MfFilterOperatorTypes.lt]?: boolean;
  [MfFilterOperatorTypes.lte]?: boolean;
  [MfFilterOperatorTypes.neq]?: boolean;
  [MfFilterOperatorTypes.ngt]?: boolean;
  [MfFilterOperatorTypes.ngte]?: boolean;
  [MfFilterOperatorTypes.nin]?: boolean;
  [MfFilterOperatorTypes.nlt]?: boolean;
  [MfFilterOperatorTypes.nlte]?: boolean;
}

export type MfFilterArrayOperatorsConfig = {
  all?: boolean;
  none?: boolean;
  some?: boolean;
  any?: boolean;
}

export type MfFilterModelOperatorSetConfig = {
  type: MfFilterOperatorSetTypes;
}

export type MfFilterModelInputConfig = {
  [MfFilterValueInputTypes.boolean]: MfFilterModelInputTypeBooleanConfig;
} | {
  [MfFilterValueInputTypes.date]: MfFilterModelInputTypeDateConfig;
} | {
  [MfFilterValueInputTypes.none]: MfFilterModelInputTypeNoneConfig;
} | {
  [MfFilterValueInputTypes.number]: MfFilterModelInputTypeNumberConfig;
} | {
  [MfFilterValueInputTypes.select]: MfFilterModelInputTypeSelectConfig;
} | {
  [MfFilterValueInputTypes.string]: MfFilterModelInputTypeStringConfig;
};

export type MfFilterModelInputTypesMapConfig = {
  [MfFilterValueInputTypes.boolean]: MfFilterModelInputTypeBooleanConfig;
  [MfFilterValueInputTypes.date]: MfFilterModelInputTypeDateConfig;
  [MfFilterValueInputTypes.none]: MfFilterModelInputTypeNoneConfig;
  [MfFilterValueInputTypes.number]: MfFilterModelInputTypeNumberConfig;
  [MfFilterValueInputTypes.select]: MfFilterModelInputTypeSelectConfig;
  [MfFilterValueInputTypes.string]: MfFilterModelInputTypeStringConfig;
}

export type MfFilterModelInputTypeBooleanConfig = {

}

export type MfFilterModelInputTypeDateConfig = {

}

export type MfFilterModelInputTypeNoneConfig = {

}

export type MfFilterModelInputTypeNumberConfig = {

}

export type MfFilterModelInputTypeSelectConfig = {

}

export type MfFilterModelInputTypeStringConfig = {
  maxLength?: number;
}



export type MfFilterModelFlattenConfig = {
  fromFieldPath?: string;
}