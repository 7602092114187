import { InjectionToken } from "@angular/core";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfFilterGroup } from "@material-framework/filter/filter";
import { MfModelFieldsConfig } from "@material-framework/modelConfig/model.config";

export const MF_FILTER_CONVERTER_SERVICE_TOKEN = new InjectionToken<MfFilterConverterService<unknown>>("MfFilterConverterService");


export type MfFilterConverterService<TFrom> = MfBaseService & {
  toGroup(from: TFrom, modelConfigOrModelKey: string | MfModelFieldsConfig): MfFilterGroup
  fromGroup(group: MfFilterGroup, includeDisabled: boolean): TFrom | undefined
}