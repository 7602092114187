<mat-card #container
          @fadeIn
          @fadeOut
          (mfClickOutside)="onClickedOutside()"
          [exclude]="'mf-clear-value-button,.mat-calendar,.mat-mdc-menu-panel,.mat-mdc-form-field,.cdk-overlay-container'"
          [excludeBeforeClick]="true">
  <mat-card-header>
    <mat-card-subtitle>Filter</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mf-filter (onFilterChange)="onFilterChange.emit($event)"
               (onGroupChange)="onFilterGroupChange.emit($event)"
               (onRemoveGroup)="onFilterRemoveGroup.emit($event)"
               (onAddGroup)="onFilterAddGroup.emit($event)"
               (onExpressionChange)="onFilterExpressionChange.emit($event)"
               (onAddExpression)="onFilterAddExpression.emit($event)"
               (onRemoveExpression)="onFilterRemoveExpression.emit($event)"
               [group]="filterGroup"
               [modelFieldsConfig]="modelFieldsConfig"></mf-filter>
  </mat-card-content>
</mat-card>