<div [formGroup]="_formGroup">
  <mat-form-field [style.width.px]="options?.width"
                  [matTooltip]="_errorMessages[_inputName]"
                  class="mf-slim-mdc-form-field">
    <input #input
           matInput
           [readonly]="true"
           [ngxMatDatetimePicker]="picker"
           [formControl]="_inputFormControl"
           (dateChange)="_changed($event)"
           [min]="options?.min"
           [max]="options?.max">
    <ngx-mat-datepicker-toggle matSuffix
                               [for]="picker"></ngx-mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker
                             [hideTime]="true">
    </ngx-mat-datetime-picker>
  </mat-form-field>
</div>