import { GlobalPositionStrategy, Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { EventEmitter, Inject, Injectable, Injector, Output, ViewContainerRef } from "@angular/core";
import { MfBaseService } from "@material-framework/base/base.service";
import { MfError } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfSnackBarErrorMessage, MfSnackBarMessage, MfSnackBarMessageTypes, MfSnackBarMfErrorMessage } from "@material-framework/snackbar/snackbar";
import { MfSnackBarComponent } from "@material-framework/snackbar/snackbar.component";
import { MF_SNACKBAR_CONFIG_TOKEN, MfSnackBarConfig } from "@material-framework/snackbar/snackbar.config";

const TYPE_INFO: MfTypeInfo = { className: "MfSnackBarService" };

@Injectable()
export class MfSnackBarService extends MfBaseService {
  @Output()
  public onMessageAdded: EventEmitter<MfSnackBarMessage> = new EventEmitter();
  @Output()
  public onMessageRemoved: EventEmitter<void> = new EventEmitter();

  protected _messages: MfSnackBarMessage[] = [];
  protected _overlayRef?: OverlayRef;
  protected _viewContainerRef?: ViewContainerRef;
  protected _snackBarComponent?: MfSnackBarComponent;

  public constructor(
    protected override _injector: Injector,
    protected _overlay: Overlay,
    protected _overlayPositionBuilder: OverlayPositionBuilder,
    @Inject(MF_SNACKBAR_CONFIG_TOKEN)
    public config: MfSnackBarConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._sub(this._errorService.onError, {
      next: (error) => this.addErrorMessage(error)
    });
  }

  public get messageCount(): number {
    return this._messages.length;
  }

  public get message(): MfSnackBarMessage[] {
    return this._messages;
  }

  public setViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this._viewContainerRef = viewContainerRef;
  }

  public addErrorMessage(error: Error) {
    if (!mfTypeIsUndefined(error) && !mfTypeIsUndefined(error.message)) {
      if (error.message.indexOf("ExpressionChangedAfterItHasBeenCheckedError") != -1) {
        return;
      }

      if (error instanceof MfError) {
        if (error.hideFromSnackBar === false) {
          const message: MfSnackBarMfErrorMessage = {
            type: MfSnackBarMessageTypes.errorMf,
            message: error.message,
            error,
          };
          this.addMessage(message);
        }
      } else {
        const message: MfSnackBarErrorMessage = {
          type: MfSnackBarMessageTypes.error,
          message: error.message,
          error,
        };
        this.addMessage(message);
      }
    }
  }

  public addMessage(message: MfSnackBarMessage) {
    this._messages.push(message);
    this._open(message);
    this.onMessageAdded.emit(message);
  }

  public deleteMessage(message: MfSnackBarMessage): void {
    const messageIndex = this._messages.findIndex(m => m == message);
    if (messageIndex != -1) {
      this._messages.splice(messageIndex, 1);
      this.onMessageRemoved.emit();
      if (this.messageCount === 0) {
        this._close();
      }
    }
  }

  protected _open(message?: MfSnackBarMessage): void {
    if (mfTypeIsUndefined(this._overlayRef)) {
      this._overlayRef = this._overlay.create(this._getOverlayConfig());
      const userProfilePortal = new ComponentPortal(MfSnackBarComponent, this._viewContainerRef, this._injector);
      this._snackBarComponent = this._overlayRef.attach(userProfilePortal).instance;
      this._snackBarComponent.selectedMessage = message;
    }
  }

  protected _close(): void {
    if (!mfTypeIsUndefined(this._overlayRef)) {
      this._overlayRef.detach();
      delete this._overlayRef;
    }
  }

  protected _getOverlayConfig(): OverlayConfig {
    return {
      positionStrategy: this._getOverlayPositionStrategy(),
    };
  }

  protected _getOverlayPositionStrategy(): GlobalPositionStrategy {
    return this._overlayPositionBuilder
      .global()
      .bottom()
      .centerHorizontally();
  }
}