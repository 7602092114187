<div class="mf-fill"
     (mfResized)="_onMeasureResized($event)"></div>
<div class="mf-table-container">
    <div class="mf-table-loading"
         *ngIf="loadingType === 'spinner' && _isLoading == true && _loadingService.isLoading === false">
        <mat-spinner></mat-spinner>
    </div>
    <div class="mf-table-header"
         [style]="_config.header.styles.main">
        <mf-table-header (onFieldColumnVisibleChanged)="onFieldColumnVisibleChanged($event)"
                         (onFieldsColumnVisibleChanged)="_onFieldsColumnVisibleChanged($event)"
                         (onFilterChange)="_onFilterChanged($event)"
                         (onFilterRemoveExpression)="onFilterRemoveExpression.emit($event)"
                         (onFilterRemoveGroup)="onFilterRemoveGroup.emit($event)"
                         (onGroupColumnMoving)="_onGroupColumnMoving($event)"
                         (onReloadData)="loadData()"
                         (onSelectedViewStateChanged)="_onSelectedViewStateChanged($event)"
                         (onViewStateBeforeUpdate)="_onViewStateBeforeUpdate($event)"
                         (onFilterMenuClose)="_onTableFilterMenuClose()"
                         [headerLeftDef]="_headerLeftDef"
                         [headerRightDef]="_headerRightDef"
                         [shrinkSticky]="_shrinkSticky"
                         [fieldColumns]="_getFieldColumns()"
                         [groupColumns]="_groupColumns"
                         [locationKey]="locationKey"
                         [dataNeedsReload]="_dataNeedsReload"
                         [modelFieldsConfig]="modelConfig?.fields"
                         [filterGroup]="_filterGroup"
                         [viewState]="_viewState"></mf-table-header>
    </div>
    <div class="mf-table">
        <table #table
               mat-table
               multiTemplateDataRows
               [class.mf-table-row-hover-disabled]="!rowMouseOverHighlight"
               [dataSource]="dataSource">
            <!-- ## Selection Manager columns ## -->
            <ng-container [matColumnDef]="_GROUP_NAME_PREFIX + _SELECTION_MANAGER_POSTFIX"
                          [stickyEnd]="selectionStickyEnd"
                          [sticky]="selectionSticky">
                <th mat-header-cell
                    class="mf-table-cell-column-selection-group-header"
                    *matHeaderCellDef>
                </th>
            </ng-container>
            <ng-container [matColumnDef]="_FIELD_NAME_PREFIX + _SELECTION_MANAGER_POSTFIX"
                          [stickyEnd]="selectionStickyEnd"
                          [sticky]="selectionSticky">
                <th mat-header-cell
                    [style.width.px]="0"
                    *matHeaderCellDef>
                    <div class="mf-table-cell-column-selection-field-header">
                        <div *ngIf="selectionManager?.canSelectAll && !_selectionManagerIsLoading">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                          [disabled]="_isLoading"
                                          [indeterminate]="true"
                                          [checked]="selectionManager?.isAllSelected"
                                          (change)="setAllItemsSelected($event.checked)"></mat-checkbox>
                        </div>
                        <div *ngIf="_selectionManagerIsLoading">
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </div>
                        <div>
                            {{ selectionManager?.selectedCount }}
                        </div>
                    </div>
                </th>

                <td mat-cell
                    class="mf-table-cell-column-selection-field"
                    [class.mf-expandable-row]="!_isUndefined(_columnExpandDef)"
                    *matCellDef="let item;">
                    <mat-checkbox #itemSelect
                                  [disabled]="_isLoading || !_canSelectItem(item)"
                                  *ngIf="_isUndefined(selectionManagerCanSelectItem) || selectionManagerCanSelectItem(item)"
                                  (click)="_onSelectItemClicked(item, $event, itemSelect)"
                                  [checked]="selectionManager?.isSelected(item)"
                                  (change)="setItemSelected(item, $event.checked)"></mat-checkbox>
                </td>
            </ng-container>
            <ng-container [matColumnDef]="_INFO_NAME_PREFIX + _SELECTION_MANAGER_POSTFIX"
                          [stickyEnd]="selectionStickyEnd"
                          [sticky]="selectionSticky">
                <th mat-header-cell
                    class="mf-table-cell-actions-selection-header"
                    *matHeaderCellDef="let item;">
                </th>
            </ng-container>

            <!-- ## Model Config columns ## -->
            <!-- ### group header cell ### -->
            <ng-container [matColumnDef]="groupColumn.groupPath"
                          *ngFor="let groupColumn of _groupColumns;"
                          [stickyEnd]="groupColumn?.stickyEnd"
                          [sticky]="groupColumn?.sticky">
                <th class="mf-table-cell-group-header"
                    mat-header-cell
                    *matHeaderCellDef
                    [style]="groupColumn.style"
                    [attr.colspan]="groupColumn.colSpan">
                    <mf-table-row-group-header-cell [groupColumn]="groupColumn">
                    </mf-table-row-group-header-cell>
                </th>
            </ng-container>
            <!-- ### info header cell ### -->
            <ng-container *ngFor="let groupColumn of _groupColumns;">
                <ng-container [matColumnDef]="_INFO_NAME_PREFIX + fieldColumn.fieldPath"
                              *ngFor="let fieldColumn of groupColumn.fieldColumns;"
                              [stickyEnd]="fieldColumn?.stickyEnd"
                              [sticky]="fieldColumn?.sticky">
                    <th class="mf-table-cell-field-header-info"
                        [class.mf-table-cell-header-hover]="fieldColumn.mouseOverHeader === true"
                        (mouseover)="fieldColumn.mouseOverHeader = true"
                        (mouseleave)="fieldColumn.mouseOverHeader = false"
                        (click)="_openHeaderMenu(fieldColumn)"
                        [id]="_INFO_NAME_PREFIX + fieldColumn.fieldPath"
                        mat-header-cell
                        [style]="fieldColumn.modelFieldConfig?.table?.header?.style"
                        [style.width.px]="fieldColumn.width!.width"
                        [style.minWidth.px]="fieldColumn.width!.width"
                        *matHeaderCellDef="let item;">
                        <mf-table-row-field-header-cell-info [item]="item"
                                                             [fieldColumn]="fieldColumn"></mf-table-row-field-header-cell-info>
                    </th>
                </ng-container>
            </ng-container>

            <!-- ### header cell and data cell ### -->
            <ng-container *ngFor="let groupColumn of _groupColumns;">
                <ng-container [matColumnDef]="fieldColumn.fieldPath"
                              *ngFor="let fieldColumn of groupColumn.fieldColumns;"
                              [stickyEnd]="fieldColumn?.stickyEnd"
                              [sticky]="fieldColumn?.sticky">
                    <th class="mf-table-cell-field-header"
                        [class.mf-table-cell-header-hover]="fieldColumn.mouseOverHeader === true"
                        (mouseover)="fieldColumn.mouseOverHeader = true"
                        (mouseleave)="fieldColumn.mouseOverHeader = false"
                        [id]="fieldColumn.fieldPath"
                        mat-header-cell
                        [style.width.px]="fieldColumn.width!.width"
                        [style.minWidth.px]="fieldColumn.width!.width"
                        [style]="fieldColumn.modelFieldConfig?.table?.header?.style"
                        [matTooltip]="_config.filter.mode === _filterModeTypes.rowHeader ? _config.header.showOptionsFilterTooltip : _config.header.showOptionsNoFilterTooltip"
                        *matHeaderCellDef>
                        <mf-table-row-field-header-cell #fieldHeaderCell
                                                        [filterMode]="_config.filter.mode"
                                                        (onFilterChange)="_onFilterChanged($event)"
                                                        (onFilterRemoveExpression)="onFilterRemoveExpression.emit($event)"
                                                        (onFilterRemoveGroup)="onFilterRemoveGroup.emit($event)"
                                                        (onMenuClose)="_onTableFilterMenuClose()"
                                                        (onSortCleared)="_onSortCleared()"
                                                        (onSortDirectionChanged)="_onSortDirectionChanged()"
                                                        (onSortIndexChanged)="_onSortIndexChanged()"
                                                        (onSortIndexChanging)="_onSortIndexChanging($event)"
                                                        [fieldColumn]="fieldColumn">
                        </mf-table-row-field-header-cell>
                    </th>

                    <td mat-cell
                        class="mf-table-cell-field"
                        [class.mf-expandable-row]="!_isUndefined(_columnExpandDef)"
                        [style]="fieldColumn.modelFieldConfig?.table?.cell?.style"
                        *matCellDef="let item">
                        <mf-table-row-cell (requireTableReload)="_onCellRequireTableReload($event)"
                                           (onValueInput)="_onRowCellValueInput($event)"
                                           (onValueChange)="_onRowCellValueChanged($event)"
                                           [isLoading]="_isLoading"
                                           [fieldColumn]="fieldColumn"
                                           [rowItem]="item"></mf-table-row-cell>
                    </td>
                </ng-container>
            </ng-container>

            <!-- ## Model Config Expanded Content ## -->
            <!-- ### group header cell ### -->
            <ng-container [matColumnDef]="_GROUP_NAME_PREFIX + _EXPANDED_CONTENT_POSTFIX">
                <th mat-header-cell
                    class="mf-table-cell-expanded-content-group-header"
                    *matHeaderCellDef>
                </th>
            </ng-container>
            <!-- ### info header cell ### -->
            <ng-container [matColumnDef]="_INFO_NAME_PREFIX + _EXPANDED_CONTENT_POSTFIX">
                <th mat-header-cell
                    class="mf-table-cell-expanded-content-header-info"
                    *matHeaderCellDef>
                </th>
            </ng-container>
            <!-- ### header cell and expand action cell ### -->
            <ng-container [matColumnDef]="_FIELD_NAME_PREFIX + _EXPANDED_CONTENT_POSTFIX">
                <th class="mf-table-cell-expanded-content-field-header"
                    mat-header-cell
                    *matHeaderCellDef>
                </th>

                <td mat-cell
                    [class.mf-expandable-row]="!_isUndefined(_columnExpandDef)"
                    class="mf-table-cell-expanded-content-field"
                    *matCellDef="let item;">
                    <button mat-icon-button
                            [matTooltip]="_expandedItem !== item ? _config.row.expand.expandButtonTooltip : _config.row.expand.collapseButtonTooltip"
                            (click)="_toggleExpandableRow($event, item)">
                        <mf-icon *ngIf="_expandedItem !== item"
                                 [icon]="_config.row.expand.expandIcon"></mf-icon>
                        <mf-icon *ngIf="_expandedItem === item"
                                 [icon]="_config.row.expand.collapseIcon"></mf-icon>
                    </button>
                </td>
            </ng-container>
            <!-- ### expand content ### -->
            <ng-container [matColumnDef]="_EXPAND_NAME_PREFIX + _EXPANDED_CONTENT_POSTFIX">
                <td mat-cell
                    *matCellDef="let item"
                    [attr.colspan]="_fieldDisplayColumns.length">
                    <div class="mf-expand-container"
                         *ngIf="!_isUndefined(_columnExpandDef) && !_isUndefined(_columnExpandDef.cellDef) && !_isUndefined(_columnExpandDef.cellDef.template)"
                         [@detailExpand]="item == _expandedItem ? 'expanded' : 'collapsed'"
                         (@detailExpand.done)="_onExpandableRowAnimationDone($event, item)">
                        <div class="mf-expand-content">
                            <ng-template [ngTemplateOutlet]="_columnExpandDef.cellDef.template"
                                         [ngTemplateOutletContext]="{$implicit: item}">
                            </ng-template>
                        </div>
                    </div>
                </td>
            </ng-container>

            <!-- ## mfTableColumnDef columns ## -->
            <ng-container *ngFor="let columnDef of _columnDefs"
                          [matColumnDef]="_GROUP_NAME_PREFIX + columnDef.name"
                          [stickyEnd]="columnDef.stickyEnd"
                          [sticky]="columnDef.sticky">
                <th mat-header-cell
                    class="mf-table-cell-column-def-group-header"
                    *matHeaderCellDef>
                </th>
            </ng-container>
            <ng-container *ngFor="let columnDef of _columnDefs"
                          [matColumnDef]="_FIELD_NAME_PREFIX + columnDef.name!"
                          [stickyEnd]="columnDef.stickyEnd"
                          [sticky]="columnDef.sticky">
                <th mat-header-cell
                    class="mf-table-cell-column-def-field-header"
                    [style.width.px]="columnDef.width"
                    [style.minWidth.px]="columnDef.width"
                    [style.textAlign]="_isUndefined(columnDef.headerCell) ?  _config.row.header.contentAlign : columnDef.headerCell.contentAlign"
                    *matHeaderCellDef="let item;">
                    <ng-template [ngTemplateOutlet]="columnDef.headerCellDef.template"
                                 [ngTemplateOutletContext]="{$implicit: item}">
                    </ng-template>
                </th>

                <td mat-cell
                    [class.mf-expandable-row]="!_isUndefined(_columnExpandDef)"
                    class="mf-table-cell-column-def-field"
                    [style.textAlign]="_isUndefined(columnDef.cell) ? _config.row.cell.contentAlign : columnDef.cell.contentAlign"
                    *matCellDef="let item;">
                    <ng-template [ngTemplateOutlet]="columnDef.cellDef.template"
                                 [ngTemplateOutletContext]="{$implicit: item}">
                    </ng-template>
                </td>
            </ng-container>
            <ng-container *ngFor="let columnDef of _columnDefs"
                          [matColumnDef]="_INFO_NAME_PREFIX + columnDef.name!"
                          [stickyEnd]="columnDef?.stickyEnd"
                          [sticky]="columnDef?.sticky">
                <th mat-header-cell
                    class="mf-table-cell-actions-info-header"
                    *matHeaderCellDef>
                </th>
            </ng-container>

            <!-- ## mfTableColumnSlideOutActionsDef slide out action ## -->
            <ng-container [matColumnDef]="_GROUP_NAME_PREFIX + _SIDLE_OUT_ACTION_POSTFIX"
                          [stickyEnd]="_columnSlideOutActionsDef?.stickyEnd"
                          [sticky]="_columnSlideOutActionsDef?.sticky">
                <th mat-header-cell
                    class="mf-table-cell-actions-group-header"
                    *matHeaderCellDef>
                </th>
            </ng-container>
            <ng-container [matColumnDef]="_FIELD_NAME_PREFIX + _SIDLE_OUT_ACTION_POSTFIX"
                          [stickyEnd]="_columnSlideOutActionsDef?.stickyEnd"
                          [sticky]="_columnSlideOutActionsDef?.sticky">
                <th mat-header-cell
                    class="mf-table-cell-actions-field-header"
                    [style.width.px]="0"
                    *matHeaderCellDef>
                    {{ _columnSlideOutActionsDef?.headerLabel }}
                </th>
                <td mat-cell
                    [class.mf-expandable-row]="!_isUndefined(_columnExpandDef)"
                    class="mf-table-cell-actions-field"
                    *matCellDef="let item">
                    <mf-table-row-cell-slide-out-actions (onOpen)="_onActionsSlideOutOpen($event)"
                                                         [location]="_columnSlideOutActionsDef?.stickyEnd ? 'end' : _columnSlideOutActionsDef?.sticky ? 'start': 'start'"
                                                         [item]="item"
                                                         [columnSlideOutActionsDef]="_columnSlideOutActionsDef"></mf-table-row-cell-slide-out-actions>
                </td>
            </ng-container>
            <ng-container [matColumnDef]="_INFO_NAME_PREFIX + _SIDLE_OUT_ACTION_POSTFIX"
                          [stickyEnd]="_columnSlideOutActionsDef?.stickyEnd"
                          [sticky]="_columnSlideOutActionsDef?.sticky">
                <th mat-header-cell
                    class="mf-table-cell-actions-info-header"
                    *matHeaderCellDef>
                </th>
            </ng-container>

            <!-- ## row click action indicator ## -->
            <ng-container [matColumnDef]="_GROUP_NAME_PREFIX + _ROW_CLICK_ACTION_POSTFIX"
                          [stickyEnd]="_columnSlideOutActionsDef?.stickyEnd"
                          [sticky]="_columnSlideOutActionsDef?.sticky">
                <th mat-header-cell
                    class="mf-table-cell-action-indicator-group-header"
                    *matHeaderCellDef>
                </th>
            </ng-container>
            <ng-container [matColumnDef]="_FIELD_NAME_PREFIX + _ROW_CLICK_ACTION_POSTFIX"
                          [stickyEnd]="_columnSlideOutActionsDef?.stickyEnd"
                          [sticky]="_columnSlideOutActionsDef?.sticky">
                <th mat-header-cell
                    class="mf-table-cell-action-indicator-field-header"
                    [style.width.px]="0"
                    *matHeaderCellDef>
                </th>
                <td mat-cell
                    [class.mf-expandable-row]="!_isUndefined(_columnExpandDef)"
                    class="mf-table-cell-action-indicator-field"
                    *matCellDef="let item">
                    <mf-icon [matTooltip]="_config.row.clickAction.tooltip"
                             [icon]="_config.row.clickAction.icon"></mf-icon>
                </td>
            </ng-container>
            <ng-container [matColumnDef]="_INFO_NAME_PREFIX + _ROW_CLICK_ACTION_POSTFIX"
                          [stickyEnd]="_columnSlideOutActionsDef?.stickyEnd"
                          [sticky]="_columnSlideOutActionsDef?.sticky">
                <th mat-header-cell
                    class="mf-table-cell-action-indicator-info-header"
                    *matHeaderCellDef>
                </th>
            </ng-container>


            <!-- ## tr for model config group headers ## -->
            <ng-container *ngIf="_hasGroupedColumns">
                <tr [style]="_config.header.styles.group"
                    class="mf-table-row-group-header"
                    mat-header-row
                    *matHeaderRowDef="_groupDisplayColumns; sticky: true"></tr>
            </ng-container>
            <!-- ## tr for model config field header row ## -->
            <tr [style]="_config.header.styles.field"
                class="mf-table-row-field-header"
                mat-header-row
                *matHeaderRowDef="_fieldDisplayColumns; sticky: true"></tr>
            <!-- ## tr for model config field info header row ## -->
            <tr [style]="_config.header.styles.info"
                class="mf-table-row-field-header-info"
                mat-header-row
                *matHeaderRowDef="_fieldInfoDisplayColumns; sticky: true"></tr>
            <!-- ## tr for model row data ## -->
            <tr class="mf-table-row"
                (click)="_onRowClick($event, item)"
                [class.mf-selected-row]="item.selected === true"
                [class.mf-error-row]="item.error === true"
                [class.mf-table-row-action]="_hasRowClickAction === true"
                mat-row
                *matRowDef="let item; columns: _fieldDisplayColumns;">
            </tr>
            <!-- ## tr for expand row ## -->
            <tr mat-row
                class="mf-expand-row mf-table-row"
                [class.mf-table-row-action]="_hasRowClickAction === true"
                *matRowDef="let item; columns: _expandColumns"></tr>
        </table>
        <div *ngIf="_hasData === false"
             class="mf-flex-fill mf-table-no-data">
            {{ _hasFilters ? "No data matching the filter." : "No data." }}
        </div>
    </div>

    <div class="mf-table-footer">
        <mf-table-footer (onPageChange)="_onPageChange($event)"
                         [paginationEnabled]="_paginationEnabled"
                         [footerLeftDef]="_footerLeftDef"
                         [footerRightDef]="_footerRightDef"
                         [pagination]="_pagination"></mf-table-footer>
    </div>
</div>