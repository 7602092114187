import { Component, Inject, Injector, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfBreadcrumb } from "@material-framework/breadcrumb/breadcrumb";
import { MF_BREADCRUMB_CONFIG_TOKEN, MfBreadcrumbCompConfig } from "@material-framework/breadcrumb/breadcrumb.config";
import { MfBreadcrumbService } from "@material-framework/breadcrumb/breadcrumb.service";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { map, Observable } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "MfBreadcrumbsComponent" };

@Component({
  selector: "mf-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MfBreadcrumbsComponent extends MfBaseComponent implements OnInit {

  /**
   * custom class provided by consumer to increase specificity
   * This will benefit to override styles that are conflicting
   */
  @Input()
  public class: string = "";

  /**
   * anchorTarget = "_blank" makes the breadcrumb link open in a new tab
   */
  @Input()
  public anchorTarget?: string = "_blank";

  public breadcrumbs$?: Observable<MfBreadcrumb[]>;
  public someParameterValue: unknown = null;

  public constructor(
    protected override _injector: Injector,
    protected _breadcrumbService: MfBreadcrumbService,
    protected _activateRoute: ActivatedRoute,
    @Inject(MF_BREADCRUMB_CONFIG_TOKEN)
    protected _config: MfBreadcrumbCompConfig,
  ) {
    super(TYPE_INFO, _injector);
    // breadcrumb inside ngIf works only this way
    this._sub(_activateRoute.params, {
      next: (params) => {
        this.setupComponent(params["someParam"]);
      }
    });
  }

  public setupComponent(someParam: unknown): void {
    this.someParameterValue = someParam;
  }

  public ngOnInit(): void {
    this.breadcrumbs$ = this._breadcrumbService.breadcrumbs.pipe(
      map((breadcrumbs: MfBreadcrumb[]) => {
        return breadcrumbs
          .map((breadcrumb: MfBreadcrumb) => {
            return {
              ...breadcrumb,
              routeLink: breadcrumb.routeInterceptor?.(breadcrumb.routeLink, breadcrumb) || breadcrumb.routeLink,
            };
          });
      })
    );
  }
}