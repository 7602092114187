<div class="mf-sort-container">
  <button mat-button
          matTooltip="{{ ascTooltip }}"
          [ngClass]="{'mf-button-selected': !_isUndefined(column) && !_isUndefined(column.sort) && !_isUndefined(column.sort.direction) ? column.sort.direction === sortTypes.asc  : false}"
          (click)="onAscClicked()">
    <div class="mf-sort-item">
      <span>{{ ascDisplayName }}</span>
      <div class="mf-flex-fill"></div>
      <mf-icon [icon]="config.row.header.sort.ascIcon"></mf-icon>
    </div>
  </button>
  <button mat-button
          matTooltip="{{ descTooltip }}"
          [ngClass]="{'mf-button-selected': !_isUndefined(column) && !_isUndefined(column.sort) && !_isUndefined(column.sort.direction) ? column.sort.direction === sortTypes.desc  : false}"
          (click)="onDescClicked()">
    <div class="mf-sort-item">
      <span>{{ descDisplayName }}</span>
      <div class="mf-flex-fill"></div>
      <mf-icon [icon]="config.row.header.sort.descIcon"></mf-icon>
    </div>
  </button>
  <button mat-button
          matTooltip="{{ config.row.header.sort.clearTooltip }}"
          (click)="onClearClicked()">
    <div class="mf-sort-item">
      <span>{{config.row.header.sort.clearDisplayName}}</span>
      <div class="mf-flex-fill"></div>
      <mf-icon [icon]="config.row.header.sort.clearIcon"></mf-icon>
    </div>
  </button>
  <ng-container *ngIf="orderEnabled">
    <button mat-button
            [matMenuTriggerFor]="sortOrderMenu">
      <div class="mf-sort-item">
        <span>{{ config.row.header.sort.orderDisplayName }}: {{ !_isUndefined(column) && !_isUndefined(column.sort) &&
          !_isUndefined(column.sort.index) ? (column.sort.index + 1) : 'Not Set' }}</span>
        <div class="mf-flex-fill"></div>
        <mf-icon [icon]="config.row.header.sort.orderIcon"></mf-icon>
      </div>
    </button>
    <mat-menu #sortOrderMenu="matMenu">
      <button mat-menu-item
              (click)="onSortIndexOptionClicked(option)"
              *ngFor="let option of column?.sort?.options"
              [value]="option.value">{{option.label}}</button>
    </mat-menu>
  </ng-container>
</div>