<div class="ecom-page-container">
  <div class="ecom-page-account-select">
    <ecom-account-select [disabled]="_isLoading"></ecom-account-select>
  </div>
  <mf-table #ordersTable
            viewManagerEnabled="true"
            [locationKey]="_locationKey"
            [modelConfig]="_orderModelConfig"
            [postModelConfig]="_orderRequestModelConfig"
            (onRowClicked)="_onRowClicked($event)">
    <ng-container *mfTableHeaderLeftDef>
      <div class="ecom-page-header">
        <div class="ecom-title">
          Order History
        </div>
      </div>
    </ng-container>
  </mf-table>
</div>