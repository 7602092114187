import { InjectionToken } from "@angular/core";
import { MfHTTPClientHTTPRetryConfig } from "../httpClient/http.client.config";

export const MF_PORTALS_CONFIG_TOKEN = new InjectionToken<MfPortalsConfig>("MfPortalsConfig");
export const MF_PORTALS_DEFAULT_CONFIG: MfPortalsConfig = {
  httpRetry: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    409: {
      retryCount: 2.
    }
  }
};

export type MfPortalsConfig = {
  httpRetry: MfHTTPClientHTTPRetryConfig;
}