import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { EComAccountSelectModule } from "@e-commerce/accountSelect/account.select.module";
import { EComManufacturerDetailLineModule } from "@e-commerce/manufacturerDetailLine/manufacturer.detail.line.module";
import { EComProductsComponent } from "@e-commerce/products/products.component";
import { MfIconModule } from "@material-framework/icon/icon.module";
import { MfInputModule } from "@material-framework/input/input.module";
import { MfTableSimpleSearchModule } from "@material-framework/table/simpleSearch/table.simple.search.module";
import { MfTableModule } from "@material-framework/table/table.module";
@NgModule({
  declarations: [
    EComProductsComponent,
  ],
  exports: [
    EComProductsComponent,
  ],
  imports: [
    BrowserModule,
    EComAccountSelectModule,
    EComManufacturerDetailLineModule,
    MatButtonModule,
    MatTooltipModule,
    MfIconModule,
    MfInputModule,
    MfTableModule,
    MfTableSimpleSearchModule,
  ],
  providers: [
  ]
})
export class EComProductsModule { }