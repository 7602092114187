<ng-template #breadcrumbTemplate
             let-breadcrumb>
  <div *ngIf="!_isUndefined(breadcrumb.icon)"
       class="mf-breadcrumb-icon">
    <mf-icon [icon]="breadcrumb.icon">
    </mf-icon>
  </div>
  <div class="mf-breadcrumb-label">
    {{ breadcrumb.label }}
  </div>
</ng-template>

<nav aria-label="breadcrumb"
     class="mf-breadcrumb-root"
     [ngClass]="class">
  <ol class="mf-breadcrumb-list">
    <ng-container
                  *ngFor="let breadcrumb of breadcrumbs$ | async; last as isLast; first as isFirst; index as index; count as count ">
      <li class="mf-breadcrumb-item">
        <div *ngIf="!isLast"
             class="mf-breadcrumb-link"
             [ngClass]="{ 'mf-breadcrumb-link-disabled': breadcrumb.disable }"
             [attr.aria-disabled]="breadcrumb.disable"
             [attr.tabIndex]="breadcrumb.disable ? -1 : 0"
             rel="noopener noreferrer"
             [routerLink]="breadcrumb.routeLink"
             [target]="anchorTarget ? anchorTarget : '_self'">
          <div class="mf-breadcrumb-row">
            <ng-template *ngTemplateOutlet="breadcrumbTemplate; context: {$implicit: breadcrumb}"></ng-template>
          </div>
        </div>

        <label *ngIf="isLast"
               class="mf-breadcrumb-trail">
          <div class="mf-breadcrumb-row-trail">
            <ng-template *ngTemplateOutlet="breadcrumbTemplate; context: {$implicit: breadcrumb}"></ng-template>
          </div>
        </label>
      </li>

      <li *ngIf="!isLast"
          class="mf-breadcrumb-separator"
          aria-hidden="true">
        <mf-icon [icon]="_config.nextIcon">chevron_right</mf-icon>
      </li>
    </ng-container>
  </ol>
</nav>