import { FocusMonitor } from "@angular/cdk/a11y";
import { BooleanInput, coerceBooleanProperty, coerceNumberProperty, NumberInput } from "@angular/cdk/coercion";
import {
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  numberAttribute,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from "@angular/material/form-field";
import { faPercent } from "@fortawesome/free-solid-svg-icons";
import { MfBaseComponent } from "@material-framework/base/base.component";
import { MfError } from "@material-framework/common/error/error";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { MF_KEYBOARD_KEY_CODE_0, MF_KEYBOARD_KEY_CODE_1, MF_KEYBOARD_KEY_CODE_2, MF_KEYBOARD_KEY_CODE_3, MF_KEYBOARD_KEY_CODE_4, MF_KEYBOARD_KEY_CODE_5, MF_KEYBOARD_KEY_CODE_6, MF_KEYBOARD_KEY_CODE_7, MF_KEYBOARD_KEY_CODE_8, MF_KEYBOARD_KEY_CODE_9, MF_KEYBOARD_KEY_CODE_ALT, MF_KEYBOARD_KEY_CODE_ARROW_DOWN, MF_KEYBOARD_KEY_CODE_ARROW_LEFT, MF_KEYBOARD_KEY_CODE_ARROW_RIGHT, MF_KEYBOARD_KEY_CODE_ARROW_UP, MF_KEYBOARD_KEY_CODE_BACKSPACE, MF_KEYBOARD_KEY_CODE_CONTROL, MF_KEYBOARD_KEY_CODE_COPY, MF_KEYBOARD_KEY_CODE_CUT, MF_KEYBOARD_KEY_CODE_DELETE, MF_KEYBOARD_KEY_CODE_PASTE, MF_KEYBOARD_KEY_CODE_SHIFT, mfKeyboardIsNumberKeyCode } from "@material-framework/common/utils/keyboard.utils";
import { mfNumberIsNumberConvert, mfNumberIsParsable } from "@material-framework/common/utils/number.utils";
import { mfStringCountChar, mfStringIsEmptyOrWhitespace, mfStringReplaceFromTo, mfStringStartsWith } from "@material-framework/common/utils/string.utils";
import { mfTypeHasOwnProperty, mfTypeIsNull, mfTypeIsNullOrUndefined, mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfIcon } from "@material-framework/icon/icon";
import { MfIconTypes } from "@material-framework/icon/icon.types";
import { MF_INPUT_CONFIG_TOKEN, MfInputConfig, MfInputModelFieldNumberTypeMapConfig } from "@material-framework/input/input.config";
import { MfInputTypes } from "@material-framework/input/input.types";
import { mfModelFieldDataTypeIsFloatingPoint, mfModelFieldDataTypeIsIntegral, mfModelFieldDataTypeIsNumber } from "@material-framework/modelConfig/model.config";
import { Subject } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "MfInputComponent" };

export type MfInputDataTypes = number | string | null | undefined;

@Component({
  selector: "mf-input",
  templateUrl: "input.component.html",
  styleUrls: ["input.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MatFormFieldControl, useExisting: MfInputComponent }],
})
export class MfInputComponent extends MfBaseComponent implements ControlValueAccessor, MatFormFieldControl<MfInputDataTypes>, OnDestroy {
  @ViewChild("input")
  public input?: ElementRef;

  @Input("aria-describedby")
  public userAriaDescribedBy?: string;

  @Input()
  public label?: string;

  @Input()
  public clearValue?: MfInputDataTypes;

  @Input()
  public pattern?: RegExp;

  @Input()
  public get value(): MfInputDataTypes {
    return this._getReturnValue();
  }
  public set value(value: MfInputDataTypes) {
    this._value = mfTypeIsNullOrUndefined(value) ? "" : value;
    this.stateChanges.next();
  }

  @Input()
  public stepValue = 1;

  @Input()
  public get type(): MfInputTypes {
    return this._type;
  }
  public set type(value: MfInputTypes) {
    this._type = value;
    this._initType();
  }

  @Input()
  public get placeholder(): string {
    return this._placeholder;
  }
  public set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input({ transform: booleanAttribute })
  public get required(): boolean {
    return this._required;
  }
  public set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input({ transform: booleanAttribute })
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input({ transform: booleanAttribute })
  public get periodAllowed(): boolean {
    return this._periodAllowed;
  }
  public set periodAllowed(value: BooleanInput) {
    this._periodAllowed = coerceBooleanProperty(value);
  }

  @Input({ transform: booleanAttribute })
  public get minusAllowed(): boolean {
    return this._minusAllowed;
  }
  public set minusAllowed(value: BooleanInput) {
    this._minusAllowed = coerceBooleanProperty(value);
  }

  @Input({ transform: booleanAttribute })
  public get plusAllowed(): boolean {
    return this._plusAllowed;
  }
  public set plusAllowed(value: BooleanInput) {
    this._plusAllowed = coerceBooleanProperty(value);
  }

  @Input({ transform: booleanAttribute })
  public get percentage(): boolean {
    return this._percentage;
  }
  public set percentage(value: BooleanInput) {
    this._percentage = coerceBooleanProperty(value);
  }

  @Input({ transform: numberAttribute })
  public get maxLength(): number | undefined {
    return this._maxLength;
  }
  public set maxLength(value: NumberInput) {
    if (!isNaN(value as any)) {
      this._maxLength = coerceNumberProperty(value);
    }
  }

  @Input({ transform: numberAttribute })
  public get minValue(): number | undefined {
    return this._minValue;
  }
  public set minValue(value: NumberInput) {
    this._minValue = coerceNumberProperty(value);
  }

  @Input({ transform: numberAttribute })
  public get maxValue(): number | undefined {
    return this._maxValue;
  }
  public set maxValue(value: NumberInput) {
    this._maxValue = coerceNumberProperty(value);
  }

  @Output()
  public onInput: EventEmitter<MfInputDataTypes> = new EventEmitter();

  @Output()
  public onChanged: EventEmitter<MfInputDataTypes> = new EventEmitter();

  @Output()
  public onEnterKey: EventEmitter<boolean> = new EventEmitter();

  public stateChanges = new Subject<void>();
  public focused = false;
  public touched = false;
  public controlType?: string;

  protected _iconPercentage: MfIcon = { type: MfIconTypes.fontAwesome, icon: faPercent };
  protected _value: MfInputDataTypes = null;
  protected _type: MfInputTypes = MfInputTypes.string;
  protected _placeholder = "";
  protected _required = false;
  protected _disabled = false;
  protected _periodAllowed = false;
  protected _minusAllowed = false;
  protected _plusAllowed = false;
  protected _percentage = false;
  protected _maxLength?: number;
  protected _minValue?: number;
  protected _maxValue?: number;
  protected _oldValue?: MfInputDataTypes;
  protected _modelFieldNumberTypeMapConfig?: MfInputModelFieldNumberTypeMapConfig;

  public constructor(
    protected override _injector: Injector,
    protected _focusMonitor: FocusMonitor,
    protected _elementRef: ElementRef<HTMLElement>,
    @Inject(MF_INPUT_CONFIG_TOKEN)
    protected _config: MfInputConfig,
    @Optional() @Self()
    public ngControl: NgControl | null,
    @Optional() @Inject(MAT_FORM_FIELD)
    public formField: MatFormField | null,
  ) {
    super(TYPE_INFO, _injector);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this as ControlValueAccessor;
    }
  }

  public onTouched: () => void = () => { };
  public onChange: (_: MfInputDataTypes) => void = () => { };

  public setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector(
      ".mf-input-container",
    )!;
    controlElement.setAttribute("aria-describedby", ids.join(" "));
  }

  public onContainerClick(): void {
    if (!mfTypeIsUndefined(this.input)) {
      this._focusMonitor.focusVia(this.input, "program");
    }
  }

  public writeValue(value: MfInputDataTypes): void {
    this._value = value;
  }

  public registerOnChange(fn: (_: MfInputDataTypes) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.stateChanges.complete();
  }

  public get errorState(): boolean {
    if (!mfTypeIsNull(this.ngControl)) {
      return this.ngControl.invalid === true && this.touched;
    }
    return false;
  }

  public get empty(): boolean {
    return mfStringIsEmptyOrWhitespace(this._value?.toString());
  }

  public get shouldLabelFloat(): boolean {
    return this.focused === true || !this.empty;
  }

  protected _initType(): void {
    if (!mfTypeIsUndefined(this._type) && mfModelFieldDataTypeIsNumber(this._type)) {
      if (!mfTypeHasOwnProperty(this._config.modelNumberTypes, this._type) || mfTypeIsUndefined(this._config.modelNumberTypes[this._type])) {
        throw new MfError(this._typeInfo, "_initType", "No input model data type mapping");
      }

      this._modelFieldNumberTypeMapConfig = this._config.modelNumberTypes[this._type] as MfInputModelFieldNumberTypeMapConfig;
      this._periodAllowed = mfModelFieldDataTypeIsFloatingPoint(this._type);
    }
  }

  protected get _inputRawValue(): string {
    return this.input?.nativeElement?.value;
  }
  protected set _inputRawValue(value: string) {
    if (!mfTypeIsUndefined(this.input) && !mfTypeIsUndefined(this.input.nativeElement)) {
      this.input.nativeElement.value = value;
    }
  }

  protected _getRawValueReplaceSelected(event: Event, originalString: string, replacement: string): string {
    const input = (event.target as HTMLInputElement);

    if (!mfTypeIsNull(input.selectionStart) && !mfTypeIsNull(input.selectionEnd)) {
      return mfStringReplaceFromTo(originalString, input.selectionStart, input.selectionEnd, replacement);
    }
    return originalString;
  }

  protected _paste(event: ClipboardEvent): void {
    const rawValue = this._inputRawValue;
    const data = event.clipboardData?.getData("text");

    if (mfTypeIsUndefined(data)) {
      event.preventDefault();
      return;
    }

    if (mfModelFieldDataTypeIsNumber(this.type)) {
      this._pasteNumberValue(event, rawValue, data);
    } else {
      if (!mfTypeIsUndefined(this._maxLength) && data.length > this._maxLength) {
        event.preventDefault();
        return;
      }
    }
  }

  protected _pasteNumberValue(event: ClipboardEvent, rawValue: string, data: string | undefined): void {
    if (!mfNumberIsParsable(data)) {
      event.preventDefault();
      return;
    } else {
      if (!mfStringIsEmptyOrWhitespace(data)) {
        const newValue = this._getRawValueReplaceSelected(event, rawValue, data);

        if (mfModelFieldDataTypeIsIntegral(this._type)) {
          if (newValue.indexOf(".") !== -1) {
            event.preventDefault();
            return;
          }
        }

        if (mfNumberIsParsable(newValue)) {
          const newNumber = mfNumberIsNumberConvert(newValue);
          if (!mfTypeIsNull(newNumber) && this._checkNumberValue(newNumber, this.type).passed) {
            return;
          }
        }

        event.preventDefault();
        return;
      }
    }
  }

  protected _keyDown(event: KeyboardEvent): void {
    const rawValue = this._getRawValueReplaceSelected(event, this._inputRawValue, event.key);

    switch (event.key) {
      case MF_KEYBOARD_KEY_CODE_CONTROL:
      case MF_KEYBOARD_KEY_CODE_ALT:
      case MF_KEYBOARD_KEY_CODE_SHIFT:
      case MF_KEYBOARD_KEY_CODE_PASTE:
      case MF_KEYBOARD_KEY_CODE_COPY:
      case MF_KEYBOARD_KEY_CODE_CUT:
      case MF_KEYBOARD_KEY_CODE_DELETE:
      case MF_KEYBOARD_KEY_CODE_ARROW_LEFT:
      case MF_KEYBOARD_KEY_CODE_ARROW_RIGHT:
      case MF_KEYBOARD_KEY_CODE_BACKSPACE:
        return;
    }

    if (event.ctrlKey === true && (event.key === "v" || event.key === "c")) {
      return;
    }

    if (!mfTypeIsUndefined(this._maxLength) && rawValue.length > this._maxLength) {
      event.preventDefault();
      return;
    }

    if (!mfTypeIsUndefined(this.pattern)) {
      if (!this.pattern.test(rawValue as string)) {
        event.preventDefault();
        return;
      }
    }

    if (mfModelFieldDataTypeIsNumber(this.type)) {
      const newIntValue = mfNumberIsNumberConvert(mfTypeIsNullOrUndefined(rawValue) ? "" : rawValue.toString() + event.key);
      if (mfKeyboardIsNumberKeyCode(event.key) && !this._checkNumberValue(newIntValue, this.type).passed) {
        event.preventDefault();
        return;
      }
      this._checkNumberKeyPress(event, rawValue);
    }
  }

  protected _checkNumberKeyPress(event: KeyboardEvent, rawValue: string): void {
    switch (event.key) {
      case ".":
        if (!this._periodAllowed || mfStringCountChar(rawValue, ".") >= 1) {
          event.preventDefault();
        }
        break;
      case "-":
        if (!this._minusAllowed || mfStringCountChar(rawValue, "-") >= 1) {
          event.preventDefault();
        }
        break;
      case "+":
        if (!this._plusAllowed || mfStringCountChar(rawValue, "+") >= 1) {
          event.preventDefault();
        }
        break;
      case MF_KEYBOARD_KEY_CODE_ARROW_UP:
        this._handleValueIncrement();
        event.preventDefault();
        break;
      case MF_KEYBOARD_KEY_CODE_ARROW_DOWN:
        this._handleValueDecrement();
        event.preventDefault();
        break;
      case MF_KEYBOARD_KEY_CODE_0:
        if (mfStringStartsWith("0", rawValue)) {
          event.preventDefault();
          break;
        }
        break;
      case MF_KEYBOARD_KEY_CODE_1:
      case MF_KEYBOARD_KEY_CODE_2:
      case MF_KEYBOARD_KEY_CODE_3:
      case MF_KEYBOARD_KEY_CODE_4:
      case MF_KEYBOARD_KEY_CODE_5:
      case MF_KEYBOARD_KEY_CODE_6:
      case MF_KEYBOARD_KEY_CODE_7:
      case MF_KEYBOARD_KEY_CODE_8:
      case MF_KEYBOARD_KEY_CODE_9:
        break;
      default:
        event.preventDefault();
    }
  }

  protected _checkNumberValue(numberValue: number | null, type: MfInputTypes): { passed: boolean, min?: number, max?: number } {
    if (mfModelFieldDataTypeIsNumber(type)) {
      if (mfTypeIsUndefined(this._modelFieldNumberTypeMapConfig)) {
        throw new MfError(this._typeInfo, "_checkNumberValue", "No input model data type mapping");
      }

      const min = this._minValue || this.clearValue as number || this._modelFieldNumberTypeMapConfig.min;
      const max = this._maxValue || this._modelFieldNumberTypeMapConfig.max;

      if (!mfTypeIsNull(numberValue) && numberValue > max) {
        return { passed: false, max };
      }
      if (!mfTypeIsNull(numberValue) && numberValue < min) {
        return { passed: false, min };
      }

      return { passed: true };
    }
    return { passed: true };
  }

  protected _onFocusIn(): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  protected _onFocusOut(event: FocusEvent): void {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  protected _change(): void {
    if (mfModelFieldDataTypeIsNumber(this._type) && this._inputRawValue === "") {
      this._inputRawValue = mfTypeIsUndefined(this._minValue) ? "0" : this._minValue.toString();
    }
    this.onChanged.emit(this._getReturnValue(this._value));
  }

  protected _valueInput(value: MfInputDataTypes): void {
    this._value = this._getReturnValue(value);
    this.onChange(this._value);
    this.onInput.emit(this._value);
  }

  protected _onClearValueClicked(): void {
    if (!mfTypeIsUndefined(this.clearValue)) {
      this._value = this.clearValue;
    } else if (!mfTypeIsUndefined(this._minValue)) {
      this._value = this._minValue;
    } else {
      if (mfModelFieldDataTypeIsNumber(this.type)) {
        this._value = 0;
      } else {
        this._value = null;
      }
    }
    this.onChange(this._getReturnValue());
    this.onInput.emit(this._getReturnValue());
  }

  protected _mouseWheel(event: WheelEvent): void {
    if (mfTypeIsUndefined(this._config.mouseWheelDisabled) || this._config.mouseWheelDisabled === false) {
      if (mfModelFieldDataTypeIsNumber(this.type)) {
        if (event.deltaY < 0) {
          this._handleValueIncrement();
          event.preventDefault();
        } else if (event.deltaY > 0) {
          this._handleValueDecrement();
          event.preventDefault();
        }
      }
    }
  }

  protected _handleValueIncrement(): void {
    const value = mfNumberIsNumberConvert(this._value);

    if (mfTypeIsUndefined(this._oldValue)) {
      this._oldValue = value;
    }

    if (!mfTypeIsNull(value)) {
      let newValue = (value + this._getStepValue());

      if (!this._checkNumberValue(newValue, this.type).passed) {
        newValue = value;
      }

      this._value = newValue;
      this.onChange(this._value);
      this.onInput.emit(this._value);
    }
  }

  protected _handleValueDecrement(): void {
    const value = mfNumberIsNumberConvert(this._value);

    if (mfTypeIsUndefined(this._oldValue)) {
      this._oldValue = value;
    }

    if (!mfTypeIsNull(value)) {
      let newValue = (value - this._getStepValue());

      if (this.minusAllowed === false && newValue < 0) {
        if (!mfTypeIsUndefined(this.clearValue)) {
          newValue = this.clearValue as number;
        } else if (!mfTypeIsUndefined(this._minValue)) {
          newValue = this._minValue;
        } else {
          newValue = 0;
        }
      }

      if (!this._checkNumberValue(newValue, this.type).passed) {
        newValue = value;
      }

      this._value = newValue;
      this.onChange(this._value);
      this.onInput.emit(this._value);
    }
  }

  protected _blur(): void {
    const value = this._getReturnValue(this._value);
    if (this._oldValue !== value) {
      delete this._oldValue;
      this._change();
    }
  }

  protected _getStepValue(): number {
    if (this.percentage === true) {
      return this.stepValue / 100;
    } else {
      return this.stepValue;
    }
  }

  protected _getReturnValue(value?: MfInputDataTypes): MfInputDataTypes {
    const v = mfTypeIsNullOrUndefined(value) ? this._value : value;
    if (mfModelFieldDataTypeIsNumber(this.type)) {
      const numberValue = mfNumberIsNumberConvert(v);

      if (mfTypeIsNull(numberValue) || isNaN(numberValue)) {
        return this._getDefaultValue();
      }

      if (this.percentage === true) {
        return numberValue / 100;
      } else {
        const result = this._checkNumberValue(numberValue, this._type);

        if (!result.passed) {
          if (!mfTypeIsUndefined(result.min)) {
            return result.min;
          } else if (!mfTypeIsUndefined(result.max)) {
            return result.max;
          }
        }

        return numberValue;
      }
    } else {
      return v;
    }
  }

  protected _getDisplayValue(): MfInputDataTypes {
    if (mfModelFieldDataTypeIsNumber(this.type)) {
      const numberValue = mfNumberIsNumberConvert(this._value);

      if (mfTypeIsNull(numberValue) || isNaN(numberValue)) {
        return this._getDefaultValue();
      }

      if (this.percentage === true) {
        return Math.round(numberValue * 100);
      } else {
        const result = this._checkNumberValue(numberValue, this._type);

        if (!result.passed) {
          if (!mfTypeIsUndefined(result.min)) {
            return result.min;
          } else if (!mfTypeIsUndefined(result.max)) {
            return result.max;
          }
        }

        return numberValue;
      }
    } else {
      return this._value;
    }
  }

  protected _getDefaultValue(): MfInputDataTypes {
    if (mfModelFieldDataTypeIsNumber(this.type)) {
      return this._minValue || this.clearValue || 0;
    } else {
      return "";
    }
  }
}