import { NgxMatDatepickerInputEvent } from "@angular-material-components/datetime-picker/lib/datepicker-input-base";
import { Component, Inject, Injector, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MfTypeInfo } from "@material-framework/common/type.info";
import { mfObjectGetPropertyPathValue, mfObjectSetPropertyPathValue } from "@material-framework/common/utils/object.utils";
import { mfTypeIsUndefined } from "@material-framework/common/utils/type.utils";
import { MfModelBase } from "@material-framework/model/model.base";
import { MfTableRowCellBaseEditorComponent } from "@material-framework/table/row/cell/value/table.row.cell.base.editor.component";
import { MfTableRowCellEditorBaseOptions, MfTableRowCellEditorValidatorOptions } from "@material-framework/table/table";
import { MF_TABLE_CONFIG_TOKEN, MfTableConfig } from "@material-framework/table/table.config";
import * as moment from "moment";

const TYPE_INFO: MfTypeInfo = { className: "MfTableRowCellDateEditorComponent" };

export type MfTableRowCellDateEditorOptions = MfTableRowCellEditorBaseOptions & {
  width?: number;
  hideTime?: boolean;
  min?: Date;
  max?: Date;
}

@Component({
  selector: "mf-table-row-cell-date-editor-component",
  templateUrl: "table.row.cell.date.editor.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MfTableRowCellDateEditorComponent<TModel extends MfModelBase> extends MfTableRowCellBaseEditorComponent<MfTableRowCellDateEditorOptions, TModel> {
  protected _formGroup: FormGroup;
  protected _inputFormControl: FormControl = new FormControl();
  protected readonly _inputName = "input" as const;
  protected readonly _max = new Date(Math.max());
  protected _originalValue?: Date;

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._formGroup = new FormGroup([]);
    this._buildFormGroup();
  }

  public ngOnInit(): void {

  }

  public override addValidators(validators: MfTableRowCellEditorValidatorOptions[]): void {
    this._addEditorOptionsValidators(this._inputName, this._inputFormControl, validators);
  }

  protected _changed(event: NgxMatDatepickerInputEvent<Date | moment.Moment>): void {
    if (!mfTypeIsUndefined(this.rowItem) && !mfTypeIsUndefined(this.fieldColumn)) {
      mfObjectSetPropertyPathValue(this.fieldColumn?.fieldPath, this.rowItem, event.value);
      if (moment.isMoment(event.value)) {
        this.onChanged.emit({ value: event.value.toDate(), rowItem: this.rowItem, fieldColumn: this.fieldColumn, originalValue: this._originalValue });
      } else {
        this.onChanged.emit({ value: event.value, rowItem: this.rowItem, fieldColumn: this.fieldColumn, originalValue: this._originalValue });
      }
    }
  }

  protected _buildFormGroup(): void {
    this._addFormControlToFormGroup(this._formGroup, this._inputName, this._inputFormControl);
  }

  protected override _onOptionsSet(): void {
    if (!mfTypeIsUndefined(this.options)) {
      this._addEditorOptionsValidators(this._inputName, this._inputFormControl, this.options.validators);
    }
  }

  protected override _onFieldColumnSet(): void {
    this._init();
  }

  protected override _onRowItemSet(): void {
    this._init();
  }

  protected _init(): void {
    if (!mfTypeIsUndefined(this.fieldColumn) && !mfTypeIsUndefined(this.rowItem)) {
      this._originalValue = mfObjectGetPropertyPathValue<Date>(this.fieldColumn.fieldPath, this.rowItem);
      this._setFormControlValue(this._inputFormControl, this._originalValue);
      this._inputFormControl.markAsUntouched();
    }
  }

  protected override _onIsLoadingSet(value: boolean | undefined): void {
    if (mfTypeIsUndefined(value) || value === false) {
      this._inputFormControl.enable();
    } else {
      this._inputFormControl.disable();
    }
  }
}