import { MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@material-framework/modelConfig/model.config";

export type EComCompleteOrderModel = {
  key: string;
  cartKey: string;
  carrierKey: string;
  paymentMethod: number;
  purchaseOrderRef: string;
};

export type EComCompleteOrderModelFieldsConfig = {
  [key in keyof Required<EComCompleteOrderModel>]: MfModelFieldConfig
};

export const ECOM_COMPLETE_ORDER_MODEL_FIELDS_CONFIG: EComCompleteOrderModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  carrierKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  paymentMethod: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  purchaseOrderRef: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  }
};

export const ECOM_COMPLETE_ORDER_MODEL_CONFIG_ID = "completeOrder";

export const ECOM_COMPLETE_ORDER_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_COMPLETE_ORDER_MODEL_CONFIG_ID,
  fields: ECOM_COMPLETE_ORDER_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_COMPLETE_ORDER_CONFIG",
  },
  portals: {}
};